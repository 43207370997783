import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";

const ExtensionOffer = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);

  const [emp, setEmp] = useState({});

  useEffect(() => {
    getEmpInfo();
  }, []);

  const getEmpInfo = () => {
    try {
      Apiconnect.postData(`separation/extensionOfferById/${user_id}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setEmp(extract[0]);
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  console.log(emp);

  const current_retirement = emp.retirement_on;
  const year = current_retirement?.slice(-4);
  const dt = current_retirement?.slice(0, 2);
  const mon = current_retirement?.slice(3, 5);
  const year2 = Number(year) + 1;
  const nextYear = `${dt}/${mon}/${year2}`;

  const handleAccept = () => {
    const data = {
      extension_on: nextYear,
    };
    Apiconnect.postData(`separation/acceptExtensionOffer/${emp.id}`, data).then((res) => {
      toast.success("Success");
      getEmpInfo();
    });
  };

  const handleReject = () => {
    Apiconnect.postData(`separation/rejectExtensionOffer/${emp.id}`).then((res) => {
      toast.success("Success");
      getEmpInfo();
    });
  };

  return (
    <Container heading="Retirement Extension Offer">
      <Table className="col-md-12" trHeader="">
        <tr className="text-center">
          <td>Current Retirement</td>
          <td>{emp.retirement_on}</td>
          <td>Offered Extension</td>
          <td>{nextYear}</td>
          <td>
            {emp.extension_on === null || emp.extension_on === "" ? (
              <>
                {emp.offer_extension == 1 && (
                  <button className="btn btn-sm btn-info m-1" onClick={handleAccept}>
                    Accept
                  </button>
                )}
                {emp.offer_extension == 1 && (
                  <button className="btn btn-sm btn-danger m-1" onClick={handleReject}>
                    Reject
                  </button>
                )}
                {emp.offer_extension == 2 && <span className="badge badge-pill badge-danger">Offer Rejected</span>}
              </>
            ) : (
              <span className="badge badge-pill badge-success">Accepted Extension</span>
            )}
          </td>
        </tr>
      </Table>
    </Container>
  );
};

export default ExtensionOffer;
