import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import FullScreenLoading from 'react-fullscreen-loading';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

function Daily_absent_report() {
    
    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);
    var date = new Date();

    const [from_date, setFromDate] = useState(new Date());
    const [emp_id, setempid] = useState(null);
    const [dwnEmpAbsentList, setDwnEmpAbsentListList] = useState([]);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    useEffect(() => {
        getgrouplist();
    }, []);
    useEffect(() => { empdata();  }, []);
    useEffect(() => {getCompany()}, []);
    useEffect(() => {get_load_daily_abset_list()}, []);
    
    const local_user = JSON.parse(localStorage.getItem('user'));
    const [companyaddress, setCompanyData] = useState([])
    const [empList, setEmpList] = useState([]);
    const [dailyAttendanceList, setDailyAttendanceList] = useState([]);
    const [grplist, setGrouplist] = useState([])
    const [selgrp, setSelgrp] = useState('')
    const [list_leaves1, setlist_leaves1] = useState([])
    const [empGroupList, setEmpGroup] = useState([]);

    const [filteredList, setFilteredList] = useState([])
    const [filteredList1, setFilteredList1] = useState([])

    const onInputChange = e => {
        setempid(e.target.value);
    }
 

    const empdata = () => {
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpList(_xtract.reverse());
        });
    }

    const submit =async () => { 
        get_load_daily_abset_list();
    }
    const get_load_combine_list = (list_absent1,list_leave1) => {    
        //console.log(list_absent1);  
        //console.log(list_leave1);
    };

    const get_load_daily_abset_list =async () => {    
        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select from date');
            return
        } 
        // setFullScreenLoader(true);
        let info = { company_id: Apiconnect.get_company_id(), from_date: from, emp_id: emp_id, group_id: selgrp};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        await Apiconnect.postData(`employee_dailydata/get_daily_absent_report`, inf_a).then(async(response) => {
            let _xtract_empabs = Apiconnect.decrypt_obj(response.data.data);

            await Apiconnect.postData(`leave_manage/get_employee_leave_lists_bydate`, inf_a).then((response) => {
                let _xtract_empleaves = Apiconnect.decrypt_obj(response.data.data); 
                get_load_combine_list(_xtract_empabs.reverse(),_xtract_empleaves);
                setlist_leaves1(_xtract_empleaves);
                setFilteredList(_xtract_empleaves)
            });

            setDwnEmpAbsentListList(_xtract_empabs.reverse());
            setDailyAttendanceList(_xtract_empabs.reverse());
            setFilteredList1(_xtract_empabs.reverse())
            // setFullScreenLoader(false); 
        });
    };


    
    const getCompany = () => {         
        Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
            let ern = Apiconnect.decrypt_obj(response.data.data);
            setCompanyData(ern[0].address_one)
            
        });
    };
   
    /* ----------------------------excel--------------------- */
    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    const saveasexcel =async () => {
        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select from date');
            return
        }
        let info = { company_id: Apiconnect.get_company_id(), from_date: from, emp_id: emp_id};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        let list_array_absent=[];
        let list_array_leave=[];
        let list_array_combine=[];
        await Apiconnect.postData(`employee_dailydata/get_daily_absent_report`, inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data); 
            list_array_absent=_xtract;
        });

        await Apiconnect.postData(`leave_manage/get_employee_leave_lists_bydate`, inf_a).then((response) => {
            let _xtract_empleaves = Apiconnect.decrypt_obj(response.data.data);  
            list_array_leave=_xtract_empleaves;
        });

        var icnt3=0;
        for (let icnt2 = 0; icnt2 < list_array_leave.length; icnt2++) {
            let listarrayleave = list_array_leave[icnt2];
            list_array_combine[icnt3]={
                'emp_code':listarrayleave.emp_code,
                'PunchId':listarrayleave.PunchId,
                'Employee_name':listarrayleave.Employee_name,
                'department':listarrayleave.department,
                'emp_designation':listarrayleave.emp_designation,
                'shift_name':listarrayleave.shift_name,
                'shift_in_time':listarrayleave.shift_in_time,
                // 'Status':listarrayleave.leave_cat_code,
                'Status':'Leave',
            }
            icnt3++;
        }
        for (let icnt1 = 0; icnt1 < list_array_absent.length; icnt1++) {
            let listarrayabsent = list_array_absent[icnt1];
            list_array_combine[icnt3]={
                'emp_code':listarrayabsent.emp_code,
                'PunchId':listarrayabsent.PunchId,
                'Employee_name':listarrayabsent.Employee_name,
                'department':listarrayabsent.department,
                'emp_designation':listarrayabsent.emp_designation,
                'shift_name':listarrayabsent.shift_name,
                'shift_in_time':listarrayabsent.shift_in_time,
                'Status':'Absent',
            }
            icnt3++;
        }

        

        let array_data1=[1,2];
        let header = ["emp_code","PunchId","Employee_name","department","emp_designation","shift_name","shift_in_time","Status"]
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(list_array_combine, header);
            const totalColumns = sheetData[0].length;
        
            sheet1.cell("A1").value(local_user.company_name);
            sheet1.cell("A2").value(companyaddress)
            sheet1.cell("A3").value(`Daily Absent Report - ${from_date}`);
            sheet1.cell("A5").value(sheetData);

            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);

            sheet1.range("A1:H1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
            sheet1.range("A2:H2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
            sheet1.range("A3:H3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
            sheet1.range("A5:H5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");;

            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "DailyAbsentReport.xlsx");
            });
        });
    }
 
    const getgrouplist = async () => {
        const inf_a = {company_id : Apiconnect.get_company_id()};
        await Apiconnect.postData(`leave_manage/leave_group_list`, inf_a).then(res => setGrouplist(res.data.data)) 
    }

    // const getgrouplist = () => {
    //     Apiconnect.getData(`common/getallemploymenttypeById/${Apiconnect.get_company_id()}`).then((response) => {
    //         let _xtract = Apiconnect.decrypt_obj(response.data.data);
    //         setEmpGroup(_xtract.reverse());
    //     });
    // }
    
    var cnt_srno1 = 1;
    
    // console.log(grplist)

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                {/* {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    : null
                    } */}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Daily Absent Report</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">
                                        <div className="col-md-3">
                                            <label>Date</label>
                                            <DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Selete Date"
                                                name="from_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                dropdownMode="select"
                                                autoComplete='Off'
                                            />
                                        </div>
                                        <div className="col-md-4" style={{display:'none'}}>
                                            <label>Group</label>
                                            <select className="form-control" name="selgrp" value={selgrp} onChange={(e) => {
                                                setSelgrp(e.target.value)
                                                // const filtered = list_leaves1.filter((item) => item.emp_group_name.includes(e.target.value))
                                                // const filtered1 = dailyAttendanceList.filter((item) => item.emp_group_name.includes(e.target.value))
                                                // setFilteredList(filtered)
                                                // setFilteredList1(filtered1)
                                            }}>
                                                <option value="" selected>select group</option>
                                                {
                                                   grplist.map((item, index) => (
                                                        <option key={index} value={item.leavegroup_pk_id}>{item.leavegroup_name}</option>
                                                    )) 
                                                }
                                            </select>

                                        </div>
                                         
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                        </div>        
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-warning" value="Download in excel" style={{ marginTop: "23px" }} onClick={() => saveasexcel()} />
                                        </div>        
                                    </div>

                                    <div className="row" style={{ marginTop: '25px' }} >

                                        <div className="col-lg-12">
                                            <div className="iq-card">
                                                <div className="row">
                                                    <div className="col-md-6" >
                                                    </div> 
                                                </div>
                                                <div class="">
                                                    <div className="table-responsive">

                                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                                            <thead>
                                                                <tr className="clr">
                                                                    {/* <th>#</th> */}
                                                                    <th>Emp Code</th>
                                                                    <th>Punch ID / RF ID</th>
                                                                    <th>Emp Name</th>
                                                                    <th>Department</th>
                                                                    <th>Designation</th>
                                                                    <th>Group</th>
                                                                    <th>Shift</th>
                                                                    <th>Shift Start Time</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    
                                                                    filteredList.map((val, key) => {
                                                                    //if(selgrp==val.emp_group || selgrp==''){ 
                                                                        return (
                                                                            <tr key={key}>
                                                                                {/* <td>{cnt_srno1}</td> */}
                                                                                <td>{val.emp_code}</td>
                                                                                <td>{val.PunchId}</td>
                                                                                <td>{val.Employee_name}</td>
                                                                                <td>{val.department}</td>
                                                                                <td>{val.emp_designation}</td>
                                                                                <td>{val.emp_group_name}</td>
                                                                                <td>{val.shift_name}</td>
                                                                                <td>{val.shift_in_time}</td>
                                                                                <td>Leave</td>
                                                                            </tr>
                                                                        );
                                                                        cnt_srno1++;
                                                                    //}
                                                                    }) 
                                                                }

                                                                {filteredList1.length > 0
                                                                    ?
                                                                    filteredList1.map((val, key) => {
                                                                    //if(selgrp==val.emp_group || selgrp==''){     
                                                                    return (
                                                                        <tr key={key}>
                                                                            {/* <td>{cnt_srno1}</td> */}
                                                                            <td>{val.emp_code}</td>
                                                                            <td>{val.PunchId}</td>
                                                                            <td>{val.Employee_name}</td>
                                                                            <td>{val.department}</td>
                                                                            <td>{val.emp_designation}</td>
                                                                            <td>{val.emp_group_name}</td>
                                                                            <td>{val.shift_name}</td>
                                                                            <td>{val.shift_in_time}</td>
                                                                            <td>Absent</td>
                                                                        </tr>
                                                                    );
                                                                    cnt_srno1++;
                                                                    //}
                                                                })
                                                            : <tr>
                                                                <th colSpan={11}>No Data Found</th>
                                                                </tr>}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )

}
export default Daily_absent_report