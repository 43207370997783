import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { pageStyles } from "../../../globalStyles";

const RatingS = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FAAF00",
  },
  "& .MuiRating-iconHover": {
    color: "#FAAF00",
  },
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: "#FF0000",
  },
});

const Rm_consolidated_candidate_score = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [vacancy_list, setVacancy_list] = useState([]);
  const [applicant_score, setApplicant_score] = useState([]);
  const [filtered, setFilted] = useState([]);
  const [search, setSearch] = useState("");

  const [assessment, setAssessment] = useState("");

  useEffect(() => {
    get_vacancy_list();
    get_applicants_score();
  }, []);

  const get_vacancy_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(
      `hrms_initiate_assessments/get_all_vacancies/${company_id}`
    ).then((res) => {
      setFullScreenLoader(false);
      let data = Apiconnect.decrypt_obj(res.data.data);
      setVacancy_list(data);
    });
  };

  const get_applicants_score = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(
      `hrms_initiate_assessments/consolidated_applicant_scores/${company_id}`
    ).then((res) => {
      setFullScreenLoader(false);
      let data = Apiconnect.decrypt_obj(res.data.data);
      setApplicant_score(data);
      setFilted(data);
    });
  };

  let test_header_arr = [
    "#",
    "Applicant Name",
    "Total Questions",
    "Total Marks",
    "Total Attempted",
    "Total Correct",
    "Total InCorrect",
    "Total Obtained",
    "Percentage On Total",
  ];
  let interview_header_arr = [
    "#",
    "Applicant Name",
    "Ability to Communicate/Listen",
    "Critical Thinking",
    "Subject Knowledge",
    "Exposure to the job Profile",
    "Empathy",
    "Expression",
    "Body Language",
    "Attitude towards job",
    "Awareness of latest systems",
    "Network with Relevant people",
    "Presence On Social Media",
    "Parent Connect",
    "Percentage Score",
  ];
  let interview_leadership_arr = [
    "#",
    "Applicant Name",
    "Ability to Communicate",
    "Critical Thinking",
    "Understanding the school Processess",
    "Digital Understanding",
    "Empathy",
    "International Exposure",
    "Vision",
    "Liasioning with District Officials",
    "Parent Connect",
    "Education World",
    "People Management",
    "Awareness about Cambridge or IB",
    "Presence On Social Media",
    "Percentage Score",
  ];
  let demo_class_arr = [
    "#",
    "Applicant Name",
    "Planning Q-1",
    "Plannnig Q-2",
    "Plannnig Q-3",
    "Planning Total",
    "Disposition Q-1",
    "Disposition Q-2",
    "Disposition Q-3",
    "Disposition Total",
    "Clarity Q-1",
    "Clarity Q-2",
    "Clarity Q-3",
    "Clarity Total",
    "Questioning Q-1",
    "Questioning Q-2",
    "Questioning Q-3",
    "Questioning Total",
    "Engagement Q-1",
    "Engagement Q-2",
    "Engagement Q-3",
    "Engagement Total",
    "Assessment Q-1",
    "Assessment Q-2",
    "Assessment Q-3",
    "Assessment Total",
    "Closure Q-1",
    "Closure Q-2",
    "Closure Q-3",
    "Closure Total",
    "Team Work Q-1",
    "Team Work Q-2",
    "Team Work Q-3",
    "Team Work Total",
    "Lesson Plan",
    "Total Percentage",
  ];

  console.log(filtered);

  let assessment_arr = filtered.filter(
    (item) => item.assessment_list.length > 0
  );
  console.log(applicant_score);

  return (
    <>
      <div className="content-page" style={pageStyles.main_bg}>
        {fullScreenLoader ? (
          <FullScreenLoading loading loaderColor="#3498db" />
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="iq-card" style={pageStyles.main_container}>
                <div className="iq-card-header" style={pageStyles.card_head}>
                  <h4 className="text-white">Consolidated Candidate Sheet</h4>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <select
                        className="form-control text-center"
                        onChange={(e) => {
                          let filtered_list = applicant_score.filter(
                            (item, index) =>
                              item.applied_position_id == e.target.value
                          );
                          setFilted(filtered_list);
                        }}
                      >
                        <option value="" disabled selected>
                          Select Vacancy
                        </option>
                        {vacancy_list.map((item, index) => {
                          return (
                            <option key={index} value={item.position_id}>
                              {item.position_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control text-center"
                        name="assessment"
                        value={assessment}
                        onChange={(e) => setAssessment(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select Assessment
                        </option>
                        <option value="">All</option>
                        <option value={1}>Test</option>
                        <option value={2}>Interview</option>
                        <option value={3}>Interview Leadership</option>
                        <option value={4}>Demo Class</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3">
                      <input
                        type="text text-center"
                        placeholder="Search Applicant"
                        className="form-control"
                        name="search"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value.toLocaleLowerCase());
                          let filteredList = applicant_score.filter((item) =>
                            item.applicant_name.toLowerCase().includes(search)
                          );
                          setFilted(filteredList);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    {/* <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th className="text-center">#</th>
                              <th className="text-center">Name</th>
                              <th className="text-center">Position</th>
                              <th className="text-center">Asssessment Score Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {applicant_score.map((item, index) => {
                              let st = "";
                              if (item.assessment_list.length > 0) {
                                st = <span className="badge badge-pill badge-success">Assessment Score Available</span>;
                              } else {
                                st = <span className="badge badge-pill badge-warning">Panelist Not Scored Yet</span>;
                              }
                              return (
                                <tr>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-center">
                                    {item.applicant_name} <br />
                                    <Link to={`initiate_assessments/${item.applicant_id}`}>View Assessment Details</Link>
                                  </td>
                                  <td className="text-center">{item.applied_position}</td>
                                  <td className="text-center">{st}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                  </div>
                  <hr />
                  {assessment === "" && (
                    <span className="badge badge-secondary">
                      Select Assessment To View Consolidated Score
                    </span>
                  )}
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered normal_shadow min">
                          <thead className="thead-light">
                            {assessment == "" && (
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Applicant Name</th>
                              </tr>
                            )}
                            {assessment == 1 && (
                              <tr>
                                {test_header_arr.map((item, index) => {
                                  return <th key={index}>{item}</th>;
                                })}
                              </tr>
                            )}
                            {assessment == 2 && (
                              <tr>
                                {interview_header_arr.map((item, index) => {
                                  return <th key={index}>{item}</th>;
                                })}
                              </tr>
                            )}
                            {assessment == 3 && (
                              <tr>
                                {interview_leadership_arr.map((item, index) => {
                                  return <th key={index}>{item}</th>;
                                })}
                              </tr>
                            )}
                            {assessment == 4 && (
                              <tr>
                                {demo_class_arr.map((item, index) => {
                                  return <th key={index}>{item}</th>;
                                })}
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {assessment_arr.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-center">
                                    {item.applicant_name}
                                  </td>
                                  {item.assessment_list.map((item1, index1) => {
                                    if (item1.hsa_form_id == assessment) {
                                      let score_data = "";
                                      if (assessment == 1) {
                                        score_data = (
                                          <React.Fragment>
                                            <td className="text-center">
                                              {item1.test_score.total_questions}
                                            </td>
                                            <td className="text-center">
                                              {item1.test_score.total_marks}
                                            </td>
                                            <td className="text-center">
                                              {item1.test_score.total_attempted.toFixed(
                                                2
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item1.test_score.total_correct.toFixed(
                                                2
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item1.test_score.total_incorrect.toFixed(
                                                2
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item1.test_score.total_obtained.toFixed(
                                                2
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item1.test_score.total_percentage.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                          </React.Fragment>
                                        );
                                      } else if (assessment == 2) {
                                        score_data = (
                                          <React.Fragment>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_communication
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_critical_thinking
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_sub_knowledge
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_exposure
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_empathy
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_expression
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_bodyLang
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_attitude
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_awerness
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_networking
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_social
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.interview_score
                                                    .total_avg_parentConnect
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              {item1.interview_score.total_avg_scorePercentage.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                          </React.Fragment>
                                        );
                                      } else if (assessment == 3) {
                                        score_data = (
                                          <React.Fragment>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_communication
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_critical_thinking
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_school_process
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_digital
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_empathy
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_international_exposure
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_vision
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_liasioning
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_parent_connect
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_edu_world
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_ppl_management
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_awerness_ib
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1
                                                    .interview_leadership_score
                                                    .total_avg_social_presence
                                                }
                                                defaultValue={0}
                                                max={5}
                                              />
                                            </td>
                                            <td className="text-center">
                                              {item1.interview_leadership_score.total_avg_score_percentage.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                          </React.Fragment>
                                        );
                                      } else if (assessment == 4) {
                                        score_data = (
                                          <React.Fragment>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_planning_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_planning_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_planning_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_planning_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_disposition_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_disposition_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_disposition_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_disposition_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_clarity_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_clarity_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_clarity_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_clarity_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_questioning_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_questioning_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_questioning_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_questioning_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_engagement_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_engagement_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_engagement_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_engagement_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_assessment_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_assessment_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_assessment_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_assessment_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_closure_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_closure_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_closure_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_closure_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_tw_1
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_tw_2
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_tw_3
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_tw_total.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                            <td className="text-center">
                                              <RatingS
                                                name="hpils_communication"
                                                value={
                                                  item1.demoClass_Score
                                                    .total_avg_documentation
                                                }
                                                defaultValue={0}
                                                max={4}
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                backgroundColor: "#F8F8FF",
                                              }}
                                            >
                                              {item1.demoClass_Score.total_avg_total_score.toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                      return (
                                        <React.Fragment key={index1}>
                                          {score_data}
                                        </React.Fragment>
                                      );
                                    }
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rm_consolidated_candidate_score;
