import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';


const Employee_manage_info = (props) => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 
    let company_id = Apiconnect.get_company_id()

    // let emp_id = Apiconnect.decrypt(props.match.params.id);
    let emp_id = props.match.params.id;

    const alert = useAlert();
    let history = useHistory();
    const [btn_data, setBtnId] = useState([]);
    const { btn_id } = btn_data;

    const [info2, setInfo2] = useState({
        employee_mst_id: emp_id, present_address_one: '', present_address_two: '', present_landmark: '', present_area_id: '', present_city_id: '',
        present_state_id: '', present_country_id: 1, present_zip: '', parmanent_address_one: '', parmanent_address_two: '',
        parmanent_landmark: '', parmanent_area_id: '', parmanent_city_id: '', parmanent_state_id: '', parmanent_country_id: 1,
        parmanent_zip: '', phone_secondary: '', email_secondary: '', blood_group: '', religion: '', caste: '', nationality: '', marital_status: '',
        anniversary_date: '', present_area: '', parmanent_area: '',fax:'',fax1:'',spouse_name:'',father_name:'',

        emergency_contact_person: '', emergency_contact_number: '', emergency_relationship_person_id: '',
        emergency_contact_person2: '', emergency_contact_number2: '', emergency_relationship_person_id2: '',

        pan: '', passport_no: '', aadhaar: '', payment_mode: ''
    });


    const { employee_mst_id, present_address_one, present_address_two, present_landmark, present_area_id, present_city_id,
        present_state_id, present_country_id, present_zip, parmanent_address_one, parmanent_address_two,
        parmanent_landmark, parmanent_area_id, parmanent_city_id, parmanent_state_id, parmanent_country_id,
        parmanent_zip, phone_secondary, email_secondary, blood_group, religion, caste, nationality, marital_status,
        anniversary_date, present_area, parmanent_area,fax,fax1,spouse_name,father_name,

        emergency_contact_person, emergency_contact_number, emergency_relationship_person_id,
        emergency_contact_person2, emergency_contact_number2, emergency_relationship_person_id2,

        pan, passport_no, aadhaar, payment_mode } = info2;


    const onInputChange2 = e => {

        if (e.target.name === 'present_state_id') {
            // console.log(e.target.value);
            getCity(e.target.value);
        }
        if (e.target.name === 'parmanent_state_id') {
            //  console.log(e.target.value);
            getCity_parmanent(e.target.value);
        }
        setInfo2({ ...info2, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
        e.preventDefault();

        if (btn_id > 0) {

            console.log('in edit function.....');
            info2.employee_mst_id = emp_id;

            if (anniversaryDate === null) {
                info2.anniversary_date = anniversaryDate;
            } else {
                info2.anniversary_date = format(anniversaryDate, 'yyyy-MM-dd');
            }



            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id, log_name: "Employee Other Details", company_id: company_id};
            Apiconnect.postData("employee_info/update", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: '', present_address_one: '', present_address_two: '', present_landmark: '', present_area_id: '', present_city_id: '',
                    present_state_id: '', present_country_id: 1, present_zip: '', parmanent_address_one: '', parmanent_address_two: '',
                    parmanent_landmark: '', parmanent_area_id: '', parmanent_city_id: '', parmanent_state_id: '', parmanent_country_id: 1,
                    parmanent_zip: '', phone_secondary: '', email_secondary: '', blood_group: '', religion: '', caste: '', nationality: '', marital_status: '',
                    anniversary_date: '', present_area: '', parmanent_area: '',fax:'',fax1:'',spouse_name:'',father_name:'',

                    emergency_contact_person: '', emergency_contact_number: '', emergency_relationship_person_id: '',
                    emergency_contact_person2: '', emergency_contact_number2: '', emergency_relationship_person_id2: '',


                    pan: '', passport_no: '', aadhaar: '', payment_mode: ''
                });
                alert.success(response.data.message);
                history.push(`/employee_manage_bank/${emp_id}`);
            });

        } else {

            console.log('in add function.....');
            info2.employee_mst_id = emp_id;

            if (anniversaryDate === null) {
                info2.anniversary_date = anniversaryDate;
            } else {
                info2.anniversary_date = format(anniversaryDate, 'yyyy-MM-dd');
            }

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id };
            Apiconnect.postData("employee_info/create", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: '', present_address_one: '', present_address_two: '', present_landmark: '', present_area_id: '', present_city_id: '',
                    present_state_id: '', present_country_id: 1, present_zip: '', parmanent_address_one: '', parmanent_address_two: '',
                    parmanent_landmark: '', parmanent_area_id: '', parmanent_city_id: '', parmanent_state_id: '', parmanent_country_id: 1,
                    parmanent_zip: '', phone_secondary: '', email_secondary: '', blood_group: '', religion: '', caste: '', nationality: '', marital_status: '',
                    anniversary_date: '', present_area: '', parmanent_area: '',fax:'',fax1:'',spouse_name:'',father_name:'',

                    emergency_contact_person: '',
                    emergency_contact_number: '',
                    emergency_relationship_person_id: '',


                    emergency_contact_person2: '',
                    emergency_contact_number2: '',
                    emergency_relationship_person_id2: '',


                    pan: '', passport_no: '', aadhaar: '', payment_mode: ''
                });
                alert.success(response.data.message);
                history.push(`/employee_manage_bank/${emp_id}`);
            });

        }


    };

    const [anniversaryDate, setAnniversaryDate] = useState(null);

    useEffect(() => { getArea(); getState(); getCountry(); getRelationship(); }, []);
    const [areaList, setArea] = useState([]);
    const [cityList, setCity] = useState([]);
    const [cityList_Parmanent, setCity_Parmanent] = useState([]);
    const [stateList, setState] = useState([]);
    const [countryList, setCountry] = useState([]);
    const [relationshipList, setRelationship] = useState([]);


    const getArea = () => {
        Apiconnect.getData("area/getall").then((response) => {
            setArea(response.data.data.reverse());
        });
    };

    const getCity = (id) => {
        Apiconnect.getData(`city/getCityByState/${id}`).then((response) => {
            setCity(response.data.data.reverse());
        });
    };

    const getCity_parmanent = (id) => {
        Apiconnect.getData(`city/getCityByState/${id}`).then((response) => {
            setCity_Parmanent(response.data.data.reverse());
        });
    };

    console.log(cityList);

    const getState = () => {
        Apiconnect.getData("state/getall").then((response) => {
            setState(response.data.data.reverse());
        });
    };

    const getCountry = () => {
        Apiconnect.getData("country/getall").then((response) => {
            setCountry(response.data.data.reverse());
        });
    };

    const getRelationship = () => {
        Apiconnect.getData("common/getallrelationshipperson").then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setRelationship(_xtract.reverse());
        });
    };

    useEffect(() => { getItem(); }, []);


    const getItem = () => {
        if (emp_id > 0) {
            Apiconnect.getData(`employee_info/get/${emp_id}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                if (_xtract.length > 0) {
                    setBtnId({ btn_id: 1 });

                    setInfo2({
                        employee_mst_id: _xtract[0].employee_mst_id, present_address_one: _xtract[0].present_address_one, present_address_two: _xtract[0].present_address_two,
                        present_landmark: _xtract[0].present_landmark, present_area_id: _xtract[0].present_area_id, present_city_id: _xtract[0].present_city_id,
                        present_state_id: _xtract[0].present_state_id, present_country_id: _xtract[0].present_country_id, present_zip: _xtract[0].present_zip,
                        parmanent_address_one: _xtract[0].parmanent_address_one, parmanent_address_two: _xtract[0].parmanent_address_two,
                        parmanent_landmark: _xtract[0].parmanent_landmark, parmanent_area_id: _xtract[0].parmanent_area_id, parmanent_city_id: _xtract[0].parmanent_city_id,
                        parmanent_state_id: _xtract[0].parmanent_state_id, parmanent_country_id: _xtract[0].parmanent_country_id, parmanent_zip: _xtract[0].parmanent_zip,
                        email_secondary: _xtract[0].email_secondary, blood_group: _xtract[0].blood_group,
                        religion: _xtract[0].religion, caste: _xtract[0].caste, nationality: _xtract[0].nationality,
                        marital_status: _xtract[0].marital_status, present_area: _xtract[0].present_area, parmanent_area: _xtract[0].parmanent_area,
                        fax:_xtract[0].fax,fax1:_xtract[0].fax1,spouse_name:_xtract[0].spouse_name,father_name:_xtract[0].father_name,
                        emergency_contact_person: _xtract[0].emergency_contact_person,
                        emergency_relationship_person_id: _xtract[0].emergency_relationship_person_id,
                        emergency_contact_number: _xtract[0].emergency_contact_number,

                        emergency_contact_person2: _xtract[0].emergency_contact_person2,
                        emergency_relationship_person_id2: _xtract[0].emergency_relationship_person_id2,
                        emergency_contact_number2: _xtract[0].emergency_contact_number2,



                        pan: _xtract[0].pan, passport_no: _xtract[0].passport_no,
                        aadhaar: _xtract[0].aadhaar, payment_mode: _xtract[0].payment_mode, id: _xtract[0].id
                    });



                    if (_xtract[0].present_state_id !== '') {
                        // console.log(e.target.value);
                        getCity(_xtract[0].present_state_id);
                    }
                    if (_xtract[0].parmanent_state_id !== '') {
                        //  console.log(e.target.value);
                        getCity_parmanent(_xtract[0].parmanent_state_id);
                    }

                    if (_xtract[0].anniversary_date === null || _xtract[0].anniversary_date === '0000-00-00') {
                        setAnniversaryDate(null);
                    } else {
                        setAnniversaryDate(new Date(_xtract[0].anniversary_date));
                    }

                } else {
                    setBtnId({ btn_id: null });
                }


            });
        }


    };

    function refreshPage() {
        window.location.reload();
    }

    function nextPage() {
        history.push(`/employee_manage_employement/${emp_id}`);
    }
    

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="iq-card">

                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Info <span style={{ fontSize: '15px', color: 'blue' }}>Stage 2 to 6</span></h4>
                            </div>
                        </div>

                        <div className="iq-card-body">

                        <div className="bs-example">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${emp_id}`} >Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link active" data-toggle="tab" to={`/employee_manage_info/${emp_id}`} >Other Details</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_bank/${emp_id}`} >Financial</Link>
                                    </li> */}
                                    <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_employement/${emp_id}`} >Employement Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/PreviousEmployment/${emp_id}`} >Previous Employment</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_reimbursement/${emp_id}`} >Reimbursement Info</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_sal/${emp_id}`} >Salary (Monthly Wages)</Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_qualification/${emp_id}`} >Educational Qualification</Link>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={e => onSubmit(e)}>
                                <div className="border">
                                    <div className="bbox" style={{ padding: '10px' }}>
                                        <h4>Present Address</h4>
                                        <div className="row ">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>House No</label>
                                                    <input type="text" className="form-control validate[required]" name="present_address_one" placeholder="House No" value={present_address_one} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Additional Address</label>
                                                    <input type="text" className="form-control validate[required]" name="present_address_two" placeholder="Additional Address" value={present_address_two} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>



                                            {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Area</label>
                                                    <select className="form-control" name="present_area_id" value={present_area_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select Area</option>
                                                        {areaList.map((val, key) => {
                                                            return (
                                                                <option key={key} value={val.id}>{val.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div> */}

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <select className="form-control" name="present_state_id" value={present_state_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select State</option>
                                                        {
                                                            stateList.map((val, key) => {
                                                                return (
                                                                    <option key={key} value={val.id}>{val.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <select className="form-control" name="present_city_id" value={present_city_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select City</option>
                                                        {cityList.map((val, key) => {
                                                            return (
                                                                <option key={key} value={val.id}>{val.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>


                                        </div>


                                        <div className="row">


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Area</label>
                                                    <input type="text" className="form-control validate[required]" name="present_area" placeholder="Enter Area" value={present_area} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>


                                            {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <select className="form-control" name="present_country_id" value={present_country_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select Country</option>
                                                        {
                                                            countryList.map((val, key) => {
                                                                return (
                                                                    <option key={key} value={val.id}>{val.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Postal Code</label>
                                                    <input type="text" className="form-control validate[required]" name="present_zip" placeholder="Postal Code" value={present_zip} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Landmark</label>
                                                    <input type="text" className="form-control validate[required]" name="present_landmark" placeholder="Landmark" value={present_landmark} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Fax</label>
                                                    <input type="text" className="form-control validate[required]" name="fax" placeholder="Fax" value={fax} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="bbox" style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
                                        <h4>Permanent Address</h4>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>House No</label>
                                                    <input type="text" className="form-control validate[required]" name="parmanent_address_one" placeholder="House No" value={parmanent_address_one} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Additional Address</label>
                                                    <input type="text" className="form-control validate[required]" name="parmanent_address_two" placeholder="Additional Address" value={parmanent_address_two} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>



                                            {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Area</label>
                                                    <select className="form-control" name="parmanent_area_id" value={parmanent_area_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select Area</option>
                                                        {areaList.map((val, key) => {
                                                            return (
                                                                <option key={key} value={val.id}>{val.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div> */}

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <select className="form-control" name="parmanent_state_id" value={parmanent_state_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select State</option>
                                                        {
                                                            stateList.map((val, key) => {
                                                                return (
                                                                    <option key={key} value={val.id}>{val.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <select className="form-control" name="parmanent_city_id" value={parmanent_city_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select City</option>
                                                        {cityList_Parmanent.map((val, key) => {
                                                            return (
                                                                <option key={key} value={val.id}>{val.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                           

                                        </div>

                                        <div className="row">

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Area</label>
                                                    <input type="text" className="form-control validate[required]" name="parmanent_area" placeholder="Enter Area" value={parmanent_area} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <select className="form-control" name="parmanent_country_id" value={parmanent_country_id} onChange={e => onInputChange2(e)}>
                                                        <option value="">Select Country</option>
                                                        {
                                                            countryList.map((val, key) => {
                                                                return (
                                                                    <option key={key} value={val.id}>{val.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Postal Code</label>
                                                    <input type="text" className="form-control validate[required]" name="parmanent_zip" placeholder="Postal Code" value={parmanent_zip} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Landmark</label>
                                                    <input type="text" className="form-control validate[required]" name="parmanent_landmark" placeholder="Landmark" value={parmanent_landmark} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Fax</label>
                                                    <input type="text" className="form-control validate[required]" name="fax1" placeholder="Fax" value={fax1} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ padding: '10px' }}>

                                    <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Father Name</label>
                                                    <input type="text" className="form-control validate[required]" name="father_name" placeholder="Father Name" value={father_name} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Marital Status</label>
                                                    <select className="form-control" name="marital_status" value={marital_status} onChange={e => onInputChange2(e)}>
                                                        <option value='' >Select Marital Status</option>
                                                        <option value='married'>Married </option>
                                                        <option value='unmarried'>Unmarried</option>
                                                        <option value='widow'>Widow</option>
                                                        <option value='separated'>Separated</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                 <div className="form-group">
                                                    <label>Anniversary Date</label>
                                                    <DatePicker
                                                        selected={anniversaryDate}
                                                        onChange={e => setAnniversaryDate(e)}
                                                        className="form-control"
                                                        dateFormat='yyyy-MM-dd'
                                                        placeholderText="Anniversary Date"
                                                        name="anniversary_date"
                                                        dropdownMode="select"
                                                    />

                                                </div>
                                                </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Spouse Name</label>
                                                    <input type="text" className="form-control validate[required]" name="spouse_name" placeholder="Spouse Name" value={spouse_name} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Phone Secondary</label>
                                                    <input type="text" className="form-control validate[required]" name="phone_secondary" placeholder="Phone Secondary" value={phone_secondary} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Email Secondary</label>
                                                    <input type="email" className="form-control validate[required]" name="email_secondary" placeholder="Email Secondary" value={email_secondary} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Blood Group</label>
                                                    <input type="text" className="form-control validate[required]" name="blood_group" placeholder="Blood Group" value={blood_group} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Passport Number</label>
                                                <input type="text" className="form-control validate[required]" name="passport_no" placeholder="Passport Number" value={passport_no} onChange={e => onInputChange2(e)} />
                                            </div>
                                        </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Religion</label>
                                                    <input type="text" className="form-control validate[required]" name="religion" placeholder="Religion" value={religion} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {/* <label>Caste</label> */}
                                                    {/* <input type="text" className="form-control validate[required]" name="caste" placeholder="Caste" value={caste} onChange={e => onInputChange2(e)} /> */}
                                                    <div className="form-group">
                                                    <label>Category</label>
                                                    <select className="form-control" name="caste" value={caste} onChange={e => onInputChange2(e)}>
                                                        <option value='' >Select Category</option>
                                                        <option value='general'>General</option>
                                                        <option value='obc'>OBC</option>
                                                        <option value='sc'>SC</option>
                                                        <option value='st'>ST</option>
                                                        <option value='other'>Other</option>
                                                    </select>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Nationality</label>
                                                    <input type="text" className="form-control validate[required]" name="nationality" placeholder="Nationality" value={nationality} onChange={e => onInputChange2(e)} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Anniversary Date</label>
                                                    <DatePicker
                                                        selected={anniversaryDate}
                                                        onChange={e => setAnniversaryDate(e)}
                                                        className="form-control"
                                                        dateFormat='yyyy-MM-dd'
                                                        placeholderText="Anniversary Date"
                                                        name="anniversary_date"
                                                    />

                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="border">

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Emergency Contact Person</label>
                                                <input type="text" className="form-control validate[required]" name="emergency_contact_person" placeholder="Emergency Contact Person" value={emergency_contact_person} onChange={e => onInputChange2(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Emergency Relationship Person</label>
                                                <select className="form-control" name="emergency_relationship_person_id" value={emergency_relationship_person_id} onChange={e => onInputChange2(e)}>
                                                    <option >Select Relationship</option>
                                                    {
                                                        relationshipList.map((val, kay) => {
                                                            return (
                                                                <option kay={kay} value={val.id}>{val.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Emergency Contact Number</label>
                                                <input type="text" className="form-control validate[required]" name="emergency_contact_number" placeholder="Emergency Contact Number" value={emergency_contact_number} onChange={e => onInputChange2(e)} />
                                            </div>
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Emergency Contact Person (2)</label>
                                                <input type="text" className="form-control validate[required]" name="emergency_contact_person2" placeholder="Emergency Contact Person" value={emergency_contact_person2} onChange={e => onInputChange2(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Emergency Relationship Person (2)</label>
                                                <select className="form-control" name="emergency_relationship_person_id2" value={emergency_relationship_person_id2} onChange={e => onInputChange2(e)}>
                                                    <option >Select Relationship</option>
                                                    {
                                                        relationshipList.map((val, kay) => {
                                                            return (
                                                                <option kay={kay} value={val.id}>{val.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Emergency Contact Number (2)</label>
                                                <input type="text" className="form-control validate[required]" name="emergency_contact_number2" placeholder="Emergency Contact Number" value={emergency_contact_number2} onChange={e => onInputChange2(e)} />
                                            </div>
                                        </div>

                                    </div>








                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" value={btn_id} className="btn btn-primary mr-2"> {btn_id ? 'Update' : 'Submit'} </button>
                                        {/* <button type="button" className="btn iq-bg-danger" onClick={ refreshPage }> cancle </button> 
                                      <button type="button" className="btn iq-bg-danger" onClick={ nextPage }> Next </button>  */}
                                        <button type="button" className="btn btn-success mr-2" onClick={nextPage}> Next </button>
                                        <Link style={{ float: 'right' }} className="btn btn-secondary" to="/employee_manage_list" >Back to List</Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )



}
export default Employee_manage_info