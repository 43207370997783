import React, { useState, useEffect } from "react";
import moment from "moment";
import { range } from "moment-range";
import "../pages/calendar.css";
import Apiconnect from "../../services/Apiconnect.js";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../components/imports.js";

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.localuser = JSON.parse(localStorage.getItem("user"));
    this.emp = Apiconnect.decrypt(this.localuser.id);
    this.get_holiday(new Date().getFullYear(), new Date().getMonth() + 1);
    this.get_Attendance();
    this.getWeekList();
    this.getleaveList();
    this.getmonthwisedata(new Date().getFullYear(), new Date().getMonth() + 1);
  }

  weekdayshort = moment.weekdaysShort();

  state = {
    showCalendarTable: true,
    showMonthTable: false,
    dateObject: moment(),
    allmonths: moment.months(),
    showYearNav: false,
    selectedDay: null,
    holiday: [],
    attendance: [],
    weekly_of: [],
    leave: [],
    current_month: new Date().getMonth() + 1,
    current_year: new Date().getFullYear(),
    info: [{ id: "", month: "", year: "" }],
    api_month: new Date().getMonth() + 1,
    api_year: new Date().getFullYear(),
    number: { 1: "01", 2: "02", 3: "03", 4: "04", 5: "05", 6: "06", 7: "07", 8: "08", 9: "09" },
    monthwisedata: [],
    attendance_emp_cnt: [],
    isOpen: false,
    daily_punches: [],
  };

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  getmonthwisedata = async (year, month) => {
    ReactTooltip.rebuild();

    var mn = this.state.number[month];
    if (mn == undefined) {
      var mn = month;
    }

    await Apiconnect.getData(`employee_dailydata/getattendancemonthwise_admin/${this.emp}/${year}/${mn}`).then((response) => {
      let ern = response.data.data1;
      this.setState({ monthwisedata: ern });
    });
    await Apiconnect.getData(`employee_dailydata/getpresentattendancemonthwise_admin_cnt/${this.emp}/${year}/${mn}`).then((response) => {
      let ern = response.data.data1;
      // console.log(ern);
      this.setState({ attendance_emp_cnt: ern });
    });
  };

  get_holiday = (year, month) => {
    ReactTooltip.rebuild();

    var mn = this.state.number[month];
    if (mn == undefined) {
      var mn = month;
    }

    //  Apiconnect.getData(`company_holiday/getallmonthwise/${Apiconnect.get_company_id()}/${year+'-'+mn}`).then((response) => {
    Apiconnect.getData(`company_holiday/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data);
      //    console.log(ern);
      //    console.log(year+'-'+mn);
      this.setState({ holiday: ern });
    });
  };

  getleaveList = () => {
    var emp_id = this.emp;
    Apiconnect.getData(`employee_leave/getAllLeavemonth/${emp_id}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data);
      this.setState({ leave: ern });
      //   console.log(ern);
    });
  };

  get_Attendance = () => {
    // const ern = [];
    var emp_id = this.emp;

    Apiconnect.getData(`employee_dailydata/getattendancedata/${emp_id}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data);
      this.setState({ attendance: ern });
    });
  };

  getWeekList = () => {
    Apiconnect.getData(`company_weekly_off/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      this.setState({ weekly_of: _xtract });
    });
  };

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };
  year = () => {
    return this.state.dateObject.format("Y");
  };
  currentDay = () => {
    return this.state.dateObject.format("D");
  };
  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject).startOf("month").format("d"); // Day of week 0...1..5...6
    return firstDay;
  };
  month = () => {
    return this.state.dateObject.format("MMMM");
  };
  showMonth = (e, month) => {
    this.setState({
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable,
    });
  };
  setMonth = (month) => {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable,
    });
    this.setState({ current_month: Number(monthNo) + 1 });
  };
  MonthList = (props) => {
    let months = [];
    props.data.map((data) => {
      let monthNo = this.state.allmonths.indexOf(data);
      if (new Date().getMonth() + 1 > monthNo) {
        months.push(
          <td
            key={data}
            className="calendar-month"
            onClick={(e) => {
              this.setMonth(data);
            }}
          >
            <span>{data}</span>
          </td>
        );
      }
    });

    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let monthlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="thead-light">
        <thead className="thead-light">
          <tr>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    );
  };

  showYearEditor = () => {
    this.setState({
      showYearNav: true,
      showCalendarTable: !this.state.showCalendarTable,
    });
  };

  onPrev = () => {
    ReactTooltip.rebuild();

    let sel_current_month1 = this.state.current_month;
    let sel_current_year1 = this.state.current_year;

    let sel_current_month2 = sel_current_month1 - 1;
    let sel_current_year2 = sel_current_year1;

    if (sel_current_month2 <= 0) {
      sel_current_year2 = sel_current_year1 - 1;
      sel_current_month2 = 12;
      this.setState({ current_year: Number(sel_current_year2) });
    }

    //console.log(Number(sel_current_month2)+' '+sel_current_year2);

    let curr = "";
    if (this.state.showMonthTable == true) {
      curr = "year";
      this.setState({ current_year: Number(sel_current_year2) });
      this.getmonthwisedata(Number(sel_current_year2), sel_current_month1);
    } else {
      curr = "month";
      this.setState({ current_month: Number(sel_current_month2) });
      this.getmonthwisedata(sel_current_year2, Number(sel_current_month2));
    }
    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr),
    });
  };
  onNext = () => {
    ReactTooltip.rebuild();

    let sel_current_month1 = this.state.current_month;
    let sel_current_year1 = this.state.current_year;

    if (sel_current_month1 == 12) {
      sel_current_month1 = 0;
      sel_current_year1 = sel_current_year1 + 1;

      this.setState({ current_year: Number(sel_current_year1) });
    }

    //console.log('Next == '+sel_current_month1+' == '+sel_current_year1);

    let curr = "";
    if (this.state.showMonthTable == true) {
      curr = "year";
      this.setState({ current_year: Number(sel_current_year1) });
      this.getmonthwisedata(Number(sel_current_year1), this.state.current_month);
    } else {
      if (new Date().getMonth() + 1 != this.state.current_month) {
        curr = "month";
        this.setState({ current_month: Number(sel_current_month1) + 1 });
        this.getmonthwisedata(sel_current_year1, Number(sel_current_month1) + 1);
      }
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr),
    });
  };
  setYear = (year) => {
    // alert(year)
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);

    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearNav: !this.state.showYearNav,
      showMonthTable: !this.state.showMonthTable,
    });
    this.setState({ current_year: year });
  };
  onYearChange = (e) => {
    this.setYear(e.target.value);
  };
  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY"));
      currentDate = moment(currentDate).add(1, "year");
    }
    return dateArray;
  }
  YearTable = (props) => {
    let months = [];
    let nextten = moment().set("year", props).add("year", 1).format("Y");

    let tenyear = this.getDates(props, nextten);

    tenyear.map((data) => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={(e) => {
            this.setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);

    let yearlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Year</th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    );
  };
  onDayClick = (e, d) => {
    let id = e.target.getAttribute("data-ids");
    if (id != "") {
      let text = id;
      const myArray = text.split(",").map(Number);
      var crntdate = this.state.current_year + "-" + this.state.current_month + "-" + d;

      const data = { date: crntdate, ids: myArray };
      Apiconnect.postData(`employee_dailydata/getalldailypunches_ids`, data).then((response) => {
        let ern = response.data.data;
        this.setState({ daily_punches: ern });
        this.openModal();
      });
    }

    this.setState(
      {
        selectedDay: d,
      },
      () => {
        //console.log("SELECTED DAY: ", this.state.selectedDay);
      }
    );
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        //    marginRight: '-50%',
        transform: "translate(-50%, -50%)",
      },
    };

    // -----------> Weekly Of day
    const week = [
      { id: "0", name: "Sunday" },
      { id: "1", name: "Monday" },
      { id: "2", name: "Tuesday" },
      { id: "3", name: "Wednesday" },
      { id: "4", name: "Tuersday" },
      { id: "5", name: "Friday" },
      { id: "6", name: "Saterday" },
    ];

    var weekly_off_days = new Array();
    this.state.weekly_of.map((val, key) => {
      var week_id = "";
      if (week[val.day_of_week].id == val.day_of_week) {
        var week_id = week[val.day_of_week].id;
      }
      // console.log(week_id);
      var makeDate = new Date();
      var year = this.state.current_year;
      var month = this.state.current_month;
      var days = new Date(year, month, 0).getDate();
      var d = new Date();

      if (val.holiday_typ === "All") {
        for (var i = 1; i <= days; i++) {
          var newDate = new Date(year, Number(month) - 1, i);
          //   console.log(newDate);
          if (newDate.getDay() == week_id) {
            weekly_off_days.push(i);
          }
        }
      } else {
        var week_cnt = 1;
        for (var i = 1; i <= days; i++) {
          var newDate = new Date(year, Number(month) - 1, i);
          if (newDate.getDay() == week_id) {
            if (val.holiday_typ == week_cnt) {
              weekly_off_days.push(i);
            }
            week_cnt++;
          }
        }
      }
    });
    //console.log(weekly_off_days);

    // ----------->End Weekly Of day

    let weekdayshortname = this.weekdayshort.map((day) => {
      return <th key={day}>{day}</th>;
    });
    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day empty">{""}</td>);
    }

    let daysInMonth = [];

    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay = d == this.currentDay() ? "today" : "";
      let color_day = "";

      // -----------> Weekly Of day
      var weekly_day = weekly_off_days.some((item) => Number(d) === Number(item));

      // if(weekly_day === true){
      //     color_day = 'absent_days';
      // }

      // -----------> End Weekly Of day

      // -----------> Present day
      var present_day = this.state.attendance.some((item) => Number(d) === Number(item.day) && Number(this.state.current_month) === Number(item.month) && Number(this.state.current_year) === Number(item.year));

      // if(present_day === true){
      //      color_day = 'present_days';
      // }
      // ----------->End Present day

      // -----------> Holiday day
      var holiday_day = this.state.holiday.some((item) => Number(d) >= Number(format(new Date(item.start_date), "d")) && Number(d) <= Number(format(new Date(item.end_date), "d")) && Number(this.state.current_month) === Number(format(new Date(item.start_date), "M")) && Number(this.state.current_year) === Number(format(new Date(item.start_date), "Y")));

      // if(holiday_day === true){
      //     color_day = 'holiday_days';
      // }
      // ----------->End Holiday day

      var leave_day = this.state.leave.some((item) => Number(d) >= Number(format(new Date(item.leave_start_date1), "d")) && Number(d) <= Number(format(new Date(item.leave_end_date1), "d")) && Number(this.state.current_month) === Number(format(new Date(item.leave_start_date1), "M")) && Number(this.state.current_year) === Number(format(new Date(item.leave_start_date1), "Y")));

      // if(leave_day === true){
      //     color_day = 'leave_days';
      // }
      //  var container = '';

      var mn1 = this.state.number[this.state.current_month];
      if (mn1 == undefined) {
        var mn1 = this.state.current_month;
      }
      var d1 = this.state.number[d];
      if (d1 == undefined) {
        var d1 = d;
      }

      var yr = this.state.current_year + "-" + mn1 + "-" + d1;
      var mnwise = this.state.monthwisedata;
      var emp_present_cnt = this.state.attendance_emp_cnt;
      var title = "";
      var total_present = "";
      var employee_ids = "";

      //   console.log('yr == '+yr );
      emp_present_cnt.map((val) => {
        if (val[yr] != undefined) {
          if (val[yr].customStyles.container.backgroundColor == "#18b82d") {
            color_day = "present_days";
            total_present = val[yr].details.cnt + "/" + val[yr].details.total;
            employee_ids = val[yr].details.emp_present_ids;
          }
        }
      });

      mnwise.map((val) => {
        if (val[yr] != undefined) {
          if (val[yr].customStyles.container.backgroundColor == "red") {
            color_day = "absent_days";
          }
          if (val[yr].customStyles.container.backgroundColor == "#0ec9d2") {
            color_day = "holiday_days";
            title = val[yr].desc.title;
          }
        }
      });

      var pad2 = "";
      if (d.toString().length <= 1) {
        pad2 = "pad2";
        // console.log(d);
      }

      daysInMonth.push(
        <td key={d} className={`calendar-day ${currentDay}  pad`} style={{ lineHeight: "1.7" }}>
          <span
            data-tip={title}
            data-type="info"
            className={`${color_day} ${pad2}`}
            // <span  data-ids ={employee_ids}  data-tip={title} data-tip={total_present} data-type="info" className={`${color_day} ${pad2}`}
            data-ids={employee_ids}
            onClick={(e) => {
              this.onDayClick(e, d);
            }}
          >
            {d}
          </span>{" "}
          <br />
          {total_present ? (
            <span
              style={{ color: "#0084ff" }}
              data-ids={employee_ids}
              onClick={(e) => {
                this.onDayClick(e, d);
              }}
            >
              {total_present}
            </span>
          ) : (
            <span style={{ color: "white" }}>12</span>
          )}
        </td>
      );
    }
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr key={i}>{d}</tr>;
    });

    return (
      <>
        <div>
          <Modal isOpen={this.state.isOpen} style={customStyles} onRequestClose={() => this.closeModal()}>
            <div className="iq-card" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px" }}>
              <div className="iq-card-header d-flex justify-content-between model_head">
                <div className="iq-header-title">
                  <h4 className="card-title ">Daily Attendance</h4>
                </div>
              </div>

              <div className="iq-card-body">
                <table className="table table-striped table-hover" style={{ tableLayout: "fixed", "overflow-y": "scroll", display: "block", width: "100%", height: "300px" }}>
                  <thead className="thead-light">
                    <tr style={{ textAlign: "center" }}>
                      <th scope="col" style={{ width: "80px" }}>
                        #
                      </th>
                      <th scope="col" style={{ width: "70px" }}>
                        Profile
                      </th>
                      <th scope="col">Employee</th>
                      <th scope="col">Punches</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.daily_punches.map((val, key) => {
                      if (val.thumb == null) {
                        var img = "/images/user/1.jpg";
                      } else {
                        var img = process.env.React_App_BASE_URL_API + "/uploads/thumb/" + val.thumb;
                      }
                      return (
                        <tr style={{ textAlign: "center" }}>
                          <td scope="row">{key + 1}</td>
                          <td>
                            <div className="" style={{ display: "grid" }}>
                              <img src={img} alt="profile-img" className="prof" />
                            </div>
                          </td>
                          <td>{val.name}</td>
                          <td className="wrap">{val.punches}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="text_center">
                <button className="btn btn-info" onClick={() => this.closeModal()}>
                  close
                </button>
              </div>
            </div>
          </Modal>
        </div>

        {/* <div id="content-page" className="content-page">
                <div className="container-fluid"> */}

        <div className="row">
          <div className="tail-datetime-calendar">
            <div className="calendar-navi">
              <span
                onClick={(e) => {
                  this.onPrev();
                }}
                className="calendar-button button-prev"
              />
              {!this.state.showMonthTable && !this.state.showYearEditor && (
                <span
                  onClick={(e) => {
                    this.showMonth();
                  }}
                  className="calendar-label"
                >
                  {this.month()},
                </span>
              )}
              <span
                className="calendar-label"
                onClick={(e) => {
                  this.showYearEditor();
                }}
              >
                {this.year()}
              </span>

              <span
                onClick={(e) => {
                  this.onNext();
                }}
                className="calendar-button button-next"
              />
            </div>
            <div className="calendar-date">
              {this.state.showYearNav && <this.YearTable props={this.year()} />}
              {this.state.showMonthTable && <this.MonthList data={moment.months()} />}
            </div>

            {this.state.showCalendarTable && (
              <div className="calendar-date">
                <table className="calendar-day">
                  <thead className="thead-light">
                    <tr>{weekdayshortname}</tr>
                  </thead>
                  <tbody>{daysinmonth}</tbody>
                </table>
              </div>
            )}

            <div className="row" style={{ paddingTop: "25px", paddingLeft: "30px", paddingBottom: "10px" }}>
              <div className="sqr holiday"></div>
              <div style={{ paddingRight: "20px", paddingLeft: "5px", paddingTop: "3px" }}>Holiday</div>
              <ReactTooltip />
              <div className="sqr precent"></div>
              <div style={{ paddingRight: "15px", paddingLeft: "5px", paddingTop: "3px" }}>Attendance</div>
              <div className="sqr weekly_off"></div>
              <div style={{ paddingRight: "15px", paddingLeft: "5px", paddingTop: "3px" }}>Weekly Off</div>
              {/* <div className="sqr leave"></div>
                                        <div style={{paddingRight: '15px',paddingLeft: '5px',paddingTop: '3px'}}>Leave</div> */}
            </div>
          </div>
        </div>
        {/* </div>
                        </div> */}
      </>
    );
  }
}
