import React, { useState, useEffect, Component } from 'react';
import { useHistory } from "react-router-dom";
import Apiconnect from "../services/Apiconnect.js";
// import "./ems_pages.css";
import moment from 'moment'
import { format } from 'date-fns';
import * as html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Axios from 'axios';
import FullScreenLoading from 'react-fullscreen-loading';
import { createContext } from 'react';
import { renderToString } from 'react-dom/server';
import Combine_view_emp_salary_slip from "../components/combine_view_emp_salary_slip";

function Salary_slip(props) {
    let history = useHistory();

    let emp_id = props.match.params.id;
    let year = props.match.params.year;
    let month = props.match.params.month;
    let months = Number(month) - 1;

   const local_user = JSON.parse(localStorage.getItem('user'));
    
    const [salaryMonth, setSalaryMonthList] = useState([]);
    const [deduction_tot, setDeduction_tot] = useState(0);
    const [earning_tot, setEarning_tot] = useState(0);
    const [leavestypelist, setleavestypelist] = useState([]);
    const [debited_leaves, setdebited_leaves] = useState(0);
    const [paidDay, setPaidDay] = useState(0);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [logo, setlogo] = useState(null);
    const [companyData,setAddress] = useState(null)
    const [earning_head, setEarning_head] = useState([]);
    const [deduct_head, setDeduct_head] = useState([]) 

    return (
        <div className="container-fluid">
            <div className='row'>
                <div className="col-md-12">
                    
                    {/*===============strat_right_div==================*/}
                    <div className="col-lg-12">
                        <Combine_view_emp_salary_slip
                            options={{ 
                                title: 'Welcome'
                            }}
                            sel_user_id={emp_id} 
                            company_id={Apiconnect.get_company_id()} 
                            // sel_user_name={user_name}
                            year={year}
                            month={month}
                            months={months}
                            alert={alert}
                            localuser={local_user}
                            salaryMonth={salaryMonth}
                            deduction_tot={deduction_tot}
                            earning_tot={earning_tot}
                            leavestypelist={leavestypelist}
                            debited_leaves={debited_leaves}
                            paidDay={paidDay}
                            logo={logo}
                            companyData={companyData}
                            earning_head={earning_head}
                            deduct_head={deduct_head}
                            company_name={local_user.company_name}
                        />
                    </div>
                    {/*================end_right_div==================*/} 

                </div>
            </div>
        </div>
    )
}

export default Salary_slip