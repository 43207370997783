import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import TheLayout from './admin_component/TheLayout'; 
import TheEmsLayout from './ems_component/TheLayout'; 
import Forgotpassword from "./components/forgotpassword.component";
import Salarypdf from "./components/salaryslip_pdf.component";
import Webview_leave from './components/webview_leave.component';
import Webview_my_team from './components/webview_my_team.component';
import Webview_holiday from './components/webview_holiday.component';
import Webview_team_member_leaves from "./components/webview_team_member_leaves.component";
import Webview_salary_slip from "./components/webview_salary_slip.component";
import Webview_conveyance from "./components/webview_conveyance.component";
import Webview_salary_breakup from "./components/webview_salary_breakup.component";
import Webview_daily_punches from "./components/webview_daily_punches.component";
import Webview_request_profile from "./components/webview_request_profile.component";
import Webview_request_profile_list from "./components/webview_request_profile_list.component";
import Webview_request_edit_details from "./components/webview_request_edit_details.component";
import Webview_request_approval_details from "./components/webview_request_approval_details.component";
import salarySlip from "./components/salary_slip.component";
import Leave_details from './components/leave_details.component';
import Manual_attendance_request_approval from './components/manual_attendance_request_approval.component';




import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

import Career from "./components/Career" 
import Rm_selected_applicants_docs from "./components/Rm_selected_applicants_docs"; 
import Rm_employer_verification from "./components/Rm_employer_verification";


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        //showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        //showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }
  

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
       
          <Switch>            
            <Route exact path={["/", "/login"]} component={Login} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/upload_documents/:id" component={Rm_selected_applicants_docs} />
            <Route exact path="/employer_verification/:id" component={Rm_employer_verification} />
            <Route exact path={["/forgotpassword/:id", "/forgotpassword/"]} component={Forgotpassword} />
            <Route exact path={["/salaryslippdf/:id/:year/:month", "/salaryslippdf/:id/:year/:month"]} component={salarySlip} />
            <Route exact path={["/webview/leave/:company_id/:id", "/webview/leave/:company_id/:id"]} component={Webview_leave} />
            <Route exact path={["/webview/holiday/:company_id/:id", "/webview/holiday/:company_id/:id"]} component={Webview_holiday} />
            <Route exact path={["/webview/team_member_leaves/:id", "/webview/team_member_leaves/:id"]} component={Webview_team_member_leaves} />
            <Route exact path={["/webview/salary_slip/:id", "/webview/salary_slip/:id"]} component={Webview_salary_slip} />
            <Route exact path={["/webview/conveyance/:id", "/webview/conveyance/:id"]} component={Webview_conveyance} />
            <Route exact path={["/webview/salary_breakup/:company_id/:id", "/webview/salary_breakup/:company_id/:id"]} component={Webview_salary_breakup} />
            <Route exact path={["/webview/daily_punches", "/webview/daily_punches"]} component={Webview_daily_punches} />
            <Route exact path={["/webview/request_profile/:id", "/webview/request_profile/:id"]} component={Webview_request_profile} />
            <Route exact path={["/webview/request_profile_list/:company_id/:id", "/webview/request_profile_list/:company_id/:id"]} component={Webview_request_profile_list} />
            <Route exact path={["/webview/request_edit_details/:id", "/webview/request_edit_details/:id"]} component={Webview_request_edit_details} />
            <Route exact path={["/webview/request_approval_details/:user_id/:id/:company_id", "/webview/request_approval_details/:user_id/:id/:company_id"]} component={Webview_request_approval_details} />
            <Route exact path={["/salaryslip/:id/:year/:month", "/salaryslip/:id/:year/:month"]} component={salarySlip} />  
            <Route exact path={["/webview/my_team/:id", "/webview/my_team/:id"]} component={Webview_my_team} />
            <Route exact path={["/leave_details/:id/:leave_id", "/leave_details/:id/:leave_id/:company_id"]} component={Leave_details} />
            <Route exact path={["/manual_attendance_request_approval/:id", "/manual_attendance_request_approval/:id"]} component={Manual_attendance_request_approval} />
            <Route path="/ems/" name="Home" render={props => <TheEmsLayout {...props}/>} />
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
         
    );
  }
}

export default App;
