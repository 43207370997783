import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Apiconnect from '../../services/Apiconnect'
import {MdAdd} from 'react-icons/md'
import {MdDelete} from 'react-icons/md'
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import {mdDelete} from 'react-icons/md'

const Kra_list = () => {
    const {id} = useParams()
    let company_id = Apiconnect.get_company_id()

    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const alert = useAlert();

    const [list, setList] = useState()
    const [role, setRole] = useState("")
    const [secArr, setSecArr] = useState([{kpc_title: "", kpc_weightage: "", descriptions: [{kra_desc: "", kra_desc_weightage: ""}]}])

    useEffect(() => {getRoleWiseKra()}, [])

    const getRoleWiseKra = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`kra/getRoleWiseKra/${company_id}`).then(res => {
            let data = res.data.data
            let filteredData = data.filter((item) => item.kra_pool_id == id)
            setList(filteredData[0])
            setRole(filteredData[0].kra_pool_title)
            setSecArr(filteredData[0].sections)
            setFullScreenLoader(false)
        })
    }


    const handleinputchange = (e, index, key) => {
        const {name, value} = e.target
        const lists = [...secArr]
        lists[index][name]=value
        // lists[index]["descriptions"][key][name] = value
        setSecArr(lists)
    }

    const handledescchange = (e,index, key) => {
        const {name, value} = e.target
        const lists = [...secArr]
        lists[index]["descriptions"][key][name] = value
        setSecArr(lists)
    }

    

    let totalWeightage = secArr.map((item) => item.kpc_weightage).reduce((result, item) => Number(result) + Number(item))

    const handleUpdate = () => {
        let info = {role: role, desc: secArr}
        setFullScreenLoader(true)
        Apiconnect.postData(`kra/kra_update/${id}`, info).then(res => {
            getRoleWiseKra()
            setFullScreenLoader(false)
        })
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`kra/kra_desc_delete/${id}`).then(res => {
            getRoleWiseKra()
            setFullScreenLoader(false)
        })
    }


    const [kpc_title, setKpc_title] = useState("")
    const [kpc_weightage, setKpc_weightage] = useState("")

    const handleAddSection = () => {
        setFullScreenLoader(true)
        if(kpc_title !== "" && kpc_weightage !== ""){
            const info = {kpc_title: kpc_title, kpc_weightage:kpc_weightage, kra_pool_id:id}
            Apiconnect.postData(`kra/kra_section_create`, info).then(res => {
            getRoleWiseKra()
            setKpc_title("")
            setKpc_weightage("")
            alert.success("New KRA Section Added")
            setFullScreenLoader(false)
            }).catch(err => console.log(err))
        }    
    }

    const [kra_desc, setKra_desc] = useState("")
    const [kra_desc_weightage, setKra_desc_weightage] = useState("")
    const [kra_pool_cat_id, setKra_pool_cat_id] = useState("")

    const handleAddDesc = () => {
        setFullScreenLoader(true)
        if(kra_desc !== "" && kra_desc_weightage !== "" && kra_pool_cat_id !== ""){
            const info_ins = {kra_desc:kra_desc, kra_desc_weightage:kra_desc_weightage, kra_pool_cat_id:kra_pool_cat_id, kra_pool_id:id}
            Apiconnect.postData(`kra/kra_section_desc_create`, info_ins).then(res => {
                getRoleWiseKra()
                setKra_desc("")
                setKra_desc_weightage("")
                setKra_pool_cat_id("")
                alert.success("New KRA Description Added")
                setFullScreenLoader(false)
            })
        }
    }

    const handleDeleteSection = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`kra/kra_section_delete/${id}`).then(res => {
            getRoleWiseKra()
            setFullScreenLoader(false)
        }).catch(err => console.log(err))
    }
 
  return (
    <>
        <div id="content-page" className="content-page">
        {fullScreenLoader? (<FullScreenLoading loading loaderColor="#3498db" />): null}
        
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Manage KRAs of {role}</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-4 m-3'>
                                        <input type='text' className='form-control' placeholder='Enter Role' name='role' value={role} onChange={e => setRole(e.target.value)} />
                                    </div>
                                    <div className='col-md-12' >
                                        
                                    {
                                        secArr.map((item, index) => {
                                            return(
                                                <div key={index} className='row mb-3 m-3' style={{border: "1px solid #0453ad", borderRadius: 10, backgroundColor: "#b7d6fa"}}>
                                                    <div className='col-md-12' style={{border: "1px solid #5480df", backgroundColor: "#5480df", borderRadius: 10}}>
                                                        <div className='row card-header'>
                                                            <div className='col-md-8'>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='row'>
                                                                            <div className='col-md-4'>
                                                                                <p className="badge badge-success">KRA Section <span className="badge badge-light">{index + 1}</span></p>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control bg-white " required placeholder="Enter KRA Section Title" value={item.kpc_title} name='kpc_title' onChange={e => handleinputchange(e, index)}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className="form-group">
                                                                                    <input type="number" className="form-control bg-white" required placeholder="Enter KRA Section Percentage" value={item.kpc_weightage} name='kpc_weightage' onChange={e => handleinputchange(e, index)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className='col-md-4'>
                                                                <button className='btn btn-sm btn-danger float-right' onClick={() => handleDeleteSection(item.kpc_id)}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        item.descriptions.length > 0 && item.descriptions.map((val, key) => {
                                                            console.log(item.descriptions)
                                                            return(
                                                                <div key={key} className='col-md-12 mt-3 mb-2'>
                                                                    <div className='row'>
                                                                        <div className='col-md-10'>
                                                                            <div className='row bbox p-2 ml-2 d-flex align-items-center'>
                                                                                <div className='col-md-8'>
                                                                                    <textarea className="form-control" placeholder='Add KRA Description' name='kra_desc' value={val.kra_desc} onChange={(e) => handledescchange(e,index, key)}/>
                                                                                </div>
                                                                                <div className='col-md-2'>
                                                                                    <input type='number' className="form-control" placeholder='weightage' name='kra_desc_weightage' value={val.kra_desc_weightage} onChange={(e) => handledescchange(e,index, key)}/>
                                                                                </div>
                                                                                <div className='col-md-2'>
                                                                                    <button className='btn btn-sm btn-danger mr-2' onClick={() => handleDelete(val.kra_pool_keys_id)}>Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) 

                                                    }
                                                    <div className='row m-3'>
                                                        <div className='col-md-6'><p className='badge fs-4' style={{backgroundColor: "#0077D6", color: "white"}}>KRA Count  <span className="badge badge-light">{item.descriptions.length}</span></p></div>
                                                        <div className='col-md-6'><p className='badge fs-4' style={{backgroundColor: "#0077D6", color: "white"}}>KRA Section weightage <span className="badge badge-light">{item.descriptions.map((item) => item.kra_desc_weightage).reduce((result, item) => Number(result) + Number(item))}</span></p></div>
                                                    </div>
                        
                                                </div>
                                            )
                                        })
                                    }  

                                        <div className='row mb-3 m-3 p-2' style={{border: "1px solid #0f104d", borderRadius: 10, backgroundColor: "#1f218d"}}>
                                            <div className='col-md-12'>
                                                <div className='row mt-2'>
                                                    <div className='col-md-6'>
                                                        <h3 className='badge badge-warning fs-4 mr-3'>Total KRA Sections <span className="badge badge-light">{secArr.length}</span></h3>
                                                        <h3 className='badge badge-warning fs-4'>Total KRA Section weightage <span className="badge badge-light">{totalWeightage ? totalWeightage : 0}</span></h3>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <button className='btn btn-sm btn-primary float-right mr-2' onClick={handleUpdate}>Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <hr className="mt-1 mb-1"/>

                                    {/* ---Add section--- */}

                                    <div className='col-md-12'>
                                        <div className='row m-3 p-2 rounded-2' style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", backgroundColor: "#eae7ff", border: "1px solid #6e58ff", borderRadius: 5}}>
                                            <div className='col-md-4'>
                                                <input type='text' className='form-control bg-white' placeholder='Enter KRA Section' name='kpc_title' value={kpc_title} onChange={(e) => setKpc_title(e.target.value)} />
                                            </div>
                                            <div className='col-md-4'>
                                                <input type='number' className='form-control bg-white' placeholder='Enter Section Weightage' name='kpc_weightage' value={kpc_weightage} onChange={e => setKpc_weightage(e.target.value)} />
                                            </div>
                                            <div className='col-md-4'>
                                                <button className='btn btn-sm btn-info float-right' onClick={handleAddSection}>Add Section</button>
                                            </div>
                                        </div>

                                        {/* ---add description--- */}

                                        <div className='row m-3 p-2 rounded-2' style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", backgroundColor: "#eae7ff", border: "1px solid #6e58ff", borderRadius: 5}}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <select className="form-control bg-white" name='kra_pool_cat_id' onChange={e => setKra_pool_cat_id(e.target.value)}>
                                                                <option value="" disabled selected>SELECT KRA Section</option>
                                                                {secArr.map((val, key) => {
                                                                    return(
                                                                        <option key={key} value={val.kpc_id}>{val.kpc_title}</option>
                                                                    )
                                                                    })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <input type='number' className='form-control bg-white' placeholder='Description Weightage' name='kra_desc_weightage' value={kra_desc_weightage} onChange={e => setKra_desc_weightage(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <textarea rows={3} className='form-control bg-white' placeholder='Enter KRA Section Description' name='kra_desc' value={kra_desc} onChange={e => setKra_desc(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-12 p-2'>
                                                        <button className='btn btn-sm btn-info float-right' onClick={handleAddDesc}>Add Description</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Kra_list
