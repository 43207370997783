import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
const customStyles = {
    content: {
       top: '55%',
       left: '55%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "70%" ,
       height: '60%'
    },
};

const Manage_roundwise_assessments = () => {

    const alert = useAlert(); 
    const company_id = Apiconnect.get_company_id()
    const local_user = JSON.parse(localStorage.getItem('user'));
    let emp_id = local_user.emp_id;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [round_list, setRound_list] = useState([])
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([]);
    const [allchecked, setallchecked] = useState(null);
    const [round, setRound] = useState("")

    useEffect(() => {get_round_list(); get_assessment_list()}, [])

    const selectAll = (e) => {
        if (e.target.checked === true) { 
          setallchecked("checked");
          let ob = {};
          list.map((val, key) => {
            ob[val.sra_pk_id] = val.sra_pk_id;
          });
          setSelected(ob);
        }else{
          setallchecked(null);
          setSelected([]);
        }
      };

    const onInputChange = (e) => {
        setallchecked(null);
        if (e.target.checked === true) {
            setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
            });
        } else {
            setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
    }

    const get_assessment_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_round_assessment_getall/${company_id}`).then((res) => {
            setList(res.data.data)
            setFullScreenLoader(false)
        })
    }

    const get_round_list = () => {
        setFullScreenLoader(false)
        Apiconnect.postData(`hiring_application/hiring_rounds_getall/${company_id}`).then((res) => {
            setFullScreenLoader(false)
            setRound_list(res.data.data)
        })
    }

    const handleAssign = () => {
        let data_ins = {rwam_round_id:round, rwam_assessment_id:selected, rwam_created_by:emp_id}
        if(round !== "" && Object.keys(selected).length > 0){
            setFullScreenLoader(false)
            Apiconnect.postData(`hiring_application/roundwise_assessment_create/${company_id}`, data_ins).then((res) => {
                setFullScreenLoader(false)
                alert.success("Created")
                setRound("")
                setSelected("")
                setModalIsOpen(false)
            })
        }  
    }

  return (
    <>
        <div className='content-page'>
            {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head">
                        <div className="iq-header-title">
                            <h4 className="card-title ">Assign Assessments to rounds</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="row">
                            <div className='col-md-4'>
                                <select className='form-control center' name="round" value={round} onChange={(e) => setRound(e.target.value)}>
                                    <option value="" disabled selected>---Select Round---</option>
                                    {
                                        round_list.length > 0 ?
                                        (
                                            round_list.map((val, key) => {
                                                return(
                                                    <option key={key} value={val.id}>{val.sr_round_name+" / "+val.group_name}</option>
                                                )
                                            })
                                        )
                                        :
                                        null
                                    }
                                </select>
                            </div>
                            <div className='col-md-8 mb-2'>
                                <button className='btn btn-sm btn-info' onClick={handleAssign}>Assign</button>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                        <tr className="clr">
                                        <th><input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /></th>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.length > 0 ?
                                            (
                                                list.map((val, key) => {

                                                    let ck = Object.keys(selected).find(function (element) {
                                                    return selected[element] == val.sra_pk_id;
                                                    });  
                                                    var ck1 = "";
                                                    if (ck !== undefined) {
                                                        ck1 = true;
                                                    }

                                                    return(
                                                        <tr key={key}>
                                                            <th scope="row"><input type="checkbox" name={val.sra_pk_id} value={val.sra_pk_id} checked={ck1} onChange={(e) => onInputChange(e)}/></th>
                                                            <td>{key + 1}</td>
                                                            <td>{val.sra_name}</td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <tr>
                                                    <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className="iq-card-header d-flex justify-content-between model_head">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Roundwise Assessments</h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-info float-right' onClick={() => setModalIsOpen(true)}>Assign</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Manage_roundwise_assessments