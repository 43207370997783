import React from "react"
import Rating from "@mui/material/Rating"
import { styled } from "@mui/material/styles"
const RatingS = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FAAF00",
  },
  "& .MuiRating-iconHover": {
    color: "#FAAF00",
  },
})

const EmpExitTableInfo = ({ indexNo, label, val }) => {
  return (
    <tr className="text-center">
      <td className="text-muted" style={{ width: "10%" }}>
        {indexNo}
      </td>
      <td className="text-muted" style={{ width: "60%" }}>
        {label}
      </td>
      <td style={{ width: "30%" }}>
        <RatingS
          style={{ color: "yellow" }}
          value={Number(val)}
          defaultValue={0}
          max={5}
        />
      </td>
    </tr>
  )
}

export default EmpExitTableInfo
