import React, {useState, useEffect} from 'react'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Apiconnect from '../../services/Apiconnect'
import Modal from 'react-modal';
import ReactDOM from 'react-dom';

const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }

}

const WingClass_Assign = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id();
    const alert = useAlert(); 

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [class_id, setClass_Id] = useState("")
    const [wing_id, setWing_id] = useState("")
    const [lists_class, setlists_class] = useState([])
    const [lists_wings, setlists_wings] = useState([]);
    const [list, setList] = useState([])
    const [editId, setEditId] = useState("")
    const [className, setClassName] = useState("")

    useEffect(() => { getlist_class(); getLists_wings(); getLists()}, []);

    const getlist_class = () => { 
        setFullScreenLoader(true);
        Apiconnect.postData("manage_class/get_all_class_lists", {company_id:company_id}).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            setlists_class(_xtract);
        });
    };

    const getLists_wings = () => { 
        setFullScreenLoader(true);
        Apiconnect.postData("manage_wings/get_wings_lists", {company_id:company_id}).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            setlists_wings(_xtract);  
            setFullScreenLoader(false);
        });
    };

    const getLists = () => {
        setFullScreenLoader(true);
        Apiconnect.postData("manage_wings/wings_class_list", {company_id:company_id}).then((res) => { 
            setList(res.data.data);  
            setFullScreenLoader(false);
        });
    }

    console.log(list)

    const handleAssign = () => {
        setFullScreenLoader(true)
        if(editId !== ""){
            let data = {wingclass_wing_id:wing_id, company_id:company_id, wingclass_pk_id:editId}
            Apiconnect.postData("manage_wings/wings_class_update", data).then((res) => {  
                setFullScreenLoader(false);
                setWing_id("")
                setEditId("")
                getLists()
                alert.success("Updated")
                console.log("Updated")
            });
        }else{
            let data = {wingclass_class_id: class_id, wingclass_wing_id:wing_id, company_id:company_id}
            Apiconnect.postData("manage_wings/wings_class_create", data).then((res) => {  
                setFullScreenLoader(false);
                getLists()
                alert.success("Inserted")
                console.log("Inserted")
            });
        }   
    }

    const handleEdit = (id) => {
        const filteredData = list.find((item) => item.id === id)
        setWing_id(filteredData.wingclass_wing_id)
        setClassName(filteredData.class)
        setEditId(id)
    }

    const handleDelete = (id) => {
        /* ====delete==== */
        let data = {wingclass_pk_id:id, company_id:company_id}
            Apiconnect.postData("manage_wings/wings_class_delete", data).then((res) => {  
                getLists()
                alert.success("Inserted")
                console.log("Inserted")
                setFullScreenLoader(false);
            });
    }

    


  return (
    <>
        {fullScreenLoader ? (
            <FullScreenLoading loading loaderColor="#3498db" />
        ) : 
            null 
        }
        <div id="content-page" className="content-page" style={page_styles.main_container_bg}>
             
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-md-12">
                        <div className="iq-card" style={page_styles.main_container_shadows}>
                            <div className="iq-card-header" style={page_styles.card_head}>
                                <div className="iq-header-title">
                                    <h4 className="card-title text-white">Manage Wing Class Assignment</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row mb-3'>
                                    {
                                        editId && 
                                        <div className='col-md-4'>
                                            <p className='text-center text-success'>{className}</p>
                                        </div>
                                    }
                                    {!editId && <div className='col-md-4'>
                                        <select className='form-control' name='class_id' value={class_id} onChange={e => setClass_Id(e.target.value)}>
                                            <option value='' disabled selected>Select Class Name</option>
                                            {
                                                lists_class.map((val, key) => {  
                                                    return(
                                                        <option key={key} value={val.class_pk_id}> {val.class_name} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>}

                                    <div className='col-md-4'>
                                        <select className='form-control' name='wing_id' value={wing_id} onChange={e => setWing_id(e.target.value)}>
                                            <option value='' disabled selected>Select Wing Name</option>
                                            {
                                                lists_wings.map((val, key) => {  
                                                    return(
                                                        <option key={key} value={val.wing_pk_id}> {val.wing_name} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-4'>
                                        <button type='button' className='btn btn-sm btn-info' onClick={handleAssign}>{editId ? "Update" : "Assign"}</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {list.length > 0 && <table  className="table table-bordered mt-1 center" style={page_styles.shadow}>
                                        <thead className="thead-light">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Class Name</th> 
                                            <th scope="col">Wing Name</th> 
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td scope="row">{index + 1}</td> 
                                                            <td>{item.class}</td>
                                                            <td>{item.wing}</td>
                                                            <td>
                                                                <button type='button' className='btn btn-sm btn-info mr-2' onClick={() => handleEdit(item.id)}>Edit</button>
                                                                {/* <button type='button' className='btn btn-sm btn-danger' onClick={() => handleDelete(item.id)}>Delete</button> */}
                                                            </td>
                                                        </tr> 
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default WingClass_Assign