import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import {useParams} from "react-router-dom"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "70%" ,
        height: '80%'
    },
};

const Assign_assessments = (props) => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const {hp_id, round_id} = useParams()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([]);
    const [allchecked, setallchecked] = useState(null);

    useEffect(() => {get_assessment_list();get_round_details(); get_hpra_list()}, [])

    const get_assessment_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_assessment_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setList(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    const selectAll = (e) => {
        if (e.target.checked === true) { 
          setallchecked("checked");
          let ob = {};
          list.map((val, key) => {
            ob[val.hpa_pk_id] = val.hpa_pk_id;
          });
          setSelected(ob);
        }else{
          setallchecked(null);
          setSelected([]);
        }
    };
    const onInputChange = (e) => {
        setallchecked(null);
        if (e.target.checked === true) {
            setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
            });
        } else {
            setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
    }

    const [rnd_name, setRnd_name] = useState("")
    const [hp_name, sethp_name] = useState("")

    const get_round_details = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_get_round/${company_id}/${hp_id}/${round_id}`).then(res => {
            setFullScreenLoader(false)
            let arr = Apiconnect.decrypt_obj(res.data.data)
            setRnd_name(arr[0].name)
            sethp_name(arr[0].hp_name)
        })
    }

    const handleAssign = () => {
        let ins_ob = {hpra_company_id: company_id, hpra_assessments: selected, hpra_hiring_process_id:hp_id, hpra_hiring_round_id: round_id, hpra_created_by:emp_id}
        if(Object.keys(selected).length > 0){
            setFullScreenLoader(true)
            Apiconnect.postData(`hiring_application/hiring_process_round_assessments_create`, ins_ob).then(res => {
                setFullScreenLoader(false)
                setModalIsOpen(false)
                alert.success("Assigned SuccessFully")
            }).catch(err => alert.error("Can't Assign"))
        }
    }

    const [hpra_list, setHpra_list] = useState([])

    const get_hpra_list = () => {
        setFullScreenLoader(true)
            Apiconnect.postData(`hiring_application/hiring_process_round_assessments_getall/${company_id}/${hp_id}/${round_id}`).then(res => {
                setFullScreenLoader(false)
                setHpra_list(Apiconnect.decrypt_obj(res.data.data))
            }).catch(err => alert.error("Error"))
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_round_assessments_delete/${id}`).then(res => {
            setFullScreenLoader(false)
            get_hpra_list()
            alert.success("Deleted Successfully")
        })
    }

   
  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}   
            <div className='container-fluid'>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className='iq-card'>
                    <div className='iq-card-header justify-content-between model_head'>
                        <div className='iq-header-title' style={{float: "left"}}>
                            <h4 className='card-title'>Assign Assessments For <span className='font-weight-bold'>{rnd_name}</span> of <span className='font-weight-bold'>{hp_name}</span></h4>
                        </div>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <button className='btn btn-sm btn-info float-right' onClick={handleAssign}>Assign</button>
                            </div>
                            <div className='col-md-12 mt-3'>
                            <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                <thead className="table-primary">
                                    <tr className='"table-primary"'>
                                        <th><input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /></th>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length > 0 ?
                                        (
                                            list.map((val, key) => {
                                                    let status = null
                                                    if(val.hpa_status === 1){
                                                        status = <span className='badge badge-pill badge-success'>Active</span>
                                                    }else{
                                                        status = <span className='badge badge-pill badge-danger'>Inactive</span>
                                                    }

                                                    let ck = Object.keys(selected).find(function (element) {
                                                        return selected[element] == val.hpa_pk_id;
                                                    });  
                                                    
                                                    var ck1 = "";
                                                    if (ck !== undefined) {
                                                        ck1 = true;
                                                    }
                                                return(
                                                    <tr key={key}>
                                                        <th scope="row"><input type="checkbox" name={val.hpa_pk_id} value={val.hpa_pk_id} checked={ck1} onChange={(e) => onInputChange(e)}/></th>
                                                        <td>{key + 1}</td>
                                                        <td>{val.hpa_name}</td>
                                                        <td>{status}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        :
                                        (
                                            <tr>
                                                <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </Modal>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className='iq-card-header d-flex justify-content-between model_head'>
                                <div className='iq-header-title' style={{ float: "left" }}>
                                    <h4 className='card-title'>Assessments For <span className='font-weight-bold'>{rnd_name}</span> of <span className='font-weight-bold'>{hp_name}</span></h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-dark float-right' onClick={() => setModalIsOpen(true)}>Assign Assessments</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                            <thead className="">
                                                <tr className="clr">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Hiring Process</th>
                                                    <th scope="col">Hiring Round</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    hpra_list.length > 0 ?
                                                    (
                                                        hpra_list.map((val, key) => {
                                                                let status = null
                                                                if(val.hpa_status === 1){
                                                                    status = <span className='badge badge-pill badge-success'>Active</span>
                                                                }else{
                                                                    status = <span className='badge badge-pill badge-danger'>Inactive</span>
                                                                }

                                                                let ck = Object.keys(selected).find(function (element) {
                                                                    return selected[element] == val.hpa_pk_id;
                                                                });  
                                                                
                                                                var ck1 = "";
                                                                if (ck !== undefined) {
                                                                    ck1 = true;
                                                                }
                                                            return(
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>{val.assessment_name}</td>
                                                                    <td>{val.hp_name}</td>
                                                                    <td>{val.round_name}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete(val.id)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={10} style={{textAlign:'center'}}> No Assessments Assigned Yet... </th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Assign_assessments