import React, { useEffect, useState } from "react"
import {
  Card,
  Container,
  Input,
  SelectFromOptions,
  CustomModal,
  Table,
  TextArea,
} from "../../../components/imports"
import Apiconnect from "../../../services/Apiconnect"
import toast from "react-hot-toast"
import { Link, useParams } from "react-router-dom/cjs/react-router-dom"
import EmpExitTableInfo from "./components/EmpExitTableInfo"
const trHeader = (
  <tr className="text-center">
    <th>#</th>
    <th>Questionnaires</th>
    <th>Rating (1 Not Satisfied – 5 Very Satisfied)</th>
  </tr>
)

const Emp_exit_interview = () => {
  const { id } = useParams()
  const localuser = JSON.parse(localStorage.getItem("user"))
  let emp_id = Apiconnect.decrypt(localuser.id)

  const [emp, setEmp] = useState({})
  const [eei_interviewer_cmnt, setEei_interviewer_cmnt] = useState("")

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    Apiconnect.postData(
      `separation/emp_exit_interviewed_list/${Apiconnect.get_company_id()}`
    ).then((res) => {
      let extract = Apiconnect.decrypt_obj(res.data.data)
      let emp = extract.filter((item) => item.eei_emp_id == id)
      setEmp(emp[0])
    })
  }

  const handleSubmit = () => {
    let dataIns = {
      eei_interviewer_cmnt: eei_interviewer_cmnt,
      eei_interviewer_id: emp_id,
    }

    if (eei_interviewer_cmnt !== "") {
      Apiconnect.postData(
        `separation/emp_exit_interviewer_cmnt/${emp.eei_pk_id}`,
        dataIns
      ).then((res) => {
        getList()
        toast.success("Success")
      })
    } else {
      toast.success("Comment Field Is Empty")
    }
  }

  const satisfactionArr = [
    { index: 1, lebel: "Your job profile", val: emp.eei_q7_1 },
    { index: 2, lebel: "Your compensation", val: emp.eei_q7_2 },
    { index: 3, lebel: "Your benefits", val: emp.eei_q7_3 },
    { index: 4, lebel: "Working conditions & culture", val: emp.eei_q7_4 },
    { index: 5, lebel: "YWork life balance", val: emp.eei_q7_5 },
    { index: 6, lebel: "Internal communications", val: emp.eei_q7_6 },
    {
      index: 7,
      lebel: "Relationship with Immediate Supervisor",
      val: emp.eei_q7_7,
    },
    { index: 8, lebel: "Support from Authorities", val: emp.eei_q7_8 },
    {
      index: 9,
      lebel: "Your professional growth/development",
      val: emp.eei_q7_9,
    },
    { index: 10, lebel: "Learning Opportunity", val: emp.eei_q7_10 },
    { index: 11, lebel: "Interaction with your peers", val: emp.eei_q7_11 },
    { index: 12, lebel: "Interaction with leadership", val: emp.eei_q7_12 },
  ]

  const empExitOne_Three = [
    {
      id: 1,
      label:
        "1. What attracted you to join the Jaipuria group? To what extent did you actually experience that?",
      val: emp.eei_q1,
    },
    {
      id: 2,
      label:
        " 2. What are the 2 best things about Jaipuria group you would like to talk about?",
      val: emp.eei_q2,
    },
    {
      id: 3,
      label:
        "3. What are 2 things that you would like to change in the organization?",
      val: emp.eei_q3,
    },
  ]

  return (
    <Container heading={`${emp.employee_name} `}>
      <Card>
        <div className="col-md-12">
          <Link to={`/Emp_exit_interviews`}>
            <button className="btn btn-sm btn-light ml-3">
              <i
                style={{ fontSize: 20, color: "#4667B0" }}
                className="fa fa-chevron-circle-left"
                aria-hidden="true"
              ></i>
            </button>
          </Link>
        </div>
        <Card>
          <div className="col-md-12 text-center">
            <span className="text-muted font-weight-bold">
              Exit Interview Questionnaire & Answers
            </span>
          </div>
        </Card>
        <Card>
          {empExitOne_Three.map((item, index) => {
            return (
              <TextArea
                key={index}
                type="text"
                className="col-md-12"
                label={item.label}
                placeholder=""
                value={item.val}
              />
            )
          })}
          <div className="col-md-12">
            <label className="text-muted">
              4. In order of priority, what were the top two reasons for your
              resignation?
            </label>
            <div className="row">
              <TextArea
                type="text"
                className="col-md-12"
                placeholder="1."
                name="eei_q4_1"
                value={emp.eei_q4_1}
              />
              <TextArea
                type="text"
                className="col-md-12 mt-3"
                placeholder="2."
                name="eei_q4_2"
                value={emp.eei_q4_2}
              />
            </div>
          </div>
          <TextArea
            type="text"
            className="col-md-12"
            label="  5. If you were to reconsider your decision to move out, what could actually hold you back from going?"
            placeholder=""
            name="eei_q5"
            value={emp.eei_q5}
          />
          <TextArea
            type="text"
            className="col-md-12"
            label="  6. Would you like to join The Jaipuria Group again in future?"
            placeholder=""
            name="eei_q6"
            value={emp.eei_q6}
          />
          <div className="col-md-12">
            <label className="text-muted">
              7. How satisfied were you with: (1 Not Satisfied – 5 Very
              Satisfied)
            </label>
            <div className="row">
              <Table className="col-md-12" trHeader={trHeader}>
                {satisfactionArr.map((item, ind) => {
                  return (
                    <EmpExitTableInfo
                      key={ind}
                      indexNo={item.index}
                      label={item.lebel}
                      val={item.val}
                    />
                  )
                })}
              </Table>
            </div>
          </div>
        </Card>
        <Card>
          <TextArea
            type="text"
            className="col-md-12"
            label="Suggestions"
            placeholder=""
            name="eei_suggestions"
            value={emp.eei_suggestions}
          />
          <TextArea
            type="text"
            className="col-md-12"
            label="Interviewer’s comments"
            placeholder=""
            name="eei_interviewer_cmnt"
            value={
              emp.eei_interviewer_cmnt !== "" ||
              emp.eei_interviewer_cmnt !== null
                ? emp.eei_interviewer_cmnt
                : eei_interviewer_cmnt
            }
            onChange={(e) => setEei_interviewer_cmnt(e.target.value)}
          />
          {!emp.eei_interviewer_cmnt && (
            <div className="col-md-12 mt-3 text-center">
              <button className="btn btn-sm btn-info" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          )}
        </Card>
      </Card>
    </Container>
  )
}

export default Emp_exit_interview
