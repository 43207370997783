import React, {useState, useEffect} from 'react'
import { nanoid } from 'nanoid'
import {MdAdd} from 'react-icons/md'
import {MdDelete} from 'react-icons/md'
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link } from 'react-router-dom'


const Kra_manage = () => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id);
    let company_id = Apiconnect.get_company_id()

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    
    const [role, setRole] = useState("")
    const [inputList, setInputList] = useState([{section: "", percentage: "", descList: []}])
    const [active, setActive] = useState(false)
    const alert = useAlert();

    const handleaddClick = () => {
        setInputList([...inputList, {section: "", percentage: "", descList: []}])
    }

    const handleRemove = (index) => {
        const list = [...inputList]
        list.splice(index, 1)
        setInputList(list)
    }

    const handleinputchange = (e, index) => {
        const {name, value} = e.target
        const list = [...inputList]
        list[index][name]=value
        setInputList(list)
    }

    let totalWeightage = inputList.map((item) => item.percentage).reduce((result, item) => Number(result) + Number(item))

    const handleReset = () => {
        setInputList([{section: "", percentage: "", descList: []}])
        setActive(false)
        setRole("")
    }

    const handleSubmit = () => {
        let data = {employee_id:employee_id, role:role, desc: inputList}
        console.log(data)
        setFullScreenLoader(true)

        if(role !== "" && inputList.section !== "" && inputList.percentage !== ""){
            Apiconnect.postData(`kra/kra_create/${company_id}`, data).then(res => {
                setFullScreenLoader(false)
                setInputList([{section: "", percentage: "", descList: []}])
                setActive(false)
                setRole("")
                alert.success("Inserted")
            }).catch(err => console.log(err))
        }  
    }


    
  return (
    <>
        <div id="content-page" className="content-page">
        {fullScreenLoader? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between"
                             style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div className="iq-header-title">
                                    <h4 className="card-title text-white">Manage KRA</h4>
                                </div>
                                <Link to="/Kra_manage_list" ><button className='btn btn-sm btn-info float-right'>Back</button></Link>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <input type="text" className="form-control " required placeholder="Enter KRA Role" name='role' value={role} onChange={e => setRole(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                    {!active && <button type='button' className='btn btn-sm btn-primary' onClick={() => setActive(true)}>Add KRA Section</button>}
                                    </div>
                                </div>
                                {active && <div className='bg-light p-2'>
                                {
                                    inputList.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #e9b200", borderRadius: 10}}>
                                                <div className='col-md-12' style={{border: "1px solid orange", backgroundColor: "#ffeeb7"}}>
                                                    <div className='row card-header'>
                                                        <div className='col-md-6'>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <p className="badge badge-success">Section <span className="badge badge-light">{index + 1}</span></p>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control bg-white " required placeholder="Enter KRA Section Title" name='section' onChange={e => handleinputchange(e, index)}/>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <input type="number" className="form-control bg-white" required placeholder="Enter KRA Section Percentage" name='percentage' onChange={e => handleinputchange(e, index)}/>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {<DescForm index={index} inputList={inputList} setInputList={setInputList} />}
                                                <div className='col-md-12 mt-3' style={{border: "1px solid orange", backgroundColor: "#ffeeb7"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-6'>
                                                            {inputList.length-1 === index &&  <button className='btn btn-sm btn-primary mt-2' onClick={handleaddClick}>Add Section</button>}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {inputList.length !== 1 && <button className='btn btn-sm btn-danger mt-2 float-right' onClick={() => handleRemove(index)}>Remove Section</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                
                                
                                <div className='row mb-3 m-3' style={{border: "3px solid #e9b200", borderRadius: 10}}>
                                    <div className='col-md-12' style={{border: "1px solid orange", backgroundColor: "#fbffe7"}}>
                                        <div className='row mt-2'>
                                            <div className='col-md-6'>
                                                <h4 className='badge badge-warning fs-4 mr-3'>Total Section Count <span className="badge badge-light">{inputList.length}</span></h4>
                                                <h4 className='badge badge-warning fs-4'>Total Section weightage <span className="badge badge-light">{totalWeightage ? totalWeightage : 0}</span></h4>
                                            </div>
                                            <div className='col-md-6'>
                                                <button className='btn btn-sm btn-primary float-right mr-2' onClick={handleSubmit}>Submit</button>
                                                <button className='btn btn-sm btn-danger float-right mr-2' onClick={handleReset}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Kra_manage

const DescForm = ({index, inputList, setInputList}) => {
   const [inputs, setInputs] = useState([{description: "", weightage: ""}])
   const alert = useAlert();

   const handleChange = (e, index) => {
    const {name, value} = e.target
        const list = [...inputs]
        list[index][name]=value
        setInputs(list)
   }

   const handleaddClick = () => {
    // setInputs([...inputs, {description: "", weightage: ""}])
    inputList[index].descList.length = 0
    let list = [...inputs]
    inputList[index].descList.push.apply(inputList[index].descList, list)
    alert.success("Description Added")
    }

  const handleAdd = () => {
    setInputs([...inputs, {description: "", weightage: ""}])
  }

   const handleRemove = (id) => {
        const list = [...inputs]
        list.splice(id, 1)
        setInputs(list)
        inputList[index].descList.length = 0
        inputList[index].descList.push.apply(inputList[index].descList, list)
   }

   let totalWeightage = inputs.map((item) => item.weightage).reduce((result, item) => Number(result) + Number(item))

//    console.log(inputList)
   
    return(
        <>
            {
                inputs.length >= 1 ? 
                inputs.map((item, index) => {
                    return(
                        <div key={index} className='col-md-12 mt-3 mb-2'>
                            <div className='row'>
                                <div className='col-md-10'>
                                    <div className='row bbox p-2 ml-2 d-flex align-items-center'>
                                        <div className='col-md-8'>
                                            <textarea className="form-control" placeholder='Add KRA Description' name='description' onChange={(e) => handleChange(e, index)}/>
                                        </div>
                                        <div className='col-md-2'>
                                            <input type='number' className="form-control" placeholder='weightage' name='weightage' onChange={(e) => handleChange(e, index)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <button className='btn btn-sm btn-primary mt-2 mr-2' onClick={() => handleaddClick(index)}>Add</button>
                                    {inputs.length !== 1 &&<button className='btn btn-sm btn-danger mt-2 mr-2' onClick={() => handleRemove(index)}><MdDelete/></button>}
                                    <button className='btn btn-sm btn-success mt-2 mr-2' onClick={() => handleAdd(index)}><MdAdd/></button>
                                </div>
                            </div>
                        </div>
                    )
                })

                :

                <div className='col-md-12 mt-3 mb-2'>
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className='row bbox p-2 ml-2 d-flex align-items-center'>
                                <div className='col-md-8'>
                                    <textarea className="form-control" placeholder='Add KRA Description' name='description' onChange={(e) => handleChange(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <button className='btn btn-sm btn-primary mt-2 mr-2' onClick={() => handleaddClick(index)}>Add</button>
                        </div>
                    </div>
                </div>
            }
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-6'><p className='badge badge-warning fs-4'>Section Description Count<span className="badge badge-light">{inputs.length}</span></p></div>
                    <div className='col-md-6'><p className='badge badge-warning fs-4'>Section weightage <span className="badge badge-light">{totalWeightage ? totalWeightage : 0}</span></p></div>
                </div>
            </div>
        </>
    )
}
























