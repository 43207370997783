import React, {useState, useEffect} from 'react'
import { useLocation } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";
import DatePicker from "react-datepicker";
import Axios from 'axios';
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useAlert } from "react-alert";
import moment from 'moment'
import FullScreenLoading from 'react-fullscreen-loading';
import { tr } from 'date-fns/locale';
import { keys } from '@material-ui/core/styles/createBreakpoints.js';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

const Employee_leave_taken = () => {
    const { emp_id } = useParams();
    const company_id = Apiconnect.get_company_id()
    
    const alert = useAlert();
    let history = useHistory();

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken;
    
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [list_empcatleave1,set_list_empcatleave1]=useState([]);
    const [list_category1,set_list_category1]=useState([]); 
    const [CompanyData, setCompanyData] = useState([])

    var date_current1=new Date();
    var date_first1=new Date(date_current1.getFullYear(), (date_current1.getMonth()), 1);
    var date_last1=new Date(date_current1.getFullYear(), (date_current1.getMonth()) + 1, 0);

    const [from_date, setFromDate] = useState(date_first1);
    const [to_date, setToDate] = useState(date_last1);

    useEffect(() => { 
        getEmployeeLeaveTakenList();
        getCategoryList();
        getCompany();
    }, []);

    const getEmployeeLeaveTakenList = async () => { 
        var postData1={
            company_id:company_id,
            from_date:from_date,
            to_date:to_date,
        }
        await Apiconnect.postData(`leave_manage/get_employee_wise_leave_category_taken_list`,postData1).then(response => {
            set_list_empcatleave1(Apiconnect.decrypt_obj(response.data.data));
        }); 
    };  

    const getCategoryList = async () => {  
        await Apiconnect.postData(`leave_manage/get_leave_category_list_by_id`,{company_id:company_id}).then(response => {
            set_list_category1(Apiconnect.decrypt_obj(response.data.data)); 
        }); 
    }; 

    const getCompany = () => {         
        Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
            setCompanyData(Apiconnect.decrypt_obj(response.data.data));            
        });
    };
    
    
    var list_empcatleave3=[]; 
    var list_empcatleave4=[]; 
    for(var cnt_i1=0;cnt_i1<list_empcatleave1.length>0;cnt_i1++){
        var list_empcatleave2=list_empcatleave1[cnt_i1];
        var empid=list_empcatleave2.id;
        var empidnew=list_empcatleave2.id;
        var emp_code=list_empcatleave2.emp_code;
        var empname=list_empcatleave2.first_name+' '+list_empcatleave2.last_name;
        var lpcategory_category_id=list_empcatleave2.lpcategory_category_id;
        var lpcategory_pk_id=list_empcatleave2.lpcategory_pk_id;

        var cal_leave_taken=list_empcatleave2.cal_leave_taken;
        if(cal_leave_taken==null){ cal_leave_taken=0; }

        var app_options1=[]; 
        app_options1['emp_code']=emp_code;
        app_options1['emp_name']=empname; 

        list_empcatleave3[empidnew+'#'+lpcategory_category_id]=cal_leave_taken;

        list_empcatleave4[empidnew]=app_options1;
    } 
    
    // console.log(list_empcatleave3);
    // console.log(list_category1);

    var cnt_srno1=0;

    
    const btn_submit1 = async () => {  
        getEmployeeLeaveTakenList();
    }

    const download_excel = async () => {  
        
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            var sel_from_date=moment(from_date).format("DD-MM-YYYY");
            var sel_to_date=moment(to_date).format("DD-MM-YYYY"); 

            const sheet1 = workbook.sheet(0);
             
            sheet1.cell("A1").value(localuser.company_name);
            sheet1.cell("A2").value(CompanyData[0].address_one)
            sheet1.cell("A3").value("Employee Leave Taken Report"); 

            sheet1.cell("A5").value('From Date');
            sheet1.cell("B5").value(sel_from_date);
            sheet1.cell("C5").value('TO Date');
            sheet1.cell("D5").value(sel_to_date);

            sheet1.cell("A7").value('SrNo');
            sheet1.cell("B7").value('Employee ID');  
            sheet1.cell("C7").value('Employee Name');  

            var cnt_char1="D";
            list_category1.map((val_catg, key) => { 
                sheet1.cell(cnt_char1+"7").value(val_catg.leave_cat_code);  
                cnt_char1=nextChar(cnt_char1); 
            })

            // console.log(list_empcatleave3.length);

            var cnt_srno2=1;
            var cnt_col1=8;
            list_empcatleave4.map((val_data1, index) => { 

                sheet1.cell("A"+cnt_col1).value(cnt_srno2);
                sheet1.cell("B"+cnt_col1).value(val_data1.emp_code);  
                sheet1.cell("C"+cnt_col1).value(val_data1.emp_name);  

                var cnt_char1="D";
                list_category1.map((val_catg, key) => { 
                    var leave_cat_id=val_catg.leave_cat_id;
                    //var count_leave=(typeof val_data1[leave_cat_id]!='undefined')?val_data1[leave_cat_id]:0;
                    var count_leave=(typeof list_empcatleave3[index+'#'+leave_cat_id]!='undefined')?list_empcatleave3[index+'#'+leave_cat_id]:0;

                    sheet1.cell(cnt_char1+cnt_col1).value(count_leave);  

                    cnt_char1=nextChar(cnt_char1); 
                })

                cnt_srno2++; 
                cnt_col1++;
            })
            

            return workbook.outputAsync().then((res) => {
                saveAs(res, "EmployeeLeaveTaken.xlsx");
            });
        });
    }


    function nextChar(c) {
        var u = c.toUpperCase();
        if (same(u,'Z')){
            var txt = '';
            var i = u.length;
            while (i--) {
                txt += 'A';
            }
            return (txt+'A');
        } else {
            var p = "";
            var q = "";
            if(u.length > 1){
                p = u.substring(0, u.length - 1);
                q = String.fromCharCode(p.slice(-1).charCodeAt(0));
            }
            var l = u.slice(-1).charCodeAt(0);
            var z = nextLetter(l);
            if(z==='A'){
                return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
            } else {
                return p + z;
            }
        }
    }

    function nextLetter(l){
        if(l<90){
            return String.fromCharCode(l + 1);
        }else{
            return 'A';
        }
    }
    
    function same(str,char){
        var i = str.length;
        while (i--) {
            if (str[i]!==char){
                return false;
            }
        }
        return true;
    }

    return (
    <>
        {
            fullScreenLoader ? (
                <FullScreenLoading loading loaderColor="#3498db" />
            ) :  null 
        }
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                
                
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            
                            <div className="col-sm-12 col-lg-12 col-md-12" style={{paddingTop:10}}>
                                <div className="row"> 
                                    <div className="col-sm-6 col-lg-6 col-md-6">
                                        <h4 className="card-title">Employee Leave Taken</h4>
                                    </div> 
                                </div>
                            </div> 

                            <div className="iq-card-body">
                                <div className='row'> 
                                    <div className="form-group col-md-3">
                                        <label>From</label>
                                        <DatePicker
                                           selected={from_date}
                                            onChange={e => setFromDate(e)}
                                            className="form-control"
                                            placeholderText="From"
                                            name="from_date"
                                            dateFormat="dd-MM-yyyy" 
                                            autoComplete="off" 
                                            dropdownMode="select" 
                                            showMonthDropdown
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown   
                                        />
                                    </div>  
                                    <div className="form-group col-md-3">
                                        <label>To</label>
                                        <DatePicker
                                            selected={to_date}
                                            onChange={e => setToDate(e)}
                                            className="form-control"
                                            placeholderText="To"
                                            name="to_date"
                                            dateFormat="dd-MM-yyyy" 
                                            autoComplete="off" 
                                            dropdownMode="select" 
                                            showMonthDropdown
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown   
                                        />
                                    </div> 
                                    <div className="form-group col-md-3">
                                        <button type='button' className='btn btn-success' style={{marginTop:25}} onClick={() => btn_submit1()}>
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button type='button' className='btn btn-primary' style={{marginTop:25}} onClick={() => download_excel()}>
                                            Excel
                                        </button>
                                    </div>
                                </div>


                                <div className='row' style={{marginTop:10}}>   
                                    <div className="form-group col-md-12 table-responsive" style={{marginTop:'10'}}>
                                        <table className="table table-striped table-bordered table-sm center table-hover" role="grid" >
                                            <thead>  
                                                <tr key={0}>
                                                    <th scope='col'>SrNo.</th>
                                                    <th>Employee ID</th>
                                                    <th>Employee Name</th> 
                                                    {
                                                        list_category1.map((val_catg, key) => { 
                                                            return ( <th>{val_catg.leave_cat_code}</th> );  
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {
                                                    list_empcatleave4.length > 0 ?  ( 
                                                        list_empcatleave4.map((val_data1, index) => { 
                                                            cnt_srno1++;
                                                            
                                                            return(
                                                                <tr key={cnt_srno1}>
                                                                    <td>{cnt_srno1}</td>   
                                                                    <td>{val_data1.emp_code}</td>
                                                                    <td>{val_data1.emp_name}</td>
                                                                    {
                                                                        list_category1.map((val_catg, key) => { 
                                                                            var leave_cat_id=val_catg.leave_cat_id;
                                                                            var count_leave=(typeof list_empcatleave3[index+'#'+leave_cat_id]!='undefined')?list_empcatleave3[index+'#'+leave_cat_id]:0;
                                                                            return ( <td>{count_leave}</td> );  
                                                                        })
                                                                    }
                                                                </tr> 
                                                            ) 
                                                        })
                                                    ) : (
                                                        <th colSpan={list_category1.length+4} style={{textAlign:'center'}}> No Data Found.. </th>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Employee_leave_taken