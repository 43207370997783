import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Apiconnect from '../../services/Apiconnect'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";

const page_styles = {
    container: {
        // boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    },
    shadow: {
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
    }
  }



const Kra_manage_list = () => {

    const alert = useAlert();
    let company_id = Apiconnect.get_company_id()

    
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const [list, setList] = useState([])
    useEffect(() => {getRoleWiseKra()}, [])

    const getRoleWiseKra = () => {
        // setFullScreenLoader(true)
        Apiconnect.postData(`kra/getRoleWiseKra/${company_id}`).then(res => {
            setList(res.data.data)
            // setFullScreenLoader(false)
        })
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`kra/kra_delete/${id}`).then(res => {
            getRoleWiseKra()
            alert.success("Deleted")
            setFullScreenLoader(false)
        })
    }

    console.log(list)
 

  return (
    <>
        <div id="content-page" className="content-page bg-light">
        {fullScreenLoader? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-md-12">
                     
                    <div className="iq-card" style={page_styles.container}>
                            <div className="iq-card-header d-flex justify-content-between"
                            >
                                <div className="iq-header-title">
                                    <h4 className="card-title text-muted">Manage Role Wise KRAs</h4>
                                </div>
                            </div>

                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <Link to={`/Kra_manage`}><button className='btn btn-sm btn-primary float-right'>Add New</button></Link>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <table  className="table table-bordered mt-1 center" role="grid" >
                                        <thead className="thead-light">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Added By</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.kra_pool_title}</td>
                                                            <td>{item.addedBy}</td>
                                                            <td>
                                                                <Link to={`/Kra_assign/${item.kra_pool_id}`}><button className='btn btn-sm btn-success mr-2'>Assign Employee</button></Link>
                                                                <Link to={`/Kra_list/${item.kra_pool_id}`}><button className='btn btn-sm btn-info mr-2' >Edit</button></Link>
                                                                <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.kra_pool_id)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Kra_manage_list