import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import {Link} from 'react-router-dom'
import Combine_view_emp_leave_apply from "../../components/combine_view_emp_leave_apply";


const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }
 }
 

function Employee_leave_apply() {
    const alert = useAlert();
    let history = useHistory(); 

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken; 

    let user_name=localuser.username;
      
     
    return (
        <>
            <div id="content-page" className="content-page" style={page_styles.main_container_bg}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Link to="/LeaveEmployee"><button className="btn btn-primary pull-right">Back</button></Link> 
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>

                        {/*================strat_left_div==================*/}
                        <div className="col-lg-12">
                            <Combine_view_emp_leave_apply
                                options={{ 
                                    title: 'Welcome'
                                }}
                                sel_user_id={''} 
                                company_id={Apiconnect.get_company_id()} 
                                sel_user_name={user_name}
                                alert={alert}
                            />
                        </div>
                        {/*================end_left_div==================*/} 

                    </div>
                </div>
            </div>
        </>
    )

}
export default Employee_leave_apply