import React, {useState, useEffect} from 'react'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Apiconnect from '../../services/Apiconnect'
const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }
 }

const Work_force_hierarchy_level = () => {

    const alert = useAlert();
    let company_id = Apiconnect.get_company_id();

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [name, setName] = useState("")
    const [rank, setRank] = useState("")
    const [list, setList] = useState([])
    const [editId, setEditId] = useState("")
 
    useEffect(() => {hierarchy_list()}, [])

    const hierarchy_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_hierarchy_level_list/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setList(_xtract)
            setFullScreenLoader(false)
        })
    }

    const handleSubmit = () => {
        const data = {name:name, rank:rank}
        setFullScreenLoader(true)
        if(editId !== ""){
            if(name !== "" && rank !== ""){
                Apiconnect.postData(`work_force/work_force_hierarchy_level_update/${editId}`, data).then(res => {
                    setName("")
                    setFullScreenLoader(false)
                    hierarchy_list()
                    alert.success("Updated")
                    setEditId("")
                })
            }
        }else{
            if(name !== "" && rank !== ""){
                Apiconnect.postData(`work_force/work_force_hierarchy_level_create/${company_id}`, data).then(res => {
                    setName("")
                    setFullScreenLoader(false)
                    hierarchy_list()
                    alert.success("Created")
                })
            }else{
                alert.error("Input field is empty")
                setFullScreenLoader(false)
            }
        }
    }

    const handleEdit = (id) => {
        const filtered = list.find((item) => item.wfhl_pk_id === id)
        setName(filtered.wfhl_name)
        setEditId(id)
    }


  return (
    <>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />) : null }
        <div className='content-page' style={page_styles.main_container_bg}>
            <div className='container-fluid'>
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head"
                     style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                    >
                        <div className="iq-header-title">
                            <h4 className="card-title text-white">Work Force Hierarchy Level</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="row">
                            <div className='col-md-4 mb-2'>
                                <input type="text" className='form-control' placeholder='Enter Hierarchy Level' name='name' value={name} onChange={(e) => setName(e.target.value)}  />
                            </div>
                            <div className='cl-md-4 mb-2'>
                                <select className='form-control' name='rank' value={rank} onChange={(e) => setRank(e.target.value)}>
                                    <option value="" disabled selected>--Select--</option>
                                    <option value="1">Senior Management</option>
                                    <option value="2">Middle Management</option>
                                    <option value="3">First-level Management</option>
                                </select>
                            </div>
                            <div className='col-md-4 '>
                                <button className='btn btn-sm btn-primary' onClick={handleSubmit}>{editId ? "Update" : "Create"}</button>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <table className="table table-striped table-bordered mt-1 center">
                                    <thead className="">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Hierarchy Level</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                      {
                                        list.length > 0 ?
                                        (list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.wfhl_name}</td>
                                                    <td>
                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => handleEdit(item.wfhl_pk_id)}>Edit</button>
                                                        {/* <button className='btn btn-sm btn-danger mr-2'>Delete</button> */}
                                                    </td>
                                                </tr>
                                            )
                                        }))
                                        :
                                        (
                                                <td colSpan={3}>No Data Found...</td>
                                        )
                                      }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Work_force_hierarchy_level