import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};
const customStyles1 = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Retirement = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);
  const today = new Date();
  const numberOfDaysToAdd = 3;
  const date = today.setDate(today.getDate());
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const [retireEmp, setRetireEmp] = useState("");
  const [retireEmpId, setretireEmpId] = useState("");
  const [retireEmpDesg, setretireEmpDesg] = useState([]);
  const [retireEmpDesgId, setRetireEmpDesgId] = useState("");
  const [vacName, setVacName] = useState("");
  const [list, setList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deglist, setDegList] = useState([]);
  const [deg, setDeg] = useState("");
  const [emps, setEmps] = useState([]);
  const [emp, setEmp] = useState();
  const [retirementDate, setRetirementDate] = useState("");
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [desList, setDesList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [attList, setAttList] = useState([]);
  const [deptId, setDeptId] = useState("");
  const [inputFields, setInputFields] = useState({
    name: "",
    dor: defaultValue,
    attrition: 1,
    onboarding: "",
    location: "",
    unit_details: "",
    reporting_auth: "",
    working_days: "",
    candidate_pref: "",
    location_type: "",
    salary_range: "",
    additional_Perquisites: "",
    vacancies: "1",
    hiring_process: "",
    q1: "",
    q2: "",
    q3: "",
    exp1: "",
    exp2: "",
    justification1: "",
    justification2: "",
    justification3: "",
    key_deliverables1: "",
    key_deliverables2: "",
    key_deliverables3: "",
    key_deliverables4: "",
    key_deliverables5: "",
    other_requisites1: "",
    other_requisites2: "",
    other_requisites3: "",
    raised_by: user_id,
    approving_auth: "",
    added_by: user_id,
    company_id: Apiconnect.get_company_id(),
  });
  const { dor, onboarding, department, location, unit_details, reporting_auth, working_days, candidate_pref, location_type, salary_range, additional_Perquisites, vacancies, hiring_process, q1, q2, q3, exp1, exp2, justification1, justification2, justification3, key_deliverables1, key_deliverables2, key_deliverables3, key_deliverables4, key_deliverables5, other_requisites1, other_requisites2, other_requisites3, raised_by, approving_auth, name } = inputFields;

  const onInputChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getList();
    getDesignation();
    getAttritionList();
    getDesList();
    getDeptList();
    getEmployeeList();
  }, []);

  const getList = () => {
    try {
      Apiconnect.postData(`separation/getAllRetirementList/${Apiconnect.get_company_id()}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setList(extract);
      });
    } catch (error) {
      toast.error("Soemthing Went Wrong");
    }
  };

  const getDesignation = () => {
    try {
      Apiconnect.postData(`separation/getalldesignationsbycompany/${Apiconnect.get_company_id()}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setDegList(extract);
      });
    } catch (error) {
      toast.error("Can't fetch designations");
    }
  };

  const theader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Position</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Age</th>
      <th>Retirement On</th>
      <th>Action</th>
      <th>Shadow</th>
      <th>Retirement Status</th>
    </tr>
  );

  const getallemps = (id) => {
    try {
      Apiconnect.postData(`separation/getAllEmpByDesignation/${Apiconnect.get_company_id()}/${id}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setEmps(extract);
        console.log(extract);
      });
    } catch (error) {
      toast.error("Error While Fetching Employees");
    }
  };

  const handleAssign = () => {
    try {
      const dataIns = {
        emp: emp,
        retireEmpId: retireEmpId,
        retirement_on: retirementDate,
      };
      !emp && toast.error("Please Select Designation & Employee");
      emp &&
        Apiconnect.postData(`separation/assignShadow`, dataIns)
          .then((res) => {
            toast.success("Assigned Successfully");
            getList();
            setModalIsOpen(false);
          })
          .catch((err) => {
            toast.error("Something Went Wrong");
          });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  const updatedList = list.map((item, index) => {
    const retirement_year = item.retirement_on;
    item["retireYear"] = retirement_year.slice(-4);
    return item;
  });

  const now = new Date();
  const year = now.getFullYear();

  const newList = list.filter((item) => Number(item.retireYear) === Number(year + 1));

  const getAttritionList = () => {
    Apiconnect.postData(`work_force/workForce_mst_attrition_getall`).then((res) => {
      let result = Apiconnect.decrypt_obj(res.data.data);
      console.log(result);
      setAttList(result);
    });
  };

  const getDesList = () => {
    Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = response.data.data;
      setDesList(_xtract.reverse());
    });
  };

  const getDeptList = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setDeptList(_xtract.reverse());
    });
  };

  const getEmployeeList = () => {
    Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
    });
  };

  const handleCreateVacancy = () => {
    inputFields["position"] = retireEmpDesgId;
    inputFields["department"] = deptId;
    !name && toast.error("Please Provide Vacancy Name");
    if (name !== "" && dor !== "" && onboarding !== "" && department !== "" && location !== "" && unit_details !== "" && working_days !== "" && candidate_pref !== "" && location_type !== "" && salary_range !== "" && vacancies !== "" && hiring_process !== "" && q1 !== "" && exp1 !== "" && justification1 !== "" && key_deliverables1 !== "" && other_requisites1 !== "" && raised_by !== "" && approving_auth !== "") {
      Apiconnect.postData(`work_force/work_force_requisition_create_by_admin/${Apiconnect.get_company_id()}`, inputFields).then((res) => {
        Apiconnect.postData(`separation/replacementRequest/${retireEmpId}`).then((res) => {
          setInputFields({
            name: "",
            dor: "",
            position: "",
            attrition: "",
            onboarding: "",
            department: "",
            location: "",
            unit_details: "",
            reporting_auth: "",
            working_days: "",
            candidate_pref: "",
            location_type: "",
            salary_range: "",
            additional_Perquisites: "",
            vacancies: "",
            hiring_process: "",
            q1: "",
            q2: "",
            q3: "",
            exp1: "",
            exp2: "",
            justification1: "",
            justification2: "",
            justification3: "",
            key_deliverables1: "",
            key_deliverables2: "",
            key_deliverables3: "",
            key_deliverables4: "",
            key_deliverables5: "",
            other_requisites1: "",
            other_requisites2: "",
            other_requisites3: "",
            raised_by: "",
            approving_auth: "",
            added_by: "",
          });
          getList();
          alert.success("Created");
          setModalIsOpen1(false);
        });
      });
    } else {
      toast.error("Input Fields Are Empty. All Input Fields Must Be Filled !");
    }
  };

  const handleOffer = (id) => {
    Apiconnect.postData(`separation/offerExtension/${id}`).then((res) => {
      toast.success("Success");
    });
  };

  console.log(list);

  return (
    <Container heading="Employee Retirement">
      <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
        <div className="iq-card">
          <div className="iq-card-header card_head">
            <h5 className="text-white">
              Assign Shadow For <span className="font-weight-bold">{retireEmp}</span> <span>({retireEmpDesg})</span>
            </h5>
          </div>
          <div className="iq-card-body ">
            <Card>
              <SelectFromOptions
                className="col-md-6"
                label="Select Designation"
                optionOne="Designation List"
                name="deg"
                value={deg}
                onChange={(e) => {
                  setDeg(e.target.value);
                  getallemps(e.target.value);
                }}
              >
                {deglist.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </SelectFromOptions>
              <SelectFromOptions
                className="col-md-6"
                label="Select Employee"
                optionOne="Employee List"
                name="emp"
                value={emp}
                onChange={(e) => {
                  setEmp(e.target.value);
                }}
              >
                {emps.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.first_name + " " + item.middle_name + " " + item.last_name}
                    </option>
                  );
                })}
              </SelectFromOptions>
              <div className="col-md-12 mt-3 text-center">
                <button className="btn btn-sm btn-info" onClick={handleAssign}>
                  Assign
                </button>
              </div>
            </Card>
          </div>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen1} style={customStyles1} onRequestClose={() => setModalIsOpen1(false)}>
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between model_head" style={{ borderRadius: 5, background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
            <div className="iq-header-title">
              <h4 className="card-title text-white">Create Vacancy For {vacName}</h4>
            </div>
            <button className="btn btn-sm btn-secondary" onClick={() => setModalIsOpen(false)}>
              Close
            </button>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="row m-2 p-2">
                  <div className="col-md-4 mb-3">
                    <label>Vacancy Name</label>
                    <input type="text" className="form-control" name="name" placeholder="Enter Vacancy Name" value={name} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Position</label>
                    <input type="text" className="form-control" value={vacName} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Date of Requisition</label>
                    <input type="date" className="form-control" name="dor" value={dor} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Attrition Type</label>
                    <input type="text" value="Replacement" className="form-control" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Expected Date of onboarding</label>
                    <input type="date" className="form-control" placeholder="Date of Requisition" name="onboarding" value={onboarding} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Department</label>
                    {/* <input type='text' className='form-control' placeholder='Department' name='department' value={department} onChange={e => onInputChange(e)} autoComplete='off'/>  */}
                    <select className="form-control" name="department" value={deptId}>
                      <option value="" disabled selected>
                        --Select--
                      </option>
                      {deptList.map((item, index) => {
                        return (
                          <option key={index} value={deptId}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Location (Exact)</label>
                    <input type="text" className="form-control" placeholder="Enter Location" name="location" value={location} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Unit details (Additional in any)</label>
                    <input type="text" className="form-control" placeholder="Enter Unit details" name="unit_details" value={unit_details} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Reporting Authority</label>
                    {/* <input type='text' className='form-control' placeholder='Enter Reporting Authority' name='reporting_auth' value={reporting_auth} onChange={e => onInputChange(e)} autoComplete='off'/> */}
                    <select className="form-control" name="reporting_auth" value={reporting_auth} onChange={(e) => onInputChange(e)}>
                      <option value="" disabled selected>
                        --Select--
                      </option>
                      {empList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.first_name + " " + item.last_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Working days/Time</label>
                    <input type="text" className="form-control" placeholder="Enter Working days/Time" name="working_days" value={working_days} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Candidate Preference</label>
                    <select className="form-control" name="candidate_pref" value={candidate_pref} onChange={(e) => onInputChange(e)}>
                      <option value="">--Select--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Any">Any</option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Local/Outstation</label>
                    <select className="form-control" name="location_type" value={location_type} onChange={(e) => onInputChange(e)}>
                      <option value="">--Select--</option>
                      <option value="Local">Local</option>
                      <option value="Outstation">Outstation</option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Salary Range (Lakhs Per Annum)</label>
                    <input type="number" className="form-control" placeholder="Enter Salary Range" name="salary_range" value={salary_range} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Any Additional Perquisites</label>
                    <input type="text" className="form-control" placeholder="Enter Additional Perquisites" name="additional_Perquisites" value={additional_Perquisites} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Number of Vacancies</label>
                    <input type="number" className="form-control" placeholder="Enter Number of Vacancies" name="vacancies" value="1" autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Hiring process (incase different from the standard process shared in Recruitment policy)</label>
                    <input type="text" className="form-control" placeholder="Hiring process" name="hiring_process" value={hiring_process} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Qualification-1</label>
                        <input type="text" className="form-control" placeholder="Enter Qualification" name="q1" value={q1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Qualification-2</label>
                        <input type="text" className="form-control" placeholder="Enter Qualification" name="q2" value={q2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Qualification-3</label>
                        <input type="text" className="form-control" placeholder="Enter Qualification" name="q3" value={q3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-6 mb-3">
                        <label>Experience-1</label>
                        <input type="text" className="form-control" placeholder="Enter Experience" name="exp1" value={exp1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Experience-2</label>
                        <input type="text" className="form-control" placeholder="Enter Experience" name="exp2" value={exp2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Justification for adding the position-1</label>
                        <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification1" value={justification1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Justification for adding the position-2 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification2" value={justification2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Justification for adding the position-3 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification3" value={justification3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables1" value={key_deliverables1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-2 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables2" value={key_deliverables2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-3 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables3" value={key_deliverables3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-4 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables4" value={key_deliverables4} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-5 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables5" value={key_deliverables5} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Any other requisites to be considered while screening </label>
                        <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites1" value={other_requisites1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Any other requisites to be considered while screening-2 </label>
                        <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites2" value={other_requisites2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Any other requisites to be considered while screening-3 </label>
                        <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites3" value={other_requisites3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-md-6 mb-3'>
                                        <label>Raised by</label>
                                        <input type='text' className='form-control' placeholder='Raised by' name='raised_by' value={raised_by} onChange={e => onInputChange(e)} autoComplete='off'/> 
                                    </div> */}
                  <div className="col-md-6 mb-3">
                    <label>Approving Authority</label>
                    <input type="text" className="form-control" placeholder="Approving Authority" name="approving_auth" value={approving_auth} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-2 p-2 mt-4">
              <div className="col-md-12" align="center">
                <button className="btn btn-sm btn-success" onClick={handleCreateVacancy}>
                  Create
                </button>
                &nbsp;
                <button className="btn btn-sm btn-danger" onClick={() => setModalIsOpen(false)}>
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Card>
        <Table className="col-md-12" trHeader={theader}>
          {newList.map((item, index) => {
            let st = <span className="badge badge-pill badge-warning">Pending</span>;
            if (item.retirement_st == 1) {
              st = <span className="badge badge-pill badge-success">Retirement Scheduled</span>;
            }
            return (
              <tr className={`text-center ${item.retirement_st === 1 && "table-info"}`} key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.designation}</td>
                <td>{item.emp_code}</td>
                <td>{item.email}</td>
                <td>{item.age}</td>
                <td>{item.retirement_on}</td>
                <td className="text-center">
                  <div className="display-flex justify-content-between">
                    {!item.replacement_reqst && !item.shadowEmp_name && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setModalIsOpen1(true);
                          setVacName(item.designation);
                          setRetireEmpDesgId(item.emp_designation);
                          setretireEmpId(item.id);
                          setDeptId(item.emp_category);
                        }}
                      >
                        Create Replacement Vacancy
                      </button>
                    )}
                    {!item.shadowEmp_name && !item.replacement_reqst && (
                      <button
                        className="btn btn-sm btn-info m-2"
                        onClick={() => {
                          setModalIsOpen(true);
                          setRetireEmp(item.name);
                          setretireEmpDesg(item.designation);
                          setretireEmpId(item.id);
                          setRetirementDate(item.retirement_on);
                          setRetireEmpDesgId(item.emp_designation);
                        }}
                      >
                        Assign Shadow
                      </button>
                    )}
                    {item.offer_extension == 0 && (
                      <button className={`btn btn-sm btn-info ${item.retirement_st == 1 && "disabled"}`} onClick={() => handleOffer(item.id)}>
                        Offer Extension
                      </button>
                    )}
                    <div>
                      {item.offer_extension == 1 && <span className="badge badge-pill badge-warning ">Offered Extension</span>}
                      {item.offer_extension == 2 && <span className="badge badge-pill badge-danger ">Rejected Extension</span>}
                    </div>
                  </div>
                </td>
                <td>{item.shadowEmp_name ? <span className="badge badge-light">{item.shadowEmp_name + ", " + item.shadowEmp_empCode + ", " + item.shadowEmp_email}</span> : <span className="badge badge-pill badge-info">Shadow Teacher Not Assigned</span>}</td>
                <td>{item.extension_on ? <span className="badge badge-pill badge-primary ">Retirement Extended to {item.extension_on}</span> : st}</td>
              </tr>
            );
          })}
        </Table>
      </Card>
    </Container>
  );
};

export default Retirement;
