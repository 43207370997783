import React, {useState, useEffect} from 'react'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Apiconnect from '../../services/Apiconnect'
import Modal from 'react-modal';

const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
        borderRadius: 10
    }

}

const Wings_manage = () => {

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id();
    const alert = useAlert();

    const [datawing, setdatawing] = useState({
        wing_name: "",
        wing_ratio_studentteacher: "",
        wing_ratio_teacher_per_section: "",
        wing_avg_student_strength:"",
        wing_avg_no_of_period_min:"",
        wing_avg_no_of_period_max:"",
        company_id : company_id,
        wing_added_user_id:user_id,
        sel_wing_id:0,
    }); 
    const {wing_name, wing_ratio_studentteacher, wing_ratio_teacher_per_section,wing_avg_student_strength,wing_avg_no_of_period_min,wing_avg_no_of_period_max,sel_wing_id} = datawing;
    
    

    /* ---Modal--- */
    const customStyles = {
        content: {
           top: '55%',
           left: '55%',
           right: 'auto',
           bottom: 'auto', 
           transform: 'translate(-50%, -50%)', 
        },
    };

    useEffect(() => { getLists_wings(); }, []);
    const [lists_wings, setlists_wings] = useState([]);
    const getLists_wings = () => { 
        setFullScreenLoader(true);
        Apiconnect.postData("manage_wings/get_wings_lists", {company_id:company_id}).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            setlists_wings(_xtract);  
            setFullScreenLoader(false);
            setModalIsOpen(false);
            setdatawing({ 
                wing_name: '',
                wing_ratio_studentteacher: "",
                wing_ratio_teacher_per_section: "",
                wing_avg_student_strength:"",
                wing_avg_no_of_period_min:"",
                wing_avg_no_of_period_max:"", 
                wing_added_user_id:user_id,
            });
        });
    };

    const fun_addedit = (selwing_id) => {  
        setModalIsOpen(true);  
        if(selwing_id!=0){
            const data_edi1 = lists_wings.find((valitem) => valitem.wing_pk_id === selwing_id); 
            setdatawing({ 
                sel_wing_id:selwing_id,
                wing_name: data_edi1.wing_name,
                wing_ratio_studentteacher: data_edi1.wing_ratio_studentteacher,
                wing_ratio_teacher_per_section: data_edi1.wing_ratio_teacher_per_section,
                wing_avg_student_strength: data_edi1.wing_avg_student_strength,
                wing_avg_no_of_period_min: data_edi1.wing_avg_no_of_period_min,
                wing_avg_no_of_period_max: data_edi1.wing_avg_no_of_period_max, 
                wing_added_user_id:user_id,
            });
        }else{
            setdatawing({ 
                sel_wing_id:0,
                wing_name: '',
                wing_ratio_studentteacher: "",
                wing_ratio_teacher_per_section: "",
                wing_avg_student_strength:"",
                wing_avg_no_of_period_min:"",
                wing_avg_no_of_period_max:"", 
                wing_added_user_id:user_id,
            });
        }
    }

    const handle_submit = () => { 
    }
    const onInputChange = e => {
        setdatawing({ ...datawing, [e.target.name]: e.target.value ,company_id : company_id,wing_added_user_id:user_id});
    };

    // const onInputChange_wname = e => {
    //     setdatawing({ wing_name:e.target.value});

    //     Apiconnect.postData("manage_wings/get_wing_details_by_name", { wing_name:e.target.value}).then((response) => {   
    //         let _xtract = Apiconnect.decrypt_obj(response.data.data);  
    //         if(_xtract.length!=0){
    //             setdatawing({ wing_name:''});
    //             alert.error('Name Already Exits....');
    //         } 
    //     }); 
    // };
    

    const onSubmit = async e => {  
        e.preventDefault();  
        // console.log(sel_wing_id);
        setFullScreenLoader(true);
        if(typeof sel_wing_id=='undefined' || sel_wing_id==0){
            function_wing_add();
        }else{
            function_wing_edit();
        } 
    }
    const function_wing_add = () => { 
        
        Apiconnect.postData("manage_wings/wings_create", datawing).then((response) => {  
            setModalIsOpen(false);
            setdatawing({sel_wing_id:0});
            getLists_wings();
            setFullScreenLoader(false);
            alert.success("Submitted Successfully");
        });
    }

    const function_wing_edit = () => { 
        Apiconnect.postData("manage_wings/wings_update", datawing).then((response) => { 
            setModalIsOpen(false);
            setdatawing({sel_wing_id:0}); 
            getLists_wings();
            setFullScreenLoader(false);
            alert.success("Submitted Successfully");
        });
    }



    


    

  return (
    <>
        {fullScreenLoader ? (
            <FullScreenLoading loading loaderColor="#3498db" />
        ) : 
            null 
        }
        <div id="content-page" className="content-page" style={page_styles.main_container_bg}>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
            <form onSubmit={e => onSubmit(e)}>
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between model_head"
                 style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                >
                    <div className="iq-header-title">
                        <h4 className="card-title text-white">Wing </h4>
                    </div>
                </div>

                <div className="iq-card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='row m-2 p-2 '>
                                <div className='col-md-6 mb-3'>
                                    <label>Wing name</label>
                                    <input type="text"  className='form-control'  placeholder='Enter Wing Name' name='wing_name'  value={wing_name} onChange={e => onInputChange(e)}  autoComplete='off' required/>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label>Student Teacher Ratio</label>
                                    <input type="number" className='form-control' placeholder='Student Teacher Ratio' name='wing_ratio_studentteacher' value={wing_ratio_studentteacher} onChange={e => onInputChange(e)} autoComplete='off'/>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label>Teacher Section Ratio</label>
                                    <input type="number" className='form-control' placeholder='Teacher Section Ratio' name='wing_ratio_teacher_per_section' value={wing_ratio_teacher_per_section} onChange={e => onInputChange(e)} autoComplete='off'/>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label>AVG student strength </label>
                                    <input type="number" className='form-control' placeholder='AVG student strength' name='wing_avg_student_strength' value={wing_avg_student_strength} onChange={e => onInputChange(e)} autoComplete='off'/>
                                </div>
                                <div className='col-md-6'>
                                    <label>Min AVG no of periods </label>
                                    <input type="number" className='form-control' placeholder='Min Average No. Of Period' name='wing_avg_no_of_period_min' value={wing_avg_no_of_period_min} onChange={e => onInputChange(e)} autoComplete='off' />
                                </div>
                                <div className='col-md-6'>
                                    <label>Max AVG no of periods </label>
                                    <input type="number" className='form-control' placeholder='Max Average No. Of Period' name='wing_avg_no_of_period_max' value={wing_avg_no_of_period_max} onChange={e => onInputChange(e)} autoComplete='off'/>
                                </div>
                            </div>
                            <div className='row m-2 p-2 mt-4'>
                                <div className='col-md-12' align="center">
                                    <button className='btn btn-success' type='submit'>Submit</button>
                                    &nbsp;
                                    <button className="btn btn-danger" onClick={() => setModalIsOpen(false)}>close</button>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
            </form>
            </Modal>
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-md-12">
                        <div className="iq-card mt-3" style={page_styles.main_container_shadows}>
                            <div className="navbar" style={page_styles.card_head}>
                                <div className="iq-header-title">
                                    <h4 className="card-title text-white">Manage Wing</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-primary float-right' onClick={() => fun_addedit(0)}>Create</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <table  className="table table-bordered mt-1 center" style={page_styles.shadow} >
                                        <thead className="thead-light">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Student Teacher Ratio</th>
                                            <th scope="col">Teacher Per Section Ratio</th>
                                            <th scope="col">Average Student Strength</th>
                                            <th scope="col">Min Average No. Of Period</th>
                                            <th scope="col">Max Average No. Of Period</th>
                                            <th scope="col">Status</th> 
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {   
                                            lists_wings.length>0 ?  (

                                                lists_wings.map((valwing, key) => {  
                                                    let res_status=<div className='badge badge-warning p-2'>InActive</div>
                                                    if(valwing.wing_status_id==1){
                                                        res_status=<div className='badge badge-info p-2'>Active</div>
                                                    }
                                                    return(
                                                        <tr key={key}>
                                                            <td scope="row">{key + 1}</td> 
                                                            <td>{valwing.wing_name}</td> 
                                                            <td>{valwing.wing_ratio_studentteacher}</td> 
                                                            <td>{valwing.wing_ratio_teacher_per_section}</td> 
                                                            <td>{valwing.wing_avg_student_strength}</td> 
                                                            <td>{valwing.wing_avg_no_of_period_min}</td> 
                                                            <td>{valwing.wing_avg_no_of_period_max}</td> 
                                                            <td>{res_status}</td>
                                                            <td>
                                                                {
                                                                    valwing.wing_status_id==1 ? 
                                                                     <button style={{marginRight: 10}}  className="btn badge badge-pill badge-success" onClick={() => fun_addedit(valwing.wing_pk_id)} >Edit</button>
                                                                    :''
                                                                }
                                                            </td>
                                                        </tr> 
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                </tr>
                                            ) 
                                        } 
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Wings_manage