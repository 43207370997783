import React, { useEffect, useState } from "react";
import { pageStyles } from "../../../globalStyles";
import Apiconnect from "../../../services/Apiconnect";
import { useAlert } from "react-alert";
import toast, { Toaster } from "react-hot-toast";
import FullScreenLoading from "react-fullscreen-loading";
import Container from "../../../components/reusable/Container";
import Card from "../../../components/reusable/Card";
import SelectFromOptions from "../../../components/reusable/SelectFromOptions";
import Input from "../../../components/reusable/Input";


const Grievance_cat = () => {
  const [loading, setLoading] = useState(false);
  const [grievanceDeptList, setGrievanceDeptList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [empListByCat, setEmpListByCat] = useState("");
  const [inputs, setInputs] = useState({ gc_company_id: Apiconnect.get_company_id(), gc_gd_dept_id: "", gc_name: "", gc_categoryHead_empId: "", dept: "" });
  const [errors, setErrors] = useState({ gc_gd_dept_id_er: "", gc_name_er: "", gc_categoryHead_empId_er: "", dept_err: "" });
  const [editId, setEditId] = useState("");
  const [list, setList] = useState([]);

  const { gc_gd_dept_id, gc_name, gc_categoryHead_empId, gc_dept, gc_company_id } = inputs;
  const { gc_gd_dept_id_er, gc_name_er, gc_categoryHead_empId_er, gc_dept_err } = errors;

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getGrievanceDeptList();
    getDeptList();
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    try {
      const response = await Apiconnect.postData(`grievance/grievanceCategoryGetall/${Apiconnect.get_company_id()}`);
      const resultData = response.data.data;
      setList(resultData);
    } catch (error) {
      console.log(error);
    }
  };

  const getGrievanceDeptList = async () => {
    try {
      const response = await Apiconnect.postData(`grievance/getalldepartmentwiseHead/${Apiconnect.get_company_id()}`);
      const responseData = response.data.data;
      setGrievanceDeptList(responseData);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getDeptList = () => {
    const company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setDeptList(_xtract.reverse());
    });
  };
  const getEmpListByCat = async (catId) => {
    try {
      const dataInsert = {
        catId: catId,
        companyId: Apiconnect.get_company_id(),
      };
      const response = await Apiconnect.postData(`grievance/getEmpListByCat`, dataInsert);
      const result = response.data.data;
      setEmpListByCat(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const validate = () => {
    const error = {
      gc_gd_dept_id_er: "",
      gc_name_er: "",
      gc_categoryHead_empId_er: "",
      gc_dept_err: "",
    };
    if (gc_categoryHead_empId === "") {
      error.gc_categoryHead_empId_er = "Category head not selected";
    }
    if (gc_gd_dept_id === "") {
      error.gc_gd_dept_id_er = "Grievance department not selected";
    }
    if (gc_name === "") {
      error.gc_name_er = "Category name is empty";
    }
    if (gc_dept === "") {
      error.dept_err = "Please select department";
    }
    setErrors(error);
    return !Object.values(errors).some((error) => error);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    validate();
    if (validate() && gc_categoryHead_empId !== "" && gc_gd_dept_id !== "" && gc_name !== "" && gc_dept !== "") {
      if (editId) {
        const insertOb = {
          gc_name: gc_name,
          gc_dept: gc_dept,
          gc_categoryHead_empId: gc_categoryHead_empId,
        };
        const request = await Apiconnect.postData(`grievance/grievanceCategoryUpdate/${editId}`, insertOb);
        setInputs({ gc_company_id: Apiconnect.get_company_id(), gc_gd_dept_id: "", gc_name: "", gc_categoryHead_empId: "", gc_dept: "" });
        setEditId("");
        toast.success("Updated");
        getCategoryList();
      } else {
        try {
          const dataInsert = {
            gc_company_id: gc_company_id,
            gc_gd_dept_id: gc_gd_dept_id,
            gc_name: gc_name,
            gc_categoryHead_empId: gc_categoryHead_empId,
            gc_dept: gc_dept,
          };
          const response = await Apiconnect.postData(`grievance/grievanceCategoryCreate`, dataInsert);
          setInputs({ gc_company_id: Apiconnect.get_company_id(), gc_gd_dept_id: "", gc_name: "", gc_categoryHead_empId: "", gc_dept: "" });
          toast.success("Created");
          getCategoryList();
        } catch (error) {
          toast.error("Error");
        }
      }
    } else {
      toast.error("Validation Error");
    }
  };
  const handleEdit = (id) => {
    setEditId(id);
    const filteded = list.find((item) => item.gc_pk_id === id);
    getEmpListByCat(filteded.gc_dept);
    setInputs({
      gc_company_id: Apiconnect.get_company_id(),
      gc_gd_dept_id: filteded.gc_gd_dept_id,
      gc_name: filteded.gc_name,
      gc_categoryHead_empId: filteded.gc_categoryHead_empId,
      gc_dept: filteded.gc_dept,
    });
  };

  console.log(list);

  return (
    <>
      <Container heading="Grievance Category & Category Incharge">
        <Card>
          <SelectFromOptions className="col-md-3 m-2" label="Grievance Department" name="gc_gd_dept_id" value={gc_gd_dept_id} optionOne="Select Grievance Department" onChange={(e) => onInputChange(e)}>
          {grievanceDeptList.map((item, index) => {
                return (
                  <option key={index} value={item.gd_pk_id}>
                    {item.departmentName}
                  </option>
                );
              })}
          </SelectFromOptions>
          <Input className="col-md-3 m-2" label="Grievance Category Name" type="text" placeholder="Please Enter Grievance Category" name="gc_name" value={gc_name} onChange={(e) => onInputChange(e)} error={gc_name_er} />
          <div className="col-md-3 m-2">
            <label className="text-muted">Select Category Incharge From</label>
            <select
              className="form-control text-center"
              name="gc_dept"
              value={gc_dept}
              onChange={(e) => {
                onInputChange(e);
                getEmpListByCat(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Select Department
              </option>
              {deptList.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <span className="required">{gc_dept_err}</span>
          </div>
          <div className="col-md-3 m-2">
            <label className="text-muted">Select Category Incharge</label>
            <select className="form-control" name="gc_categoryHead_empId" value={gc_categoryHead_empId} onChange={(e) => onInputChange(e)}>
              <option value="" disabled selected>
                Select Category Incharge
              </option>
              {empListByCat.length > 0 &&
                empListByCat.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.first_name + " " + item.last_name + " " + item.last_name}
                    </option>
                  );
                })}
            </select>
            <span className="required">{gc_categoryHead_empId_er}</span>
          </div>
          <div className="col-md-12 mt-3 text-center">
            <button className={`btn btn-sm ${editId ? "btn-info" : "btn-primary"}`} onClick={(e) => handleCreate(e)}>
              {editId ? "Update" : "Create"}
            </button>
          </div>
        </Card>
        <Card>
          <div className="col-md-12 table-reponsive">
            <table className="table table-bordered" style={pageStyles.main_container}>
              <thead className="thead-light">
                <tr className="text-center">
                  <th>#</th>
                  <th>Grievance Department</th>
                  <th>Grievance Category</th>
                  <th>Category Incharge</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => {
                  return (
                    <tr className="text-center" key={index}>
                      <th>{index + 1}</th>
                      <th>{item.grievanceDepartment}</th>
                      <th>{item.gc_name}</th>
                      <th>{item.categoryHead}</th>
                      <th>
                        <button className="btn btn-sm btn-info" onClick={() => handleEdit(item.gc_pk_id)}>
                          Edit
                        </button>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default Grievance_cat;
