import React, {useState, useEffect } from "react";
import Apiconnect from "../../services/Apiconnect.js";

const Employee_manage_view = (props) => {
    const emp_id = props.emp_id;

    useEffect(()=>{viewEmployee(emp_id);},[]);

    const [info, setInfo] = useState({
        emp_code: '', first_name: '', middle_name: '', last_name: '', phone: '', email: '', password: '',
        gender: '', department: '', employment_type: '', emp_shift: '',
        company_id: Apiconnect.get_company_id(), reporting_officer: '', salary_template_id: '', ctc_yearly: '', date_of_birth: ''
     });
     const { emp_code, first_name, middle_name, last_name, phone, email, password, date_of_birth,
        gender, department, employment_type, emp_shift, reporting_officer, ctc_yearly, salary_template } = info;
  
     const [info2, setInfo2] = useState({
        employee_mst_id: '', present_address_one: '', present_address_two: '', present_landmark: '', present_area_id: '', present_city_id: '',
        present_state_id: '', present_country_id: 1, present_zip: '', parmanent_address_one: '', parmanent_address_two: '',
        parmanent_landmark: '', parmanent_area_id: '', parmanent_city_id: '', parmanent_state_id: '', parmanent_country_id: 1,
        parmanent_zip: '', phone_secondary: '', email_secondary: '', blood_group: '', religion: '', caste: '', nationality: '', marital_status: '',
        anniversary_date: '', present_area: '', parmanent_area: '',
        emergency_contact_person: '', emergency_contact_number: '', emergency_relationship_person_id: '',
        emergency_contact_person2: '', emergency_contact_number2: '', emergency_relationship_person_id2: '',
        fax:'',fax1:'',father_name:'',	spouse_name:'',
        pan: '', passport_no: '', aadhaar: '', payment_mode: ''
     });
  
     const { employee_mst_id, present_address_one, present_address_two, present_landmark, present_area_id, present_city_id,
        present_state_id, present_country_id, present_zip, parmanent_address_one, parmanent_address_two,
        parmanent_landmark, parmanent_area_id, parmanent_city_id, parmanent_state_id, parmanent_country_id,
        parmanent_zip, phone_secondary, email_secondary, blood_group, religion, caste, nationality, marital_status,
        anniversary_date, present_area, parmanent_area,
        emergency_contact_person, emergency_contact_number, emergency_relationship_person_id,
        emergency_contact_person2, emergency_contact_number2, emergency_relationship_person_id2,
         passport_no,fax,fax1,father_name,spouse_name } = info2;
  
        const [info3, setInfo3] = useState({
          bank_name: '',
           ifsc_code: '', account_number: '', tax_borne_by_copany: 1, is_senior_citizen: 1, is_epf: 1, UAN_number: '', epf_number: '',
           pf_start_date: '', is_esi: 1, esi_number: '', dispencery: '', pan: '', aadhaar: '', payment_mode: '',
         
           confirmation_date: '', joining_date: '', resign_date: '',
           leaving_date: '', promotion_date: '', transfer_date: '',notice_period:''
       });
       const {bank_name, ifsc_code, account_number, is_epf, UAN_number, epf_number,
           pf_start_date, is_esi, esi_number, dispencery, pan, aadhaar, payment_mode, confirmation_date, joining_date, resign_date,
           leaving_date, promotion_date, transfer_date,notice_period } = info3;

    const viewEmployee = (id) => {

        Apiconnect.getData(`employee/allempinfobyid/${id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log("_xtract123",_xtract);
            setInfo({
               emp_code: _xtract[0].emp_code, first_name: _xtract[0].first_name, middle_name: _xtract[0].middle_name,
               last_name: _xtract[0].last_name, phone: _xtract[0].phone, email: _xtract[0].email, gender: _xtract[0].gender,
               department: _xtract[0].department, emp_shift: _xtract[0].emp_shift,
               reporting_officer: _xtract[0].reporting_officer, employment_type: _xtract[0].employement_type, ctc_yearly: _xtract[0].ctc_yearly,
               salary_template: _xtract[0].salary_template, date_of_birth: _xtract[0].date_of_birth
            });
   
            var present_address_one = _xtract[0].present_address_one !== null ? _xtract[0].present_address_one : '';
            var present_landmark = _xtract[0].present_landmark !== null ? _xtract[0].present_landmark : '';
            var present_area = _xtract[0].present_area !== null ? _xtract[0].present_area : '';
            var present_city_id = _xtract[0].present_city_id !== null ? _xtract[0].present_city_id : '';
            var present_state_id = _xtract[0].present_state_id !== null ? _xtract[0].present_state_id : '';
            var present_country_id = _xtract[0].present_country_id !== null ? _xtract[0].present_country_id : '';
            var present_zip = _xtract[0].present_zip !== null ? _xtract[0].present_zip : '';
   
            var parmanent_address_one = _xtract[0].parmanent_address_one !== null ? _xtract[0].parmanent_address_one : '';
            var parmanent_landmark = _xtract[0].parmanent_landmark !== null ? _xtract[0].parmanent_landmark : '';
            var parmanent_area = _xtract[0].parmanent_area !== null ? _xtract[0].parmanent_area : '';
            var parmanent_city_id = _xtract[0].parmanent_city_id !== null ? _xtract[0].parmanent_city_id : '';
            var parmanent_state_id = _xtract[0].parmanent_state_id !== null ? _xtract[0].parmanent_state_id : '';
            var parmanent_country_id = _xtract[0].parmanent_country_id !== null ? _xtract[0].parmanent_country_id : '';
            var parmanent_zip = _xtract[0].parmanent_zip !== null ? _xtract[0].parmanent_zip : '';
   
   
            setInfo2({
               employee_mst_id: _xtract[0].employee_mst_id, present_address_one: present_address_one, present_address_two: _xtract[0].present_address_two,
               present_landmark: present_landmark, present_city_id: present_city_id,
               present_state_id: present_state_id, present_country_id: present_country_id, present_zip: present_zip,
               parmanent_address_one: parmanent_address_one, parmanent_address_two: _xtract[0].parmanent_address_two,
               parmanent_landmark: parmanent_landmark, parmanent_area_id: parmanent_area, parmanent_city_id: parmanent_city_id,
               parmanent_state_id: parmanent_state_id, parmanent_country_id: parmanent_country_id, parmanent_zip: parmanent_zip,
               email_secondary: _xtract[0].email_secondary, phone_secondary: _xtract[0].phone_secondary, blood_group: _xtract[0].blood_group, anniversary_date: _xtract[0].anniversary_date,
               religion: _xtract[0].religion, caste: _xtract[0].caste, nationality: _xtract[0].nationality,
               marital_status: _xtract[0].marital_status, present_area: present_area, parmanent_area: parmanent_area,
               fax: _xtract[0].fax,fax1: _xtract[0].fax1,
               father_name: _xtract[0].father_name,spouse_name: _xtract[0].spouse_name,
               emergency_contact_person: _xtract[0].emergency_contact_person,
               emergency_relationship_person_id: _xtract[0].emergency_relationship_person_id,
               emergency_contact_number: _xtract[0].emergency_contact_number,
   
               emergency_contact_person2: _xtract[0].emergency_contact_person2,
               emergency_relationship_person_id2: _xtract[0].emergency_relationship_person_id2,
               emergency_contact_number2: _xtract[0].emergency_contact_number2,
               passport_no: _xtract[0].passport_no,id: _xtract[0].id
            });
            
            var is_esi = _xtract[0].is_esi === '1' ? 'Yes' : 'No';
            var is_epf = _xtract[0].is_epf === '1' ?'Yes' : 'No';
   
            setInfo3({ bank_name: _xtract[0].bank_name, ifsc_code: _xtract[0].ifsc_code,
               account_number: _xtract[0].account_number, tax_borne_by_copany: _xtract[0].tax_borne_by_copany, is_senior_citizen: _xtract[0].is_senior_citizen,
               is_epf: is_epf,UAN_number: _xtract[0].UAN_number,is_esi:is_esi,
               esi_number: _xtract[0].esi_number, dispencery: _xtract[0].dispencery,pf_start_date: _xtract[0].pf_start_date,
               pan: _xtract[0].pan,epf_number:_xtract[0].epf_number,
               
               confirmation_date: _xtract[0].confirmation_date, joining_date: _xtract[0].joining_date, resign_date: _xtract[0].resign_date,
               leaving_date: _xtract[0].leaving_date, promotion_date: _xtract[0].promotion_date, transfer_date: _xtract[0].transfer_date,
               notice_period: _xtract[0].	notice_period, aadhaar: _xtract[0].aadhaar, payment_mode: _xtract[0].payment_mode,
            
            });
          
         });

         Apiconnect.getData(`employee_info/docgetall/${id}`).then((response) => {
            setdocs(response.data.data.reverse());   
           
         });
    }

    const [docs, setdocs] = useState([]);
           
        return (
            <div className="bs-example">
            <ul className="nav nav-tabs">
               <li className="nav-item">
                  <a href="#personnel" className="nav-link active" data-toggle="tab">Personal</a>
               </li>
               <li className="nav-item">
                  <a href="#add_on_info" className="nav-link" data-toggle="tab">Other Details</a>
               </li>
               <li className="nav-item">
                  <a href="#bank" className="nav-link" data-toggle="tab">Financial</a>
               </li>
               <li className="nav-item">
                  <a href="#employeement" className="nav-link" data-toggle="tab">Employment Details</a>
               </li>
               
            </ul>

            <div className="tab-content" style={{height:'350px'}}>
               <div className="tab-pane fade show active" id="personnel">

                  <table>
                     <tr>
                        <th className="p_2 w_20">Code</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{emp_code}</td>
                        <th className="p_2 w_20"></th>
                        <td className="p_2 w_5"></td>
                        <td className="p_2 w_20"></td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Name</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{first_name + ' ' + middle_name + ' ' + last_name}</td>
                        <td className="p_2 w_20"></td>
                        <td className="p_2 w_5"></td>
                        <td className="p_2 w_20"></td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Phone</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{phone}</td>
                        <th className="p_2 w_20">Email</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{email}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Gender</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{gender}</td>
                        <th className="p_2 w_20">Date Of Birth</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{date_of_birth}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Department</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{department}</td>
                        <th className="p_2 w_20">Type  </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{employment_type}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Reporting Officer</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{reporting_officer}</td>
                        <th className="p_2 w_20">Salary Template </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{salary_template}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Shift</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{emp_shift}</td>
                        <th className="p_2 w_20">Yearly CTC </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{ctc_yearly}</td>
                     </tr>

                     {/* <tr style={{paddingTop:'10px;'}}>
                        <th className="p_2 w_20">Documents</th>
                        <td colSpan="4" className="p_2 w_5">
                           <div>
                           {
                                docs.map((val, key) => { 
                                 return(
                                    <div> {key+1}) 
                                       <a href={process.env.React_App_BASE_URL_API+'/uploads/emppreviousdocs/'+val.name} target="_blank" rel="noopener noreferrer" download>
                                          {val.name} <i className="fa fa-download"></i>
                                       </a>  <br/>
                                    </div>
                                 )
                                })
                           }
                           </div>
                        </td>
                       
                     </tr> */}
                  </table>
               </div>

               <div className="tab-pane fade" id="bank">
            
                  <table>
                     <tr>
                        <th className="p_2 w_20">Bank Name</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{bank_name}</td>
                        <th className="p_2 w_20">IFSC Code</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{ifsc_code}</td>
                     </tr>
                    
                     <tr>
                        <th className="p_2 w_20">Bank A/C No</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{account_number}</td>
                        <th className="p_2 w_20">PF Active Or Not</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{is_epf}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">UAN</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{UAN_number}</td>
                        <th className="p_2 w_20">EPF Number</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{epf_number}</td>
                     </tr>
                  
                     <tr>
                        <th className="p_2 w_20">PF Start Date</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{pf_start_date}</td>
                        <th className="p_2 w_20">ESI Member  </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{is_esi}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">ESI No</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{esi_number}</td>
                        <th className="p_2 w_20">Dispensary </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{dispencery}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Pan Card Number</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{pan}</td>
                        <th className="p_2 w_20">Aadhar Number</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{aadhaar}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Payment Mode</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{payment_mode}</td>
                        <th className="p_2 w_20"> </th>
                        <th className="p_2 w_5"></th>
                        <td className="p_2 w_20"></td>
                     </tr>
                  </table>
               </div>
               <div className="tab-pane fade" id="employeement">
                    <table>
                    <tr>
                        <th className="p_2 w_20">Joining Date</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{joining_date}</td>
                        <th className="p_2 w_20">Confirmation Date </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{confirmation_date}</td>
                     </tr>
                  
                     <tr>
                        <th className="p_2 w_20">Promotion Date</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{promotion_date}</td>
                        <th className="p_2 w_20">Transfer Date </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{transfer_date}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Resignation Date</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{resign_date}</td>
                        <th className="p_2 w_20">Leaving Date </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{leaving_date}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Notice Period</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{notice_period}</td>
                        <th className="p_2 w_20"></th>
                        <th className="p_2 w_5"></th>
                        <td className="p_2 w_20"></td>
                     </tr>
                    </table>
               </div>

               <div id="add_on_info" className="tab-pane fade" >
                  <table>
                     <tr>
                        <th className="p_2">Present Address</th>
                        <th className="p_2">:</th>
                        <td colSpan="4" className="p_2">{present_address_one + ',' + present_landmark + ',' + present_area}</td>
                     </tr>
                     <tr>
                        <th className="p_2"></th>
                        <th className="p_2"></th>
                        <td colSpan="4" className="p_2">{present_city_id + ',' + present_state_id + ',' + present_country_id + ',' + present_zip}</td>
                     </tr>
                     <tr>
                        <th className="p_2">Permanent Address</th>
                        <th className="p_2">:</th>
                        <td colSpan="4" className="p_2">{parmanent_address_one + ',' + parmanent_landmark + ',' + parmanent_area}</td>
                     </tr>
                     <tr>
                        <th className="p_2"></th>
                        <th className="p_2"></th>
                        <td colSpan="4" className="p_2">{parmanent_city_id + ',' + parmanent_state_id + ',' + parmanent_country_id + ',' + parmanent_zip}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Fax 1</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{fax}</td>
                        <th className="p_2 w_20">Fax 2 </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{fax1}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Father Name</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{father_name}</td>
                        <th className="p_2 w_20">Marital Status </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{marital_status}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Anniversary Date</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{anniversary_date}</td>
                        <th className="p_2 w_20">Spouse Name </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{spouse_name}</td>
                     </tr>

                     <tr>
                        <th className="p_2 w_20">Phone Secondary</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{phone_secondary}</td>
                        <th className="p_2 w_20">Email Secondary </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{email_secondary}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Blood Group</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{blood_group}</td>
                        <th className="p_2 w_20">Passport Number </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{passport_no}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Religion</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{religion}</td>
                        <th className="p_2 w_20">Caste </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{caste}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Nationality</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{nationality}</td>
                        <th className="p_2 w_20">Contact Number </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{emergency_contact_number}</td>
                     </tr>
                     <tr>
                        <th className="p_2 w_20">Contact Person</th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{emergency_contact_person}</td>
                        <th className="p_2 w_20">Relationship Person </th>
                        <th className="p_2 w_5">:</th>
                        <td className="p_2 w_20">{emergency_relationship_person_id}</td>
                     </tr>
                  </table>
               </div>
            </div>

         </div>
        );
}

export default Employee_manage_view