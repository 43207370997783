import React, { useState, useEffect } from "react";
import Apiconnect from "../services/Apiconnect.js";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";

const pageStyles = {
  card_styles: {
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
    borderRadius: 10,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main_bg: {
    background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)",
    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card_head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    color: "white !important",
    background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
  },
};

const Rm_selected_applicants_docs = () => {
  const alert = useAlert();
  const { id } = useParams();

  const hashId = Apiconnect.decrypt(id)

  console.log(hashId)

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [selected_applicants_list, setSelected_applicants_list] = useState([]);
  const [id_pdf, setId_pdf] = useState("");
  const [pan_pdf, setPan_pdf] = useState("");
  const [salarySlip, setSalarySlip] = useState("");
  const [aletter, setAletter] = useState("");
  const [prev_company_id, setPrev_company_id] = useState("");
  const [emp_info, setEmp_info] = useState([]);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  useEffect(() => {
    get_selected_applicants_list();
  }, []);

  const get_selected_applicants_list = async () => {
    try {
      const result = await Apiconnect.postData(`selected_applicants/get_selected_applicants_list/${id}`);
      let result_data = result.data.data;
      let user_details = result_data.find((item) => item.am_pk_id === Number(id));
      console.log(user_details);
      let fname = user_details?.am_first_name;
      let lname = user_details?.am_last_name;
      setFname(fname);
      setLname(lname);
      setSelected_applicants_list(user_details);
    } catch (error) {
      console.log(error.message);
    }
  };

  const get_employement_info = async (id) => {
    try {
      const res_q = await Apiconnect.postData(`selected_applicants/get_employement_info/${id}`);
      const res_data = res_q.data.data;
      setEmp_info(res_data[0]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append("id_proof", id_pdf);
    data.append("pan_proof", pan_pdf);
    data.append("aletter", aletter);
    data.append("salarySlip", salarySlip);
    data.append("prevEmployerId", prev_company_id);
    data.append("applicant_id", id);

    if (id_pdf !== "" && pan_pdf !== "" && aletter !== "" && salarySlip !== "" && prev_company_id !== "") {
      Apiconnect.postData(`selected_applicants/upload_emp_docs`, data)
        .then((res) => {
          alert.success("Success");
          setId_pdf("");
          setPan_pdf("");
          setAletter("");
          setSalarySlip("");
        })
        .catch((err) => {
          alert.error("Error");
        });
    } else {
      console.log("Please Select Documents");
    }
  };

  console.log(fname, lname);

  let name = fname.toLocaleUpperCase() + " " + lname.toLocaleUpperCase();

  return (
    <div className="container-fluid">
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div className="row">
        <div className="col-md-12" style={pageStyles.main_bg}>
          <div className="iq-card" style={pageStyles.card_styles}>
            <div className="iq-card-header" style={pageStyles.card_head}>
              <h4 className="text-white">{name}</h4>
            </div>
            <div className="iq-card-body">
              <div className="row m-3">
                <div className=" col-md-12">
                  <span className="text-muted font-weight-bold">
                    Required Documents <span className="required">*</span>
                  </span>
                  <div className="row mt-2 mb-2 bg-white" style={{ border: "3px solid #04BDEF", borderRadius: 10, padding: 20 }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-muted">Upload Id Proof (Aadhaar Card/ Driving License : PDF)</label>
                        <input type="file" id="file" name="id_proof" placeholder="Upload Your Id" className="form-control" onChange={(e) => setId_pdf(e.target.files[0])} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-muted">Upload PAN Card (PDF)</label>
                        <input type="file" id="file" name="pan_proof" placeholder="Upload Your PAN" className="form-control" onChange={(e) => setPan_pdf(e.target.files[0])} />
                      </div>
                    </div>
                  </div>
                  <span className="text-muted font-weight-bold">
                    Employer Specific Documents <span className="required">*</span>
                  </span>
                  <div className="row mt-2 mb-2 bg-white" style={{ border: "3px solid #04BDEF", borderRadius: 10, padding: 20 }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-muted">Select Previous Employer</label>
                        <select
                          className="form-control"
                          name="prev_company_id"
                          value={prev_company_id}
                          onChange={(e) => {
                            setPrev_company_id(e.target.value);
                            get_employement_info(e.target.value);
                          }}
                        >
                          <option value="" disabled selected>
                            {" "}
                            Select Previous Employer{" "}
                          </option>
                          {selected_applicants_list?.employement_info?.map((item1, index1) => {
                            let to_date = moment(item1.ame_to).format("DD/MM/YYYY");
                            return (
                              <option key={index1} value={item1.ame_pk_id}>
                                {item1.ame_name} | {to_date} {}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {prev_company_id && (
                      <div className="col-md-12">
                        <div className="row bbox m-1">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="text-muted">Upload Appointment Letter (PDF)</label>
                              <input type="file" id="file" name="aletter" className="form-control" onChange={(e) => setAletter(e.target.files[0])} />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="text-muted">Upload Last 3 Months Salary Slips (PDF)</label>
                              <input type="file" id="file" name="salarySlip" className="form-control" onChange={(e) => setSalarySlip(e.target.files[0])} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center">
                  <button className="btn btn-sm btn-info text-center" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_selected_applicants_docs;
