import React, { useEffect, useState } from "react";
import Apiconnect from "../../../services/Apiconnect";
import toast from "react-hot-toast";
import { Card, Container, Input, InputFile,Table } from "../../../components/imports";
import {FcDocument} from "react-icons/fc"
import {FiEdit} from "react-icons/fi"
import {RiDeleteBin4Fill} from "react-icons/ri"
import { pageStyles } from "../../../globalStyles";


const Onboarding_docs = () => {
  const [od_name, setName] = useState("");
  const [doc, setDoc] = useState(null);
  const [inputError, setInputError] = useState({ nameError: "", docError: "" });
  const [validationError, setValidationError] = useState(true);
  const [list, setList] = useState({})
  const [editId, setEditId] = useState("")
  const { nameError, docError } = inputError;

  const manageError = () => {
    let errors = { nameError: "", docError: "" };

    if (od_name === "") {
      errors.nameError = "Document title is empty";
      toast.error("Please enter document title");
    }
    if (doc === "") {
      errors.docError = "Please select document";
      toast.error("Document not selected");
    }
    setInputError(errors);
    Object.values(errors).every((val) => {
      if (val !== "" || val !== null || val !== undefined) {
        setValidationError(true);
      } else {
        setValidationError(false);
      }
    });
  };

  useEffect(() => {
    getAllData()
  },[])

  const getAllData = () => {
    Apiconnect.postData(`onboarding/getAllDocs/${Apiconnect.get_company_id()}`).then(res => {
      const data = Apiconnect.decrypt_obj(res.data.data)
      setList(data)
    })
  }

  const handleSubmit = async () => {
    manageError()
    try {
      if(editId !== ""){
         let dataIns = {
          od_name: od_name
         }
         Apiconnect.postData(`onboarding/updateDocs/${editId}`, dataIns).then(res => {
          toast.success("Updated")
          getAllData()
          setEditId("")
          setName("")
        })

      }else{
        if(od_name !== "" && doc !== ""){
          let formData = new FormData();
          formData.append('od_company_id', Apiconnect.get_company_id());
          formData.append('od_name', od_name);
          formData.append("onboarding_docs", doc);
          Apiconnect.postData(`onboarding/uploadDocs`, formData).then(res => {
            toast.success("Success")
            getAllData()
            setName("")
            setDoc("")
          })
        }else{
          toast.error("Validation Error")
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong")
    }
    
  };

  console.log(list)

  const trHeader = <tr className="text-center">
    <th>#</th>
    <th>Name</th>
    <th>Manage</th>
  </tr>

  const handleDelete = (id) => {
    Apiconnect.postData(`onboarding/deleteDocs/${id}`).then(res => {
      toast.success("Deleted")
      getAllData()
    }).catch(err => {
      toast.error("Something went wrong")
    })
  }

  const handleEdit = (id) => {
    setEditId(id)
    const filtered = list.find(item => item.od_pk_id === id)
    setName(filtered.od_name)
  }

  return (
    <Container heading="Manage Onboarding Documents">
      <Card>
        <Input className="col-md-4" label="Document Name" type="text" placeholder="Enter Document Title" name="od_name" value={od_name} error={nameError} onChange={(e) => setName(e.target.value)} />
        {/* <InputFile className="col-md-4" label="Choose Document" type="file" id="file" name="doc" error={docError} onChange={(e) => setDoc(e.target.files[0])} /> */}
       {!editId && <div className="form-group col-md-6">
          <label className="text-muted " htmlFor="">Choose Document</label>
          <input className="form-control" type="file" id="file" name="doc" onChange={(e) => setDoc(e.target.files[0])}  />
        </div>}
        <div className="col-md-12 d-flex justify-content-center align-items-center mt-4">
          <button className="btn mr-2 btn-sm btn-info" onClick={handleSubmit}>
            Submit
          </button>
          {editId && <button className="btn btn-sm btn-secondary" onClick={() => {
            setEditId("")
            setName("")
          }}>
            Clear
          </button>}
        </div>
      </Card>
      <Card>
          <Table className="col-md-12" trHeader={trHeader}>
            {
               list.length > 0 && list.map((item, index) => {
                  return(
                    <tr className="text-center" key={index}>
                      <td>{index + 1}</td>
                      <td>{item.od_name}</td>
                      <td style={{width: "30%"}}>
                        <div className="d-flex justify-content-around">
                             <a className="badge m-2 badge-light" href={item.od_document} target="_blank" style={pageStyles.main_container}><FcDocument style={{fontSize: 20}}/></a>
                             <button className="btn m-2 btn-sm btn-info" style={pageStyles.main_container} onClick={() => handleEdit(item.od_pk_id)} ><FiEdit/></button>
                             <button className="btn m-2 btn-sm btn-danger" style={pageStyles.main_container} onClick={() => handleDelete(item.od_pk_id)}><RiDeleteBin4Fill/></button>
                        </div>
                      </td>
                    </tr>
                  )
                })
            }
          </Table>
      </Card>
    </Container>
  );
};

export default Onboarding_docs;
