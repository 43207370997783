import React, { useState, useEffect } from "react";
import Apiconnect from "../../../services/Apiconnect.js";
import { pageStyles } from "../../../globalStyles.js";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import Modal from "react-modal";
import moment from "moment";

const Rm_selected_applicants = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [selected_applicants_list, setSelected_applicants_list] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idproof, setIdproof] = useState("");
  const [pan, setPan] = useState("");
  const [prevEmployers, setPrevEmployers] = useState("");
  const [name, setName] = useState("");
  const [a_id, setA_id] = useState("");
  const [id_st, setId_st] = useState("");
  const [pan_st, setPan_st] = useState("");

  const customStyles = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    get_selected_applicants_list();
  }, []);

  const get_selected_applicants_list = async () => {
    try {
      const result = await Apiconnect.postData(`selected_applicants/get_all_selected_applicants/${company_id}`);
      setSelected_applicants_list(result.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleVerify = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`selected_applicants/verifyApplicant/${id}`).then((res) => {
      alert.success("Verified");
      get_selected_applicants_list();
      setFullScreenLoader(false);
    });
  };

  const verifyId = async () => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/idverify/${a_id}`);
      alert.success("Verified");
      get_selected_applicants_list();
      setModalIsOpen(false);
    } catch (error) {
      alert.error("Error");
    }
  };
  const verifyPan = async () => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/panverify/${a_id}`);
      alert.success("Verified");
      get_selected_applicants_list();
      setModalIsOpen(false);
    } catch (error) {
      alert.error("Error");
    }
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div>
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
          <div className="container-fluid" style={pageStyles.main_container}>
            <div className="iq-card">
              <div className="iq-card-header bg-light" style={pageStyles.card_head}>
                <h5 className="text-white">
                  Applicant Info - <span className="text-white">{name}</span>
                </h5>
              </div>
              <div className="iq-card-body">
                <div className="row">
                  <span className="text-muted p-3">
                    Applicant Required Documents <span className="required">*</span>
                  </span>
                  <div className="col-md-12 table-responsive">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center text-muted">Document Name</th>
                          <th className="text-center text-muted">View</th>
                          <th className="text-center text-muted">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center font-weight-bold text-muted">Id Card</td>
                          <td className="text-center">
                            {idproof ? (
                              <a className="btn btn-sm btn-info" href={idproof} target="_blank">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </a>
                            ) : (
                              <span className="text-muted">No Record Found</span>
                            )}
                          </td>
                          <td className="text-center">
                            {id_st !== 1 && (
                              <button className="btn btn-sm btn-info" onClick={verifyId}>
                                Verify
                              </button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center font-weight-bold text-muted">PAN Card</td>
                          <td className="text-center">
                            {pan ? (
                              <a className="btn btn-sm btn-info" href={pan} target="_blank">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </a>
                            ) : (
                              <span className="text-muted">No Record Found</span>
                            )}
                          </td>
                          <td className="text-center">
                            {pan_st !== 1 ? (
                              <button className="btn btn-sm btn-info" onClick={verifyPan}>
                                Verify
                              </button>
                            ) : (
                              <span className="badge badge-sm badge-success">Verified</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {prevEmployers.length > 0 && (
                  <div className="row">
                    <span className="text-muted p-3">
                      Applicants Employment Information <span className="required">*</span>
                    </span>
                    <div className="col-md-12 table-responsive">
                      <table className="table table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th className="text-center text-muted">#</th>
                            <th className="text-center text-muted">Org Name</th>
                            <th className="text-center text-muted">Period Of Service</th>
                            <th className="text-center text-muted">Appointment Letter</th>
                            <th className="text-center text-muted">Salary Slip</th>
                            <th className="text-center text-muted">Manage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {prevEmployers.map((item, index) => {
                            const from = moment(item.ame_from).format("DD/MM/YYYY");
                            const to = moment(item.ame_to).format("DD/MM/YYYY");
                            return (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">{item.ame_name}</td>
                                <td className="text-center">
                                  {from} - {to}
                                </td>
                                <td className="text-center">
                                  <a href={item.ame_appointment_letter} className="btn btn-sm btn-info" target="_blank">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  </a>
                                </td>
                                <td className="text-center">
                                  <a href={item.ame_salary_slip} className="btn btn-sm btn-info" target="_blank">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  </a>
                                </td>
                                <td className="text-center">
                                  <button className="btn btn-sm btn-info">Verify</button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="iq-card" style={pageStyles.main_container}>
              <div className="iq-card-header" style={pageStyles.card_head}>
                <h4 className="text-white">Selected Applicants</h4>
              </div>
              <div className="iq-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Link to={`/Verified_applicants`} className="btn btn-sm btn-info float-right mb-2">
                      Verified Applicants
                    </Link>
                  </div>
                  <div className="col-md-12">
                    {selected_applicants_list && (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th className="text-center">#</th>
                              <th className="text-center">Applicant Name</th>
                              <th className="text-center">Email</th>
                              <th className="text-center">Applied Position</th>
                              <th className="text-center">Document List</th>
                              {/* <th className="text-center">Previous Employers</th>
                              <th className="text-center">Verified Employers</th> */}
                              <th className="text-center">Manage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selected_applicants_list.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td>{item.am_first_name + " " + item.am_middle_name + " " + item.am_last_name}</td>
                                  <td>{item.am_email}</td>
                                  <td>{item.am_position}</td>
                                  <td className="text-center">
                                    <div>
                                      {item.am_id_proof ? (
                                        <a className="badge badge-light m-2" href={item.am_id_proof} target="_blank">
                                          <i className="text-info fa fa-id-card" aria-hidden="true"></i> Id Proof
                                        </a>
                                      ) : (
                                        <span className="text-muted">Documents Not Available</span>
                                      )}

                                      {item.am_pan && (
                                        <a className="badge badge-light m-2" href={item.am_pan} target="_blank">
                                          <i className="text-info fa fa-university" aria-hidden="true"></i> PAN Proof
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                  {/* <td>
                                    <div className="text-center">
                                      {item.prevEmployers.length > 0 ? (
                                        item.prevEmployers.map((item1, index1) => {
                                          return (
                                            <div key={index1} className="badge badge-primary">
                                              <div className="d-flex">
                                                <div className="m-2">{item1.ame_name} </div>
                                                <div className="dropdown f_left">
                                                  <span className="dropdown-toggle btn btn-sm  text-light" id="dropdownMenuButton4" data-toggle="dropdown">
                                                    <i className="fa text-white fa-cog"></i>
                                                  </span>

                                                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton4">
                                                    <a href={item1.ame_appointment_letter} className="dropdown-item">
                                                      <i className="fa text-info mr-2 fa-eye" aria-hidden="true"></i>
                                                      Appointment Letter
                                                    </a>
                                                    <a href={item1.ame_salary_slip} className="dropdown-item">
                                                      <i className="fa text-info mr-2 fa-eye" aria-hidden="true"></i>
                                                      Salary Slip
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <span className="text-muted">Record Not Available</span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      {item.verifiedEmployers.length > 0 ? (
                                        item.verifiedEmployers.map((item1, index1) => {
                                          return <span>{item1.ame_name}</span>;
                                        })
                                      ) : (
                                        <span className="text-muted">Record Not Available</span>
                                      )}
                                    </div>
                                  </td> */}
                                  <td className="text-center">
                                    <div>
                                      <Link className="btn btn-sm btn-info" to={`Verify_applicant_docs/${item.am_pk_id}`}>
                                        Verify
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_selected_applicants;
