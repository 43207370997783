import React, { useState, useEffect, useRef } from "react";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Loadder from "./Loadder";
import Axios from "axios";
import { CSVLink } from "react-csv";
// import Modal from "react-modal";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import moment from 'moment'
import parseJSON from "date-fns/parseJSON/index.js";
import FullScreenLoading from 'react-fullscreen-loading';

function Monthly_attendance_report() {
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const customStyles1 = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "unset ",
      backgroundColor: "unset ",
    },
  };

  useEffect(() => {
    getCompany();
    empdata();
    get_leave_category_list();
    getgrouplist();
    getSetMonthdata(new Date(),1);
    get_monthlyAttendaceinfo(new Date(),1);

    getCategoryList(); 
    lists_categorys();
    get_employee_leaves_unlockloc_data();
  }, []);

  const localuser = JSON.parse(localStorage.getItem("user"));
  let selempid = Apiconnect.decrypt(localuser.id);

  const csvLink = useRef(); 
  const [from_date, setFromDate] = useState(new Date());
  const [emp_id, setempid] = useState('');
  // const [modalLodder, setModalLodder] = useState(false);
  const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);
    
  const [empList, setEmpList] = useState([]);
  const [dailyAttendanceList, setDailyAttendanceList] = useState([]);
  const [list_leave_category, setlist_leave_category] = useState([]); 
  const local_user = JSON.parse(localStorage.getItem('user'));
  const [companyaddress, setCompanyData] = useState([]);
 
  const [date_start2, set_date_start2] = useState('')
  const [date_end2, set_date_end2] = useState('');
  const [array_calender1, set_array_calender1] = useState([]); 
  
  const [grplist, setGrouplist] = useState([]);
  const [selgrp, setSelgrp] = useState("");
  const [listsuseattendance1, setlistsuseattendance1] = useState([]);

  const [lists_monthly_attendance1, setlists_monthly_attendance1] = useState([]);

  const [list_half_day_leave, setlist_half_day_leave] = useState([]);
 
  const onInputChange = (e) => {
    setempid(e.target.value); 
  }; 

  const get_leave_category_list =async () => {
    let data_pstlcl = { company_id: Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/get_all_leave_category_lists`,data_pstlcl).then( (response) => {
      setlist_leave_category(response.data.data);
    });
  };

  const empdata =async ()  => {
    await Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then( (response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
    });
  };

  const submit = () => {
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    } 
    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
      group_id: selgrp
    };
    // console.log(info);
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc }; 
    // setModalLodder("true");
    csvLink.current.link.click(); 
    // setModalLodder(false);  
  };
  
  const getCompany = () => {         
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data);
      setCompanyData(ern[0].address_one) 
    });
  };
 
  /* ------------------------------download in excel---------------------- */
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  function nextChar(c) {
    var u = c.toUpperCase();
    if (same(u,'Z')){
      var txt = '';
      var i = u.length;
      while (i--) {
          txt += 'A';
      }
      return (txt+'A');
    } else {
      var p = "";
      var q = "";
      if(u.length > 1){
        p = u.substring(0, u.length - 1);
        q = String.fromCharCode(p.slice(-1).charCodeAt(0));
      }
      var l = u.slice(-1).charCodeAt(0);
      var z = nextLetter(l);
      if(z==='A'){
        return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
      } else {
        return p + z;
      }
    }
  }

  function nextLetter(l){
    if(l<90){
      return String.fromCharCode(l + 1);
    }else{
      return 'A';
    }
  }

  function same(str,char){
    var i = str.length;
    while (i--) {
      if (str[i]!==char){
        return false;
      }
    }
    return true;
  }

  const saveAsExcel =async () => {
    setFullScreenLoader(true);
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }
    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
    };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };

    let header = ["Employee_id",	"Name",	"Department",	"Designation",	"Company"];
    let list_array1=[1,2,3,4];

    let date_current1=new Date(from+'-01');
    var date_lastDay1 = new Date(date_current1.getFullYear(), (date_current1.getMonth() + 1), 0);
    let date_lastDay2=moment(new Date(date_lastDay1)).format("DD"); 
    let date_year1=moment(new Date(date_lastDay1)).format("YYYY");
    let date_month1=moment(new Date(date_lastDay1)).format("MM");
 
    let cnt_head1=5;
    for(var i_day1=1;i_day1<=date_lastDay2;i_day1++){
      let date_current2=moment(new Date(date_year1+'-'+date_month1+'-'+i_day1)).format("DD-MM-YYYY");

      header[cnt_head1]=date_current2; 
      cnt_head1++;
    } 
    header[cnt_head1]='Total Half Day Leaves'; 
    header[cnt_head1+1]='Total Full Day Leaves'; 
    header[cnt_head1+2]='Total Loss Of Pay';
    header[cnt_head1+3]='Total Present'; 
    header[cnt_head1+4]='Total Absent';
    header[cnt_head1+5]='Total Paid Days';
     
    // let array_list_att1=[];
    let array_list_att1=lists_monthly_attendance1;
 
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(list_array1,header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);

      let cnt_col2=2;
      for(let cnt_col1=0;cnt_col1<array_list_att1.length;cnt_col1++){
        let array_list_att2=array_list_att1[cnt_col1]; 
        sheet1.cell("A"+cnt_col2).value(array_list_att2['Employee_id']); //--Employee_id	 
        sheet1.cell("B"+cnt_col2).value(array_list_att2['Name']); //--Name
        sheet1.cell("C"+cnt_col2).value(array_list_att2['Department']); //--Department
        sheet1.cell("D"+cnt_col2).value(array_list_att2['Designation']); //--Designation
        sheet1.cell("E"+cnt_col2).value(array_list_att2['Company']); //--Company

        let cnt_char1="F"; 
        for(var i_day1=1;i_day1<=date_lastDay2;i_day1++){
          let date_current2=moment(new Date(date_year1+'-'+date_month1+'-'+i_day1)).format("DD-MM-YYYY");
          
          var val_excelcol='';

          if(typeof array_list_att2[date_current2]!='undefined'){
            val_excelcol='ST: '+array_list_att2[date_current2].ST+" \n";
            val_excelcol+='ET: '+array_list_att2[date_current2].ET+" \n";
            val_excelcol+='WH: '+array_list_att2[date_current2].WH+" \n";
            val_excelcol+='Status: '+array_list_att2[date_current2].Status;
          }
          
          sheet1.cell(cnt_char1+cnt_col2).value(val_excelcol); //--date_wise_data 

          cnt_char1=nextChar(cnt_char1); 
        }

        var cal_tot_present1=array_list_att2['cal_tot_present'];
        var cal_tot_absent1=array_list_att2['cal_tot_absent'];
        var cal_tot_halfday1=array_list_att2['cal_tot_halfday'];
        var cal_tot_fullday1=array_list_att2['cal_tot_fullday'];
        var cal_tot_lop1=array_list_att2['cal_tot_lop'];
        var cal_tot_paidday1=array_list_att2['cal_tot_paidday'];

        sheet1.cell(cnt_char1+cnt_col2).value(cal_tot_halfday1); //--Total Half Leaves
        var cnt_char1_1=nextChar(cnt_char1);
        sheet1.cell(cnt_char1_1+cnt_col2).value(cal_tot_fullday1); //--Total Full Leaves
        var cnt_char1_2=nextChar(cnt_char1_1);
        sheet1.cell(cnt_char1_2+cnt_col2).value(cal_tot_lop1); //--Total Loss Of Pay
        var cnt_char1_3=nextChar(cnt_char1_2);
        sheet1.cell(cnt_char1_3+cnt_col2).value(cal_tot_present1); //--Total Present 
        var cnt_char1_4=nextChar(cnt_char1_3);
        sheet1.cell(cnt_char1_4+cnt_col2).value(cal_tot_absent1); //--Total Absent
        var cnt_char1_5=nextChar(cnt_char1_4);
        sheet1.cell(cnt_char1_5+cnt_col2).value(cal_tot_paidday1); //--Total Paid Days   
        
        cnt_col2++;
      } //--end_for

      // return false;
      
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);  
      return workbook.outputAsync().then((res) => {
        setFullScreenLoader(false);
        saveAs(res, "monthlyattendance.xlsx"); 
      });
    });
  } 

  //get group list 
  const getgrouplist = async () => {
    const inf_a = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_a).then(res => setGrouplist(res.data.data)) 
  }
   
  const getSetMonthdata = async (sel_fromdate,iscall) => {   
    var date_con=new Date(sel_fromdate);
    var date_year =date_con.getFullYear();
    var date_month =date_con.getMonth()+1;
 
    var day_firstDay = moment(new Date(date_year, date_month, 1)).format("DD");
    var day_lastDay = moment(new Date(date_year, date_month, 0)).format("DD");
    
    var array_calender2=[];
    var cnt_index1=0;
    for(var cnt_day=1;cnt_day<=day_lastDay;cnt_day++){
      let date_current2=moment(new Date(date_year+'-'+date_month+'-'+cnt_day)).format("DD-MM-YYYY");

      array_calender2[cnt_index1]=date_current2; 
      cnt_index1++;
    }
    set_array_calender1(array_calender2); 
  }
  
  const onInputChange_fromdate =async (e) => {   
    await setFromDate(e);  
  };

 
  const submit_filter =async () => {
    var date_con=new Date(from_date);
    var date_year =date_con.getFullYear();
    var date_month =date_con.getMonth()+1;
 
    var day_firstDay = moment(new Date(date_year, date_month, 1)).format("DD");
    var day_lastDay = moment(new Date(date_year, date_month, 0)).format("DD");
    
    var array_calender2=[];
    var cnt_index1=0;
    for(var cnt_day=1;cnt_day<=day_lastDay;cnt_day++){
      let date_current2=moment(new Date(date_year+'-'+date_month+'-'+cnt_day)).format("DD-MM-YYYY");

      array_calender2[cnt_index1]=date_current2; 
      cnt_index1++;
    }
    set_array_calender1(array_calender2);

    get_monthlyAttendaceinfo();
    lists_categorys();
  }


  const get_monthlyAttendaceinfo= async () => {  
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }
    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
      group_id: selgrp
    };

    setFullScreenLoader(true);
    // setModalLodder(true);
    // console.log(info);
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };
    await Apiconnect.postData(`employee/monthlyAttendaceinfo`,inf_a).then((response) => { 
      let array_list_att1 = Apiconnect.decrypt_obj(response.data.data); 
      let array_list_atts1 = Apiconnect.decrypt_obj(response.data.data); 

      
      let date_current1=new Date(from_date+'-01');
      var date_lastDay1 = new Date(date_current1.getFullYear(), (date_current1.getMonth() + 1), 0);
      let date_lastDay2=moment(new Date(date_lastDay1)).format("DD"); 
      let date_year1=moment(new Date(date_lastDay1)).format("YYYY");
      let date_month1=moment(new Date(date_lastDay1)).format("MM");

      // console.log(date_lastDay2);


      var array_list_att2=new Array(); 
      var cal_cnt1=0;
      array_list_att1.map((val_matt, key_matt) => {  
        array_list_att2[cal_cnt1]=val_matt;
        
        for(var i_day1=1;i_day1<=date_lastDay2;i_day1++){
          let i_day2 = i_day1.toString().length == 1 ? "0" + i_day1 : i_day1;

          var set_date_curr3 = date_year1 + "-" + date_month1 + "-" + i_day2;
          var customDate = new Date(set_date_curr3+"T00:00:00"); 
          let date_current2=moment(customDate).format("DD-MM-YYYY");

          var val_excelcol='';
          if(typeof array_list_att1[cal_cnt1][date_current2]!='undefined'){
            val_excelcol='ST: '+array_list_att1[cal_cnt1][date_current2].ST+" \n";
            val_excelcol+='ET: '+array_list_att1[cal_cnt1][date_current2].ET+" \n";
            val_excelcol+='WH: '+array_list_att1[cal_cnt1][date_current2].WH+" \n";
            val_excelcol+='Status: '+array_list_att1[cal_cnt1][date_current2].Status;
          }

          array_list_att2[cal_cnt1][date_current2]=val_excelcol;
          // console.log(date_current2);
        }
        
        cal_cnt1++;
      })

      // console.log(array_list_att1);
      // console.log(array_list_att2);
      setDwnEmpAttendanceList(array_list_att2);
      // setModalLodder(false); 
      // csvLink.current.link.click();
      // console.log(array_list_att1);
      setlists_monthly_attendance1(array_list_atts1);
      
      // setModalLodder(false);
      setFullScreenLoader(false);
    });
  }

  // console.log(lists_monthly_attendance1);
  function AddMinutesToDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  
  const [list_category1,set_list_category1]=useState([]); 
  const [lists_employee_cate1,set_lists_employee_cate1]=useState([]); 
  const [scatewise_pcls,set_catewise_pcls]=useState([]); 

  const getCategoryList = async () => {  
    await Apiconnect.postData(`leave_manage/get_leave_category_list_by_id`,{company_id:company_id}).then(response => {
      set_list_category1(Apiconnect.decrypt_obj(response.data.data)); 
    }); 
  }; 

  

  

  const lists_categorys = async () => { 
    var data_post = {
      company_id: company_id,
      employee_id:0,
      is_admin: 0,
      sel_shortleave_type: 'No',
    };
    var response_list2=[];
    await Apiconnect.postData(`leave_manage/get_employee_policy_category_list_employees`, data_post).then((response) => {
      var response_list1=(Apiconnect.decrypt_obj(response.data.data)); 
      set_lists_employee_cate1(response_list1);
    }); 
  };


  var array_appleavs=[];
  const onInputChange_leaveapply = (e) => { 
    array_appleavs[e.target.name]=e.target.value; 
  }
  
 
  const onSubmit_save = async () => { 
    // setModalLodder(true);  
    var array_info=[];
    var cal_cnt1=0;
    Object.keys(array_appleavs).forEach(function(keyappleavs) {
      
      var array_split1=keyappleavs.split('#');
      var info={};
      
      info["emppc_emp_id"] = array_split1[1]; 
      info["emppc_company_id"] = company_id; 
      info["emppc_type"] = 'debit'; 
      info["emppc_yearmonth"] = moment(new Date(from_date)).format("YYYY-MM");
      info["emppc_reason"] = 'Late Arrival'; 
      info["emppc_leavecount"] = array_appleavs[keyappleavs]; 
      info["emppc_category_id"] = array_split1[3];
      info["emppc_action"] = 'latearrival';
      info["emppc_status_id"] = 0;  
      info["emppc_created_by"]=selempid; 

      array_info.push(info);
      // array_info=info;
      cal_cnt1++;
    });
    
    
    if(cal_cnt1>0){   
      // console.log(array_info);
      var res_resultul=load_employee_leaves_unlockloc(array_info); 
      await Apiconnect.postData(`reports_module/report_manages/submit_employee_leaves_penalty_clause`, array_info).then((response) => {
        alert.success(response.data.message); 
        window.location.reload(); 
      }); 
    }

  }

  const [fullScreenLoader, setFullScreenLoader] = useState(false);

  const load_employee_leaves_unlockloc= async (array_datalu) => {  
    await Apiconnect.postData('reports_module/report_manages/submit_employee_leaves_unlockloc',array_datalu).then((response)=>{ return true;});
  }

  const [Dataunloclock, setDataunloclock] = useState('');
  const get_employee_leaves_unlockloc_data= async () => {  
    var array_datalu2={'emplu_company_id':company_id,'emplu_daymonthyear':moment(new Date(from_date)).format("YYYY-MM")};
    await Apiconnect.postData('reports_module/report_manages/get_employee_leaves_unlockloc_byid',array_datalu2).then((response)=>{
      var res_data=Apiconnect.decrypt_obj(response.data.data);
      if(typeof res_data[0]!='undefined'){
        setDataunloclock(res_data[0].count_locun);
      }
    });
  }
   
  // console.log(Dataunloclock);

  return (
    <>
      <div id="content-page" className="content-page">
        {fullScreenLoader? (<FullScreenLoading loading loaderColor="#3498db" />): null}

        <div className="container-fluid">
          <div className="row">
            {/* ////////////------------>Lodder<------------////////////////// */}
            {/* <div>
              <Modal isOpen={modalLodder} style={customStyles1}>
                {<Loadder />}
              </Modal>
            </div> */}
            {/* ////////////------------>Lodder<------------////////////////// */}

            <div className="col-sm-12">
              <div className="iq-card">
                <div>
                  <h4 className="card-title hd">Monthly Attendance</h4>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <label>Date</label>
                      <DatePicker
                        selected={from_date}
                        // onChange={(date) => setFromDate(date)}
                        onChange={(e) => onInputChange_fromdate(e)}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        className="form-control"
                        showMonthYearPicker
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-md-3">
                      <label>User</label>
                      <select
                        className="form-control"
                        name="emp_id"
                        value={emp_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option value="">Select User</option>
                        {empList.map((val, key) => {
                          return (
                            <option key={key} value={val.id}>
                              {val.first_name} {val.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
 
                    <div className="col-md-2">
                      <label>Group</label>
                      <select className="form-control" name="selgrp" value={selgrp} onChange={(e) => setSelgrp(e.target.value)}>
                        <option value="">select group</option>
                        {
                          grplist.map((item, index) => (
                            <option key={index} value={item.leavegroup_pk_id}>{item.leavegroup_name}</option>
                          ))
                        }
                      </select>
                    </div> 

                    <div className="col-md-1">
                      <button type="button" className="btn btn-primary" style={{marginTop: "23px"}} onClick={(e) => submit_filter(e)}>Filter</button>
                    </div>

                    <div className="col-md-2">
                      <input
                        type="button"
                        className="btn btn-success"
                        value="Download CSV"
                        style={{ marginTop: "23px" }}
                        onClick={(e) => submit(e)}
                      />
                      <CSVLink
                        data={dwnEmpAttendanceList}
                        filename="employee-attendance.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />  
                    </div>
                    <div className="col-md-2"> 
                      <button className="btn btn-warning" style={{ marginTop: "23px" }} onClick={() => saveAsExcel()}>Download Excel</button>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            

            
            
            <div className="col-lg-12"> 
            <form> 
              <div className="iq-card"> 

                <div className="col-lg-12" style={{paddingTop:10}}>
                  {  
                    Dataunloclock==0 ?(         
                      <button type="button" className="btn btn-success pull-right" onClick={() => onSubmit_save()}>Submit to Adjust Leave Against Late & Lock</button>
                    ):""
                  } 
                </div>

                <div className="col-lg-12 table-responsive" >
                 
                  <table className="table table-bordered" style={{marginTop:10,fontSize:12}}  id="example">
                    <thead>
                      <tr>
                        <th>SrNo</th>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Company</th>
                        { 
                          array_calender1.map((val_date1, key_index) => {
                            return(<th key={21+key_index} style={{whiteSpace:'nowrap'}}>{val_date1}</th>)
                          })
                        }
                        <th>Late Count</th>
                        {
                          list_category1.map((val_catg, key) => { 
                            return ( <th key={10+key}>{val_catg.leave_cat_code}</th> );  
                          })
                        }
                      </tr>
                    </thead>
                    <tbody>  
                    { 
                      
                      lists_monthly_attendance1.length>0 ?( 
                       
                        lists_monthly_attendance1.map((val_matt, key_matt) => {
                          var cal_count_late1=0;  
                          var emp_pk_id=val_matt.emp_pk_id; 
                          var shift_max_penalty=val_matt.shift_max_penalty;
                          var cal_tot_lacount=val_matt.cal_tot_lacount;
                          
                          return(
                            <tr  key={key_matt}>
                              <td key={1}>{key_matt+1}</td>
                              <td key={2}>{val_matt.Employee_id}</td>
                              <td key={3}>{val_matt.Name}  </td>
                              <td key={4}>{val_matt.Department}  </td> 
                              <td key={5}>{val_matt.Designation}</td>
                              <td key={6}>{val_matt.Company}</td> 
                              { 
                                array_calender1.map((val_date1, key_index) => {
                                  // console.log(val_matt[val_date1]);
                                  var count_st=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].ST:'';
                                  var count_et=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].ET:'';
                                  var count_wh=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].WH:'';
                                  var count_Status=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].Status:'';
                                  var cal_count_late2=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].cal_count_late:'';
                                  var cal_count_StatusShow=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].StatusShow:'';
                                  var cal_count_lateNEW=(typeof val_matt[val_date1]!='undefined')?val_matt[val_date1].cal_count_lateNEW:'';
                                  
                                  //console.log(cal_count_StatusShow+' = '+count_Status+' == '+count_Status+' = '+shift_max_penalty);
                                  if(cal_count_StatusShow=='LA' && count_Status!='WO' && count_Status!='H'){
                                    cal_count_late1+=parseFloat(shift_max_penalty); 
                                  } 

                                  //console.log(val_matt.Name+' == '+val_date1+' == '+cal_count_late2+' == '+cal_count_late2);

                                  return(
                                    <td key={22+key_index}  style={{whiteSpace:'nowrap'}}>
                                      ST: &nbsp; {count_st}         <br/>
                                      ET: &nbsp; {count_et}         <br/>
                                      WH: &nbsp; {count_wh}         <br/>
                                      Status: &nbsp; {count_Status}   
                                      {/* <br/> <br/> {cal_count_lateNEW}  */}
                                    </td>
                                  )
                                })
                              }
                              <td key={emp_pk_id} style={{whiteSpace:'nowrap'}}>
                                Late : {cal_tot_lacount} 
                              </td>

                              {
                                list_category1.map((val_catg, key_cat1) => { 
                                  return (  
                                    <td key={33+key_cat1} style={{whiteSpace:'nowrap'}}>
                                      {(typeof lists_employee_cate1[emp_pk_id]!='undefined') ? (
                                        lists_employee_cate1[emp_pk_id]['list_cate'].map((val_cat1, key_cat2) => {
                                          //console.log(emp_pk_id+' = '+val_cat1.leave_cat_code+' = '+val_catg.leave_cat_code);
                                          if(val_cat1.leave_cat_code==val_catg.leave_cat_code){
                                            // console.log(val_cat1);
                                            var cal_total_credit=val_cat1.cal_total_credit;
                                            var cal_total_apply=val_cat1.cal_total_apply;
                                            var cal_total_lpc=val_cat1.cal_total_lpc;

                                            var leave_cat_id=val_cat1.leave_cat_id;
                                            var leavepolicy_pk_id=val_cat1.leavepolicy_pk_id;
                                            var lpcategory_pk_id=val_cat1.lpcategory_pk_id;

                                            var cal_total_leaveremaning=cal_total_credit-cal_total_apply-cal_total_lpc;
                                            var cal_percentage=0;

                                            if(cal_total_leaveremaning<0){ cal_total_leaveremaning=0; }

                                            var app_name1='empleaveapply#'+emp_pk_id+'#'+leave_cat_id+'#'+lpcategory_pk_id;
                                            
                                            //var selected_value=(typeof scatewise_pcls[app_name1]!='undefined')?scatewise_pcls[app_name1]:'';
                                            
                                            // console.log(scatewise_pcls);
                                            return ( 
                                              <span key={111+key_cat2}>
                                                {/* Credit : {cal_total_credit}
                                                <br/>
                                                Apply : {cal_total_apply}
                                                <br/>
                                                {cal_percentage}
                                                <br/> */}

                                                {(cal_total_credit!=0 && cal_count_late1!=0 && cal_total_leaveremaning!=0) ? (
                                                  <input 
                                                    type='number' 
                                                    name={app_name1}
                                                    onChange={(e) => onInputChange_leaveapply(e)} 
                                                    autoComplete="off"
                                                    placeholder="Leave Count"
                                                    // value={selected_value}
                                                    // max="5"
                                                  />
                                                ) : ( 
                                                  '' 
                                                )}

                                              </span>  
                                            )
                                          } 
                                        })
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                  );   
                                })
                              }
                            </tr>
                          )
                        })
                      ):
                        <tr>
                          <th style={{textAlign:'center'}} colSpan={6+moment(new Date(new Date(from_date.getFullYear(), (from_date.getMonth() + 1), 0))).format("DD")}>No Data Found.. </th>
                        </tr>
                      }
                    </tbody>
                  </table>
                      
                </div>

                
              </div> 
              </form>    
            </div> 
                  


          </div>
        </div>
      </div>
    </>
  );
}

export default Monthly_attendance_report;
