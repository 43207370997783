import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link} from 'react-router-dom/cjs/react-router-dom.min.js';
import Modal from 'react-modal';

const customStyles = {
    content: {
       top: '55%',
       left: '55%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "70%" ,
       height: '60%'
    },
};
const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }
 }

const Hiring_dashboard = () => {

    const alert = useAlert(); 
    const company_id = Apiconnect.get_company_id()

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [add_vac_list, setAdd_vac_list] = useState([])
    const [repl_vac_list, setRepl_vac_list] = useState([])
    const [application_list, setApplication_list] = useState([])
    const [view_id, setView_id] = useState("")
    const [shortlisted_list, setShortlisted_list] = useState([])

    useEffect(() => {get_additional_vacancies_list(); get_replacement_vacancies_list(); appliation_lists(); get_shortlisted_list()}, [])

    const get_shortlisted_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_shortlisted_list/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setShortlisted_list(_xtract)
            setFullScreenLoader(false)
        })
    }

    const get_additional_vacancies_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/get_additional_vacancies_list/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setAdd_vac_list(_xtract)
            setFullScreenLoader(false)
        })
    }

    const get_replacement_vacancies_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/get_replacement_vacancies_list/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setRepl_vac_list(_xtract)
            setFullScreenLoader(false)
        })
    }

    const appliation_lists = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_getall/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setApplication_list(_xtract)
            setFullScreenLoader(false)
        })
    }

    let application_cnt = (application_list.length)

    const total_additional_vacancies = add_vac_list.map((item) =>Number(item.wf_no_of_vacancies)).reduce((curr, prev) => curr + prev, 0)
    const total_replacement_vacancies = repl_vac_list.map((item) =>Number(item.wf_no_of_vacancies)).reduce((curr, prev) => curr + prev, 0)

    const get_applicants = view_id !== "" ? application_list.filter(item => item.am_position_id == view_id) : ""
    const shortlisted_cnt = (application_list.filter(item => item.am_shortlisted=== 1)).length

    console.log(shortlisted_list)



  return (
    <>
        <div className="content-page"
        style={page_styles.main_container_bg}
        >
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#b3bfc7" />): null}
            <div className="container-fluid"
            
            >
                <div className='row'>
                    <div className="col-sm-12 col-lg-12 col-md-12" style={{borderRadius: 30}}>
                        <div className="iq-card" style={{borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                            <div className="iq-card-header d-flex justify-content-between model_head"
                            style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title text-light">Hiring Dashboard</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    {/* <div className='col-md-12'>
                                        <Link to={`/Manage_shortlisted`}><button className='btn btn-sm btn-info float-right'>Manage Shortlisted</button></Link>
                                    </div> */}
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="row m-2">
                                            <div className="col-md-12 bg-white" style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                <div className="iq-card bg-white">
                                                    <div className="iq-card-body">
                                                        <div className="row">
                                                            <div className="col-lg-12 mb-2 d-flex justify-content-center">
                                                                <div className="icon iq-icon-box rounded-circle iq-bg-info rounded-circle" data-wow-delay="0.2s">
                                                                    <i className="fa fa-user-plus" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-3 center">
                                                                <span className="card-title text-uppercase text-secondary mb-0">Total Additional Vacancies</span>
                                                                <span className="h2 text-dark mb-0 d-inline-block w-100">{total_additional_vacancies}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-2">
                                            <div className="col-md-12 bg-white" style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                <div className="iq-card bg-white">
                                                    <div className="iq-card-body">
                                                        <div className="row">
                                                            <div className="col-lg-12 mb-2 d-flex justify-content-center">
                                                                <div className="icon iq-icon-box rounded-circle iq-bg-success rounded-circle" data-wow-delay="0.2s">
                                                                    <i className="fa fa-exchange" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-3 center">
                                                                <span className="card-title text-uppercase text-secondary mb-0 center">Total Replacement Vacancies</span>
                                                                <span className="h2 text-dark mb-0 d-inline-block w-100">{total_replacement_vacancies}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-2">
                                            <div className="col-md-12 bg-white" style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                <div className="iq-card bg-white">
                                                    <div className="iq-card-body">
                                                        <div className="row">
                                                            <div className="col-lg-12 mb-2 d-flex justify-content-center">
                                                                <div className="icon iq-icon-box rounded-circle iq-bg-primary rounded-circle" data-wow-delay="0.2s">
                                                                    <i className="fa fa-handshake-o" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-3 center">
                                                                <span className="card-title text-uppercase text-secondary mb-0 center">Total Shortlisted</span><br/>
                                                                <span className="h2 text-dark mb-0 d-inline-block w-100">{shortlisted_cnt}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-2">
                                            <div className="col-md-12 bg-white" style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                <div className="iq-card bg-white">
                                                    <div className="iq-card-body">
                                                        <div className="row">
                                                            <div className="col-lg-12 mb-2 d-flex justify-content-center">
                                                                <div className="icon iq-icon-box rounded-circle iq-bg-warning rounded-circle" data-wow-delay="0.2s">
                                                                    <i className="fa fa-envelope-open-o" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-3 center">
                                                                <span className="card-title text-uppercase text-secondary mb-0 center">Total Application Received</span>
                                                                <span className="h2 text-dark mb-0 d-inline-block w-100">{application_cnt}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-12'>
                                        <div className='className="iq-card'>
                                            <div className='iq-card-body'>
                                                <div className='row'>
                                                    <div className='col-md-12 bg-white p-3 mr-2 mb-2' style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                        <h6 className="card-title badge-pill mb-0 center" 
                                                        style={{color:"white",borderRadius: 50,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                                                        >Additional Vacancies List</h6><br/>
                                                        <table  className="table table-striped table-bordered mt-4 center grid" role="grid">
                                                           <thead className='thead-light'>
                                                                <tr className="">
                                                                    <th scope="col">Position</th>
                                                                    <th scope="col">Vacancies</th>
                                                                    <th scope="col">Applicants</th>
                                                                </tr>
                                                           </thead>
                                                           <tbody>
                                                            {
                                                                add_vac_list.length > 0 ?
                                                                (
                                                                    add_vac_list.map((item, index) => {
                                                                        return(
                                                                            <tr className='table-light' key={index}>
                                                                                <td>{item.position}</td>
                                                                                <td><span className="badge badge-pill badge-light">{item.wf_no_of_vacancies}</span></td>
                                                                                
                                                                                <td>
                                                                                    <Link to={`/Applicants/${item.wf_position}`}>
                                                                                        <button className='btn btn-sm btn-success'
                                                                                         style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                                                                                        >View</button>
                                                                                    </Link>
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                        )
                                                                    }) 
                                                                )
                                                                :
                                                                (
                                                                    <tr className='table-light'>
                                                                        <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                                    </tr>
                                                                )
                                                            } 
                                                           </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-12 bg-white p-3 mr-2 mb-2' style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                        <h6 className="card-title badge-pill mb-0 center" 
                                                        style={{color:"white",borderRadius: 50,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                                                        >Replacement Vacancies List</h6><br/>
                                                        <table  className="table table-striped table-bordered mt-4 center grid" role="grid" >
                                                            <thead className='thead-light'>
                                                                <tr className="">
                                                                    <th scope="col">Position</th>
                                                                    <th scope="col">Vacancies</th>
                                                                    <th scope="col">Applicants</th>
                                                                </tr>
                                                           </thead>
                                                           <tbody>
                                                           {
                                                                repl_vac_list.length > 0 ?
                                                                (
                                                                    repl_vac_list.map((item, index) => {
                                                                        return(
                                                                            <tr className='table-light' key={index}>
                                                                                <td>{item.position}</td>
                                                                                <td><span className="badge badge-pill badge-light">{item.wf_no_of_vacancies}</span></td>
                                                                                <td>
                                                                                    <Link to={`/Applicants/${item.wf_position}`}>
                                                                                        <button className='btn btn-sm btn-success'
                                                                                        
                                                                                        >View Applicants</button>
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }) 
                                                                )
                                                                :
                                                                (
                                                                    <tr className='table-light'>
                                                                        <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                                    </tr>
                                                                )
                                                           }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-12 bg-white p-3 mr-2 mb-2' style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>
                                                    <h6 className="card-title badge-pill mb-0 center" 
                                                    style={{color:"white",borderRadius: "50",background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                                                    >Shortlisted List</h6><br/>
                                                        <table  className="table table-striped table-bordered mt-4 center grid" role="grid" >
                                                            <thead className='thead-light'>
                                                                <tr className="">
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Applied For</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Phone</th>
                                                                </tr>
                                                           </thead>
                                                           <tbody>
                                                            {
                                                                shortlisted_list.length > 0 ? 
                                                                (
                                                                    shortlisted_list.map((item, index) => {
                                                                        return(
                                                                            <tr key={index} className='table-light'>
                                                                                <td>{index + 1}</td>
                                                                                <td>{item.am_first_name+' '+item.am_middle_name+' '+item.am_middle_name}</td>
                                                                                <td>{item.am_position}</td>
                                                                                <td>{item.am_email}</td>
                                                                                <td>{item.am_phone_cell}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                                :
                                                                (
                                                                    <tr className='table-light'>
                                                                        <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                                    </tr>
                                                                )
                                                            }
                                                           </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_dashboard