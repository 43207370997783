import React, { useState, useEffect } from "react";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect";
import Modal from "react-modal";
import moment from "moment";
const page_styles = {
  main_container_bg: { background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)" },
  main_container_shadows: {
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
    borderRadius: 10,
  },
  shadow: { boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" },
  card_head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "5",
    color: "white !important",
    background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
  },
};

/* ---Modal--- */
const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "100%",
  },
};
const customStyles_1 = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "50%",
  },
};

const Work_force = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(localuser.id);
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);
  let company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const today = new Date();
  const numberOfDaysToAdd = 3;
  const date = today.setDate(today.getDate());
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen_1, setModalIsOpen_1] = useState(false);
  const [workforce_list, setWorkfforce_list] = useState([]);
  const [editId, setEditId] = useState("");
  const [wf_id, setWf_id] = useState("");
  const [hp_list, setHp_list] = useState([]);
  const [hp_id, setHp_id] = useState("");

  const [desList, setDesList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [attList, setAttList] = useState([]);

  const [inputFields, setInputFields] = useState({
    name: "",
    dor: defaultValue,
    position: "",
    attrition: "",
    onboarding: "",
    department: "",
    location: "",
    unit_details: "",
    reporting_auth: "",
    working_days: "",
    candidate_pref: "",
    location_type: "",
    salary_range: "",
    additional_Perquisites: "",
    vacancies: "",
    hiring_process: "",
    q1: "",
    q2: "",
    q3: "",
    exp1: "",
    exp2: "",
    justification1: "",
    justification2: "",
    justification3: "",
    key_deliverables1: "",
    key_deliverables2: "",
    key_deliverables3: "",
    key_deliverables4: "",
    key_deliverables5: "",
    other_requisites1: "",
    other_requisites2: "",
    other_requisites3: "",
    raised_by: user_id,
    approving_auth: "",
    added_by: user_id,
    company_id: company_id,
  });

  useEffect(() => {
    work_force_requisition_getall();
    getDesList();
    getDeptList();
    getEmployeeList();
    getAttritionList();
    get_hplist();
  }, []);

  const { dor, position, attrition, onboarding, department, location, unit_details, reporting_auth, working_days, candidate_pref, location_type, salary_range, additional_Perquisites, vacancies, hiring_process, q1, q2, q3, exp1, exp2, justification1, justification2, justification3, key_deliverables1, key_deliverables2, key_deliverables3, key_deliverables4, key_deliverables5, other_requisites1, other_requisites2, other_requisites3, raised_by, approving_auth, name } = inputFields;

  const onInputChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const getAttritionList = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/workForce_mst_attrition_getall`).then((res) => {
      setFullScreenLoader(false);
      let result = Apiconnect.decrypt_obj(res.data.data);
      setAttList(result);
    });
  };

  const get_hplist = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hiring_application/hiring_process_getall/${company_id}`).then((res) => {
      setFullScreenLoader(false);
      // setHp_list(Apiconnect.decrypt_obj(res.data.data))
    });
  };

  const getDesList = () => {
    setFullScreenLoader(true);
    Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
      setFullScreenLoader(false);
      let _xtract = response.data.data;
      setDesList(_xtract.reverse());
    });
  };

  const getDeptList = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setDeptList(_xtract.reverse());
    });
  };

  const getEmployeeList = () => {
    setFullScreenLoader(true);
    Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
      setFullScreenLoader(false);
    });
  };

  const handleSubmit = () => {
    setFullScreenLoader(true);
    if (editId !== "") {
      if (dor !== "" && name !== "" && position !== "" && attrition !== "" && onboarding !== "" && department !== "" && location !== "" && unit_details !== "" && working_days !== "" && candidate_pref !== "" && location_type !== "" && salary_range !== "" && vacancies !== "" && hiring_process !== "" && q1 !== "" && exp1 !== "" && justification1 !== "" && key_deliverables1 !== "" && other_requisites1 !== "" && raised_by !== "" && approving_auth !== "") {
        Apiconnect.postData(`work_force/work_force_requisition_update/${company_id}/${editId}`, inputFields).then((res) => {
          setInputFields({
            name: "",
            dor: "",
            position: "",
            attrition: "",
            onboarding: "",
            department: "",
            location: "",
            unit_details: "",
            reporting_auth: "",
            working_days: "",
            candidate_pref: "",
            location_type: "",
            salary_range: "",
            additional_Perquisites: "",
            vacancies: "",
            hiring_process: "",
            q1: "",
            q2: "",
            q3: "",
            exp1: "",
            exp2: "",
            justification1: "",
            justification2: "",
            justification3: "",
            key_deliverables1: "",
            key_deliverables2: "",
            key_deliverables3: "",
            key_deliverables4: "",
            key_deliverables5: "",
            other_requisites1: "",
            other_requisites2: "",
            other_requisites3: "",
            raised_by: "",
            approving_auth: "",
            added_by: "",
          });
          setModalIsOpen(false);
          work_force_requisition_getall();
          alert.success("Updated");
          setFullScreenLoader(false);
        });
      } else {
        alert.error("Some Input fields are empty");
        alert.success("Error");
        setFullScreenLoader(false);
      }
    } else {
      if (name !== "" && dor !== "" && position !== "" && attrition !== "" && onboarding !== "" && department !== "" && location !== "" && unit_details !== "" && working_days !== "" && candidate_pref !== "" && location_type !== "" && salary_range !== "" && vacancies !== "" && hiring_process !== "" && q1 !== "" && exp1 !== "" && justification1 !== "" && key_deliverables1 !== "" && other_requisites1 !== "" && raised_by !== "" && approving_auth !== "") {
        Apiconnect.postData(`work_force/work_force_requisition_create_by_admin/${company_id}`, inputFields).then((res) => {
          setInputFields({
            name: "",
            dor: "",
            position: "",
            attrition: "",
            onboarding: "",
            department: "",
            location: "",
            unit_details: "",
            reporting_auth: "",
            working_days: "",
            candidate_pref: "",
            location_type: "",
            salary_range: "",
            additional_Perquisites: "",
            vacancies: "",
            hiring_process: "",
            q1: "",
            q2: "",
            q3: "",
            exp1: "",
            exp2: "",
            justification1: "",
            justification2: "",
            justification3: "",
            key_deliverables1: "",
            key_deliverables2: "",
            key_deliverables3: "",
            key_deliverables4: "",
            key_deliverables5: "",
            other_requisites1: "",
            other_requisites2: "",
            other_requisites3: "",
            raised_by: "",
            approving_auth: "",
            added_by: "",
          });
          setModalIsOpen(false);
          work_force_requisition_getall();
          alert.success("Created");
          setFullScreenLoader(false);
        });
      } else {
        alert.error("Input fields are empty");
        alert.success("Error");
        setFullScreenLoader(false);
      }
    }
  };

  const work_force_requisition_getall = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_requisition_getall/${company_id}`, { company_id: company_id })
      .then((res) => {
        setFullScreenLoader(false);
        let result = Apiconnect.decrypt_obj(res.data.data);
        setWorkfforce_list(result);
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (id) => {
    setModalIsOpen(true);
    setEditId(id);
    const filtered = workforce_list.filter((item) => item.wf_pk_id === id);
    const data = filtered[0];

    setInputFields({
      name: data.wf_name,
      dor: data.wf_date_of_requisition,
      position: data.wf_position,
      attrition: data.wf_attrition,
      onboarding: data.wf_onboarding_date,
      department: data.wf_department,
      location: data.wf_location,
      unit_details: data.wf_unit_details,
      reporting_auth: data.wf_reporting_authority,
      working_days: data.wf_working_days,
      candidate_pref: data.wf_candidate_pref,
      location_type: data.wf_location_type,
      salary_range: data.wf_salary_range,
      additional_Perquisites: data.wf_add_perquisites,
      vacancies: data.wf_no_of_vacancies,
      hiring_process: data.wf_hiring_process,
      q1: data.wf_qualification_1,
      q2: data.wf_qualification_2,
      q3: data.wf_qualification_3,
      exp1: data.wf_experience_1,
      exp2: data.wf_experience_2,
      justification1: data.wf_justification_1,
      justification2: data.wf_justification_2,
      justification3: data.wf_justification_3,
      key_deliverables1: data.wf_key_deliverables_1,
      key_deliverables2: data.wf_key_deliverables_2,
      key_deliverables3: data.wf_key_deliverables_3,
      key_deliverables4: data.wf_key_deliverables_4,
      key_deliverables5: data.wf_key_deliverables_5,
      other_requisites1: data.wf_other_requisites_1,
      other_requisites2: data.wf_other_requisites_2,
      other_requisites3: data.wf_other_requisites_3,
      raised_by: data.wf_raised_by,
      approving_auth: data.wf_approving_authority,
      added_by: data.wf_added_by,
    });
  };

  const handleDelete = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_requisition_delete`, { company_id: company_id, id: id }).then((res) => {
      work_force_requisition_getall();
      alert.success("Deleted");
      setFullScreenLoader(false);
    });
  };

  const handleAssign_hp = () => {
    let ins_ob = { wf_hiring_process_id: selected.hp_pk_id };
    if (hp_id !== "" || wf_id !== "") {
      Apiconnect.postData(`work_force/assign_vacancy_to_hiring_process/${wf_id}`, ins_ob).then((res) => {
        alert.success("Hiring Process Assigned Successfully");
        work_force_requisition_getall();
        setModalIsOpen_1(false);
        setHp_id("");
        setHp_id("");
      });
    } else {
      alert.error("Please Select Hiring Process");
    }
  };

  const get_hp_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hrms_hiring_process/getall/${company_id}`).then((res) => {
      setFullScreenLoader(false);
      setHp_list(res.data.data);
      console.log(res.data.data);
    });
  };

  useEffect(() => {
    get_hp_list();
  }, []);

  const [selected, setSelected] = useState([]);
  const [allchecked, setallchecked] = useState(null);

  const onInputChange_1 = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelected({
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelected({ ...selected, [e.currentTarget.name]: 0 });
    }
  };

  console.log("workforce_list", workforce_list);

  return (
    <>
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}

      <div className="content-page" style={page_styles.main_container_bg}>
        {/* ---Modal-Assign HP--- */}
        <Modal isOpen={modalIsOpen_1} style={customStyles_1} onRequestClose={() => setModalIsOpen(false)}>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between model_head" style={{ borderRadius: 5, background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
              <div className="iq-header-title">
                <h4 className="card-title text-white ">Assign Hiring Process</h4>
              </div>
              <button className="btn btn-sm btn-secondary" onClick={() => setModalIsOpen_1(false)}>
                Close
              </button>
            </div>
            <div className="iq-card-body">
              <div className="row">
                <div className="col-md-12">
                  <button className="btn btn-sm btn-success  float-right mr-2" onClick={handleAssign_hp}>
                    Assign
                  </button>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "10%" }} className="text-center"></th>
                          <th style={{ width: "10%" }} className="text-center">
                            #
                          </th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Assigned Rounds</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hp_list.map((item, index) => {
                          let ck = Object.keys(selected).find(function (element) {
                            return selected[element] == item.hp_pk_id;
                          });

                          var ck1 = "";
                          if (ck !== undefined) {
                            ck1 = true;
                          }

                          return (
                            <tr key={index}>
                              <td>{item.round_info.length > 0 && <input type="radio" name="hp_pk_id" value={item.hp_pk_id} checked={ck1} onChange={(e) => onInputChange_1(e)} />}</td>
                              <td>{index + 1}</td>
                              <td>{item.hp_name}</td>
                              <td className="text-center">
                                {item.round_info.length > 0 ? (
                                  item.round_info.map((val, key) => {
                                    return (
                                      <span className="badge badge-success mr-2" key={key}>
                                        <span className="badge badge-sm badge-secondary mt-2 ">{val.round_name} </span>
                                      </span>
                                    );
                                  })
                                ) : (
                                  <span className="badge badge-warning badge small">Rounds Not Assigned Yet</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* ---Modal--- */}
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between model_head" style={{ borderRadius: 5, background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
              <div className="iq-header-title">
                <h4 className="card-title text-white">Create Vacancies</h4>
              </div>
              <button className="btn btn-sm btn-secondary" onClick={() => setModalIsOpen(false)}>
                Close
              </button>
            </div>
            <div className="iq-card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="row m-2 p-2">
                    <div className="col-md-4 mb-3">
                      <label>Vacancy Name</label>
                      <input type="text" className="form-control" name="name" placeholder="Enter Vacancy Name" value={name} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Position</label>
                      <select className="form-control" name="position" value={position} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {desList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Date of Requisition</label>
                      <input type="date" className="form-control" name="dor" value={dor} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Additional/Replacement</label>
                      <select className="form-control" name="attrition" value={attrition} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {attList.map((item, index) => {
                          return (
                            <option key={index} value={item.wfma_pk_id}>
                              {item.wfma_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Expected Date of onboarding</label>
                      <input type="date" className="form-control" placeholder="Date of Requisition" name="onboarding" value={onboarding} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Department</label>
                      {/* <input type='text' className='form-control' placeholder='Department' name='department' value={department} onChange={e => onInputChange(e)} autoComplete='off'/>  */}
                      <select className="form-control" name="department" value={department} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {deptList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Location (Exact)</label>
                      <input type="text" className="form-control" placeholder="Enter Location" name="location" value={location} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Unit details (Additional in any)</label>
                      <input type="text" className="form-control" placeholder="Enter Unit details" name="unit_details" value={unit_details} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Reporting Authority</label>
                      {/* <input type='text' className='form-control' placeholder='Enter Reporting Authority' name='reporting_auth' value={reporting_auth} onChange={e => onInputChange(e)} autoComplete='off'/> */}
                      <select className="form-control" name="reporting_auth" value={reporting_auth} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {empList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.first_name + " " + item.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Working days/Time</label>
                      <input type="text" className="form-control" placeholder="Enter Working days/Time" name="working_days" value={working_days} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Candidate Preference</label>
                      <select className="form-control" name="candidate_pref" value={candidate_pref} onChange={(e) => onInputChange(e)}>
                        <option value="">--Select--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Local/Outstation</label>
                      <select className="form-control" name="location_type" value={location_type} onChange={(e) => onInputChange(e)}>
                        <option value="">--Select--</option>
                        <option value="Local">Local</option>
                        <option value="Outstation">Outstation</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Salary Range (Lakhs Per Annum)</label>
                      <input type="number" className="form-control" placeholder="Enter Salary Range" name="salary_range" value={salary_range} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Any Additional Perquisites</label>
                      <input type="text" className="form-control" placeholder="Enter Additional Perquisites" name="additional_Perquisites" value={additional_Perquisites} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Number of Vacancies</label>
                      <input type="number" className="form-control" placeholder="Enter Number of Vacancies" name="vacancies" value={vacancies} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Hiring process (incase different from the standard process shared in Recruitment policy)</label>
                      <input type="text" className="form-control" placeholder="Hiring process" name="hiring_process" value={hiring_process} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Qualification-1</label>
                          <input type="text" className="form-control" placeholder="Enter Qualification" name="q1" value={q1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Qualification-2</label>
                          <input type="text" className="form-control" placeholder="Enter Qualification" name="q2" value={q2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Qualification-3</label>
                          <input type="text" className="form-control" placeholder="Enter Qualification" name="q3" value={q3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-6 mb-3">
                          <label>Experience-1</label>
                          <input type="text" className="form-control" placeholder="Enter Experience" name="exp1" value={exp1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Experience-2</label>
                          <input type="text" className="form-control" placeholder="Enter Experience" name="exp2" value={exp2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Justification for adding the position-1</label>
                          <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification1" value={justification1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Justification for adding the position-2 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification2" value={justification2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Justification for adding the position-3 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification3" value={justification3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables1" value={key_deliverables1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-2 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables2" value={key_deliverables2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-3 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables3" value={key_deliverables3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-4 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables4" value={key_deliverables4} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-5 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables5" value={key_deliverables5} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Any other requisites to be considered while screening </label>
                          <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites1" value={other_requisites1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Any other requisites to be considered while screening-2 </label>
                          <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites2" value={other_requisites2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Any other requisites to be considered while screening-3 </label>
                          <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites3" value={other_requisites3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>

                    {/* <div className='col-md-6 mb-3'>
                                        <label>Raised by</label>
                                        <input type='text' className='form-control' placeholder='Raised by' name='raised_by' value={raised_by} onChange={e => onInputChange(e)} autoComplete='off'/> 
                                    </div> */}
                    <div className="col-md-6 mb-3">
                      <label>Approving Authority</label>
                      <input type="text" className="form-control" placeholder="Approving Authority" name="approving_auth" value={approving_auth} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-2 p-2 mt-4">
                <div className="col-md-12" align="center">
                  <button className="btn btn-sm btn-success" onClick={handleSubmit}>
                    {editId ? "Update" : "Create"}
                  </button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger" onClick={() => setModalIsOpen(false)}>
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <div
          className="iq-card"
          style={{
            borderRadius: 12,
            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          }}
        >
          <div className="iq-card-header d-flex justify-content-between model_head" style={{ borderRadius: 5, background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
            <div className="iq-header-title">
              <h4 className="card-title text-white">Work Force Vacancies</h4>
            </div>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12 mb-2">
                {/* <button className='btn btn-sm btn-success float-right mr-2' onClick={() => handleApproval()}>Send to approval</button> */}
                <button className="btn btn-sm btn-primary float-right mr-2" onClick={() => setModalIsOpen(true)}>
                  Create Vacancy
                </button>
                {/* <Link to={`/Hiring_process`} ><button className='btn btn-sm btn-info float-right mr-2'>Manage Hiring Process</button></Link> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 table-responsive">
                <table
                  className="table table-sm f-12 table-bordered mt-1 center"
                  role="grid"
                  style={{
                    borderRadius: 5,
                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                    border: "50px solid linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
                  }}
                >
                  <thead className="thead-light">
                    <tr className="clr">
                      <th scope="col">#</th>
                      <th scope="col">Vacancy Name</th>
                      <th scope="col">Vacancy For</th>
                      <th scope="col">Requisition Date</th>
                      <th scope="col">Onboarding Date</th>
                      <th scope="col">Vacancy Type</th>
                      <th scope="col">Vacancy Count</th>
                      <th scope="col">Hiring Process</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workforce_list.length > 0 ? (
                      workforce_list.map((item, index) => {
                        let dor = String(item.wf_date_of_requisition).length === 10 ? item.wf_date_of_requisition : moment(item.wf_date_of_requisition).format("DD/MM/YYYY");
                        let onbording = String(item.wf_onboarding_date).length === 10 ? item.wf_onboarding_date : moment(item.wf_date_of_requisition).format("DD/MM/YYYY");

                        let added = moment(item.wf_added_on).format("LLL");

                        let btn = null;
                        if (item.wf_status == 0) {
                          btn = (
                            <h5>
                              <span className="badge badge-warning">Pending</span>
                            </h5>
                          );
                        } else if (item.wf_status == 1) {
                          btn = (
                            <h5>
                              <span className="badge badge-success">Approved</span>
                            </h5>
                          );
                        } else {
                          btn = (
                            <h5>
                              <span className="badge badge-danger">Rejected</span>
                            </h5>
                          );
                        }

                        let hp = "";
                        if (item.wf_hiring_process_id === null) {
                          hp = "NA";
                        } else {
                          hp = item.wf_hiring_process_id;
                        }

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <span className="font-weight-bold">{item.wf_name}</span> <br /> <span className="text-muted">{added}</span>
                            </td>
                            <td>{item.wf_position}</td>
                            <td>{dor}</td>
                            <td>{onbording}</td>
                            <td>{item.attrition}</td>
                            <td>{item.wf_no_of_vacancies}</td>
                            <td>{item.hiring_process === null ? <span className="badge badge-pill badge-warning">Hiring Process Not Assigned</span> : <span className="badge badge-pill badge-success">{item.hiring_process}</span>}</td>
                            <td>{btn}</td>
                            <td>
                              <button
                                className="btn btn-info btn-sm mr-2 mb-2 "
                                onClick={() => {
                                  setModalIsOpen_1(true);
                                  setWf_id(item.wf_pk_id);
                                }}
                              >
                                Assign Hiring Process
                              </button>
                              <button className="btn btn-info btn-sm mr-2 mb-2" onClick={() => handleEdit(item.wf_pk_id)}>
                                Edit
                              </button>
                              {/* <button className='btn btn-danger btn-sm mr-2 mb-2' onClick={() => handleDelete(item.wf_pk_id)}>Delete</button> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <th colSpan={10} style={{ textAlign: "center" }}>
                          {" "}
                          No Data Found..{" "}
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work_force;

/* work force */
