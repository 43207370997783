import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import {Link} from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "70%" ,
        height: '80%'
    },
};

const Assign_panel = () => {

    const {hp_id, r_id} = useParams()

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [rnd_name, setRnd_name] = useState("")
    const [hp_name, sethp_name] = useState("")
    const [panel_list, setPanel_list] = useState([])
    const [selected, setSelected] = useState([]);
    const [allchecked, setallchecked] = useState(null);
    const [list,setList] = useState([])

    useEffect(() => {get_details(); get_panel_list(); get_hiring_process_round_panels()}, [])

    const get_details = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_get_round/${company_id}/${hp_id}/${r_id}`).then(res => {
            setFullScreenLoader(false)
            let arr = Apiconnect.decrypt_obj(res.data.data)
            setRnd_name(arr[0].name)
            sethp_name(arr[0].hp_name)
        })
    }

    const get_panel_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_panel_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setPanel_list(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    const selectAll = (e) => {
        if (e.target.checked === true) { 
          setallchecked("checked");
          let ob = {};
          panel_list.map((val, key) => {
            ob[val.id] = val.id;
          });
          setSelected(ob);
        }else{
          setallchecked(null);
          setSelected([]);
        }
    };
    const onInputChange = (e) => {
        setallchecked(null);
        if (e.target.checked === true) {
            setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
            });
        } else {
            setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
    }

    const handleAssign = () => {
        if(Object.keys(selected).length > 0){
            let ins_ob = {
                hprp_company_id:company_id,
                hprp_panels:selected,
                hprp_hiring_process_id: hp_id,
                hprp_hiring_round_id:r_id,
                hprp_created_by:emp_id
            }
            setFullScreenLoader(true)
            Apiconnect.postData(`hiring_application/hiring_process_round_panels_create`, ins_ob).then(res => {
                setFullScreenLoader(false)
                alert.success("Assigned")
                setModalIsOpen(false)
            })
        }else{
            alert.error("Please Select panels")
        }
    }

    const get_hiring_process_round_panels = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_round_panels_getall/${company_id}/${hp_id}/${r_id}`).then(res => {
            setList(Apiconnect.decrypt_obj(res.data.data))
            setFullScreenLoader(false)
        })
    }

    console.log(list)


    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_round_panels_delete/${id}`).then(res => {
            setFullScreenLoader(false)
        })
    }

    
    
  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}  
            <div className='container-fluid'>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>                                          {/* ---Modal-1--- onRequestClose={() => setModalIsOpen(false)}*/}  {/* ------Manage Rounds----- */}
                <div className='iq-card'>
                    <div className='iq-card-header d-flex justify-content-between model_head'>
                        <div style={{ float: "left" }} className="iq-header-title">
                            <h4 className="card-title">Assign Panel For <span className='font-weight-bold'>{rnd_name}</span> of <span className='font-weight-bold'>{hp_name}</span></h4>
                        </div>
                        <button className='btn badge btn-sm btn-dark' onClick={() => {setModalIsOpen(false)}}>close</button>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <button className='btn btn-sm btn-info float-right' onClick={handleAssign}>Assign</button>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <table className='table table-striped table-bordered center'>
                                    <thead>
                                        <tr >
                                            {/* <th><input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /></th> */}
                                            <th colSpan={2}>#</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            panel_list.length > 0 ?
                                            (
                                                panel_list.map((item, index) => {
                                                    let st = null
                                                    if(item.hpp_status === 1){
                                                        st = <span className='badge badge-pill badge-success'>Active</span>
                                                    }else{
                                                        st = <span className='badge badge-pill badge-danger'>Inactive</span>
                                                    }

                                                    let ck = Object.keys(selected).find(function (element) {
                                                        return selected[element] == item.id;
                                                    });  
                                                    
                                                    var ck1 = "";
                                                    if (ck !== undefined) {
                                                        ck1 = true;
                                                    }

                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row"><input type="checkbox" name={item.id} value={item.id} checked={ck1} onChange={(e) => onInputChange(e)}/></th>
                                                            <td>{index + 1}</td>
                                                            <td>{item.hpp_name}</td>
                                                            <td>{st}</td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <tr>
                                                    <th colSpan={6}>No Data Found...</th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className='iq-card-header d-flex justify-content-between model_head'>
                                <div className='iq-header-title' style={{float: "left"}}>
                                    <h4 className="card-title">Assign Panel For <span className='font-weight-bold'>{rnd_name}</span> of <span className='font-weight-bold'>{hp_name}</span></h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-info float-right' onClick={() => setModalIsOpen(true)}>Assign Panels</button>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                            <table className='table table-striped table-bordered center'>
                                                <thead>
                                                    <tr className="clr">
                                                        <th scope="col">#</th>
                                                        <th scope="col">Panel Name</th>
                                                        <th scope="col">Round</th>
                                                        <th scope="col">Hiring Process</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list.length > 0 ?
                                                        (
                                                            list.map((val, key) => {
                                                                return(
                                                                    <tr key={key}>
                                                                        <td>{key+1}</td>
                                                                        <td>{val.panel_name}</td>
                                                                        <td>{val.round_name}</td>
                                                                        <td>{val.hp_name}</td>
                                                                        <td>
                                                                            <button className='btn btn-sm btn-danger' onClick={() => handleDelete(val.id)} >Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        )
                                                        :
                                                        (
                                                            <tr>
                                                                <th colSpan={5}>No Data Found...</th>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Assign_panel