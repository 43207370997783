import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';

const EditProfile = () => {

    const alert = useAlert();

    let history = useHistory();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);

    const [anniversaryDate, setAnniversaryDate] = useState(new Date());

    const [info2, setInfo2] = useState({
        employee_mst_id: emp, present_address_one: '', present_address_two: '', present_landmark: '', present_area_id: '', present_city_id: '',
        present_state_id: '', present_country_id: '', present_zip: '', parmanent_address_one: '', parmanent_address_two: '',
        parmanent_landmark: '', parmanent_area_id: '', parmanent_city_id: '', parmanent_state_id: '', parmanent_country_id: '',
        parmanent_zip: '', phone_secondary: '', email_secondary: '', blood_group: '', religion: '', caste: '', nationality: '', marital_status: '',
        anniversary_date: '', emergency_contact_person: '', emergency_contact_number: '', pan: '', aadhaar: '', payment_mode: '',bank_name: '',
             ifsc_code: '', account_number: '',branch_name:'',present_area:'',parmanent_area:'',first_name: '', middle_name: '', last_name: ''
    });
    const { employee_mst_id, present_address_one, present_address_two, present_landmark, present_area_id, present_city_id,
        present_state_id, present_country_id, present_zip, parmanent_address_one, parmanent_address_two,
        parmanent_landmark, parmanent_area_id, parmanent_city_id, parmanent_state_id, parmanent_country_id,
        parmanent_zip, phone_secondary, email_secondary, blood_group, religion, caste, nationality, marital_status,
        anniversary_date, emergency_contact_person, emergency_contact_number, pan, aadhaar, payment_mode,bank_name,
         ifsc_code, account_number,branch_name,present_area,parmanent_area,first_name, middle_name, last_name } = info2;

    // const [reqinfo1, setReqInfo1] = useState([]);    
    // const onInputChange1 = e => {
    //     setInfo2({ ...info2, [e.target.name]: e.target.value });
    //     setReqInfo1({ ...reqinfo2, [e.target.name]: e.target.value,[e.target.name+'_status']: 1 });
    // };
            
    const [reqinfo2, setReqInfo2] = useState([]);    
    const onInputChange2 = e => {
        setInfo2({ ...info2, [e.target.name]: e.target.value });
        setReqInfo2({ ...reqinfo2, [e.target.name]: e.target.value,[e.target.name+'_status']: 1 });
    };

    const handleChangeDOB = e => {
        setDobDate(e);
        setReqInfo2({ ...reqinfo2,['date_of_birth_status']: 1 });
      };
    
    const handleChangeDOJ = e => {
        setDojDate(e);
        setReqInfo2({ ...reqinfo2,['joining_date_status']: 1 });
    }
    const [autoinfo, setAutoInfo] = useState([]);    
    const onInputChangeAuto = e => {
        setInfo2({ ...info2, [e.target.name]: e.target.value });
        setAutoInfo({ ...autoinfo, [e.target.name]: e.target.value});
    };

    const onSubmitA = async e => {
        e.preventDefault();
        autoinfo.employee_mst_id = emp;

        let autoinfo_enc = Apiconnect.encrypt_obj(autoinfo);
        const autoinf_a = { enc: autoinfo_enc };
         Apiconnect.postData(`employee_info/editprofile/`, autoinf_a).then((response) => {
             alert.success(response.data.message);
         });
        
    }
    
    const onSubmit = async e => {
        e.preventDefault();

        if(!file){  
            alert.error('document required');    
            return false
        }

        const formData = new FormData();
        formData.append('employee_id',emp);
        formData.append('detailsapprovaldoc',file);  

        Apiconnect.postData(`employee_info/editprofile_request_doc`, formData).then((response) => {

            if(response.data.status == 1){
                console.log('in edit function');
                reqinfo2.joining_date = format(dojDate, 'yyyy-MM-dd');
                reqinfo2.employee_mst_id = emp;
                reqinfo2.date_of_birth = format(dobDate, 'yyyy-MM-dd');
                reqinfo2.document = file.name;
                let info_enc = Apiconnect.encrypt_obj(reqinfo2);
                const inf_a = { enc: info_enc};

                Apiconnect.postData(`employee_info/editprofile_request/`, inf_a).then((response) => {
                    alert.success(response.data.message);
                });
                
            }


        });


      

    };

    function returnPage() {
        history.push("/ems/dashboard");
    }

    useEffect(() => { getArea(); getCity(); getState(); getCountry();getEditProfileData();ReactTooltip.rebuild(); }, []);
    const [areaList, setArea] = useState([]);
    const [cityList, setCity] = useState([]);
    const [stateList, setState] = useState([]);
    const [countryList, setCountry] = useState([]);
    const [dobDate, setDobDate] = useState(new Date());
    const [dojDate, setDojDate] = useState(new Date());

    const getEditProfileData = () => {    
        Apiconnect.getData(`employee_info/geteditprofile/${emp}`).then((response) => {        
           let _xtract = Apiconnect.decrypt_obj(response.data.data);  

            setInfo2({ present_address_one: _xtract[0].present_address_one, present_address_two: _xtract[0].present_address_two, present_landmark: _xtract[0].present_landmark, present_area_id: _xtract[0].present_area_id, present_city_id: _xtract[0].present_city_id,
                present_state_id: _xtract[0].present_state_id, present_country_id: _xtract[0].present_country_id, present_zip: _xtract[0].present_zip, parmanent_address_one: _xtract[0].parmanent_address_one, parmanent_address_two: _xtract[0].parmanent_address_two,
                parmanent_landmark: _xtract[0].parmanent_landmark, parmanent_area_id: _xtract[0].parmanent_area_id, parmanent_city_id: _xtract[0].parmanent_city_id, parmanent_state_id: _xtract[0].parmanent_state_id, parmanent_country_id: _xtract[0].parmanent_country_id,
                parmanent_zip: _xtract[0].parmanent_zip, phone_secondary: _xtract[0].phone_secondary, email_secondary: _xtract[0].email_secondary, blood_group: _xtract[0].blood_group,
                religion: _xtract[0].religion, caste: _xtract[0].caste, nationality: _xtract[0].nationality, marital_status: _xtract[0].marital_status,
                anniversary_date: _xtract[0].anniversary_date, emergency_contact_person: _xtract[0].emergency_contact_person, emergency_contact_number: _xtract[0].emergency_contact_number, pan: _xtract[0].pan, aadhaar: _xtract[0].aadhaar, payment_mode: _xtract[0].payment_mode,
                bank_name: _xtract[0].bank_name,ifsc_code: _xtract[0].ifsc_code, account_number: _xtract[0].account_number,branch_name: _xtract[0].branch_name,present_area: _xtract[0].present_area,parmanent_area: _xtract[0].parmanent_area,first_name: _xtract[0].first_name,
                middle_name: _xtract[0].middle_name,last_name: _xtract[0].last_name
            });     
            
            if (_xtract[0].joining_date !== null && _xtract[0].joining_date !== '0000-00-00') {
                setDojDate(new Date(_xtract[0].joining_date));
            } else {
                setDojDate(null);
            }

            if (_xtract[0].date_of_birth !== null && _xtract[0].date_of_birth !== '0000-00-00') {
                setDobDate(new Date(_xtract[0].date_of_birth));
            } else {
                setDobDate(null);
            }
        });
         
      };


    const getArea = () => {
        Apiconnect.getData("area/getall").then((response) => {
            setArea(response.data.data.reverse());
        });
    };

    const getCity = () => {
        Apiconnect.getData("city/getall").then((response) => {
            setCity(response.data.data.reverse());
        });
    };

    const getState = () => {
        Apiconnect.getData("state/getall").then((response) => {
            setState(response.data.data.reverse());
        });
    };

    const getCountry = () => {
        Apiconnect.getData("country/getall").then((response) => {
            setCountry(response.data.data.reverse());
        });
    };

    const [file,setFile] = useState()
    const handleChangeFile = (e) => {
        setFile(e.target.files[0])
        console.log(e.target.files[0].name)
    }

    /* ---------------------------Previous employee details ----------------------------*/

    

    const [startDate, setStartDate] = useState(new Date());
    const secondDate = new Date(startDate)
    const [nextDate, setNextDate] = useState(secondDate.getDate() + 1)
    const [endDate, setEndDate] = useState(new Date());
    const [editId, setEditId] = useState(0)
    let empId = Apiconnect.decrypt(localuser.id)

    /* ----------- */
    const [info, setInfo] = useState({
        company_id: Apiconnect.get_company_id(),
        employee_id: empId,
        organisaction_name: "",
        emp_designation: "",
        salary_drawn: "",
        from_date:format(new Date(), 'yyyy-MM-dd'),
        to_date:format(new Date(), 'yyyy-MM-dd'),
    })
    const {organisaction_name, emp_designation, salary_drawn, from_date, to_date} = info

    const handleChange = (e) => {
        setInfo({...info, [e.target.name]:e.target.value})
    }

    /* ----- get data------ */
    const [data, setData] = useState([])
    
    const getAllData = async () => {
        const inf_b = {company_id: Apiconnect.get_company_id(), employee_id: empId}
        await Apiconnect.postData(`leave_manage/employee_pre_emp_list`, inf_b).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data);
            setData(_xtract)
        })
    }
    useEffect(() => {getAllData()}, [])

    /* -----Add----- */

    const handleClick = (e) => {    
        e.preventDefault()
        info.from_date = format(startDate, 'yyyy-MM-dd');
        info.to_date = format(endDate, 'yyyy-MM-dd');
        const inf_a = {
            company_id: Apiconnect.get_company_id(),
            employee_id: empId,
            organisaction_name: info.organisaction_name,
            emp_designation: info.emp_designation,
            salary_drawn: info.salary_drawn,
            from_date: info.from_date,
            to_date: info.to_date
        }
        console.log(inf_a)
        if(data.find(item => item.empprev_organisaction_name.includes(inf_a.organisaction_name))){
            alert.error("Organisation name already Exists")
            setInfo({
                company_id: Apiconnect.get_company_id(),
                employee_id: empId,
                organisaction_name: "",
                emp_designation: "",
                salary_drawn: "",
                from_date:format(new Date(), 'yyyy-MM-dd'),
                to_date:format(new Date(), 'yyyy-MM-dd'),
            })
        }else if(inf_a.organisaction_name === "" || inf_a.emp_designation === "" || inf_a.salary_drawn === ""){
            alert.error("Please enter details")
        }else{
            Apiconnect.postData("leave_manage/employee_pre_emp_create", inf_a).then(res => {
                alert.success("Previous Employment Added")
                getAllData()
                setInfo({
                    company_id: Apiconnect.get_company_id(),
                    employee_id: empId,
                    organisaction_name: "",
                    emp_designation: "",
                    salary_drawn: "",
                    from_date:format(new Date(), 'yyyy-MM-dd'),
                    to_date:format(new Date(), 'yyyy-MM-dd'),
                })
            })
        }
    }


         /* delete */
         const handleDelete = async (id) => {
            await Apiconnect.postData(`leave_manage/employee_pre_emp_delete`, {empprev_pk_id: id}).then(res => getAllData())
        }



    return (
        <>


            <div id="content-page" className="content-page">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12 col-lg-12 col-md-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Edit Profile</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    {/* <form onSubmit={e => onSubmit(e)}> */}
                                        <div className="col-md-12">
                                            {/* <div className="" > */}

                                                

                                            <div className="border">

                                            <div><h4>Details I (<small>Need Approval</small>)</h4></div> 

                                            <div className="bbox" style={{ padding: '10px',margin: '10px 0' }}>
                                                 <h4>Personal Details</h4>     
                                                <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" className="form-control " name="first_name" value={first_name} placeholder="First Name" onChange={e => onInputChange2(e)} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Middle Name</label>
                                                        <input type="text" className="form-control " name="middle_name" value={middle_name} placeholder="Middle Name" onChange={e => onInputChange2(e)} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input type="text" className="form-control " name="last_name" value={last_name} placeholder="Last Name" onChange={e => onInputChange2(e)} readOnly />
                                                    </div>
                                                </div>   



                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Date of Birth</label>
                                                            <DatePicker
                                                            selected={dobDate}
                                                            // onChange={e => setDobDate(e)}
                                                            onChange={e => handleChangeDOB(e)}
                                                            className="form-control"
                                                            dateFormat='yyyy-MM-dd'
                                                            placeholderText="Date Of Birth"
                                                            name="date_of_birth"
                                                            readOnly
                                                            dropdownMode="select"
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Date of Joining</label>
                                                            <DatePicker
                                                            selected={dojDate}
                                                            // onChange={e => setDojDate(e)}
                                                            onChange={e => handleChangeDOJ(e)}
                                                            className="form-control"
                                                            dateFormat='yyyy-MM-dd'
                                                            placeholderText="Date Of Joining"
                                                            name="joining_date"
                                                            readOnly
                                                            dropdownMode="select"
                                                        />
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                            <div className="bbox" style={{ padding: '10px',margin: '10px 0' }}>
                                                <h4>Present Address</h4>

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>House No &nbsp;&nbsp;
                                                            {/* <i data-tip="Approved" data-type="info" class="fa fa-check-circle" aria-hidden="true" style={{color: 'green',fontSize: '20px'}}></i>
                                                            &nbsp;<i class="fa fa-times-circle" aria-hidden="true" style={{color: 'red',fontSize: '20px'}}></i> */}
                                                            </label>
                                                            <input type="text" className="form-control validate[required]" name="present_address_one" placeholder="House No" value={present_address_one} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Additional Address</label>
                                                            <input type="text" className="form-control validate[required]" name="present_address_two" placeholder="Additional Address" value={present_address_two} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Landmark</label>
                                                            <input type="text" className="form-control validate[required]" name="present_landmark" placeholder="Landmark" value={present_landmark} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>

                                                   <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Area</label>
                                                        <input type="text" className="form-control validate[required]" name="present_area" placeholder="Enter Area" value={present_area} onChange={e => onInputChange2(e)} />
                                                    </div>
                                                    </div>

                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Present Area</label>
                                                            <select className="form-control" name="present_area_id" value={present_area_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select Area</option>
                                                                {areaList.map((val, key) => {
                                                                    return (
                                                                        <option value={val.id}>{val.name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <select className="form-control" name="present_city_id" value={present_city_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select City</option>
                                                                {cityList.map((val, key) => {
                                                                    return (
                                                                        <option value={val.id}>{val.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>State</label>
                                                            <select className="form-control" name="present_state_id" value={present_state_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select State</option>
                                                                {
                                                                    stateList.map((val, key) => {
                                                                        return (
                                                                            <option value={val.id}>{val.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Country</label>
                                                            <select className="form-control" name="present_country_id" value={present_country_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countryList.map((val, key) => {
                                                                        return (
                                                                            <option value={val.id}>{val.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Postal Code</label>
                                                            <input type="text" className="form-control validate[required]" name="present_zip" placeholder="Postal Code" value={present_zip} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                </div>
                                                {/* </div> */}

                                                {/* <div className="border"> */}
                                           <div className="bbox" style={{ padding: '10px',margin: '10px 0' }}>
                                                <h4>Permanent Address</h4>                

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>House No</label>
                                                            <input type="text" className="form-control validate[required]" name="parmanent_address_one" placeholder="House No" value={parmanent_address_one} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Additional Address</label>
                                                            <input type="text" className="form-control validate[required]" name="parmanent_address_two" placeholder="Additional Address" value={parmanent_address_two} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Landmark</label>
                                                            <input type="text" className="form-control validate[required]" name="parmanent_landmark" placeholder="Permanent Landmark" value={parmanent_landmark} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Area</label>
                                                            <input type="text" className="form-control validate[required]" name="parmanent_area" placeholder="Enter Area" value={parmanent_area} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Permanent Area</label>
                                                            <select className="form-control" name="parmanent_area_id" value={parmanent_area_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select Area</option>
                                                                {areaList.map((val, key) => {
                                                                    return (
                                                                        <option value={val.id}>{val.name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <select className="form-control" name="parmanent_city_id" value={parmanent_city_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select City</option>
                                                                {cityList.map((val, key) => {
                                                                    return (
                                                                        <option value={val.id}>{val.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>State</label>
                                                            <select className="form-control" name="parmanent_state_id" value={parmanent_state_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select State</option>
                                                                {
                                                                    stateList.map((val, key) => {
                                                                        return (
                                                                            <option value={val.id}>{val.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Country</label>
                                                            <select className="form-control" name="parmanent_country_id" value={parmanent_country_id} onChange={e => onInputChange2(e)}>
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countryList.map((val, key) => {
                                                                        return (
                                                                            <option value={val.id}>{val.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Postal Code</label>
                                                            <input type="text" className="form-control validate[required]" name="parmanent_zip" placeholder="Postal Code" value={parmanent_zip} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                {/* </div>                 */}
                                            
                                            
                                       

                                                 
                                            

                                             {/* <div className="border"> */}
                                             <div className="bbox" style={{ padding: '10px',margin: '10px 0' }}>
                                                 <h4>Financial Details</h4>     
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Bank Name</label>
                                                            <input readOnly type="text" className="form-control validate[required]" name="bank_name" placeholder="Bank Name" value={bank_name} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>IFSC Code</label>
                                                            <input readOnly type="text" className="
                                 form-control validate[required]" name="ifsc_code" placeholder="IFSC Code" value={ifsc_code} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Bank A/C No</label>
                                                            <input readOnly type="text" className="
                                 form-control validate[required]" name="account_number" placeholder="Account Number" value={account_number} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Branch Name</label>
                                                            <input readOnly type="text" className="
                                 form-control validate[required]" name="branch_name" placeholder="Branch Name" value={branch_name} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>PAN No</label>
                                                            <input readOnly type="text" className="form-control validate[required]" name="pan" placeholder="Pan Card Number" value={pan} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Aadhaar Number</label>
                                                            <input readOnly type="text" className="datepicker
                                 form-control validate[required]" data-date-format="dd-mm-yyyy" name="aadhaar" placeholder="Aadhaar Number" value={aadhaar} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Payment Mode</label>
                                                            <select className="form-control" name="payment_mode" value={payment_mode} onChange={e => onInputChange2(e)}>
                                                                <option >Select Payment Mode</option>
                                                            </select>
                                                        </div>
                                                    </div> */}
                                             

                                                    {/* <div className="col-md-3">
                                                        <label>Tax Borne By Company</label>
                                                        <div className="flex">
                                                            &nbsp;&nbsp;&nbsp;
                                                            <label>
                                                                <input type="radio" className="" name="tax_borne_by_copany" value="1" id="yes" value={tax_borne_by_copany} onChange={e => onInputChange2(e)} />
                                                                &nbsp;Yes</label>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <label >
                                                                <input type="radio" className="" name="tax_borne_by_copany" value="0" id="no" value={tax_borne_by_copany} onChange={e => onInputChange2(e)} />
                                                                &nbsp;NO</label>

                                                        </div>
                                                    </div> */}
                                                </div>
                                                </div>


                                                {/* <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Senior Citizen</label>
                                                            <select className="form-control" name="is_senior_citizen" value={is_senior_citizen} onChange={e => onInputChange2(e)}>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Active Or Not</label>
                                                            <select className="form-control" name="is_epf" value={is_epf} onChange={e => onInputChange2(e)}>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>UAN</label>
                                                            <input type="text" className="form-control validate[required]" name="UAN_number" placeholder="UAN Number" value={UAN_number} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>EPF Number</label>
                                                            <input type="text" className="form-control validate[required]" name="epf_number" placeholder="EPF Number" value={epf_number} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>PF Start Date</label>
                                                            <DatePicker
                                                                selected={pfStartDate}
                                                                onChange={e => setPfStartDate(e)}
                                                                className="form-control"
                                                                dateFormat='yyyy-MM-dd'
                                                                placeholderText="PF Start Date"
                                                                name="pf_start_date"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>ESI Member</label>
                                                            <select className="form-control" name="is_esi" value={is_esi} onChange={e => onInputChange2(e)}>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>ESI No</label>
                                                            <input type="text" className=" form-control validate[required]" name="esi_number" placeholder="ESI No" value={esi_number} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Dispencery</label>
                                                            <input type="text" className=" form-control validate[required]" name="dispencery" placeholder="Dispencery" value={dispencery} onChange={e => onInputChange2(e)} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                        <div className="col-md-4" style={{paddingTop:'10px'}} >            
                                        <label for="photo">Upload Document &nbsp; </label>     
                                            <input 
                                            type="file"
                                            id="photo" 
                                            name="detailsapprovaldoc"
                                            accept=".png, .jpg, .jpeg"
                                            required
                                            onChange={(e) => handleChangeFile(e)}
                                                ></input>  
                                                <br/>

                                                <span style={{fontSize:'10px',color:'#FF0000'}}>Image size should be less than 1 mb</span>
                                          </div>

                                              <div className="col-md-12 m-t-20" style={{paddingTop:'20px',textAlign:'right'}}>
                                                <button type="button" name="id" className="btn btn-primary mr-2" onClick={e => onSubmit(e)}>Submit</button>
                                                <button type="button" className="btn btn-info" onClick={returnPage}> Return to dashboard </button>
                                            </div>

                                            </div> 


                                           

                                            <div className="border">  
                                            <div><h4>Details II (<small>Need No Approval</small>)</h4></div>   
                                                <div className="bbox" style={{ padding: '10px' }}>
                                                 <h4>Other Details</h4>               
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Phone Secondary</label>
                                                            <input type="text" className="form-control validate[required]" name="phone_secondary" placeholder="Phone Secondary" value={phone_secondary} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Email Secondary</label>
                                                            <input type="email" className="form-control validate[required]" name="email_secondary" placeholder="Email Secondary" value={email_secondary} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Blood Group</label>
                                                            <input type="email" className="form-control validate[required]" name="blood_group" placeholder="Blood Group" value={blood_group} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Religion</label>
                                                            <input type="text" className="form-control validate[required]" name="religion" placeholder="Religion" value={religion} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Caste</label>
                                                            <input type="email" className="form-control validate[required]" name="caste" placeholder="Caste" value={caste} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Nationality</label>
                                                            <input type="email" className="form-control validate[required]" name="nationality" placeholder="Nationality" value={nationality} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                          

                                                 <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Marital Status</label>
                                                            <select className="form-control" name="marital_status" value={marital_status} onChange={e => onInputChangeAuto(e)}>
                                                        <option value='' >Select Marital Status</option>
                                                        <option value='married'>Married </option>
                                                        <option value='unmarried'>Unmarried</option>
                                                        <option value='widow'>Widow</option>
                                                        <option value='separated'>Separated</option>
                                                    </select>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Emergency Contact Person</label>
                                                            <input type="text" className="form-control validate[required]" name="emergency_contact_person" placeholder="Emergency Contact Person" value={emergency_contact_person} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Emergency Contact Number</label>
                                                            <input type="text" className="form-control validate[required]" name="emergency_contact_number" placeholder="Emergency Contact Number" value={emergency_contact_number} onChange={e => onInputChangeAuto(e)} />
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>

                                            <div className="col-md-12 m-t-20" style={{paddingTop:'20px',textAlign:'right'}}>
                                                <button type="button" name="id" className="btn btn-primary mr-2" onClick={e => onSubmitA(e)}>Submit</button>
                                                <button type="button" className="btn btn-info" onClick={returnPage}> Return to dashboard </button>
                                            </div>

                                            </div>
                                            <div className='border'>
                                                <div><h4>Previous Employment</h4></div>
                                                <div className="bbox" style={{ padding: '10px' }}>
                                                    <h4>Add Previous Employment</h4>
                                                    <div className="row" style={{justifyContent: "space-between"}}>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Name of the Company/ School/ College</label>
                                                                <input type='text' className="form-control" name='organisaction_name' value={organisaction_name} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Period of Service From</label>
                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={e => setStartDate(e)}
                                                                        placeholderText="from date" 
                                                                        name="from_date"
                                                                        className="form-control"
                                                                        dateFormat='yyyy-MM-dd'
                                                                        required=""
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        scrollableMonthDropdown
                                                                        yearDropdownItemNumber={100}
                                                                        dropdownMode="select"
                                                                        />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Period of Service To</label>
                                                                    <DatePicker
                                                                        selected={endDate}
                                                                        onChange={e => setEndDate(e)}
                                                                        placeholderText="To Date" 
                                                                        minDate={startDate}
                                                                        name="to_date"
                                                                        className="form-control"
                                                                        dateFormat='yyyy-MM-dd'
                                                                        required=""
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        scrollableMonthDropdown
                                                                        yearDropdownItemNumber={100}
                                                                        dropdownMode="select"
                                                                        />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{gap: "50px"}} >
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Designation</label>
                                                                <input type='text' className="form-control" name='emp_designation' value={emp_designation} onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Salary Drawn</label>
                                                                <input type='text' className="form-control" name='salary_drawn' value={salary_drawn} onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 m-t-20" style={{paddingTop:'20px',textAlign:'right'}}>
                                                    <button type="button" name="id" className="btn btn-primary mr-2" onClick={handleClick}>{editId ? "Update" : "Submit"}</button>
                                                    <button type="button" className="btn btn-info" onClick={returnPage}> Return to dashboard </button>
                                                </div>
                                                <div className="iq-card-body">
                                            <table className="table table-striped table-hover">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Organisation</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                        <th scope="col">Designation</th>
                                                        <th scope="col">Salary Drawn</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.map((value, key) => {
                                                            let s = value.empprev_date_from.slice(0, 10)
                                                            let e = value.empprev_date_to.slice(0, 10)

                                                            return(
                                                            <tr>
                                                                <th>{key+1}</th>
                                                                <th>{value.empprev_organisaction_name}</th>
                                                                <th>{s}</th>
                                                                <th>{e}</th>
                                                                <th>{value.empprev_designation}</th>
                                                                <th>{value.empprev_status}</th>
                                                                <th>
                                                                    <button type="button" className="btn btn-danger mr-2" onClick={() => {handleDelete(value.empprev_pk_id)}}>Delete</button>
                                                                </th>
                                                            </tr>
                                                        )})
                                                    }
                                                </tbody>
                                            </table>
                                        </div> 
                                            </div>

                                        </div>



                                    {/* </form> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProfile