import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Apiconnect from "../services/Apiconnect.js";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import UserService from "../services/user.service";
import DatePicker from "react-datepicker";
import Helper from "../services/HelperCodebase.js";
import { format } from "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import moment from "moment"; 
import jsPDF from 'jspdf'
import "react-datepicker/dist/react-datepicker.css";

export default class combine_view_emp_salary_slip extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      content: "", 
      sel_user_id2: "",  
      company_id:"",
      year:"",
      month:'',
      months:'',
      alert:'',
      localuser:'',
      salaryMonth:'',
      deduction_tot:'',
      earning_tot:'',
      leavestypelist:[],
      debited_leaves:[],
      paidDay:'',
      logo:'',
      companyData:[],
      earning_head:[],
      deduct_head:[],
      salaryMonth:[],
      array_mn:[],
      cal_total_leaveapply:0,
      list_categorys:[],
      aadhaar:'',
      setlogo_base64:'',
    };
  } 
  async componentDidMount() {
    var company_id = this.state.company_id;
    var sel_user_id = this.state.sel_user_id; 
    this.setState({ sel_user_id2: sel_user_id });

    let mn = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];

    this.setState({ array_mn: mn });

    await this.getsalaryMonthWise();
    await this.getEmpInfo();
    await this.getLeaveByType();
    await this.getMonthlyPaidDay();
    await this.getCompany();
    await this.getdeductList();
    await this.get_earning_head();
    await this.get_lists_categoryss();
  } 

  

  getsalaryMonthWise = () => {
    // setFullScreenLoader(true)
    const info = {};
    info.month = this.props.month;
    info.year = this.props.year;
    info.emp_id = this.props.sel_user_id;
    let info_enc =  Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };
    Apiconnect.postData(`public/getsalarymonthwisepdf`,inf_a).then((response) => { 
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if(_xtract.length <= 0){
      }else{  
        this.setState({ earning_tot:_xtract[0].earning_tot });
        this.setState({ deduction_tot:_xtract[0].deduction_tot }); 
        this.setState({ salaryMonth:_xtract });  
        // setFullScreenLoader(false);
      } 
    });
  }; 

  get_earning_head = () => {         
    Apiconnect.getData(`earning_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data); 
      // console.log(ern);
      this.setState({ earning_head:ern });
    });
  };

  getLeaveByType = () => {
    Apiconnect.getData(`employee_leave/getAllLeaveByType/${this.props.sel_user_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      this.setState({ leavestypelist:_xtract.reverse() });
    }); 
    Apiconnect.getData(`employee_leave/totaltakenleave/${this.props.sel_user_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].debitleaves !== null) { 
        this.setState({ debited_leaves:_xtract[0].debitleaves });
      }
    });
  };

  getCompany =async ()  => {
    if (this.props.company_id != "") {
      Apiconnect.getData(`company/get/${this.props.company_id}`).then(async(response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data); 
        this.setState({ companyData:_xtract}); 
        if (_xtract[0].logo == null) { 
          this.setState({ logo:"images/logo.png"}); 
        } else {
          var img = `${process.env.React_App_BASE_URL_API}/uploads/logo/` + _xtract[0].logo;
          this.setState({ logo:img}); 
          await this.get_image_encode(img);
        }
      });
    } else {
      var img =process.env.React_App_BASE_URL_API + "/uploads/logo/school_logo.png";
      this.setState({ logo:img}); 
      await this.get_image_encode(img);
    }
  };

 
  getMonthlyPaidDay = () => {
    Apiconnect.getData(`employee_leave/getmonthlypaiddays/${this.props.sel_user_id}/${this.props.year}/${this.props.month}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      this.setState({ paidDay:_xtract[0].present_days}); 
    });
  }
 

  getEmpInfo = () =>{
    Apiconnect.getData(`employee_monthly_salary/getempsalaryinfo/${this.props.sel_user_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if(_xtract.length > 0){
        this.setState({ 
          name: _xtract[0].first_name + ' '+ _xtract[0].middle_name + ' ' + _xtract[0].last_name, department: _xtract[0].department, emp_code: _xtract[0].emp_code,
          account_number: _xtract[0].account_number, bank_name: _xtract[0].bank_name, pan: _xtract[0].pan,esi_number:_xtract[0].esi_number,
          epf_number:_xtract[0].epf_number,joining_date:_xtract[0].joining_date,designation:_xtract[0].designation,aadhaar:_xtract[0].aadhaar,branch_name:_xtract[0].branch_name
        });  
      }
    });
  }

  getdeductList = () => { 
    Apiconnect.getData(`deduction_head/getall/${Apiconnect.get_company_id()}`).then((response) => {           
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      this.setState({ deduct_head: _xtract }); 
    });
  };

  get_lists_categoryss = (imageUrl='') => {  
    var data_post={
      company_id:Apiconnect.get_company_id(),
      employee_id:this.props.sel_user_id,
      is_admin:0,
      sel_shortleave_type:'No',
    };   
    Apiconnect.postData(`leave_manage/get_employee_policy_category_list`,data_post).then(async (response) => {
      var list_categorysres=(typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):0;
      this.setState({ list_categorys: list_categorysres }); 
    });     
  }

  get_image_encode = (imageUrl) => { 
    let jpgDataUrlPrefix = 'data:image/png;base64,'; 
    let data_pst={ url_image:imageUrl }
    Apiconnect.postData('public/get_image_encode_ajax',data_pst).then( (response) => {
      this.setState({setlogo_base64:jpgDataUrlPrefix+response.data.data});
    });  
  } 
  roundOff(value,round) {
    return (parseInt(value * (10 ** (round + 1))) - parseInt(value * (10 ** round)) * 10) > 4 ? (((parseFloat(parseInt((value + parseFloat(1 / (10 ** round))) * (10 ** round))))) / (10 ** round)) : (parseFloat(parseInt(value * (10 ** round))) / ( 10 ** round));
  }
  

 
  render() { 
    var sel_user_id = this.props.sel_user_id;
    let sel_user_name = this.props.sel_user_name;  
      
    const onSubmit = async (event) => {
      event.preventDefault();  
    };
    
    const generatePDF = e => { 
      let sel_month = (typeof this.state.array_mn[this.props.months]!='undefined') ? this.state.array_mn[this.props.months] : ''; 
      let sel_name=this.state.name;
      // console.log(this.state.name);
      // console.log(sel_month);
      var doc = new jsPDF('p', 'pt','a3'); 
      doc.html(document.getElementById('print'), {
        callback: function (doc) {
          // console.log(doc);
          doc.save(sel_name+' payslip_'+sel_month);
        },
        x:35,
        y: 20,
      });
    } 
    
    var cal_total_leaveapply=0;
    let companyData = this.state.companyData.length > 0 ? this.state.companyData : []; 

    let sel_month = (typeof this.state.array_mn[this.props.months]!='undefined') ? this.state.array_mn[this.props.months] : ''; 
    
    let leavestypelist = this.state.leavestypelist.length > 0 ? this.state.leavestypelist : []; 

    let list_categorys = this.state.list_categorys.length > 0 ? this.state.list_categorys : []; 
     

    let company_name=companyData.length>0?companyData[0]['company_name']:''; 

    // console.log(company_name);
    return (
      <>
        <div>
        <div className="row">
        <div className="col-sm-2 col-lg-2">
        </div>
        <div className="col-sm-10 col-lg-10">
            <div className="iq-card" style={{marginLeft:"10px;"}}>
                <div className="iq-card-body"  >
                <div className="col-md-10 m-t-20" style={{paddingBottom:'20px',marginTop:'60px'}}>
                    <button className="btn btn-success mr-2" onClick={e=>generatePDF(e)} 
                        style={{cursor: 'pointer',float: 'right'}}>
                        <i className="fa fa-download" aria-hidden="true" ></i> Download
                    </button>
                </div>
                <div className="container mt-5 mb-5" style={{'backgroundColor': 'unset'}}>
                    <div className="row" id="print" style={{ width: '85%'}}>
                    <div  style={{ border: '1px solid black',fontSize:'10px'   }}>
                        <div className="col-sm-12 col-lg-12" style={{marginBottom: '10px',borderBottom:'1px solid black' }}>
                        <div className="row" >
                            <div className="col-md-3" style={{padding: '10px'}}>
                              <img style={{width: '110px'}} src={this.state.setlogo_base64}/>
                            </div>
                            <div className="text-center col-md-6" style={{padding: '10px'}}>
                            <h6 style={{ marginBottom: '1px'}}><b> {company_name}</b></h6>
                            <span className="fw-normal">{companyData.length>0 ? this.state.companyData[0].address_one : ''}</span> <br/>
                            <span className="fw-normal">{companyData.length>0 ? this.state.companyData[0].address_two : ''}</span> <br/>
                            <span className="fw-normal">{companyData.length>0 ? this.state.companyData[0].website +' / '+ (companyData.length>0 ? this.state.companyData[0].phone:'') : ''}</span>
                              <div className="text-center lh-1 mb-2">
                                <h6 style={{marginTop:'10px',marginBottom:'3px'}} className="fw-bold">Payslip</h6>    <span className="fw-normal">Payment slip for the month of <b>{sel_month} {this.props.year}</b></span>
                              </div>
                            </div>
                            <div className="col-md-3">
                            </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                  <div className="row">
                                  <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>EMP Name :</b> </span>{this.state.name} </div>
                                      </div>
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>Bank Name :</b> </span> {this.state.bank_name} </div>
                                      </div>
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>ESI No. :</b> </span> {this.state.esi_number} </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                  <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>EMP Code :</b> </span> {this.state.emp_code} </div>
                                      </div>
                                      
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>Bank A/C no. :</b> </span> {this.state.account_number} </div>
                                      </div>
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>PF/UAN No :</b> </span> {this.state.epf_number} </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>Department :</b> </span> {this.state.department} </div>
                                      </div>
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>Designation :</b> </span> {this.state.designation}  </div>
                                      </div>
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>PAN No :</b> </span> {this.state.pan}  </div>
                                      </div>
                                      
                                  </div>
                                  <div className="row">
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>DOJ :</b> </span> {this.state.joining_date} </div>
                                      </div>
                                      <div className="col-md-4">
                                      </div>
                                      <div className="col-md-4">
                                          <div> <span className="fw-bolder"><b>AADHAR No :</b> </span> {this.state.aadhaar} </div>
                                      </div>
                                      
                                      
                                  </div>
                                  
                              </div>

                              <div className="col-md-12" style={{marginTop:'10px'}}>
                                  <div className="col-md-6" style={{paddingLeft:'0px'}}>
                                      <div><b>Total Paid Days :</b> <span>{this.state.paidDay}</span></div>
                                    </div>
                                  <div className="col-md-6" style={{float:'right'}}>
                                  <table style={{ width: "100%", border: "1px solid #a3a3a3",textAlign:'center' }} >
                                    <thead>
                                    <tr style={{  border: "1px solid #a3a3a3", }} key="164949">
                                      <th
                                          style={{
                                          border: "1px solid #a3a3a3",
                                          width: "10%",
                                          }}
                                      >
                                          #
                                      </th>
                                      <th
                                          style={{
                                          border: "1px solid #a3a3a3",
                                          width: "30%",
                                          }}
                                      >
                                          Leave Type
                                      </th>
                                      <th
                                          style={{
                                          border: "1px solid #a3a3a3",
                                          width: "30%",
                                          }}
                                      >
                                          Taken Leave
                                      </th>
                                      <th
                                          style={{
                                          border: "1px solid #a3a3a3",
                                          width: "30%",
                                          }}
                                      >
                                          Total Available Till Now
                                      </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {  
                                        list_categorys.length>0 ?(
                                            list_categorys.map((val_lpcat2, key_cat2) => {  
                                            
                                                let cal_total_apply=val_lpcat2.cal_total_apply;
                                                let cal_total_credit=val_lpcat2.cal_total_credit;
                                                
                                                let res_cal_tot1= parseFloat(cal_total_credit) - parseFloat(cal_total_apply);
                        
                                                if(res_cal_tot1<0){
                                                res_cal_tot1=0;
                                                }

                                                if(cal_total_apply<0){
                                                cal_total_apply=0;
                                                }

                                                if(cal_total_apply!=0){ 
                                                    cal_total_leaveapply+=cal_total_apply;
                                                    return (
                                                        <tr key={key_cat2}>
                                                            <td style={{ border: "1px solid #a3a3a3",padding:"5px" }}>{key_cat2+1}</td>
                                                            <td style={{ border: "1px solid #a3a3a3",padding:"5px" }}> {val_lpcat2.leave_cat_code} </td> 
                                                            <td style={{ border: "1px solid #a3a3a3"}}> {cal_total_apply} </td>
                                                            <td style={{ border: "1px solid #a3a3a3"}}> {res_cal_tot1.toFixed(2)} </td>
                                                        </tr>
                                                    ); 
                                                } 
                                            })
                                        ):
                                          <tr>
                                              <th colSpan={4}>No Data Found.. </th>
                                          </tr>
                                      }
                                      </tbody>
                                      <tfoot>
                                      <tr style={{ border: "1px solid #a3a3a3" }}>
                                      <th style={{ border: "1px solid #a3a3a3" }}></th>
                                      <th style={{ border: "1px solid #a3a3a3" }}>Total</th>
                                      <th style={{ border: "1px solid #a3a3a3" }}>{this.state.debited_leaves}</th>
                                      </tr>
                                      </tfoot>
                                  </table>
                                  </div>
                              </div>



                              <table className="mt-4 table">
                                  
                                  <tbody>
                                      <tr>
                                          <td scope="row" colSpan="2" style={{ padding: '0px' }}>
                                              <table className='table  table-bordered' style={{ width: '100%' }}>
                                                  <thead>
                                                  <tr>
                                                      <th style={{padding: '8px'  }} scope="col">Earnings</th>
                                                      <th style={{padding: '8px',textAlign: 'center'  }} scope="col">Amount</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {
                                                    this.state.earning_head.map((val1)=>{

                                                      var data = this.state.salaryMonth.find(val => val.typ == 'earning' && val.earning_name == val1.name)

                                                      return (
                                                          data ? (
                                                              <tr style={{ borderBottom: '1px solid #dee2e6',padding: '7px'  }}>
                                                              <th style={{padding: '7px'  }}> {data.earning_name}</th>
                                                              <td style={{ textAlign: 'center',padding: '7px'  }}>{data.amount}</td>
                                                          </tr>     
                                                        ) : null 
                                                      )
                                                    })
                                                  }
                                                  </tbody>
                                                  <tfoot>
                                                  <tr className="border-top">
                                                      <th scope="row">Total Earning</th>
                                                      <th style={{textAlign: 'center'  }}>{this.state.earning_tot}</th>
                                                  </tr>
                                                  </tfoot>
                                              </table>
                                          </td>
                                          <td colSpan="2" style={{ padding: '0px' }}>
                                              <table className='table  table-bordered' style={{ width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                  <th style={{padding: '8px'  }} scope="col">Deductions</th>
                                                  <th style={{padding: '8px',textAlign: 'center'  }} scope="col">Amount</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {

                                                    this.state.deduct_head.map((val1)=>{

                                                      var data = this.state.salaryMonth.find(val => val.typ == 'deduction' && val.deduction_name == val1.name)

                                                        return (
                                                            data ? (
                                                                <tr style={{ borderBottom: '1px solid #dee2e6',padding: '7px'  }}>
                                                                <th style={{padding: '7px'  }}> {data.deduction_name}</th>
                                                                <td style={{ textAlign: 'center',padding: '7px'  }}>{data.amount}</td>
                                                            </tr>     
                                                        ) : null
                                                      )
                                                    })
                                                  }
                                                  </tbody>
                                                  <tfoot>
                                                    <tr className="border-top">
                                                      <th>Total Deductions</th>
                                                      <th style={{textAlign: 'center'  }}>{this.state.deduction_tot}</th>
                                                  </tr>
                                                  </tfoot>
                                              </table>
                                          </td> 
                                      </tr> 
                                  </tbody>
                              </table>
                          </div>
                          <div style={{paddingTop: '5px',paddingBottom:'5px',fontSize: '18px'}}><b>Net Pay. : </b> {this.state.earning_tot-this.state.deduction_tot}</div>
                        </div>
                      
                    </div>
                    <div className="row" style={{textAlign:'center',padding:'8px',width: '100%'}}>
                      <div className="" style={{textAlign:'center',width:'100%',fontSize:'12px'}}>
                        <span className="mt-4">This is a System Generated Payslip, Hence Signature and Stamp does not Required</span>
                      </div>
                      </div>
                    </div>
                </div>
                  
              </div>
            </div>
        </div>

      </div> 
      </div> 
      </>
    );
  }
}
