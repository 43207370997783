import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import { addDays } from "date-fns";

const Separation = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);

  const [rdate, setRdate] = useState("");

  const [ro, setRo] = useState("");
  const [roId, setRoId] = useState("");
  const [list, setList] = useState([]);
  const [reason, setReason] = useState("");

  useEffect(() => {
    getUserInfo();
    getAllApplicants();
  }, []);

  const getUserInfo = async () => {
    try {
      await Apiconnect.postData(`separation/getemployeedetails/${user_id}`).then((res) => {
        const data = Apiconnect.decrypt_obj(res.data.data);
        setRo(data[0].reporting_officer_name);
        setRoId(data[0].reporting_officer);
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  const dt = rdate && moment(rdate).format("DD/MM/YYYY");

  const date = rdate ? new Date(rdate) : null;
  const newDate = addDays(date, 30);
  const releaseDt = rdate && moment(newDate).format("DD/MM/YYYY");

  const handleSubmit = async () => {
    try {
      const dataIns = {
        er_companyId: Apiconnect.get_company_id(),
        er_emp_id: employee_id,
        er_reporting_officer: roId,
        er_resign_dt: dt,
        er_last_working_day: releaseDt,
        er_reason: reason,
      };
      ro &&
        dt &&
        (await Apiconnect.postData(`separation/empResignation`, dataIns)
          .then((res) => {
            toast.success("Success");
            setRdate("");
          })
          .catch((err) => {
            toast.error("Already Initiated");
          }));
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  const getAllApplicants = () => {
    Apiconnect.postData(`separation/getallemplistbyId/${employee_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      setList(extract);
    });
  };

  const theader = (
    <tr className="text-center">
      <th>#</th>
      <th>Applicant Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Resigned On</th>
      <th>Last Working Day</th>
      <th>Status</th>
      <th>Manage</th>
    </tr>
  );

  const handleManage = (st, id) => {
    try {
      Apiconnect.postData(`separation/manageSeparationStatus/${id}/${st}`).then((res) => {
        toast.success("Success");
        getAllApplicants();
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  console.log(list);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <Container heading="Initiate Resignation Letter">
      <Card>
        <div className="col-md-12 border">
          <div className="row">
            <div className="col-md-4 m-2">
              <label className="text-muted">Select Resignation Date</label>
              <input type="date" className="form-control" name="rdate" min={disablePastDate()} value={rdate} onChange={(e) => setRdate(e.target.value)} />
            </div>
            {rdate && (
              <div className="col-md-12 m-2">
                <label className="text-muted">Reason</label>
                <textarea name="reason" value={reason} className="form-control" onChange={(e) => setReason(e.target.value)} />
              </div>
            )}
            {rdate && (
              <div className="col-md-12 m-2">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    setRdate("");
                    setReason("");
                  }}
                >
                  Clear
                </button>
              </div>
            )}
          </div>
        </div>
      </Card>
      {rdate && (
        <Card>
          <div className="col-md-12 border">
            <span>
              Dear <span className="font-weight-bold">{ro} (Reporting Manager)</span>,
            </span>{" "}
            <br />
            <br />
            <span>
              I wish to resign on <span className="font-weight-bold">{dt}</span> & my last working date will be <span className="font-weight-bold">{releaseDt} </span>
              because of {reason}.
            </span>
          </div>

          <div className="col-md-12 mt-3 text-center">
            <button className="btn btn-sm btn-info" onClick={handleSubmit}>
              Proceed
            </button>
          </div>
        </Card>
      )}
      {list.length > 0 && (
        <Card>
          <Table className="col-md-12" trHeader={theader}>
            {list.map((item, index) => {
              let st = <span className="badge badge-pill badge-warning">Pending</span>;
              if (item.er_status == 1) {
                st = <span className="badge badge-pill badge-success">Accepted</span>;
              }
              if (item.er_status == 2) {
                st = <span className="badge badge-pill badge-danger">Rejected</span>;
              }
              if (item.er_status == 3) {
                st = <span className="badge badge-pill badge-info">Under Consideration</span>;
              }
              return (
                <tr key={index} className={`text-center`}>
                  <td>{index + 1}</td>
                  <td>{item.er_applicant_info.first_name + " " + item.er_applicant_info.middle_name + " " + item.er_applicant_info.last_name}</td>
                  <td>{item.er_applicant_info.emp_code}</td>
                  <td>{item.er_applicant_info.email}</td>
                  <td>{item.er_resign_dt}</td>
                  <td>{item.er_last_working_day}</td>
                  <td>{st}</td>
                  <td>
                    <div className="display-flex justify-content-between">
                      <button className={`btn btn-sm btn-primary m-2 ${item.er_status == 1 && "disabled"}`} onClick={() => handleManage(1, item.er_pk_id)}>
                        Accept
                      </button>

                      <button className={`btn btn-sm btn-danger m-2 ${item.er_status == 2 && "disabled"}`} onClick={() => handleManage(2, item.er_pk_id)}>
                        Reject
                      </button>
                      <button className={`btn btn-sm btn-info m-2 ${item.er_status == 3 && "disabled"}`} onClick={() => handleManage(3, item.er_pk_id)}>
                        Under Consideration
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </Table>
        </Card>
      )}
    </Container>
  );
};

export default Separation;
