
 import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import Modal from 'react-modal';

const customStyles = {
    content: {
       top: '50%',
       left: '50%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "70%" ,
       height: '80%'
    },
  };

const Applicants = () => {

    let {id} = useParams()
    const alert = useAlert(); 
    const company_id = Apiconnect.get_company_id()
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [application_list, setApplication_list] = useState([])
    const [designation, setDesignation] = useState("")
    const [view_id, setView_id] = useState("")
    const [info, setInfo] = useState([])
    const [edu, setEdu] = useState([])
    const [emp, setEmp] = useState([])
    const [fam, setFam] = useState([])
    const [ref, setRef] = useState([])
    const [pub, setPub] = useState([])
    const [tad, setTad] = useState([])


    const [basic_dropDown, setBasic_dropDown] = useState(false)
    const [ed_dropDown, setEd_dropDown] = useState(false)
    const [pub_dropDown, setPub_dropDown] = useState(false)
    const [td_dropDown, setTd_dropDown] = useState(false)
    const [emp_dropDown, setEmp_dropDown] = useState(false)
    const [fam_dropDown, setFam_dropDown] = useState(false)
    const [ref_dropDown, setRef_dropDown] = useState(false)


    useEffect(() => {appliation_lists(); getDesignation()}, [])

    const appliation_lists = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            let data_arr = Apiconnect.decrypt_obj(res.data.data)
            setApplication_list(data_arr)
        })
    }


    const getDesignation = () => {    
        Apiconnect.getData(`designation/get/${id}/${Apiconnect.get_company_id()}`).then((response) => {        
           let _xtract = response.data.data;      
           setDesignation(_xtract[0].name);      
        });
      };


    const list = application_list.length > 0 && application_list.filter(item => item.am_position_id === id)
    console.log(list)
    console.log(application_list)

    const handleView = (idm) => {
        setView_id(idm)
        setModalIsOpen(true)
        let details = list.find((item) => item.am_pk_id === idm)
        setInfo(details)
        setEdu(details.educations)
        setEmp(details.employment)
        setFam(details.family_particulars)
        setRef(details.porfessional_references)
        setPub(details.publications)
        setTad(details.training_and_development)
        setBasic_dropDown(true);
        setEd_dropDown(false); 
        setPub_dropDown(false); 
        setTd_dropDown(false); 
        setEmp_dropDown(false); 
        setFam_dropDown(false); 
        setRef_dropDown(false);   
    }

    /* ----MODAL---- */

    const handleClose = () => {
        setInfo("")
        setEdu("")
        setEmp("")
        setFam("")
        setRef("")
        setPub("")
        setTad("")
        setView_id("")
        setModalIsOpen(false)
      }

      const handleNext_1 = () => {
          setBasic_dropDown(false);
          setEd_dropDown(true); 
          setPub_dropDown(false); 
          setTd_dropDown(false); 
          setEmp_dropDown(false); 
          setFam_dropDown(false); 
          setRef_dropDown(false);  
      }
      
      const handleNext_2 = () => {
        setBasic_dropDown(false); 
        setEd_dropDown(false); 
        setPub_dropDown(true); 
        setTd_dropDown(false); 
        setEmp_dropDown(false); 
        setFam_dropDown(false); 
        setRef_dropDown(false); 
      }
      const handleNext_3 = () => {
        setBasic_dropDown(false); 
        setEd_dropDown(false); 
        setPub_dropDown(false); 
        setTd_dropDown(true); 
        setEmp_dropDown(false); 
        setFam_dropDown(false); 
        setRef_dropDown(false); 
      }
      const handleNext_4 = () => {
        setBasic_dropDown(false); 
        setEd_dropDown(false); 
        setPub_dropDown(false); 
        setTd_dropDown(false); 
        setEmp_dropDown(true); 
        setFam_dropDown(false); 
        setRef_dropDown(false); 
      }
      const handleNext_5 = () => {
        setBasic_dropDown(false); 
        setEd_dropDown(false); 
        setPub_dropDown(false); 
        setTd_dropDown(false); 
        setEmp_dropDown(false); 
        setFam_dropDown(true); 
        setRef_dropDown(false); 
      }
      const handleNext_6 = () => {
        setBasic_dropDown(false); 
        setEd_dropDown(false); 
        setPub_dropDown(false); 
        setTd_dropDown(false); 
        setEmp_dropDown(false); 
        setFam_dropDown(false); 
        setRef_dropDown(true); 
      }
      const handleNext_7 = () => {
        setBasic_dropDown(false); 
        setEd_dropDown(false); 
        setPub_dropDown(false); 
        setTd_dropDown(false); 
        setEmp_dropDown(false); 
        setFam_dropDown(false); 
        setRef_dropDown(false); 
      }


      /* ----select applicants---- */
      const [selected, setSelected] = useState([]);
      const onInputSelect = (e) => {
        if (e.target.checked === true) {
          setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
          });
        } else {
          setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
      };


      const handleShortlist = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_shortlist/${id}`).then(res => {
            appliation_lists()
            alert.success("Shortlisted Successfully")
            setFullScreenLoader(false)
        })
      }
      const handleReject = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_reject/${id}`).then(res => {
            appliation_lists()
            alert.success("Rejected Successfully")
            setFullScreenLoader(false)
        })
      }
      const handlePending = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_pending/${id}`).then(res => {
            appliation_lists()
            alert.success("Assigned to Not Evaluated")
            setFullScreenLoader(false)
        })
      }
      const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_delete/${id}`).then(res => {
            appliation_lists()
            alert.success("Deleted Successfully")
            setFullScreenLoader(false)
        })
      }


  return (
    <>
        <div className='content-page'
        style={{
            background: "#ffffff",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
        }}
        >
            {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <Modal isOpen={modalIsOpen} style={customStyles} >
                <div className="iq-card" >
                    <div className="iq-card-header d-flex justify-content-between" style={{background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)", borderRadius: 10}}>
                        <div className="iq-header-title">
                            <h4 className="card-title white">NAME OF THE APPLICANT</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="row">
                            <div className='col-md-12'>
                            {/* <div className='row'>
                                <div className='col-md-12 m-2 p-2'>
                                <div className="bs-example">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(true); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}><Link className={`nav-link ${basic_dropDown ? "active": null}`} data-toggle="tab" >Basic Details</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(true); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}><Link className={`nav-link ${ed_dropDown ? "active": null}`} data-toggle="tab" >Education</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(true); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}><Link className={`nav-link ${pub_dropDown ? "active": null}`} data-toggle="tab" >Publication</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(true); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}><Link className={`nav-link ${td_dropDown ? "active": null}`} data-toggle="tab" >Training & Development</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(true); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}><Link className={`nav-link ${emp_dropDown ? "active": null}`} data-toggle="tab" >Employment History</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(true); setRef_dropDown(false); setRes_dropDown(false)}}><Link className={`nav-link ${fam_dropDown ? "active": null}`} data-toggle="tab" >Family Particulars</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(true); setRes_dropDown(false)}}><Link className={`nav-link ${ref_dropDown ? "active": null}`} data-toggle="tab" >Professional References</Link></li>
                                        <li className="nav-item" onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(true)}}><Link className={`nav-link ${res_dropDown ? "active": null}`} data-toggle="tab" >Upload Resume</Link></li>
                                    </ul>
                                </div>
                                </div>
                            </div> */}
                            {basic_dropDown && <div className='row m-2 p-2' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                    <div className='col-md-4 mb-3'>
                                        <label>First Name</label>
                                        <input type='text' className='form-control' name='am_first_name' value={info.am_first_name}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Middle Name</label>
                                        <input type='text' className='form-control' name='am_middle_name' value={info.am_middle_name} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Last Name</label>
                                        <input type='text' className='form-control' name='am_last_name' value={info.am_last_name}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Email id</label>
                                        <input type='email' className='form-control' name='am_email' value={info.am_email}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Alternate Email Id</label>
                                        <input type='email' className='form-control' name='am_email_alternative' value={info.am_email_alternative}/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Date of Birth</label>
                                        <input type='date' className='form-control' name='am_dob' value={info.am_dob}  />

                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Position</label>
                                        <input type='text' className='form-control' name='position' value={info.am_position}   /> 
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Location</label>
                                        <input type='text' className='form-control' name='companyaddress' value={info.am_location}  /> 
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Organisation</label>
                                        <input type='text' className='form-control' name='companyname' value={info.am_org}/> 
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <label>Correspondence Address</label>
                                        <textarea type='text' className='form-control' name='am_address_correspondence' value={info.am_address_correspondence}  />
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <label>Permanent Address</label>
                                        <textarea type='text' className='form-control' name='am_address_permanent' value={info.am_address_permanent}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Phone (Res)</label>
                                        <input type='text' pattern='/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im' className='form-control' name='am_phone_res' value={info.am_phone_res}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Phone (Cell)</label>
                                        <input type='text' pattern='/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im' className='form-control' name='am_phone_cell' value={info.am_phone_cell}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Marital Status</label>
                                        <select className='form-control' name='am_marital_status' value={info.am_marital_status} >
                                            <option value="" disabled>---select---</option>
                                            <option value="Married">Married</option>
                                            <option value="Unmarried">Unmarried</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Languages Known: Read</label>
                                        <input type='text' className='form-control' name='am_lk_read' value={info.am_lk_read}  />

                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Languages Known: Write</label>
                                        <input type='text' className='form-control' name='am_lk_write' value={info.am_lk_write}  />

                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Languages Known: Speak</label>
                                        <input type='text' className='form-control' name='am_lk_speak' value={info.am_lk_speak}  />
                                       
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Caste</label>
                                        <select className='form-control' name='am_caste' value={info.am_caste} >
                                            <option value="" disabled>---select---</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                            <option value="OBC">OBC</option>
                                            <option value="GEN">GEN</option>
                                        </select>
                           
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you been involved in any court proceedings</label>
                                        <select className='form-control' name='am_court_proceedings' value={info.am_court_proceedings}  >
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                     
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you suffer from any contagious disease</label>
                                        <select className='form-control' name='am_contagious_disease' value={info.am_contagious_disease} >
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>List of Professional, Social, Religious or cultural organization of which you are a member</label>
                                        <input type='text' className='form-control' name='am_org_member_list' value={info.am_org_member_list}  />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you have ever (directly or indirectly) been connected with any political organization</label>
                                        <select className='form-control' name='am_political_org_connection' value={info.am_political_org_connection}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                   
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you ever been employed with any of the company/institute affiliated(directly/indirectly)with this organisation</label>
                                        <select className='form-control' name='am_employed_here_before' value={info.am_employed_here_before}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                     
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If any of your friend/relative is employed with this organisation</label>
                                        <select className='form-control' name='am_relatives_employed_here' value={info.am_relatives_employed_here} >
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                     
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Fixed</label>
                                        <input type='number' className='form-control' name='am_current_ctc_fixed' value={info.am_current_ctc_fixed}  />
                                    
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Reimbursements</label>
                                        <input type='number' className='form-control' name='am_current_ctc_reimbursements' value={info.am_current_ctc_reimbursements}  />
                                  
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Incentives</label>
                                        <input type='number' className='form-control' name='am_current_ctc_incentives' value={info.am_current_ctc_incentives}  />
                                   
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Others</label>
                                        <input type='number' className='form-control' name='am_current_ctc_others' value={info.am_current_ctc_others}  />
                                     
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Total emoluments expected</label>
                                        <input type='number' className='form-control' name='am_expected_emoluments' value={info.am_expected_emoluments}  />
                                      
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Notice Period with the current organization</label>
                                        <input type='text' className='form-control' name='am_notice_period' value={info.am_notice_period}  />
                                     
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <label>Any other details to share (Industry, Research exposure you may like to share additionally)</label>
                                        <textarea type='text' className='form-control' name='am_other_details' value={info.am_other_details} />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <label>How do you think our institute is going to benefit by appointing you</label>
                                        <textarea type='text' className='form-control' name='am_remark_if_appointed' value={info.am_remark_if_appointed} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Games & Sports</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_games_sports' value={info.am_cocurriculum_games_sports} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Art & Craft</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_art_craft' value={info.am_cocurriculum_art_craft} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Music & Dance</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_music_dance' value={info.am_cocurriculum_music_dance} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Environment Science/Astronomy</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_env_sc_astro' value={info.am_cocurriculum_env_sc_astro} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Computer & Technology</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_computer_tech' value={info.am_cocurriculum_computer_tech} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Debates & Quiz</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_debates_quiz' value={info.am_cocurriculum_debates_quiz} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Co-curriculum Activities - Any other</label>
                                        <input type='text' className='form-control' name='am_cocurriculum_others' value={info.am_cocurriculum_others} />
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-md-3 center d-flex justify-content-around'>
                                        {/* <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}>Prev</button> */}
                                        <button className='btn btn-sm btn-dark mr-2' onClick={handleNext_1}>Next</button>
                                        <button className="btn btn-sm btn-danger mr-2" onClick={handleClose}>close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                }
                                {/* ----Education--- */}
                                { ed_dropDown &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {
                                        edu.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Qualification</label>
                                                        <input type='text' className='form-control' name='ame_qualification' value={item.ame_qualification}/>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Name & Address Of the Institute</label>
                                                        <input type='text' className='form-control' name='ame_name_address' value={item.ame_name_address}/>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>University/Board</label>
                                                        <input type='text' className='form-control' name='ame_university_board' value={item.ame_university_board}/>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>From</label>
                                                        <input type='date' className='form-control' name='ame_year_start' value={item.ame_year_start}/>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>To</label>
                                                        <input type='date' className='form-control' name='ame_year_end' value={item.ame_year_end} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Subject</label>
                                                        <input type='text' className='form-control' name='ame_subject' value={item.ame_subject} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>% Marks</label>
                                                        <input type='number' className='form-control' name='ame_mark_percentage' value={item.ame_mark_percentage} />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3' style={{border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                                            <h5 className='text-capitalize' style={{color: "white"}}>Educational History {index === 0 ? null : index + 1}</h5><br />
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row d-flex justify-content-center'>
                                            <div className='col-md-3 center d-flex justify-content-around'>
                                            <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(true); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false)}}>Prev</button>
                                            <button className="btn btn-sm btn-danger" onClick={handleClose}>close</button>
                                            <button className='btn btn-sm btn-dark' onClick={handleNext_2}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {/* ---publications--- */}
                                { pub_dropDown &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {
                                        pub.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Thesis, papers, articles, cooks written</label>
                                                        <input type='text' className='form-control' name='amp_written' value={item.amp_written} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Published by</label>
                                                        <input type='text' className='form-control' name='amp_published_by' value={item.amp_published_by} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Date Published</label>
                                                        <input type='date' className='form-control' name='amp_date_published' value={item.amp_date_published} />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3' style={{border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                                            <h5 className='text-capitalize' style={{color: "white"}}>Details  Of Thesis, Papers, Articles, Books Published {index === 0 ? null : index + 1}</h5><br />
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row d-flex justify-content-center'>
                                            <div className='col-md-3 center d-flex justify-content-around'>
                                            <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(true); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false)}}>Prev</button>
                                            <button className="btn btn-sm btn-danger" onClick={handleClose}>close</button>
                                            <button className='btn btn-sm btn-dark' onClick={handleNext_3}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {/* ---Training & Development--- */}
                                { td_dropDown &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {
                                        tad.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Topic</label>
                                                        <input type='text' className='form-control' name='amtad_topic' value={item.amtad_topic}  />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Date</label>
                                                        <input type='date' className='form-control' name='amtad_date' value={item.amtad_date}  />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Organized by</label>
                                                        <input type='text' className='form-control' name='amtad_organised_by' value={item.amtad_organised_by} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Remarks</label>
                                                        <input type='text' className='form-control' name='amtad_remarks' value={item.amtad_remarks} />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3' style={{border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                                            <h5 className='text-capitalize' style={{color: "white"}}>Training & Development {index === 0 ? null : index + 1}</h5><br />
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row d-flex justify-content-center'>
                                            <div className='col-md-3 center d-flex justify-content-around'>
                                            <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(true); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false)}}>Prev</button>
                                            <button className="btn btn-sm btn-danger" onClick={handleClose}>close</button>
                                            <button className='btn btn-sm btn-dark' onClick={handleNext_4}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {/* ---Employment History--- */}
                                {emp_dropDown &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {
                                        emp.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Name of the Company/School/College</label>
                                                        <input type='text' className='form-control' name='ame_name' value={item.ame_name} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Period of Service From</label>
                                                        <input type='date' className='form-control' name='ame_from' value={item.ame_from}  />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Period of Service To</label>
                                                        <input type='date' className='form-control' name='ame_to' value={item.ame_to}  />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Designation</label>
                                                        <input type='text' className='form-control' name='ame_designation' value={item.ame_designation}  />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Scope of Assignment/ Subject /Stream</label>
                                                        <input type='text' className='form-control' name='ame_scope_subject' value={item.ame_scope_subject} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>CTC Drawn</label>
                                                        <input type='number' className='form-control' name='ame_ctc_drawn' value={item.ame_ctc_drawn} />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3' style={{border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                                            <h5 className='text-capitalize' style={{color: "white"}}>Employment History {index === 0 ? null : index + 1}</h5><br />
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-md-3 center d-flex justify-content-around'>
                                        <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(true); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false)}}>Prev</button>
                                        <button className="btn btn-sm btn-danger" onClick={handleClose}>close</button>
                                        <button className='btn btn-sm btn-dark' onClick={handleNext_5}>Next</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                }
                                {/* ---Family Particulars--- */}
                                {fam_dropDown && 
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {
                                        fam.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Relation</label>
                                                        <input type='text' className='form-control' name='amfp_relation' value={item.amfp_relation} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Name</label>
                                                        <input type='text' className='form-control' name='amfp_name' value={item.amfp_name} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Age</label>
                                                        <input type='number' className='form-control' name='amfp_age' value={item.amfp_age} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Staying with you</label>
                                                        <select className='form-control' name='amfp_staying' value={item.amfp_staying} >
                                                        <option value="" disabled selected>--Select--</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Current Status</label>
                                                        <select className='form-control' name='amfp_employment_status' value={item.amfp_employment_status} >
                                                        <option value="" disabled selected>--Select--</option>
                                                        <option value="Student">Student</option>
                                                        <option value="Employed">Employed</option>
                                                        <option value="Retired">Retired</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Dependent on you</label>
                                                        <select className='form-control' name='amfp_dependant_on_you' value={item.amfp_dependant_on_you} >
                                                        <option value="" disabled selected>--Select--</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3' style={{border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                                            <h5 className='text-capitalize' style={{color: "white"}}>Family Particulars {index === 0 ? null : index + 1}</h5><br />
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-md-3 center d-flex justify-content-around'>
                                        <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(true); setFam_dropDown(false); setRef_dropDown(false)}}>Prev</button>
                                        <button className="btn btn-sm btn-danger" onClick={handleClose}>close</button>
                                        <button className='btn btn-sm btn-dark' onClick={handleNext_6}>Next</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                }
                                {/* ---Professional References--- */}
                                {ref_dropDown &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                    {ref.length > 0 && ref.map((item, index) => {
                                        return(
                                            <div key={index} className='row mb-3 m-3' style={{border: "3px solid #3F2A8F", borderRadius: 10}}>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Name</label>
                                                        <input type='text' className='form-control' name='ampr_name' value={item.ampr_name} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>address</label>
                                                        <input type='text' className='form-control' name='ampr_address' value={item.ampr_address} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>Occupation</label>
                                                        <input type='text' className='form-control' name='ampr_occupation' value={item.ampr_occupation} />
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <label>May be contacted</label>
                                                        <select className='form-control' name='ampr_may_be_contacted' value={item.ampr_may_be_contacted}>
                                                        <option value="" disabled selected>--Select--</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3' style={{border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
                                                    <div className='row mb-2'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                                            <h5 className='text-capitalize' style={{color: "white"}}>Professional References {index === 0 ? null : index + 1}</h5><br />
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row d-flex justify-content-center'>
                                        <div className='col-md-3 center d-flex justify-content-around'>
                                            <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(true); setRef_dropDown(false)}}>Prev</button>
                                            {/* <button className='btn btn-sm btn-dark' onClick={handleNext_7}>Next</button> */}
                                            <button className="btn btn-sm btn-danger" onClick={handleClose}>close</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </Modal>
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card"
                        style={{borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}
                        >
                            <div className="iq-card-header d-flex justify-content-between model_head "
                            style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title text-white">Applicant List: {designation}</h4>
                                </div>
                                <Link to={`/Hiring_dashboard`}><button className='btn btn-sm btn-info'>Back</button></Link>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='iq-card'
                                        style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}
                                        >
                                            <table  className="table table-bordered mt-1 center" role="grid" >
                                                <thead className='thead-light'>
                                                    <tr >
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col">Hiring Process</th>
                                                        {/* <th scope="col">Assigned Panels</th> */}
                                                        <th scope="col">Match Percentage</th>
                                                        <th scope="col">Applicant Details</th>
                                                        {/* <th scope="col">If Shortlisted</th> */}
                                                        <th scope="col">status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list.length > 0 ?
                                                        (
                                                            list.map((val, key) => {

                                                                let status = null

                                                                if(val.am_shortlisted===0){
                                                                    status = <span className='badge badge-pill badge-warning'>Pending</span>
                                                                }else if(val.am_shortlisted===1){
                                                                    status = <span className='badge badge-pill badge-success'>Shortlisted</span>
                                                                }else{
                                                                    status = <span className='badge badge-pill badge-danger'>Rejected</span>
                                                                }

                                                                // let panel_arr = val.panels
                                                                // const unique = [...new Map(panel_arr.map((m) => [m.id, m])).values()];
                                                                // let panel_arr_list = unique.map((item, index) => {
                                                                //    return(
                                                                //     <>
                                                                //         <span className='badge badge-pill badge-sm badge-light mb-1' value={item.id}>{item.name}</span> <br/>
                                                                //     </> 
                                                                //    )
                                                                // })

                                                                return(
                                                                    <tr className='thead-light' key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{val.am_first_name+" "+val.am_middle_name+" "+val.am_last_name}</td>
                                                                        <td>{val.am_email}</td>
                                                                        <td>{val.am_phone_cell}</td>
                                                                        <td>{val.hp_name}</td>
                                                                        {/* <td>{panel_arr_list}</td> */}
                                                                        <td>NA</td>
                                                                        <td>
                                                                            <button className='btn btn-sm btn-info mr-2 mb-2' onClick={() => handleView(val.am_pk_id)}>View</button>
                                                                            <a href={val.am_resume} target="_blank" rel="noopener noreferrer" ><button className='btn btn-sm btn-success mr-2 mb-2'><i className="fa fa-file-pdf-o" aria-hidden="true"></i>Resume</button></a>
                                                                        </td>
                                                                        {/* <td>
                                                                            {
                                                                                val.am_shortlisted === 1 ?
                                                                                <Link to={`/Initiate_hiring_process/${id}/${val.am_pk_id}`}><button className='btn btn-sm btn-info'>Initiate Hiring Process</button></Link>
                                                                                :
                                                                                null
                                                                            }
                                                                        </td> */}
                                                                        <td>
                                                                            <div class="btn-group">
                                                                                <button type="button" className="btn btn-white dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {status}
                                                                                </button>
                                                                                <div className="dropdown-menu center">
                                                                                    <button className='btn btn-sm btn-success mr-2 mb-2' onClick={() => handleShortlist(val.am_pk_id)}>Shortlist</button> <br />
                                                                                    <button className='btn btn-sm btn-danger mr-2 mb-2' onClick={() => handleReject(val.am_pk_id)}>Reject</button>
                                                                                    <button className='btn btn-sm btn-warning mr-2 mb-2' onClick={() => handlePending(val.am_pk_id)}>Not Evaluated</button>
                                                                                    {/* <button className='btn btn-sm btn-danger mr-2 mb-2' onClick={() => handleDelete(val.am_pk_id)}>Delete</button> */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        )
                                                        :
                                                        (
                                                            <tr>
                                                                <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Applicants