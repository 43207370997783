import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FullScreenLoading from 'react-fullscreen-loading';
import moment from 'moment';

function Team_member_short_leaves() {

    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);
 
    useEffect(() => { 
        leavedata(); 
        empdata(); 
    }, []);
    const [leaveList, setleaveList] = useState([]);
    const [empList, setEmpList] = useState([]);
    const[seluser_id,setUserId]=useState('');
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    var date = new Date();
    const [from_date, setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [to_date, setToDate] = useState(new Date());

    const leavedata =async () => {
        var data_pst={'from_date':moment(new Date(from_date)).format("YYYY-MM-DD"),'to_date':moment(new Date(to_date)).format("YYYY-MM-DD")};
        setFullScreenLoader(true);
        await Apiconnect.postData(`leave_short/shortleavereportingdata/${emp}`,data_pst).then((response) => {
            setleaveList(Apiconnect.decrypt_obj(response.data.data)); 
            setFullScreenLoader(false);
        });
    } 
    const empdata =async () => {
        setFullScreenLoader(true);
        await Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
            setEmpList(Apiconnect.decrypt_obj(response.data.data));
            setFullScreenLoader(false);
        });
    }

    const onSubmitFilter = (evnt) => {  
        leavedata(from_date,to_date,seluser_id);
    }

    return (
        <>
            {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />) : null }
            <div id="content-page" className="content-page">
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Team Member Short Leaves</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">

                                        <div className="col-md-3"> 
                                            <label>From</label>   
                                            <DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="From Date"
                                                name="from_date"
                                                required=""
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>TO</label>   
                                            <DatePicker
                                                selected={to_date}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="To Date"
                                                name="to_date"
                                                required=""
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3"> 
                                            <label>User</label>   
                                            <select className="form-control" name="emp_id"  onChange={e => setUserId(e.target.value)}>
                                                <option value="">Select User</option>
                                                {empList.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-success" value="Filter" style={{marginTop:"23px"}} onClick={(e) => onSubmitFilter(e)}/>
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="table-responsive">

                                            <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                                <thead>
                                                    <tr className="clr" >
                                                        <th>#</th>
                                                        <th>Emp. Name</th>
                                                        <th>Applied On</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Reason</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {   
                                                        (leaveList.length>0)?(
                                                            leaveList.map((val, key) => {
                                                                return (
                                                                    <tr className="" key={key}>
                                                                        <th>{key + 1}</th>
                                                                        <td>{val.first_name} {val.last_name}</td>
                                                                        <td>{val.date}</td>
                                                                        <td>{val.start_time}</td>
                                                                        <td>{val.end_time}</td>
                                                                        <td>{val.reason}</td>
                                                                        <td><div className=" badge badge-pill badge-success">Approved</div></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ):(
                                                            <tr>
                                                                <th colSpan={7} style={{textAlign:"center"}}>No Data Found..</th>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default Team_member_short_leaves
