import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import "react-datepicker/dist/react-datepicker.css";
import FullScreenLoading from 'react-fullscreen-loading';
import { confirmAlert } from "react-confirm-alert";
import { CSVLink } from "react-csv";
import jsPDF from 'jspdf';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import moment from "moment";

var converter = require('number-to-words');

function Salary_disbursement() {
    const alert = useAlert();
    const locusr = JSON.parse(localStorage.getItem("user"));
    let company_name = locusr.company_name;

   const [salaryMonths, setSallaryMonths] = useState([]);
   const [salaryData, setSallaryData] = useState([]);
   const [fullScreenLoader, setFullScreenLoader] = useState(false);
   const [allchecked, setallchecked] = useState(null);
   const [selectedEmp, setSelectedEmp] = useState([]);
   const [selectedMonth, setSelectedMonth] = useState(null);
   const [disbursementStatus, setDisbursementStatus] = useState([]);
   const [companyaddress, setCompanyData] = useState([])
   const [salaryDataCvs, setSallaryDataCvs] = useState([]);
   const [cal_grand_total, setcal_grand_total] = useState(0);

   useEffect(() => {getMonthData();getDisbursementData();getCompany()}, []);

   const getCompany = () => {         
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
          let ern = Apiconnect.decrypt_obj(response.data.data);
          setCompanyData(ern[0].address_one)
         
      });
  };

  const local_user = JSON.parse(localStorage.getItem('user'));

   const getMonthData = () => {
    Apiconnect.postData(`employee_monthly_salary/getSalarySlipAllMonths/${Apiconnect.get_company_id()}`).then((response) => {
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       var monthsList=_xtract;
       setSallaryMonths(monthsList);
    });
 };

 const getDisbursementData = () => {
  Apiconnect.getData(`employee_salary/getSalaryDisbursmentStatus`).then((response) => {
     let _xtract = Apiconnect.decrypt_obj(response.data.data);
     setDisbursementStatus(_xtract);
  });
};


 const months = {'01' :'JAN','02' :'FEB','03' :'MAR','04' :'APR','05' :'MAY',
 '06' :'JUN','07' :'JUL','08' :'AUG','09' :'SEP','10' :'OCT','11' :'NOV','12' :'DEC'};


 const onChangeMonth = (e) => {

    var year = e.target.value;
    if(year != ''){
        setSelectedMonth(year)
        getSalaryData(year)
        getSalaryDataCvs(year)
    }else{
        setSelectedMonth('')
        setSallaryData([]);
        setSallaryDataCvs([]);
    }
   
   
 }

 const getSalaryData = (yyyymm,type=null) => {
    setFullScreenLoader(true)
    Apiconnect.getData(`employee_salary/getSalaryEmployeeMonthsWise/${Apiconnect.get_company_id()}/${yyyymm}/${type}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        var monthsList=_xtract;
        setSallaryData(monthsList);
        setFullScreenLoader(false)

        // console.log('monthsListPdf',monthsList)
     });
 }

 const getSalaryDataCvs = (yyyymm,type=null) => {
  Apiconnect.getData(`employee_salary/getSalaryEmployeeMonthsWiseCvs/${Apiconnect.get_company_id()}/${yyyymm}/${type}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      var monthsList=_xtract;
      setSallaryDataCvs(monthsList);
      // console.log('monthsListCvs',monthsList)
   });
}


 const sellectAll = (e) => {
    if (e.target.checked === true) {
      // console.log(e.target.checked);
      setallchecked("checked");
      let ob = {};
      salaryData.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
      // setSelectedCountEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
      // setSelectedCountEmp([]);
    }
  };

  const onInputChange2 = (e) => {
    setallchecked(null);

    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });
    }
  };


  const isEmployeeDisbursement = (status) => {
    if (selectedEmp != "") {
      disbursement(status)
    } else {
      confirmAlert({
        title: "Select Employee",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    }
  };

  const disbursement = (status) => {
    const inf_a = {yyyymm:selectedMonth, company_id : Apiconnect.get_company_id(), status: status, employee: selectedEmp };
    Apiconnect.postData("employee_salary/salary_disbursment", inf_a).then(
     (response) => {
       getSalaryData(selectedMonth);
       setSelectedEmp([]);
       alert.success("Data Update Successfully");
     }
   );
  }

  const seleteFilter = (e)=> {
    var value = e.target.value;

    if(value != ''){
        getSalaryData(selectedMonth,value)
    }else{
        getSalaryData(selectedMonth)
    }
    
  }


  const downloadBankLetterPdf = () => {

    var year= selectedMonth.slice(0, 4);
    var month= selectedMonth.slice(4, 6);

    let col = ['Sr.no']
    var head = Object.keys(salaryData[0])
    const valuesToRemove = ['id','status']
    const filteredItems = head.filter(item => !valuesToRemove.includes(item))

    // let colNew = ['Sr.no','Name','SB A/C No','Amount'];
    let colNew =["SrNo",'Name','Employee ID', "Bank Name", "Bank Account No",'IFSC Code','Branch Name', "Amount"];

    var doc = new jsPDF('p', 'pt','a3');
    col.push(...filteredItems);
    var rows = [];

    var cnt = 1;
    salaryData.map((key, key1) => {    
      var sr = cnt++;  
      var arr = [sr];
      filteredItems.map(val => (
          arr.push(key[val])
       ))  
       rows.push(arr);
    }) 



    let total = 0;
    salaryData.forEach(element => {      
      total = total+element.DisburseAmount;
    }); 
    var arr1 = ['','','Grand Total',total];
    rows.push(arr1);

    rows=[];
    // console.log(rows);

    var cal_grand_total2=0;
    var cnt_row=17;
    var cnt_srno1=1;
    salaryDataCvs.map((val, key) => {
      var arr = [
        cnt_srno1,
        val.StaffName,
        val.EmployeeID,
        val.bank_name,
        String(val.BankAccountNo),
        val.ifsc_code,
        val.branch_name,
        val.DisburseAmount
      ];

      rows.push(arr);

      cal_grand_total2+=val.DisburseAmount;
      cnt_row++;
      cnt_srno1++;
    });
    
    var cal_grand_text1=inWords(cal_grand_total2);

    doc.setFontSize(12);
    doc.text(280,25,company_name.toUpperCase());
    doc.setFontSize(10);
    doc.text(350,40,companyaddress);
  
    doc.setFontSize(10);
    doc.text(20,65,'To');
    doc.setFontSize(10);
    doc.text(20,75,'The Manager,');

    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(20,100,'Ref : Bank Account No. _______________________ of  '+company_name);

    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(20,120,'Sub : Regarding salary');


    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(20,150,'Dear Sir/Mam,');

    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(20,170,'Please find enclosed herewith a cheque no. ________ dated. __________ for');
    
    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(20,190,'Rs. '+cal_grand_total2+'/- ('+cal_grand_text1+')');
    
    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(20,210,'For Amount transfer through RTGS/NEFT Payment Bank Accounts details are given below.');


    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page

    doc.autoTable(colNew, rows, { 
      theme: 'grid',
      // showHead:'firstPage',
      startY: pageCurrent == 1 ? 250 : 50 ,
      margin: {left:5,right:5},
      headStyles:{fillColor:'#dee2e6',valign:'middle'},
      styles: { fontSize: 8,cellWidth:'auto',textColor:20,valign:'middle',halign:'center' },
      });

      let finalY = doc.lastAutoTable.finalY;
      // doc.setFontSize(12);
      // doc.text(20,finalY+20,'Total Amount in Words : - '+converter.toWords(total));

      // doc.setFontSize(12);
      // doc.text(40,finalY+100,'Principal');
      // doc.setFontSize(12);
      // doc.text(700,finalY+100,'Manager');

      doc.setFontSize(12);
      doc.text(20,finalY+25,'Thanking you.');

      doc.setFontSize(12);
      doc.text(20,finalY+50,'For '+company_name);

      doc.setFontSize(12);
      doc.text(20,finalY+110,'(Authorised Signatory)');

      doc.save('BankLetterPdf.pdf');
  }

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  const saveAsExcel = () => {
    // console.log(salaryDataCvs);
    var cal_grand_total2=0;
    var cnt_row=17;
    salaryDataCvs.map((val, key) => {
      cal_grand_total2+=val.DisburseAmount;
      cnt_row++;
    });
    
    var cal_grand_text1=inWords(cal_grand_total2);

    var year= selectedMonth.slice(0, 4);
    var month= selectedMonth.slice(4, 6);

    let header = ["SrNo",'Name','EmployeeID', "BankName", "BankAccountNo",'BranchName','IFSC_CODE', "DisburseAmount"]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(salaryData_new, header);
      const totalColumns = sheetData[0].length;

      let current_date1=moment(new Date()).format("DD-MM-YYYY"); 
      // sheet1.cell("A1").value(local_user.company_name);
      // sheet1.cell("A2").value(companyaddress);
      // sheet1.cell("A3").value('Salary Statement Of The Month: '+months[month]+' '+year);

      sheet1.cell("A2").value('To');
      sheet1.cell("F2").value('Date');
      sheet1.cell("G2").value(current_date1);

      sheet1.cell("A3").value('The Branch Manager');
       

      sheet1.cell("A5").value('Ref : Bank Account No. _______________________ of '+company_name);
      sheet1.cell("A6").value('Sub : Regarding salary');
      sheet1.cell("A8").value('Dear Sir/Mam, ');
      sheet1.cell("A10").value('Please find enclosed herewith a cheque no. ________ dated. __________ for  Rs. '+cal_grand_total2+'/- ('+cal_grand_text1+')');
      sheet1.cell("A11").value('For Amount transfer through RTGS/NEFT Payment Bank Accounts details are given below.');
      
      // console.log(cnt_row);
      sheet1.cell("A"+cnt_row).value('Thanking you.');
      cnt_row=cnt_row+2;
      sheet1.cell("A"+cnt_row).value('For '+company_name);
      cnt_row=cnt_row+2;
      sheet1.cell("A"+cnt_row).value('(Authorised Signatory)');

      sheet1.cell("A14").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      // sheet1.row(1).style("bold", true); 
      sheet1.range("A14:" + endColumn + "14").style("fill", "BFBFBF");  
      
      // sheet1.row(5).style("cellWidth","1000"); 
      // sheet1.column("A5").width(100); 
      // sheet1.range("A5:" + endColumn + "5").style("cellWidth", "1000px");  

      // range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "salary data.xlsx");
      });
    });
  }

  var cal_grand_total1=0;
  var cnt_srno1=1;
  let salaryData_new = salaryDataCvs.map((val,cnt_i1) => (
    {
    
      Id: cnt_i1+1,
      Name: val.StaffName,
      EmployeeID: val.EmployeeID,
      // StaffName: val.StaffName,
      BankName: val.bank_name,
      BankAccountNo: String(val.BankAccountNo), 
      BranchName:val.branch_name,
      IFSC_CODE:val.ifsc_code,
      DisburseAmount: val.DisburseAmount, 
      cal_grand_total1:cal_grand_total1+val.DisburseAmount,
    } 
  ));
   
  var a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
  var b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];

  function inWords (num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
  }
  // salaryDataCvs.map((val,cnt_i1) => (  
  //   cal_grand_total1+=val.DisburseAmount
  // ));
  // console.log(cal_grand_total1);
  // setcal_grand_total(cal_grand_total1);


   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">

            {fullScreenLoader
            ? (
              <FullScreenLoading loading loaderColor="#3498db" />
            )
             
            : null
            }

               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div>
                           <h4 className="card-title hd">Salary disbursement</h4>
                          
                        </div>
                            
                        <div className="iq-card-body">

                      

                           <div className="row p_t_15">
                       
                              <div className="col-md-3">
                                Months : <br />
                                 <select className="form-control" name="month" onChange={(e)=>onChangeMonth(e)}>
                                    <option value="">Select Month</option>
                                    {
                                    salaryMonths.map((value,key)=>{
                                      if(value.status == 1){
                                        return (
                                          <option value={value.year+''+value.month}>{months[value.month]+'-'+value.year}</option>
                                      )
                                      }
                                       
                                    })
                                    }
                                   
                                 </select>
                              </div>

                              {
                                selectedMonth ?
                                (
                                    <>

                                      {
                                        disbursementStatus.map((val, key) => {
                                          return (
                                            <div className="col-md-1" style={{textAlign:'center',marginRight:'20px'}} >
                                              <button
                                              style={{ marginTop: "20px" }}
                                                  onClick={() => {
                                                      isEmployeeDisbursement(val.status);
                                                  }}
                                                  className="btn btn-info mr-2"
                                              >
                                                 {val.name}
                                              </button>
                                          </div>
                                          )
                                        })
                                      }

                                 
                                    <div className="col-md-2">
                                 
                                      
                                    </div>        
                                    <div className="col-md-2">
                                        Filter : <br />
                                        <select className="form-control" name="month" onChange={(e)=>seleteFilter(e)}>
                                        <option value="">All</option>
                                          {
                                            disbursementStatus.map((val, key) => {
                                              return (
                                                <option value={val.status}>{val.name}</option>
                                              )
                                            })
                                          }
                                        </select>
                                    </div>

                                    <div style={{marginTop:'5px'}}>
                                     <CSVLink
                                        filename={"employee-salary_disbursement.csv"}
                                        style={{ marginTop: "20px", float: "right" }}
                                        className="btn btn-warning btn-sm"
                                        data={salaryData_new}
                                        >
                                        <i className="fa fa-download"></i>Employee Data
                                    </CSVLink>
                                    </div>
                                    
                                    <div style={{marginTop:'5px'}}>
                                    <button  className="btn btn-primary btn-sm" onClick={() => downloadBankLetterPdf()}
                                     style={{ marginTop: "20px",marginLeft:'10px', float: "right" }} >
                                             <i className="fa fa-download"></i>
                                             {` `} Bank Letter Pdf
                                          </button>

                                    </div>
                                    <div style={{marginTop:'5px'}}>
                                    <button  className="btn btn-primary btn-sm" onClick={saveAsExcel} style={{ marginTop: "20px",marginLeft:'10px', float: "right" }} >
                                             <i className="fa fa-download"></i>
                                             {` `} Employee Data Excel
                                          </button>
                                    </div>
                                    </>
                                )
                                : null
                              }

                           </div>

                           <div className="row">

                              <div className="table-responsive">

                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                       <th scope="col">
                                            <input
                                                type="checkbox"
                                                name="select_all"
                                                checked={allchecked}
                                                onChange={(e) => sellectAll(e)}
                                            />
                                          </th>
                                          <th>#</th>
                                          <th>Employee ID</th>
                                          <th>Sraff Name</th>
                                          <th>Bank Account No</th>
                                          <th>Disburse Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {salaryData.map((val, key) => {

                                        var ck = Object.keys(selectedEmp).find(function (
                                            element
                                        ) {
                                            return selectedEmp[element] == val.id;
                                        });

                                        var ck1 = "";
                                        if (ck !== undefined) {
                                            ck1 = true;
                                        }

                                          return (
                                            <tr key={key}
                                             style={{color:val.status == 1 ? 'green' : val.status == 2 ? 'red' : ''}}>
                                                 <th scope="row">
                                                    <input
                                                    type="checkbox"
                                                    name={val.id}
                                                    value={val.id}
                                                    checked={ck1}
                                                    onChange={(e) => onInputChange2(e)}
                                                    
                                                    />
                                                </th>
                                               <th scope="row">{key + 1}</th>
                                               <td>{val.EmployeeID}</td>
                                               <td>{val.StaffName}</td>
                                               <td>{val.BankAccountNo}</td>
                                               <td>{val.DisburseAmount}</td>
                                            </tr>
                                         );
                                    })}
                                    </tbody>
                                    
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>


      </>
   )
}

export default Salary_disbursement
