import React from "react";
import Modal from "react-modal";
import Card from "./Card";
import { pageStyles } from "../../globalStyles";

const customStyles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    innerWidth: "75%",

    transform: "translate(-50%, -50%)",
    width: "65%",
    height: "60%"
  },
};

const CustomModal = ({ open, close, header, children }) => {
  return (
    <Modal isOpen={open} style={customStyles} onRequestClose={close}>
      <div className="iq-card">
        <div className="iq-card-header" style={pageStyles.card_head}>
          <h5 className="text-white" style={pageStyles.h4_bg}>{header}</h5>
        </div>
        <Card>{children}</Card>
      </div>
    </Modal>
  );
};

export default CustomModal;
