import React, { useEffect, useState } from "react";
import { pageStyles } from "../../../globalStyles";
import Apiconnect from "../../../services/Apiconnect";
import { useAlert } from "react-alert";
import toast, { Toaster } from "react-hot-toast";
import FullScreenLoading from "react-fullscreen-loading";
import Container from "../../../components/reusable/Container";
import Card from "../../../components/reusable/Card";
import SelectFromOptions from "../../../components/reusable/SelectFromOptions";
import Input from "../../../components/reusable/Input";
import Table from "../../../components/reusable/Table";
import moment from "moment";
import { FcAbout } from "react-icons/fc";
import Modal from "react-modal";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};
const customStyles1 = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const GrievanceList = () => {
  const [list, setList] = useState([]);
  const [logs, setLogs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [msg, setMsg] = useState("");
  const [cat, setCat] = useState("");

  const [type, setType] = useState("");
  const [cdate, setCdate] = useState(new Date());
  const [days, setDays] = useState("");
  const [nmessage, setNmessage] = useState("");
  const [cInchrge, setCIncharge] = useState("");
  const [dIncharge, setDIncharge] = useState("");
  const [grid, setGrid] = useState("");
  const [raisedBy, setRaisedBy] = useState("");
  useEffect(() => {
    getGrievanceList();
    getCompany();
  }, []);

  console.log(list);

  const getGrievanceList = () => {
    Apiconnect.postData(
      `grievance/getGrievanceRaiseList/${Apiconnect.get_company_id()}`
    ).then((res) => {
      setList(res.data.data);
    });
  };

  const header = (
    <tr className="text-center">
      <th>#</th>
      <th>Created On</th>
      <th>Department</th>
      <th>Department Incharge</th>
      <th>Category</th>
      <th>Category Incharge</th>
      <th>Raised By</th>
      <th>Priority</th>
      <th>Status</th>
      <th>View</th>
      <th>Grievance Stage</th>
      <th>Action</th>
    </tr>
  );

  const getLogs = (id) => {
    Apiconnect.postData(`grievance/getGrievanceLogs/${id}`).then((res) => {
      setLogs(res.data.data);
    });
  };
  const local_user = JSON.parse(localStorage.getItem("user"));
  const [companyaddress, setCompanyData] = useState([]);
  const getCompany = () => {
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let ern = Apiconnect.decrypt_obj(response.data.data);
        setCompanyData(ern[0].address_one);
      }
    );
  };

  //download excel

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  const saveAsExcel = () => {
    let header = [
      "grievance_id",
      "company_id",
      "employee_id",
      "department_id",
      "category_id",
      "priority",
      "subject",
      "description",
      "status",
      "category_incharge_id",
      "department_incharge_id",
      "supporting_document",
      "created_on",
      "updated_on",
      "final_status",
      "category_incharge",
      "applied_by",
      "department_incharge",
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(list, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress);
      sheet1.cell("A3").value("All Grievances List");
      sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);

      sheet1.row(1).style("bold", true);
      // sheet1.range("A5:" + endColumn + "5").style("fill", "BFBFBF");
      range.style("border", true);

      sheet1
        .range("A1:R1")
        .style({ horizontalAlignment: "center", verticalAlignment: "center" })
        .merged(true);
      sheet1
        .range("A2:R2")
        .style({ horizontalAlignment: "center", verticalAlignment: "center" })
        .merged(true)
        .style("bold", true);
      sheet1
        .range("A3:R3")
        .style({ horizontalAlignment: "center", verticalAlignment: "center" })
        .merged(true)
        .style("bold", true);
      sheet1
        .range("A5:R5")
        .style({ horizontalAlignment: "center", verticalAlignment: "center" })
        .style("bold", true)
        .style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, "grievanceList.xlsx");
      });
    });
  };

  const handleSchedule = () => {
    const currentDate = new Date().getTime();
    const selectedDate = new Date(cdate).getTime();
    const cronTime = selectedDate - currentDate;

    if (type !== "" && cdate !== "" && nmessage !== "") {
      try {
        if (type == 1) {
          const dataIns = {
            grievanceId: grid,
            to: dIncharge,
            reaisedBy: raisedBy,
            msg: msg,
            cat: cat,
            nmessage: nmessage,
            cronTime: cronTime,
          };
          Apiconnect.postData(`grievance/notifyIncharge`, dataIns)
            .then((res) => {
              toast.success("Notified Successfully");
              setModalIsOpen1(false);
            })
            .catch((err) => {
              toast.error("something went wrong");
            });
        } else if (type == 2) {
          const dataIns = {
            grievanceId: grid,
            to: cInchrge,
            reaisedBy: raisedBy,
            msg: msg,
            cat: cat,
            nmessage: nmessage,
            cronTime: cronTime,
          };
          Apiconnect.postData(`grievance/notifyIncharge`, dataIns)
            .then((res) => {
              toast.success("Notified Successfully");
              setModalIsOpen1(false);
            })
            .catch((err) => {
              toast.error("something Went Wrong");
            });
        }
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    } else {
      toast.error("Some fields are empty");
    }
  };

  console.log(list);

  return (
    <>
      <Container heading="Grievance List">
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div className="iq-card">
            <div className="iq-card-header card_head">
              <h5 className="text-white">Grievance Resolve Logs</h5>
            </div>
            <div className="iq-card-body ">
              <span className="text-muted">
                {cat} / {msg}
              </span>
              <div className="row mt-3">
                <div className="col-md-12 table-responsive text-center">
                  {logs.length > 0 ? (
                    <table
                      className="table table-bordered"
                      style={pageStyles.main_container}
                    >
                      <thead className="thead-light">
                        <tr className="text-center">
                          <th>Resolved On</th>
                          <th>Resolved By</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.map((item, index) => {
                          const dt = moment(item.grl_createdAt).format(
                            "DD/MM/YYYY"
                          );
                          return (
                            <tr className="text-center" key={index}>
                              <td>{dt}</td>
                              <td>{item.name}</td>
                              <td>{item.grl_message}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <span className="text-muted font-weight-bold">
                      No Logs Available
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalIsOpen1}
          style={customStyles1}
          onRequestClose={() => setModalIsOpen1(false)}
        >
          <div className="iq-card">
            <div className="iq-card-header card_head">
              <h5 className="text-white">
                Notify To Department Incharge / Category Incharge
              </h5>
            </div>
            <div className="iq-card-body ">
              <div className="row">
                <div className="col-md-6">
                  <label className="text-muted">Select Incharge Type</label>
                  <select
                    className="form-control"
                    name="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Incharge Type
                    </option>
                    <option value="1">Department Incharge</option>
                    <option value="2">Category Incharge</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="text-muted">Enter Number Of Days</label>
                  <input
                    type="date"
                    name="cdate"
                    value={cdate}
                    className="form-control"
                    onChange={(e) => setCdate(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="text-muted">Enter Message</label>
                  <textarea
                    className="form-control"
                    name="nmessage"
                    value={nmessage}
                    cols="30"
                    rows="5"
                    onChange={(e) => setNmessage(e.target.value)}
                  />
                </div>
                <div className="col-md-12 text-center mt-3">
                  <button
                    className="btn btn-sm btn-info"
                    onClick={handleSchedule}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Card>
          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            <button className="btn btn-sm btn-info m-3" onClick={saveAsExcel}>
              <i className="fa fa-download"></i> Download Excel
            </button>
          </div>

          <Table className="col-md-12" trHeader={header}>
            {list.map((item, index) => {
              let priority = null;
              if (item.gr_priority === 1) {
                priority = <span className="badge badge-danger">High</span>;
              } else if (item.gr_priority === 2) {
                priority = <span className="badge badge-warning">Medium</span>;
              } else if (item.gr_priority === 3) {
                priority = <span className="badge badge-success">Low</span>;
              }
              let status = null;
              if (item.gr_status === 1) {
                status = <span className="badge badge-warning">Pending</span>;
              } else if (item.gr_status === 2) {
                status = <span className="badge badge-success">Resolved</span>;
              }
              const formatted = moment(item.gr_createdOn).format(
                "DD/MM/YYYY h:mm a"
              );
              const updated = moment(item.gr_updatedOn).format(
                "DD/MM/YYYY h:mm a"
              );
              let resolved = (
                <span
                  className="badge badge-success "
                  style={pageStyles.normal_shadow}
                >
                  Resolved on {updated}
                </span>
              );
              return (
                <tr className="text-center" key={index}>
                  <td>{index + 1}</td>
                  <td>{formatted}</td>
                  <td>{item.grievanceDepartment}</td>
                  <td>{item.deptIncharge}</td>
                  <td>{item.grievanceCategoryName}</td>
                  <td>{item.catInchargeName}</td>
                  <td>{item.applied_by}</td>
                  <td>{priority}</td>
                  <td>
                    {item.gr_status === 2 ? (
                      resolved
                    ) : (
                      <span className="badge badge-sm badge-warning">
                        Pending
                      </span>
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-light"
                      style={pageStyles.main_container}
                      onClick={() => {
                        setModalIsOpen(true);
                        getLogs(item.gr_pk_id);
                        setMsg(item.gr_desc);
                        setCat(item.grievanceCategoryName);
                      }}
                    >
                      <FcAbout />
                    </button>
                  </td>
                  <td>
                    <span className="badge badge-pill badge-success">
                      {item.gr_final_st}
                    </span>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-info"
                      onClick={() => {
                        setModalIsOpen1(true);
                        setDIncharge(item.gr_dept_incharge);
                        setCIncharge(item.gr_cat_incharge);
                        setGrid(item.gr_pk_id);
                        setRaisedBy(item.gr_emp_id);
                        setMsg(item.gr_desc);
                        setCat(item.grievanceCategoryName);
                      }}
                    >
                      Notify
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default GrievanceList;
