import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import Pagination from "react-js-pagination";

const Appraiser_assign = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);
  let company_id = Apiconnect.get_company_id();

  const [empList, setEmpList] = useState([]);
  const [emp1, setEmp1] = useState("");
  const [emp2, setEmp2] = useState("");
  const [empTo, setEmpTo] = useState("");
  const [reviewer, setReviewer] = useState("");

  //emplist
  const [emp_list, setEmp_list] = useState([]);
  const [catList, setCategory] = useState([]);
  const [desg_id, setDesg_id] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [empStatus, setEmpStatus] = useState(1);
  const [empCount, setempCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [des_list, setDesList] = useState([]);
  const [selected, setSelected] = useState({});
  const [allchecked, setallchecked] = useState(null);
  const [name, setName] = useState("");
  //
  const [deg_list, setDeg_list] = useState([]);
  const [emps1, setEmps1] = useState([]);
  const [emps2, setEmps2] = useState([]);

  useEffect(() => {
    getEmp();
    get_emp_list("10", "0", "", "", empStatus, desg_id);
    getEmpCount("", "", empStatus, desg_id);
    getDesignation();
    getCategory();
    getDesignation2();
  }, []);

  const getDesignation2 = () => {
    try {
      Apiconnect.postData(`separation/getalldesignationsbycompany/${Apiconnect.get_company_id()}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setDeg_list(extract);
      });
    } catch (error) {
      toast.error("Can't fetch designations");
    }
  };

  const getEmp = () => {
    Apiconnect.getData(`employee/getusers_all/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
    });
  };

  const getAssignedList = () => {
    try {
    } catch (error) {}
  };

  const get_emp_list = (limit, offset, search, desg_id) => {
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
      desg_id: desg_id,
    };
    Apiconnect.postData(`hiring_application/hiring_panel_getallemployeelist`, data).then((res) => {
      setEmp_list(res.data.data);
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * 10;
    let limit = 10;
    get_emp_list(limit, offset, "", desg_id);
  };

  const getEmpCount = (search, desg_id) => {
    const data = {
      search: search,
      company_id: Apiconnect.get_company_id(),
      desg_id: desg_id,
    };

    Apiconnect.postData(`hiring_application/hiring_panel_getEmpSearchcount`, data).then((res) => {
      let _xtract = res.data.data;
      setempCount(_xtract[0].cnt);
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let offset = (activePage - 1) * 10;
      let limit = 10;

      get_emp_list(limit, offset, searchTerm, desg_id);

      const data = {
        search: searchTerm,
        company_id: Apiconnect.get_company_id(),
        desg_id: desg_id,
      };

      Apiconnect.postData(`hiring_application/hiring_panel_getEmpSearchcount`, data).then((res) => {
        let _xtract = res.data.data;
        setempCount(_xtract[0].cnt);
      });
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getDesignation = () => {
    Apiconnect.getData(`designation/getall/${company_id}`).then((response) => {
      let _xtract = response.data.data;
      setDesList(_xtract.reverse());
    });
  };

  const onDesgChange = (e) => {
    let desg_id = e.target.value;
    get_emp_list("10", "0", "", desg_id);
    getEmpCount("", desg_id);
    setDesg_id(desg_id);
  };
  const getCategory = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setCategory(_xtract.reverse());
    });
  };
  const getallemps1 = (id) => {
    try {
      Apiconnect.postData(`separation/getAllEmpByDesignation/${Apiconnect.get_company_id()}/${id}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setEmps1(extract);
      });
    } catch (error) {
      toast.error("Error While Fetching Employees");
    }
  };
  const getallemps2 = (id) => {
    try {
      Apiconnect.postData(`separation/getAllEmpByDesignation/${Apiconnect.get_company_id()}/${id}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setEmps2(extract);
      });
    } catch (error) {
      toast.error("Error While Fetching Employees");
    }
  };

  const selectAll = (e) => {
    if (e.target.checked === true) {
      setallchecked("checked");
      let ob = {};
      emp_list.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelected(ob);
    } else {
      setallchecked(null);
      setSelected([]);
    }
  };
  const onInputChange = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelected({
        ...selected,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelected({ ...selected, [e.currentTarget.name]: 0 });
    }
  };

  const trHeader = (
    <tr>
      <th className="text-center">{/* <input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /> */}</th>
      <th className="text-center">#</th>
      <th className="text-center">Name</th>
      <th className="text-center">Designation</th>
    </tr>
  );

  const handleSubmit = () => {
    try {
      if (emp1 && emp2 && Object.keys(selected).length > 0) {
        const dataIns = {
          aaa_company_id: Apiconnect.get_company_id(),
          aaa_appraiser: emp1,
          aaa_reviewer: emp2,
          appraisee: selected,
        };
        Apiconnect.postData(`appraisal/appraiserAssign`, dataIns)
          .then((res) => {
            toast.success("Assigned Successfully");
            setEmp1("");
            setEmp2("");
            setSelected({});
          })
          .catch((err) => {
            toast.error("Something Went Wrong");
          });
      }
      !emp1 && toast.error("Please Select  Appraiser");
      !emp2 && toast.error("Please Select  Reviewer");
      Object.keys(selected).length === 0 && toast.error("Please Select Appraisee");
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <>
      <Container heading="Employee Appraiser Assign">
        <Card>
          <div className="col-md-6">
            <div className="row">
              <SelectFromOptions
                className="col-md-12"
                label="Select Appraiser Designation"
                optionOne="Designation List"
                onChange={(e) => {
                  getallemps1(e.target.value);
                }}
              >
                {deg_list.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </SelectFromOptions>
              <SelectFromOptions
                className="col-md-12"
                label="Select Appraiser"
                optionOne="Employee List"
                name="emp1"
                value={emp1}
                onChange={(e) => {
                  setEmp1(e.target.value);
                }}
              >
                {emps1.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.first_name + " " + item.middle_name + " " + item.last_name}
                    </option>
                  );
                })}
              </SelectFromOptions>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <SelectFromOptions
                className="col-md-12"
                label="Select Reviewer Designation"
                optionOne="Designation List"
                onChange={(e) => {
                  getallemps2(e.target.value);
                }}
              >
                {deg_list.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </SelectFromOptions>
              <SelectFromOptions
                className="col-md-12"
                label="Select Reviewer"
                optionOne="Employee List"
                name="emp2"
                value={emp2}
                onChange={(e) => {
                  setEmp2(e.target.value);
                }}
              >
                {emps2.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.first_name + " " + item.middle_name + " " + item.last_name}
                    </option>
                  );
                })}
              </SelectFromOptions>
            </div>
          </div>
        </Card>
        <Card>
          <Card>
            <SelectFromOptions className="col-md-4" label="" name={`desg_id`} value={desg_id} onChange={(e) => onDesgChange(e)} optionOne="Select Designation">
              {des_list.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </SelectFromOptions>
            <Input className="col-md-4" label="" type="text" name="search" placeholder="Search ..." onChange={(e) => setSearchTerm(e.target.value)} />
            <div className="col-md-4">
              <label></label>
              <button className="btn btn-sm btn-info" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </Card>
          <Table className="col-md-12" trHeader={trHeader}>
            {emp_list.length > 0 ? (
              emp_list.map((item, index) => {
                let ck = Object.keys(selected).find(function (element) {
                  return selected[element] == item.id;
                });

                let ck1 = "";
                if (ck !== undefined) {
                  ck1 = true;
                }

                let names = "";
                if (item.panels_names === null) {
                  names = <span className="badge badge-warning">No Panels Assigned</span>;
                } else {
                  names = item.panels_names.map((val, key) => {
                    return (
                      <span className="badge badge-success" key={key}>
                        {val.hpp_name}
                      </span>
                    );
                  });
                }
                return (
                  <tr className="text-center" key={index}>
                    <th scope="row" className="text-center">
                      <input type="checkbox" name={item.id} value={item.id} checked={ck1} onChange={(e) => onInputChange(e)} />
                    </th>
                    <td className="text-center">{index + 1}</td>
                    <td>
                      {item.first_name} {item.last_name}
                    </td>
                    <td>{item.emp_designation_name}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th className="text-center" colSpan={4}>
                  No Data Found...
                </th>
              </tr>
            )}
          </Table>
          <Pagination activePage={activePage} itemsCountPerPage={50} totalItemsCount={empCount} pageRangeDisplayed={5} onChange={(e) => handlePageChange(e)} />
        </Card>
      </Container>
    </>
  );
};

export default Appraiser_assign;
