import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import Modal from 'react-modal';
import moment from 'moment';

const View_panelist_score = () => {

    const {id, a_id} = useParams()
    
    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    useEffect(() => {id && applicant_assessment_list()},[])


    /* ----------------------------------- */
    const [p_score, setP_score]=useState([])
    const [p_name, setP_name] = useState("")
    const [p_email, setP_email] = useState("")
    const [p_desg, setP_desg] = useState("")
    const [assessment_name, setAssessment_name] = useState('')
    const applicant_assessment_list = () => {
        let ins_ob = {
            company_id:company_id,
            id:a_id
        }
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_applicant_assessment_getall`, ins_ob).then(res => {
            setFullScreenLoader(false)
            let data = Apiconnect.decrypt_obj(res.data.data)
            console.log(data)
            const filtered = data.find((item) => item.id == id)
            setP_score(filtered)
            setP_name(filtered.panelist_score.panelist_details[0].panelist_name)
            setP_email(filtered.panelist_score.panelist_details[0].email)
            setP_desg(filtered.panelist_score.panelist_details[0].panelist_designation)
            setAssessment_name(filtered.assessment_name)
        })
    }

    console.log(p_score)

  return (
    <>
        <div className='content-page'
        style={{background: "#ffffff"}}
        >
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'
                        style={{boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}
                        >
                            <div className='iq-card-header content_header_bg d-flex justify-content-between mdoel_head '
                            style={{background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div className='header-title'>
                                    <h4 style={{color:"White"}}>Panelist Score For {assessment_name}</h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <label>Panelist Name</label>
                                        <input className='form-control' value={p_name} />
                                    </div>
                                    <div className='col-md-2'>
                                        <label>Panelist Email</label>
                                        <input className='form-control' value={p_email} />
                                    </div>
                                    <div className='col-md-2'>
                                        <label>Panelist Designation</label>
                                        <input className='form-control' value={p_desg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default View_panelist_score
