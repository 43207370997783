import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Rm_initiate_assessments = () => {
  const { applicant_id } = useParams();

  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [applicant_info, setApplicant_info] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phn, setPhn] = useState("");
  const [hp_name, setHp_name] = useState("");
  const [hp_id, setHp_id] = useState("");
  const [round_cnt, setRound_cnt] = useState(0);
  const [rounds, setRounds] = useState([]);
  const [applied_for, setApplied_for] = useState("");
  const [position_id, setPosition_id] = useState("");

  useEffect(() => {
    get_applicant_info();
    get_panel_list();
  }, []);

  const get_applicant_info = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(
      `hrms_initiate_assessments/get_applicant_info/${applicant_id}`
    ).then((res) => {
      setFullScreenLoader(false);
      const data = Apiconnect.decrypt_obj(res.data.data);
      console.log(data)
      setName(data.name);
      setEmail(data.am_email);
      setPhn(data.am_phone_cell);
      setRound_cnt(data.round_details.length);
      setRounds(data.round_details);
      setHp_name(data.hp_name);
      setApplied_for(data.am_position);
      setPosition_id(data.am_position_id);
      setHp_id(data.hp_id);
    });
  };

  const [panel_list, setPanel_list] = useState([]);

  const get_panel_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(
      `hrms_initiate_assessments/hiring_panel_list/${company_id}`
    ).then((res) => {
      setFullScreenLoader(false);
      setPanel_list(Apiconnect.decrypt_obj(res.data.data));
    });
  };

  // console.log(panel_list)

  /* ------------Modal---------- */
  const [m_round_name, setM_round_name] = useState("");
  const [m_round_id, setM_round_id] = useState("");
  const [m_assessment_name, setM_assessment_name] = useState("");
  const [m_assessment_id, setM_assessment_id] = useState("");
  const [m_form_id, setM_form_id] = useState("");
  const [m_form_name, setM_form_name] = useState("");
  const [m_panel_id, setM_panel_id] = useState("");
  const [m_date, setM_date] = useState("");
  const [m_from_time, setM_from_time] = useState("");
  const [m_to_time, setM_to_time] = useState("");
  const [m_remark, setM_remark] = useState("");

  const [selected, setSelected] = useState([]);
  const [allchecked, setallchecked] = useState(null);

  const onInputChange_1 = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelected({
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const schedule_assessment = () => {
    let ins_ob = {
      hsa_company_id: company_id,
      hsa_applicant_id: applicant_id,
      hsa_applied_position_id: position_id,
      hsa_hp_id: hp_id,
      hsa_round_id: m_round_id,
      hsa_assessment_id: m_assessment_id,
      hsa_form_id: m_form_id,
      hsa_panel_id: selected.hp_pk_id,
      hsa_date: m_date,
      hsa_from_time: m_from_time,
      hsa_to_time: m_to_time,
      hsa_applicant_name: name,
      hsa_applied_position_name: applied_for,
      hsa_hp_name: hp_name,
      hsa_round_name: m_round_name,
      hsa_assessment_name: m_assessment_name,
      hsa_form_name: m_form_name,
      hsa_added_by: emp_id,
      hsa_remark: m_remark,
    };

    if (
      m_date !== "" &&
      m_from_time !== "" &&
      m_to_time !== "" &&
      m_remark !== ""
    ) {
      if (
        ins_ob.hsa_panel_id !== "" ||
        ins_ob.hsa_panel_id !== undefined ||
        ins_ob.hsa_panel_id !== null
      ) {
        Apiconnect.postData(
          `hrms_initiate_assessments/schedule_assessments`,
          ins_ob
        ).then((res) => {
          alert.success("Assessment Scheduled");
          setModalIsOpen(false);
          setM_date("");
          setM_from_time("");
          setM_to_time("");
          setM_remark("");
        });
      } else {
        alert.error("Please Select Panel");
      }
    } else {
      alert.error("Empty Inputfields");
    }
  };

  const [round_info, setRound_info] = useState([]);
  const get_scheduled_info = (r_id, a_id) => {
    let data_ins = {
      hsa_company_id: company_id,
      hsa_applicant_id: applicant_id,
      hsa_applied_position_id: position_id,
      hsa_hp_id: hp_id,
      hsa_round_id: r_id,
      hsa_assessment_id: a_id,
    };
    Apiconnect.postData(
      `hrms_initiate_assessments/scheduled_info`,
      data_ins
    ).then((res) => {
      let data = Apiconnect.decrypt_obj(res?.data?.data);
      setRound_info(data);
    });
  };

  return (
    <div className="content-page main_bg">
      {fullScreenLoader ? (
        <FullScreenLoading loading loaderColor="#3498db" />
      ) : null}
      <div className="container-fluid">
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div className="iq-card">
            <div className="iq-card-header card_head">
              <h5 className="text-white">
                Assign{" "}
                <span className="font-weight-bold">
                  {m_assessment_name} - ({m_round_name})
                </span>{" "}
                For <span className="font-weight-bold">{name}</span>
              </h5>
            </div>
            <div className="iq-card-body ">
              <div className="row">
                <div className="col-md-3 col-sm-4">
                  <label>Select Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="m_date"
                    value={m_date}
                    onChange={(e) => setM_date(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-4">
                  <label>From Time</label>
                  <input
                    className="form-control"
                    type="time"
                    name="m_from_time"
                    value={m_from_time}
                    onChange={(e) => setM_from_time(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-4">
                  <label>To Time</label>
                  <input
                    className="form-control"
                    type="time"
                    name="m_to_time"
                    value={m_to_time}
                    onChange={(e) => setM_to_time(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-4">
                  <label>Remark</label>
                  <input
                    className="form-control"
                    type="text"
                    name="m_remark"
                    value={m_remark}
                    onChange={(e) => setM_remark(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 mt-3">
                  <div className="table-responsive">
                    <table className="table table-bordered normal_shadow">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center"></th>
                          <th className="text-center">#</th>
                          <th className="text-center">Panel Name</th>
                          <th className="text-center">Panelist Count</th>
                          <th className="text-center">Asigned Panelists</th>
                          {/* hpp_name */}
                          {/* panel_members */}
                        </tr>
                      </thead>
                      <tbody>
                        {panel_list.map((item, index) => {
                          let ck = Object.keys(selected).find(function (
                            element
                          ) {
                            return selected[element] == item.hpp_pk_id;
                          });

                          var ck1 = "";
                          if (ck !== undefined) {
                            ck1 = true;
                          }

                          return (
                            <tr key={item.hpp_pk_id}>
                              <td>
                                {item.panel_members.length > 0 && (
                                  <input
                                    type="radio"
                                    name="hp_pk_id"
                                    value={item.hpp_pk_id}
                                    checked={ck1}
                                    onChange={(e) => onInputChange_1(e)}
                                  />
                                )}
                              </td>
                              <td>{index + 1}</td>
                              <td>{item.hpp_name}</td>
                              <td className="text-center">
                                {item.panel_members.length}
                              </td>
                              <td>
                                {item.panel_members.length > 0 ? (
                                  item.panel_members.map((item1, index1) => {
                                    return (
                                      <span
                                        className="badge badge-pill badge-success badge-sm m-2"
                                        key={index1}
                                      >
                                        {item1.emp_name}
                                      </span>
                                    );
                                  })
                                ) : (
                                  <span className="badge badge-pill badge-warning badge-sm m-2">
                                    No Panelist Assiged
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={schedule_assessment}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="iq-card main_container">
          <div className="iq-card-header card_head">
            <h4 className="text-white">
              Initiate Assessments For{" "}
              <span className="font-weight-bold">{name}</span>
            </h4>
            <Link to={`/hrms_shortlisted_applicant_list`}>
              <button className="btn btn-sm btn-info">Back</button>
            </Link>
          </div>
          <div className="iq-card-body">
            <div className="row mt-3">
              <div className="col-md-12 mt-3">
                <div table-responsive>
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="text-center font-weight-bold"
                          style={{ width: "33.33%" }}
                        >
                          Candidate Name
                        </th>
                        <th
                          className="text-center font-weight-bold"
                          style={{ width: "33.33%" }}
                        >
                          Email
                        </th>
                        <th
                          className="text-center font-weight-bold"
                          style={{ width: "33.33%" }}
                        >
                          Phone
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="text-center">{name}</th>
                        <th className="text-center">{email}</th>
                        <th className="text-center">{phn}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div table-responsive>
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="text-center font-weight-bold"
                          style={{ width: "33.33%" }}
                        >
                          Position Applied
                        </th>
                        <th
                          className="text-center font-weight-bold"
                          style={{ width: "33.33%" }}
                        >
                          Assigned Hiring Process
                        </th>
                        <th
                          className="text-center font-weight-bold"
                          style={{ width: "33.33%" }}
                        >
                          Total Rounds
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="text-center">{applied_for}</th>
                        <th className="text-center">{hp_name}</th>
                        <th className="text-center">{round_cnt}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="table-responsive bg-light">
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="text-center font-weight-bold"
                          colSpan={20}
                        >
                          Manage Rounds
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rounds.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "5%" }}>{index + 1}</td>
                            <td
                              className="text-center"
                              key={index}
                              style={{ width: "95%" }}
                            >
                              <div className="text-center">
                                <span className="font-weight-bold badge badge-pill badge-info">
                                  {item.round_name}
                                </span>{" "}
                                <br />
                                <div className="row mt-2">
                                  <div className="col-md-12 table-responsive">
                                    <table className="table table-bordered normal_shadow">
                                      <thead className="thead-light">
                                        <tr>
                                          <th
                                            className="text-center font-weight-bold"
                                            style={{ width: "10%" }}
                                          >
                                            #
                                          </th>
                                          <th
                                            className="text-center font-weight-bold"
                                            style={{ width: "30%" }}
                                          >
                                            Assessment Name
                                          </th>
                                          <th
                                            className="text-center font-weight-bold"
                                            style={{ width: "30%" }}
                                          >
                                            Assigned Form
                                          </th>
                                          <th
                                            className="text-center font-weight-bold"
                                            style={{ width: "30%" }}
                                          >
                                            Manage Assessment
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.assessments.map((val, key) => {
                                          return (
                                            <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>
                                                <span className="badge badge-pill badge-success">
                                                  {val.assessment_name}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="badge badge-small badge-warning">
                                                  {val.form_name} Form
                                                </span>
                                              </td>
                                              <td>
                                                <Link
                                                  to={`/manage_assessments/${applicant_id}/${position_id}/${hp_id}/${item.hhr_round_id}/${val.hra_assessment_id}/${val.assessment_form_id}`}
                                                >
                                                  <button className="btn btn-sm btn-primary">
                                                    Manage
                                                  </button>
                                                </Link>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_initiate_assessments;
