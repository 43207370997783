import React from "react";

import Dashboard from "./admin_component/pages/Dashboard";
import Dashboard_superadmin from "./admin_component/pages/Dashboard_superadmin";
import Test from "./admin_component/pages/test";
import Formula_list from "./admin_component/pages/Formula_list";
import Formula_add from "./admin_component/pages/Formula_add";
import Formula_edit from "./admin_component/pages/Formula_edit";

import Employee_list from "./admin_component/pages/Employee_list";
import Employee_add from "./admin_component/pages/Employee_add";
import Employee_edit from "./admin_component/pages/Employee_edit";

import Company from "./admin_component/pages/Company";
import Company_list from "./admin_component/pages/Company_list";
import Company_edit from "./admin_component/pages/Company_edit";
import Company_category from "./admin_component/pages/Company_category";
import Employment_group from "./admin_component/pages/Employment_group";
import Leaves_assign from "./admin_component/pages/Leaves_assign";
import Shift_assign from "./admin_component/pages/Shift_assign";
import Company_holiday from "./admin_component/pages/Company_holiday";
import Weekly_off from "./admin_component/pages/Weekly_off";
import Leave_policy from "./admin_component/pages/Leaves_policy";
import Earning_head from "./admin_component/pages/Earning_head";
import Deduction_head from "./admin_component/pages/Deduction_head";
import Reimbursement from "./admin_component/pages/Reimbursement";
import Perk_head from "./admin_component/pages/Perk_head";
import Shift from "./admin_component/pages/Shift";

import Employee_manage from "./admin_component/pages/Employee_manage";
import Employee_manage_info from "./admin_component/pages/Employee_manage_info";
import Employee_manage_bank from "./admin_component/pages/Employee_manage_bank";
import Employee_manage_employement_info from "./admin_component/pages/Employee_manage_employement_info";
import Employee_manage_salary_info from "./admin_component/pages/Employee_manage_salary_info";
import Employee_manage_sal from "./admin_component/pages/Employee_manage_sal";
import Employee_manage_reimbursement from "./admin_component/pages/Employee_manage_reimbursement";
import Employee_month_sal from "./admin_component/pages/Employee_month_sal";
import Employee_manage_doc from "./admin_component/pages/Employee_manage_doc";
import employee_manage_qualification from "./admin_component/pages/Employee_manage_qualification";

import Employee_salary_list from "./admin_component/pages/Employee_salary_list";
import Upload from "./admin_component/pages/Upload";
import Monthly_salary_list from "./admin_component/pages/Monthly_salary_list";

import Employee_manage_list from "./admin_component/pages/Employee_manage_list";
import ResetPassword from "./admin_component/pages/ResetPassword";
import Team_member_leaves from "./admin_component/pages/Team_member_leaves";
import Team_member_attendance from "./admin_component/pages/Team_member_attendance";
import Manual_leaves from "./admin_component/pages/Manual_leaves";

import Employee_salary_template from "./admin_component/pages/Employee_salary_template";
import Employee_salary_template_edit from "./admin_component/pages/Employee_salary_template_edit";
import Employee_salary_template_list from "./admin_component/pages/Employee_salary_template_list";

import Employee_bulk_upload from "./admin_component/pages/Employee_bulk_upload";
import Employee_image_upload from "./admin_component/pages/Employee_image_upload";
import Fr_image_upload from "./admin_component/pages/Fr_image_upload";
import Company_upload_logo from "./admin_component/pages/Company_upload_logo";

import Employee_requested_thumb from "./admin_component/pages/Employee_requested_thumb";
import Employee_requested_details from "./admin_component/pages/Employee_requested_details";

import Salary_disbursement from "./admin_component/pages/Salary_disbursement";
import Annual_salary_report from "./admin_component/pages/Annual_salary_report";

import Area from "./admin_component/pages/Area";
import City from "./admin_component/pages/City";

import Employee_report from "./admin_component/pages/Employee_report";
import Employee_punchtime_report from "./admin_component/pages/Employee_punchtime_report";
import Employee_attendance_report from "./admin_component/pages/Employee_attendance_report";
import Monthly_attendance_report from "./admin_component/pages/Monthly_attendance_report";
import Daily_departure_report from "./admin_component/pages/Daily_departure_report";
import Daily_Absent_report from "./admin_component/pages/Daily_absent_report";
import Daily_late_arrival_report from "./admin_component/pages/Daily_late_arrival_report";
import Employee_leave_report from "./admin_component/pages/Employee_leave_report";
import Employee_leave_report_format from "./admin_component/pages/Employee_leave_report_format";

import Permission_group_list from "./admin_component/pages/Permission_group_list";
import EsiPdf from "./admin_component/pages/EsiPdf";
import Designation from "./admin_component/pages/Designation";
import Institute from "./admin_component/pages/edu_institute";
import University_board from "./admin_component/pages/edu_university_board";

import Employee_manage_salary_wages from "./admin_component/pages/Employee_manage_salary_wages";
import Employee_manage_financial from "./admin_component/pages/Employee_manage_financial";
import IdCard from "./admin_component/pages/IdCard";

import Employee_leave_apply from "./admin_component/pages/Employee_leave_apply";
import Employee_leave_apply_short from "./admin_component/pages/Employee_leave_apply_short";

/* Leave Management */
import LeaveCycleSettings from "./admin_component/pages/LeaveCycleSettings";
import CustomLeaveGroup from "./admin_component/pages/CustomLeaveGroup";
import LeavePolicyList from "./admin_component/pages/LeavePolicyList";
import Leave_policy_manages from "./admin_component/pages/Leave_policy_manages";
import Leave_policy_manages_cat from "./admin_component/pages/Leave_policy_manages_cat";
import LeavePolicyAssignEmp from "./admin_component/pages/LeavePolicyAssignEmp";
import LeaveEmployee from "./admin_component/pages/LeaveEmployee";
import LeaveApply from "./admin_component/pages/LeaveApply";
import LeaveCategory from "./admin_component/pages/LeaveCategory";
import Manage_leave_category_wise from "./admin_component/pages/Manage_leave_category_wise";

import PreviousEmployment from "./admin_component/pages/PreviousEmployment";
import Monthly_attendance_list from "./admin_component/pages/Monthly_attendance_list";
import Team_compoff_request from "./admin_component/pages/Team_compoff_request";

import Employee_leave_record from "./admin_component/pages/Employee_leave_record";
import Employee_leave_taken from "./admin_component/pages/Employee_leave_taken";

/* Wings */
import Wings_manage from "./admin_component/pages/Wings_manage";
import Wings_ClassAssignment from "./admin_component/pages/Wings_ClassAssignment";
import WingClass_Assign from "./admin_component/pages/WingClass_Assign";
import Work_force_planning from "./admin_component/pages/Work_force_planning";

/* KRA */
import Kra_manage from "./admin_component/pages/Kra_manage";
import Kra_list from "./admin_component/pages/Kra_list";
import Kra_manage_list from "./admin_component/pages/Kra_manage_list";
import Kra_assign from "./admin_component/pages/Kra_assign";

/* workforce */
import Work_force from "./admin_component/pages/Work_force";
import Work_force_hierarchy_level from "./admin_component/pages/Work_force_hierarchy_level";
import Work_force_hierarchy from "./admin_component/pages/Work_force_hierarchy";
import Work_force_auth from "./admin_component/pages/Work_force_auth";

/* --Hiring Dashboard-- */
import Hiring_dashboard from "./admin_component/pages/Hiring_dashboard";
import Applications from "./admin_component/pages/Applications";
import Applicants from "./admin_component/pages/Applicants";
import Hiring_rounds from "./admin_component/pages/Hiring_rounds";
import Hiring_round_assessment from "./admin_component/pages/Hiring_round_assessment";
import Manage_roundwise_assessments from "./admin_component/pages/Manage_roundwise_assessments";
import Hiring_round_panel from "./admin_component/pages/Hiring_round_panel";
import Hiring_process from "./admin_component/pages/Hiring_process";
import Assign_assessments from "./admin_component/pages/Assign_assessments";
import Hiring_panel from "./admin_component/pages/Hiring_panel";
import Manage_hiring_panel from "./admin_component/pages/Manage_hiring_panel";
import Compose_panel from "./admin_component/pages/Compose_panel";
import View_panel from "./admin_component/pages/View_panel";
import Assign_panel from "./admin_component/pages/Assign_panel";
import Manage_shortlisted from "./admin_component/pages/Manage_shortlisted";
import Hiring_process_vac_assign from "./admin_component/pages/Hiring_process_vac_assign";
import Initiate_hiring_process from "./admin_component/pages/Initiate_hiring_process";

/* --Manage Assessment Forms */
import Manage_assessment_forms from "./admin_component/pages/Manage_assessment_forms";
import View_panelist_score from "./admin_component/pages/View_panelist_score";
import Rm_hp_master from "./admin_component/pages/recruitment_module/Rm_hp_master";
import Rm_forms_master from "./admin_component/pages/recruitment_module/Rm_forms_master";
import Rm_assessment_master from "./admin_component/pages/recruitment_module/Rm_assessment_master";
import Rm_round_master from "./admin_component/pages/recruitment_module/Rm_round_master";
import Rm_additional_vacancies from "./admin_component/pages/recruitment_module/Rm_additional_vacancies";
import Rm_replacement_vacancies from "./admin_component/pages/recruitment_module/Rm_replacement_vacancies";
import Rm_manage_shortlisted from "./admin_component/pages/recruitment_module/Rm_manage_shortlisted";
import Rm_initiate_assessments from "./admin_component/pages/recruitment_module/Rm_initiate_assessments";
import Rm_manage_assessment from "./admin_component/pages/recruitment_module/Rm_manage_assessment";
import Rm_consolidated_candidate_score from "./admin_component/pages/recruitment_module/Rm_consolidated_candidate_score";
import Employee_notifications from "./admin_component/pages/Employee_notifications";
import Rm_selected_applicants from "./admin_component/pages/recruitment_module/Rm_selected_applicants";
import Employee_logs from "./admin_component/pages/Employee_logs";
import Verified_applicants from "./admin_component/pages/recruitment_module/Verified_applicants";
import Verify_applicant_docs from "./admin_component/pages/recruitment_module/Verify_applicant_docs";
import Hiring_approval_authority from "./admin_component/pages/recruitment_module/Hiring_approval_authority";

/* Grievance */
import Grievance_dept from "./admin_component/pages/grievance_module/Grievance_dept";
import Grievance_cat from "./admin_component/pages/grievance_module/Grievance_cat";
import GrievanceList from "./admin_component/pages/grievance_module/GrievanceList";

/* Boarding */
import Employee_boarding from "./admin_component/pages/employee_onboarding/Employee_boarding";
import Onboarding_docs from "./admin_component/pages/employee_onboarding/Onboarding_docs";

/* full & final */
import Resignation from "./admin_component/pages/full_and_final/Resignation";
import Retirement from "./admin_component/pages/full_and_final/Retirement";
import ExtensionRequested from "./admin_component/pages/full_and_final/ExtensionRequested";
import Termination from "./admin_component/pages/full_and_final/Termination";
import Emp_exit_interviews from "./admin_component/pages/full_and_final/Emp_exit_interviews";

/* Appraisal */
import Appraiser_assign from "./admin_component/pages/employee_appraisal/Appraiser_assign";
import Appraiser_assigned_list from "./admin_component/pages/employee_appraisal/Appraiser_assigned_list";
import Emp_exit_interview from "./admin_component/pages/full_and_final/Emp_exit_interview";
import NoDuesHeads from "./admin_component/pages/full_and_final/NoDuesHeads";
import NoDuesResig from "./admin_component/pages/full_and_final/NoDuesResig";
import NoDuesResignationInfo from "./admin_component/pages/full_and_final/NoDuesResignationInfo";

/* Appraisal */
import Manange_attendance_paidday from "./admin_component/pages/Manange_attendance_paidday";

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/superadmin", name: "Dashboard", component: Dashboard_superadmin },

  { path: "/upload", name: "Upload", component: Upload },
  { path: "/employee_report", name: "upload", component: Employee_report },
  {
    path: "/punchtime_report",
    name: "upload",
    component: Employee_punchtime_report,
  },
  {
    path: "/daily_attendance_report",
    name: "upload",
    component: Employee_attendance_report,
  },
  {
    path: "/monthly_attendance_report",
    name: "upload",
    component: Monthly_attendance_report,
  },
  {
    path: "/daily_departure_report",
    name: "upload",
    component: Daily_departure_report,
  },
  {
    path: "/daily_absent_report",
    name: "upload",
    component: Daily_Absent_report,
  },
  {
    path: "/daily_late_arrival_report",
    name: "upload",
    component: Daily_late_arrival_report,
  },

  {
    path: "/Employee_leave_report",
    name: "upload",
    component: Employee_leave_report,
  },
  {
    path: "/Employee_leave_report_format/:emp_id",
    name: "upload",
    component: Employee_leave_report_format,
  },

  { path: "/Test/:id?", name: "Test", component: Test },
  {
    path: "/employee_manage_sal/:id",
    name: "Emloyee Salary",
    component: Employee_manage_sal,
  },
  {
    path: "/employee_manage_reimbursement/:id",
    name: "Emloyee Salary",
    component: Employee_manage_reimbursement,
  },
  {
    path: "/employee_month_sal/:id?/:monId?/:status?",
    name: "Emloyee Salary",
    component: Employee_month_sal,
  },

  { path: "/formula_list", name: "formula_list", component: Formula_list },
  { path: "/formula_add/", name: "formula_add", component: Formula_add },
  { path: "/formula_edit/:id", name: "formula_edit", component: Formula_edit },

  { path: "/employee_add", name: "Employee Add", component: Employee_add },
  { path: "/employee_edit", name: "Employee Add", component: Employee_edit },
  { path: "/employee_list", name: "Employee List", component: Employee_list },
  { path: "/company", name: "Company", component: Company },
  {
    path: "/company_category",
    name: "Company Category",
    component: Company_category,
  },
  {
    path: "/leaveassign/:id",
    name: "Company Category",
    component: Leaves_assign,
  },
  { path: "/shiftassign/:id", name: "Shift Assign", component: Shift_assign },
  {
    path: "/employment_group",
    name: "Employment Type",
    component: Employment_group,
  },

  { path: "/company_list", name: "Company List", component: Company_list },
  { path: "/company_edit/:id", name: "Company Edit", component: Company_edit },

  {
    path: "/company_holiday",
    name: "Company Holiday",
    component: Company_holiday,
  },
  { path: "/weekly_off", name: "Weekly Off", component: Weekly_off },
  { path: "/leave_policy", name: "Leave Policy", component: Leave_policy },
  { path: "/earning_head", name: "Earning head", component: Earning_head },
  {
    path: "/deduction_head",
    name: "Deduction head",
    component: Deduction_head,
  },
  { path: "/reimbursement", name: "Reimbursement", component: Reimbursement },
  { path: "/Perk_head", name: "Perk head", component: Perk_head },
  { path: "/Shift", name: "Shift", component: Shift },

  {
    path: "/employee_manage/:id",
    name: "Emloyee Manage",
    component: Employee_manage,
  },
  {
    path: "/employee_manage_info/:id",
    name: "Emloyee Manage",
    component: Employee_manage_info,
  },
  {
    path: "/employee_manage_bank/:id",
    name: "Emloyee Manage",
    component: Employee_manage_bank,
  },
  {
    path: "/employee_manage_employement/:id",
    name: "Emloyee Manage",
    component: Employee_manage_employement_info,
  },
  {
    path: "/employee_manage_salary/:id",
    name: "Emloyee Manage",
    component: Employee_manage_salary_info,
  },
  {
    path: "/employee_manage_doc/:id",
    name: "Emloyee Docs",
    component: Employee_manage_doc,
  },
  {
    path: "/employee_manage_qualification/:id",
    name: "Emloyee Qualification",
    component: employee_manage_qualification,
  },

  {
    path: "/employee_manage_list",
    name: "Emloyee Manage",
    component: Employee_manage_list,
  },
  { path: "/resetPassword", name: "Dashboard", component: ResetPassword },

  {
    path: "/salary_list",
    name: "Salary List",
    component: Employee_salary_list,
  },
  { path: "/manual_leaves", name: "Dashboard", component: Manual_leaves },
  {
    path: "/monthly_salary_list",
    name: "Monthly Salary List",
    component: Monthly_salary_list,
  },

  {
    path: "/team_member_leaves",
    name: "Dashboard",
    component: Team_member_leaves,
  },
  {
    path: "/team_member_attendance",
    name: "Dashboard",
    component: Team_member_attendance,
  },

  {
    path: "/salary_template",
    name: "Dashboard",
    component: Employee_salary_template,
  },
  {
    path: "/salary_template_edit/:id",
    name: "Dashboard",
    component: Employee_salary_template_edit,
  },
  {
    path: "/salary_template_list",
    name: "Dashboard",
    component: Employee_salary_template_list,
  },

  { path: "/area", name: "Dashboard", component: Area },
  { path: "/city", name: "Dashboard", component: City },
  { path: "/designation", name: "Dashboard", component: Designation },
  { path: "/institute", name: "Dashboard", component: Institute },
  { path: "/university_board", name: "Dashboard", component: University_board },

  {
    path: "/employee_upload_bulk",
    name: "upload",
    component: Employee_bulk_upload,
  },
  {
    path: "/employee_upload_image/:id?",
    name: "upload",
    component: Employee_image_upload,
  },
  { path: "/fr_upload_image/:id?", name: "upload", component: Fr_image_upload },
  {
    path: "/company_upload_logo/:id?",
    name: "upload",
    component: Company_upload_logo,
  },
  {
    path: "/employee_requested_thumb/:id?",
    name: "upload",
    component: Employee_requested_thumb,
  },
  {
    path: "/employee_requested_details/:id?",
    name: "upload",
    component: Employee_requested_details,
  },

  {
    path: "/permission_group_list/:id?",
    name: "upload",
    component: Permission_group_list,
  },
  { path: "/esiPdf/:year?/:month?", name: "upload", component: EsiPdf },
  {
    path: "/salary_disbursement",
    name: "upload",
    component: Salary_disbursement,
  },
  {
    path: "/annual_salary_report",
    name: "upload",
    component: Annual_salary_report,
  },
  {
    path: "/manage_salary_wages",
    name: "upload",
    component: Employee_manage_salary_wages,
  },
  {
    path: "/manage_financial",
    name: "upload",
    component: Employee_manage_financial,
  },
  { path: "/idcard", name: "upload", component: IdCard },

  {
    path: "/employee_leave_apply",
    name: "upload",
    component: Employee_leave_apply,
  },
  {
    path: "/Employee_leave_apply_short",
    name: "upload",
    component: Employee_leave_apply_short,
  },

  {
    path: "/LeaveCycleSettings",
    name: "LeaveCycleSettings",
    component: LeaveCycleSettings,
  },
  {
    path: "/CustomLeaveGroup",
    name: "CustomLeaveGroup",
    component: CustomLeaveGroup,
  },
  {
    path: "/LeavePolicyList",
    name: "LeavePolicyList",
    component: LeavePolicyList,
  },
  {
    path: "/leave_policy_manages",
    name: "leave_policy_manages",
    component: Leave_policy_manages,
  },
  {
    path: "/Leave_policy_manages_cat",
    name: "Leave_policy_manages_cat",
    component: Leave_policy_manages_cat,
  },
  {
    path: "/LeavePolicyAssignEmp/:name/:id",
    name: "LeavePolicyAssignEmp",
    component: LeavePolicyAssignEmp,
  },
  { path: "/LeaveEmployee", name: "LeaveEmployee", component: LeaveEmployee },
  { path: "/LeaveApply", name: "LeaveApply", component: LeaveApply },
  { path: "/LeaveCategory", name: "LeaveCategory", component: LeaveCategory },
  {
    path: "/Manage_leave_category_wise",
    name: "Manage_leave_category_wise",
    component: Manage_leave_category_wise,
  },
  {
    path: "/PreviousEmployment/:id",
    name: "PreviousEmployment",
    component: PreviousEmployment,
  },

  {
    path: "/Monthly_attendance_list",
    name: "upload",
    component: Monthly_attendance_list,
  },
  {
    path: "/Team_compoff_request",
    name: "upload",
    component: Team_compoff_request,
  },

  {
    path: "/Employee_leave_record",
    name: "upload",
    component: Employee_leave_record,
  },
  {
    path: "/Employee_leave_taken",
    name: "upload",
    component: Employee_leave_taken,
  },

  /* --Wings Manage--- */
  { path: "/Wings_manage", name: "upload", component: Wings_manage },
  {
    path: "/Wings_ClassAssignment",
    name: "upload",
    component: Wings_ClassAssignment,
  },
  { path: "/WingClass_Assign", name: "upload", component: WingClass_Assign },
  {
    path: "/Work_force_planning",
    name: "Work_force_planning",
    component: Work_force_planning,
  },

  /* --KRA-- */
  { path: "/Kra_manage", name: "Kra_manage", component: Kra_manage },
  { path: "/Kra_list/:id", name: "Kra_list", component: Kra_list },
  { path: "/Kra_manage_list", name: "Kra_list", component: Kra_manage_list },
  { path: "/Kra_assign/:id", name: "Kra_assign", component: Kra_assign },

  /* --workforce-- */
  { path: "/Work_force", name: "Work_force", component: Work_force },
  {
    path: "/Work_force_auth",
    name: "Work_force_auth",
    component: Work_force_auth,
  },
  {
    path: "/Work_force_hierarchy",
    name: "Work_force_hierarchy",
    component: Work_force_hierarchy,
  },
  {
    path: "/Work_force_hierarchy_level",
    name: "Work_force_hierarchy_level",
    component: Work_force_hierarchy_level,
  },

  /* --Hiring Dashboard-- */
  {
    path: "/Hiring_dashboard",
    name: "Hiring_dadshboard",
    component: Hiring_dashboard,
  },
  { path: "/Applications", name: "Applications", component: Applications },
  { path: "/Applicants/:id", name: "Applicants", component: Applicants },
  { path: "/Hiring_rounds", name: "Hiring_rounds", component: Hiring_rounds },
  {
    path: "/Hiring_round_assessment",
    name: "Hiring_round_assessment",
    component: Hiring_round_assessment,
  },
  {
    path: "/Manage_roundwise_assessments",
    name: "Manage_roundwise_assessments",
    component: Manage_roundwise_assessments,
  },
  {
    path: "/Hiring_round_panel",
    name: "Hiring_round_panel",
    component: Hiring_round_panel,
  },
  {
    path: "/Hiring_process",
    name: "Hiring_process",
    component: Hiring_process,
  },
  {
    path: "/Assign_assessments/:hp_id/:round_id",
    name: "Assign_assessments",
    component: Assign_assessments,
  },
  {
    path: "/Manage_hiring_panel/:p_id",
    name: "Manage_hiring_panel",
    component: Manage_hiring_panel,
  },
  {
    path: "/Assign_panel/:hp_id/:r_id",
    name: "Compose_panel",
    component: Assign_panel,
  },
  {
    path: "/Manage_shortlisted",
    name: "Compose_panel",
    component: Manage_shortlisted,
  },
  {
    path: "/Hiring_process_vac_assign",
    name: "Hiring_process_vac_assign",
    component: Hiring_process_vac_assign,
  },
  {
    path: "/Initiate_hiring_process/:vac_id/:id",
    name: "Initiate_hiring_process",
    component: Initiate_hiring_process,
  },

  /* Assessment forms */
  {
    path: "/Manage_assessment_forms",
    name: "Initiate_hiring_process",
    component: Manage_assessment_forms,
  },
  {
    path: "/View_panelist_score/:id/:a_id",
    name: "View_panelist_score",
    component: View_panelist_score,
  },

  /* Recruitment Module */
  {
    path: "/hrms_hiring_process",
    name: "Rm_hp_master",
    component: Rm_hp_master,
  },
  { path: "/hrms_rounds", name: "Rm_round_master", component: Rm_round_master },
  {
    path: "/hrms_assessments",
    name: "Rm_assessment_master",
    component: Rm_assessment_master,
  },
  { path: "/hrms_forms", name: "Rm_forms_master", component: Rm_forms_master },
  {
    path: "/hrms_additional_vacancy_list",
    name: "Rm_additional_vacancies",
    component: Rm_additional_vacancies,
  },
  {
    path: "/hrms_replacement_vacancy_list",
    name: "Rm_replacement_vacancies",
    component: Rm_replacement_vacancies,
  },
  {
    path: "/hrms_shortlisted_applicant_list",
    name: "Rm_manage_shortlisted",
    component: Rm_manage_shortlisted,
  },
  { path: "/hrms_hiring_panel", name: "Hiring_panel", component: Hiring_panel },
  {
    path: "/Compose_panel/:id",
    name: "Compose_panel",
    component: Compose_panel,
  },
  { path: "/View_panel/:id", name: "Compose_panel", component: View_panel },
  {
    path: "/initiate_assessments/:applicant_id",
    name: "Rm_initiate_assessments",
    component: Rm_initiate_assessments,
  },
  {
    path: "/manage_assessments/:applicant_id/:position_id/:hp_id/:r_id/:assessment_id/:form_id",
    name: "Rm_manage_assessment",
    component: Rm_manage_assessment,
  },
  {
    path: "/consolidatedCandidateSheet",
    name: "Rm_consolidated_candidate_score",
    component: Rm_consolidated_candidate_score,
  },
  {
    path: "/Employee_notifications/:id",
    name: "Rm_consolidated_candidate_score",
    component: Employee_notifications,
  },
  {
    path: "/Rm_selected_applicants",
    name: "Rm_selected_applicants",
    component: Rm_selected_applicants,
  },
  {
    path: "/Employee_logs/:id",
    name: "Employee_logs",
    component: Employee_logs,
  },
  {
    path: "/Verified_applicants",
    name: "Verified_applicants",
    component: Verified_applicants,
  },
  {
    path: "/Verify_applicant_docs/:id",
    name: "Verify_applicant_docs",
    component: Verify_applicant_docs,
  },
  {
    path: "/Hiring_approval_authority",
    name: "Hiring_approval_authority",
    component: Hiring_approval_authority,
  },
  {
    path: "/Grievance_dept",
    name: "Grievance_dept",
    component: Grievance_dept,
  },
  {
    path: "/Grievance_cat",
    name: "Grievance_cat",
    component: Grievance_cat,
  },
  {
    path: "/Employee_boarding",
    name: "Employee_boarding",
    component: Employee_boarding,
  },
  {
    path: "/Onboarding_docs",
    name: "Employee_boarding",
    component: Onboarding_docs,
  },
  {
    path: "/GrievanceList",
    name: "GrievanceList",
    component: GrievanceList,
  },
  {
    path: "/Appraiser_assign",
    name: "Appraiser_assign",
    component: Appraiser_assign,
  },
  {
    path: "/Resignation",
    name: "Resignation",
    component: Resignation,
  },
  {
    path: "/Retirement",
    name: "Retirement",
    component: Retirement,
  },
  {
    path: "/ExtensionRequested",
    name: "ExtensionRequested",
    component: ExtensionRequested,
  },
  {
    path: "/Termination",
    name: "Termination",
    component: Termination,
  },
  {
    path: "/Appraiser_assigned_list",
    name: "Appraiser_assigned_list",
    component: Appraiser_assigned_list,
  },
  {
    path: "/Emp_exit_interviews",
    name: "Emp_exit_interviews",
    component: Emp_exit_interviews,
  },
  {
    path: "/Emp_exit_interview/:id",
    name: "Emp_exit_interview",
    component: Emp_exit_interview,
  },
  {
    path: "/NoDuesHeads",
    name: "NoDuesHeads",
    component: NoDuesHeads,
  },
  {
    path: "/NoDuesResig",
    name: "NoDuesResig",
    component: NoDuesResig,
  },
  {
    path: "/NoDuesResignationInfo",
    name: "NoDuesResignationInfo",
    component: NoDuesResignationInfo,
  },
  {
    path: "/Manange_attendance_paidday",
    name: "Manange_attendance_paidday",
    component: Manange_attendance_paidday,
  },
];
export default routes;
