import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import FullScreenLoading from "react-fullscreen-loading";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

const Employee_manage_financial = (props) => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);

  // let emp_id = Apiconnect.decrypt(props.match.params.id);
  // let emp_id = props.match.params.id;

  const alert = useAlert();
  let history = useHistory();
  const [btn_data, setBtnId] = useState([]);
  const { btn_id } = btn_data;
  const [emp_id, setEmployeeId] = useState("");
  const [empList, setEmpList] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [view, setView] = useState(false);

  useEffect(() => {
    getAllEmpdata();
  }, []);

  const getAllEmpdata = () => {
    setFullScreen(true);
    Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
      setFullScreen(false);
    });
  };

  const [info2, setInfo2] = useState({
    employee_mst_id: emp_id,
    bank_name: "",
    ifsc_code: "",
    account_number: "",
    tax_borne_by_copany: 1,
    is_senior_citizen: 1,
    is_epf: 1,
    UAN_number: "",
    epf_number: "",
    pf_start_date: "",
    is_esi: 1,
    esi_number: "",
    dispencery: "",
    confirmation_date: "",
    joining_date: "",
    resign_date: "",
    leaving_date: "",
    promotion_date: "",
    transfer_date: "",
    pan: "",
    aadhaar: "",
    payment_mode: "",
    branch_name: "",
  });

  const { employee_mst_id, bank_name, ifsc_code, account_number, tax_borne_by_copany, is_senior_citizen, is_epf, UAN_number, epf_number, pf_start_date, is_esi, esi_number, dispencery, confirmation_date, joining_date, resign_date, leaving_date, promotion_date, transfer_date, pan, aadhaar, payment_mode, branch_name } = info2;

  const onInputChange2 = (e) => {
    if (is_epf === 1) {
      var req = "required";
      var req_mand = "*";
    }
    setInfo2({ ...info2, [e.target.name]: e.target.value });
  };

  // console.log(info2);

  const onSubmit = async (e) => {
    e.preventDefault();
    setFullScreen(true);
    if (btn_id > 0) {
      // console.log('in edit function.....');
      info2.employee_mst_id = emp_id;

      if (pfStartDate === null) {
        info2.pf_start_date = pfStartDate;
      } else {
        info2.pf_start_date = format(pfStartDate, "yyyy-MM-dd");
      }

      let info_enc2 = Apiconnect.encrypt_obj(info2);
      const inf_a2 = { enc: info_enc2, employee_id: employee_id, log_name: "Employee Financial Info",  company_id:Apiconnect.get_company_id()};
      Apiconnect.postData("employee_info/update", inf_a2).then((response) => {
        getItem();
        alert.success(response.data.message);
        // history.push(`/employee_manage_employement/${emp_id}`);
        setFullScreen(false);
      });
    } else {
      // console.log('in edit function.....');
      info2.employee_mst_id = emp_id;

      if (pfStartDate === null) {
        info2.pf_start_date = pfStartDate;
      } else {
        info2.pf_start_date = format(pfStartDate, "yyyy-MM-dd");
      }

      let info_enc2 = Apiconnect.encrypt_obj(info2);
      const inf_a2 = { enc: info_enc2, employee_id: employee_id };
      Apiconnect.postData("employee_info/create", inf_a2).then((response) => {
        getItem();
        alert.success(response.data.message);
        // history.push(`/employee_manage_employement/${emp_id}`);
        setFullScreen(false);
      });
    }
  };

  const getItem = (emp_id) => {
    if (emp_id > 0) {
      setInfo2({
        employee_mst_id: "",
        bank_name: "",
        ifsc_code: "",
        account_number: "",
        tax_borne_by_copany: 1,
        is_senior_citizen: 1,
        is_epf: 1,
        UAN_number: "",
        epf_number: "",
        pf_start_date: "",
        is_esi: 1,
        esi_number: "",
        dispencery: "",
        confirmation_date: "",
        joining_date: "",
        resign_date: "",
        leaving_date: "",
        promotion_date: "",
        transfer_date: "",
        pan: "",
        aadhaar: "",
        payment_mode: "",
        branch_name: "",
      });

      Apiconnect.getData(`employee_info/get/${emp_id}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);

        if (_xtract.length > 0) {
          setBtnId({ btn_id: 1 });

          setInfo2({
            employee_mst_id: _xtract[0].employee_mst_id,
            bank_name: _xtract[0].bank_name,
            ifsc_code: _xtract[0].ifsc_code,
            account_number: _xtract[0].account_number,
            tax_borne_by_copany: _xtract[0].tax_borne_by_copany,
            is_senior_citizen: _xtract[0].is_senior_citizen,
            is_epf: _xtract[0].is_epf,
            UAN_number: _xtract[0].UAN_number,
            is_esi: _xtract[0].is_esi,
            branch_name: _xtract[0].branch_name,
            epf_number: _xtract[0].epf_number,
            esi_number: _xtract[0].esi_number,
            dispencery: _xtract[0].dispencery,
            id: _xtract[0].id,
            pan: _xtract[0].pan,
            aadhaar: _xtract[0].aadhaar,
            payment_mode: _xtract[0].payment_mode,
          });

          if (_xtract[0].pf_start_date === null || _xtract[0].pf_start_date === "0000-00-00") {
            setPfStartDate(null);
          } else {
            setPfStartDate(new Date(_xtract[0].pf_start_date));
          }
        } else {
          setBtnId({ btn_id: null });
        }
        setFullScreen(false);
      });
      setView(true);
    }
  };

  const [pfStartDate, setPfStartDate] = useState(null);

  if (is_epf === "1" || is_epf === null) {
    var req = "required";
    var req_mand = "*";
  }

  const onUserChange = (e, value) => {
    setEmployeeId(value.id);
    if (value.id != "") {
      setFullScreen(true);
      getItem(value.id);
    } else {
      setView(false);
    }
  };

  return (
    <>
      <div id="content-page" className="content-page">
        {fullScreen ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
        <div className="container-fluid">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Employee Financial Info</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="row" style={{ marginBottom: "10px" }}>
                {/* <div className="col-md-3">
                            Employee : <br />
                            <select className="form-control" name="emp_id" onChange={e => onUserChange(e)}>
                                <option value="">Select Employee</option>
                                {empList.map((val, key) => {
                                    return (
                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                    );
                                })}
                            </select>
                        </div> */}
                <div className="col-md-5">
                  <Autocomplete options={empList} getOptionLabel={(option) => (option.first_name ? option.first_name : "") + " " + (option.last_name ? option.last_name : "") + " / " + (option.emp_code ? option.emp_code.slice(-4) : "") + " / " + (option.email ? option.email : "") + " / " + (option.phone ? option.phone : "")} style={{ width: 600 }} renderInput={(params) => <TextField {...params} placeholder="Select Employees" label="Employees" margin="normal" variant="outlined" fullWidth />} onChange={onUserChange} />
                </div>
              </div>

              {view ? (
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="border">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Bank Name <span className="required">*</span>
                          </label>
                          <input type="text" required className="form-control validate[required]" name="bank_name" placeholder="Bank Name" value={bank_name} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>IFSC Code</label>
                          <input
                            type="text"
                            className="
                                 form-control validate[required]"
                            name="ifsc_code"
                            placeholder="IFSC Code"
                            value={ifsc_code}
                            onChange={(e) => onInputChange2(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Bank A/C No <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            className="
                                 form-control validate[required]"
                            name="account_number"
                            placeholder="Account Number"
                            value={account_number}
                            onChange={(e) => onInputChange2(e)}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Branch Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            className="
                                 form-control validate[required]"
                            name="branch_name"
                            placeholder="Branch Name"
                            value={branch_name}
                            onChange={(e) => onInputChange2(e)}
                          />
                        </div>
                      </div>

                      <div className="col-md-3" style={{ display: "none" }}>
                        <label>Tax Borne By Company</label>
                        <select className="form-control" name="tax_borne_by_copany" value={tax_borne_by_copany} onChange={(e) => onInputChange2(e)}>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>PF Active Or Not</label>
                          <select className="form-control" name="is_epf" value={is_epf} onChange={(e) => onInputChange2(e)}>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            UAN <span className="required">{req_mand}</span>
                          </label>
                          <input type="text" required={req} className="form-control validate[required]" name="UAN_number" placeholder="UAN Number" value={UAN_number} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>EPF Number</label>
                          <input type="text" className="form-control validate[required]" name="epf_number" placeholder="EPF Number" value={epf_number} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>PF Start Date</label>
                          <DatePicker selected={pfStartDate} onChange={(e) => setPfStartDate(e)} className="form-control" dateFormat="yyyy-MM-dd" placeholderText="PF Start Date" name="pf_start_date" showMonthDropdown showYearDropdown scrollableYearDropdown scrollableMonthDropdown yearDropdownItemNumber={100} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>ESI Member</label>
                          <select className="form-control" name="is_esi" value={is_esi} onChange={(e) => onInputChange2(e)}>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>ESI No</label>
                          <input type="text" className=" form-control validate[required]" name="esi_number" placeholder="ESI No" value={esi_number} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Dispensary</label>
                          <input type="text" className=" form-control validate[required]" name="dispencery" placeholder="Dispensary" value={dispencery} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Payment Mode</label>
                          <select className="form-control" name="payment_mode" value={payment_mode} onChange={(e) => onInputChange2(e)}>
                            <option>Select Payment Mode</option>
                            <option value="cash">Cash</option>
                            <option value="cheque">Cheque</option>
                            <option value="bank_transfer">Bank Transfer</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            PAN<span className="required">*</span>
                          </label>
                          <input type="text" required className="form-control validate[required]" name="pan" placeholder="PAN" value={pan} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Aadhar Number <span className="required">*</span>{" "}
                          </label>
                          <input type="text" required className="datepicker form-control validate[required]" data-date-format="dd-mm-yyyy" name="aadhaar" placeholder="Aadhar Number" value={aadhaar} onChange={(e) => onInputChange2(e)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <button type="submit" name="id" value={btn_id} className="btn btn-primary mr-2">
                        {" "}
                        {btn_id ? "Update" : "Submit"}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Employee_manage_financial;
