import React from "react";

export const tableHeader = (
  <>
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Email</th>
      <th>Position</th>
      <th>Documents Verify</th>
      <th>Send Onboarding Docs</th>
      <th>Onboarding Docs Verify</th>
      <th>Onboard</th>
    </tr>
  </>
);

export const tableDocsHeader = (
  <>
    <tr className="text-center">
      <th>Document Name</th>
      <th>View</th>
      <th>Status</th>
      <th>Manage</th>
    </tr>
  </>
);
