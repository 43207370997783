import React from "react";
import { pageStyles } from "../../globalStyles";

const Card = ({ children }) => {
  return (
    <div className="col-md-12 mt-3">
      <div className="iq-card" style={pageStyles.main_container}>
        <div className="iq-card-body">
          <div className="row">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
