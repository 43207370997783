import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
const RatingS = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FAAF00',
  },
  '& .MuiRating-iconHover': {
    color: '#FAAF00',
  },
});

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Rm_manage_assessment = () => {
  const { applicant_id, position_id, hp_id, r_id, assessment_id, form_id } =
    useParams();


  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phn, setPhn] = useState("");
  const [hp_name, setHp_name] = useState("");
  const [round_cnt, setRound_cnt] = useState(0);
  const [rounds, setRounds] = useState([]);
  const [applied_for, setApplied_for] = useState("");
  const [m_date, setM_date] = useState("");
  const [m_from_time, setM_from_time] = useState("");
  const [m_to_time, setM_to_time] = useState("");
  const [m_remark, setM_remark] = useState("");
  const [selected, setSelected] = useState([]);
  const [allchecked, setallchecked] = useState(null);
  const [panel_list, setPanel_list] = useState([]);
  const [assessment_name, setAssessment_name] = useState("");
  const [form_name, setForm_name] = useState("");
  const [round_name, setRound_name] = useState("");
  const [scheduled_info, setScheduled_info] = useState([])
  const [panel_id, setPanel_id] = useState("")
  const [assessment_mst_id, setAssessment_mst_id] = useState("")
  const [assessment_st, setAssessment_st] = useState([])

  useEffect(() => {
    get_panel_list()
    get_applicant_info()
    get_scheduled_info()
    get_assessment_info()
    get_round_info()
    get_all_panelist_scores()
    assessment_status_getall()
}, [])

  const get_panel_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(
      `hrms_initiate_assessments/hiring_panel_list/${company_id}`
    ).then((res) => {
      setFullScreenLoader(false);
      setPanel_list(Apiconnect.decrypt_obj(res.data.data));
    });
  };

  const onInputChange_1 = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelected({
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const get_assessment_info = () => {
    Apiconnect.postData(`hrms_assessments/getbyid/${assessment_id}`).then(res => {
      let data = Apiconnect.decrypt_obj(res.data.data)
      setAssessment_name(data[0].ha_name);
      setForm_name(data[0].form_name);

    })
  }
  
  const get_round_info = () => {
    Apiconnect.postData(`hrms_rounds/getbyid/${r_id}`).then(res => {
      const data = Apiconnect.decrypt_obj(res.data.data)
      setRound_name(data[0].r_name)
    })
  }

  const get_applicant_info = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(
      `hrms_initiate_assessments/get_applicant_info/${applicant_id}`
    ).then((res) => {
      setFullScreenLoader(false);
      const data = Apiconnect.decrypt_obj(res.data.data);
      setName(data.name);
      setEmail(data.am_email);
      setPhn(data.am_phone_cell);
      setRound_cnt(data.round_details.length);
      setRounds(data.round_details);
      setHp_name(data.hp_name);
      setApplied_for(data.am_position);
    });
  };

  const schedule_assessment = () => {
    let ins_ob = {
      hsa_company_id: company_id,
      hsa_applicant_id: applicant_id,
      hsa_applied_position_id: position_id,
      hsa_hp_id: hp_id,
      hsa_round_id: r_id,
      hsa_assessment_id: assessment_id,
      hsa_form_id: form_id,
      hsa_panel_id: selected.hp_pk_id,
      hsa_date: m_date,
      hsa_from_time: m_from_time,
      hsa_to_time: m_to_time,
      hsa_applicant_name: name,
      hsa_applied_position_name: applied_for,
      hsa_hp_name: hp_name,
      hsa_round_name: round_name,
      hsa_assessment_name: assessment_name,
      hsa_form_name: form_name,
      hsa_added_by: emp_id,
      hsa_remark: m_remark,
    };

    if (
      m_date !== "" &&
      m_from_time !== "" &&
      m_to_time !== "" &&
      m_remark !== ""
    ) {
      if (
        ins_ob.hsa_panel_id !== "" ||
        ins_ob.hsa_panel_id !== undefined ||
        ins_ob.hsa_panel_id !== null
      ) {
        Apiconnect.postData(
          `hrms_initiate_assessments/schedule_assessments`,
          ins_ob
        ).then((res) => {
          alert.success("Assessment Scheduled");
          setModalIsOpen(false);
          setM_date("");
          setM_from_time("");
          setM_to_time("");
          setM_remark("");
          get_scheduled_info()
          get_all_panelist_scores()
        });
      } else {
        alert.error("Please Select Panel");
      }
    } else {
      alert.error("Empty Inputfields");
    }
  };

  const get_scheduled_info = () => {
    let data_ins = {
      hsa_company_id: company_id,
      hsa_applicant_id: applicant_id,
      hsa_applied_position_id: position_id,
      hsa_hp_id: hp_id,
      hsa_round_id: r_id,
      hsa_assessment_id: assessment_id,
    };
    Apiconnect.postData(
      `hrms_initiate_assessments/scheduled_info`,
      data_ins
    ).then((res) => {
      let data = Apiconnect.decrypt_obj(res?.data?.data);
      setScheduled_info(data);
      setPanel_id(data[0]?.hsa_panel_id)
      setAssessment_mst_id(data[0]?.hsa_pk_id)
    });
  };

  const [panelist_score, setPanelist_score] = useState([])
  const [panelist_info, setPanelist_info] = useState([])
  const [all_scores, setAll_scores] = useState([])
  const get_all_panelist_scores = () => {
    let data_ins = {
      company_id:company_id,
      applicant_id:applicant_id,
      position_id:position_id,
      hp_id:hp_id,
      round_id:r_id,
      assessment_id:assessment_id
    }
    Apiconnect.postData(`hrms_initiate_assessments/get_all_panelist_score`, data_ins).then(res => {
      let data = Apiconnect.decrypt_obj(res.data.data)
      setPanelist_score(data)
      setPanelist_info(data.panelist_info)
      // setAll_scores(data.panelist_score.length > 0 && data.panelist_score)
      if(data.panelist_score.length > 0){
        setAll_scores(data.panelist_score)
      }
    })
  }
  
  /* Interview_score */
  let applicant_name = all_scores.length > 0 ? (all_scores[0].hpis_applicant_name) : "NA"
  let applied_position = all_scores.length > 0 ? (all_scores[0].hpis_position) : "NA"
  let applicant_qualification_1 = all_scores.length > 0 ? (all_scores[0].hpis_academic_qualification) : "NA"
  let applicant_qualification_2 = all_scores.length > 0 ? (all_scores[0].hpis_additional_qualification) : "NA"
  let applicant_age = all_scores.length > 0 ? (all_scores[0].hpis_age_in_years) : "NA"
  let applicant_current_salary = all_scores.length > 0 ? (all_scores[0].hpis_current_salary) : "NA"
  let applicant_expected_salary = all_scores.length > 0 ? (all_scores[0].hpis_expected_salary) : "NA"
  let applicant_unit = all_scores.length > 0 ? (all_scores[0].hpis_unit) : "NA"
  let applicant_resume_source = all_scores.length > 0 ? (all_scores[0].hpis_resume_source) : "NA"
  let applicant_notice_period = all_scores.length > 0 ? (all_scores[0].hpis_notice_period + ' days') : "NA"
  let applicant_total_experience = all_scores.length > 0 ? (all_scores[0].hpis_total_experience + ' Years') : "NA"
  let years_of_service = all_scores.length > 0 ? (all_scores[0].hpis_years_of_service + ' Years') : "NA"
  let age_in_years= all_scores.length > 0 ? (all_scores[0].hpis_age_in_years + ' Years') : "NA"
  let interview_date = all_scores.length > 0 ? (moment(all_scores[0].hpis_interview_date).format('DD/MM/YYYY')) : "NA"

  let interview_scores_arr = all_scores.length > 0 && all_scores.map(item => {
    return(item.hpis_percentage_score)
  })
  let avg_score = interview_scores_arr.length > 0 ? ((interview_scores_arr.reduce((a, b) => {
    return (
      Number(a) + Number(b)
    )
  })) / all_scores.length).toFixed(2) : "NA"
  
  /* Interview Leadership Score */
  let applicant_name_leadership = all_scores.length > 0 ? (all_scores[0].hpils_applicant_name) : "NA"
  let applied_position_leadership = all_scores.length > 0 ? (all_scores[0].hpils_position) : "NA"
  let applicant_qualification_1_leadership = all_scores.length > 0 ? (all_scores[0].hpils_academic_qualification) : "NA"
  let applicant_qualification_2_leadership = all_scores.length > 0 ? (all_scores[0].hpils_additional_qualification) : "NA"
  let applicant_age_leadership = all_scores.length > 0 ? (all_scores[0].hpils_age_in_years) : "NA"
  let applicant_current_salary_leadership = all_scores.length > 0 ? (all_scores[0].hpils_current_salary) : "NA"
  let applicant_expected_salary_leadership = all_scores.length > 0 ? (all_scores[0].hpils_expected_salary) : "NA"
  let applicant_resume_source_leadership = all_scores.length > 0 ? (all_scores[0].hpils_resume_source) : "NA"
  let applicant_notice_period_leadership = all_scores.length > 0 ? (all_scores[0].hpils_notice_period + ' days') : "NA"
  let applicant_total_experience_leadership = all_scores.length > 0 ? (all_scores[0].hpils_total_experience + ' Years') : "NA"
  let years_of_service_leadership = all_scores.length > 0 ? (all_scores[0].hpils_years_of_service + ' Years') : "NA"
  let age_in_years_leadership = all_scores.length > 0 ? (all_scores[0].hpils_age_years + ' Years') : "NA"
  let interview_date_leadership  = all_scores.length > 0 ? (moment(all_scores[0].hpils_interview_date).format('DD/MM/YYYY')) : "NA"

  let interview_scores_arr_leadership = all_scores.length > 0 && all_scores.map(item => {
    return(item.hpils_score_percentage)
  })
  let avg_score_leadership = interview_scores_arr_leadership.length > 0 ? ((interview_scores_arr_leadership.reduce((a, b) => {
    return (
      Number(a) + Number(b)
    )
  })) / all_scores.length).toFixed(2) : "NA"

  /* Demo Class Score */
  let applicant_name_demo = all_scores.length > 0 ? (all_scores[0].hpdcs_applicant_name) : "NA"
  let applied_position_demo = all_scores.length > 0 ? (all_scores[0].hpdcs_position) : "NA"
  let interview_date_demo  = all_scores.length > 0 ? (moment(all_scores[0].hpdcs_interview_date).format('DD/MM/YYYY')) : "NA"
  let class_section = all_scores.length > 0 ? (all_scores[0].hpdcs_class_sec) : "NA"
  let class_subject = all_scores.length > 0 ? (all_scores[0].hpdcs_subject) : "NA"
  let class_topic = all_scores.length > 0 ? (all_scores[0].hpdcs_topic) : "NA"

  let total_score = all_scores.length > 0 && all_scores.map(item => {
    return(item.hpdcs_total_score)
  })
  let total = total_score.length > 0 ? ((total_score.reduce((a, b) => {
    return (
      Number(a) + Number(b)
    )
  })) / all_scores.length).toFixed(2) : "NA"
  
  /* Test Score */
  let applicant_name_test = all_scores.length > 0 ? (all_scores[0].hpts_applicant_name) : "NA"
  let applied_position_test = all_scores.length > 0 ? (all_scores[0].hpts_position) : "NA"
  let applicant_qualification_1_test = all_scores.length > 0 ? (all_scores[0].hpts_qualification) : "NA"
  let applicant_qualification_2_test = all_scores.length > 0 ? (all_scores[0].hpts_additional_qualification) : "NA"
  let applicant_age_test = all_scores.length > 0 ? (all_scores[0].hpts_age_in_years) : "NA"
  let applicant_current_salary_test = all_scores.length > 0 ? (all_scores[0].hpts_current_salary) : "NA"
  let applicant_expected_salary_test = all_scores.length > 0 ? (all_scores[0].hpts_salary_expected) : "NA"
  let applicant_resume_source_test = all_scores.length > 0 ? (all_scores[0].hpts_resume_source) : "NA"
  let applicant_notice_period_test = all_scores.length > 0 ? (all_scores[0].hpts_notice_period + ' days') : "NA"
  let applicant_total_experience_test = all_scores.length > 0 ? (all_scores[0].hpts_total_experience + ' Years') : "NA"
  let years_of_service_test = all_scores.length > 0 ? (all_scores[0].hpts_years_of_service + ' Years') : "NA"
  let age_in_years_test = all_scores.length > 0 ? (all_scores[0].hpts_age_years + ' Years') : "NA"
  let interview_date_test  = all_scores.length > 0 ? (moment(all_scores[0].hpts_interview_date).format('DD/MM/YYYY')) : "NA"

  let interview_scores_arr_test = all_scores.length > 0 && all_scores.map(item => {
    return(item.hpts_score_percentage)
  })
  let avg_score_test = interview_scores_arr_leadership.length > 0 ? ((interview_scores_arr_leadership.reduce((a, b) => {
    return (
      Number(a) + Number(b)
    )
  })) / all_scores.length).toFixed(2) : "NA"

  // console.log(applicant_current_salary_test)

  let panelist_score_status = panelist_info.every((val) => val.score_status === 1)

  const assign_round_assessment_to_completed = () => {
    let data_ins = {
        hrs_company_id: company_id,
        hrs_applicant_id: applicant_id,
        hrs_position_id:position_id,
        hrs_hp_id:hp_id,
        hrs_round_id:r_id,
        hrs_assessmnet_id:assessment_id,
        hrs_form_id:form_id
    }
    Apiconnect.postData(`hiring_application/round_assessment_status_create`, data_ins).then((res) => {
        alert.success(res.data.message)
        assessment_status_getall()
    })
}

const assessment_status_getall = () => {
      let data_ins = {
        hrs_company_id: company_id,
        hrs_applicant_id: applicant_id,
        hrs_position_id:position_id,
        hrs_hp_id:hp_id,
        hrs_round_id:r_id,
        hrs_assessmnet_id:assessment_id,
        hrs_form_id:form_id
    }
  Apiconnect.postData(`hiring_application/round_assessment_status`, data_ins).then(res => {
    let data = Apiconnect.decrypt_obj(res.data.data)
    setAssessment_st(data[0]?.hrs_status
      )
  })
}

  

  return (
    <div className="content-page main_bg">
      {fullScreenLoader ? (
        <FullScreenLoading loading loaderColor="#3498db" />
      ) : null}
      <div className="container-fluid">
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div className="iq-card">
            <div className="iq-card-header card_head">
              <h5 className="text-white">
                Schedule <span className="font-weight-bold">{assessment_name}</span> 
                For <span className="font-weight-bold">{name}</span>
              </h5>
            </div>
            <div className="iq-card-body ">
              <div className="row">
                <div className="col-md-3 col-sm-4">
                  <label>Select Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="m_date"
                    value={m_date}
                    onChange={(e) => setM_date(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-4">
                  <label>From Time</label>
                  <input
                    className="form-control"
                    type="time"
                    name="m_from_time"
                    value={m_from_time}
                    onChange={(e) => setM_from_time(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-4">
                  <label>To Time</label>
                  <input
                    className="form-control"
                    type="time"
                    name="m_to_time"
                    value={m_to_time}
                    onChange={(e) => setM_to_time(e.target.value)}
                  />
                </div>
                <div className="col-md-3 col-sm-4">
                  <label>Remark</label>
                  <input
                    className="form-control"
                    type="text"
                    name="m_remark"
                    value={m_remark}
                    onChange={(e) => setM_remark(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 mt-3">
                  <div className="table-responsive">
                    <table className="table table-bordered normal_shadow">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center"></th>
                          <th className="text-center">#</th>
                          <th className="text-center">Panel Name</th>
                          <th className="text-center">Panelist Count</th>
                          <th className="text-center">Asigned Panelists</th>
                        </tr>
                      </thead>
                      <tbody>
                        {panel_list.map((item, index) => {
                          let ck = Object.keys(selected).find(function (
                            element
                          ) {
                            return selected[element] == item.hpp_pk_id;
                          });

                          var ck1 = "";
                          if (ck !== undefined) {
                            ck1 = true;
                          }

                          return (
                            <tr key={item.hpp_pk_id}>
                              <td>
                                {item.panel_members.length > 0 && (
                                  <input
                                    type="radio"
                                    name="hp_pk_id"
                                    value={item.hpp_pk_id}
                                    checked={ck1}
                                    onChange={(e) => onInputChange_1(e)}
                                  />
                                )}
                              </td>
                              <td>{index + 1}</td>
                              <td>{item.hpp_name}</td>
                              <td className="text-center">
                                {item.panel_members.length}
                              </td>
                              <td>
                                {item.panel_members.length > 0 ? (
                                  item.panel_members.map((item1, index1) => {
                                    return (
                                      <span
                                        className="badge badge-pill badge-success badge-sm m-2"
                                        key={index1}
                                      >
                                        {item1.emp_name}
                                      </span>
                                    );
                                  })
                                ) : (
                                  <span className="badge badge-pill badge-warning badge-sm m-2">
                                    No Panelist Assiged
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={schedule_assessment}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="iq-card main_container">
          <div className="iq-card-header card_head">
            <h4 className="text-white">
              Manage Assessment For {name} (<span className="font-weight-bold">{applied_for}</span>)
              {assessment_st == 1 && <span className="m-3 badge badge-small badge-success">Assessment Completed</span>}
            </h4>
            <Link to={`/initiate_assessments/${applicant_id}`}>
              <button className="btn btn-sm btn-info">Back</button>
            </Link>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12">
                {scheduled_info.length > 0 ? (
                  <span className="badge badge-pill badge-success float-right">
                    Scheduled
                  </span>
                ) : (
                  <button className="btn btn-sm btn-primary float-right" onClick={() => setModalIsOpen(true)}>Schedule</button>
                )}
              </div>
            </div>
            <div className="row mt-3">
            {scheduled_info.length > 0 && 
            <>
            <div className="col-md-12 table-responsive">
              <table className="table table-bordered normal_shadow">
                <thead className="thead-light">
                  <tr>
                    <th  className="text-center font-weight-bold">Assessment Name</th>
                    <th className="text-center font-weight-bold">Assessment Date</th>
                    <th className="text-center font-weight-bold">From Time</th>
                    <th className="text-center font-weight-bold">To Time</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    scheduled_info.map((item, index) => {
                      let date = moment(item.hsa_date).format("DD/MM/YYYY")
                      let from_time = moment(item.hsa_from_time, "HH:mm:ss").format("hh:mm A")
                      let to_time = moment(item.hsa_to_time, "HH:mm:ss").format("hh:mm A")
                      return(
                        <tr key={index}>
                          <td className="text-center">{item.hsa_assessment_name}</td>
                          <td className="text-center">{date}</td>
                          <td className="text-center">{from_time}</td>
                          <td className="text-center">{to_time}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            </>
              
          }
            </div>
            <div className="row mt-3"> {/* ----->>>> panelist_score*/}
              {panelist_info.length > 0 && 
                <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center font-weight-bold" style={{width:"12%"}}>#</th>
                        <th className="text-center font-weight-bold" style={{width:"23%"}}>Panelist Name</th>
                        <th className="text-center font-weight-bold" style={{width:"23%"}}>Panelist Designation</th>
                        <th className="text-center font-weight-bold" style={{width:"23%"}}>Panelist Email</th>
                        <th className="text-center font-weight-bold" style={{width:"23%"}}>Score Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                        {
                          panelist_info.map((item, index) => {

                            let status = ""
                            if(item.score_status == 1){
                              status = <span className="badge badge-pill badge-success">Assessment Completed</span>
                            }else{
                              status = <span className="badge badge-pill badge-warning">Pending</span>
                            }
                            return(
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                                <td>{item.email}</td>
                                <td className="text-center">{status}</td>
                              </tr>
                            )
                          })
                        }
                      
                    </tbody>
                  </table>
                </div>
              </div>}
              {
                panelist_info.length > 0 &&
                panelist_score_status === true && <div className="col-md-12">
                {assessment_st !==1 && <button className="btn btn-sm btn-primary float-right" onClick={assign_round_assessment_to_completed}>Complete Assessment</button>}
                </div>
              }
            </div> 
            
            <div className="row mt-3">
              <div className="col-md-12">
                {form_id == 1 &&
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th colSpan={20} className="font-weight-bold text-center">Test Score</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr>
                          <td colSpan={1} style={{width: '16.66%'}} className="font-weight-bold text-muted">Name:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applicant_name_test}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Date of Interview:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{interview_date_test}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Position:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applied_position_test}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Academic Qualification:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_qualification_1_test}</span></td>
                          {/* <td className="font-weight-bold text-muted">Total/Relevant Experience:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_total_experience_test}</span></td> */}
                          {/* <td className="font-weight-bold text-muted">Unit:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_unit_test}</span></td> */}
                          <td className="font-weight-bold text-muted">Current Salary:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_current_salary_test}</span></td>
                          <td className="font-weight-bold text-muted">Expected Salary:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_expected_salary_test}</span></td>
                        </tr>
                       {/* <tr>
                          <td className="font-weight-bold text-muted">Additional Qualification:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_qualification_2_test}</span></td>
                         
                        </tr> */}
                       <tr>
                          {/* <td className="font-weight-bold text-muted">Age in Years/DOB:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_age_test}</span></td> */}
                          <td className="font-weight-bold text-muted">Notice Period:</td>
                          <td colSpan={2}><span className="badge badge-pill badge-warning">{applicant_notice_period_test}</span></td>
                          <td className="font-weight-bold text-muted">Resume Source:</td>
                          <td colSpan={2}><span className="badge badge-pill badge-warning">{applicant_resume_source_test}</span></td>
                        </tr>
                        <tr className="table-white">
                          <th colSpan={6} style={{width: '100%'}} className="text-center  font-weight-bold text-muted">
                            <span className="badge badge-pill badge-success">Interview Scores-Evaluation Criteria</span>
                          </th>
                        </tr>
                        {/* <tr>
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted"></td>
                        </tr> */}
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        {/* panelist_name */}
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Questions</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="number" className="form-control text-center" value={item.hpts_total_qsns} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Marks</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="number" className="form-control text-center" value={item.hpts_total_marks} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Attempted</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="number" className="form-control text-center" value={item.hpts_total_attempted} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Correct</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="number" className="form-control text-center" value={item.hpts_total_correct} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Incorrect</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                   <input type="number" className="form-control text-center" value={item.hpts_total_incorrect} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Obtained</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="number" className="form-control text-center" value={item.hpts_total_obtained} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Percentage On Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control text-center" value={`${item.hpts_percentage_on_total} %`} />
                                </td>
                              )
                            })
                          }
                        </tr>
                    </tbody>
                  </table>
                }
                {form_id == 2 &&
                  (all_scores.length > 0 && <table className="table table-bordered normal_shadow border-3">
                    <thead className="thead-light">
                      <tr>
                        <th className="font-weight-bold text-center" colSpan={6}>Interview Score</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr>
                          <td colSpan={1} style={{width: '16.66%'}} className="font-weight-bold text-muted">Name:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applicant_name}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Date of Interview:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{interview_date}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Position:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applied_position}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Academic Qualification:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_qualification_1}</span></td>
                          <td className="font-weight-bold text-muted">Total/Relevant Experience:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_total_experience}</span></td>
                          <td className="font-weight-bold text-muted">Unit:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_unit}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Additional Qualification:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_qualification_2}</span></td>
                          <td className="font-weight-bold text-muted">Current Salary:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_current_salary}</span></td>
                          <td className="font-weight-bold text-muted">Expected Salary:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_expected_salary}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Age in Years/DOB:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_age}</span></td>
                          <td className="font-weight-bold text-muted">Notice Period:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_notice_period}</span></td>
                          <td className="font-weight-bold text-muted">Resume Source:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_resume_source}</span></td>
                        </tr>
                        <tr className="table-white">
                          <th colSpan={6} style={{width: '100%'}} className="text-center  font-weight-bold text-muted">
                            <span className="badge badge-pill badge-success">Interview Scores-Evaluation Criteria</span>
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">Scale 1-5</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        {/* panelist_name */}
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Ability to Communicate/Listen</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_communication} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Critical Thinking</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_critical_thinking} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Subject Knowledge</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_sub_knowledge} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Exposure to the job Profile</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_job_exposure} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Empathy</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpaamps_empathy} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Expression</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_expression} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Body Language</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_body_lang} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Attitude towards job</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_attitude} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Awareness of latest systems</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_awerness} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Network with Relevant people</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_networking} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Presence On Social Media </td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_social_presence} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Parent Connect</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpis_parent_connect} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted " colSpan={1} style={{width: '20%'}}>Total Score of Interaction out of 60</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  {item.hpis_total_score} 
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted " colSpan={1} style={{width: '20%'}}>Percentage Score</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  {item.hpis_percentage_score} %
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted " colSpan={1} style={{width: '20%'}}>Remark</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-secondary">{item.hpis_remark}</span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>No. of years of Service</td>
                          <td className="font-weight-bold text-muted text-center" colSpan={5} style={{width: '80%'}}>{years_of_service}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Age in Years</td>
                          <td className="font-weight-bold text-muted text-center" colSpan={5} style={{width: '80%'}}>{age_in_years}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Average Score</td>
                          <td className="font-weight-bold text-muted text-center" colSpan={5} style={{width: '80%'}}>{avg_score} %</td>
                        </tr>
                    </tbody>
                  </table>)
                }
                {form_id == 3 &&
                  (all_scores.length > 0 && <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th colSpan={20} className="font-weight-bold text-center">Interview Leadership Scores</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr>
                          <td colSpan={1} style={{width: '16.66%'}} className="font-weight-bold text-muted">Name:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applicant_name_leadership}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Date of Interview:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{interview_date_leadership}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Position:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applied_position_leadership}</span></td>
                        </tr>
                       <tr>
                          <td style={{width: '25%'}} colSpan={2} className="font-weight-bold text-muted">Academic Qualification:</td>
                          <td style={{width: '25%'}}><span className="badge badge-pill badge-warning">{applicant_qualification_1_leadership}</span></td>
                          <td style={{width: '25%'}} colSpan={2} className="font-weight-bold text-muted">Total/Relevant Experience:</td>
                          <td style={{width: '25%'}}><span className="badge badge-pill badge-warning">{applicant_total_experience_leadership}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Additional Qualification:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_qualification_2_leadership}</span></td>
                          <td className="font-weight-bold text-muted">Current Salary:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_current_salary_leadership}</span></td>
                          <td className="font-weight-bold text-muted">Expected Salary:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_expected_salary_leadership}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Age in Years/DOB:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_age_leadership}</span></td>
                          <td className="font-weight-bold text-muted">Notice Period:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_notice_period_leadership}</span></td>
                          <td className="font-weight-bold text-muted">Resume Source:</td>
                          <td><span className="badge badge-pill badge-warning">{applicant_resume_source_leadership}</span></td>
                        </tr>
                        <tr className="table-white">
                          <th colSpan={6} style={{width: '100%'}} className="text-center  font-weight-bold text-muted">
                            <span className="badge badge-pill badge-success">Interview Leadership Scores-Evaluation Criteria</span>
                          </th>
                        </tr>
                        
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted">Criteria Of Judgement</td>
                          <td colSpan={5} style={{width: '80%'}} className="text-center font-weight-bold text-muted">Scale 1-5</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        {/* panelist_name */}
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Ability to Communicate/Listen</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_communication} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Critical Thinking</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_critical_thinking} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Understanding the school Processess</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_critical_thinking} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Digital Understanding</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_digital} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Empathy</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_empathy} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>International Exposure</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_international_exposure} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Vision</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_vision} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Liasioning with District Officials</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_liasioning} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Parent Connect</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_parent_connect} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Education World</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_edu_world} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>People Management</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_ppl_management} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Awareness about Cambridge or IB</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_awerness_ib} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Presence On Social Media </td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpils_social_presence} defaultValue={0} max={5}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted " colSpan={1} style={{width: '20%'}}>Total Score of Interaction out of 65</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  {item.hpils_total_score} 
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted " colSpan={1} style={{width: '20%'}}>Percentage Score</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  {item.hpils_score_percentage} %
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted " colSpan={1} style={{width: '20%'}}>Remark</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-secondary">{item.hpils_remark}</span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>No. of years of Service</td>
                          <td className="font-weight-bold text-muted text-center" colSpan={5} style={{width: '80%'}}>{years_of_service_leadership}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Age in Years</td>
                          <td className="font-weight-bold text-muted text-center" colSpan={5} style={{width: '80%'}}>{age_in_years_leadership}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Average Score</td>
                          <td className="font-weight-bold text-muted text-center" colSpan={5} style={{width: '80%'}}>{avg_score_leadership} %</td>
                        </tr>
                    </tbody>
                  </table>)
                }
                {form_id == 4 &&
                  all_scores.length > 0 && (<table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th colSpan={20} className="font-weight-bold text-center">Demo Class Score</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr>
                          <td colSpan={1} style={{width: '16.66%'}} className="font-weight-bold text-muted">Name:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applicant_name_demo}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Date of Interview:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{interview_date_demo}</span></td>
                          <td colSpan={1} style={{width: '16.66%'}}  className="font-weight-bold text-muted">Position:</td>
                          <td colSpan={1} style={{width: '16.66%'}} ><span className="badge badge-pill badge-warning">{applied_position_demo}</span></td>
                        </tr>
                       <tr>
                          <td className="font-weight-bold text-muted">Class & Section:</td>
                          <td><span className="badge badge-pill badge-warning">{class_section}</span></td>
                          <td className="font-weight-bold text-muted">Subject:</td>
                          <td><span className="badge badge-pill badge-warning">{class_subject}</span></td>
                          <td className="font-weight-bold text-muted">Topic:</td>
                          <td><span className="badge badge-pill badge-warning">{class_topic}</span></td>
                        </tr>
                        <tr className="table-white">
                          <th colSpan={6} style={{width: '100%'}} className="text-center  font-weight-bold text-muted">
                            <span className="badge badge-pill badge-success">Demo Class Scores-Evaluation Criteria</span>
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">Scale 1-4</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        {/* panelist_name */}
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">1. Instructional Planning</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Clearly identified learning objectives/outcomes</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_planning_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Lesson is well planned, structured & flows sequentially</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_planning_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. Planned for varied instructional and assessment strategies aligned to identified learning outcomes with optimum and creative usage of technological aids/ other resources.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_planning_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_planning_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">2. Disposition in the class room</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Began class with a warm-up and connected with the previous knowledge</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_disposition_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Teacher encourages understanding and co-operation with students . Brings out the best in student through affirmation.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_disposition_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. Practices proactive discipline. Good behavior and effort of student praised & prompt action taken to address poor behavior</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_disposition_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_disposition_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">3. Clarity and Specificness of Instructions & Explanations</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Related the concept with real life situations by using modern and innovative methods</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_clarity_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Develops an environment that nurtures creative thinking and encourgaes Questioning .</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_clarity_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. Instructions and explainations are clear and specific . The teacher involves all students listens to them and responds appropriately</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_clarity_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_clarity_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">4. Questioning Skills</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Asked questions to the whole class and provided helpful prompts with adequate response time</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_questioning_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Teacher is aware of different levels of questions (Blooms Taxonomy) easy to difficult. HOTS</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_questioning_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. Managed wrong responses appropriately for better concept clarity</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_questioning_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_questioning_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">5. Learner’s Engagement</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Teacher Encouraged collaboration and active participation of the learners and manages students well.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_engagement_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Used positive reinforcement and respectful behaviour for student connect to make learning an enjoyable experience</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_engagement_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. Ensured that class room was neat and orderly and all the required resources were ready to be used and properly displayed before the initiation of class.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_engagement_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_engagement_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">6. Assessment</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Used appropriate assignments, supportive reading documents in terms of relevence quantity and quality.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_assessment_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Used varied and quality tools for checking understanding.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_assessment_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. The teacher provides regular, clear and accurate feedback to the students on their performance</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_assessment_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_assessment_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">7. Closure</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Concluded the class with some interesting observations and questions</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_closure_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Gave assignments/ activities to re-enforce the concepts</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_closure_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. The lesson is collectively reviewed at the end. With learning outcomes.</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_closure_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_closure_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr className="table-info">
                          <td colSpan={6} style={{width: '80%'}} className="text-center font-weight-bold text-muted">8. Teamwork /Beyond Classroom</td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>1. Submission of Lesson plans and Question Papers in time or not</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_tw_1} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>2. Quality of Correction of Note books and Submissions</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_tw_2} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>3. Seeks Opportunities to work and collaborate as a way to develop experience and knowledge</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_tw_3} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted text-center" colSpan={1} style={{width: '20%'}}>Total</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_tw_total} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td colSpan={1} style={{width: '20%'}} className="text-center font-weight-bold text-muted"></td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <span className="badge badge-pill badge-warning">
                                    <span className="text-muted">Panelist-{index+1}</span><br />
                                    {item.panelist_name}
                                  </span>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted table-primary" colSpan={1} style={{width: '20%'}}>Is the teacher helping in documenting the soft copy of the Lesson plans and the question banks</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <RatingS name='hpils_communication' value={item.hpdcs_documentation} defaultValue={0} max={4}/>
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Total Score Out Of 100</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <input type="text" className="form-control" value={item.hpdcs_total_score} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Teacher's Reflection</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <textarea type="text" className="form-control" value={item.hpdcs_teachers_reflection} />
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-muted" colSpan={1} style={{width: '20%'}}>Observer's Comment</td>
                          {
                            all_scores.map((item, index) => {
                              return(
                                <td className="text-center" key={index} colSpan={5/all_scores.length} style={{width: `${80/all_scores.length}%`}}>
                                  <textarea type="text" className="form-control" value={item.hpdcs_comment} />
                                </td>
                              )
                            })
                          }
                        </tr>
                    </tbody>
                  </table>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_manage_assessment;
