import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert"; // Import
import IdCard_view from './IdCard_view.js';
import IdCard_view2 from './IdCard_view2.js';
import FullScreenLoading from 'react-fullscreen-loading';
import { PDFDownloadLink,PDFViewer } from '@react-pdf/renderer';

function IdCard(props) {
  let history = useHistory();

  const local_user = JSON.parse(localStorage.getItem('user'));
  const [fullScreenLoader, setFullScreenLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState('')
  const [List, setEmployeeList] = useState([]);
  const [empCount, setempCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [allchecked, setallchecked] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [logo, setLogo] = useState(null);
  



  useEffect(() => {
    getEmployeeList_new("50", "0", "")
    // getEmpCount("", '');
    getCompanyDetails()
  }, []);

  const getCompanyDetails = () => {
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        // console.log('company', _xtract.reverse())
        setCompanyData(_xtract.reverse());
      }
    );
  }
  const getEmployeeList_new = (limit, offset, search) => {
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
    };
    Apiconnect.postData(`employee/getallemployeelistIdcard`, data).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        // console.log('test',_xtract.reverse())
        setEmployeeList(_xtract.reverse());
        setLogo(response.data.logo)
        setFullScreenLoader(false)
      }
    );
  };

  // const handlePageChange = (pageNumber) => {
  //   setFullScreenLoader(true)
  //   setActivePage(pageNumber);
  //   let offset = (pageNumber - 1) * 50;
  //   let limit = 50;
  //   getEmployeeList_new(limit, offset, "");
  // };


  // const getEmpCount = (search, dept_id, month, year) => {

  //   const data = {
  //     search: search,
  //     company_id: Apiconnect.get_company_id(),
  //   };
  //   Apiconnect.postData(
  //     `employee/getEmpSearchcountIdcard`, data
  //   ).then((response) => {
  //     let _xtract = Apiconnect.decrypt_obj(response.data.data);
  //     setempCount(_xtract[0].cnt);
  //     console.log('cnt', _xtract[0].cnt)
  //   });
  // };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let offset = (activePage - 1) * 50;
      let limit = 50;
      setFullScreenLoader(true)
      getEmployeeList_new(limit, offset, searchTerm);
      // const data = {
      //   search: searchTerm,
      //   company_id: Apiconnect.get_company_id(),
      // };

      // Apiconnect.postData(
      //   `employee/getEmpSearchcountIdcard`, data
      // ).then((response) => {
      //   let _xtract = Apiconnect.decrypt_obj(response.data.data);
      //   setempCount(_xtract[0].cnt);
      // });
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])


  const [displayPdf, setDisplayPdf] = useState(false)
  const idCard = () => {
    if (selectedEmp != "") {
      setDisplayPdf(true)

      // const pdf = new jsPDF('p', 'pt',[500,500]);
      // const string = renderToString(<IdCard_view data={selectedEmp} companyData={companyData}/>);
      // pdf.html(string);   
      // setTimeout(() => {
      //     pdf.save("pdf");
      //   }, "1000")


    } else {
      confirmAlert({
        title: "Select Employee",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    }
  }

  const onInputChange2 = (e, val) => {

    setallchecked(null);
    setDisplayPdf(false)

    if (e.target.checked === true) {
      setSelectedEmp([
        ...selectedEmp,
        val
      ]);
    } else {
      const index = selectedEmp.findIndex(dd => dd.id === val.id)
      if (index > -1) {
        selectedEmp.splice(index, 1);
      }
      // console.log(selectedEmp)
      setSelectedEmp([...selectedEmp])
    }
  };


  const sellectAll = (e) => {
    setDisplayPdf(false)
    if (e.target.checked === true) {
      setallchecked("checked");
      setSelectedEmp(List);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
    }
  };

  // console.log('List',List)

  // const back = () => {
  //   setDisplayPdf(false)
  //   getEmployeeList_new("50", "0", "")
  //   getEmpCount("", '');

  // }

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="iq-card">

                {fullScreenLoader
                  ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                  )

                  : null
                }

                {/* {
                  displayPdf
                    ?
                    <div className='row' style={{ margin: '10px', paddingTop: '20px' }}>
                      <button
                        onClick={() => {
                          back();
                        }}
                        className="btn btn-sm btn-info mr-2"
                      >
                        Back
                      </button>
                    </div>
                    : null
                } */}

                {
                  // displayPdf
                  //   ? (
                  //     <>
                  //       <PDFViewer width={1200} height={400} showToolbar={false}>
                  //         <IdCard_view data={selectedEmp} logo={logo} companyData={companyData} />
                  //       </PDFViewer>

                  //       <PDFDownloadLink
                  //         document={<IdCard_view data={selectedEmp} logo={logo} companyData={companyData} />}
                  //         fileName='test'
                  //       >
                  //         {({ blob, url, loading, error }) =>
                  //           loading ? "Loading..." : "Download Invoice"
                  //         }
                  //       </PDFDownloadLink>
                  //       {/* <IdCard_view2 empListAll={selectedEmp} companyData={companyData} /> */}
                  //     </>)
                  //   :
                  <>
                    <div className="iq-card-header d-flex justify-content-between model_head">
                      <div className="iq-header-title">
                        <h4 className="card-title ">Id Card</h4>
                      </div>
                    </div>
                    <div className="iq-card-body">
                      <div style={{ padding: "10px", textAlign: "right" }}>
                        <div>
                          {
                            displayPdf ? <PDFDownloadLink
                              document={<IdCard_view data={selectedEmp} logo={logo} companyData={companyData} />}
                              fileName='IdCards'
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? <FullScreenLoading loading loaderColor="#3498db" /> : "Download IdCard"
                              }
                            </PDFDownloadLink> : null}
                          <button
                            onClick={() => {
                              idCard();
                            }}
                            style={{ marginLeft: '20px' }}
                            className="btn btn-sm btn-primary mr-2"
                          >
                            Id Card
                          </button>

                          <input
                            type="text"
                            placeholder="Search here..."
                            name="search"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            autoComplete='off'
                          />
                        </div>

                      </div>

                      <div class="table-responsive" style={{ height: '530px', overflowY: 'auto' }}>
                        <table className="table table-striped table-hover">
                          <thead className="">
                            <tr>
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  name="select_all"
                                  checked={allchecked}
                                  onChange={(e) => sellectAll(e)}
                                />
                              </th>
                              <th scope="col">#</th>
                              <th scope="col">Emp Code</th>
                              <th scope="col">Name</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {
                              List.length < 0
                                ? (
                                  <tr>
                                    <th colSpan={4}>No Data Found</th>
                                  </tr>
                                )
                                : List.map((val, key) => {

                                  const ck = selectedEmp.find(data => data.id === val.id)

                                  var ck1 = "";
                                  if (ck !== undefined) {
                                    ck1 = true;
                                  }

                                  return (
                                    <tr key={key}>
                                      <th scope="row">
                                        <input
                                          type="checkbox"
                                          name={val.id}
                                          value={val.id}
                                          checked={ck1}
                                          onChange={(e) => onInputChange2(e, val)}

                                        />
                                      </th>
                                      <th scope="row">{key + 1}</th>
                                      <td>{val.emp_code}</td>
                                      <td>
                                        {val.first_name} {val.last_name}
                                      </td>
                                    </tr>
                                  );
                                })
                            }
                          </tbody>
                        </table>
                      </div>
                      {/* <div>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={50}
                          totalItemsCount={empCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div> */}
                    </div>
                  </>
                }
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default IdCard