import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import {Link} from 'react-router-dom'


const Hiring_process = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 
    
    
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [name, setName] = useState([])
    const [editId, setEditId] = useState("")
    const [list, setList] = useState([])

    useEffect(() => {get_hplist()}, [])

    const get_hplist = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setList(Apiconnect.decrypt_obj(res.data.data))
        })
    }
    // console.log(list)

    const handleSubmit = (e) => {
        e.preventDefault()
        if(name !== ""){
            if(editId !== ""){
                let ins_ob = {hp_name:name}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_process_update/${editId}`, ins_ob).then((res) => {
                    setFullScreenLoader(false)
                    alert.success("Updated")
                    setName("")
                    setEditId("")
                    get_hplist()
                }).catch(err => alert.error("Error"))

            }else{
                let ins_ob = {hp_company_id: company_id, hp_created_by:emp_id, hp_name:name}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_process_create`, ins_ob).then((res) => {
                    setFullScreenLoader(false)
                    alert.success("Created")
                    setName("")
                    get_hplist()
                }).catch(err => alert.error("Error"))
            }
        }
    }

    const handleEdit = (id) => {
        setEditId(id)
        let fnd = list.find((item) => item.id === id)
        setName(fnd.name)
    }
    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_delete/${id}`).then(res => {
            setFullScreenLoader(false)
            alert.success("Deleted Successfully")
            get_hplist()
        }).catch(err => alert.error("Error"))
    }

    /* ---Rounds--- */
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto', 
            transform: 'translate(-50%, -50%)',
            width: "70%" ,
            height: '80%'
        },
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [hp_id, setHp_id] = useState("")
    const [hp_name, setHp_name] = useState("")

    
    

    const [r_id, setR_id] = useState('')

    console.log(r_id)


    

    

    
    

   
  return (
    <>
        <div className='content-page'
        style={{background: "#fff"}}
        >
            {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}                                                                               
            <Modal isOpen={modalIsOpen} style={customStyles}>                                          {/* ---Modal-1--- onRequestClose={() => setModalIsOpen(false)}*/}  {/* ------Manage Rounds----- */}
                <SingleRound 
                r_id={r_id} 
                hp_id={hp_id} 
                setFullScreenLoader={setFullScreenLoader}
                company_id={company_id}
                emp_id={emp_id}
                hp_name={hp_name}
                setHp_id={setHp_id}
                setModalIsOpen={setModalIsOpen}
                alert={alert}
                />
            </Modal>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'
                        style={{
                            borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" 
                        }}
                        >
                            <div className="iq-card-header d-flex justify-content-between model_head">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Hiring Process</h4>
                                </div>
                                <Link to={`/Work_force`}><button className='btn btn-sm btn-info'>Back</button></Link>
                            </div>
                            
                            <div className='iq-card-body'>
                                <div className='row mt-2'>
                                    <div className='col-md-4 mb-2'>
                                        <input type="text" className='form-control' placeholder='Enter Hiring Process' name='name' value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className='col-md-4'>
                                        <button className='btn btn-sm btn-primary mr-2' onClick={(e) => handleSubmit(e)}>{editId ? "Update" : "Create"}</button>
                                        {editId ? <button className='btn btn-sm btn-dark' onClick={() => {setEditId(""); setName("")}}>Clear</button> : null}
                                    </div>
                                    <div className='col-md-4'>
                                        <Link to={`/Hiring_round_assessment`}><button  className='btn btn-sm btn-info float-right mr-2'>Manage Assessments</button></Link>
                                        <Link to={`/Hiring_panel`}><button className='btn btn-sm btn-info float-right mr-2'>Manage Panel</button></Link>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <table className='table table-striped table-bordered center'>
                                            <thead>
                                                <tr className="clr">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Manage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.length > 0 ?
                                                    (
                                                        list.map((val, key) => {
                                                            let st = null
                                                            if(val.hp_status === 1){
                                                                st = <span className='badge badge-pill badge-success'>Active</span>
                                                            }else{
                                                                st = <span className='badge badge-pill badge-danger'>Inactive</span>
                                                            }
                                                            return(
                                                                <tr key={key}>
                                                                    <td>{key+1}</td>
                                                                    <td>{val.name}</td>
                                                                    <td>{st}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => {setModalIsOpen(true); setHp_id(val.id); setHp_name(val.name);setR_id(val.id)}}>Manage Rounds</button>
                                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => handleEdit(val.id)}  >Edit</button>
                                                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete(val.id)} >Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={4}>No Data Found...</th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_process



const SingleRound = (props) => {
    
    let {r_id, hp_id, setFullScreenLoader, company_id, emp_id, hp_name, setHp_id, setModalIsOpen, alert}=props

    const [round_name, setRound_name] = useState("")
    const [round_h, setRound_h] = useState("")
    const [edit_id_1, setEdit_id_1] = useState("")

    const [round_list, setRound_list] = useState([])

    useEffect(() => {get_round_list()}, [])

    const get_round_list = () => {
        setFullScreenLoader(true)     
        Apiconnect.postData(`hiring_application/hiring_process_round_getall/${company_id}/${r_id}`).then(res => {
            // let data  = Apiconnect.decrypt_obj(res.data.data)
            setRound_list(res.data.data)
            setFullScreenLoader(false)
        })
    }

    const handleSubmitRound = (e) => {
        e.preventDefault()
        if(round_name!=="" && round_h!==""){
            if(edit_id_1 !== ""){
                let ins_ob = {hpr_round_name:round_name, hpr_round_hierarchy:round_h}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_process_round_update/${edit_id_1}`, ins_ob).then(res => {
                    get_round_list()
                    setFullScreenLoader(false)
                    alert.success("Updated")
                    setRound_name("")
                    setRound_h("")
                })

            }else{
                let ins_ob = {hpr_company_id:company_id, hpr_round_name:round_name, hpr_round_hierarchy:round_h, hpr_hiring_process_id:hp_id, hpr_created_by:emp_id}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_process_round_create`, ins_ob).then(res => {
                    get_round_list()
                    setFullScreenLoader(false)
                    alert.success("Created")
                    setRound_name("")
                    setRound_h("")
                })
            }
        }
    }


    const handleEditRound = (id) => {
        setEdit_id_1(id)
        let ob = round_list.find((item) => item.id === id)
        setRound_name(ob.name)
        setRound_h(ob.hpr_round_hierarchy)
    }
    const handleDeleteRound = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_round_delete/${id}`).then(res => {
            get_round_list()
            setFullScreenLoader(false)
            alert.success("Deleted Successfully")
        })
    }

    return(
        <>
            <div className='iq-card'
            style={{background: "#fff"}}
            >
                    <div className='iq-card-header d-flex justify-content-between model_head'>
                        <div style={{ float: "left" }} className="iq-header-title">
                            <h4 className="card-title">Manage Rounds For <span className="font-weight-bold">{hp_name}</span></h4>
                        </div>
                        <button className='btn badge btn-sm btn-dark' onClick={() => {setModalIsOpen(false);setHp_id("");setEdit_id_1("")}}>close</button>
                    </div>
                    <div className='iq-card-body'>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <input type="text" className='form-control' placeholder='Enter Hiring Round' name='round_name' value={round_name} onChange={(e) => setRound_name(e.target.value)} />
                                </div>
                                <div className='col-md-4'>
                                    <input type="number" className='form-control' placeholder='Enter Hiring Hierarchy' name='round_h' value={round_h} onChange={(e) => setRound_h(e.target.value)} />
                                </div>
                                <div className='col-md-4'>
                                    <button className='btn btn-sm btn-primary mr-2' onClick={(e) => handleSubmitRound(e)}>{edit_id_1 ? "Update" : "Create"}</button>
                                    {edit_id_1 ? <button className='btn btn-sm btn-dark mr-2' onClick={(e) => {setEdit_id_1("");setRound_name("");setRound_h("")}}>Clear</button> : null}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mt-3'>
                            <table className='table table-striped table-bordered center'>
                                <thead>
                                    <tr className="clr">
                                        <th scope="col">#</th>
                                        <th scope="col">Round Name</th>
                                        <th scope="col">Round Hierarchy</th>
                                        <th scope="col">Round For</th>
                                        <th scope="col">manage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        round_list.length > 0 ?
                                        (
                                            round_list.map((val, key) => {
                                                return(
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.name}</td>
                                                        <td>{val.hpr_round_hierarchy}</td>
                                                        <td>{val.hp_name}</td>
                                                        <td>
                                                            {/* <Link to={`/Assign_panel/${hp_id}/${val.id}`} target="_blank"><button className='btn btn-sm btn-success mr-2'>Assign Panel</button></Link> */}
                                                            <Link to={`/Assign_assessments/${hp_id}/${val.id}`} target="_blank"><button className='btn btn-sm btn-success mr-2'>Assign Assessments</button></Link>
                                                            <button className='btn btn-sm btn-info mr-2' onClick={() => handleEditRound(val.id)}>Edit</button>
                                                            <button className='btn btn-sm btn-danger mr-2' onClick={() => handleDeleteRound(val.id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        :
                                        (
                                            <tr>
                                                <th colSpan={5}>No Data Found...</th>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        </>
    )
} 