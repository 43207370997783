import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Rm_manage_shortlisted = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    get_shortlisted_list();
  }, []);

  const get_shortlisted_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hiring_application/hiring_application_shortlisted_list/${company_id}`).then((res) => {
      let _xtract = Apiconnect.decrypt_obj(res.data.data);
      setList(_xtract);
      setFullScreenLoader(false);
    });
  };

  console.log(list);

  const manage_st = (id, st) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`selected_applicants/manage_applicant_status/${id}/${st}`)
      .then((res) => {
        get_shortlisted_list();
        setFullScreenLoader(false);
        alert.success("Success");
      })
      .catch((err) => {
        alert.error("Error");
        setFullScreenLoader(false);
      });
  };


  return (
    <div className="content-page main_bg">
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div className="container-fluid">
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
          <div className="iq-card">
            <div className="iq-card-header card_head">
              <h5 className="text-white">Assign Rounds Name</h5>
            </div>
            <div className="iq-card-body ">
              <div className="row mt-3">
                {/* <div className='col-md-12 mt-3'>
                            <div className='table-responsive'>
                                <table className='table table-bordered normal_shadow'>
                                    
                                    <thead className='thead-light'>
                                    </thead>

                                    <tbody>
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>
        </Modal>
        <div className="iq-card main_container">
          <div className="iq-card-header card_head">
            <h4 className="text-white" style={{ position: "sticky", top: 0 }}>
              Manage Shortlisted Candidates
            </h4>
          </div>
          <div className="iq-card-body">
            <div className="row mt-3">
              <div className="col-md-12 mt-3 table-responsive  border">
                <table className="table table-sm table-bordered normal_shadow">
                  <thead className="thead-light">
                    <tr>
                      <th style={{position: "sticky", left: "-20px"}} scope="col" className="text-center">
                        #
                      </th>
                      <th style={{position: "sticky", left: 0 }} scope="col" className="text-center">
                        Name
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Applied For
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Email
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Assigned Hiring Process
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Manage Hiring Process
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Manage Assessments
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Completed Assessments
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Total Score Percentage
                      </th>
                      <th style={{ width: "10%" }} scope="col" className="text-center">
                        Manage Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length > 0 ? (
                      list.map((item, index) => {
                        let status = "";

                        if (item.am_isSelected === 0) {
                          status = <span className="badge badge-pill badge-warning">Onhold</span>;
                        } else if (item.am_isSelected === 1) {
                          status = <span className="badge badge-pill badge-success">Selected</span>;
                        } else if (item.am_isSelected === 2) {
                          status = <span className="badge badge-pill badge-danger">Rejected</span>;
                        } else if (item.am_isSelected === null && item.am_isSelected === "") {
                          status = <span className="badge badge-pill badge-primary">Not Evaluated</span>;
                        }

                        const totalPerc = (item.total_percentage).toFixed(2)

                        return (
                          <tr key={index}>
                            <td style={{ position: "sticky", left: "-20px", background: "white" }} className="text-center">{index + 1}</td>
                            <td style={{ position: "sticky", left: 0, background: "white" }} className="text-center">
                              {item.am_first_name + " " + item.am_middle_name + " " + item.am_middle_name}
                            </td>
                            <td className="text-center">{item.am_position}</td>
                            <td className="text-center">{item.am_email}</td>
                            <td className="text-center">
                              <span className="badge badge-pill badge-success">{item.hp_name}</span>
                            </td>
                            <td className="text-center">
                              <Link to={`/initiate_assessments/${item.id}`}>
                                <button className="btn btn-sm btn-info">Manage</button>
                              </Link>
                            </td>
                            <td className="text-center">
                              <div className="table-responsive  mt-2">
                                <table className="table table-primary table-bordered table-sm">
                                  <tbody>
                                    <tr>
                                      {item.hp_rounds.map((val, key) => (
                                        <td key={key}>
                                          <span className="badge badge-pill badge-info badge-sm">{val.name} </span>
                                          <br />
                                          {val.assessments.map((val1, key1) => (
                                            <React.Fragment key={key1}>
                                              <Link to={`manage_assessments/${item.id}/${item.am_position_id}/${item.hp_id}/${val.id}/${val1.assessment_id}/${val1.form_id}`}>
                                                <button className="btn btn-sm btn-secondary m-2 " key={key1}>
                                                  {val1.assessment_name}
                                                </button>
                                              </Link>
                                              <br />
                                            </React.Fragment>
                                          ))}
                                        </td>
                                      ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="table-responsive  mt-2">
                                <table className="table table-success table-bordered table-sm">
                                  <tbody>
                                    <tr>
                                      {item.completed_assessments.map((val, key) => {
                                        const totalScore = (val.total_score).toFixed(2)
                                        return (
                                          <td key={key}>
                                            <span className="badge badge-pill badge-info badge-sm">{val.round_name} </span>
                                            <br />
                                            <span className="badge badge-secondary">
                                              {val.assessment_name} <span className="badge badge-warning">{totalScore} %</span>
                                            </span>
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                            <td className="text-center">
                              <span className="font-weight-bold text-muted">{totalPerc} %</span>
                            </td>
                            <td>
                              <div class="btn-group">
                                <button type="button" className="btn btn-white dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  {status}
                                </button>
                                <div className="dropdown-menu center">
                                  <button
                                    className="btn btn-sm btn-success mr-2 mb-2"
                                    onClick={() => {
                                      manage_st(item.id, 1);
                                    }}
                                  >
                                    Select
                                  </button>{" "}
                                  <br />
                                  <button
                                    className="btn btn-sm btn-warning mr-2 mb-2"
                                    onClick={() => {
                                      manage_st(item.id, 0);
                                    }}
                                  >
                                    Onhold
                                  </button>{" "}
                                  <br />
                                  <button
                                    className="btn btn-sm btn-danger mr-2 mb-2"
                                    onClick={() => {
                                      manage_st(item.id, 2);
                                    }}
                                  >
                                    Reject
                                  </button>{" "}
                                  <br />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <th colSpan={10} style={{ textAlign: "center" }}>
                          No Data Found..
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_manage_shortlisted;
