import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table, TextArea } from "../../../../components/imports";
import Apiconnect from "../../../../services/Apiconnect";
import { pageStyles } from "../../../../globalStyles";
import toast from "react-hot-toast";

const NoDues = () => {
  const [payroll, setPayroll] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [hod, setHod] = useState(false);
  const [it, setIt] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [library, setLibrary] = useState(false);
  const [hr, setHr] = useState(false);

  return (
    <Container heading="Employee No Dues">
      <Card>
        <div className="col-md-12 border p-3">
          <button
            className={`btn btn-sm ${payroll ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll((currentState) => !currentState);
              setAccounts(false);
              setHod(false);
              setIt(false);
              setAdmin(false);
              setLibrary(false);
              setHr(false);
            }}
          >
            Payroll
          </button>
          <button
            className={`btn btn-sm ${accounts ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll(false);
              setAccounts((currentState) => !currentState);
              setHod(false);
              setIt(false);
              setAdmin(false);
              setLibrary(false);
              setHr(false);
            }}
          >
            Accounts
          </button>
          <button
            className={`btn btn-sm ${hod ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll(false);
              setAccounts(false);
              setHod((currentState) => !currentState);
              setIt(false);
              setAdmin(false);
              setLibrary(false);
              setHr(false);
            }}
          >
            Department Head
          </button>
          <button
            className={`btn btn-sm ${it ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll(false);
              setAccounts(false);
              setHod(false);
              setIt((currentState) => !currentState);
              setAdmin(false);
              setLibrary(false);
              setHr(false);
            }}
          >
            IT
          </button>
          <button
            className={`btn btn-sm ${admin ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll(false);
              setAccounts(false);
              setHod(false);
              setIt(false);
              setAdmin((currentState) => !currentState);
              setLibrary(false);
              setHr(false);
            }}
          >
            Admin
          </button>
          <button
            className={`btn btn-sm ${library ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll(false);
              setAccounts(false);
              setHod(false);
              setIt(false);
              setAdmin(false);
              setLibrary((currentState) => !currentState);
              setHr(false);
            }}
          >
            Library
          </button>
          <button
            className={`btn btn-sm ${hr ? "btn-info border" : "btn-light border"} m-2`}
            onClick={() => {
              setPayroll(false);
              setAccounts(false);
              setHod(false);
              setIt(false);
              setAdmin(false);
              setLibrary(false);
              setHr((currentState) => !currentState);
            }}
          >
            HR
          </button>
        </div>
      </Card>
      {payroll && <Payroll />}
      {accounts && <Accounts />}
      {hod && <Hod />}
      {it && <It />}
      {admin && <Admin />}
      {library && <Library />}
      {hr && <Hr />}
    </Container>
  );
};

export default NoDues;

const Payroll = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);

  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_payroll_np_desc: "",
    ndr_payroll_np_recovery: "",
    ndr_payroll_al_desc: "",
    ndr_payroll_al_recovery: "",
    ndr_payroll_sal_desc: "",
    ndr_payroll_sal_recovery: "",
    ndr_payroll_inc_desc: "",
    ndr_payroll_inc_recovery: "",
    ndr_payroll_grat_desc: "",
    ndr_payroll_grat_recovery: "",
    ndr_payroll_reim_desc: "",
    ndr_payroll_reim_recovery: "",
  });
  const [id, setId] = useState("");
  const [info, setInfo] = useState(null);
  const { ndr_payroll_np_desc, ndr_payroll_np_recovery, ndr_payroll_al_desc, ndr_payroll_al_recovery, ndr_payroll_sal_desc, ndr_payroll_grat_desc, ndr_payroll_grat_recovery, ndr_payroll_inc_desc, ndr_payroll_inc_recovery, ndr_payroll_reim_desc, ndr_payroll_reim_recovery, ndr_payroll_sal_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListPayroll/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );

  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_payroll_al_desc && ndr_payroll_al_desc && ndr_payroll_grat_desc && ndr_payroll_grat_recovery && ndr_payroll_inc_desc && ndr_payroll_inc_recovery && ndr_payroll_np_desc && ndr_payroll_np_recovery && ndr_payroll_reim_desc && ndr_payroll_reim_recovery && ndr_payroll_sal_desc && ndr_payroll_sal_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_payroll_np_desc: "",
            ndr_payroll_np_recovery: "",
            ndr_payroll_al_desc: "",
            ndr_payroll_al_recovery: "",
            ndr_payroll_sal_desc: "",
            ndr_payroll_sal_recovery: "",
            ndr_payroll_inc_desc: "",
            ndr_payroll_inc_recovery: "",
            ndr_payroll_grat_desc: "",
            ndr_payroll_grat_recovery: "",
            ndr_payroll_reim_desc: "",
            ndr_payroll_reim_recovery: "",
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };

  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - Payroll</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Notice Period</td>
                <td>
                  <TextArea className="col-md-12" label="" placeholder={info ? `${info.ndr_payroll_np_desc}` : "Description"} name="ndr_payroll_np_desc" value={ndr_payroll_np_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_payroll_np_recovery" value={ndr_payroll_np_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Access leave</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_payroll_al_desc" value={ndr_payroll_al_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_payroll_al_recovery" value={ndr_payroll_al_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Salary</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_payroll_sal_desc" value={ndr_payroll_sal_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_payroll_sal_recovery" value={ndr_payroll_sal_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Incentive (if any)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_payroll_inc_desc" value={ndr_payroll_inc_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_payroll_inc_recovery" value={ndr_payroll_inc_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Gratuity (if payable)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_payroll_grat_desc" value={ndr_payroll_grat_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_payroll_grat_recovery" value={ndr_payroll_grat_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Any Reimbursement (LTA etc)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_payroll_reim_desc" value={ndr_payroll_reim_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_payroll_reim_recovery" value={ndr_payroll_reim_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive((s) => !s);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_payroll_np_desc && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                        setInfo(item);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
const Accounts = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_acc_adv_desc: "",
    ndr_acc_adv_recovery: "",
    ndr_acc_imprest_desc: "",
    ndr_acc_imprest_recovery: "",
    ndr_acc_leased_desc: "",
    ndr_acc_leased_recovery: "",
    ndr_acc_pay_desc: "",
    ndr_acc_pay_recovery: "",
    ndr_acc_other_desc: "",
    ndr_acc_other_recovery: "",
  });
  const [id, setId] = useState("");
  const { ndr_acc_adv_desc, ndr_acc_adv_recovery, ndr_acc_imprest_desc, ndr_acc_imprest_recovery, ndr_acc_leased_desc, ndr_acc_leased_recovery, ndr_acc_other_desc, ndr_acc_other_recovery, ndr_acc_pay_desc, ndr_acc_pay_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListAccounts/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };
  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_acc_adv_desc && ndr_acc_adv_recovery && ndr_acc_imprest_desc && ndr_acc_imprest_recovery && ndr_acc_leased_desc && ndr_acc_leased_recovery && ndr_acc_pay_desc && ndr_acc_pay_recovery && ndr_acc_other_desc && ndr_acc_other_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_acc_adv_desc: "",
            ndr_acc_adv_recovery: "",
            ndr_acc_imprest_desc: "",
            ndr_acc_imprest_recovery: "",
            ndr_acc_leased_desc: "",
            ndr_acc_leased_recovery: "",
            ndr_acc_pay_desc: "",
            ndr_acc_pay_recovery: "",
            ndr_acc_other_desc: "",
            ndr_acc_other_recovery: "",
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );

  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - Accounts</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Tour or any other Adv./Salary Adv./Loans (Specify)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_acc_adv_desc" value={ndr_acc_adv_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_acc_adv_recovery" value={ndr_acc_adv_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Imprest</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_acc_imprest_desc" value={ndr_acc_imprest_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_acc_imprest_recovery" value={ndr_acc_imprest_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Company leased House</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_acc_leased_desc" value={ndr_acc_leased_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_acc_leased_recovery" value={ndr_acc_leased_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Any 3rd party payments</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_acc_pay_desc" value={ndr_acc_pay_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_acc_pay_recovery" value={ndr_acc_pay_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Any other (Specify)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_acc_other_desc" value={ndr_acc_other_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_acc_other_recovery" value={ndr_acc_other_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive((s) => !s);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_acc_other_recovery && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
const Hod = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_hod_office_desc: "",
    ndr_hod_office_recovery: "",
    ndr_hod_files_desc: "",
    ndr_hod_files_recovery: "",
    ndr_hod_other_desc: "",
    ndr_hod_other_recovery: "",
  });
  const [id, setId] = useState("");
  const { ndr_hod_files_desc, ndr_hod_files_recovery, ndr_hod_office_desc, ndr_hod_office_recovery, ndr_hod_other_desc, ndr_hod_other_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListHod/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );
  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_hod_office_desc && ndr_hod_office_recovery && ndr_hod_files_desc && ndr_hod_files_recovery && ndr_hod_other_desc && ndr_hod_other_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_hod_office_desc: "",
            ndr_hod_office_recovery: "",
            ndr_hod_files_desc: "",
            ndr_hod_files_recovery: "",
            ndr_hod_other_desc: "",
            ndr_hod_other_recovery: "",
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };
  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - HOD</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Office Information, records</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hod_office_desc" value={ndr_hod_office_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hod_office_recovery" value={ndr_hod_office_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Databases and files</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hod_files_desc" value={ndr_hod_files_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hod_files_recovery" value={ndr_hod_files_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Any other (Specify)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hod_other_desc" value={ndr_hod_other_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hod_other_recovery" value={ndr_hod_other_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_hod_other_recovery && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
const It = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_it_email_desc: "",
    ndr_it_email_recovery: "",
    ndr_it_lap_desc: "",
    ndr_it_lap_recovery: "",
    ndr_it_dc_desc: "",
    ndr_it_dc_recovery: "",
  });
  const [id, setId] = useState("");
  const { ndr_it_dc_desc, ndr_it_dc_recovery, ndr_it_email_desc, ndr_it_email_recovery, ndr_it_lap_desc, ndr_it_lap_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListIt/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );
  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_it_email_desc && ndr_it_email_recovery && ndr_it_lap_desc && ndr_it_lap_recovery && ndr_it_dc_desc && ndr_it_dc_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_it_email_desc: "",
            ndr_it_email_recovery: "",
            ndr_it_lap_desc: "",
            ndr_it_lap_recovery: "",
            ndr_it_dc_desc: "",
            ndr_it_dc_recovery: "",
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };
  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - IT</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Email ID</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_it_email_desc" value={ndr_it_email_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_it_email_recovery" value={ndr_it_email_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Laptop / Computer</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_it_lap_desc" value={ndr_it_lap_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_it_lap_recovery" value={ndr_it_lap_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Data card</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_it_dc_desc" value={ndr_it_dc_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_it_dc_recovery" value={ndr_it_dc_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_it_dc_recovery && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
const Admin = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_admin_mob_desc: "",
    ndr_admin_mob_recovery: "",
    ndr_admin_other_desc: "",
    ndr_admin_other_recovery: "",
  });
  const [id, setId] = useState("");
  const { ndr_admin_mob_desc, ndr_admin_mob_recovery, ndr_admin_other_desc, ndr_admin_other_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListAdmin/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );
  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_admin_mob_desc && ndr_admin_mob_recovery && ndr_admin_other_desc && ndr_admin_other_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_admin_mob_desc,
            ndr_admin_mob_recovery,
            ndr_admin_other_desc,
            ndr_admin_other_recovery,
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };
  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - Admin</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Mobile handset</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_admin_mob_desc" value={ndr_admin_mob_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_admin_mob_recovery" value={ndr_admin_mob_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Any other (Specify)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_admin_other_desc" value={ndr_admin_other_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_admin_other_recovery" value={ndr_admin_other_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_admin_other_recovery && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
const Library = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_lib_book_desc: "",
    ndr_lib_book_recovery: "",
    ndr_lib_other_desc: "",
    ndr_lib_other_recovery: "",
  });
  const [id, setId] = useState("");
  const { ndr_lib_book_desc, ndr_lib_book_recovery, ndr_lib_other_desc, ndr_lib_other_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListLibrary/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );
  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_lib_book_desc && ndr_lib_book_recovery && ndr_lib_other_desc && ndr_lib_other_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_lib_book_desc: "",
            ndr_lib_book_recovery: "",
            ndr_lib_other_desc: "",
            ndr_lib_other_recovery: "",
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };
  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - Library</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Books, Periodicals & Newspapers</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_lib_book_desc" value={ndr_lib_book_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_lib_book_recovery" value={ndr_lib_book_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Any other (Specify)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_lib_other_desc" value={ndr_lib_other_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_lib_other_recovery" value={ndr_lib_other_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive((s) => !s);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_lib_other_recovery && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
const Hr = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  const emp_id = Apiconnect.decrypt(localuser.id);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [inputs, setInputs] = useState({
    ndr_hr_ic_desc: "",
    ndr_hr_ic_recovery: "",
    ndr_hr_ac_desc: "",
    ndr_hr_ac_recovery: "",
    ndr_hr_ma_desc: "",
    ndr_hr_ma_recovery: "",
    ndr_hr_itp_desc: "",
    ndr_hr_itp_recovery: "",
    ndr_hr_pl_desc: "",
    ndr_hr_pl_recovery: "",
  });
  const [id, setId] = useState("");
  const { ndr_hr_ac_desc, ndr_hr_ac_recovery, ndr_hr_ic_desc, ndr_hr_ic_recovery, ndr_hr_itp_desc, ndr_hr_itp_recovery, ndr_hr_ma_desc, ndr_hr_ma_recovery, ndr_hr_pl_desc, ndr_hr_pl_recovery } = inputs;

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getallempListHr/${Apiconnect.get_company_id()}/${emp_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      console.log(extract);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Action</th>
    </tr>
  );
  const trHeader2 = (
    <tr className="text-center">
      <th style={{ width: "20%" }}>Particulars</th>
      <th style={{ width: "50%" }}>Description</th>
      <th style={{ width: "30%" }}>Recovery/Payment</th>
    </tr>
  );

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (ndr_hr_ic_desc && ndr_hr_ic_recovery && ndr_hr_ac_desc && ndr_hr_ac_recovery && ndr_hr_ma_desc && ndr_hr_ma_recovery && ndr_hr_itp_desc && ndr_hr_itp_recovery && ndr_hr_pl_desc && ndr_hr_pl_recovery) {
      Apiconnect.postData(`separation/empNoDuesUpdateById/${id}`, inputs)
        .then((res) => {
          toast.success("Success");
          setInputs({
            ndr_hr_ic_desc: "",
            ndr_hr_ic_recovery: "",
            ndr_hr_ac_desc: "",
            ndr_hr_ac_recovery: "",
            ndr_hr_ma_desc: "",
            ndr_hr_ma_recovery: "",
            ndr_hr_itp_desc: "",
            ndr_hr_itp_recovery: "",
            ndr_hr_pl_desc: "",
            ndr_hr_pl_recovery: "",
          });
          getList();
          setActive((s) => !s);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("error");
    }
  };
  return (
    <>
      <Card>
        {active && (
          <Card>
            <div className="col-md-12 text-center">
              <h3 className="text-muted">Employee Payroll - HR</h3>
            </div>
            <div className="col-md-12 text-center">
              <span className="text-muted">{name}</span>
            </div>

            <Table className="col-md-12" trHeader={trHeader2}>
              <tr className="text-center">
                <td>Id Card</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hr_ic_desc" value={ndr_hr_ic_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hr_ic_recovery" value={ndr_hr_ic_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Access Card</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hr_ac_desc" value={ndr_hr_ac_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hr_ac_recovery" value={ndr_hr_ac_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Missed attendance (if any)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hr_ma_desc" value={ndr_hr_ma_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hr_ma_recovery" value={ndr_hr_ma_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>Income Tax Proofs (submitted/not submitted)</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hr_itp_desc" value={ndr_hr_itp_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hr_itp_recovery" value={ndr_hr_itp_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
              <tr className="text-center">
                <td>PL Accumulated</td>
                <td>
                  <TextArea className="col-md-12" label="" name="ndr_hr_pl_desc" value={ndr_hr_pl_desc} onChange={(e) => onInputChange(e)} />
                </td>
                <td>
                  <SelectFromOptions className="col-md-12" label="" name="ndr_hr_pl_recovery" value={ndr_hr_pl_recovery} onChange={(e) => onInputChange(e)} optionOne="Select Status">
                    <option value="No Dues">No Dues</option>
                    <option value="Dues Pending">Dues Pending</option>
                  </SelectFromOptions>
                </td>
              </tr>
            </Table>

            <div className="col-md-12 mt-3 text-center">
              <div className="text-center">
                <button className="btn btn-sm btn-info m-2" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="btn btn-sm btn-secondary m-2"
                  onClick={() => {
                    setName("");
                    setActive((s) => !s);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Card>
        )}
        {!active && (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.empName}</td>
                  <td>{item.empCode}</td>
                  <td>{item.empEmail}</td>
                  <td>{item.desig}</td>
                  <td>
                    <button
                      className={`btn btn-sm btn-info ${item.ndr_hr_pl_recovery && "disabled"}`}
                      onClick={() => {
                        setName(item.empName + " ( " + item.empCode + " ) " + item.empEmail);
                        setActive((s) => !s);
                        setId(item.ndr_pk_id);
                      }}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card>
    </>
  );
};
