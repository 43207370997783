import React, { useState, useEffect } from "react";
import Apiconnect from "../../services/Apiconnect";
import { pageStyles } from "../../globalStyles";
import { useAlert } from "react-alert";

const Applicant_approval = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    getApplicantFinalApprovalList();
  }, []);

  const getApplicantFinalApprovalList = async () => {
    try {
      const data = {
        empId: emp_id,
        companyId: Apiconnect.get_company_id(),
      };
      const res = await Apiconnect.postData(`selected_applicants/applicantApprovalListGetByEmpId`, data);
      const resData = res.data.data;
      setList(resData);
    } catch (error) {
      console.log(error);
    }
  };
  const handleApprove = async (a, b) => {
    try {
      const data_ins = {
        fa_Id: a,
        empId: b,
      };
      const approve = await Apiconnect.postData(`selected_applicants/applicantFinalApproval`, data_ins);
      getApplicantFinalApprovalList();
      alert.success("Success");
    } catch (error) {
      console.log(error.message);
      alert.error("error");
    }
  };

  const handleReject = async (id) => {
    try {
      const response = await Apiconnect.postData(`selected_applicants/applicantRejection/${id}`);
      alert.success("Applicant Rejected");
      getApplicantFinalApprovalList();
    } catch (error) {
      alert.error("Error");
      console.log(error.message);
    }
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <div className="container-fluid">
        <div className="iq-card" style={pageStyles.main_container}>
          <div className="iq-card-header" style={pageStyles.card_head}>
            <h4 className="text-white" style={pageStyles.h4_bg}>Selected Applicant List</h4>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12 table-responsive">
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Applicant Name</th>
                      <th className="text-center">Applied Position</th>
                      <th className="text-center">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.am_first_name + " " + item.am_middle_name + " " + item.am_last_name}</td>
                          <td>{item.am_position}</td>
                          <td className="text-center">
                            <div>
                              {item.fa_isApproved === 0 && (
                                <button className="btn btn-info btn-sm m-2" onClick={() => handleApprove(item.fa_pk_id, item.am_pk_id)}>
                                  Approve
                                </button>
                              )}
                              {item.fa_isApproved === 1 && <span className="badge badge-success badge-sm">Approved</span>}
                              {item.fa_isApproved === 2 && <span className="badge badge-danger badge-sm">Rejected</span>}
                              {item.fa_isApproved === 1 && (
                                <button className="btn btn-sm btn-danger m-2" onClick={() => handleReject(item.fa_pk_id)}>
                                  Reject
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicant_approval;
