import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";

const ExtensionRequested = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getEmps();
  }, []);

  const getEmps = () => {
    Apiconnect.postData(`separation/getallExtensionRequest/${Apiconnect.get_company_id()}`)
      .then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setList(extract);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });
  };

  console.log(list);

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Date Of Birth</th>
      <th>Age</th>
      <th>Retirement Date</th>
      <th>Action</th>
    </tr>
  );

  const handleApprove = (id) => {
    Apiconnect.postData(`separation/approveExtension/${id}`).then((res) => {
      getEmps();
      toast.success("Extension Request Approved");
    });
  };

  const handleReject = (id) => {
    Apiconnect.postData(`separation/rejectExtension/${id}`).then((res) => {
      getEmps();
      toast.success("Extension Request Rejected");
    });
  };

  return (
    <Container heading="Retirement Extension Requests">
      {list.length > 0 ? (
        <Table className="col-md-12" trHeader={trHeader}>
          {list.map((item, index) => {
            const dob = item.dob;
            const dob_format = moment(dob).format("YYYY");
            const date = new Date();
            const date_format = moment(date).format("YYYY");
            const age = Number(date_format) - Number(dob_format);
            const dob_original = moment(dob).format("DD/MM/YYYY");
            const current_date = moment(date).format("DD/MM/YYYY");
            var now = moment(new Date());
            var end = moment(dob).format("YYYY-MM-DD");

            const actual_dob = moment(dob).format("MM/DD/YYYY");
            const current_now = moment(current_date).format("MM/DD/YYYY");

            function calcDate(date1, date2) {
              //new date instance
              const dt_date1 = new Date(date1);
              const dt_date2 = new Date(date2);

              //Get the Timestamp
              const date1_time_stamp = dt_date1.getTime();
              const date2_time_stamp = dt_date2.getTime();

              let calc;
              //Check which timestamp is greater
              if (date1_time_stamp > date2_time_stamp) {
                calc = new Date(date1_time_stamp - date2_time_stamp);
              } else {
                calc = new Date(date2_time_stamp - date1_time_stamp);
              }

              //Retrieve the date, month and year
              const calcFormatTmp = calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
              //Convert to an array and store
              const calcFormat = calcFormatTmp.split("-");
              //Subtract each member of our array from the default date
              const days_passed = Number(Math.abs(calcFormat[0]) - 1);
              const months_passed = Number(Math.abs(calcFormat[1]) - 1);
              const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

              //Set up custom text
              const yrsTxt = ["year", "years"];
              const mnthsTxt = ["month", "months"];
              const daysTxt = ["day", "days"];

              //Convert to days and sum together
              const total_days = years_passed * 365 + months_passed * 30.417 + days_passed;

              //display result with custom text
              const result = (years_passed == 1 ? years_passed + " " + yrsTxt[0] + " " : years_passed > 1 ? years_passed + " " + yrsTxt[1] + " " : "") + (months_passed == 1 ? months_passed + " " + mnthsTxt[0] : months_passed > 1 ? months_passed + " " + mnthsTxt[1] + " " : "") + (days_passed == 1 ? days_passed + " " + daysTxt[0] : days_passed > 1 ? days_passed + " " + daysTxt[1] : "");
              //return the result
              // return {
              //   total_days: Math.round(total_days),
              //   result: result.trim(),
              // };

              return result;
            }

            const age_new = calcDate(current_now, actual_dob);

            return (
              <tr className="text-muted text-center" key={index}>
                <td>{index + 1}</td>
                <td>{item.emp_name}</td>
                <td>{item.emp_code}</td>
                <td>{item.email}</td>
                <td>{dob_original}</td>
                <td>{age_new}</td>
                <td>{item.retirement_dt}</td>
                <td className="text-center">
                  <div className="display-flex justify-content-center">
                    <button className="btn btn-sm btn-info m-2" onClick={() => handleApprove(item.re_emp_id, 1)}>
                      Approve
                    </button>
                    <button className="btn btn-sm btn-danger m-2" onClick={() => handleReject(item.re_emp_id, 1)}>
                      Reject
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="col-md-12 text-center">
          <span className="badge badge-pill badge-success">No Records Fround</span>
        </div>
      )}
    </Container>
  );
};

export default ExtensionRequested;
