import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";
import Apiconnect from "../services/Apiconnect.js";
import homeIcon from "../assets/sidebarIcons/home.png";
import { pageStyles, page_styles } from "../globalStyles";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const master = ["area", "city", "company_category", "leaveassign", "shiftassign", "company_holiday", "weekly_off", "leave_policy", "shift", "manual_leaves", "designation", "institute", "university_board"];
  const salary_info = ["Earning_head", "deduction_head", "reimbursement", "perk_head", "formula_add", "formula_list", "formula_edit", "salary_template", "salary_template_edit", "salary_template_list", "employee_month_sal", "monthly_salary_list", "manage_salary_wages", "manage_financial"];
  const emp_manage = ["employee_manage_list", "employee_manage", "employee_requested_thumb", "employee_requested_details"];
  const report = ["punchtime_report", "daily_attendance_report", "monthly_attendance_report", "employee_report", "daily_departure_report", "daily_absent_report", "daily_late_arrival_report", "Employee_leave_report", "Employee_leave_taken"];
  const permission = ["permission_group_list"];
  const company = ["company_list", "company", "company_edit"];

  const leave_manage = ["LeaveCycleSettings", "CustomLeaveGroup", "LeavePolicyList", "LeaveEmployee"];

  /////////---------->breadcrumb<----------//////////
  var pt = "Add";
  if (splitLocation[1] === "employee_manage" && splitLocation[2] != 0) {
    var pt = "Edit";
  }
  const breadcrumb_pages = {
    company: "Home/Add Company",
    company_list: "Home/List",
    company_edit: "Home/Edit company",
    dashboard: "Home",
    employee_manage_list: "Home/Employee Management",
    employee_manage: "Home/Employee Management",
    employee_upload_bulk: "Home/Employee Management/List/Bulk Upload",
    employee_manage_info: "Home/Employee Management/Edit",
    employee_manage_bank: "Home/Employee Management/Edit",
    employee_manage_employement: "Home/Employee Management/Edit",
    employee_manage_reimbursement: "Home/Employee Management/Edit",
    employee_manage_sal: "Home/Employee Management/Edit",
    Earning_head: "Home/Salary Management/Earning Head",
    deduction_head: "Home/Salary Management/Deduction Head",
    reimbursement: "Home/Salary Management/Reimbursement Head",
    perk_head: "Home/Salary Management/Perk Head",
    formula_add: "Home/Salary Management/Formula",
    formula_list: "Home/Salary Management/Formula",
    formula_edit: "Home/Salary Management/Formula",
    employee_month_sal: "Home/Salary Management/Monthly Salary",
    salary_template: "Home/Salary Management/Salary Template",
    salary_template_list: "Home/Salary Management/Salary Template",
    salary_template_edit: "Home/Salary Management/Salary Template",
    city: "Home/Setting/City",
    area: "Home/Setting/Area",
    company_category: "Home/Setting/Departments",
    leaveassign: "Home/Setting/Departments/Leaves Assign",
    shiftassign: "Home/Setting/Departments/Shift Assign",
    company_holiday: "Home/Setting/Company Holiday",
    weekly_off: "Home/Setting/Weekly Off",
    leave_policy: "Home/Setting/Leave Policy",
    shift: "Home/Setting/Shift",
    manual_leaves: "Home/Setting/Leave Balance Update",
    punchtime_report: "Home/Employee PunchTime Report",
    daily_attendance_report: "Home/Employee Attendance Report",
    monthly_attendance_report: "Home/Monthly Attendance Report",
    employee_requested_thumb: "Home/Employee Requested Profile List",
    employee_requested_details: "Home/Employee Requested Details",
    designation: "Home/Setting/Designation",
    institute: "Home/Setting/Institute",
    university_board: "Home/Setting/University Board",
    LeaveCycleSettings: "Home/Leave Management/LeaveCycleSettings",
    CustomLeaveGroup: "Home/Leave Management/CustomLeaveGroup",
    LeavePolicyList: "Home/Leave Management/LeavePolicyList",
    LeaveEmployee: "Home/Leave Management/Leave Employee",
    LeaveCategory: "Home/Leave Management/Leave Category",
    Manage_leave_category_wise: "Home/Leave Management/Manage Leave Category Wise",
    Employee_leave_report: "Home/Report/Employee Leave Report",
    Monthly_attendance_list: "Home/Monthly_attendance_list",
    Employee_leave_record: "Home/Report/Employee Leave Record",
    Employee_leave_taken: "Home/Report/Employee Leave Taken Report",
  };

  if (breadcrumb_pages[splitLocation[1]] != undefined) {
    var breadcrumb = breadcrumb_pages[splitLocation[1]].split("/");
  } else {
    var breadcrumb = "";
  }

  /////////---------->End breadcrumb<----------//////////

  const [show, setShow] = useState(false);

  let history = useHistory();

  function logOut() {
    AuthService.logout();
    history.push("/");
  }

  function resetPassword() {
    history.push("resetPassword/");
  }

  const locusr = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(locusr.id);
  let user_typ = Apiconnect.decrypt(locusr.emp_typ);
  let company_id = Apiconnect.get_company_id();
  let company_name = locusr.company_name;
  let fullname = locusr.fullname;

  const [profile_image, setprofile_image] = useState(null);
  const [logo, setlogo] = useState(null);
  const [getPermission, setPermission] = useState([]);

  useEffect(() => {
    getprofile();
    getCompanyCount();
    getCompany();
    // if(user_typ !== '3'){
    getPermissionAPI(user_id);
    // }
  }, []);

  const getPermissionAPI = (id) => {
    Apiconnect.getData(`permission/getPermissionByUser/${id}`).then((response) => {
      let _xtract = response.data.data;
      if (_xtract && _xtract.length > 0) {
        let dd = _xtract[0].permissions.split(",");
        setPermission(dd);
      }
    });
  };

  const isPermission = (per) => {
    let data1 = getPermission.find((val) => val === per);

    if (data1 || (user_typ === "3" && getPermission.length == 0)) {
      return true;
    } else {
      return false;
    }
  };

  const getprofile = () => {
    Apiconnect.getData(`employee/get/${user_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);

      if (_xtract[0].display_picture == null) {
        setprofile_image("/images/user/1.jpg");
      } else {
        var img = `${process.env.React_App_BASE_URL_API}/uploads/thumbdp/` + _xtract[0].display_picture;
        setprofile_image(img);
      }
    });
  };

  const [allcompany, setCompanyCount] = useState([]);
  const getCompanyCount = () => {
    Apiconnect.getData(`company/getallcompanydata`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setCompanyCount(_xtract.reverse());
    });
  };

  const getCompany = () => {
    if (Apiconnect.get_company_id() != "") {
      Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        if (_xtract[0].logo == null) {
          setlogo("images/logo.png");
        } else {
          var img = `${process.env.React_App_BASE_URL_API}/uploads/logo/` + _xtract[0].logo;
          setlogo(img);
        }
      });
    } else {
      var img = process.env.React_App_BASE_URL_API + "/uploads/logo/school_logo.png";
      setlogo(img);
    }
  };

  const oncompanyChange = (e) => {
    var cm_id = e.target.value;

    const local_user = JSON.parse(localStorage.getItem("user"));
    var company_id1 = Apiconnect.encrypt(cm_id + "");
    local_user.company_id = company_id1;

    var comp_name = allcompany.find((Element) => Element.id == cm_id);

    if (cm_id === "") {
      local_user.company_id = "";
      local_user.company_name = "";
    } else {
      local_user.company_name = comp_name.company_name;
    }
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(local_user));
    window.location.reload();
  };

  // PERMISSION

  // END PERMISSION
  return (
    <>
      <div id="loading">
        <div id="loading-center">
          <div className="loader">
            <div className="cube">
              <div className="sides">
                <div className="top"></div>
                <div className="right"></div>
                <div className="bottom"></div>
                <div className="left"></div>
                <div className="front"></div>
                <div className="back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* style={{color:"white",backgroundColor: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}} */}

      <div className="wrapper">
        <div className="iq-sidebar" style={page_styles.main_container_bg}>
          <div className="iq-sidebar-logo d-flex justify-content-between p-3 w-50" style={{ background: "transparent" }}>
            <Link to="/dashboard">
              <img src={logo} className="img-fluid" alt="" />
              <span>HRMS</span>
            </Link>
          </div>
          <div id="sidebar-scrollbar">
            <nav className="iq-sidebar-menu">
              <ul id="iq-sidebar-toggle" className="iq-menu">
                <li className="iq-menu-title">
                  <i className="ri-separator"></i>
                  <span>Main</span>
                  <span style={{ float: "right" }}>
                    <Link style={{ padding: "0px" }} onClick={(e) => setShow(!show)} value="">
                      {show === true ? "Hide All" : "Show All"}
                    </Link>
                  </span>{" "}
                </li>

                {user_typ !== 3 ? (
                  <li className={splitLocation[1] === "dashboard" ? "active" : ""}>
                    <Link to="/dashboard">
                      <img className="mr-2" src={homeIcon} width={25} height={25} />
                      Dashboard
                    </Link>
                  </li>
                ) : (
                  <li className={splitLocation[1] === "superadmin" ? "active" : ""}>
                    <Link to="/superadmin">
                      <img className="mr-2" src={homeIcon} width={25} height={25} /> Dashboard
                    </Link>
                  </li>
                )}

                <li color="white">
                  {isPermission("PEM_LEAVE_APPY") ? (
                    <Link to="/employee_leave_apply">
                      <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/leave.png" alt="leave" /> Leave
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  {isPermission("PEM_LEAVE_APPY") ? (
                    <Link to="/employee_leave_apply_short">
                      <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/leave.png" alt="leave" /> Short Leave
                    </Link>
                  ) : (
                    ""
                  )}
                </li>

                {user_typ === "3" ? (
                  <>
                    <li className={company.some((item) => splitLocation[1] === item) ? "active" : ""}>
                      <a href="#company" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                        <i className="ri-chat-check-line"></i>
                        <span>Company Management</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                      </a>
                      <ul id="company" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                        {/* <li className={splitLocation[1] === 'company' ? "active" : ""}><Link to="/company">Company</Link></li> */}
                        <li className={splitLocation[1] === "company_list" ? "active" : ""}>
                          <Link to="/company_list">Company List</Link>
                        </li>
                      </ul>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {company_id != "" ? (
                  <>
                    {isPermission("PM_EMPMANG") ? (
                      <li className={emp_manage.some((item) => splitLocation[1] === item) ? "active" : ""}>
                        <a href="#emp_manage" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                          <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/manager--v4.png" alt="manager--v4" />
                          <span>Employee</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul
                          id="emp_manage"
                          className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"}
                          data-parent="#iq-sidebar-toggle"
                          // style={page_styles.main_container_bg}
                        >
                          {isPermission("PM_EMPLIST") ? (
                            <li className={splitLocation[1] === "employee_manage_list" ? "active" : ""}>
                              <Link to="/employee_manage_list">Employee List</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_EMPADD") ? (
                            <li className={splitLocation[1] === "employee_manage" ? "active" : ""}>
                              <Link to="/employee_manage/0">Add New Employee</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_EMPREQPROFILE") ? (
                            <li className={splitLocation[1] === "employee_requested_thumb" ? "active" : ""}>
                              <Link to="/employee_requested_thumb">Employee Requested Profile</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_IDCARD") ? (
                            <li className={splitLocation[1] === "idcard" ? "active" : ""}>
                              <Link to="/idcard">Id Card</Link>
                            </li>
                          ) : null}
                          {/* <li
                          className={
                            splitLocation[1] === "employee_leave_apply"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/employee_leave_apply">
                            Employee Leave Apply
                          </Link>
                        </li> */}
                        </ul>
                      </li>
                    ) : null}

                    {isPermission("PM_SALMANG") ? (
                      <li className={salary_info.some((item) => splitLocation[1] === item) ? "active" : ""}>
                        <a href="#salary_info" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                          <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/receive-cash.png" alt="receive-cash" />
                          <span>Salary</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="salary_info" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          {isPermission("PM_ERHEAD") ? (
                            <li className={splitLocation[1] === "Earning_head" ? "active" : ""}>
                              <Link to="/Earning_head">Earning Head</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_DDHEAD") ? (
                            <li className={splitLocation[1] === "deduction_head" ? "active" : ""}>
                              <Link to="/deduction_head">Deduction Head</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_RBHEAD") ? (
                            <li className={splitLocation[1] === "reimbursement" ? "active" : ""}>
                              <Link to="/reimbursement">Reimbursement Head</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_PERKHEAD") ? (
                            <li className={splitLocation[1] === "perk_head" ? "active" : ""}>
                              <Link to="/perk_head">Perk Head</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_FORMULAADD") ? (
                            <li className={splitLocation[1] === "formula_add" ? "active" : ""}>
                              <Link to="/formula_add">Add Formula</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_FORMULALIST") ? (
                            <li className={splitLocation[1] === "formula_list" ? "active" : ""}>
                              <Link to="/formula_list">Formula List</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_SALTEM") ? (
                            <li className={splitLocation[1] === "salary_template_list" ? "active" : splitLocation[1] === "salary_template" ? "active" : splitLocation[1] === "salary_template_edit" ? "active" : ""}>
                              <Link to="/salary_template_list">Salary Template</Link>
                            </li>
                          ) : null}
                          {/* {
                                    isPermission('PM_SALMONTH')
                                    ? ( <li
                                      className={
                                        splitLocation[1] === "employee_month_sal"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <Link to="/employee_month_sal">
                                      Dynamic Salary {'\n'} computation sheet
                                      </Link>
                                    </li>)
                                    : null
                                } */}
                          {isPermission("PM_SALMONTHLIST") ? (
                            <li className={splitLocation[1] === "monthly_salary_list" ? "active" : ""}>
                              <Link to="/monthly_salary_list">Salary List Monthly</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_SALDISBURSEMENT") ? (
                            <li className={splitLocation[1] === "salary_disbursement" ? "active" : ""}>
                              <Link to="/salary_disbursement">Salary Disbursement</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_ANNUALSALARYRPT") ? (
                            <li className={splitLocation[1] === "annual_salary_report" ? "active" : ""}>
                              <Link to="/annual_salary_report">Annual Salary Report</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_SALMONTHWAGES") ? (
                            <li className={splitLocation[1] === "manage_salary_wages" ? "active" : ""}>
                              <Link to="/manage_salary_wages">Manage Salary Wages</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_MONTHLYFINANCIAL") ? (
                            <li className={splitLocation[1] === "manage_financial" ? "active" : ""}>
                              <Link to="/manage_financial">Manage Financial</Link>
                            </li>
                          ) : null}
                        </ul>
                      </li>
                    ) : null}

                    {isPermission("PM_SETTINGMNG") ? (
                      <li className={master.some((item) => splitLocation[1] === item) ? "active" : ""}>
                        <a href="#master" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                          <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/gear--v2.png" alt="gear--v2" />
                          <span>Setting</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="master" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          {/* <li><Link to="/company">Company</Link></li>
                                <li><Link to="/company_list">Company List</Link></li> */}
                          {isPermission("PM_AREA") ? (
                            <li className={splitLocation[1] === "area" ? "active" : ""}>
                              <Link to="/area">Area</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_CITY") ? (
                            <li className={splitLocation[1] === "city" ? "active" : ""}>
                              <Link to="/city">City</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_DEPT") ? (
                            <li className={splitLocation[1] === "company_category" ? "active" : splitLocation[1] === "leaveassign" ? "active" : splitLocation[1] === "shiftassign" ? "active" : ""}>
                              <Link to="/company_category">Departments</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_DESIGNATION") ? (
                            <li className={splitLocation[1] === "designation" ? "active" : ""}>
                              <Link to="/designation">Designation</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_EMPGRP") ? (
                            <li className={splitLocation[1] === "employment_group" ? "active" : splitLocation[1] === "leaveassign" ? "active" : splitLocation[1] === "shiftassign" ? "active" : ""}>
                              <Link to="/employment_group">Employee Group</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_CMPHOLIDAY") ? (
                            <li className={splitLocation[1] === "company_holiday" ? "active" : ""}>
                              <Link to="/company_holiday">Holiday</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_WEEKLYOFF") ? (
                            <li className={splitLocation[1] === "weekly_off" ? "active" : ""}>
                              <Link to="/weekly_off">Weekly Off</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_LEAVEPOLICY") ? (
                            <li className={splitLocation[1] === "leave_policy" ? "active" : ""}>
                              <Link to="/leave_policy">Leave Policy</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_SHIFT") ? (
                            <li className={splitLocation[1] === "shift" ? "active" : ""}>
                              <Link to="/shift">Shift</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_LEAVEBALANCE") ? (
                            <li className={splitLocation[1] === "manual_leaves" ? "active" : ""}>
                              <Link to="/manual_leaves">Leave Balance Update</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_INSTITUTE") ? (
                            <li className={splitLocation[1] === "institute" ? "active" : ""}>
                              <Link to="/institute">Institute</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_UNIVERSITY") ? (
                            <li className={splitLocation[1] === "university_board" ? "active" : ""}>
                              <Link to="/university_board">University Board</Link>
                            </li>
                          ) : null}
                          {/* <li><Link to="/Earning_head">Earning Head</Link></li>
                                <li><Link to="/deduction_head">Deduction Head</Link></li>
                                <li><Link to="/reimbursement">Reimbursement</Link></li>
                                <li><Link to="/perk_head">Perk Head</Link></li> */}
                          <li className={splitLocation[1] === "Onboarding_docs" ? "active" : ""}>
                            <Link to="/Onboarding_docs">Onboarding Documents</Link>
                          </li>
                        </ul>
                      </li>
                    ) : null}

                    {isPermission("PM_TEAMMAG") ? (
                      <li>
                        <a href="#emp_manage1" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/conference-call--v1.png" alt="conference-call--v1" />
                          <span>Team Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="emp_manage1" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          {isPermission("PM_TEAMMEMLEV") ? (
                            <li>
                              <Link to="/team_member_leaves">Team Member Leaves</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_TEAMMEMATT")
                            ? // <li>
                              //   <Link to="/team_member_attendance">
                              //     Team Member Attendance
                              //   </Link>
                              // </li>
                              null
                            : null}

                          <li>{/* <Link to="/team_compoff_request"> Team Compoff Request </Link> */}</li>
                        </ul>
                      </li>
                    ) : null}

                    {isPermission("PM_REPORT") ? (
                      <li className={report.some((item) => splitLocation[1] === item) ? "active" : ""}>
                        <a href="#report" className={show === true ? "iq-waves-effect" : "iq-waves-effect collapsed"} data-toggle="collapse" aria-expanded={show}>
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/chart.png" alt="chart" />
                          <span>Report</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="report" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          {isPermission("PM_EMPRPT") ? (
                            <li className={splitLocation[1] === "employee_report" ? "active" : ""}>
                              <Link to="/employee_report">Employee Report</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_PUNCHTMRPT") ? (
                            <li className={splitLocation[1] === "punchtime_report" ? "active" : ""}>
                              <Link to="/punchtime_report">Punch Time Report</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_ATDRPT") ? (
                            <li className={splitLocation[1] === "daily_attendance_report" ? "active" : ""}>
                              <Link to="/daily_attendance_report">Attendance Report</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_MONATDRPT") ? (
                            <li className={splitLocation[1] === "monthly_attendance_report" ? "active" : ""}>
                              <Link to="/monthly_attendance_report">Monthly Attendance Report</Link>
                            </li>
                          ) : null}

                          {isPermission("PM_DAILTDEPARTURERPT") ? (
                            <li className={splitLocation[1] === "daily_departure_report" ? "active" : ""}>
                              <Link to="/daily_departure_report">Daily Early Departure Attendance</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_DAILYABSRPT") ? (
                            <li className={splitLocation[1] === "daily_absent_report" ? "active" : ""}>
                              <Link to="/daily_absent_report">Daily Absent Attendance</Link>
                            </li>
                          ) : null}
                          {isPermission("PM_DAILYLATEARRIVALRPT") ? (
                            <li className={splitLocation[1] === "daily_late_arrival_report" ? "active" : ""}>
                              <Link to="/daily_late_arrival_report">Daily Late Arrival Attendance</Link>
                            </li>
                          ) : null}

                          <li>
                            <Link to="/Employee_leave_report">Employee Leave Report</Link>
                          </li>
                          <li className={splitLocation[1] === "Employee_leave_record" ? "active" : ""}>
                            <Link to="/Employee_leave_record">Employee Leave Record</Link>
                          </li>

                          <li>
                            <Link to="/Employee_leave_taken">Employee Leave Taken</Link>
                          </li>
                        </ul>
                      </li>
                    ) : null}
                    {isPermission("PM_PM") ? (
                      <li className={permission.some((item) => splitLocation[1] === item) ? "active" : ""}>
                        <a href="#permission_manage" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="fa fa-cog"></i>
                          <span>Permission Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="permission_manage" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          {isPermission("PM_PM") ? (
                            <li className={splitLocation[1] === "permission_group_list" ? "active" : ""}>
                              <Link to="/permission_group_list">Permission Group List</Link>
                            </li>
                          ) : null}
                        </ul>
                      </li>
                    ) : null}

                    {isPermission("PEM_LEAVE_MGMT") ? (
                      <li>
                        <a href="#leaveCycle" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img className="mr-2" width="25" height="25" src="https://img.icons8.com/3d-fluency/94/event-accepted-tentatively.png" alt="event-accepted-tentatively" />
                          <span>Leave Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="leaveCycle" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          {/* <li className={splitLocation[1] === 'LeaveCycleSettings' ? "active" : ""}><Link to="/LeaveCycleSettings">Leave Cycle Settings</Link></li> */}
                          <li className={splitLocation[1] === "LeaveCategory" ? "active" : ""}>
                            <Link to="/LeaveCategory">Leave Category</Link>
                          </li>
                          <li className={splitLocation[1] === "CustomLeaveGroup" ? "active" : ""}>
                            <Link to="/CustomLeaveGroup">Custom Leave Group</Link>
                          </li>
                          <li className={splitLocation[1] === "LeavePolicyList" ? "active" : ""}>
                            <Link to="/LeavePolicyList">Leave Policy List</Link>
                          </li>
                          <li className={splitLocation[1] === "LeaveEmployee" ? "active" : ""}>
                            <Link to="/LeaveEmployee">Manage Employee Leaves</Link>
                          </li>
                          <li className={splitLocation[1] === "Manage_leave_category_wise" ? "active" : ""}>
                            <Link to="/Manage_leave_category_wise">Leave balance update</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_MGMT_ATTENDANCE") ? (
                      <li>
                        <a href="#Monthly_attendance_list" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/tear-off-calendar.png" alt="tear-off-calendar" />
                          <span>Attendance</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="Monthly_attendance_list" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Monthly_attendance_list" ? "active" : ""}>
                            <Link to="/Monthly_attendance_list">Attendance Setting</Link>
                          </li>

                          <li className={splitLocation[1] === "Manange_attendance_paidday" ? "active" : ""}>
                            <Link to="/Manange_attendance_paidday">Manage Set Paid Day </Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_MGMT_KRA") ? (
                      <li>
                        <a href="#Kra_Category" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/hard-working.png" alt="hard-working" />
                          <span>KRA Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="Kra_Category" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Kra_manage_list" ? "active" : ""}>
                            <Link to="/Kra_manage_list">Manage Role Wise KRAs</Link>
                          </li>
                          {/* <li className={splitLocation[1] === 'Manage_WorkForce' ? "active" : ""}><Link to="/Manage_WorkForce">Manage Work Force</Link></li> */}
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {company_id != 8 && company_id != 9 && company_id != 10 && isPermission("PEM_MGMT_WING") ? (
                      <li>
                        <a href="#Manage_wings1" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/journey.png" alt="journey" />
                          <span>Wing Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="Manage_wings1" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Wings_manage" ? "active" : ""}>
                            <Link to="/Wings_manage">Manage Wing</Link>
                          </li>
                          <li className={splitLocation[1] === "Wings_ClassAssignment" ? "active" : ""}>
                            <Link to="/WingClass_Assign">Wing-Class Assign</Link>
                          </li>
                          <li className={splitLocation[1] === "Work_force_planning" ? "active" : ""}>
                            <Link to="/Work_force_planning">Work Force Planning</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_Recruitment") ? (
                      <li>
                        <a href="#recruitment" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/job.png" alt="job" />
                          <span>Recruitment Settings</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="recruitment" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "hrms_forms" ? "active" : ""}>
                            <Link to="/hrms_forms">Hiring Forms Master</Link>
                          </li>
                          <li className={splitLocation[1] === "hrms_assessments" ? "active" : ""}>
                            <Link to="/hrms_assessments">Hiring Assessments Master</Link>
                          </li>
                          <li className={splitLocation[1] === "hrms_rounds" ? "active" : ""}>
                            <Link to="/hrms_rounds">Hiring Rounds Master</Link>
                          </li>
                          <li className={splitLocation[1] === "hrms_hiring_process" ? "active" : ""}>
                            <Link to="/hrms_hiring_process">Hiring Process Master</Link>
                          </li>
                          <li className={splitLocation[1] === "hrms_hiring_panel" ? "active" : ""}>
                            <Link to="/hrms_hiring_panel">Hiring Panel Master</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_Vacancy") ? (
                      <li>
                        <a href="#Work_force" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" src="https://img.icons8.com/3d-fluency/94/employee-card.png" alt="employee-card" />
                          <span>Vacancy</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="Work_force" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Work_force" ? "active" : ""}>
                            <Link to="/Work_force">Manage Vacancies</Link>
                          </li>
                          <li className={splitLocation[1] === "Work_force_hierarchy_level" ? "active" : ""}>
                            <Link to="/Work_force_hierarchy_level">Manage Work Force Hierarchy Level</Link>
                          </li>
                          <li className={splitLocation[1] === "Work_force_hierarchy" ? "active" : ""}>
                            <Link to="/Work_force_hierarchy">Manage Work Force Hierarchy</Link>
                          </li>
                          <li className={splitLocation[1] === "Work_force_auth" ? "active" : ""}>
                            <Link to="/Work_force_auth">Manage Work Force Authority</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_MGMT_KRA") ? (
                      <li>
                        <a href="#Hiring_dashboard" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/briefcase--v2.png" alt="briefcase--v2" />
                          <span>Hiring Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="Hiring_dashboard" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Hiring_dashboard" ? "active" : ""}>
                            <Link to="/Hiring_dashboard">Hiring Dashboard</Link>
                          </li>
                          {/* <li className={splitLocation[1] === 'Manage_assessment_forms' ? "active" : ""}><Link to="/Manage_assessment_forms">Manage Assessment Forms</Link></li> */}
                          <li className={splitLocation[1] === "hrms_additional_vacancy_list" ? "active" : ""}>
                            <Link to="/hrms_additional_vacancy_list">Manage Additional Vacancies</Link>
                          </li>
                          <li className={splitLocation[1] === "hrms_replacement_vacancy_list" ? "active" : ""}>
                            <Link to="/hrms_replacement_vacancy_list">Manage Replacement Vacancies</Link>
                          </li>
                          <li className={splitLocation[1] === "hrms_shortlisted_applicant_list" ? "active" : ""}>
                            <Link to="/hrms_shortlisted_applicant_list">Manage Shortlisted Applicants</Link>
                          </li>
                          <li className={splitLocation[1] === "consolidatedCandidateSheet" ? "active" : ""}>
                            <Link to="/consolidatedCandidateSheet">Consolidated Candidate Sheet</Link>
                          </li>
                          <li className={splitLocation[1] === "Rm_selected_applicants" ? "active" : ""}>
                            <Link to="/Rm_selected_applicants">Manage Selected Applicants</Link>
                          </li>
                          <li className={splitLocation[1] === "Hiring_approval_authority" ? "active" : ""}>
                            <Link to="/Hiring_approval_authority">Manage Approval Authority</Link>
                          </li>
                          <li className={splitLocation[1] === "/Verified_applicants" ? "active" : ""}>
                            <Link to="/Verified_applicants">Manage Verified Applicants</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_EMP_Onboarding") ? (
                      <li>
                        <a href="#onboarding" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/3d-fluency/94/plus.png" alt="briefcase--v2" />
                          <span>Employee Onboarding</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="onboarding" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Employee_boarding" ? "active" : ""}>
                            <Link to="/Employee_boarding">Manage Employee Boarding</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {isPermission("PEM_MGMT_Grievance") ? (
                      <li>
                        <a href="#Grievance_dept" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <img width="25" height="25" className="mr-2" src="https://img.icons8.com/arcade/64/add-bookmark.png" alt="briefcase--v2" />
                          <span>Grievance Management</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>
                        <ul id="Grievance_dept" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                          <li className={splitLocation[1] === "Grievance_dept" ? "active" : ""}>
                            <Link to="/Grievance_dept">Manage Grievance Department</Link>
                          </li>
                          <li className={splitLocation[1] === "Grievance_cat" ? "active" : ""}>
                            <Link to="/Grievance_cat">Manage Grievance Category</Link>
                          </li>
                          <li className={splitLocation[1] === "GrievanceList" ? "active" : ""}>
                            <Link to="/GrievanceList">All Grievances</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    <li>
                      <a href="#Appraiser_assign" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                        <img width="30" height="25" className="mr-2" src="https://img.icons8.com/arcade/64/employee-card.png" alt="briefcase--v2" />
                        <span>Appraisal Management</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                      </a>
                      <ul id="Appraiser_assign" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                        <li className={splitLocation[1] === "Grievance_dept" ? "active" : ""}>
                          <Link to="/Appraiser_assign">Appraiser Assign</Link>
                        </li>
                        <li className={splitLocation[1] === "Grievance_dept" ? "active" : ""}>
                          <Link to="/Appraiser_assigned_list">Appraiser Assigned List</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a href="#Resignation" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                        <img width="30" height="25" src="https://img.icons8.com/arcade/64/split-files.png" alt="split-files" />
                        <span>Separation</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                      </a>
                      <ul id="Resignation" className={show === true ? "iq-submenu collapse show" : "iq-submenu collapse"} data-parent="#iq-sidebar-toggle">
                        <li className={splitLocation[1] === "Grievance_dept" ? "active" : ""}>
                          <Link to="/Resignation">Resignation</Link>
                          <Link to="/Retirement">Retirement</Link>
                          {/* <Link to="/ExtensionRequested">Retirement Extension Requests</Link> */}
                          <Link to="/Termination">Termination</Link>
                          <Link to="/NoDuesHeads">No Dues Department Heads</Link>
                          <Link to="/NoDuesResig">No Dues - Resignation</Link>
                          <Link to="/NoDuesResignationInfo">No Dues Clearance - Resignation</Link>
                          <Link to="/Emp_exit_interviews">Exit Interviews</Link>
                        </li>
                      </ul>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </nav>
            <div className="p-3"></div>
          </div>
        </div>

        <div className="iq-top-navbar" style={page_styles.main_container_bg}>
          <div className="iq-navbar-custom">
            <div className="iq-sidebar-logo" style={{ background: "transparent" }}>
              <div className="top-logo">
                <img src={logo} className="img-fluid" alt="" />

                {window.innerWidth > "450px" && <h4 style={{ paddingLeft: "5px" }}>{company_name}</h4>}
              </div>
            </div>
            <div className="navbar-breadcrumb">
              <h4 className="text-muted" style={{ paddingLeft: "5px" }}>
                {company_name}
              </h4>
              {/* <h5 style={{ fontSize: "16px" }}>{company_name}</h5> */}
              {/* // <nav aria-label="breadcrumb">
                        //    <ul className="breadcrumb">
                        //       <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        //       <li className="breadcrumb-item active" aria-current="page">{breadcrumb_pages[splitLocation[1]]}</li>
                        //    </ul>
                        // </nav> */}

              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  {breadcrumb[0] != undefined ? (
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/dashboard">{breadcrumb[0]}</Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}
                  {breadcrumb[1] != undefined ? (
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={pathname}>{breadcrumb[1]}</Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}
                  {breadcrumb[2] != undefined ? (
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={pathname}>{breadcrumb[2]}</Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}
                  {breadcrumb[3] != undefined ? (
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={pathname}>{breadcrumb[3]}</Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}
                  {breadcrumb[4] != undefined ? (
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={pathname}>{breadcrumb[4]}</Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}
                  {breadcrumb[5] != undefined ? (
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to={pathname}>{breadcrumb[5]}</Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </nav>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light p-0">
              {user_typ === "3" ? (
                <>
                  <div className="col-md-3 col-sm-3"></div>

                  <ul className="navbar-list" style={{ float: "unset" }}>
                    <li style={{ paddingRight: "5px" }}>Select Company : </li>
                    <li>
                      <div className="form-group q-waves-effect" style={{ width: "130px", margin: "0px" }}>
                        {/* Select Company <br/> */}
                        <select className="form-control" value={company_id} onChange={(e) => oncompanyChange(e)}>
                          <option value="">All</option>
                          {allcompany.map((val) => {
                            return <option value={val.id}>{val.company_name}</option>;
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                ""
              )}

              <button className="navbar-toggler btn btn-sm" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                {" "}
                <i className="ri-menu-3-line"></i>{" "}
              </button>
              <div className="iq-menu-bt align-self-center">
                <div className="wrapper-menu">
                  <div className="line-menu half start"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu half end"></div>
                </div>
              </div>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-list">
                  <li className="nav-item iq-full-screen">
                    <a href="#" className="iq-waves-effect" id="btnFullscreen">
                      <i className="ri-fullscreen-line"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <ul className="navbar-list">
                <li>
                  <a href="#" className="search-toggle iq-waves-effect text-white" style={page_styles.profile}>
                    <img src={profile_image} width={20} height={20} className="rounded" />
                  </a>

                  <div className="iq-sub-dropdown iq-user-dropdown" style={page_styles.drop_down}>
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white line-height"> Hello {fullname} </h5>
                          <span className="text-white font-size-12"> Available </span>
                        </div>
                        <a href="#" className="iq-sub-card iq-bg-primary-hover">
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-file-user-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">My Profile</h6>
                              <p className="mb-0 font-size-12"> View personal profile details. </p>
                            </div>
                          </div>
                        </a>

                        <Link to={`/employee_upload_image`} className="iq-sub-card iq-bg-primary-hover">
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-file-user-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Change Profile Pic</h6>
                            </div>
                          </div>
                        </Link>

                        <div className="d-flex justify-content-around mb-3">
                          <button className="btn btn-danger btn-sm" onClick={logOut}>
                            Sign out
                          </button>
                          <button className="btn btn-sm btn-info" onClick={resetPassword}>
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
