import React, { useState, useEffect } from "react";
import Apiconnect from "../services/Apiconnect.js";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";

const pageStyles = {
  card_styles: {
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
    borderRadius: 10,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main_bg: {
    background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)",
    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card_head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    color: "white !important",
    background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
  },
};

const Rm_employer_verification = () => {
  const { id } = useParams();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [applicant_info, setApplicant_info] = useState([]);
  const [am_pmployer, setAm_pemployer] = useState('')

  const [inputs, setInputs] = useState({
    hvd_applicant_id: id,
    hvd_name: "",
    hvd_org: "",
    hvd_emp_code: "",
    hvd_desig: "",
    hvd_salary: "",
    hvd_reporting_manager: "",
    hvd_leaving_reason: "",
    hvd_duty_res: "",
    hvd_performance: "",
    hvd_exit_formalities: "",
    hvd_disciplinary_issue: "",
    hvd_rehire: "",
    hvd_remark: "",
    hvd_isGenuine: "",
    hvd_vname: "",
    hvd_vdesig: "",
    hvd_vcontact: "",
    hvd_tenure: "",
  });
  const { hvd_applicant_id, hvd_desig, hvd_disciplinary_issue, hvd_tenure, hvd_duty_res, hvd_emp_code, hvd_exit_formalities, hvd_isGenuine, hvd_leaving_reason, hvd_name, hvd_org, hvd_performance, hvd_rehire, hvd_remark, hvd_reporting_manager, hvd_salary, hvd_vcontact, hvd_vdesig, hvd_vname } = inputs;

  useEffect(() => {
    get_emp_info();
  }, []);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };


  const get_emp_info = () => {
    Apiconnect.postData(`selected_applicants/prev_employement_info/${id}`).then((res) => {
      const data = res?.data?.data;
      data.length > 0 && setAm_pemployer(data[0]?.am_prevEmployer)
      data.length > 0 && setApplicant_info(data[0]);
    });
  };

  let from = moment(applicant_info?.ame_from).format("DD/MM/YYYY");
  let to = moment(applicant_info?.ame_to).format("DD/MM/YYYY");

  const handleSubmit = () => {
    inputs['hvd_prev_employment_id'] = am_pmployer
    if (am_pmployer!== "" && hvd_desig !== "" && hvd_disciplinary_issue !== "" && hvd_tenure !== "" && hvd_duty_res !== "" && hvd_emp_code !== "" && hvd_exit_formalities !== "" && hvd_isGenuine !== "" && hvd_leaving_reason !== "" && hvd_name !== "" && hvd_org !== "" && hvd_performance !== "" && hvd_rehire !== "" && hvd_remark !== "" && hvd_reporting_manager !== "" && hvd_salary !== "" && hvd_vcontact !== "" && hvd_vdesig !== "" && hvd_vname !== "") {
      Apiconnect.postData(`selected_applicants/verify_emp_employer`, inputs)
        .then((res) => {
          alert.success("Employment Record Verified");
        })
        .catch((err) => {
          alert.error("Error Occured");
        });
    } else {
      alert.error("Remark Fields are empty");
    }
  };




  return (
      <div className="container-fluid">
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div className="row">
        <div className="col-md-12" style={pageStyles.main_bg}>
          <div className="iq-card" style={pageStyles.card_styles}>
            <div className="iq-card-header" style={pageStyles.card_head}>
              <h4 className="text-white">
                Request To Verify Employment Record Of{" "}
                <span className="font-weight-bold">
                  {applicant_info?.am_first_name} {applicant_info?.am_last_name}
                </span>{" "}
              </h4>
            </div>
            <div className="iq-card-body">
              <div className="row m-3">
                <div className=" col-md-12">
                  <div className="row mt-2 mb-2 bg-white" style={{ border: "3px solid #04BDEF", borderRadius: 10, padding: 20 }}>
                    <div className="table-responsive">
                      <table className="table table-sm bg-light table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "40%" }} className="text-center">
                              Details
                            </th>
                            <th className="text-center">Applicant Details</th>
                            <th className="text-center">Verifier Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Applicant Name</td>
                            <td>
                              {applicant_info.am_first_name} {applicant_info.am_last_name}
                            </td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_name" value={hvd_name} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Organization Name</td>
                            <td>{applicant_info.ame_name}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_org" value={hvd_org} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Employee Code</td>
                            <td>{applicant_info.ame_empCode}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_emp_code" value={hvd_emp_code} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Employment Tenure</td>
                            <td>
                              {from} - {to}
                            </td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_tenure" value={hvd_tenure} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Designation</td>
                            <td>{applicant_info.ame_designation}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_desig" value={hvd_desig} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted"> Salary</td>
                            <td>{applicant_info.ame_ctc_drawn}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_salary" value={hvd_salary} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted"> Reporting Manager</td>
                            <td>{applicant_info.ame_reportingManager}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_reporting_manager" value={hvd_reporting_manager} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Reason for Leaving</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_leaving_reason" value={hvd_leaving_reason} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Duties and Responsibilities</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_duty_res" value={hvd_duty_res} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Performance at Work</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_performance" value={hvd_performance} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Exit Formalities / Full and Final Settlement - Pending / Completed (If Pending - Please specify the reason)</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_exit_formalities" value={hvd_exit_formalities} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Disciplinary Issue during the tenure</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_disciplinary_issue" value={hvd_disciplinary_issue} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Eligibility for Re-hire</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_rehire" value={hvd_rehire} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Remarks (if any)</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_remark" value={hvd_remark} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Attached document is genuine or Not?</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_isGenuine" value={hvd_isGenuine} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Verifier Name</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_vname" value={hvd_vname} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Verifier Designation</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_vdesig" value={hvd_vdesig} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Verifier Contact Number</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_vcontact" value={hvd_vcontact} onChange={(e) => handleChange(e)} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                      <button className="btn btn-sm btn-info " onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_employer_verification;
