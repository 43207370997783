import React, { useEffect, useState } from "react";
import { pageStyles } from "../../../globalStyles";
import Apiconnect from "../../../services/Apiconnect";
import { useAlert } from "react-alert";
import toast, { Toaster } from "react-hot-toast";
import FullScreenLoading from "react-fullscreen-loading";

const Grievance_cat = () => {

  const [categoryList, setCategoryList] = useState([]);
  const [empListByCat, setEmpListByCat] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [inputs, setInputs] = useState({ gd_company_id: Apiconnect.get_company_id(), gd_dept_id: "", gd_dept_head_emp_id: "" });
  const [errors, setErrors] = useState({ gd_dept_id_error: "", gd_dept_head_emp_id_error: "" });
  const { gd_dept_id_error, gd_dept_head_emp_id_error } = errors;
  const { gd_dept_id, gd_dept_head_emp_id } = inputs;
  const [editId, setEditId] = useState("");

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getCategory();
    getList();
  }, []);

  const getCategory = () => {
    const company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setCategoryList(_xtract.reverse());
    });
  };

  const getEmpListByCat = async (catId) => {
    try {
      const dataInsert = {
        catId: catId,
        companyId: Apiconnect.get_company_id(),
      };
      const response = await Apiconnect.postData(`grievance/getEmpListByCat`, dataInsert);
      const result = response.data.data;
      setEmpListByCat(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const validateForm = () => {
    const error = {
      gd_dept_id_error: "",
      gd_dept_head_emp_id_error: "",
    };
    if (gd_dept_id === "") {
      error.gd_dept_id_error = "Department is not selected";
    }
    if (gd_dept_head_emp_id === "") {
      error.gd_dept_head_emp_id_error = "Employee head is not selected";
    }
    setErrors(error);
  };

  const getList = async () => {
    try {
      const response = await Apiconnect.postData(`grievance/getalldepartmentwiseHead/${Apiconnect.get_company_id()}`);
      const responseData = response.data.data;
      setList(responseData);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    setLoading(true);
    try {
      if (gd_dept_id !== "" && gd_dept_head_emp_id !== "") {
        if (editId) {
          const data = {
            gd_dept_head_emp_id: gd_dept_head_emp_id
          }
          const response = await Apiconnect.postData(`grievance/updateDeaprtmentHead/${editId}`, data)
          toast.success("Updated");
          setInputs({ gd_company_id: Apiconnect.get_company_id(), gd_dept_id: "", gd_dept_head_emp_id: "" });
          getList();
          setLoading(false);
          setEditId("")
        } else {
          const response = await Apiconnect.postData(`grievance/departmentHeadCreate`, inputs);
          toast.success("Created Successfully");
          setInputs({ gd_company_id: Apiconnect.get_company_id(), gd_dept_id: "", gd_dept_head_emp_id: "" });
          getList();
          setLoading(false);
        }
      } else {
        toast.error("Validation Error");
        setInputs({ gd_company_id: Apiconnect.get_company_id(), gd_dept_id: "", gd_dept_head_emp_id: "" });
        setLoading(false);
        setErrors({ gd_dept_id_error: "", gd_dept_head_emp_id_error: "" });
      }
    } catch (error) {
      toast.error("Error");
      setLoading(false);
      setTimeout(() => {
        setErrors({ gd_dept_id_error: "", gd_dept_head_emp_id_error: "" });
      }, 2000);
    }
  };

  const handleEdit = (id) => {
    setEditId(id);
    console.log(id);
    const filteredList = list.find((item) => item.gd_pk_id === id);
    setInputs({ gd_company_id: Apiconnect.get_company_id(), gd_dept_id: filteredList.gd_dept_id, gd_dept_head_emp_id: filteredList.gd_dept_head_emp_id });
    getEmpListByCat(filteredList.gd_dept_id);
  };

  const handleClear = () => {
    setEditId(false);
    setInputs({ gd_company_id: Apiconnect.get_company_id(), gd_dept_id: "", gd_dept_head_emp_id: "" });
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <Toaster />
      {loading ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div className="container-fluid">
        <div className="iq-card" style={pageStyles.main_container}>
          <div className="iq-card-header" style={pageStyles.card_head}>
            <h4 className="text-white" style={pageStyles.h4_bg}>
              Manage Grievance Department & Department Head
            </h4>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="iq-card m-3 p-3" style={pageStyles.main_container}>
                  <div className="iq-card-body">
                    <div className="row border rounded p-3">
                      <div className="col-md-4 m-2">
                        <span className="text-muted">
                          Select Department <span className="required">*</span>
                        </span>
                        <select
                          className="form-control text-center"
                          name="gd_dept_id"
                          value={gd_dept_id}
                          onChange={(e) => {
                            handleInputChange(e);
                            getEmpListByCat(e.target.value);
                          }}
                        >
                          <option value="" disabled selected>
                            Select Department
                          </option>
                          {categoryList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="required">{errors.gd_dept_id_error}</span>
                      </div>

                      <div className="col-md-4 m-2">
                        <span className="text-muted">
                          Select Department Head <span className="required">*</span>
                        </span>
                        <select className="form-control text-center" name="gd_dept_head_emp_id" value={gd_dept_head_emp_id} onChange={(e) => handleInputChange(e)}>
                          {empListByCat.length === 0 && (
                            <option value="" disabled selected>
                              Employees Not Assigned
                            </option>
                          )}
                          {empListByCat.length > 0 &&
                            empListByCat.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.first_name + " " + item.middle_name + " " + item.last_name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="required">{errors.gd_dept_head_emp_id_error}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center p-3">
                        <button className={`btn btn-sm ${editId ? "btn-info" : "btn-primary"} m-2`} onClick={(e) => handleSubmit(e)}>
                          {editId ? "Update" : "Submit"}
                        </button>
                        {editId && (
                          <button className={`btn btn-sm btn-secondary`} onClick={handleClear}>
                            Clear
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {list.length > 0 && (
                <div className="col-md-12">
                  <div className="iq-card m-3 p-3" style={pageStyles.main_container}>
                    <div className="iq-card-body">
                      <div className="row">
                        <div className="col-md-12 table-responsive">
                          <table className="table table-bordered pb-3" style={pageStyles.main_container}>
                            <thead className="thead-light">
                              <tr className="text-center">
                                <th>#</th>
                                <th>Department</th>
                                <th>Department Head</th>
                                <th>Status</th>
                                <th>Manage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.map((item, index) => {
                                let st = null;
                                if (item.gd_idActive === 1) {
                                  st = <span className="badge badge-primary badge-small">Active</span>;
                                } else {
                                  st = <span className="badge badge-danger badge-small">Inactive</span>;
                                }
                                return (
                                  <tr className="text-center" key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.departmentName}</td>
                                    <td>{item.name}</td>
                                    <td>{st}</td>
                                    <td>
                                      <div>
                                        <button className="btn btn-sm btn-info" onClick={() => handleEdit(item.gd_pk_id)}>
                                          Edit
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grievance_cat;
