import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table, TextArea } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
const RatingS = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FAAF00",
  },
  "& .MuiRating-iconHover": {
    color: "#FAAF00",
  },
});

const Emp_exit_interview = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);

  const [inputs, setInputs] = useState({
    eei_company_id: Apiconnect.get_company_id(),
    eei_emp_id: emp_id,
    eei_q1: "",
    eei_q2: "",
    eei_q3: "",
    eei_q4_1: "",
    eei_q4_2: "",
    eei_q5: "",
    eei_q6: "",
    eei_q7_1: "",
    eei_q7_2: "",
    eei_q7_3: "",
    eei_q7_4: "",
    eei_q7_5: "",
    eei_q7_6: "",
    eei_q7_7: "",
    eei_q7_8: "",
    eei_q7_9: "",
    eei_q7_10: "",
    eei_q7_11: "",
    eei_q7_12: "",
    eei_suggestions: "",
  });

  const { eei_q1, eei_q2, eei_q3, eei_q4_1, eei_q4_2, eei_q5, eei_q6, eei_q7_1, eei_q7_2, eei_q7_3, eei_q7_4, eei_q7_5, eei_q7_6, eei_q7_7, eei_q7_8, eei_q7_9, eei_q7_10, eei_q7_11, eei_q7_12, eei_suggestions } = inputs;
  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Questionnaires</th>
      <th>Rating (1 Not Satisfied – 5 Very Satisfied)</th>
    </tr>
  );

  const handleSubmit = () => {
    if (eei_q1 !== "" && eei_q2 !== "" && eei_q3 !== "" && eei_q4_1 !== "" && eei_q4_2 !== "" && eei_q5 !== "" && eei_q6 !== "" && eei_q7_1 !== "" && eei_q7_2 !== "" && eei_q7_3 !== "" && eei_q7_4 !== "" && eei_q7_5 !== "" && eei_q7_6 !== "" && eei_q7_7 !== "" && eei_q7_8 !== "" && eei_q7_9 !== "" && eei_q7_10 !== "" && eei_q7_11 !== "" && eei_q7_12 !== "" && eei_suggestions !== "") {
      console.log(inputs);
      Apiconnect.postData(`separation/emp_exit_interview`, inputs)
        .then((res) => {
          toast.success("Successfully Submitted");
        })
        .catch((err) => {
          toast.error("Already Submitted");
        });
    } else {
      toast.error("Some fields are empty");
    }
  };

  return (
    <Container heading="Employee Exit Interview">
      <Card>
        <div className="col-md-12 text-center">
          <span className="text-muted font-weight-bold">Exit Interview Questionnaire</span>
        </div>
      </Card>
      <Card>
        <TextArea type="text" className="col-md-12" label="  1. What attracted you to join the Jaipuria group? To what extent did you actually experience that?" placeholder="" name="eei_q1" value={eei_q1} onChange={(e) => onInputChange(e)} />
        <TextArea type="text" className="col-md-12" label="  2. What are the 2 best things about Jaipuria group you would like to talk about?" placeholder="" name="eei_q2" value={eei_q2} onChange={(e) => onInputChange(e)} />
        <TextArea type="text" className="col-md-12" label="  3. What are 2 things that you would like to change in the organization?" placeholder="" name="eei_q3" value={eei_q3} onChange={(e) => onInputChange(e)} />
        <div className="col-md-12">
          <label className="text-muted"> 4. In order of priority, what were the top two reasons for your resignation?</label>
          <div className="row">
            <TextArea type="text" className="col-md-12" placeholder="1." name="eei_q4_1" value={eei_q4_1} onChange={(e) => onInputChange(e)} />
            <TextArea type="text" className="col-md-12 mt-3" placeholder="2." name="eei_q4_2" value={eei_q4_2} onChange={(e) => onInputChange(e)} />
          </div>
        </div>
        <TextArea type="text" className="col-md-12" label="  5. If you were to reconsider your decision to move out, what could actually hold you back from going?" placeholder="" name="eei_q5" value={eei_q5} onChange={(e) => onInputChange(e)} />
        <TextArea type="text" className="col-md-12" label="  6. Would you like to join The Jaipuria Group again in future?" placeholder="" name="eei_q6" value={eei_q6} onChange={(e) => onInputChange(e)} />
        <div className="col-md-12">
          <label className="text-muted">7. How satisfied were you with: (1 Not Satisfied – 5 Very Satisfied)</label>
          <div className="row">
            <Table className="col-md-12" trHeader={trHeader}>
              <tr className="text-center">
                <td className="text-muted" style={{ width: "10%" }}>
                  1
                </td>
                <td className="text-muted" style={{ width: "60%" }}>
                  Your job profile
                </td>
                <td style={{ width: "30%" }}>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_1" value={eei_q7_1} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">2</td>
                <td className="text-muted">Your compensation</td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_2" value={eei_q7_2} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">3</td>
                <td className="text-muted">Your benefits</td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_3" value={eei_q7_3} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">4</td>
                <td className="text-muted">Working conditions & culture</td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_4" value={eei_q7_4} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">5</td>
                <td className="text-muted">Work life balance </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_5" value={eei_q7_5} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">6</td>
                <td className="text-muted">Internal communications </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_6" value={eei_q7_6} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">7</td>
                <td className="text-muted">Relationship with Immediate Supervisor </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_7" value={eei_q7_7} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">8</td>
                <td className="text-muted">Support from Authorities </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_8" value={eei_q7_8} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">9</td>
                <td className="text-muted">Your professional growth/development </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_9" value={eei_q7_9} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">10</td>
                <td className="text-muted">Learning Opportunity </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_10" value={eei_q7_10} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">11</td>
                <td className="text-muted">Interaction with your peers </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_11" value={eei_q7_11} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
              <tr className="text-center">
                <td className="text-muted">12</td>
                <td className="text-muted">Interaction with leadership </td>
                <td>
                  <RatingS style={{ color: "yellow" }} defaultValue={0} max={5} name="eei_q7_12" value={eei_q7_12} onChange={(e) => onInputChange(e)} />
                </td>
              </tr>
            </Table>
          </div>
        </div>
      </Card>
      <Card>
        <TextArea type="text" className="col-md-12" label="Suggestions" placeholder="" name="eei_suggestions" value={eei_suggestions} onChange={(e) => onInputChange(e)} />
        <div className="col-md-12 mt-3 text-center">
          <button className="btn btn-sm btn-info" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </Card>
    </Container>
  );
};

export default Emp_exit_interview;
