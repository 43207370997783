import React, { useState, useEffect,Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Apiconnect from "../services/Apiconnect.js";
import moment from 'moment'
import { fontSize } from "@mui/system";
import DatePicker from "react-datepicker";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { confirmAlert } from 'react-confirm-alert'; // Import

export default class combine_view_emp_team_member_leave extends Component {
  
  constructor(props) {
    super(props);

    this.state = {  
      from_date:'',
      to_date:'', 
      seluser_id:'',
      leaveList:[],
      empList:[],
      ListEmployeeLeaveUnlocklock:[],
    };  
  }   
    
    async componentDidMount() {
        var company_id = this.props.company_id;
        var emp = this.props.sel_user_id; 
        await this.leavedata(emp);
        await this.empdata(emp);
        await this.LoadGetEmployeeLeavesUnlocklocList(company_id);
    }

    leavedata = async (emp,pstfromdate='',psttodate='',pstseluser_id='') => {

        var data_pst={'pstfromdate':pstfromdate,'psttodate':psttodate,'pstseluser_id':pstseluser_id};
        await Apiconnect.postData(`employee_leave/leavereportingdata/${emp}`,data_pst).then((response) => { 
            this.setState({ leaveList: typeof response.data.data != "undefined" ? Apiconnect.decrypt_obj(response.data.data) : [] });
        });
    } 
    empdata =async (emp) => {
        await Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data); 
            this.setState({ empList:_xtract });
        });
    }
    LoadGetEmployeeLeavesUnlocklocList =async (company_id) => { 
        var data_pst={'emplu_company_id':company_id,'emplu_daymonthyear':moment(new Date()).format("YYYY-MM")}; 
        await Apiconnect.postData(`reports_module/report_manages/get_employee_leaves_unlockloc_lists`,data_pst).then((response) => {
            let ListXtract1 = Apiconnect.decrypt_obj(response.data.data);   
            var ListXtract2={};
            if(ListXtract1.length>0){
                ListXtract1.map((value_elul, key_elul) => {
                    ListXtract2[value_elul.emplu_emp_id]=value_elul.emplu_status_id;
                });
            }//end_if
            this.setState({ ListEmployeeLeaveUnlocklock:ListXtract2 });
        });
    }


  render () {   
    var emp = this.props.sel_user_id;  
   
    let leaveList1 = this.state.leaveList.length > 0 ? this.state.leaveList : [];
    let empList1 = this.state.empList.length > 0 ? this.state.empList : [];

    const setFromDate = async (evnt) => { 
       this.setState({ from_date: evnt });
    }; 
    const setToDate = async (evnt) => {
        this.setState({ to_date: evnt });
    }; 
    const setUserIds = async (evnt) => { 
        this.setState({ seluser_id: evnt.currentTarget.value });
    };

    const leaveApprove = e => { 
        confirmAlert({
           customUI: ({ onClose }) => {
             return (
              <div className='react-confirm-alert'>
                 <div className='react-confirm-alert-body'>
                    <h1>Are you sure?</h1>
                    <p>You want to Approve this Leave?</p>
                    <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                    <button className="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                    <button className="btn badge badge-pill badge-success" style={{marginLeft:20}}
                    onClick={() => {
                       let approval_note = document.getElementById("mr_comments").value; 
                       let info = { id: e, status: 1, approved_by: emp, approval_note:approval_note };
                       let info_enc = Apiconnect.encrypt_obj(info);
                       const inf_a = { enc: info_enc };
                       Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                          this.state.alert.success(response.data.message);
                          this.state.leavedata();
                       });
                       onClose();
                    }}
                    >
                    Yes, Approve Leave!
                    </button>
                 </div>
              </div>
             );
           }
        }); 
    }

    const leaveCancle = e => { 
        confirmAlert({
         customUI: ({ onClose }) => {
           return (
            <div className='react-confirm-alert'>
               <div className='react-confirm-alert-body'>
                  <h1>Are you sure?</h1>
                  <p>You want to Reject this Leave?</p>
                  <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                  <button className="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                  <button className="btn badge badge-pill badge-warning" style={{marginLeft:20}}
                  onClick={() => {
                     let approval_note = document.getElementById("mr_comments").value; 
                     if(approval_note.trim() == ''){
                        this.state.alert.error('Please write reason to Reject leave');
                       return false;
                     }
                     let info = { id: e, status: 2, approved_by: emp, approval_note:approval_note };
                     let info_enc = Apiconnect.encrypt_obj(info);
                     const inf_a = { enc: info_enc };
                     Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                        this.state.alert.success(response.data.message);
                        this.state.leavedata();
                     });
                     onClose();
                  }}
                  >
                  Yes, Reject Leave!
                  </button>
               </div>
            </div>
           );
        }
       }); 
    }
  
    const leaveListExcel = leaveList1.map((item) => ({
        fullname: `${item.first_name} ${item.last_name}`,
        applied_on: item.applied_on,
        name: item.name,
        reason:item.reason,
        leave_count:item.leave_count,
        day_type: item.day_typ,
        date:`${item.leave_start_date1} ${item.leave_start_date1}`,
        status: item.status
    }));

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    const saveAsExcel = () => {
        let header = ["fullName", "applied_on", "name", "reason", "leave_count", "day_type", "date", "status"]
      
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = getSheetData(leaveListExcel, header);
          const totalColumns = sheetData[0].length;
      
          sheet1.cell("A1").value(sheetData);
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true); 
          sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "TeamLeaves.xlsx");
          });
        });
    }
 
    
    const submit_filter =async () => { 
        var pstfromdate=this.state.from_date;
        var psttodate=this.state.to_date;
        var pstseluser_id=this.state.seluser_id;
        await this.leavedata(emp,pstfromdate,psttodate,pstseluser_id);
    }
 
    return ( 
        
      
      <div> 
        <div className="row">
            <div className="col-sm-12">
                <div className="iq-card">
                <div>
                    <h4 className="card-title hd">Team Member Leaves</h4>
                </div>
                <div className="iq-card-body">

                    <div className="row p_t_15">
                        <div className="col-md-2">
                            From :  <br />
                            <DatePicker
                                selected={this.state.from_date}
                                onChange={e => setFromDate(e)}
                                className="form-control"
                                dateFormat='yyyy-MM-dd'
                                placeholderText="From Date"
                                name="from_date"
                                required=""
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                scrollableMonthDropdown
                                yearDropdownItemNumber={100}
                                dropdownMode="select"
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-2">
                            TO :  <br /> 
                            <DatePicker
                                selected={this.state.to_date}
                                onChange={e => setToDate(e)}
                                className="form-control"
                                dateFormat='yyyy-MM-dd'
                                placeholderText="To Date"
                                name="to_date"
                                required=""
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                scrollableMonthDropdown
                                yearDropdownItemNumber={100}
                                dropdownMode="select"
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-3">
                            User : <br />
                            <select className="form-control" name="emp_id" value={this.state.seluser_id} onChange={e => setUserIds(e)}>
                            <option value="">Select User</option>
                            {empList1.map((val, key) => {
                                return (
                                    <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                );
                            })}
                            </select>
                        </div>
                        <div className="col-md-2">
                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={() => submit_filter()}/>
                        </div>
                        <div className='col-md-3'>
                            <button className='btn btn-warning float-right' onClick={() => saveAsExcel()} style={{ marginTop: "23px" }}><i className="fa fa-download" aria-hidden="true"></i>Team Leave List Excel</button>
                        </div>     
                    </div> 

                    <div className="row">

                        <div className="table-responsive">
                            
                            <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                            <thead>
                                <tr className="clr" >
                                    <th>#</th>
                                    <th>Emp. Name</th>
                                    <th>Applied On</th>
                                    <th>Leave Type</th>
                                    <th>Reason</th>
                                    <th>Days</th>
                                    <th>Half Day Type</th>
                                    <th>Date</th>
                                    <th>Opening</th>
                                    <th>Available</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (leaveList1.length>0) ?  (
                                        leaveList1.map((val, key) => {

                                            var action1 = '';
                                            if (val.status === 0) {
                                                var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                                var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                                var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Reject</div>;
                                            }
                                            if (val.status === 1) {
                                                var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                                var action = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Reject</div>;
                                            }
                                            if (val.status === 2) {
                                                var status = <div className=" badge badge-pill badge-danger">Rejected</div>;
                                                var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                            }

                                            var is_lockunlock=(typeof this.state.ListEmployeeLeaveUnlocklock[val.id]!='undefined')?this.state.ListEmployeeLeaveUnlocklock[val.id]:0;
                                            // console.log(val.id+' == '+is_lockunlock);
                                            
                                            return (
                                                <tr key={key}>
                                                    <th scope="row">{key + 1}</th>
                                                    <td>{val.first_name} {val.last_name}</td>
                                                    <td>{val.applied_on}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.reason}</td>
                                                    <td>{val.leave_count}</td>
                                                    <td style={{textAlign:'center'}}>{val.day_typ ? val.day_typ:'-'}</td>
                                                    <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{status}</td>
                                                    <td>
                                                        {
                                                            (is_lockunlock==1)?(
                                                                <span style={{color:'red'}}>Attendance Locked For This Period.</span> 
                                                            ):(
                                                               <span>{action} {action1}</span> 
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }) 
                                    ) : (
                                        <tr>
                                            <th colSpan={12} style={{textAlign:'center'}}> No Data Found.. </th>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
      </div> 
    );
  }
}
