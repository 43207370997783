import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link} from 'react-router-dom/cjs/react-router-dom.min.js';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "70%" ,
        height: '80%'
    },
};


const Manage_shortlisted = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [hp_list, setHp_list] = useState([])
    const [shortlisted_list, setShortlisted_list] = useState([])
    const [hp_id, setHp_id] = useState('')

    useEffect(() => {get_hplist(); get_shortlisted_list()}, [])

    const get_hplist = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setHp_list(res.data.data)
        })
    }

    const get_shortlisted_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_shortlisted_list/${company_id}`).then(res => {
            setShortlisted_list(res.data.data)
            setFullScreenLoader(false)
        })
    }

    const onInputChange = (e) => {
        if (e.target.checked === true) {
            setSelected({
            [e.currentTarget.name]: e.currentTarget.value,
            });
        } else {
            setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
    }

    const handleAssign = () => {
        let ins_ob = {}
    }


  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>                                          {/* ---Modal-1--- onRequestClose={() => setModalIsOpen(false)}*/}  {/* ------Manage Rounds----- */}
                <div className='iq-card'>
                    <div className='iq-card-header d-flex justify-content-between model_head'>
                        <div style={{ float: "left" }} className="iq-header-title">
                            <h4 className="card-title">Assign Shortlisted Candidate</h4>
                        </div>
                        <button className='btn badge btn-sm btn-dark' onClick={() => {setModalIsOpen(false)}}>close</button>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <select className='form-control' name="hp_id" value={hp_id} onChange={(e) => setHp_id(e.target.value)}>
                                            <option value="" disabled selected>Select Hiring Process</option>
                                            {
                                                hp_list.map((item, index) => {
                                                    return(
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <button className='btn btn-sm btn-info float-right' onClick={handleAssign}>Assign</button>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <table className='table table-striped table-bordered center'>
                                    <thead>
                                        <tr >
                                            {/* <th><input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /></th> */}
                                            <th colSpan={2}>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Shortlisted For</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            shortlisted_list.length > 0 ?
                                            (
                                                shortlisted_list.map((item, index) => {

                                                    let ck = Object.keys(selected).find(function (element) {
                                                        return selected[element] == item.id;
                                                    });  
                                                    
                                                    var ck1 = "";
                                                    if (ck !== undefined) {
                                                        ck1 = true;
                                                    }

                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row"><input type="radio" name={item.id} value={item.id} checked={ck1} onChange={(e) => onInputChange(e)}/></th>
                                                            <td>{index + 1}</td>
                                                            <td>{item.am_first_name} {item.am_middle_name} {item.am_last_name}</td>
                                                            <td>{item.am_email}</td>
                                                            <td>{item.am_position}</td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <tr>
                                                    <th colSpan={6}>No Data Found...</th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>  
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card' style={{position: "sticky", top: 0}}>
                            <div className='iq-card-header d-flex justify-content-between model_head'>
                                <div className='iq-header-title' style={{float: "left"}}>
                                    <h4 className='card-title'>Manage Shortlisted</h4>
                                </div>
                                <Link to={`/Hiring_dashboard`}><button className="btn btn-sm btn-dark">Back</button></Link>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-primary float-right' onClick={() => setModalIsOpen(true)}>Assign Hiring Process</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Manage_shortlisted