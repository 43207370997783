import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Retirement = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);

  const [list, setList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getEmpInfo();
  }, []);

  console.log(list);

  const getEmpInfo = () => {
    try {
      Apiconnect.postData(`separation/empRetirementInfoByEmpId/${user_id}`).then((res) => {
        const data = Apiconnect.decrypt_obj(res.data.data);
        setList(data[0]);
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  console.log(list);

  const handleRequest = (id) => {
    try {
      Apiconnect.postData(`separation/requestExtension/${Apiconnect.get_company_id()}/${list.id}`)
        .then((res) => {
          toast.success("Successfully requested for extension");
          getEmpInfo();
        })
        .catch((err) => {
          toast.error("Already Requested For Extension");
          console.log(err.message);
        });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <Container heading="Retirement p-2">
      <Table className="col-md-12" trHeader="">
        <tr className="text-center">
          <td className="text-muted">Retirement Date</td>
          <td>
            <span className="text-muted">{list.retirement_on}</span>
          </td>

          <td>
            {list.extension_request !== 1 && (
              <button className={`btn btn-sm btn-info`} onClick={() => handleRequest(list.id)}>
                Request Extension
              </button>
            )}
            {list.extension_request == 1 && <span className="badge badge-pill badge-success">Applied For Extension</span>}
          </td>
        </tr>
      </Table>
    </Container>
  );
};

export default Retirement;
