import React, {useState, useEffect} from 'react'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Apiconnect from '../../services/Apiconnect'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import Modal from 'react-modal';
import { tr } from 'date-fns/locale';
/* ---Modal--- */
const customStyles = {
    content: {
       top: '55%',
       left: '55%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "50%" ,
    },
};
const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }
 }

const Work_force_auth = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id();
    const alert = useAlert();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [editId, setEditId] = useState("")
    const [desList, setDesList] = useState([]);  //Designation list
    const [desId, setDesId] = useState("") //wfa_user_type
    const [empList, setEmpList] = useState([])
    const [empId, setEmpId] = useState('') //wfa_user_id
    const [list, setList] = useState('')

    useEffect(() => {getDesList(); auth_list()}, []);


    const getDesList = () => {
        setFullScreenLoader(true)
        Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
            setFullScreenLoader(false)
            let _xtract = response.data.data;
            setDesList(_xtract.reverse());      
        });
    };

    const getEmpList = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_auth_get_emplist_by_designation/${id}/${company_id}`).then((res) => {
            setFullScreenLoader(false)
            setEmpList(Apiconnect.decrypt_obj(res.data.data))     
        });
    }

    const handleCreate = () => {
        setFullScreenLoader(true)
        let ins_ob = {wfa_user_id: empId, wfa_user_type:desId}
        Apiconnect.postData(`work_force/work_force_auth_create_authority/${company_id}`, ins_ob).then(res => {
            setDesId("")
            setEmpId('')
            setFullScreenLoader(false)
            auth_list()
            setModalIsOpen(false)
            alert.success("Created")
        })
    }

    const auth_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_auth_list/${company_id}`).then(res =>{
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setFullScreenLoader(false)
            setList(_xtract)
        })
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_auth_delete/${id}`).then(res => {
            setFullScreenLoader(false)
            auth_list()
            alert.success("Deleted")
        })
    }




  return (
    <>

        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />) : null }
        <div className='content-page' style={page_styles.main_container_bg}>
            <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false} onRequestClose={() => setModalIsOpen(false)}>
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head"
                     style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                    >
                        <div className="iq-header-title">
                            <h4 className="card-title center text-white">{editId ? "Update Work Force Authority" : "Create Work Force Authority"}</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="row">
                            <div className='col-md-4 mb-2'>
                                <select className='form-control' name='desId' value={desId} onChange={(e) => {setDesId(e.target.value); getEmpList(e.target.value)}}>
                                    <option value="" disabled selected>---Select Designation---</option>
                                    {
                                        desList.map((item, index) => {
                                            return(
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-4 mb-2'>
                                <select className='form-control' name='empId' value={empId} onChange={(e) => setEmpId(e.target.value)}>
                                    <option value="" disabled selected>---Select Employee---</option>
                                    {
                                        empList.map((item, index) => {
                                            return(
                                                <option key={index} value={item.id}>{item.first_name + "" + item.last_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-4 mb-2'>
                                <button className='btn btn-sm btn-info' onClick={handleCreate}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='iq-card'>
                <div className="iq-card-header d-flex justify-content-between model_head"
                 style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                >
                    <div className="iq-header-title">
                        <h4 className="card-title text-white">Work Force Authority</h4>
                    </div>
                </div>
                <div className='iq-card-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <button className='btn btn-sm btn-primary float-right' onClick={() => setModalIsOpen(true)}>Create</button>
                        </div>
                    </div>
                    <div className='row m-2'>
                    <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                        <thead className="">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Position Level</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {   
                                            list.length>0 ?  (

                                               list.map((item, index) => {
                                                let st = null
                                                if(item.status === 1){
                                                    st = "Active"
                                                }else{
                                                    st = "Inactive"
                                                }
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.designation}</td>
                                                        <td>{item.hierarchy_level}</td>
                                                        <td>{st}</td>
                                                        <td>
                                                            <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                )
                                               })
                                            ) : (
                                                <tr>
                                                    <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                </tr>
                                            ) 
                                        } 
                                        </tbody>
                                    </table>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Work_force_auth