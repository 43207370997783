import React, { useState, useEffect } from 'react';
import "./ems_pages.css";
import { useHistory } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";
import moment from 'moment';

const Salary_slip = () => {

let history = useHistory();
const localuser = JSON.parse(localStorage.getItem('user'));
let emp = Apiconnect.decrypt(localuser.id);

useEffect(() => { getSalarySlipData(); }, []);
const [salaryMonths, setSallaryData] = useState([]);

const getSalarySlipData = (selyear='') => {
   Apiconnect.postData(`employee_monthly_salary/getsalaryslipmonths/${emp}`,{selyear:selyear}).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setSallaryData(_xtract.reverse());
   });

};

  const viewItem = (year,month) => {
   // history.push(`/salaryslip/${emp}/${year}/${month}`);    
   history.push(`/ems/salary_slip_view/${month}/${year}`);   
   }

   const months = {'01' :'JAN','02' :'FEB','03' :'MAR','04' :'APR','05' :'MAY',
                   '06' :'JUN','07' :'JUL','08' :'AUG','09' :'SEP',10 :'OCT',11 :'NOV',12 :'DEC'};

   const onInputChange = e => {  
      var sel_year=e.target.value;
      getSalarySlipData(sel_year);
   };

   var current_date=new Date();
   let current_year=moment(new Date(current_date)).format("YYYY");

   var list_years=[];
   for(var cnt_year=2020;cnt_year<=current_year;cnt_year++){ 
      list_years[cnt_year]=cnt_year;
   } 

   return (
        <>



       <div id="content-page" className="content-page">
             <div className="container-fluid">
                
                <div className="row" id="page">
                     <div className="col-sm-12">
                        <div className="iq-card">
                      <div className="head">
                          <h4 className="card-title head1">Salary Slip</h4>
                     </div>
                     <div className="iq-card-body">

                     <div className="row"> 
                      <div className="form-group col-md-12">
                      <div className="form-group col-md-6">
                        <table>
                          <tr>
                            <td><label>Year</label></td>
                            <td>&nbsp;</td>
                            <td>
                              <select className="form-control1" onChange={e => onInputChange(e)}>
                                <option value=""> All Year </option>
                                {
                                  list_years.map((val, key) => {
                                    return(
                                      <option value={val}>{val}</option>
                                    )  
                                  })
                                }
                              </select>
                            </td>
                          </tr>
                        </table> 
                      </div>  
                      </div>
                    </div>
                     
                        <div className="table-responsive">
                           
                           <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                             <thead>
                                 <tr className="clr" style={{textAlign:'center'}}>
                                    <th>#</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                 </tr>
                             </thead>
                             <tbody> 
                                 {  
                                    salaryMonths.length>0 ? (
                                       salaryMonths.map((value,key)=>{ 
                                          return (
                                          <tr key={key} style={{textAlign:'center'}}>
                                             <td style={{width:'150px'}} >{key+1}</td>
                                             <td style={{textAlign:'center'}}>{months[value.month]} - {value.year}</td>
                                             <td style={{width:'200px'}}>
                                                <button  className="btn btn-info mr-2" onClick={() => viewItem(value.year,value.month)}><i className="fa fa-eye" aria-hidden="true"></i></button>
                                             </td>
                                          </tr>
                                          )
                                       })
                                    ) : (
                                       <tr>
                                          <th colSpan={3} style={{textAlign:'center'}}> No data found. </th>
                                       </tr>
                                    )
                                 }
                             </tbody>
                          
                           </table>
                        </div>
                     </div>
                  </div>
                  </div>
                </div>

          </div>
        </div>



        </>
    )
}

export default Salary_slip
