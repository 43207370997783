import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link} from 'react-router-dom/cjs/react-router-dom.min.js';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "70%" ,
        height: '80%'
    },
};


const Hiring_process_vac_assign = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [hp_list, setHp_list] = useState([])
    const [hp_id, setHp_id] = useState('')
    const [desList, setDesList] = useState([])
    const [selected, setSelected] = useState([]);
    const [vac_list, setVacList] = useState([])

    useEffect(() => {get_hplist(); vaclist_getall(); get_vacancy_hp_list()}, [])

    const get_hplist = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setHp_list(Apiconnect.decrypt_obj(res.data.data))
        })
    }
    const vaclist_getall = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_requisition_getall/${company_id}`, {company_id: company_id}).then(res => {
            setFullScreenLoader(false)
            setVacList(Apiconnect.decrypt_obj(res.data.data))
            console.log(res)
        }).catch(err => console.log(err))
    }


    const onInputChange = (e) => {
        if (e.target.checked === true) {
            setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
            });
        } else {
            setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
    }


    const handleAssign = () => {
        if(Object.keys(selected).length > 0){
            let ins_ob = {hpad_company_id: company_id, hpad_hiring_process_id:hp_id, vac_lists:selected, hpad_added_by:emp_id}
            setFullScreenLoader(true)
            Apiconnect.postData(`hiring_application/hiring_process_assign_vac_create`, ins_ob).then(res => {
                setFullScreenLoader(false)
                get_vacancy_hp_list()
                alert.success("Assigned Successfully")
                setSelected([])
                setHp_id('')
                setModalIsOpen(false)
            })
        }else{
            alert.error("Please Select designation")
        }
    }

    const [dhp_list, setDhp_list] = useState([])
    const get_vacancy_hp_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_assign_vac_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setDhp_list(res.data.data)
        })
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_assign_vac_delete/${id}`).then(res => {
            setFullScreenLoader(false)
            get_vacancy_hp_list()
            alert.success("Deleted Successfully")
        })
    }

    console.log(dhp_list)



  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <div className='container-fluid'>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>                                          {/* ---Modal-1--- onRequestClose={() => setModalIsOpen(false)}*/}  {/* ------Manage Rounds----- */}
                <div className='iq-card'>
                    <div className='iq-card-header d-flex justify-content-between model_head'>
                        <div style={{ float: "left" }} className="iq-header-title">
                            <h4 className="card-title">Vacancy wise Hiring Process</h4>
                        </div>
                        <button className='btn badge btn-sm btn-dark' onClick={() => {setModalIsOpen(false)}}>close</button>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <select className='form-control' name="hp_id" value={hp_id} onChange={(e) => setHp_id(e.target.value)}>
                                            <option value="" disabled selected>Select Hiring Process</option>
                                            {
                                                hp_list.map((item, index) => {
                                                    return(
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <button className='btn btn-sm btn-info float-right' onClick={handleAssign}>Assign</button>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <table className='table table-striped table-bordered center'>
                                    <thead>
                                        <tr >
                                            {/* <th><input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /></th> */}
                                            <th colSpan={2}>#</th>
                                            <th>Vacancy Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            vac_list.length > 0 ?
                                            (
                                                vac_list.map((item, index) => {

                                                    let ck = Object.keys(selected).find(function (element) {
                                                        return selected[element] == item.wf_pk_id;
                                                    });  
                                                    
                                                    var ck1 = "";
                                                    if (ck !== undefined) {
                                                        ck1 = true;
                                                    }

                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row"><input type="checkbox" name={item.wf_pk_id} value={item.wf_pk_id} checked={ck1} onChange={(e) => onInputChange(e)}/></th>
                                                            <td>{index + 1}</td>
                                                            <td>{item.wf_position}</td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <tr>
                                                    <th colSpan={6}>No Data Found...</th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> 
                <div className='iq-card'>
                    <div className='iq-card-header d-flex justify-content-between model_head'>
                        <div className='iq-header-title' style={{float: "left"}}>
                            <h4 className='card-title'>Vacancy wise Hiring Process</h4>
                        </div>
                        <Link to={`/Hiring_process`}><button className='btn btn-sm btn-dark'>Back</button></Link>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <button className='btn btn-sm btn-primary float-right' onClick={() => setModalIsOpen(true)}>Assign Hiring Process</button>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <table className='table table-striped table-bordered center'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Vacancy Name</th>
                                            <th>Hiring Process</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dhp_list.length > 0 ? 
                                            (
                                                dhp_list.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.designation_name}</td>
                                                            <td>{item.hiring_process_name}</td>
                                                            <td>
                                                                <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.id)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <tr>
                                                    <th colSpan={4}>No Data Found</th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_process_vac_assign