import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./index.css";
import { Toaster } from "react-hot-toast";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { Provider } from "react-redux";


const options = {
  timeout: 3000,
};

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Toaster />
        <App />
      </AlertProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
