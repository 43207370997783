import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "50%" ,
        height: '30%'
    },
};


const Hiring_round_panel = () => {

    const alert = useAlert(); 
    const company_id = Apiconnect.get_company_id()
    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [desList, setDesList] = useState([]);
    const [inputs, setInputs] = useState({srp_company_id: company_id, srp_name:"", srp_des_for:"",srp_des:"",srp_panel_emp: "",srp_added_by:emp_id})
    const [editId, setEditId] = useState("")
    const [panel_list, setPanel_list] = useState([])
    const [empList, setEmpList] = useState([])

    const {srp_added_by,srp_company_id,srp_des,srp_des_for,srp_name,srp_panel_emp} = inputs

    useEffect(() => {getDesignation(); get_panel_list()}, [])

    const get_panel_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_round_panel_getall/${company_id}`).then(res => {
            setPanel_list(res.data.data)
            setFullScreenLoader(false)
        })
    }

    const getDesignation = () => {
        Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = response.data.data;
           setDesList(_xtract.reverse());      
        });
    };

    const emp_list_by_designation = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_round_panel_list_by_designation/${company_id}/${id}`).then(res => {
            setEmpList(res.data.data)
            setFullScreenLoader(false)
        })
    }

    const onInputChange = (e) => {
        e.preventDefault()
        setInputs({...inputs, [e.target.name]:e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(inputs)
        if(srp_des !== "" && srp_des_for !=="" && srp_name!== "" && srp_panel_emp!==""){
            if(editId !== ""){
                let ins_ob = {srp_des:srp_des, srp_des_for:srp_des_for, srp_name:srp_name, srp_panel_emp:srp_panel_emp}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hirring_round_panel_update/${editId}`, ins_ob).then((res) => {
                    get_panel_list()
                    setFullScreenLoader(false)
                    alert.success("Created Updated")
                    setInputs({srp_company_id: company_id, srp_panel_emp:"", srp_name:"", srp_des_for:"",srp_des:"",srp_added_by:emp_id})
                    setModalIsOpen(false)
                })

            }else{
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_round_panel_create/${company_id}`, inputs).then((res) => {
                    get_panel_list()
                    setFullScreenLoader(false)
                    alert.success("Created Successfully")
                    setInputs({srp_company_id: company_id,srp_panel_emp:"", srp_name:"", srp_des_for:"",srp_des:"",srp_added_by:emp_id})
                    setModalIsOpen(false)
                })
            }
        }else{
            alert.error("Inputs fields are empty")
        }
    } 

    const handleEdit = (id) => {
        setEditId(id)
        const data = panel_list.find((item) => item.id === id)
        setInputs({srp_name:data.srp_name, srp_des_for:data.srp_des_for,srp_des:data.srp_des,srp_panel_emp:data.srp_panel_emp})
        setModalIsOpen(true)
    }
    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hirring_round_panel_delete/${id}`).then(res => {
            setFullScreenLoader(false)
            get_panel_list()
            alert.success("Deleted Successfully")
        })
    }
    const handleClose = () => {
        setModalIsOpen(false)
        setEditId("")
        setInputs({srp_company_id: company_id,srp_panel_emp:"", srp_name:"", srp_des_for:"",srp_des:"",srp_added_by:emp_id})
    }

    console.log(panel_list)

  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className='iq-card'>
                    <div className='iq-card-header d-flex justify-content-between model_head'>
                        <div style={{ float: "left" }} className="iq-header-title">
                            <h4 className="card-title">Hiring Round Panel</h4>
                        </div>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-12 mb-2'>
                                <input type="text" className='form-control' placeholder='Panel Name' name='srp_name' value={srp_name} onChange={e => onInputChange(e)} />
                            </div>
                            <div className='col-md-12  mb-2'>
                                <select className='form-control' name='srp_des_for' value={srp_des_for} onChange={e => onInputChange(e)}>
                                    <option value=""> ---Select Designation For--- </option>
                                    {
                                        desList.map((item, index) => {
                                            return(
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-12  mb-2'>
                                <select className='form-control' name='srp_des' value={srp_des} onChange={e => {onInputChange(e); emp_list_by_designation(e.target.value)}}>
                                    <option value=""> ---Select Designation--- </option>
                                    {
                                        desList.map((item, index) => {
                                            return(
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-12  mb-2'>
                                <select className='form-control' name='srp_panel_emp' value={srp_panel_emp} onChange={e => onInputChange(e)}>
                                    <option value=""> ---Select Panel Employee--- </option>
                                    {
                                        empList.map((item, index) => {
                                            return(
                                                <option value={item.id} key={index}>{item.first_name+" "+item.middle_name+" "+item.last_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-12 mt-3 d-flex justify-content-center'>
                                <button className='btn btn-sm btn-info m-2' onClick={(e) => handleSubmit(e)}>{editId ? "Update" : "Create"}</button>
                                <button className='btn btn-sm btn-dark m-2' onClick={handleClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className='iq-card-header d-flex justify-content-between model_head'>
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Hiring Round Panel</h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-primary float-right' onClick={() => setModalIsOpen(true)}>Create Panel</button>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <table className='table table-striped table-bordered table-sm center table-hover'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Panel Name</th>
                                                    <th>Panel For</th>
                                                    <th>Panel Designation</th>
                                                    <th>Panel Employee</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    panel_list.length > 0 ? 
                                                    (
                                                        panel_list.map((val, key) => {
                                                            return(
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>{val.srp_name}</td>
                                                                    <td>{val.des_for}</td>
                                                                    <td>{val.des}</td>
                                                                    <td>{val.panel_emp_name}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => handleEdit(val.id)}>Edit</button>
                                                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete(val.id)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_round_panel