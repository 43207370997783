import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";

const customStyles = { content: { top: "55%", left: "55%", right: "auto", bottom: "auto", transform: "translate(-50%, -50%)", width: "40%", height: "40%" } };

const Rm_assessment_master = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [list, setList] = useState([]);
  const [ha_name, setHa_name] = useState("");
  const [edit_id, setEdit_id] = useState("");
  const [form_id, setForm_id] = useState("");
  const [name, setName] = useState("");
  const [assessment_id, setAssessment_id] = useState("");

  useEffect(() => {
    get_assessment_list();
    get_forms_list();
  }, []);

  const handleCreate = (e) => {
    e.preventDefault();
    if (edit_id !== "") {
      if (ha_name !== "") {
        setFullScreenLoader(true);
        let data_ins = {
          ha_company_id: company_id,
          ha_name: ha_name,
          ha_form_id: form_id,
        };
        Apiconnect.postData(`hrms_assessments/update/${edit_id}`, data_ins).then((res) => {
          alert.success("Updated Successfully");
          setFullScreenLoader(true);
          get_assessment_list();
          setHa_name("");
          setEdit_id("");
          setForm_id("");
        });
      } else {
        alert.error("Please Enter Assessments");
      }
    } else {
      if ((ha_name !== "") & (form_id !== "")) {
        setFullScreenLoader(true);
        let data_ins = {
          ha_company_id: company_id,
          ha_name: ha_name,
          ha_created_by: emp_id,
          ha_form_id: form_id,
        };
        Apiconnect.postData(`hrms_assessments/create`, data_ins).then((res) => {
          alert.success("Created Successfully");
          setFullScreenLoader(true);
          get_assessment_list();
          setHa_name("");
          setForm_id("");
        });
        console.log(data_ins);
      } else {
        alert.error("Invalid Inputfields");
      }
    }
  };

  const get_assessment_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hrms_assessments/getall/${company_id}`).then((res) => {
      setFullScreenLoader(false);
      setList(Apiconnect.decrypt_obj(res.data.data));
    });
  };

  const handleEdit = (id) => {
    setEdit_id(id);
    let data = list.find((item) => item.ha_pk_id == id);
    console.log(data);
    setHa_name(data.ha_name);
    setForm_id(data.ha_form_id);
  };

  const handleDelete = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hrms_assessments/delete/${id}`).then((res) => {
      setFullScreenLoader(false);
      get_assessment_list();
    });
  };

  const [form_lists, setForm_lists] = useState([]);

  const get_forms_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hrms_forms/getall`).then((res) => {
      setFullScreenLoader(false);
      setForm_lists(Apiconnect.decrypt_obj(res.data.data));
    });
  };

  const handleAssign = () => {
    let ins_ob = {
      ha_form_id: form_id,
    };

    if (form_id !== "") {
      setFullScreenLoader(true);
      Apiconnect.postData(`hrms_assessments/assignForm/${assessment_id}`, ins_ob)
        .then((res) => {
          setFullScreenLoader(false);
          alert.success(res.data.message);
          setModalIsOpen(false);
          get_assessment_list();
        })
        .catch((err) => {
          console.log(err.message);
          setFullScreenLoader(false);
          setModalIsOpen(false);
        });
    } else {
      alert.error("Please Select Form");
    }
  };

  console.log(list);

  return (
    <div className="content-page main_bg">
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div className="container-fluid">
        {/* <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                
                <div className='iq-card'>
                    <div className='iq-card-header card_head'>
                        <h5 className='text-white'>Assign Form To {name}</h5>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-6'>
                                
                            </div>
                            <div className='col-md-6'>
                                <button className='btn btn-sm btn-primary' onClick={handleAssign}>Assign</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}
        <div className="iq-card main_container">
          <div className="iq-card-header card_head">
            <h4 className="text-white">Hiring Assessments Master</h4>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-4">
                <input type="text" className="form-control" placeholder="Enter Assessments" name="ha_name" value={ha_name} onChange={(e) => setHa_name(e.target.value)} />
              </div>
              <div className="col-md-4">
                <select className="form-control" name="form_id" value={form_id} onChange={(e) => setForm_id(e.target.value)}>
                  <option value="" disabled selected>
                    Select Form
                  </option>
                  {form_lists.map((item, index) => {
                    return (
                      <option key={index} value={item.hf_pk_id}>
                        {item.hf_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-4">
                <button className="btn btn-sm btn-primary mr-2" onClick={(e) => handleCreate(e)}>
                  {edit_id ? "Update" : "Create"}
                </button>
                {edit_id && (
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setEdit_id("");
                      setHa_name("");
                    }}
                  >
                    Clear
                  </button>
                )}
                {/* {ha_name !== "" && <button className='btn btn-secondary btn-sm' onClick={() => {setHa_name(""); setForm_id("")}}>Clear</button>} */}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mt-3">
                <div className="table-responsive">
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "10%" }} className="text-center">
                          #
                        </th>
                        <th className="text-center">Assessment Name</th>
                        <th className="text-center">Assigned Form</th>
                        <th style={{ width: "30%" }} className="text-center">
                          Manage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.length > 0 ? (
                        list.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td>{item.ha_name}</td>
                              <td className="text-center">{item.form_name === null ? <span>Form Not Assigned Yet</span> : <span className="badge badge-pill badge-success">{item.form_name}</span>}</td>
                              <td className="text-center">
                                <button className="btn btn-sm btn-info mr-2" onClick={() => handleEdit(item.ha_pk_id)}>
                                  Edit
                                </button>
                                {/* <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.ha_pk_id)}>Delete</button> */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <th colSpan={4} className="center text-muted">
                            ---- No Records Found ----
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_assessment_master;
