import React, { useState, useEffect } from "react";
import Apiconnect from "../../../services/Apiconnect.js";
import { pageStyles } from "../../../globalStyles.js";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import Modal from "react-modal";
import moment from "moment";

const Verify_applicant_docs = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();
  const { id } = useParams();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [list, setList] = useState([]);
  const [prevEmployers, setPrevEmployers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [name, setName] = useState("");
  const [emp_code, setEmp_code] = useState("");
  const [desig, setDesig] = useState("");
  const [salary, setSalary] = useState("");
  const [rm, setRm] = useState("");
  const [verify, setVerify] = useState({});

  const customStyles = {
    content: {
      top: "55%",
      width: "60%",
      height: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    get_selected_applicants_list();
  }, []);

  const get_selected_applicants_list = async () => {
    try {
      const result = await Apiconnect.postData(`selected_applicants/get_all_selected_applicants/${company_id}`);
      let resData = result.data.data;
      console.log(resData);
      let filtered = resData?.find((item) => item?.am_pk_id === Number(id));
      setList(filtered);
      setPrevEmployers(filtered?.prevEmployers);
    } catch (error) {
      console.log(error.message);
    }
  };

  const verifyId = async (id) => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/idverify/${id}`);
      alert.success("Verified");
      get_selected_applicants_list();
    } catch (error) {
      alert.error("Error");
    }
  };
  const verifyPan = async (id) => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/panverify/${id}`);
      alert.success("Verified");
      get_selected_applicants_list();
    } catch (error) {
      alert.error("Error");
    }
  };
  const verifyorg = async (id) => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/prevOrgverify/${id}`);
      alert.success("Verified");
      get_selected_applicants_list();
    } catch (error) {
      alert.error("Error");
    }
  };
  const handleVerify = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`selected_applicants/verifyApplicant/${id}`).then((res) => {
      alert.success("Completed Verification");
      get_selected_applicants_list();
      setFullScreenLoader(false);
    });
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
        <div className="container-fluid" style={pageStyles.main_container}>
          <div className="iq-card">
            <div className="iq-card-header bg-light" style={pageStyles.card_head}>
              <h5 className="text-white">Employer Side Verification</h5>
            </div>
            <div className="iq-card-body">
              <div className="row mt-3">
                <div className="col-md-12 table-responsive">
                  <div className="row mt-2 mb-2 bg-white" style={{ border: "3px solid #04BDEF", borderRadius: 10, padding: 20 }}>
                    <div className="table-responsive">
                      <table className="table table-sm bg-light table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "40%" }} className="text-center">
                              Details
                            </th>
                            <th className="text-center">Applicant Details</th>
                            <th className="text-center">Verifier Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Applicant Name</td>
                            <td>
                              {list.am_first_name} {list.am_last_name}
                            </td>
                            <td>
                              <input type="text" className="form-control" value={verify.hvd_name} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Organization Name</td>
                            <td>{name}</td>
                            <td>
                              <input type="text" className="form-control" value={verify.hvd_org} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Employee Code</td>
                            <td>{emp_code}</td>
                            <td>
                              <input type="text" className="form-control" value={verify.hvd_emp_code} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Employment Tenure</td>
                            <td>
                              {fromdate} - {todate}
                            </td>
                            <td>
                              <input type="text" className="form-control" value={verify.hvd_tenure} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted">Designation</td>
                            <td>{desig}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_desig} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted"> Salary</td>
                            <td>{salary}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_salary" value={verify.hvd_salary} />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#D0F2F6" }}>
                            <td className="text-muted"> Reporting Manager</td>
                            <td>{rm}</td>
                            <td>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_reporting_manager" value={verify.hvd_reporting_manager} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Reason for Leaving</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_leaving_reason} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Duties and Responsibilities</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_duty_res} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Performance at Work</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_performance} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Exit Formalities / Full and Final Settlement - Pending / Completed (If Pending - Please specify the reason)</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_exit_formalities} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Disciplinary Issue during the tenure</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_disciplinary_issue} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Eligibility for Re-hire</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_rehire} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Remarks (if any)</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_remark} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Attached document is genuine or Not?</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_isGenuine} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Verifier Name</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" value={verify.hvd_vname} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Verifier Designation</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_vdesig" value={verify.hvd_vdesig} />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted"> Verifier Contact Number</td>
                            <td colSpan={2}>
                              <input type="text" className="form-control" placeholder="Remark" name="hvd_vcontact" value={verify.hvd_vcontact} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="iq-card" style={pageStyles.main_container}>
              <div className="iq-card-header" style={pageStyles.card_head}>
                <h4 className="text-white">
                  Verify <span className="font-weight-bold">{list?.am_first_name + " " + list?.am_last_name}</span>
                </h4>
                <Link to={`/Rm_selected_applicants`} className="btn btn-sm btn-info float-right">
                  Back
                </Link>
              </div>
              <div className="iq-card-body">
                <div className="row">
                  <span className="text-muted p-3">
                    Applicant Required Documents <span className="required">*</span>
                  </span>
                  <div className="col-md-12 table-responsive">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center text-muted">Document Name</th>
                          <th className="text-center text-muted">View</th>
                          <th className="text-center text-muted">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center font-weight-bold text-muted">Id Card</td>
                          <td className="text-center">
                            {list.am_id_proof ? (
                              <a className="btn btn-sm btn-info" href={list.am_id_proof} target="_blank">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </a>
                            ) : (
                              <span className="text-muted">No Record Found</span>
                            )}
                          </td>
                          <td className="text-center">
                            {list.am_id_verify !== "1" ? (
                              <button className="btn btn-sm btn-info" onClick={() => verifyId(list.am_pk_id)}>
                                Verify
                              </button>
                            ) : (
                              <span className="badge badge-success ">Verified</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center font-weight-bold text-muted">PAN Card</td>
                          <td className="text-center">
                            {list.am_pan ? (
                              <a className="btn btn-sm btn-info" href={list.am_pan} target="_blank">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </a>
                            ) : (
                              <span className="text-muted">No Record Found</span>
                            )}
                          </td>
                          <td className="text-center">
                            {list.am_pan_verify !== "1" ? (
                              <button className="btn btn-sm btn-info" onClick={() => verifyPan(list.am_pk_id)}>
                                Verify
                              </button>
                            ) : (
                              <span className="badge badge-sm badge-success">Verified</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <span className="text-muted p-3">
                    Applicants Employment Information <span className="required">*</span>
                  </span>
                  <div className="col-md-12 table-responsive">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center text-muted">#</th>
                          <th className="text-center text-muted">Org Name</th>
                          <th className="text-center text-muted">Period Of Service</th>
                          <th className="text-center text-muted">Appointment Letter</th>
                          <th className="text-center text-muted">Salary Slip</th>
                          <th className="text-center text-muted">Employer Verification</th>
                          <th className="text-center text-muted">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prevEmployers.map((item, index) => {
                          const from = moment(item.ame_from).format("DD/MM/YYYY");
                          const to = moment(item.ame_to).format("DD/MM/YYYY");
                          return (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">{item.ame_name}</td>
                              <td className="text-center">
                                {from} - {to}
                              </td>
                              <td className="text-center">
                                <a href={item.ame_appointment_letter} className="btn btn-sm btn-info" target="_blank">
                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                </a>
                              </td>
                              <td className="text-center">
                                <a href={item.ame_salary_slip} className="btn btn-sm btn-info" target="_blank">
                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                </a>
                              </td>
                              <td className="text-center">
                                {item.companyVerification.length > 0 ? (
                                  <button
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                      setModalIsOpen(true);
                                      setFromdate(from);
                                      setTodate(to);
                                      setName(item.ame_name);
                                      setEmp_code(item.ame_empCode);
                                      setDesig(item.ame_designation);
                                      setSalary(item.ame_ctc_drawn);
                                      setRm(item.ame_reportingManager);
                                      setVerify(item.companyVerification[0]);
                                    }}
                                  >
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  </button>
                                ) : (
                                  <span className="text-muted">Company Verification Pending</span>
                                )}
                              </td>
                              <td className="text-center">
                                {item.ame_isVerified === 0 && (
                                  <button className="btn btn-sm btn-info" onClick={() => verifyorg(item.ame_pk_id)}>
                                    Verify
                                  </button>
                                )}
                                {item.ame_isVerified === 1 && <span className="badge badge-success ">Verified</span>}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="text-center">
                      {list.am_isVerified === 0 && (
                        <button className="btn btn-sm btn-info" onClick={() => handleVerify(list.am_pk_id)}>
                          Verify
                        </button>
                      )}
                      {list.am_isVerified === 1 && <span className="badge badge-sm badge-success">Verified</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify_applicant_docs;
