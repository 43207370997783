import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import {useLocation} from 'react-router-dom'
import Apiconnect from "../../services/Apiconnect.js";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

const Kra_assign = () => {

    const {id} = useParams()
    const alert = useAlert(); 

    const [list, setList] = useState([])
    const [empStatus, setEmpStatus] = useState("");
    const [dept_id, setDept_id] = useState(null);
    const [grp_id, setGrp_id] = useState("")
    const [searchTerm, setSearchTerm] = useState('')
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [selectedEmp, setSelectedEmp] = useState([]);

  
/* cat list */
    const [catList, setCategory] = useState([]);
    const getCategory = () => {
      var company_id = Apiconnect.get_company_id();
      setFullScreenLoader(true)
      Apiconnect.getData(`company_category/getall/${company_id}`).then(
        (response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setCategory(_xtract.reverse());
          setFullScreenLoader(false)
        }
      );
    };
    useEffect(() => {getCategory()}, [])

  /* grp list */

  const [grp, setGrp] = useState([])
  const getAll = async () => {
    const inf_c = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_c).then(res => setGrp(res.data.data)) 
}
  useEffect(() => {getAll()}, [])

  /* -----------------------Pagination------------------------ */
  const [activePage, setActivePage] = useState(1);
  
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * 50;
    let limit = 50;
    getEmployeeList_new(limit, offset, "", dept_id, empStatus, grp_id);
  };

  const getEmployeeList_new = (limit, offset, search, dept_id, empStatus, grp_id) => {
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id,
      empStatus:empStatus,
      group_id: grp_id
    };
    // setFullScreenLoader(true)
    Apiconnect.postData(`employee_management/get_employee_lists`, data).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setList(_xtract);
        // setFullScreenLoader(false)
        let ob = {}
        let filteredData = _xtract.filter((item) => item.kra_pool_id == id)
        filteredData.map((val, key) => {
                ob[val.id] = val.id
        })
        setSelectedEmp(ob)
        // console.log(response)
      }
    );
  };


  

  const [empCount, setempCount] = useState(0);
  const getEmpCount = (search,dept_id,empStatus) => {

    var dept_id1 = '';
    if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
       dept_id1 = dept_id;
    }

    const data = {
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id1,
      empStatus:empStatus
    };
    

    Apiconnect.postData(
      `employee/getEmpSearchcount`,data
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setempCount(_xtract[0].cnt);
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(searchTerm)
      
      let offset = (activePage - 1) * 50;
      let limit = 50;
      let group_id = ""
     
      getEmployeeList_new(limit, offset, searchTerm, dept_id,empStatus, grp_id);

      var dept_id1 = '';
      if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
         dept_id1 = dept_id;
      }
  
      var dept_id1 = '';
      const data = {
        search: searchTerm,
        company_id: Apiconnect.get_company_id(),
        dept_id:dept_id1,
        empStatus:empStatus
      };
  
      Apiconnect.postData(
        `employee/getEmpSearchcount`,data
      ).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setempCount(_xtract[0].cnt);
      });
  
    }, 2000)
  
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])
  /*-------------------------------pagination end------------------------- */
  
//select dept
  const onDeptChange = (e) => {
    let dept_id = e.target.value;
    setDept_id(dept_id);
    getEmployeeList_new("50", "0", "", dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  };
//select grp
  const onGrpChange = (e) => {
    let grp_id = e.target.value;
    setGrp_id(grp_id)
    getEmployeeList_new("50", "0", "", dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  }
//select status
  const onStatusChange = (e) => {
    let empStatus = e.target.value;
    setEmpStatus(empStatus)
    getEmployeeList_new("50", "0", "", dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  }
  /* ---------------------------employee select------------------------------ */
  
  const onInputChange2 = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 }); 
    }
  };

  const [allchecked, setallchecked] = useState(null);

  const sellectAll = (e) => {
    console.log(e.target.checked);
    if (e.target.checked === true) { 
      setallchecked("checked");
      let ob = {};
      list.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
    }
  };

  /* Assign */
  const handleCreate = async () => {
    setFullScreenLoader(true)
    const inf_f = {employee_ids: selectedEmp, kra_pool_id:id}
        Apiconnect.postData(`kra/kra_pool_assign`, inf_f).then(res => {
            setFullScreenLoader(false)
            getEmployeeList_new();
            alert.success("Created")
        }).catch(err => console.log(err))
  }


  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
                    {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
          <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                    <div style={{ float: "left" }} className="iq-header-title">
                        <h4 className="card-title">Assign Employee</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Department</label>
                        <select className="form-control" 
                          required
                          name="dept_id"
                          value={dept_id}
                          onChange={(e) => onDeptChange(e)}
                        >
                          <option value="" disabled selected>Department</option>
                          {catList.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Group</label>
                        <select className="form-control" name="grp" value={grp_id} onChange={e => onGrpChange(e)}>
                          <option value="" disabled selected>Group</option>
                          {grp.map((val, key) => {
                              return(
                                <option key={key} value={val.leavegroup_pk_id}>{val.leavegroup_name}</option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Status</label>
                        <select  className="form-control" onChange={e => onStatusChange(e)}>
                          <option value="" disabled selected>status</option> 
                          <option value="1" >Active</option> 
                          <option value="0" >Inactive</option> 
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-3 d-flex mt-5">
                        <input autocomplete="off" className="form-control d-flex end" style={{textAlign: "center"}} placeholder='Search Employee..' onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className="col-md-5 d-flex mt-5" >
                      <button className="btn btn-success" onClick={() => handleCreate()}>Assign</button>
                    </div>
                  </div>
                  <div className="row">
                      <table  className="table table-striped  mt-4 center grid" role="grid" >
                          <thead className="">
                            <tr className="clr">
                              <th> 
                                <input
                                  type="checkbox"
                                  name="select_all"
                                  checked={allchecked}
                                  onChange={(e) => sellectAll(e)} 
                                />
                              </th>
                              <th scope="col">#</th>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">KRA Title</th>
                            </tr>
                          </thead>
                          <tbody>
                              {list.map((val, key) => {

                                  if (val.display_picture == null) {
                                    var profile_img = "/images/user/1.jpg";
                                  } else {
                                    const min = 1;
                                    const max = 1000;
                                    var rand = min + Math.random() * (max - min);
                                    var profile_img = process.env.React_App_BASE_URL_API + "/uploads/thumbdp/" + "?" + rand;
                                  }

                                  var ck = Object.keys(selectedEmp).find(function (element) {
                                    return selectedEmp[element] == val.id;
                                    });  
                                    var ck1 = "";
                                    if (ck !== undefined) {
                                        ck1 = true;
                                    }

                                    
                                return(
                                  <tr key={key}>
                                    <th scope="row"><input type="checkbox" name={val.id} value={val.id} checked={ck1} onChange={(e) => onInputChange2(e)}/></th>    
                                    <th scope="row">{key + 1}</th>
                                    <td style={{ width: "130px", textAlign: "center" }}>
                                      <div className="" style={{ display: "grid" }}>
                                        <img src={profile_img} alt="profile-img" className="prof"/>
                                      </div>
                                    </td>
                                    <td>{val.first_name} {val.last_name}</td>
                                    <td>{val.kra_title === null ? <span className='text-muted'>NA</span> : <span className='badge badge-warning'>{val.kra_title}</span> }</td>
                                  </tr>
                                )
                              })}
                          </tbody>
                      </table>
                      <Pagination
                      activePage={activePage}
                      itemsCountPerPage={50}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                    </div>
                </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Kra_assign