import Axios from 'axios'
import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";


const Monthly_attendance_list = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let employee_id = Apiconnect.decrypt(localuser.id);

    const [list, setList] = useState([])
    const getAttendancelist = () => {
        Apiconnect.getData(`attendance/monthlylist`).then(res => setList(res.data.data))
    }
    useEffect(() => {getAttendancelist()}, [])

    //console.log(list)

    let month = {"01": "Jan", "02":"FEB", "03":"MAR", "04":"APR", "05":"MAY", "06":"JUN", "07":"JUL", "08":"AUG", "09":"SEP", "10":"OCT", "11":"NOV", "12":"DEC"}

    const updateStatus = (id, stat) => {
        let stat_new = null
        if(stat !== 1){
            stat_new = 1
        }else{
            stat_new = 0
        }
        const data = {attendance_status: stat_new, id:id}

        //console.log(data);
        Apiconnect.postData(`attendance/monthlylist/updateStatus`, data).then((res) => {  
            getAttendancelist()
        }).catch(err => console.log(err))
    }

  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between"
                             style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title text-white">Monthly Attendance List</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                            <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                <thead className="">
                                <tr className="clr">
                                    <th scope="col">#</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((val, key) => {
                                            //for admin/emp
                                            let stat = ""
                                            if(val.attendance_status === 1){
                                                stat= "Unlock"
                                            }else{
                                                stat= "Lock"
                                            }
                                            //for superAdmin

                                            let stat_2 = ""
                                            if(val.attendance_status === 1){
                                                stat_2 = "Block"
                                            }else{
                                                stat_2 = "Unblock"
                                            }

                                            let btn = null
                                            if (val.attendance_status == 1){
                                                btn = <button type="button"  className="btn btn-sm btn-danger" onClick={() => updateStatus(val.id, val.attendance_status)}>{`${stat_2}`}</button>
                                            }else{
                                                btn = <button type="button"  className="btn btn-sm btn-success" onClick={() => updateStatus(val.id, val.attendance_status)}>{`${stat_2}`}</button>
                                            }
                                            return(
                                                <tr>
                                                    <td>{key + 1}</td>
                                                    <td>{month[String(val.month)]}-{val.year}</td>
                                                    <td>{stat}</td>
                                                    <td>
                                                    <button  className="btn btn-sm  mr-1 btn-info"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                                        {
                                                            user_typ === '3' ? btn : ""
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Monthly_attendance_list