import React, { Component }  from 'react'; 
import Apiconnect from "../../services/Apiconnect.js";
import { CSVLink } from "react-csv";
import { withAlert } from 'react-alert';
import '../pages/uploadfile.css';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Loadder from "./Loadder";
import Modal from 'react-modal';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";


class Employee_bulk_upload extends Component { 
        constructor(props) {
            super(props);
            this.state = { 
                loader:false,selectedFile:'',empSalarySequencesFile:'',dwnEmpSalarySequences:'',selectedSalaryFile:'',selectedLeaveFile:'',selectedAttendanceFile:'',dwnEmpSalaryfile:'',company_id: Apiconnect.get_company_id(),
            };
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSalaryChange =  this.handleSalaryChange.bind(this);
            this.handleLeaveChange =  this.handleLeaveChange.bind(this);
            this.handleAttendanceChange =  this.handleAttendanceChange.bind(this);
            this.handleInputChangeSequence = this.handleInputChangeSequence.bind(this);
            // this.salaryfile =  this.salaryfile.bind(this);
        }

        componentDidMount() {
            this.salaryfile();
            this.handleSequencesChange();
        }

        handleSequencesChange(){
            Apiconnect.getData(`employee/empsalarysequences/${this.state.company_id}`).then((response) => {
               let _xtract = Apiconnect.decrypt_obj(response.data.data);
            //    console.log('_xtract',_xtract)
                this.setState({dwnEmpSalarySequences: _xtract.reverse()});
            });
         }

        salaryfile(){
            // console.log('test');
           // Axios.get(`http://localhost:3050/api/employee/empsalaryfile/${this.state.company_id}`).then((response) => {
            Apiconnect.getData(`employee/empsalaryfile/${this.state.company_id}`).then((response) => {

               let _xtract = Apiconnect.decrypt_obj(response.data.data);
                this.setState({dwnEmpSalaryfile: _xtract.reverse()});
                 
               
            });
         }

        handleInputChange(event) {
            this.setState({
                selectedFile: event.target.files[0],
              })
        }
        submit = async e => {
            const data = new FormData() ;
            data.append('excelfile', this.state.selectedFile);   
            // console.log(data);

             Apiconnect.postData(`excel_upload/empbulkdata/${this.state.company_id}`, data).then((response) => {   
                const options = {
                    timeout: 10000,
                  }  
                  this.props.alert.success(response.data.message)   
                // if(response.data.empCodeExit.length > 0){
                //     this.props.alert.error('The following employee not Exists. '+response.data.empCodeExit,options)
                // }else{
                //     this.props.alert.success(response.data.message)
                // }
                // this.props.alert.success(response.data.message)
            });    
        };

   
        handleInputChangeSequence(event) {
            this.setState({
                empSalarySequencesFile: event.target.files[0],
              })
        }
        submitSalarySequence = async e => {
            const data = new FormData() ;
            data.append('empsalarysequencesExcel', this.state.empSalarySequencesFile);   
             Apiconnect.postData(`excel_upload/employee_salary_sequences_excel`, data).then((response) => {   
                this.props.alert.success(response.data.message)
            });
        };


          handleLeaveChange(event) {
           
            this.setState({
                selectedLeaveFile: event.target.files[0],
              })
            //   console.log(event.target.files[0]);
             
        }
   
         
        submitLeave = async e => {
            const data = new FormData() ;
            // console.log(this.state.selectedLeaveFile);
            data.append('excelleavefile', this.state.selectedLeaveFile); 
            // console.log(data);  
            //Axios.post(`http://localhost:3050/api/tush/uploadleavecsv/${this.state.company_id}`, data).then((response) => {

            Apiconnect.postData(`excel_upload/leavebulkdata/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
               // console.log(response.data.message);
            });
             
        };


        handleAttendanceChange(event) {
           
            this.setState({
                selectedAttendanceFile: event.target.files[0],
              })
              //console.log(event.target.files[0]);
             
        }
   
         
        submitAttendance = async e => {

            this.setState({loader:true });
            const data = new FormData() ;
            //console.log(this.state.selectedAttendanceFile);
            data.append('excelattendancefile', this.state.selectedAttendanceFile); 
            //console.log(data);  
           // Axios.post(`http://localhost:3050/api/upload/uploadattendacecsv/${this.state.company_id}`, data).then((response) => {

            Apiconnect.postData(`excel_upload/attendancebulkdata/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
               // console.log(response.data.message);
                this.setState({loader:false });
            });
             
        };

         sample_Attendance_file = () =>{
            //console.log('t');
        }



        handleSalaryChange(event) {
           
            this.setState({
                selectedSalaryFile: event.target.files[0],
            })
             
        }
   
         
        submitSalary = async e => {
            const data = new FormData() ;
            let sel_selectedSalaryFile=this.state.selectedSalaryFile; 
            data.append('excelsalaryfile', sel_selectedSalaryFile);  
            data.append('excelsalaryfile3', 2);  
            
            Apiconnect.postData(`excel_upload/salarybulkdata/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
                //console.log(response.data.message);
            });
             
        };

       

        // emp_sample_file = ['Bruce', 'Clark', 'Diana'];

    /* -----------------------------------Excel Downlaod Functionality------------------- */
     getSheetData(data, header) {
        var fields = Object.keys(data);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }

       saveAsExcel = () => {
        let header = ["emp_code","reporting_officer","employee_group","department","employement_type","first_name","middle_name","last_name","gender","date_of_birth","email","phone","phone_secondary","email_secondary","is_active","present_address_one","present_address_two","present_state_id","present_city_id","present_area","present_zip","present_landmark","parmanent_address_one","parmanent_address_two","parmanent_state_id","parmanent_city_id","parmanent_area","parmanent_zip","parmanent_landmark","blood_group","religion","caste","nationality","marital_status","anniversary_date","emergency_contact_person","emergency_relationship_person_id","emergency_contact_number","emergency_contact_person2","emergency_relationship_person_id2","emergency_contact_number2","pan","passport_no","aadhaar","payment_mode","bank_name","ifsc_code","account_number","is_epf","UAN_number","epf_number","pf_start_date","is_esi","esi_number","dispencery","confirmation_date","joining_date","resign_date","leaving_date","promotion_date","transfer_date","rf_id","emp_shift","emp_designation","password"]
        let data = []
      
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(data, header);
          const totalColumns = sheetData[0].length;
      
          sheet1.cell("A1").value(sheetData);
  
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:BM1").style("fill", "BFBFBF");
  
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "empsample.xlsx");
          });
        });
      }
       saveAsExcel1 = () => {
        let header = ["id",	"emp_code","first_name","earning_Arears","earning_BASIC","earning_DA","earning_HRA","earning_Others","earning_Cord_Allowance","deduction_Misc2","deduction_PF",	"deduction_TDS","deduction_Group Ins",	"deduction_ESI","deduction_Staff Conveyance","deduction_Advance","deduction_Med_Insurance","deduction_Misc1"]
        let data= []
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(data, header);
          const totalColumns = sheetData[0].length;
      
          sheet1.cell("A1").value(sheetData);
  
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:BM1").style("fill", "BFBFBF");
  
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "salary sample data.xlsx");
          });
        });
      }
       saveAsExcel2 = () => {
        let header = ["emp_id","emp_name","leave_type","leave_count","reason"]
        let data= []
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(data, header);
          const totalColumns = sheetData[0].length;
      
          sheet1.cell("A1").value(sheetData);
  
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:BM1").style("fill", "BFBFBF");
  
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "manual leave.xlsx");
          });
        });
      }
       getSheetData1(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }
       saveAsExcel3 = () => {
        let header = ["Employee ID",	"Employee Name",	"Department",	"Designation",	"Company",	"In Time",	"Out Time",	"Net In Time",	'Status',	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time", "Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status",	"In Time",	"Out Time",	"Net In Time",	"Status"]
        let data= []
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(data, header);
          const totalColumns = sheetData[0].length;
      
            sheet1.cell("A1").value("field1");
            sheet1.cell("A2").value("Report Detail Name");
            sheet1.cell("A3").value("Run Date & Time");
            sheet1.cell("A4").value("Period");
            sheet1.cell("B1").value("field2")
            sheet1.cell("B2").value("Attendance Detail By Date")
            sheet1.cell("B3").value("28/12/2021 15:47");
            sheet1.cell("B4").value("01/01/2022 To 01/06/2022");
            sheet1.cell("F4").value("1-Jan");
            sheet1.cell("J4").value("1/2/2023");
            sheet1.cell("N4").value("1/3/2023");
            sheet1.cell("R4").value("1/4/2023");
            sheet1.cell("V4").value("1/5/2023");
            sheet1.cell("Z4").value("1/6/2023");
            sheet1.cell("AD4").value("12/7/2023");
            sheet1.cell("AH4").value("12/8/2023");
            sheet1.cell("AL4").value("12/9/2023");
            sheet1.cell("AP4").value("12/10/2023");
            sheet1.cell("AT4").value("12/11/2023");
            sheet1.cell("AX4").value("12/12/2023");
            sheet1.cell("BB4").value("12/13/2023");
            sheet1.cell("BF4").value("12/14/2023");
            sheet1.cell("BJ4").value("12/15/2023");
            sheet1.cell("BN4").value("12/16/2023");
            sheet1.cell("BR4").value("12/17/2023");
            sheet1.cell("BV4").value("12/18/2023");
            sheet1.cell("BZ4").value("12/19/2023");
            sheet1.cell("CD4").value("12/20/2023");
            sheet1.cell("CH4").value("12/21/2023");
            sheet1.cell("CL4").value("12/22/2023");
            sheet1.cell("CP4").value("12/23/2023");
            sheet1.cell("CT4").value("12/24/2023");
            sheet1.cell("CX4").value("12/25/2023");
            sheet1.cell("DB4").value("12/26/2023");
            sheet1.cell("DF4").value("12/27/2023");
            sheet1.cell("DJ4").value("12/28/2023");
            sheet1.cell("DN4").value("12/29/2023");
            sheet1.cell("DR4").value("12/30/2023");
            sheet1.cell("DV4").value("12/31/2023");

            sheet1.cell("A5").value(sheetData);
  
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:BM1").style("fill", "BFBFBF");
  
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "attendance.xlsx");
          });
        });
      }
       saveAsExcel4 = () => {
        let header = ["id", "Full_name", "emp_salary_sequences"]
        let data= []
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(data, header);
          const totalColumns = sheetData[0].length;
      
          sheet1.cell("A1").value(sheetData);
  
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:BM1").style("fill", "BFBFBF");
  
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "emp_salary_sequences.xlsx");
          });
        });
      }


        render() {

            const customStyles1 = {
                content: {
                   top: '55%', width: '60%',
                   left: '50%',
                   right: 'auto',
                   bottom: 'auto',
                   marginRight: '-50%',
                   transform: 'translate(-50%, -50%)',
                   border:'unset ',
                   backgroundColor:'unset ',
                },
             };
            //  console.log(this.state.loader);


    return (

        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">

                      {/* ////////////------------>Lodder<------------////////////////// */}
                        <div >
                            <Modal isOpen={this.state.loader} style={customStyles1}>
                                            {<Loadder/>}
                            </Modal>
                        </div>
                    {/* ////////////------------>Lodder<------------////////////////// */}

                    {/* <div className="col-sm-12"> */}
                    <div className="col-sm-6">
                    <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Employee Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">

                                            <div class="dropzone">
                                                <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="excelfile" onChange={this.handleInputChange} /> {/* for csv */}
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-md" onClick={()=>this.submit()}>Submit</button>
                                            <br/>
                                            <br/>
                                            <div style={{display: "flex", justifyContent: "space-around"}}>
                                                {/* <div class="btn btn-warning btn-sm">
                                                    <CSVLink filename={"employee_sample.csv"} style={{color: "black"}} data="emp_code,reporting_officer,employee_group,department,employement_type,first_name,middle_name,last_name,gender,date_of_birth,email,phone,phone_secondary,email_secondary,is_active,present_address_one,present_address_two,present_state_id,present_city_id,present_area,present_zip,present_landmark,parmanent_address_one,parmanent_address_two,parmanent_state_id,parmanent_city_id,parmanent_area,parmanent_zip,parmanent_landmark,blood_group,religion,caste,nationality,marital_status,anniversary_date,emergency_contact_person,emergency_relationship_person_id,emergency_contact_number,emergency_contact_person2,emergency_relationship_person_id2,emergency_contact_number2,pan,passport_no,aadhaar,payment_mode,bank_name,ifsc_code,account_number,is_epf,UAN_number,epf_number,pf_start_date,is_esi,esi_number,dispencery,confirmation_date,joining_date,resign_date,leaving_date,promotion_date,transfer_date,rf_id,emp_shift,emp_designation,password" >
                                                    <i class="fa fa-download"></i> sample file CSV
                                                    </CSVLink>
                                                </div> */}
                                                <button class="btn btn-warning btn-sm" onClick={this.saveAsExcel}><i class="fa fa-download"></i> sample file Excel</button>
                                            </div>


                                        </div>
                                        
                                    </div>
                                </div>
                              
                        </div>
                        </div>
                        <div className="col-sm-6">
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Salary Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">

                                            <div class="dropzone">
                                                <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="excelsalaryfile" onChange={this.handleSalaryChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-md" onClick={()=>this.submitSalary()}>Submit</button>

                                            <br/>
                                            <br/>
                                            <div style={{display: "flex", justifyContent: "space-around"}}>
                                                {/* <div class="btn btn-warning btn-sm">
                                                <CSVLink filename={"salary_sample.csv"} style={{color: "black"}} data={this.state.dwnEmpSalaryfile} >
                                                    <i class="fa fa-download"></i> sample file CSV
                                                </CSVLink>
                                                </div> */}
                                                <button class="btn btn-warning btn-sm" onClick={this.saveAsExcel1}><i class="fa fa-download"></i> sample file Excel</button>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                        </div>
                       
                    </div>
                    <div className="row">
                    <div className="col-sm-6">
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Manual Leave Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            
                                        <div class="dropzone">
                                            <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="excelleavefile" onChange={this.handleLeaveChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-md" onClick={()=>this.submitLeave()}>Submit</button>
                                            <br/>
                                            <br/>
                                            <div style={{display: "flex", justifyContent: "space-around"}}>
                                                {/* <div class="btn btn-warning btn-sm">
                                                    <CSVLink filename={"leave_sample.csv"} style={{color: "black"}} data="emp_id,emp_name,leave_type,leave_count,reason" >
                                                        <i class="fa fa-download"></i> sample file CSV
                                                    </CSVLink>
                                                </div> */}
                                                <button class="btn btn-warning btn-sm" onClick={this.saveAsExcel2}><i class="fa fa-download"></i> sample file Excel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                        </div>  
                        <div className="col-sm-6">
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Attendance Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            
                                        <div class="dropzone">
                                            <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="excelattendancefile" onChange={this.handleAttendanceChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-md" onClick={()=>this.submitAttendance()}>Submit</button>
                                            <br/>
                                            <br/>
                                            <div style={{display: "flex", justifyContent: "space-around"}}>
                                                {/* <div class="btn btn-warning btn-sm">
                                                    <a target='_blank' href={process.env.React_App_BASE_URL_API+'/uploads/attendance_sample.csv'}  style={{color: "black"}} class=""><i class="fa fa-download"></i>sample file CSV</a>
                                                </div> */}
                                                <button class="btn btn-warning btn-sm" onClick={this.saveAsExcel3}><i class="fa fa-download"></i> sample file Excel</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                        </div> 


                    </div>
                {/* </div> */}

                <div className="col-sm-6">
                    <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Employee Salary Sequence Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            <div class="dropzone">
                                                <img src="http://100dayscss.com/codepen/upload.svg" style={{color: "green"}} class="upload-icon" />
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="empsalarysequencesExcel" onChange={this.handleInputChangeSequence} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-md" onClick={()=>this.submitSalarySequence()}>Submit</button>
                                            <br/>
                                            <br/>
                                            <div style={{display: "flex", justifyContent: "space-around"}}>
                                                {/* <div class="btn btn-warning btn-sm">
                                                    <CSVLink filename={"employee_sequences_sample.csv"} style={{color: "black"}} data={this.state.dwnEmpSalarySequences}>
                                                        <i class="fa fa-download"></i> Sample file CSV
                                                    </CSVLink>
                                                </div> */}
                                                <button class="btn btn-warning btn-sm" onClick={this.saveAsExcel4}><i class="fa fa-download"></i> sample file Excel</button>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                              
                        </div>
                        </div>

            </div>
        </div>
    )
        }
}

export default withAlert()(Employee_bulk_upload)