import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import moment from 'moment';

const customStyles = {
    content: {
       top: '55%',
       left: '55%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "70%" ,
       height: '100%'
    },
};

const Applications = () => {

    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id();
    Modal.setAppElement('body')

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [desList, setDesList] = useState([]);
    const [companyaddress, setCompanyAddress] = useState("")
    const [companyname, setCompanyname] = useState("")
    const [editId, setEditId] = useState("")
    const [application_list, setApplication_list] = useState([])

    const [inputFields, setInputFields] = useState({am_first_name: "", am_middle_name: "", am_last_name: "", am_position: "", am_address_correspondence:"",am_address_permanent: "",am_phone_res: "",am_phone_cell: "",am_email:"",am_email_alternative:"",am_dob:"",am_marital_status:"",am_lk_read:"",am_lk_write:"",am_lk_speak:"",am_caste:"",am_court_proceedings:"",am_contagious_disease:"",am_org_member_list:"",am_political_org_connection:"",am_employed_here_before:"",am_relatives_employed_here:"", am_added_by:user_id,am_current_ctc_fixed:"",am_current_ctc_reimbursements:"",am_current_ctc_incentives:"",am_current_ctc_others:"",am_expected_emoluments:"",am_notice_period:"",am_other_details:"",am_remark_if_appointed:""})
    const {am_first_name, am_middle_name, am_last_name, am_added_by, am_address_correspondence, am_address_permanent,am_caste, am_contagious_disease,am_court_proceedings,am_dob,am_email,am_email_alternative,am_employed_here_before,am_lk_read,am_lk_speak,am_lk_write,am_marital_status,am_org_member_list,am_phone_res,am_political_org_connection,am_position,am_relatives_employed_here,am_phone_cell,am_current_ctc_fixed,am_current_ctc_incentives,am_current_ctc_others,am_current_ctc_reimbursements,am_expected_emoluments,am_notice_period,am_other_details,am_remark_if_appointed} = inputFields

    useEffect(() => {getDesList(); getCompany(); get_application_lists()}, [])

    const onInputChange = e => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value});
    };

    const get_application_lists = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_getall`).then((res) => {
            setApplication_list(res.data.data)
            setFullScreenLoader(false)
        });
    }

    const getCompany = () => {         
        setFullScreenLoader(true)
        Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
            let ern = Apiconnect.decrypt_obj(response.data.data);
            setCompanyAddress(ern[0].address_one) 
            setCompanyname(ern[0].company_name)
            setFullScreenLoader(false)
        });
    }
    const getDesList = () => {
        setFullScreenLoader(true)
        Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = response.data.data;
            setDesList(_xtract.reverse()); 
            setFullScreenLoader(false)     
        });
    };

    /* ---submit--- */
    const handleSubmit = () => {
        inputFields["am_org"] = companyname
        inputFields["am_location"] = companyaddress

        if(editId !== ""){
            /* --Update- */
            setFullScreenLoader(true)
            if(am_first_name !== "" && am_last_name !== "" && am_position !== "" && am_address_correspondence !=="" && am_address_permanent!=="" && am_email!=="" && am_dob!=="" && am_marital_status!=="" && am_lk_read!=="" && am_lk_write!=="" && am_lk_speak!=="" && am_caste!=="" && am_court_proceedings!== "" && am_contagious_disease!=="" && am_org_member_list!=="" && am_political_org_connection!=="" && am_employed_here_before!=="" && am_relatives_employed_here!=="" && am_current_ctc_fixed!=="" && am_current_ctc_reimbursements!=="" && am_current_ctc_incentives!=="" && am_current_ctc_others !=="" && am_current_ctc_others !=="" && am_expected_emoluments!=="" && am_notice_period!=="" && am_other_details!==""){
                Apiconnect.postData(`hiring_application/hiring_application_update/${editId}`, inputFields).then(res => {
                    setFullScreenLoader(false)
                    get_application_lists()
                    setModalIsOpen(false)
                    setEditId("")
                    setInputFields({am_first_name: "", am_middle_name: "", am_last_name: "", am_position: "", am_address_correspondence:"",am_address_permanent: "",am_phone_res: "",am_phone_cell: "",am_email:"",am_email_alternative:"",am_dob:"",am_marital_status:"",am_lk_read:"",am_lk_write:"",am_lk_speak:"",am_caste:"",am_court_proceedings:"",am_contagious_disease:"",am_org_member_list:"",am_political_org_connection:"",am_employed_here_before:"",am_relatives_employed_here:"", am_added_by:user_id,am_current_ctc_fixed:"",am_current_ctc_reimbursements:"",am_current_ctc_incentives:"",am_current_ctc_others:"",am_expected_emoluments:"",am_notice_period:"",am_other_details:"",am_remark_if_appointed:""})
                    alert.success("Successfully Updated")
                })
                console.log(inputFields)
            }else{
                setFullScreenLoader(false)
                alert.error("Some Inputfields are empty")
            }
        }else{
            setFullScreenLoader(true)
            if(am_first_name !== "" && am_last_name !== "" && am_position !== "" && am_address_correspondence !=="" && am_address_permanent!=="" && am_email!=="" && am_dob!=="" && am_marital_status!=="" && am_lk_read!=="" && am_lk_write!=="" && am_lk_speak!=="" && am_caste!=="" && am_court_proceedings!== "" && am_contagious_disease!=="" && am_org_member_list!=="" && am_political_org_connection!=="" && am_employed_here_before!=="" && am_relatives_employed_here!=="" && am_current_ctc_fixed!=="" && am_current_ctc_reimbursements!=="" && am_current_ctc_incentives!=="" && am_current_ctc_others !=="" && am_current_ctc_others !=="" && am_expected_emoluments!=="" && am_notice_period!=="" && am_other_details!==""){
                Apiconnect.postData(`hiring_application/hiring_application_create`, inputFields).then(res => {
                    setFullScreenLoader(false)
                    get_application_lists()
                    setModalIsOpen(false)
                    setInputFields({am_first_name: "", am_middle_name: "", am_last_name: "", am_position: "", am_address_correspondence:"",am_address_permanent: "",am_phone_res: "",am_phone_cell: "",am_email:"",am_email_alternative:"",am_dob:"",am_marital_status:"",am_lk_read:"",am_lk_write:"",am_lk_speak:"",am_caste:"",am_court_proceedings:"",am_contagious_disease:"",am_org_member_list:"",am_political_org_connection:"",am_employed_here_before:"",am_relatives_employed_here:"", am_added_by:user_id,am_current_ctc_fixed:"",am_current_ctc_reimbursements:"",am_current_ctc_incentives:"",am_current_ctc_others:"",am_expected_emoluments:"",am_notice_period:"",am_other_details:"",am_remark_if_appointed:""})
                    alert.success("Successfully Inserted")
                })
            }else{
                setFullScreenLoader(false)
                alert.error("Some Inputfields are empty")
            }
        }
    }

    /* ---Delete--- */
    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_delete/${id}`).then((res) => {
            get_application_lists()
            setFullScreenLoader(false)  
            alert.success(res.data.message)   
        });
    }

    const handleEdit = (id) => {
        let filteredData = application_list.find((item) => item.am_pk_id === id)
        setModalIsOpen(true)
        setInputFields({am_first_name: filteredData.am_first_name, am_middle_name: filteredData.am_middle_name, am_last_name: filteredData.am_last_name, am_position: filteredData.am_position, am_address_correspondence:filteredData.am_address_correspondence,am_address_permanent: filteredData.am_address_permanent,am_phone_res: filteredData.am_phone_res,am_phone_cell: filteredData.am_phone_cell,am_email:filteredData.am_email,am_email_alternative:filteredData.am_email_alternative,am_dob:filteredData.am_dob,am_marital_status:filteredData.am_marital_status,am_lk_read:filteredData.am_lk_read,am_lk_write:filteredData.am_lk_write,am_lk_speak:filteredData.am_lk_speak,am_caste:filteredData.am_caste,am_court_proceedings:filteredData.am_court_proceedings,am_contagious_disease:filteredData.am_contagious_disease,am_org_member_list:filteredData.am_org_member_list,am_political_org_connection:filteredData.am_political_org_connection,am_employed_here_before:filteredData.am_employed_here_before,am_relatives_employed_here:filteredData.am_relatives_employed_here, am_added_by:user_id,am_current_ctc_fixed:filteredData.am_current_ctc_fixed,am_current_ctc_reimbursements:filteredData.am_current_ctc_reimbursements,am_current_ctc_incentives:filteredData.am_current_ctc_incentives,am_current_ctc_others:filteredData.am_current_ctc_others,am_expected_emoluments:filteredData.am_expected_emoluments,am_notice_period:filteredData.am_notice_period,am_other_details:filteredData.am_other_details,am_remark_if_appointed:filteredData.am_remark_if_appointed})
        setEditId(id)
    }

  return (
    <>
        <div className="content-page">
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            {/* ----------------Modal Start---------------*/}
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head">
                        <div className="iq-header-title">
                            <h4 className="card-title ">Create Application</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='row m-2 p-2 bbox'>
                                    <div className='col-md-4 mb-3'>
                                        <label>First Name</label>
                                        <input type='text' className='form-control' name='am_first_name' value={am_first_name} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Middle Name</label>
                                        <input type='text' className='form-control' name='am_middle_name' value={am_middle_name} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Last Name</label>
                                        <input type='text' className='form-control' name='am_last_name' value={am_last_name} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Position</label>
                                        <select className='form-control' name='am_position' value={am_position} onChange={e => onInputChange(e)}>
                                            <option value="" disabled selected>---select---</option>
                                            {
                                                desList.map((item, index) => {
                                                    return(
                                                        <option key={index} value={item.name}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select> 
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Location</label>
                                        <input type='text' className='form-control' name='companyaddress' value={companyaddress} />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Organisation</label>
                                        <input type='text' className='form-control' name='companyname' value={companyname} />
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <label>Correspondence Address</label>
                                        <textarea type='text' className='form-control' name='am_address_correspondence' value={am_address_correspondence} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <label>Permanent Address</label>
                                        <textarea type='text' className='form-control' name='am_address_permanent' value={am_address_permanent} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Phone (Res)</label>
                                        <input type='text' className='form-control' name='am_phone_res' value={am_phone_res} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Phone (Cell)</label>
                                        <input type='text' className='form-control' name='am_phone_cell' value={am_phone_cell} onChange={e => onInputChange(e)} autoComplete='off' />
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Email id</label>
                                        <input type='email' className='form-control' name='am_email' value={am_email} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Alternate Email Id</label>
                                        <input type='email' className='form-control' name='am_email_alternative' value={am_email_alternative} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Date of Birth</label>
                                        <input type='date' className='form-control' name='am_dob' value={am_dob} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Marital Status</label>
                                        <select className='form-control' name='am_marital_status' value={am_marital_status} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Married">Married</option>
                                            <option value="Unmarried">Unmarried</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Languages Known: Read</label>
                                        <input type='text' className='form-control' name='am_lk_read' value={am_lk_read} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Languages Known: Write</label>
                                        <input type='text' className='form-control' name='am_lk_write' value={am_lk_write} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Languages Known: Speak</label>
                                        <input type='text' className='form-control' name='am_lk_speak' value={am_lk_speak} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Caste</label>
                                        <select className='form-control' name='am_caste' value={am_caste} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                            <option value="OBC">OBC</option>
                                            <option value="GEN">GEN</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you been involved in any court proceedings</label>
                                        <select className='form-control' name='am_court_proceedings' value={am_court_proceedings} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you suffer from any contagious disease</label>
                                        <select className='form-control' name='am_contagious_disease' value={am_contagious_disease} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>List of Professional, Social, Religious or cultural organization of which you are a member</label>
                                        <input type='text' className='form-control' name='am_org_member_list' value={am_org_member_list} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you have ever (directly or indirectly) been connected with any political organization</label>
                                        <select className='form-control' name='am_political_org_connection' value={am_political_org_connection} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If you ever been employed with any of the company/institute affiliated(directly/indirectly)with this organisation</label>
                                        <select className='form-control' name='am_employed_here_before' value={am_employed_here_before} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>If any of your friend/relative is employed with this organisation</label>
                                        <select className='form-control' name='am_relatives_employed_here' value={am_relatives_employed_here} onChange={e => onInputChange(e)}>
                                            <option value="" disabled>---select---</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Fixed</label>
                                        <input type='text' className='form-control' name='am_current_ctc_fixed' value={am_current_ctc_fixed} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Reimbursements</label>
                                        <input type='text' className='form-control' name='am_current_ctc_reimbursements' value={am_current_ctc_reimbursements} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Incentives</label>
                                        <input type='text' className='form-control' name='am_current_ctc_incentives' value={am_current_ctc_incentives} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Current CTC Others</label>
                                        <input type='text' className='form-control' name='am_current_ctc_others' value={am_current_ctc_others} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Total emoluments expected</label>
                                        <input type='text' className='form-control' name='am_expected_emoluments' value={am_expected_emoluments} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <label>Notice Period with the current organization</label>
                                        <input type='text' className='form-control' name='am_notice_period' value={am_notice_period} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <label>Any other details to share (Industry, Research exposure you may like to share additionally)</label>
                                        <textarea type='text' className='form-control' name='am_other_details' value={am_other_details} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <label>How do you think our institute is going to benefit by appointing you</label>
                                        <textarea type='text' className='form-control' name='am_remark_if_appointed' value={am_remark_if_appointed} onChange={e => onInputChange(e)} autoComplete='off'/>
                                    </div>
                                </div>
                                <div className='row m-2 p-2 mt-4'>
                                    <div className='col-md-12' align="center">
                                        <button className='btn btn-sm btn-info' onClick={handleSubmit}>Create</button>
                                        &nbsp;
                                        <button className="btn btn-sm btn-danger" onClick={() => setModalIsOpen(false)}>close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ----------------Modal End---------------*/}
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between model_head">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Applications</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-sm btn-primary float-right' onClick={() => setModalIsOpen(true)}>Add New</button>
                                    </div>
                                    <div className='col-md-12'>
                                        <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                            <thead className="">
                                                <tr className="clr">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Applied For</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Manage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    application_list.length > 0 ?
                                                    (
                                                        application_list.map((item, index) => {
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.am_first_name + " " + item.am_middle_name + " " + item.am_last_name}</td>
                                                                    <td>{item.am_position}</td>
                                                                    <td>{item.am_email}</td>
                                                                    <td>{item.am_phone_cell}</td>
                                                                    <td>
                                                                        <div className="btn-group mr-2 mb-2">
                                                                            <button type="button" className="btn btn-sm btn-white dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="fa fa-cog" aria-hidden="true"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu">
                                                                                <a className="dropdown-item" href="#">Manage Educational History</a>
                                                                                <a className="dropdown-item" href="#">Manage Achivements</a>
                                                                                <a className="dropdown-item" href="#">Manage Training & Development</a>
                                                                                <a className="dropdown-item" href="#">Manage Employment History</a>
                                                                                <a className="dropdown-item" href="#">Manage Co-curriculum Activities</a>
                                                                                <a className="dropdown-item" href="#">Manage Family Particulars</a>
                                                                                <a className="dropdown-item" href="#">Manage Professional References</a>
                                                                            </div>
                                                                        </div>
                                                                        <button className='btn btn-sm btn-info mr-2 mb-2' onClick={() => handleEdit(item.am_pk_id)}>Edit</button>
                                                                        <button className='btn btn-sm btn-danger mr-2 mb-2' onClick={() => handleDelete(item.am_pk_id)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Applications