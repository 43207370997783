import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import "react-confirm-alert/src/react-confirm-alert.css";
import Rating from "@mui/material/Rating";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Modal from "react-modal";
const RatingS = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FAAF00",
  },
  "& .MuiRating-iconHover": {
    color: "#FAAF00",
  },
});

const Rm_forms_master = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [list, setList] = useState([]);
  const [hf_name, setHf_name] = useState("");
  const [edit_id, setEdit_id] = useState("");

  const customStyles = {
    content: {
      top: "55%",
      left: "60%",
      right: "30%",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "80%",
    },
  };

  useEffect(() => {
    get_forms_list();
  }, []);

  const handleCreate = (e) => {
    e.preventDefault();
    if (edit_id !== "") {
      if (hf_name !== "") {
        setFullScreenLoader(true);
        let data_ins = {
          hf_name: hf_name,
        };
        Apiconnect.postData(`hrms_forms/update/${edit_id}`, data_ins).then(
          (res) => {
            alert.success("Updated Successfully");
            setFullScreenLoader(true);
            get_forms_list();
            setHf_name("");
            setEdit_id("");
          }
        );
      } else {
        alert.error("Please Enter Form Name");
      }
    } else {
      if (hf_name !== "") {
        setFullScreenLoader(true);
        let data_ins = {
          hf_name: hf_name,
          hf_added_by: emp_id,
        };
        Apiconnect.postData(`hrms_forms/create`, data_ins).then((res) => {
          alert.success("Created Successfully");
          setFullScreenLoader(true);
          get_forms_list();
          setHf_name("");
        });
      } else {
        alert.error("Please Enter Form Name");
      }
    }
  };

  const get_forms_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hrms_forms/getall`).then((res) => {
      setFullScreenLoader(false);
      setList(Apiconnect.decrypt_obj(res.data.data));
    });
  };

  const handleEdit = (id) => {
    setEdit_id(id);
    let data = list.find((item) => item.hf_pk_id == id);
    console.log(data);
    setHf_name(data.ha_name);
  };

  const handleDelete = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hrms_forms/delete/${id}`).then((res) => {
      setFullScreenLoader(false);
      get_forms_list();
    });
  };
  const [id, setId] = useState("");

  return (
    <div className="content-page main_bg">
      {fullScreenLoader ? (
        <FullScreenLoading loading loaderColor="#3498db" />
      ) : null}
      <div className="container-fluid">
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="iq-card">
                <div
                  className="iq-card-header d-flex justify-content-between model_head"
                  style={{
                    borderRadius: 5,
                    background:
                      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
                  }}
                >
                  <div className="iq-header-title" style={{ float: "left" }}>
                    <h4 className="card-title text-white">Assign Score</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  {
                    <Interview
                      form_id={id}
                      form_name=""
                      company_id=""
                      emp_id=""
                      applicant_id=""
                      applicant_assessment_mst_id=""
                      applicant_name=""
                      assessment_date=""
                      applied_position=""
                      applicant_qualification=""
                      applicant_qualification_add=""
                      applicant_current_salary=""
                      applicant_expected_salary=""
                      applicant_dob=""
                      applicant_notice_period=""
                      setModalIsOpen=""
                      get_assessment_schedule_list=""
                    />
                  }
                  {
                    <Interview_ledership
                      form_id={id}
                      form_name=""
                      company_id=""
                      emp_id=""
                      applicant_id=""
                      applicant_assessment_mst_id=""
                      applicant_name=""
                      assessment_date=""
                      applied_position=""
                      applicant_qualification=""
                      applicant_qualification_add=""
                      applicant_current_salary=""
                      applicant_expected_salary=""
                      applicant_dob=""
                      applicant_notice_period=""
                      setModalIsOpen=""
                      get_assessment_schedule_list=""
                    />
                  }
                  {
                    <Demo_class
                      form_id={id}
                      form_name=""
                      company_id=""
                      emp_id=""
                      applicant_id=""
                      applicant_assessment_mst_id=""
                      applicant_name=""
                      assessment_date=""
                      applied_position=""
                      applicant_qualification=""
                      applicant_qualification_add=""
                      applicant_current_salary=""
                      applicant_expected_salary=""
                      applicant_dob=""
                      applicant_notice_period=""
                      setModalIsOpen=""
                      get_assessment_schedule_list=""
                    />
                  }
                  {
                    <Test
                      form_id={id}
                      form_name=""
                      company_id=""
                      emp_id=""
                      applicant_id=""
                      applicant_assessment_mst_id=""
                      applicant_name=""
                      assessment_date=""
                      applied_position=""
                      applicant_qualification=""
                      applicant_qualification_add=""
                      applicant_current_salary=""
                      applicant_expected_salary=""
                      applicant_dob=""
                      applicant_notice_period=""
                      setModalIsOpen=""
                      get_assessment_schedule_list=""
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="iq-card main_container">
          <div className="iq-card-header card_head">
            <h4 className="text-white">Hiring Forms Master</h4>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Form Name"
                  name="hf_name"
                  value={hf_name}
                  onChange={(e) => setHf_name(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={(e) => handleCreate(e)}
                >
                  {edit_id ? "Update" : "Create"}
                </button>
                {edit_id && (
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setEdit_id("");
                      setHf_name("");
                    }}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mt-3">
                <div table-responsive>
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "10%" }} className="text-center">
                          #
                        </th>
                        <th className="text-center">Name</th>
                        <th className="text-center">View Form</th>
                        <th style={{ width: "30%" }} className="text-center">
                          Manage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.length > 0 ? (
                        list.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td>{item.hf_name}</td>
                              <td className="text-center">
                                {item.hf_pk_id < 5 && (
                                  <button
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                      setModalIsOpen(true);
                                      setId(item.hf_pk_id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                )}
                                {item.hf_pk_id > 5 && (
                                  <span className="badge badge-warning">
                                    Developer's Action Required
                                  </span>
                                )}
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-sm btn-info mr-2"
                                  onClick={() => handleEdit(item.hf_pk_id)}
                                >
                                  Edit
                                </button>
                                {/* <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.hf_pk_id)}>Delete</button> */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <th colSpan={3} className="center text-muted">
                            ---- No Records Found ----
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_forms_master;

/* ------------------------------------------------------------------------------------------------- */
const Test = (props) => {
  /* 1 */
  const alert = useAlert();
  const locusr = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(locusr.id);
  let company_name = locusr.company_name;
  let fullname = locusr.fullname;
  const {
    form_id,
    form_name,
    company_id,
    emp_id,
    applicant_id,
    applicant_assessment_mst_id,
    applicant_name,
    assessment_date,
    applied_position,
    applicant_qualification,
    applicant_qualification_add,
    applicant_current_salary,
    applicant_expected_salary,
    applicant_dob,
    applicant_notice_period,
    get_assessment_schedule_list,
    setModalIsOpen,
  } = props;

  const [inputs, setInputs] = useState({
    hpts_company_id: company_id,
    hpts_panelist_id: emp_id,
    hpts_applicant_id: applicant_id,
    hpts_applicant_assessment_mst_id: applicant_assessment_mst_id,
    hpts_applicant_name: applicant_name,
    hpts_test_date: assessment_date,
    hpts_position: applied_position,
    hpts_qualification: applicant_qualification,
    hpts_current_salary: applicant_current_salary,
    hpts_salary_expected: applicant_expected_salary,
    hpts_dob: applicant_dob,
    hpts_notice_period: applicant_notice_period,
    hpts_resume_source: "",
    hpts_total_qsns: "",
    hpts_total_marks: "",
    hpts_total_attempted: "",
    hpts_total_correct: "",
    // hpts_total_incorrect:"",
    // hpts_total_obtained:"",
    // hpts_percentage_on_total:"",
    hpts_added_by: emp_id,
  });

  const {
    hpts_resume_source,
    hpts_total_qsns,
    hpts_total_marks,
    hpts_total_attempted,
    hpts_total_correct,
    hpts_total_incorrect,
    hpts_total_obtained,
    hpts_percentage_on_total,
    hpts_notice_period,
    hpts_dob,
    hpts_salary_expected,
    hpts_test_date,
    hpts_position,
    hpts_current_salary,
  } = inputs;

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const dt = moment(hpts_dob).format("DD/MM/YYYY");
  const test_dt = moment(hpts_test_date).format("DD/MM/YYYY");

  let total_incorrect =
    Number(hpts_total_attempted) - Number(hpts_total_correct);
  let total_marks_obtained =
    (Number(hpts_total_marks) / Number(hpts_total_qsns)) *
    Number(hpts_total_correct);
  let total_percentage = (
    (Number(total_marks_obtained) / Number(hpts_total_marks)) *
    100
  ).toFixed(2);

  inputs["hpts_total_incorrect"] = total_incorrect;
  inputs["hpts_total_obtained"] = total_marks_obtained;
  inputs["hpts_percentage_on_total"] = total_percentage;

  const handleSubmit = () => {
    // if(hpts_resume_source!=="" && hpts_total_qsns!=="" &&hpts_total_marks!=="" &&hpts_total_attempted!=="" &&hpts_total_correct!==""){
    //     Apiconnect.postData(`hiring_application/test_score_create`, inputs).then(res => {
    //         alert.success("Success")
    //         setModalIsOpen(false)
    //         get_assessment_schedule_list()
    //     })
    // }else{
    //     alert.error("Input fields are empty")
    // }
  };

  return (
    <>
      {form_id == 1 && (
        <div className="row cmp_card">
          <div className="col-md-3">
            <span>Name</span>
            <input
              type="text"
              className="form-control"
              value={applicant_name}
            />
          </div>
          <div className="col-md-3">
            <span>Date Of Test</span>
            <input type="text" className="form-control" value={test_dt} />
          </div>
          <div className="col-md-3">
            <span>Position</span>
            <input type="text" className="form-control" value={hpts_position} />
          </div>
          <div className="col-md-3">
            <span>Academic Qualification</span>
            <input
              type="text"
              className="form-control"
              value={applicant_qualification}
            />
          </div>
          <div className="col-md-3">
            <span>Current Salary</span>
            <input
              type="text"
              className="form-control"
              value={hpts_current_salary}
            />
          </div>
          <div className="col-md-3">
            <span>Expected Salary</span>
            <input
              type="text"
              className="form-control"
              value={hpts_salary_expected}
            />
          </div>
          <div className="col-md-3">
            <span>Date Of Birth</span>
            <input type="text" className="form-control" value={dt} />
          </div>
          <div className="col-md-3">
            <span>Notice Period</span>
            <input
              type="text"
              className="form-control"
              value={hpts_notice_period}
            />
          </div>
          <div className="col-md-3">
            <span>Resume Source</span>
            <input
              type="text"
              className="form-control"
              name="hpts_resume_source"
              value={hpts_resume_source}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-12 mt-3">
            <div className="iq-card">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>1. </td>
                    <td>
                      <span>Total Questions</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Questions"
                        name="hpts_total_qsns"
                        value={hpts_total_qsns}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>2. </td>
                    <td>
                      <span>Total Marks</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Marks"
                        name="hpts_total_marks"
                        value={hpts_total_marks}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3. </td>
                    <td>
                      <span>Total Attempted</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Attempted"
                        name="hpts_total_attempted"
                        value={hpts_total_attempted}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4. </td>
                    <td>
                      <span>Total Correct</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Correct"
                        name="hpts_total_correct"
                        value={hpts_total_correct}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5. </td>
                    <td>
                      <span>Total Incorrect</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Inorrect"
                        value={total_incorrect}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>6. </td>
                    <td>
                      <span>Total Marks Obtained</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Total Marks Obtained"
                        value={total_marks_obtained}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>7. </td>
                    <td>
                      <span>Percentage On Total</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Percentage On Total"
                        value={total_percentage}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12">
            <button
              className="btn btn-sm btn-primary float-right"
              onClick={handleSubmit}
            >
              Create
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const Interview = (props) => {
  /* 2 */
  const {
    form_id,
    form_name,
    company_id,
    emp_id,
    applicant_id,
    applicant_assessment_mst_id,
    applicant_name,
    assessment_date,
    applied_position,
    applicant_qualification,
    applicant_qualification_add,
    applicant_current_salary,
    applicant_expected_salary,
    applicant_dob,
    applicant_notice_period,
    setModalIsOpen,
    get_assessment_schedule_list,
  } = props;

  const alert = useAlert();

  const [hpis_inputs, setHpis_inputs] = useState({
    hpis_company_id: company_id,
    hpis_panelist_id: emp_id,
    hpis_applicant_id: applicant_id,
    hpis_applicant_assessment_mst_id: applicant_assessment_mst_id,
    hpis_applicant_name: applicant_name,
    hpis_interview_date: assessment_date,
    hpis_position: applied_position,
    hpis_academic_qualification: applicant_qualification,
    hpis_additional_qualification: applicant_qualification_add,
    hpis_total_experience: "",
    hpis_unit: "",
    hpis_current_salary: applicant_current_salary,
    hpis_expected_salary: applicant_expected_salary,
    hpaamps_dob: applicant_dob,
    hpis_notice_period: applicant_notice_period,
    hpis_resume_source: "",

    /* ---------------------------- */
    hpis_communication: "",
    hpis_critical_thinking: "",
    hpis_sub_knowledge: "",
    hpis_job_exposure: "",
    hpaamps_empathy: "",
    hpis_expression: "",
    hpis_attitude: "",
    hpis_awerness: "",
    hpis_networking: "",
    hpis_social_presence: "",
    hpis_parent_connect: "",
    hpis_body_lang: "",
    /* ------------------------------ */
    hpis_total_score: "",
    hpis_percentage_score: "",
    hpis_avg_score: "",
    hpis_years_of_service: "",
    hpis_age_in_years: "",
    hpis_remark: "",
    hpis_added_by: emp_id,
  });

  const {
    hpis_additional_qualification,
    hpis_communication,
    hpaamps_dob,
    hpaamps_empathy,
    hpis_academic_qualification,
    hpis_added_by,
    hpis_age_in_years,
    hpis_applicant_assessment_mst_id,
    hpis_applicant_id,
    hpis_applicant_name,
    hpis_attitude,
    hpis_avg_score,
    hpis_awerness,
    hpis_body_lang,
    hpis_company_id,
    hpis_critical_thinking,
    hpis_current_salary,
    hpis_expected_salary,
    hpis_expression,
    hpis_interview_date,
    hpis_job_exposure,
    hpis_networking,
    hpis_notice_period,
    hpis_panelist_id,
    hpis_parent_connect,
    hpis_percentage_score,
    hpis_position,
    hpis_remark,
    hpis_resume_source,
    hpis_social_presence,
    hpis_sub_knowledge,
    hpis_total_experience,
    hpis_total_score,
    hpis_unit,
    hpis_years_of_service,
  } = hpis_inputs;
  let total_score =
    Number(hpis_communication) +
    Number(hpis_critical_thinking) +
    Number(hpis_sub_knowledge) +
    Number(hpis_job_exposure) +
    Number(hpaamps_empathy) +
    Number(hpis_expression) +
    Number(hpis_attitude) +
    Number(hpis_awerness) +
    Number(hpis_networking) +
    Number(hpis_social_presence) +
    Number(hpis_parent_connect) +
    Number(hpis_body_lang);

  hpis_inputs["hpis_total_score"] = total_score;
  hpis_inputs["hpis_percentage_score"] = ((total_score / 60) * 100).toFixed(2);

  let assessment_date_format = moment(assessment_date).format("DD/MM/YYYY");
  let _dob_ = moment(assessment_date).format("DD/MM/YYYY");

  const onInputChange = (e) => {
    setHpis_inputs({ ...hpis_inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    // e.preventDefault()
    // console.log(hpis_inputs)
    // if(
    //     hpis_total_experience!=="" &&
    //     hpis_unit!=="" &&
    //     hpis_resume_source!=="" &&
    //     hpis_communication!=="" &&
    //     hpis_critical_thinking!=="" &&
    //     hpis_sub_knowledge!=="" &&
    //     hpis_job_exposure!=="" &&
    //     hpaamps_empathy!=="" &&
    //     hpis_expression!=="" &&
    //     hpis_attitude!=="" &&
    //     hpis_awerness!=="" &&
    //     hpis_networking!=="" &&
    //     hpis_social_presence!=="" &&
    //     hpis_parent_connect!=="" &&
    //     hpis_body_lang!=="" &&
    //     hpis_years_of_service!=="" &&
    //     hpis_age_in_years!=="" &&
    //     hpis_remark!==""
    // ){
    //     Apiconnect.postData(`hiring_application/interview_score_create`, hpis_inputs).then(res => {
    //         alert.success("Success")
    //         setModalIsOpen(false)
    //         get_assessment_schedule_list()
    //     })
    // }else{
    //     alert.error("Empty Input Fields")
    // }
  };

  return (
    <>
      {form_id == 2 && (
        <div className="row">
          <div className="col-md-3">
            <label className="text-6xl">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              value={hpis_applicant_name}
            />
          </div>
          <div className="col-md-3">
            <label>Date of {form_name}</label>
            <input
              type="text"
              className="form-control"
              value={assessment_date_format}
            />
          </div>
          <div className="col-md-3">
            <label>Position</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Position"
              value={hpis_position}
            />
          </div>
          <div className="col-md-3">
            <label>Academic Qualification</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Academic Qualification"
              value={hpis_academic_qualification}
            />
          </div>
          <div className="col-md-3">
            <label>Additional Qualification</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Additional Qualification"
              value={hpis_additional_qualification}
            />
          </div>
          <div className="col-md-3">
            <label>Total Experience</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Total Experience"
              name="hpis_total_experience"
              value={hpis_total_experience}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-3">
            <label>Unit</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Unit"
              name="hpis_unit"
              value={hpis_unit}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-3">
            <label>Current Salary</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Current Salary"
              value={hpis_current_salary}
            />
          </div>
          <div className="col-md-3">
            <label>Expected Salary</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Expected Salary"
              value={hpis_expected_salary}
            />
          </div>
          <div className="col-md-3">
            <label>Date Of Birth</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Date Of Birth"
              value={_dob_}
            />
          </div>
          <div className="col-md-3">
            <label>Notice Period</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Notice Period"
              value={hpis_notice_period}
            />
          </div>
          <div className="col-md-3">
            <label>Resume Source</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Resume Source"
              name="hpis_resume_source"
              value={hpis_resume_source}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-12 mt-3">
            <table className="table center table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>Criteria Of Judgement</th>
                  <th>Scale</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ability to Communicate/Listen</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_communication"
                      value={hpis_communication}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Critical Thinking</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_critical_thinking"
                      value={hpis_critical_thinking}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Subject Knowledge</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_sub_knowledge"
                      value={hpis_sub_knowledge}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Exposure to the job Profile</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_job_exposure"
                      value={hpis_job_exposure}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Empathy</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpaamps_empathy"
                      value={hpaamps_empathy}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Expression</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_expression"
                      value={hpis_expression}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Body Language</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_body_lang"
                      value={hpis_body_lang}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Attitude towards job</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_attitude"
                      value={hpis_attitude}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Awareness of latest systems</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_awerness"
                      value={hpis_awerness}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Network with Relevant people</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_networking"
                      value={hpis_networking}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Presence On Social Media</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_social_presence"
                      value={hpis_social_presence}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Parent Connect</td>
                  <td>
                    <RatingS
                      style={{ color: "yellow" }}
                      defaultValue={0}
                      max={5}
                      name="hpis_parent_connect"
                      value={hpis_parent_connect}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="font-weight-bold">
                      Total Score Out Of 60
                    </span>
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={hpis_inputs.hpis_total_score}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="font-weight-bold">Percentage Score</span>
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={hpis_inputs.hpis_percentage_score}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-3">
            <label>No. Of Years In Service</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter No. Of Years In Service"
              name="hpis_years_of_service"
              value={hpis_years_of_service}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-3">
            <label>Age In Years</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter Age In Years"
              name="hpis_age_in_years"
              value={hpis_age_in_years}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-3">
            <label>Remarks</label>
            <select
              className="form-control"
              name="hpis_remark"
              value={hpis_remark}
              onChange={(e) => onInputChange(e)}
            >
              <option value="SELECT">Select</option>
              <option value="REJECT">Reject</option>
              <option value="ONHOLD">Onhold</option>
            </select>
          </div>
          <div className="col-md-12 mt-3">
            <button
              className="btn btn-sm btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const Interview_ledership = (props) => {
  /* 3 */
  const alert = useAlert();
  const {
    form_id,
    form_name,
    company_id,
    emp_id,
    applicant_id,
    applicant_assessment_mst_id,
    applicant_name,
    assessment_date,
    applied_position,
    applicant_qualification,
    applicant_qualification_add,
    applicant_current_salary,
    applicant_expected_salary,
    applicant_dob,
    applicant_notice_period,
    get_assessment_schedule_list,
    setModalIsOpen,
  } = props;

  const [inputs, setInputs] = useState({
    hpils_company_id: company_id,
    hpils_panelist_id: emp_id,
    hpils_applicant_id: applicant_id,
    hpils_applicant_assessment_mst_id: applicant_assessment_mst_id,
    hpils_applicant_name: applicant_name,
    hpils_interview_date: assessment_date,
    hpils_position: applied_position,
    hpils_academic_qualification: applicant_qualification,
    hpils_additional_qualification: applicant_qualification_add,
    hpils_current_salary: applicant_current_salary,
    hpils_expected_salary: applicant_expected_salary,
    hpils_dob: applicant_dob,
    hpils_notice_period: applicant_notice_period,
    hpils_created_by: emp_id,
    hpils_total_experience: "",
    hpils_resume_source: "",
    hpils_communication: "",
    hpils_critical_thinking: "",
    hpils_school_process: "",
    hpils_digital: "",
    hpils_empathy: "",
    hpils_international_exposure: "",
    hpils_vision: "",
    hpils_liasioning: "",
    hpils_parent_connect: "",
    hpils_edu_world: "",
    hpils_ppl_management: "",
    hpils_awerness_ib: "",
    hpils_social_presence: "",
    hpils_total_score: "",
    hpils_score_percentage: "",
    hpils_avg_score: "",
    hpils_years_of_service: "",
    hpils_age_years: "",
    hpils_remark: "",
  });
  const {
    hpils_total_experience,
    hpils_applicant_assessment_mst_id,
    hpils_applicant_id,
    hpils_applicant_name,
    hpils_resume_source,
    hpils_avg_score,
    hpils_company_id,
    hpils_created_by,
    hpils_current_salary,
    hpils_communication,
    hpils_dob,
    hpils_expected_salary,
    hpils_interview_date,
    hpils_notice_period,
    hpils_critical_thinking,
    hpils_panelist_id,
    hpils_position,
    hpils_remark,
    hpils_score_percentage,
    hpils_school_process,
    hpils_social_presence,
    hpils_total_score,
    hpils_years_of_service,
    hpils_digital,
    hpils_empathy,
    hpils_international_exposure,
    hpils_vision,
    hpils_liasioning,
    hpils_parent_connect,
    hpils_edu_world,
    hpils_ppl_management,
    hpils_awerness_ib,
    hpils_academic_qualification,
    hpils_additional_qualification,
    hpils_age_years,
  } = inputs;

  let total_score =
    Number(hpils_communication) +
    Number(hpils_critical_thinking) +
    Number(hpils_school_process) +
    Number(hpils_digital) +
    Number(hpils_empathy) +
    Number(hpils_international_exposure) +
    Number(hpils_vision) +
    Number(hpils_liasioning) +
    Number(hpils_parent_connect) +
    Number(hpils_edu_world) +
    Number(hpils_ppl_management) +
    Number(hpils_awerness_ib) +
    Number(hpils_social_presence);
  inputs["hpils_total_score"] = total_score;
  inputs["hpils_score_percentage"] = ((total_score / 65) * 100).toFixed(2);

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // if(
    //     hpils_total_experience!=="" &&
    //     hpils_resume_source!=="" &&
    //     hpils_communication!=="" &&
    //     hpils_critical_thinking!=="" &&
    //     hpils_school_process!=="" &&
    //     hpils_digital!=="" &&
    //     hpils_empathy!=="" &&
    //     hpils_international_exposure!=="" &&
    //     hpils_vision!=="" &&
    //     hpils_liasioning!=="" &&
    //     hpils_parent_connect!=="" &&
    //     hpils_edu_world!=="" &&
    //     hpils_ppl_management!=="" &&
    //     hpils_awerness_ib!=="" &&
    //     hpils_social_presence!=="" &&
    //     hpils_total_score!=="" &&
    //     hpils_score_percentage!=="" &&
    //     hpils_years_of_service!=="" &&
    //     hpils_age_years!=="" &&
    //     hpils_remark!==""
    // ){
    //     Apiconnect.postData(`hiring_application/leadership_interview_score_create`, inputs).then(res => {
    //         alert.success("Success")
    //         setModalIsOpen(false)
    //         get_assessment_schedule_list()
    //     })
    // }else{
    //     alert.error("Input fields are empty")
    // }
  };

  return (
    <>
      {form_id == 3 && (
        <div className="row">
          <div className="col-md-4">
            <span>Name</span>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              value={hpils_applicant_name}
            />
          </div>
          <div className="col-md-4">
            <span>Total Experience</span>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              name="hpils_total_experience"
              value={hpils_total_experience}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-4">
            <span>Resume Source</span>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              name="hpils_resume_source"
              value={hpils_resume_source}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-12 mt-3">
            <table className="table table-bordered center">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>Criteria Of Judgement</th>
                  <th>Scale</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ability to Communicate</td>
                  <td>
                    <RatingS
                      name="hpils_communication"
                      value={hpils_communication}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Critical Thinking</td>
                  <td>
                    <RatingS
                      name="hpils_critical_thinking"
                      value={hpils_critical_thinking}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Understanding the school Processess</td>
                  <td>
                    <RatingS
                      name="hpils_school_process"
                      value={hpils_school_process}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Digital Understanding</td>
                  <td>
                    <RatingS
                      name="hpils_digital"
                      value={hpils_digital}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Empathy</td>
                  <td>
                    <RatingS
                      name="hpils_empathy"
                      value={hpils_empathy}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>International Exposure</td>
                  <td>
                    <RatingS
                      name="hpils_international_exposure"
                      value={hpils_international_exposure}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Vision</td>
                  <td>
                    <RatingS
                      name="hpils_vision"
                      value={hpils_vision}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Liasioning with District Officials</td>
                  <td>
                    <RatingS
                      name="hpils_liasioning"
                      value={hpils_liasioning}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Parent Connect</td>
                  <td>
                    <RatingS
                      name="hpils_parent_connect"
                      value={hpils_parent_connect}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Education World</td>
                  <td>
                    <RatingS
                      name="hpils_edu_world"
                      value={hpils_edu_world}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>People Management</td>
                  <td>
                    <RatingS
                      name="hpils_ppl_management"
                      value={hpils_ppl_management}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Exposure to IBDP, IGCSE, etc.</td>
                  <td>
                    <RatingS
                      name="hpils_awerness_ib"
                      value={hpils_awerness_ib}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>Social Media Presence (Own YouTube Channel)</td>
                  <td>
                    <RatingS
                      name="hpils_social_presence"
                      value={hpils_social_presence}
                      defaultValue={0}
                      max={5}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="font-weight-bold">
                    Total Score of Interaction out of 65
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={hpils_total_score}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="font-weight-bold">
                    Percentage Score
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={hpils_score_percentage}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>No. of years of Service</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      name="hpils_years_of_service"
                      value={hpils_years_of_service}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Age in Years</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      name="hpils_age_years"
                      value={hpils_age_years}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <label>Remark</label>
            <select
              className="form-control"
              name="hpils_remark"
              value={hpils_remark}
              onChange={(e) => onInputChange(e)}
            >
              <option value="SELECT">Select</option>
              <option value="REJECT">Reject</option>
              <option value="ONHOLD">Onhold</option>
            </select>
          </div>
          <div className="col-md-8">
            <button
              className="btn btn-sm btn-primary float-right mt-3"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const Demo_class = (props) => {
  /* 4 */
  const alert = useAlert();
  const locusr = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(locusr.id);
  let company_name = locusr.company_name;
  let fullname = locusr.fullname;
  const {
    form_id,
    form_name,
    company_id,
    emp_id,
    applicant_id,
    applicant_assessment_mst_id,
    applicant_name,
    assessment_date,
    applied_position,
    applicant_qualification,
    applicant_qualification_add,
    applicant_current_salary,
    applicant_expected_salary,
    applicant_dob,
    applicant_notice_period,
    setModalIsOpen,
    get_assessment_schedule_list,
  } = props;

  const [inputs, setInputs] = useState({
    hpdcs_company_id: company_id,
    hpdcs_panelist_id: emp_id,
    hpdcs_applicant_id: applicant_id,
    hpdcs_applicant_assessment_mst_id: applicant_assessment_mst_id,
    hpdcs_applicant_name: applicant_name,
    hpdcs_interview_date: assessment_date,
    hpdcs_position: applied_position,

    hpdcs_class_sec: "",
    hpdcs_subject: "",
    hpdcs_topic: "",
    hpdcs_org: company_name,
    hpdcs_org_others: "",

    hpdcs_planning_1: "",
    hpdcs_planning_2: "",
    hpdcs_planning_3: "",
    // hpdcs_planning_total:"",

    hpdcs_disposition_1: "",
    hpdcs_disposition_2: "",
    hpdcs_disposition_3: "",
    // hpdcs_disposition_total:"",

    hpdcs_clarity_1: "",
    hpdcs_clarity_2: "",
    hpdcs_clarity_3: "",
    // hpdcs_clarity_total:"",

    hpdcs_questioning_1: "",
    hpdcs_questioning_2: "",
    hpdcs_questioning_3: "",
    // hpdcs_questioning_total:"",

    hpdcs_engagement_1: "",
    hpdcs_engagement_2: "",
    hpdcs_engagement_3: "",
    // hpdcs_engagement_total:"",

    hpdcs_assessment_1: "",
    hpdcs_assessment_2: "",
    hpdcs_assessment_3: "",
    // hpdcs_assessment_total:"",

    hpdcs_closure_1: "",
    hpdcs_closure_2: "",
    hpdcs_closure_3: "",
    // hpdcs_closure_total:"",

    hpdcs_tw_1: "",
    hpdcs_tw_2: "",
    hpdcs_tw_3: "",
    // hpdcs_tw_total:"",

    hpdcs_documentation: "",
    // hpdcs_total_score:"",
    hpdcs_teachers_reflection: "",
    hpdcs_comment: "",
    hpdcs_created_by: emp_id,
  });

  const {
    hpdcs_class_sec,
    hpdcs_subject,
    hpdcs_topic,
    hpdcs_org,
    hpdcs_org_others,
    hpdcs_planning_1,
    hpdcs_planning_2,
    hpdcs_planning_3,
    hpdcs_disposition_1,
    hpdcs_disposition_2,
    hpdcs_disposition_3,
    hpdcs_clarity_1,
    hpdcs_clarity_2,
    hpdcs_clarity_3,
    hpdcs_questioning_1,
    hpdcs_questioning_2,
    hpdcs_questioning_3,
    hpdcs_engagement_1,
    hpdcs_engagement_2,
    hpdcs_engagement_3,
    hpdcs_assessment_1,
    hpdcs_assessment_2,
    hpdcs_assessment_3,
    hpdcs_closure_1,
    hpdcs_closure_2,
    hpdcs_closure_3,
    hpdcs_tw_1,
    hpdcs_tw_2,
    hpdcs_tw_3,
    hpdcs_documentation,
    hpdcs_teachers_reflection,
    hpdcs_comment,
    hpdcs_applicant_name,
    hpdcs_interview_date,
  } = inputs;

  let hpdcs_planning_total =
    Number(hpdcs_planning_1) +
    Number(hpdcs_planning_2) +
    Number(hpdcs_planning_3);
  let hpdcs_disposition_total =
    Number(hpdcs_disposition_1) +
    Number(hpdcs_disposition_2) +
    Number(hpdcs_disposition_3);
  let hpdcs_clarity_total =
    Number(hpdcs_clarity_1) + Number(hpdcs_clarity_2) + Number(hpdcs_clarity_3);
  let hpdcs_questioning_total =
    Number(hpdcs_questioning_1) +
    Number(hpdcs_questioning_2) +
    Number(hpdcs_questioning_3);
  let hpdcs_engagement_total =
    Number(hpdcs_engagement_1) +
    Number(hpdcs_engagement_2) +
    Number(hpdcs_engagement_3);
  let hpdcs_assessment_total =
    Number(hpdcs_assessment_1) +
    Number(hpdcs_assessment_2) +
    Number(hpdcs_assessment_3);
  let hpdcs_closure_total =
    Number(hpdcs_closure_1) + Number(hpdcs_closure_2) + Number(hpdcs_closure_3);
  let hpdcs_tw_total =
    Number(hpdcs_tw_1) + Number(hpdcs_tw_2) + Number(hpdcs_tw_3);

  let hpdcs_total_score =
    hpdcs_planning_total +
    hpdcs_disposition_total +
    hpdcs_clarity_total +
    hpdcs_questioning_total +
    hpdcs_engagement_total +
    hpdcs_assessment_total +
    hpdcs_closure_total +
    hpdcs_tw_total +
    Number(hpdcs_documentation);

  const onInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  let dt = moment(hpdcs_interview_date).format("DD/MM/YYYY");

  const handleSubmit = (e) => {
    // e.preventDefault()
    // inputs['hpdcs_planning_total']=hpdcs_planning_total
    // inputs['hpdcs_disposition_total']=hpdcs_disposition_total
    // inputs['hpdcs_clarity_total']=hpdcs_clarity_total
    // inputs['hpdcs_questioning_total']=hpdcs_questioning_total
    // inputs['hpdcs_engagement_total']=hpdcs_engagement_total
    // inputs['hpdcs_assessment_total']=hpdcs_assessment_total
    // inputs['hpdcs_closure_total']=hpdcs_closure_total
    // inputs['hpdcs_tw_total']=hpdcs_tw_total
    // inputs['hpdcs_total_score']=hpdcs_total_score
    // if(
    // hpdcs_class_sec!=="" &&
    // hpdcs_subject!=="" &&
    // hpdcs_topic!=="" &&
    // hpdcs_planning_1!=="" &&
    // hpdcs_planning_2!=="" &&
    // hpdcs_planning_3!=="" &&
    // hpdcs_disposition_1!=="" &&
    // hpdcs_disposition_2!=="" &&
    // hpdcs_disposition_3!=="" &&
    // hpdcs_clarity_1!=="" &&
    // hpdcs_clarity_2!=="" &&
    // hpdcs_clarity_3!=="" &&
    // hpdcs_questioning_1!=="" &&
    // hpdcs_questioning_2!=="" &&
    // hpdcs_questioning_3!=="" &&
    // hpdcs_engagement_1!=="" &&
    // hpdcs_engagement_2!=="" &&
    // hpdcs_engagement_3!=="" &&
    // hpdcs_assessment_1!=="" &&
    // hpdcs_assessment_2!=="" &&
    // hpdcs_assessment_3!=="" &&
    // hpdcs_closure_1!=="" &&
    // hpdcs_closure_2!=="" &&
    // hpdcs_closure_3!=="" &&
    // hpdcs_tw_1!=="" &&
    // hpdcs_tw_2!=="" &&
    // hpdcs_tw_3!=="" &&
    // hpdcs_documentation!=="" &&
    // hpdcs_teachers_reflection!=="" &&
    // hpdcs_comment!==""
    // ){
    //     Apiconnect.postData(`hiring_application/demo_class_score_create`, inputs).then(res => {
    //         alert.success("Success")
    //         setModalIsOpen(false)
    //         get_assessment_schedule_list()
    //     })
    // }else{
    //     alert.error("Input Fields Empty")
    // }
  };

  return (
    <>
      {form_id == 4 && (
        <div className="row">
          <div className="col-md-4">
            <label>Name of Teacher</label>
            <input
              className="form-control"
              type="text"
              value={hpdcs_applicant_name}
            />
          </div>
          <div className="col-md-4">
            <label>Class & Section</label>
            <input
              className="form-control"
              type="text"
              name="hpdcs_class_sec"
              value={hpdcs_class_sec}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label>Date</label>
            <input className="form-control" type="text" value={dt} />
          </div>
          <div className="col-md-4">
            <label>Subject</label>
            <input
              className="form-control"
              type="text"
              name="hpdcs_subject"
              value={hpdcs_subject}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label>Topic</label>
            <input
              className="form-control"
              type="text"
              name="hpdcs_topic"
              value={hpdcs_topic}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label>Organisation</label>
            <input className="form-control" type="text" value={hpdcs_org} />
          </div>
          <div className="col-md-4">
            <label>Organisation (Others)</label>
            <input
              className="form-control"
              type="text"
              name="hpdcs_org_others"
              value={hpdcs_org_others}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="col-md-12 mt-2">
            <label>1. Instructional Planning</label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Clearly identified learning objectives/outcomes</td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_planning_1"
                      value={hpdcs_planning_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Lesson is well planned, structured & flows sequentially
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_planning_2"
                      value={hpdcs_planning_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Planned for varied instructional and assessment strategies
                    aligned to identified learning outcomes with optimum and
                    creative usage of technological aids/ other resources.{" "}
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_planning_3"
                      value={hpdcs_planning_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="text-center font-weight-bold  text-muted"
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_planning_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>2. Disposition in the class room</label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Began class with a warm-up and connected with the previous
                    knowledge
                  </td>
                  <td className="text-center">
                    <RatingS
                      className="text-center"
                      defaultValue={0}
                      max={4}
                      name="hpdcs_disposition_1"
                      value={hpdcs_disposition_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Teacher encourages understanding and co-operation with
                    students . Brings out the best in student through
                    affirmation.
                  </td>
                  <td className="text-center">
                    <RatingS
                      className="text-center"
                      defaultValue={0}
                      max={4}
                      name="hpdcs_disposition_2"
                      value={hpdcs_disposition_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Practices proactive discipline. Good behavior and effort of
                    student praised & prompt action taken to address poor
                    behavior
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_disposition_3"
                      value={hpdcs_disposition_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="text-center font-weight-bold text-muted"
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_disposition_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>
              3. Clarity and Specificness of Instructions & Explanations
            </label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Related the concept with real life situations by using
                    modern and innovative methods
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_clarity_1"
                      value={hpdcs_clarity_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Develops an environment that nurtures creative thinking and
                    encourgaes Questioning .
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_clarity_2"
                      value={hpdcs_clarity_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Instructions and explainations are clear and specific . The
                    teacher involves all students listens to them and responds
                    appropriately
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_clarity_3"
                      value={hpdcs_clarity_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center font-weight-bold text-muted"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_clarity_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>4. Questioning Skills</label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Asked questions to the whole class and provided helpful
                    prompts with adequate response time
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_questioning_1"
                      value={hpdcs_questioning_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Teacher is aware of different levels of questions (Blooms
                    Taxonomy) easy to difficult. HOTS
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_questioning_2"
                      value={hpdcs_questioning_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Managed wrong responses appropriately for better concept
                    clarity{" "}
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_questioning_3"
                      value={hpdcs_questioning_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center font-weight-bold text-muted"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_questioning_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>5. Learner’s Engagement</label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Teacher Encouraged collaboration and active participation of
                    the learners and manages students well.
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_engagement_1"
                      value={hpdcs_engagement_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Used positive reinforcement and respectful behaviour for
                    student connect to make learning an enjoyable experience
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_engagement_2"
                      value={hpdcs_engagement_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Ensured that class room was neat and orderly and all the
                    required resources were ready to be used and properly
                    displayed before the initiation of class.
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_engagement_3"
                      value={hpdcs_engagement_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center font-weight-bold text-muted"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_engagement_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>6. Assessment </label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Used appropriate assignments, supportive reading documents
                    in terms of relevence quantity and quality.
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_assessment_1"
                      value={hpdcs_assessment_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Used varied and quality tools for checking understanding.
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_assessment_2"
                      value={hpdcs_assessment_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    The teacher provides regular, clear and accurate feedback to
                    the students on their performance
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_assessment_3"
                      value={hpdcs_assessment_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center text-muted font-weight-bold"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_assessment_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>7. Closure</label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Concluded the class with some interesting observations and
                    questions
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_closure_1"
                      value={hpdcs_closure_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Gave assignments/ activities to re-enforce the concepts
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_closure_2"
                      value={hpdcs_closure_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    The lesson is collectively reviewed at the end. With
                    learning outcomes.
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_closure_3"
                      value={hpdcs_closure_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center font-weight-bold text-muted"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_closure_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 mt-2">
            <label>8. Teamwork /Beyond Classroom</label>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="text-center" style={{ width: "70%" }}>
                    Indicators for the parameter
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    Submission of Lesson plans and Question Papers in time or
                    not
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_tw_1"
                      value={hpdcs_tw_1}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Quality of Correction of Note books and Submissions</td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_tw_2"
                      value={hpdcs_tw_2}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Seeks Opportunities to work and collaborate as a way to
                    develop experience and knowledge
                  </td>
                  <td className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_tw_3"
                      value={hpdcs_tw_3}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center font-weight-bold text-muted"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control input-sm"
                        value={hpdcs_tw_total}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td
                    style={{ width: "75%" }}
                    className="font-weight-bold text-muted"
                  >
                    Is the teacher helping in documenting the soft copy of the
                    Lesson plans and the question banks
                  </td>
                  <td style={{ width: "70%" }} className="text-center">
                    <RatingS
                      defaultValue={0}
                      max={4}
                      name="hpdcs_documentation"
                      value={hpdcs_documentation}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td
                    style={{ width: "75%" }}
                    className="font-weight-bold text-muted text-center"
                  >
                    Total Score
                  </td>
                  <td style={{ width: "70%" }} className="text-center">
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={hpdcs_total_score}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12">
            <label>Teacher's Reflection</label>
            <textarea
              cols="30"
              rows="5"
              className="form-control"
              name="hpdcs_teachers_reflection"
              value={hpdcs_teachers_reflection}
              onChange={(e) => onInputChange(e)}
            ></textarea>
          </div>
          <div className="col-md-12">
            <label>Observer's Comment</label>
            <textarea
              cols="30"
              rows="5"
              className="form-control"
              name="hpdcs_comment"
              value={hpdcs_comment}
              onChange={(e) => onInputChange(e)}
            ></textarea>
          </div>
          <div className="col-md-12 mt-3">
            <button
              className="btn btn-sm btn-primary center float-right"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};
