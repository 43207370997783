import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Resignation = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);

  const [list, setList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [desList, setDesList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [lwd, setLwd] = useState("");
  const [deg_name, setDeg_name] = useState("");
  const [deg_id, setDeg_id] = useState("");
  const [erId, setErId] = useState("");
  const [deptId, setDeptId] = useState("");
  const [emp_id, setEmp_id] = useState("");
  const [inputFields, setInputFields] = useState({
    name: "",
    attrition: 1,
    onboarding: "",
    department: "",
    location: "",
    unit_details: "",
    reporting_auth: "",
    working_days: "",
    candidate_pref: "",
    location_type: "",
    salary_range: "",
    additional_Perquisites: "",
    vacancies: "1",
    hiring_process: "",
    q1: "",
    q2: "",
    q3: "",
    exp1: "",
    exp2: "",
    justification1: "",
    justification2: "",
    justification3: "",
    key_deliverables1: "",
    key_deliverables2: "",
    key_deliverables3: "",
    key_deliverables4: "",
    key_deliverables5: "",
    other_requisites1: "",
    other_requisites2: "",
    other_requisites3: "",
    raised_by: user_id,
    approving_auth: "",
    added_by: user_id,
    company_id: Apiconnect.get_company_id(),
  });
  const [manageType, setManageType] = useState([]);
  const { dor, onboarding, department, location, unit_details, reporting_auth, working_days, candidate_pref, location_type, salary_range, additional_Perquisites, vacancies, hiring_process, q1, q2, q3, exp1, exp2, justification1, justification2, justification3, key_deliverables1, key_deliverables2, key_deliverables3, key_deliverables4, key_deliverables5, other_requisites1, other_requisites2, other_requisites3, raised_by, approving_auth, name } = inputFields;
  useEffect(() => {
    getResignationList();
    getDesList();
    getDeptList();
    getEmployeeList();
  }, []);

  const onInputChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const getResignationList = async () => {
    try {
      Apiconnect.postData(`separation/getResignationList/${Apiconnect.get_company_id()}`).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data);
        setList(extract);
      });
    } catch (error) {
      toast.error("Can't Fetch Information. Something Went Wrong");
    }
  };

  const getDesList = () => {
    Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = response.data.data;
      setDesList(_xtract.reverse());
    });
  };

  const getDeptList = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setDeptList(_xtract.reverse());
    });
  };

  const getEmployeeList = () => {
    Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
    });
  };

  const theader = (
    <tr className="text-center">
      <th>#</th>
      <th>Applicant Name</th>
      <th>Reporting Officer</th>
      <th>Resignation Date</th>
      <th>Last Working Date</th>
      <th>Reason</th>
      <th>Status</th>
      <th>Manage</th>
    </tr>
  );

  const handleManage = (st, id) => {
    try {
      Apiconnect.postData(`separation/manageSeparationStatus/${id}/${st}`).then((res) => {
        toast.success("Success");
        getResignationList();
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  const now = new Date();
  const currentDate = moment(now).format("DD/MM/YYYY");

  const handleCreateVacancy = () => {
    inputFields["dor"] = currentDate;
    inputFields["onboarding"] = lwd;
    inputFields["position"] = deg_id;
    inputFields["department"] = deptId;

    if (currentDate && lwd && deg_id && deptId) {
      Apiconnect.postData(`work_force/work_force_requisition_create_by_admin/${Apiconnect.get_company_id()}`, inputFields).then((res) => {
        Apiconnect.postData(`separation/manageResignationStatus/${emp_id}`).then(() => {
          setEmp_id("");
          getResignationList();
        });
        setInputFields({
          name: "",
          dor: "",
          position: "",
          attrition: "",
          onboarding: "",
          location: "",
          unit_details: "",
          reporting_auth: "",
          working_days: "",
          candidate_pref: "",
          location_type: "",
          salary_range: "",
          additional_Perquisites: "",
          vacancies: "",
          hiring_process: "",
          q1: "",
          q2: "",
          q3: "",
          exp1: "",
          exp2: "",
          justification1: "",
          justification2: "",
          justification3: "",
          key_deliverables1: "",
          key_deliverables2: "",
          key_deliverables3: "",
          key_deliverables4: "",
          key_deliverables5: "",
          other_requisites1: "",
          other_requisites2: "",
          other_requisites3: "",
          raised_by: "",
          approving_auth: "",
          added_by: "",
        });
        setModalIsOpen(false);
      });
    } else {
      toast.error("Input Fields Are Empty. All Input Fields Must Be Filled !");
    }
  };

  const handleConfirm = (val, id, text) => {
    confirmAlert({
      // title: "Confirm To Accept Resignation",
      message: `${text}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleManage(val, id),
        },
        {
          label: "No",
          onClick: () => onclose,
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  return (
    <Container heading="Resignation List">
      <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
        <div className="iq-card">
          <div
            className="iq-card-header d-flex justify-content-between model_head"
            style={{
              borderRadius: 5,
              background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)",
            }}
          >
            <div className="iq-header-title">
              <h4 className="card-title text-white">
                Create Vacancy For <span className="font-weight-bold">{deg_name}</span>{" "}
              </h4>
            </div>
            <button className="btn btn-sm btn-secondary" onClick={() => setModalIsOpen(false)}>
              Close
            </button>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="row m-2 p-2">
                  <div className="col-md-4 mb-3">
                    <label>Vacancy Name</label>
                    <input type="text" className="form-control" name="name" placeholder="Enter Vacancy Name" value={name} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Position</label>
                    <input type="text" className="form-control" value={deg_name} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Date of Requisition</label>
                    <input type="text" className="form-control" name="dor" value={currentDate} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Attrition Type</label>
                    <input type="text" value="Replacement" className="form-control" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Expected Date of onboarding</label>
                    <input type="string" className="form-control" value={lwd} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Department</label>
                    {/* <input type='text' className='form-control' placeholder='Department' name='department' value={department} onChange={e => onInputChange(e)} autoComplete='off'/>  */}
                    <select className="form-control" name="department" value={deptId} onChange={(e) => onInputChange(e)}>
                      <option value="" disabled selected>
                        --Select--
                      </option>
                      {deptList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Location (Exact)</label>
                    <input type="text" className="form-control" placeholder="Enter Location" name="location" value={location} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Unit details (Additional in any)</label>
                    <input type="text" className="form-control" placeholder="Enter Unit details" name="unit_details" value={unit_details} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Reporting Authority</label>
                    {/* <input type='text' className='form-control' placeholder='Enter Reporting Authority' name='reporting_auth' value={reporting_auth} onChange={e => onInputChange(e)} autoComplete='off'/> */}
                    <select className="form-control" name="reporting_auth" value={reporting_auth} onChange={(e) => onInputChange(e)}>
                      <option value="" disabled selected>
                        --Select--
                      </option>
                      {empList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.first_name + " " + item.last_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Working days/Time</label>
                    <input type="text" className="form-control" placeholder="Enter Working days/Time" name="working_days" value={working_days} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Candidate Preference</label>
                    <select className="form-control" name="candidate_pref" value={candidate_pref} onChange={(e) => onInputChange(e)}>
                      <option value="">--Select--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Any">Any</option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Local/Outstation</label>
                    <select className="form-control" name="location_type" value={location_type} onChange={(e) => onInputChange(e)}>
                      <option value="">--Select--</option>
                      <option value="Local">Local</option>
                      <option value="Outstation">Outstation</option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Salary Range (Lakhs Per Annum)</label>
                    <input type="number" className="form-control" placeholder="Enter Salary Range" name="salary_range" value={salary_range} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Any Additional Perquisites</label>
                    <input type="text" className="form-control" placeholder="Enter Additional Perquisites" name="additional_Perquisites" value={additional_Perquisites} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Number of Vacancies</label>
                    <input type="number" className="form-control" placeholder="Enter Number of Vacancies" name="vacancies" value="1" autoComplete="off" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Hiring process (incase different from the standard process shared in Recruitment policy)</label>
                    <input type="text" className="form-control" placeholder="Hiring process" name="hiring_process" value={hiring_process} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Qualification-1</label>
                        <input type="text" className="form-control" placeholder="Enter Qualification" name="q1" value={q1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Qualification-2</label>
                        <input type="text" className="form-control" placeholder="Enter Qualification" name="q2" value={q2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Qualification-3</label>
                        <input type="text" className="form-control" placeholder="Enter Qualification" name="q3" value={q3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-6 mb-3">
                        <label>Experience-1</label>
                        <input type="text" className="form-control" placeholder="Enter Experience" name="exp1" value={exp1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Experience-2</label>
                        <input type="text" className="form-control" placeholder="Enter Experience" name="exp2" value={exp2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Justification for adding the position-1</label>
                        <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification1" value={justification1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Justification for adding the position-2 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification2" value={justification2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Justification for adding the position-3 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification3" value={justification3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables1" value={key_deliverables1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-2 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables2" value={key_deliverables2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-3 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables3" value={key_deliverables3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-4 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables4" value={key_deliverables4} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Key Deliverables-5 (If Any)</label>
                        <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables5" value={key_deliverables5} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="row bbox">
                      <div className="col-md-4 mb-3">
                        <label>Any other requisites to be considered while screening </label>
                        <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites1" value={other_requisites1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Any other requisites to be considered while screening-2 </label>
                        <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites2" value={other_requisites2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Any other requisites to be considered while screening-3 </label>
                        <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites3" value={other_requisites3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-md-6 mb-3'>
                                        <label>Raised by</label>
                                        <input type='text' className='form-control' placeholder='Raised by' name='raised_by' value={raised_by} onChange={e => onInputChange(e)} autoComplete='off'/> 
                                    </div> */}
                  <div className="col-md-6 mb-3">
                    <label>Approving Authority</label>
                    <input type="text" className="form-control" placeholder="Approving Authority" name="approving_auth" value={approving_auth} onChange={(e) => onInputChange(e)} autoComplete="off" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-2 p-2 mt-4">
              <div className="col-md-12" align="center">
                <button className="btn btn-sm btn-success" onClick={handleCreateVacancy}>
                  Create
                </button>
                &nbsp;
                <button className="btn btn-sm btn-danger" onClick={() => setModalIsOpen(false)}>
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Card>
        <Table className="col-md-12" trHeader={theader}>
          {list.map((item, index) => {
            let st = <span className="badge badge-pill badge-warning">Pending</span>;
            if (item.er_status == 1) {
              st = <span className="badge badge-pill badge-success">Accepted</span>;
            }
            if (item.er_status == 2) {
              st = <span className="badge badge-pill badge-danger">Rejected</span>;
            }
            if (item.er_status == 3) {
              st = <span className="badge badge-pill badge-info">Under Consideration</span>;
            }
            return (
              <tr key={index} className="text-center">
                <td>{index + 1}</td>
                <td>{item.emp_name}</td>
                <td>{item.reporting_name}</td>
                <td>{item.er_resign_dt}</td>
                <td>{item.er_last_working_day}</td>
                <td>{item.er_reason}</td>
                <td>{st}</td>
                <td>
                  <div className="display-flex justify-content-between">
                    {item.er_status === 0 && (
                      <button
                        className="btn btn-sm btn-info"
                        onClick={() => {
                          handleConfirm(1, item.er_pk_id, "Are you sure to accept resignation ?");
                        }}
                      >
                        Accept
                      </button>
                    )}
                    {item.er_status === 0 && (
                      <button className={`btn btn-sm btn-danger m-2 ${item.er_status == 2 && "disabled"}`} onClick={() => handleConfirm(2, item.er_pk_id, "Are you sure to reject resignation ")}>
                        Reject
                      </button>
                    )}
                    {item.er_status === 0 && (
                      <button className={`btn btn-sm btn-info m-2 ${item.er_status == 3 && "disabled"}`} onClick={() => handleConfirm(3, item.er_pk_id, "Are you sure !")}>
                        Under Consideration
                      </button>
                    )}

                    <button
                      className={`btn btn-sm btn-info m-1 ${Number(item.replacement_st) === 1 && "disabled"} `}
                      onClick={() => {
                        setModalIsOpen(true);
                        setLwd(item.er_last_working_day);
                        setDeg_name(item.deg_name);
                        setDeg_id(item.deg_id);
                        setErId(item.er_pk_id);
                        setDeptId(item.emp_dept_id);
                        setEmp_id(item.er_emp_id);
                      }}
                    >
                      {Number(item.replacement_st) === 1 ? "Replacement Created" : "Create Replacement"}
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </Table>
      </Card>
    </Container>
  );
};

export default Resignation;
