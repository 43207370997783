import React, {useState, useEffect} from 'react'
import "./recruitment.css"
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const customStyles = {
    content: {
       top: '55%',
       left: '55%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "50%" ,
       height: '50%'
    },
};

const Rm_hp_master = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [list, setList] = useState([])
    const [hp_name, setHp_name] = useState("")
    const [edit_id, setEdit_id] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [allchecked, setallchecked] = useState(null);
    const [selected, setSelected] = useState([]);
    
    useEffect(() => {
        get_hp_list()
        get_round_list()
    }, [])

    const handleCreate = (e) => {
        e.preventDefault()
        if (edit_id!=="") {
            if(hp_name!==""){
                setFullScreenLoader(true)
                let data_ins = {
                    hp_company_id:company_id,
                    hp_name:hp_name
                }
                Apiconnect.postData(`hrms_hiring_process/update/${edit_id}`, data_ins).then(res => {
                    alert.success("Updated Successfully")
                    setFullScreenLoader(true)
                    get_hp_list()
                    setHp_name("")
                    setEdit_id("")
                })
            }else{
                alert.error("Please Enter Hiring Process")
            }
        } else {
            if(hp_name!==""){
                setFullScreenLoader(true)
                let data_ins = {
                    hp_company_id:company_id,
                    hp_name:hp_name,
                    hp_created_by:emp_id
                }
                Apiconnect.postData(`hrms_hiring_process/create`, data_ins).then(res => {
                    alert.success("Created Successfully")
                    setFullScreenLoader(true)
                    get_hp_list()
                    setHp_name("")
                })
            }else{
                alert.error("Please Enter Hiring Process")
            }
        }
    }

    const get_hp_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_hiring_process/getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setList((res.data.data))
        })
    }

    const handleEdit = (id) => {
        setEdit_id(id)
        let data = list.find((item) => item.hp_pk_id == id)
        console.log(data)
        setHp_name(data.hp_name)

    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_hiring_process/delete/${id}`).then(res => {
            setFullScreenLoader(false)
            get_hp_list()
        })
    }

    const [round_list, setRound_list] = useState([])
    const [hp_id, setHp_id] = useState("")
    const [name, setName] = useState("")

    const get_round_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_rounds/getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setRound_list(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    const handleAssign = () => {
        let ins_ob = {
            hhr_company_id:company_id,
            hhr_hp_id:hp_id,
            hhr_round_ids:selected,
            hhr_added_by:emp_id
        }
        if(Object.keys(selected).length > 0){
            setFullScreenLoader(true)
            Apiconnect.postData(`hrms_hpall_assign_mst/hp_rounds_create`, ins_ob).then(res => {
                setFullScreenLoader(false)
                alert.success("Assigned Successfully")
                get_hp_list()
                setSelected([])
                setModalIsOpen(false)
            }).catch(err => {
                alert.error("Error Occured.. Please Try Again")
                setSelected([])
                setModalIsOpen(false)
                setFullScreenLoader(false)
            })
        }else{
            alert.error("Please Select Assessments")
        }
    }

    const onInputChange = (e) => {
        setallchecked(null);
        if (e.target.checked === true) {
          setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
          });
        } else {
          setSelected({ ...selected, [e.currentTarget.name]: 0 });
        }
      };
    const sellectAll = (e) => {
    if (e.target.checked === true) {
        setallchecked("checked");
        let ob = {};
        round_list.map((val, key) => {
        ob[val.r_pk_id] = val.r_pk_id;
        });
        setSelected(ob);
    } else {
        setallchecked(null);
        setSelected([]);
    }
    };

    const [assigned_rounds, setAssigned_rounds] = useState([])
    const get_assigned_round_list = (id) => {
        const data_ins = {hhr_hp_id: id}
        Apiconnect.postData(`hrms_hiring_process/get_assigned_rounds/${company_id}`, data_ins).then(res => {
            setAssigned_rounds(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    assigned_rounds.length > 0 && console.log(assigned_rounds)

    const handleRemove = (id) => {
        Apiconnect.postData(`hrms_hiring_process/remove_assigned_round/${id}`).then(res => {
            setModalIsOpen(false)
            get_hp_list()
        })
    }


  return (
    <div className='content-page main_bg'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null} 
        <div className='container-fluid'>
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
            <div className='iq-card'>
                <div className='iq-card-header card_head'>
                    <h5 className='text-white'>Assign Rounds To {name}</h5>
                </div>
                <div className='iq-card-body '>
                    <div className='row mt-3'>
                        <div className='col-md-12'>
                            

                                <button className='btn btn-sm btn-primary float-right' onClick={handleAssign}>Assign</button>
                          
                            
                        </div>
                        <div className='col-md-12 mt-3'>
                            <div className='table-responsive'>
                                <table className='table table-bordered normal_shadow'>
                                    
                                    <thead className='thead-light'>
                                        <tr>
                                            <th>
                                                {/* <input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => sellectAll(e)}/> */}
                                            </th>
                                            <th style={{width:"10%"}} className='text-center'>#</th>
                                            <th className='text-center'>Name</th>
                                            <th className='text-center'>Assigned Assessments</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            round_list.length > 0 ? (
                                                round_list.map((item, index) => {

                                                    let ck = Object.keys(selected).find(function (element) {
                                                        return selected[element] == item.r_pk_id;
                                                        });
                            
                                                        var ck1 = "";
                                                        if (ck !== undefined) {
                                                        ck1 = true;
                                                        }
                                                    
                                                    
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                {item.assessment_names.length > 0 && <input
                                                                    type="checkbox"
                                                                    name={item.r_pk_id}
                                                                    value={item.r_pk_id}
                                                                    checked={ck1}
                                                                    onChange={(e) => onInputChange(e)}
                                                                    />}
                                                            </td>
                                                            <td className='text-center'>{index+1}</td>
                                                            <td>{item.r_name}</td>
                                                            <td className='text-center'>
                                                                {
                                                                    item.assessment_names.length > 0 ? (
                                                                        item.assessment_names.map((val, key) => {
                                                                            return(
                                                                                <span key={key} className='badge badge-success badge-sm mr-2'>{val}</span>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <div>
                                                                            <span className='badge badge-sm badge-warning'>Assessments Not Assigned Yet</span> <br />
                                                                            <Link to={`/hrms_rounds`}><button className='btn btn-sm btn-info'>Assign Assessments</button></Link>
                                                                        </div>
                                                                        
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <th colSpan={4} className='center text-muted'>----  No Records Found  ----</th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-12 mt-3'>
                            <div className='table-responsive'>
                                <table    className='table table-bordered'>
                                      <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Round Name</th>
                                                <th>Action</th>
                                            </tr>
                                      </thead> 
                                      <tbody>
                                        {assigned_rounds.map((item, index) => {
                                            return(
                                                <tr>
                                                    <td>{index + 1 }</td>
                                                    <td>{item.round_name}</td>
                                                    <td>
                                                        <button className='btn btn-sm btn-danger' onClick={() => handleRemove(item.hhr_pk_id)}>Remove</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
            <div className='iq-card main_container'>
                <div className='iq-card-header card_head'>
                    <h4 className='text-white'>Hiring Process</h4>
                </div>
                <div className='iq-card-body'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <input type="text" className='form-control' placeholder='Enter Hiring Process' name='hp_name' value={hp_name} onChange={e => setHp_name(e.target.value)} />
                        </div>
                        <div className='col-md-4'>
                            <button className='btn btn-sm btn-primary mr-2' onClick={(e) => handleCreate(e)}>{edit_id ? "Update" : "Create"}</button>
                            {edit_id && <button className='btn btn-secondary btn-sm' onClick={() => {setEdit_id(""); setHp_name("")}}>Clear</button>}
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-md-12 mt-3'>
                            <div table-responsive>
                            <table className='table table-bordered normal_shadow'>
                                
                                <thead className='thead-light'>
                                    <tr>
                                        <th style={{width:"10%"}} className='text-center'>#</th>
                                        <th className='text-center'>Hiring Process Name</th>
                                        <th className='text-center'>Assigned Rounds</th>
                                        <th style={{width: "30%"}} className='text-center'>Manage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length > 0 ? (
                                            list.map((item, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td className='text-center'>{index+1}</td>
                                                        <td>{item.hp_name}</td>
                                                        <td className='text-center'>
                                                        {
                                                            item.round_info.length > 0 ? (
                                                                item.round_info.map((val, key) => {
                                                                    return(
                                                                        <span className='badge badge-success mr-2' key={key}>
                                                                            {val.round_name} <br />
                                                                        </span>
                                                                    )
                                                                })
                                                            ) : (
                                                                <span className='badge badge-warning badge small'>
                                                                    Rounds Not Assigned Yet
                                                                </span>
                                                            )
                                                        }
                                                        </td>
                                                        <td className='text-center'>
                                                            <button className='btn btn-sm btn-info mr-2' onClick={() => {
                                                                setName(item.hp_name)
                                                                setHp_id(item.hp_pk_id)
                                                                setModalIsOpen(true)
                                                                get_assigned_round_list(item.hp_pk_id)
                                                            }}>Assign Round</button>
                                                            <button className='btn btn-sm btn-info mr-2' onClick={() => handleEdit(item.hp_pk_id)}>Edit</button>
                                                            {/* <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.hp_pk_id)}>Delete</button> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <th colSpan={3} className='center text-muted'>----  No Records Found  ----</th>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Rm_hp_master



// Again reassign rounds/ assessments if not assigned to any 