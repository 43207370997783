import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";

const RetireEmps = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);

  useEffect(() => {
    retireEmpListbyEmpId();
  }, []);

  const [retireList, setRetireList] = useState([]);
  const retireEmpListbyEmpId = () => {
    try {
      Apiconnect.postData(`separation/allRetireEmployees/${user_id}/${Apiconnect.get_company_id()}`).then((res) => {
        const result = Apiconnect.decrypt_obj(res.data.data);
        setRetireList(result);
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Employee Name</th>
      <th>Employee Code</th>
      <th>Employee Email</th>
      <th>Employee Designation</th>
    </tr>
  );

  return (
    <Container heading="Retire Employees List">
      <Card>
        <Table className="col-md-12" trHeader={trHeader}>
          {retireList.map((item, index) => {
            return (
              <tr className="text-center" key={index}>
                <td>{index + 1}</td>
                <td>{item.first_name + " " + item.middle_name + " " + item.last_name}</td>
                <td>{item.emp_code}</td>
                <td>{item.email}</td>
                <td>{item.designation}</td>
              </tr>
            );
          })}
        </Table>
      </Card>
    </Container>
  );
};

export default RetireEmps;
