import React, { useEffect, useState } from "react";
import { pageStyles } from "../../../globalStyles";
import Apiconnect from "../../../services/Apiconnect";
import { useAlert } from "react-alert";
import toast, { Toaster } from "react-hot-toast";
import FullScreenLoading from "react-fullscreen-loading";
import moment from "moment";
import Modal from 'react-modal';
import { FcApproval } from "react-icons/fc";
import { FcAbout } from "react-icons/fc";

const customStyles = {
  content: {
    top: '55%',
    left: '55%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: '50%',

  },
};

const Manage_grievance_status = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const [grievanceList, setGrievanceList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [grl_message, setGrl_message] = useState("")
  const [grl_raise_id, setGrl_raise_id] = useState("")
  const [cat, setCat] = useState("")
  const [sub, setSub] = useState("")
  const [desc, setDesc] = useState("")
  const [name, setName] = useState("")
  const [logs, setLogs] = useState([])
  const [status, setStatus] = useState("Resolved")

  useEffect(() => {
    manageGrievanceList();
  }, []);

  const manageGrievanceList = async () => {
    try {
      const response = await Apiconnect.postData(`grievance/manageGrievanceStatusByEmpId/${emp_id}`);
      const resultData = response.data.data;
      setGrievanceList(resultData);
    } catch (error) {
      console.log(error.message);
    }
  };


  const handleResolve = async (id) => {
    const dataIns = {
      grl_company_id: Apiconnect.get_company_id(),
      grl_raise_id: grl_raise_id,
      grl_message: grl_message,
      grl_emp_id: emp_id,
      gr_final_st: status
    }
    if (grl_raise_id !== "" && grl_message !== "") {
      Apiconnect.postData(`grievance/resolveGrievance/${grl_raise_id}`, dataIns).then(res => {
        manageGrievanceList();
        toast.success("Resolved")
        setModalIsOpen(false)
      }).catch(err => {
        toast.error("Something went wrong")
      })
    }
    console.log(dataIns)

  };

  const getLogs = (id) => {
    Apiconnect.postData(`grievance/getGrievanceLogs/${id}`).then(res => {
      setLogs(res.data.data)
    })
  }


console.log(grievanceList)


  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
        <div className='iq-card'>
          <div className='iq-card-header card_head'>
            <h5 className='text-white'>Resolve Grievance - <span className="text-white">{name}</span></h5>
          </div>
          <div className='iq-card-body '>
            <div className='row mt-3'>
              <div className="col-md-12 text-center">
                <span className="font-weight-bold text-muted">{cat}</span>
              </div>

              <div className="col-md-12 mt-2">
                <span className="font-weight-bold text-muted">{sub}</span>
              </div>
              <div className="col-md-12 mt-2">
                <textarea className="form-control" value={desc} rows="4" />
              </div>

              <div className="col-md-12 mt-2">
                <textarea type="text" className="form-control" name="grl_message" placeholder="Resolve Message" value={grl_message} onChange={(e) => setGrl_message(e.target.value)} />
              </div>
              <div className="col-md-12 mt-2">
                <select className="form-control text-center" name="status" value={status} onChange={e => setStatus(e.target.value)}>
                  <option value="On Hold">On Hold</option>
                  <option value="In Progess">In Progess</option>
                  <option value="Resolved">Resolved</option>
                </select>
              </div>
              <div className="col-md-12 mt-3 text-center">
                <button className="btn btn-sm btn-info" onClick={handleResolve}>Resolve</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid">
        <div className="iq-card" style={pageStyles.main_container}>
          <div className="iq-card-header" style={pageStyles.card_head}>
            <h4 className="text-white" style={pageStyles.h4_bg}>
              Resolve Grievance
            </h4>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12 table-responsive">
                <table className="table table-sm table-bordered" style={pageStyles.main_container}>
                  <thead className="thead-light">
                    <tr className="text-center">
                      <th>#</th>
                      <th>Category</th>
                      <th>Raised By</th>
                      <th>Employee Code</th>
                      <th>Subject</th>
                      {/* <th>Description</th> */}
                      <th>Raised On</th>
                      <th>Status</th>
                      <th>Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grievanceList.map((item, index) => {
                      const dt = moment(item.gr_createdOn).format("DD/MM/YYYY h:mm a");
                      const updated = moment(item.gr_updatedOn).format("DD/MM/YYYY h:mm a")
                      let st = null;
                      if (item.gr_status === 1) {
                        st = <span className="badge badge-warning">Active</span>;
                      } else if (item.gr_status === 2) {
                        st = <FcApproval />
                      }
                      let resolved = <span className="badge badge-light " style={pageStyles.normal_shadow}>Resolved on {updated}</span>;

                      return (
                        <tr className="text-center" key={index}>
                          <td>{index + 1}</td>
                          <td>{item.grievanceCategoryName}</td>
                          <td>{item.emp_name}</td>
                          <td>{item.emp_code}</td>
                          <td>{item.gr_subject}</td>
                          {/* <td>{item.gr_desc}</td> */}
                          <td>{dt}</td>
                          <td>{st}</td>
                          <td className="text-center">
                            {
                              item.gr_status === 2 ? resolved : <button className="btn btn-sm btn-info" onClick={() => {
                                setModalIsOpen(true)
                                setGrl_raise_id(item.gr_pk_id)
                                setCat(item.grievanceCategoryName)
                                setSub(item.gr_subject)
                                setDesc(item.gr_desc)
                                setName(item.emp_name)
                              }}>Resolve</button>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manage_grievance_status;
