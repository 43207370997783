import React, {useState, useEffect} from 'react'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Apiconnect from '../../services/Apiconnect'
import Modal from 'react-modal';
import ReactDOM from 'react-dom';

const Wings_ClassAssignment = () => {
    /* ---Modal--- */
    const customStyles = {
        content: {
            top: '55%',
            left: '55%',
            right: 'auto',
            bottom: 'auto', 
            transform: 'translate(-50%, -50%)', 
        },
    }; 
        
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false); 
    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id();
    const alert = useAlert(); 

    useEffect(() => { getlist_class(); getLists_wings(); getClassLists()}, []);

    const [classLists, setClassLists] = useState([])

    const [lists_class, setlists_class] = useState([]);
    const [lists_wings, setlists_wings] = useState([]);
    const [ListSelected, setListSelected] = useState([]);


    const getClassLists = () => {
        setFullScreenLoader(true);
        Apiconnect.postData("manage_wings/get_wingsclass_lists", {company_id:company_id}).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            setClassLists(_xtract);
            let ob = {}
            let filteredData = _xtract.filter((item) => item.wingclass_pk_id !== null && item.wingclass_wing_id !== null)
            filteredData.map((val, key) => {
                    ob[val.class_pk_id]=String(val.wingclass_wing_id)
            })
            setListSelected(ob)
            setFullScreenLoader(false);
        });
    }
    console.log(ListSelected)


    const getlist_class = () => { 
        setFullScreenLoader(true);
        Apiconnect.postData("manage_class/get_all_class_lists", {company_id:company_id}).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            setlists_class(_xtract);
            setFullScreenLoader(false);
        });
    };

    const getLists_wings = () => { 
        setFullScreenLoader(true);
        Apiconnect.postData("manage_wings/get_wings_lists", {company_id:company_id}).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);  
            setlists_wings(_xtract);  
            setFullScreenLoader(false);
            setModalIsOpen(false); 
        });
    };
    

    const onInputChangeSelect = (event) => { 
        // setListSelected(event.currentTarget.value);
        setListSelected({
            ...ListSelected,
            [event.currentTarget.name]: event.currentTarget.value,
        });
    }
    
    const fun_assignment = () => { 
        let data_insup1={company_id:company_id,ListSelected:ListSelected};
        setFullScreenLoader(true);
        Apiconnect.postData("manage_wings/wings_class_saveupdate", data_insup1).then((response) => { 
            setFullScreenLoader(false); 
        });
    }

    console.log(ListSelected)

 

  return (
    <>
        {fullScreenLoader ? (
            <FullScreenLoading loading loaderColor="#3498db" />
        ) : 
            null 
        }
        <div id="content-page" className="content-page">
             
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-md-12">
                        {/* <form onSubmit={e => onSubmit(e)}> */}
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Manage Wing Class Assignment</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button type='button' className='btn btn-success pull-right' onClick={() => fun_assignment(0)}>Assign</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                        <thead className="">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Class Name</th> 
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {   
                                            classLists.length>0 ?  ( 
                                                lists_class.map((valClass, key) => {  
                                                     
                                                    return(
                                                        <tr key={key}>
                                                            <td scope="row">{key + 1}</td> 
                                                            <td>{valClass.class_name}</td> 
                                                            <td>
                                                                <select className='form-control' name={valClass.class_pk_id} onChange={(e) => onInputChangeSelect(e)}>
                                                                    <option value=''>--Select--</option>
                                                                    {
                                                                        lists_wings.map((valwing, key) => {  
                                                                            return(
                                                                                <option key={key} value={valwing.wing_pk_id}> {valwing.wing_name} </option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </td> 
                                                        </tr> 
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <th colSpan={3} style={{textAlign:'center'}}> No Data Found.. </th>
                                                </tr>
                                            ) 
                                        } 
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Wings_ClassAssignment