import React, { useState, useEffect } from "react";
import Apiconnect from "../../../services/Apiconnect.js";
import { pageStyles } from "../../../globalStyles.js";
import FullScreenLoading from "react-fullscreen-loading";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import useFetchApi from "../../../hooks/useFetchApi.js";

const Verified_applicants = () => {
  const [allchecked, setallchecked] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  // const { data, message, error, loading } = useFetchApi(Apiconnect.postData, ``, null);
  
  useEffect(() => {
    getVerfiedApplicantList()
  }, [])

  const getVerfiedApplicantList = () => {
    Apiconnect.postData(`selected_applicants/verifiedApplicantList/${Apiconnect.get_company_id()}`).then(res => {
      const dataList = Apiconnect.decrypt_obj(res.data.data)
      setList(dataList)
    })
  }

  const onInputChange = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });
    }
  };

  const allemps = (e) => {
    if (e.target.checked === true) {
      // console.log(e.target.checked);
      setallchecked("checked");
      let ob = {};
      list.map((val, key) => {
        ob[val.am_pk_id] = val.am_pk_id;
      });
      setSelectedEmp(ob);
      // setSelectedCountEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
      // setSelectedCountEmp([]);
    }
  };

  const requestForApproval = async () => {
    try {
      if (Object.keys(selectedEmp).length === 0) {
        alert.error("Please Select");
      } else {
        const dataIns = {
          applicants: selectedEmp,
          fa_company_id: Apiconnect.get_company_id(),
        };
        const postData = await Apiconnect.postData(`selected_applicants/approvalRequestCreate`, dataIns);
        alert.success("Success");
      }
    } catch (error) {
      alert.error("error");
      console.log(error.message);
    }
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <div className="container-fluid">
        {loading ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
        <div className="row">
          <div className="col-md-12">
            <div className="iq-card" style={pageStyles.main_container}>
              <div className="iq-card-header" style={pageStyles.card_head}>
                <h5 className="text-white" style={pageStyles.h4_bg}>
                  Verified Applicants
                </h5>
                <Link className="btn btn-sm btn-info float-right" to="/Rm_selected_applicants">
                  Back
                </Link>
              </div>
              <div className="iq-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-info btn-sm float-right m-2" onClick={requestForApproval}>
                      Request For Approval
                    </button>
                  </div>
                  <div className="col-md-12">
                    {list && (
                      <table className="table table-bordered" style={pageStyles.normal_shadow}>
                        <thead className="thead-light">
                          <tr>
                            <th>{/* <input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => allemps(e)} /> */}</th>
                            <th className="text-center">#</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Applied For</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((item, index) => {
                            let ck = Object.keys(selectedEmp).find(function (element) {
                              return selectedEmp[element] == item.am_pk_id;
                            });

                            let ck1 = "";
                            if (ck !== undefined) {
                              ck1 = true;
                            }
                            let st = null;
                            if (item.am_final_approval === 0) {
                              st = <span className="badge badge-danger">Not Approved Yet</span>;
                            }
                            if (item.am_final_approval === 1) {
                              st = <span className="badge badge-success">Approved</span>;
                            }
                            return (
                              <tr className={`text-center ${item.am_final_approval === 1 && "table-success"}`} key={index}>
                                <th>{item.am_final_approval === 0 ? <input type="checkbox" name={item.am_pk_id} value={item.am_pk_id} checked={ck1} onChange={(e) => onInputChange(e)} /> : <i className="fa fa-check text-success" aria-hidden="true"></i>}</th>
                                <td>{index + 1}</td>
                                <td>
                                  {item.am_first_name} {item.am_last_name}
                                </td>
                                <td>{item.am_position}</td>
                                <td>{item.am_email}</td>
                                <td>{st}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verified_applicants;
