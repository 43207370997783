import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/admin.css';
// import Chart from "react-google-charts";
// import Calendar from "./Calendar.js";

function Dashboard_superadmin() {

   // getDeparmentCount(); getEmployeeCount();

   useEffect(() => {  getCompanyCount();getLeaveList();}, []);
   const [emp_count, setEmpCount] = useState([]);
   const [allcompany, setCompanyCount] = useState([]);

   const getCompanyCount = () => {
      Apiconnect.getData(`company/getallcompanydata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         //  console.log(_xtract);
         setCompanyCount(_xtract.reverse());
      });
   }

   // const getEmployeeCount = () => {
   //    Apiconnect.getData(`employee/getemployeecount/${Apiconnect.get_company_id()}`).then((response) => {
   //       let _xtract = Apiconnect.decrypt_obj(response.data.data);
   //       setEmpCount(_xtract.total);
   //    });
   // }

   // const [count_cat, setCatCount] = useState([]);
   // const getDeparmentCount = () => {
   //    Apiconnect.getData(`company_category/getcount/${Apiconnect.get_company_id()}`).then((response) => {
   //       let _xtract = Apiconnect.decrypt_obj(response.data.data);
   //       setCatCount(_xtract.total);
   //    });
   // }

    const [leaveList, setleaveList] = useState([]);

   const getLeaveList = () => {
      Apiconnect.getData(`employee_leave/getallbyapprove/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setleaveList(_xtract.reverse());
      });
   }

   const changeCompany = (id) => {
      const local_user = JSON.parse(localStorage.getItem('user'));
      var company_id1 = Apiconnect.encrypt(id+'');
      local_user.company_id = company_id1;
      var comp_name = allcompany.find(Element => Element.id == id);
      if(id == Apiconnect.get_company_id()){
         local_user.company_id = '';
         local_user.company_name = '';
      } else {
         local_user.company_name = comp_name.company_name;
      }
      localStorage.removeItem("user");    
      localStorage.setItem("user", JSON.stringify(local_user));
      window.location.reload();
      // console.log(local_user);
   }


   const Capitalize = (str) => { 
      return str.charAt(0).toUpperCase() + str.slice(1);
   }

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">



               <div className="row row-eq-height">
                  {/* <div className="col-lg-2 col-md-12"> */}
                  <div className="col-lg-12 col-md-12" style={{display:'inline-flex'}}>
                     {
                        allcompany.map((val,key)=>{

                           var br = '';
                           if(val.id == Apiconnect.get_company_id()){
                              var br = 'compbr';
                           }
                           return(
                              <div key={key} className="col-lg-3 col-md-3 " style={{paddingLeft:'0px',cursor: 'pointer'}} onClick={e=>changeCompany(val.id)}>
                              <div className="row">
                              <div className="col-md-12">
                                 <div className={br +" iq-card compbtn"}>
                                    <div className="iq-card-body">
                                       <div className="row">
                                          <div className="col-lg-12 mb-2 d-flex justify-content-between">
                                              <span className="h5 text-dark mb-0 d-inline-block w-100">{val.company_name}</span>
                                          
                                          </div>
                                          <div className="col-lg-12 mt-3">
                                            <table>
                                               <tr><td>Admin </td><td style={{width:'30px',textAlign:'center'}}> : </td><td> {val.admin_cnt}</td></tr>
                                               <tr><td>Employee</td><td style={{width:'30px',textAlign:'center'}}> : </td><td> {val.emp_cnt}</td></tr>
                                               <tr><td>Department </td><td style={{width:'30px',textAlign:'center'}}> : </td><td> {val.dept_cnt}</td></tr>
                                            </table>
                                          </div>
                                       </div>
                                       <div className="row" style={{marginTop:'10px'}}>  
                                       <div className="col-md-3">
                                       </div>
                                       <div className="col-md-8">
                                       <button className="btn btn-primary btn-sm">Reload Data</button>
                                       </div>
                                       <div className="col-md-2">
                                       </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>   
                           </div>
                           )
                        })
                     }

                  </div>
 

                  <div class="col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Leaves</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table className="table table-boreded" style={{fontSize:14}}>
                                 <thead>
                                    <tr>
                                       <th>#</th>
                                       <th>Name</th>
                                       <th>Company</th>
                                       <th>Department</th>
                                       <th>Applied On</th>
                                       <th>Leave Type</th>
                                       <th>Category</th>
                                       <th>Reason</th>
                                       <th>Days</th>
                                       <th>Date</th>
                                       <th>Approval Authority </th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {
                                       leaveList.length>0 ?(  
                                       leaveList.map((val, key) => {

                                       if (val.status == 0) {
                                          var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                       }
                                       if (val.status == 1) {
                                          var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                       }
                                       if (val.status == 2) {
                                          var status = <div className=" badge badge-pill badge-danger">Rejected</div>;
                                       }
                                       // console.log(val);
                                       return (
                                          <tr key={key}>
                                             <th scope="row">{key + 1}</th>
                                             <td>{val.emp_name+' '+val.middle_name+' '+val.last_name}</td>
                                             <td>{val.company_name}</td>
                                             <td>{val.department}</td>
                                             <td>{val.applied_on}</td>
                                             <td>{Capitalize(val.typ)}</td>
                                             <td>{val.leave_cat_code}</td>
                                             <td>{val.reason}</td>
                                             <td>{val.leave_count}</td>
                                             <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                                             <td>{val.approve_emp_name}</td>
                                             <td>  {status} </td>
                                          </tr>
                                       ); 
                                       })
                                       ):(
                                          <tr>
                                             <th colSpan={11} style={{textAlign:'center'}}> No Data Found.. </th>
                                          </tr>
                                       )
                                    }
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>

 
               </div> 
            </div>
         </div> 
      </>
   )
}

export default Dashboard_superadmin
