import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';

const Hiring_rounds = () => {

    const alert = useAlert(); 
    const company_id = Apiconnect.get_company_id()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [staff_type, setStaff_type] = useState([]);
    const [inputFields, setInputFields] = useState({sr_company_id: company_id, sr_emp_des_id:"", sr_round_name:"",sr_round_type:""})
    const [editId, setEditId] = useState("")
    const [round_list, setRound_list] = useState([])
    const [desList, setDesList] = useState([]);

    let {sr_emp_des_id, sr_round_name, sr_round_type} = inputFields

    useEffect(() => {getStaffType(); get_round_list(); getDesignation();}, [])

    const get_round_list = () => {
        setFullScreenLoader(false)
        Apiconnect.postData(`hiring_application/hiring_rounds_getall/${company_id}`).then((res) => {
            setFullScreenLoader(false)
            setRound_list(res.data.data)
        })
    }

    const onInputChange = (e) => {
        setInputFields({...inputFields, [e.target.name]:e.target.value})
    }

    const getStaffType = () => {
        Apiconnect.getData(`company_category/getall_sub_dept/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setStaff_type(_xtract.reverse());
        });
    };

   const getDesignation = () => {
      Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = response.data.data;
         setDesList(_xtract.reverse());      
      });
   };

    const handleSubmit = () => {
        if(editId !== ""){
            if(sr_emp_des_id !== "" && sr_round_name !== "" && sr_round_type !==""){
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_rounds_update/${editId}`, inputFields).then((res) => {
                    setFullScreenLoader(false)
                    get_round_list()
                    setEditId("")
                    setInputFields({sr_company_id: company_id, sr_emp_des_id:"", sr_round_name:"",sr_round_type:""})
                    alert.success("Updated")
                }).catch(err => {
                    console.log(err)
                    alert.error("Errot")
                    setFullScreenLoader(false)
                })
            }
        }else{
            if(sr_emp_des_id !== "" && sr_round_name !== "" && sr_round_type !==""){
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_rounds_create/${company_id}`, inputFields).then((res) => {
                    setFullScreenLoader(false)
                    get_round_list()
                    setInputFields({sr_company_id: company_id, sr_emp_des_id:"", sr_round_name:"",sr_round_type:""})
                    alert.success("Created")
                }).catch(err => {
                    setFullScreenLoader(false)
                    setInputFields({sr_company_id: company_id, sr_emp_des_id:"", sr_round_name:"",sr_round_type:""})
                    alert.error("Error")
                    console.log(err)
                })
            }
        }
    }

    const handleUpdate = (id) => {
        setEditId(id)
        let filteredData = round_list.find((item) => item.id === id)
        setInputFields({sr_emp_des_id:filteredData.sr_emp_des_id, sr_round_name:filteredData.sr_round_name, sr_round_type:filteredData.sr_round_type})
    }

    const handleDelete = (id) => {
        Apiconnect.postData(`hiring_application/hiring_rounds_delete/${id}`).then((res) => {
            setFullScreenLoader(false)
            get_round_list()
            alert.success("Deleted Successfully")
        })
    }

    console.log(staff_type)
    console.log(inputFields)
    console.log(round_list)

  return (
    <>
        <div className='content-page'>
            {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className="iq-card-header d-flex justify-content-between model_head">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Hiring Rounds</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row'>
                                    <div className='col-md-3 mb-2'>
                                        <input type="text" className='form-control' placeholder='Round Name' name='sr_round_name' value={sr_round_name} onChange={(e) => onInputChange(e)} />
                                    </div>
                                    <div className='col-md-3 mb-2'>
                                        <input type="number" className='form-control' placeholder='Round Rank' name='sr_round_type' value={sr_round_type} onChange={(e) => onInputChange(e)} />
                                    </div>
                                    <div className='col-md-3 mb-2'>
                                        <select className='form-control' name='sr_emp_des_id' value={sr_emp_des_id} onChange={(e) => onInputChange(e)}>
                                            <option value="" disabled>---Select Employment Position---</option>
                                            {
                                                desList.map((item, index) => {
                                                    return(
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-3 mb-2'>
                                        <button className='btn btn-sm btn-primary float-right' onClick={handleSubmit}>{editId ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                            <thead className="">
                                                <tr className="clr">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Position Type</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    round_list.length > 0 ?
                                                    (
                                                        round_list.map((val, key) => {
                                                            return(
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>{val.sr_round_name}</td>
                                                                    <td>{val.des_name}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm btn-info mr-2 mb-2' onClick={() =>handleUpdate(val.id)}>Edit</button>
                                                                        <button className='btn btn-sm btn-danger mr-2 mb-2' onClick = {() => handleDelete(val.id)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_rounds