import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from 'react-alert'
import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min';
import './recruitment_module/recruitment.css'

const View_panel = () => {

    let {id} = useParams()

    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [name, setName] = useState("")

    const [list, setList] = useState([])

    useEffect(() => {get_panel_list();get_panel_name()}, [])

    const get_panel_name = () => {
        Apiconnect.postData(`hiring_application/get_hiring_panel_name/${id}`).then(res => {
            setName(res.data.data[0].name)
        })
    }


    const get_panel_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_panel_emp_getall/${company_id}/${id}`).then(res => {
            setFullScreenLoader(false)
            setList(res.data.data)
        })
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_panel_emp_delete/${id}`).then(res => {
            setFullScreenLoader(false)
            get_panel_list()
            alert.success("Panel Member Removed")

        })
    }

    console.log(list)

  return (
    <>
        <div className='content-page main_bg'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}   
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card '>
                            <div className='iq-card-header card_head'>
                                <div className='iq-header-title' style={{float: "left"}}>
                                    <h4 className='card-title text-white'>Panel Composition For <span className='font-weight-bold'>{name}</span></h4>
                                </div>
                                <Link to={`/hrms_hiring_panel`}><button className='btn btn-sm btn-info'>Back</button></Link>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row mt-3'>
                                    <div className='col-md-12'>
                                        <table className='table table-bordered normal_shadowr'>
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Name</th>
                                                    <th className='text-center'>Designation</th>
                                                    <th className='text-center'>Panel For</th>
                                                    {/* <th>Manage</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.length > 0 ?
                                                    (
                                                        list.map((item, index) => {
                                                            return(
                                                                <tr key={index}>
                                                                    <td className='text-center'>{index + 1}</td>
                                                                    <td>{item.panel_member_name}</td>
                                                                    <td>{item.designation}</td>
                                                                    <td className='text-center'>{item.panel_name}</td>
                                                                    {/* <td>
                                                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.hppm_pk_id)}>Delete</button>
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={4}>No Data Found...</th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default View_panel