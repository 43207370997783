import React, {useState, useEffect} from "react";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }
}

const Work_force_planning = () => {

  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ts_ratio, setTs_ratio] = useState([])

  useEffect(() => {get_ratio()}, [])
  
  
  const get_ratio = () => {
    let data_ins = new FormData()
    data_ins.append('company_id', company_id)
    Apiconnect.postData(`admin_reports/get_classwise_teacherwise_ratio_ajax`, data_ins).then(res => {
        let data = Apiconnect.decrypt_obj(res.data.data)
        console.log(data)
        let filtered_data = data.filter((item) => item.wing_pk_id !== null)
        setTs_ratio(filtered_data)
    })
  }

let sortedData = ts_ratio.sort((a,b) => (a.wing_pk_id < b.wing_pk_id ? -1 : 1))



let main_data = []
const add_rowspan = (data) => {
    let rowspanMap = {}
    let totalStudents = {}
    let totalTeachers = {}


    /* total students */
    data.forEach((item) => {
    const {wing_pk_id, cal_total_student} = item
    if(wing_pk_id in totalStudents){
        totalStudents[wing_pk_id] += cal_total_student
    }else{
        totalStudents[wing_pk_id] = cal_total_student
    }
   })
   data.forEach((item) => {
    let totalStudent = totalStudents[item.wing_pk_id]
    item.totalStudent = totalStudent
   })
    /* total students */
    data.forEach((item) => {
    const {wing_pk_id, cal_total_teacher} = item
    if(wing_pk_id in totalTeachers){
        totalTeachers[wing_pk_id] += cal_total_teacher
    }else{
        totalTeachers[wing_pk_id] = cal_total_teacher
    }
   })
   data.forEach((item) => {
    let totalTeacher = totalTeachers[item.wing_pk_id]
    item.totalTeacher = totalTeacher
   })

   /* Rowspan */
   data.forEach((item) => {
        if (rowspanMap[item.wing_pk_id] === undefined) {
          rowspanMap[item.wing_pk_id] = 1;
        } else {
          rowspanMap[item.wing_pk_id]++;
        }
      });

    data.forEach((item) => {
        let rowspan = rowspanMap[item.wing_pk_id]
        if(rowspan > 1){
            item.rowSpan = rowspan
            rowspanMap[item.wing_pk_id] = 0
        }else{
            item.rowSpan = 1
        }
        main_data.push(item)
      });
}
add_rowspan(sortedData)

let totalStudents = main_data.map((item) => item.cal_total_student)
let totalStudentsCount =totalStudents.length > 0 && totalStudents.reduce((a, b) => a+b)

let totalTeachers = main_data.map((item) => item.cal_total_teacher)
let totalTeacherCount = totalTeachers.length > 0 && totalTeachers.reduce((a,b) => a+b)

let totalSections = main_data.map((item) => item.cal_total_section)
let totalSectionCount = totalSections.length > 0 && totalSections.reduce((a,b) => a+b)

console.log(ts_ratio)





  return (
    <div className="content-page" style={page_styles.main_container_bg}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="iq-card" style={page_styles.main_container_shadows}>
                        <div className="iq-card-header" style={page_styles.card_head}>
                            <h4 className="text-white">Work Force Planning</h4>
                        </div>
                        <div className="iq-card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {main_data.length > 0 && <div className="table-responsive text-nowrap">
                                        <table className="table table-sm table-bordered normal_shadow min" style={{fontSize: "12px"}}>
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th className="text-center"></th>
                                                    <th className="text-center"></th>
                                                    <th className="text-center" colSpan={4} >Current Academic Year</th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th className="text-center">Next Academic Year</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th className="text-center" rowSpan={2}>additional Headcount Required</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th className="text-center" rowSpan={2}>Position</th>
                                                </tr>
                                                <tr>
                                                    <th style={{width:"5%"}} className="text-center">Classes</th>
                                                    <th className="text-center">No.of Students</th>
                                                    <th className="text-center">Total Students</th>
                                                    <th className="text-center">No. of Sections</th>
                                                    <th className="text-center">No.of Teachers </th>
                                                    <th className="text-center">Ratio</th>
                                                    <th className="text-center" colSpan={2}>Target No of Students by July</th>
                                                    <th className="text-center" colSpan={2}>Registrations as on date</th>
                                                    <th className="text-center">proposed number of Sections per class</th>
                                                    <th className="text-center">No. of students per class</th>
                                                    <th className="text-center">No.of Teachers </th>
                                                    <th className="text-center">Ratio</th>
                                                    <th className="text-center">Total Teacher</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {main_data.map((item, index) => {
                                                    let totalStudents = item.totalStudent
                                                    let totalTeachers = item.totalTeacher
                                                    let ratio = (totalStudents/totalTeachers).toFixed(2)
                                                    return(
                                                 
                                                        <tr key={index}>
                                                            <td className="text-center" >{item.class_name}</td>
                                                            <td className="text-center">{item.cal_total_student}</td>
                                                           {item.rowSpan > 1 && <td  className="text-center text-center" rowSpan={item.rowSpan}>{totalStudents}</td>}
                                                           <td className="text-center">{item.cal_total_section}</td>
                                                           {item.rowSpan > 1 && <td className="text-center text-center " rowSpan={item.rowSpan}>{totalTeachers}</td>}
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>{ratio}</td>}
                                                           <td className="text-center">NA</td>
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                           <td className="text-center">0</td>
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                           <td className="text-center">0</td>
                                                           <td className="text-center">0</td>
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                           {item.rowSpan > 1 && <td className="text-center text-center" rowSpan={item.rowSpan}>NA</td>}
                                                        </tr>
                                                    )

                                                   
                                                })}

                                                 <tr className="bg-info text-white">
                                                    <td className="text-center font-weight-bold text-white">Total</td>
                                                    <td className="text-center">{totalStudentsCount}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">{totalSectionCount}</td>
                                                    <td className="text-center">{totalTeacherCount}</td>
                                                    <td></td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">0</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Work_force_planning;


