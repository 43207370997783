import React from "react";

const Input = ({ className, label, type, placeholder, name, value, onChange, error, id }) => {
  return (
    <>
      <div className={className}>
        <div className="form-group">
          <label className="text-muted" htmlFor="">
            {label}
          </label>
          <input type={type} id={id} className="form-control" placeholder={placeholder}  name={name} value={value} onChange={onChange} />
          <span className="required">{error}</span>
        </div>
      </div>
    </>
  );
};

export default Input;
