import React, {useEffect, useState} from 'react'
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Pagination from "react-js-pagination";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

const customStyles = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height:"70%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };


const Employee_notifications = () => {
    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    let company_id = Apiconnect.get_company_id()

    const {id} = useParams()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [employees, setEmployees] = useState([])
    const [dept_id, setDept] = useState(0);
    const [catList, setCategory] = useState([]);
    const [empCount, setempCount] = useState(0);
    const [empStatus, setEmpStatus] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')

    const [allchecked, setallchecked] = useState(null);
    const [selectedEmp, setSelectedEmp] = useState([]);

    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        getEmployeeList();
        getCategory();
        getEmployeeList_new("50", "0", "",'',empStatus);
        getEmpCount("",'',empStatus);
        getallSelectedemp()
      }, []);


    const getEmployeeList = () => {
        Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setEmployees(_xtract.reverse());
        });
      };

    const getEmployeeList_new = (limit, offset, search,dept_id,empStatus) => {
    // setLoader(true);
    const data = {
        limit: limit,
        offset: offset,
        search: search,
        company_id: Apiconnect.get_company_id(),
        dept_id:dept_id,
        empStatus:empStatus
    };
    Apiconnect.postData(`employee/getallemployeelist`, data).then(
        (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        //console.log('test',_xtract.reverse())
        setEmployees(_xtract.reverse());
        // setLoader(false);
        }
    );
    };

    const getCategory = () => {
        var company_id = Apiconnect.get_company_id();
        Apiconnect.getData(`company_category/getall/${company_id}`).then(
            (response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCategory(_xtract.reverse());
            }
        );
    };

    const getEmpCount = (search,dept_id,empStatus) => {

        var dept_id1 = '';
        if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
           dept_id1 = dept_id;
        }
    
        const data = {
          search: search,
          company_id: Apiconnect.get_company_id(),
          dept_id:dept_id1,
          empStatus:empStatus
        };
        
    
        Apiconnect.postData(
          `employee/getEmpSearchcount`,data
        ).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setempCount(_xtract[0].cnt);
        });
      };

    const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * 50;
    //console.log(`active page is ${pageNumber} -- ${offset}`);
    let limit = 50;
    getEmployeeList_new(limit, offset, "",dept_id,empStatus);
    };

    const getEmployees = (empStatus,dept_id)  => {
        var company_id = Apiconnect.get_company_id();
        var dept_id2 = '';
        if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
           dept_id2 = dept_id;
        }
        Apiconnect.getData(`employee/allempinfo/${company_id}/${empStatus}/${dept_id2}`).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
        });
      };

    useEffect ((async) => {
        const delayDebounceFn = setTimeout(async() => {
          //console.log(searchTerm)
          
          let offset = (activePage - 1) * 50;
          let limit = 50;
         
          await getEmployeeList_new(limit, offset, searchTerm,dept_id,empStatus);
    
          var dept_id1 = '';
          if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
             dept_id1 = dept_id;
          }
      
          var dept_id1 = '';
          const data = {
            search: searchTerm,
            company_id: Apiconnect.get_company_id(),
            dept_id:dept_id1,
            empStatus:empStatus
          };
      
          await Apiconnect.postData( `employee/getEmpSearchcount`,data ).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setempCount(_xtract[0].cnt);
          });
      
        }, 2000)
      
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

    const onDeptChange = (e) => {
    let dept_id = e.target.value;
    getEmployees(empStatus,dept_id);
    getEmployeeList_new("50", "0", "",dept_id,empStatus);
    getEmpCount('',dept_id,empStatus)
    setDept(dept_id);
    };

    const onSatusChange = (e) => {
    let st_id = e.target.value;
    getEmployees(st_id,dept_id);
    getEmployeeList_new("50", "0", "",dept_id,st_id);
    getEmpCount('',dept_id,st_id)
    setEmpStatus(st_id);
    };

    
    const onInputChange2 = (e) => {
    setallchecked(null);

    if (e.target.checked === true) {
        setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
        });
        // setSelectedCountEmp({ ...selectedCountEmp, [e.currentTarget.name]: e.currentTarget.value });
    } else {
        setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });

        // let ck12 = Object.keys(selectedCountEmp).find(function (element) {
        //    if(selectedCountEmp[element] == e.currentTarget.name){
        //       delete selectedCountEmp[element];
        //    }
        // });
    }
    };
    
    const sellectAll = (e) => {
        if (e.target.checked === true) {
            // console.log(e.target.checked);
            setallchecked("checked");
            let ob = {};
            employees.map((val, key) => {
            ob[val.id] = val.id;
            });
            setSelectedEmp(ob);
            // setSelectedCountEmp(ob);
        } else {
            setallchecked(null);
            setSelectedEmp([]);
            // setSelectedCountEmp([]);
        }
    };

    const assign_employees = () => {
        const data_ins = {emn_company_id:company_id, emn_employee_id:id, employees:selectedEmp}
        if(Object.keys(selectedEmp).length > 0){
            Apiconnect.postData(`employee_notifications/create`, data_ins).then(res => {
                alert.success("Assigned")
                setSelectedEmp([])
                setModalIsOpen(false)
                getallSelectedemp()
            })
        }
    }
 
    const [data_list, setData_list] = useState([])
    const getallSelectedemp = () => {
        const data_ins={emn_company_id:company_id, emn_employee_id:id}
        Apiconnect.postData(`employee_notifications/getall`, data_ins).then(res => {
            let data =Apiconnect.decrypt_obj(res.data.data)
            setData_list(data)
            console.log(data)
        })
    }


    const handleRemove = (id) => {
        Apiconnect.postData(`employee_notifications/remove/${id}`).then(res => {
            alert.success("Successfully Removed")
            getallSelectedemp()
        })
    }
  
    return (
        <div className='content-page'>
            <div className='container-fluid'>
                <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='iq-card'>
                                <div className='iq-card-header d-flex justify-content-between model_head'>
                                    <div className="iq-header-title">
                                        <h4 className="card-title" >Assign Employee</h4>
                                    </div>
                                </div>
                                <div className='iq-card-body'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <button className='btn btn-sm btn-info float-right' onClick={assign_employees}>Assign</button>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                            <label>Department</label>
                                            <select className="form-control" required name="emp_category" value={dept_id} onChange={(e) => onDeptChange(e)}>
                                                <option value="">Select Department</option>
                                                    {catList.map((val, key) => {
                                                    return (
                                                    <option key={key} value={val.id}>
                                                        {val.name}
                                                    </option>
                                                    );
                                                })}
                                            </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Employee Status</label>
                                                <select className="form-control" required name="emp_status" value={empStatus} onChange={(e) => onSatusChange(e)}>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                    <option value="">Both</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div className='col-md-12 mt-3'>
                                            <div className='table-responsive'>
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <tr className='text-muted'>
                                                            <td className='text-center'>
                                                                <input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => sellectAll(e)} />
                                                            </td>
                                                            <td className='text-center'>#</td>
                                                            <td>Employee Name</td>
                                                            <td>Employee Code</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            employees.map((item, index) => {

                                                                var ck = Object.keys(selectedEmp).find(function (
                                                                    element
                                                                  ) {
                                                                    return selectedEmp[element] == item.id;
                                                                  });
                                        
                                                                  var ck1 = "";
                                                                  if (ck !== undefined) {
                                                                    ck1 = true;
                                                                  }
                                                                return(
                                                                    <tr key={index}>
                                                                        <td className='text-center'>
                                                                            <input type="checkbox" name={item.id} value={item.id} checked={ck1} onChange={(e) => onInputChange2(e)}/>
                                                                        </td>
                                                                        <td className='text-center'>{index + 1}</td>
                                                                        <td>{item.first_name} {item.last_name}</td>
                                                                        <td>{item.emp_code}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={50}
                                                    totalItemsCount={empCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={(e) => handlePageChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className='iq-card-header d-flex justify-content-between model_head'>
                                <div className="iq-header-title">
                                    <h4 className="card-title ">Employee Notifications</h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="bs-example">
                                            <ul className="nav nav-tabs">
                                                <li className="nav-item">
                                                <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${emp_id}`} >Personal</Link>
                                                </li>
                                                <li className="nav-item">
                                                <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${emp_id}`} >Other Details</Link>
                                                </li>
                                                {/* <li className="nav-item">
                                                <Link className="nav-link" data-toggle="tab" to={`/employee_manage_bank/${emp_id}`} >Financial</Link>
                                                </li> */}
                                                <li className="nav-item">
                                                <Link className="nav-link" data-toggle="tab" to={`/employee_manage_employement/${emp_id}`} >Employement Details</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" data-toggle="tab" to={`/PreviousEmployment/${emp_id}`} >Previous Employment</Link>
                                                </li>
                                                <li className="nav-item">
                                                <Link className="nav-link" data-toggle="tab" to={`/employee_manage_reimbursement/${emp_id}`} >Reimbursement Info</Link>
                                                </li>
                                                {/* <li className="nav-item">
                                                <Link className="nav-link" data-toggle="tab" to={`/employee_manage_sal/${emp_id}`} >Salary (Monthly Wages)</Link>
                                                </li> */}
                                                <li className="nav-item">
                                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_qualification/${emp_id}`} >Educational Qualification</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link active" data-toggle="tab" to={`/Employee_notifications/${emp_id}`} >Employee Notifications</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='border'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <button className='btn btn-sm btn-primary float-right' onClick={() => setModalIsOpen(true)}>Add Employee</button>
                                                    </div>
                                                    <div className='col-md-12 mt-3'>
                                                        <div className='table-responsive'>
                                                            <table className='table table-bordered'>
                                                                <thead className='thead-light'>
                                                                    <tr>
                                                                        <th className='text-center'>#</th>
                                                                        <th>Employee Name</th>
                                                                        <th>Employee Code</th>
                                                                        <th className='text-center'>Manage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        data_list.map((item, index) => {
                                                                            return(
                                                                                <tr key={index}>
                                                                                    <td className='text-center'>{index + 1}</td>
                                                                                    <td>{item.name}</td>
                                                                                    <td>{item.emp_code}</td>
                                                                                    <td className='text-center'>
                                                                                        <button className='btn btn-sm btn-danger' onClick={() => handleRemove(item.emn_pk_id)}>Remove</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Employee_notifications