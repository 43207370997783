import React, { useState, useEffect } from "react";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import moment from "moment";
import Modal from "react-modal";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
  },
};

const Career = () => {
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alljoblists, setAlljoblists] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [position, setPosition] = useState("");
  const [position_id, setPosition_id] = useState("");
  const [company_id, setCompany_id] = useState("");
  const [inputFields, setInputFields] = useState({
    am_wf_id: "",
    am_first_name: "",
    am_middle_name: "",
    am_last_name: "",
    am_address_correspondence: "",
    am_address_permanent: "",
    am_phone_res: "",
    am_phone_cell: "",
    am_email: "",
    am_email_alternative: "",
    am_dob: "",
    am_marital_status: "",
    am_lk_read: "",
    am_lk_write: "",
    am_lk_speak: "",
    am_caste: "",
    am_court_proceedings: "",
    am_contagious_disease: "",
    am_org_member_list: "",
    am_political_org_connection: "",
    am_employed_here_before: "",
    am_relatives_employed_here: "",
    am_current_ctc_fixed: "",
    am_current_ctc_reimbursements: "",
    am_current_ctc_incentives: "",
    am_current_ctc_others: "",
    am_expected_emoluments: "",
    am_notice_period: "",
    am_other_details: "",
    am_remark_if_appointed: "",
    am_cocurriculum_games_sports: "",
    am_cocurriculum_games_sports: "",
    am_cocurriculum_music_dance: "",
    am_cocurriculum_env_sc_astro: "",
    am_cocurriculum_computer_tech: "",
    am_cocurriculum_debates_quiz: "",
    am_cocurriculum_others: "",
    am_cocurriculum_art_craft: "",
  });
  const [educational_history, setEducational_history] = useState([{ ame_qualification: "", ame_name_address: "", ame_university_board: "", ame_year_start: "", ame_year_end: "", ame_subject: "", ame_mark_percentage: "" }]);
  const [publications, setPublications] = useState([{ amp_written: "", amp_published_by: "", amp_date_published: "" }]);
  const [training_development, setTraining_development] = useState([{ amtad_topic: "", amtad_topic: "", amtad_organised_by: "", amtad_remarks: "" }]);
  const [employment, setEmployment] = useState([{ ame_name: "", ame_from: "", ame_to: "", ame_designation: "", ame_scope_subject: "", ame_ctc_drawn: "", ame_empCode: "", ame_reportingManager: "", ame_reasonForLeaving: "", ame_finalSettlement: "", ame_org_email: "", ame_org_contact: "" }]);
  const [family, setFamily] = useState([{ amfp_relation: "", amfp_name: "", amfp_age: "", amfp_staying: "", amfp_staying: "", amfp_dependant_on_you: "" }]);
  const [references, setReferences] = useState([{ ampr_name: "", ampr_address: "", ampr_occupation: "", ampr_may_be_contacted: "", ampr_email: "", ampr_phone: "" }]);
  const [selectedfile, setSelectedfile] = useState("");

  const [basic_dropDown, setBasic_dropDown] = useState(false);
  const [ed_dropDown, setEd_dropDown] = useState(false);
  const [pub_dropDown, setPub_dropDown] = useState(false);
  const [td_dropDown, setTd_dropDown] = useState(false);
  const [emp_dropDown, setEmp_dropDown] = useState(false);
  const [fam_dropDown, setFam_dropDown] = useState(false);
  const [ref_dropDown, setRef_dropDown] = useState(false);
  const [res_dropDown, setRes_dropDown] = useState(false);

  const [errors, setErrors] = useState({});

  const { am_first_name, am_middle_name, am_last_name, am_address_correspondence, am_address_permanent, am_caste, am_contagious_disease, am_court_proceedings, am_dob, am_email, am_email_alternative, am_employed_here_before, am_lk_read, am_lk_speak, am_lk_write, am_marital_status, am_org_member_list, am_phone_res, am_political_org_connection, am_relatives_employed_here, am_phone_cell, am_current_ctc_fixed, am_current_ctc_incentives, am_current_ctc_others, am_current_ctc_reimbursements, am_expected_emoluments, am_notice_period, am_other_details, am_remark_if_appointed, am_cocurriculum_computer_tech, am_cocurriculum_debates_quiz, am_cocurriculum_env_sc_astro, am_cocurriculum_games_sports, am_cocurriculum_music_dance, am_cocurriculum_others, am_cocurriculum_art_craft } = inputFields;

  useEffect(() => {
    getJobList();
  }, [errors]);

  const validateValues = (values) => {
    let errors = {};
    if (am_first_name === "") {
      errors.am_first_name = "The firstname is required";
    }
    if (am_last_name === "") {
      errors.am_last_name = "The lastname is required";
    }
    if (am_address_correspondence === "") {
      errors.am_address_correspondence = "The address is required";
    }
    if (am_address_permanent === "") {
      errors.am_address_permanent = "The address is required";
    }
    if (am_caste === "") {
      errors.am_caste = "Select Caste";
    }
    if (am_dob === "") {
      errors.am_dob = "The Date of birth is required";
    }
    if (am_email === "") {
      errors.am_email = "Email is required";
    }
    if (am_lk_read === "") {
      errors.am_lk_read = "Required";
    }
    if (am_lk_speak === "") {
      errors.am_lk_speak = "Required";
    }
    if (am_lk_write === "") {
      errors.am_lk_write = "Required";
    }
    if (am_phone_cell === "") {
      errors.am_phone_cell = "Required";
    }
    if (am_marital_status === "") {
      errors.am_marital_status = "Required";
    }
    if (am_court_proceedings === "") {
      errors.am_court_proceedings = "Required";
    }
    if (am_contagious_disease === "") {
      errors.am_contagious_disease = "Required";
    }
    if (am_political_org_connection === "") {
      errors.am_political_org_connection = "Required";
    }
    if (am_employed_here_before === "") {
      errors.am_employed_here_before = "Required";
    }
    if (am_relatives_employed_here === "") {
      errors.am_relatives_employed_here = "Required";
    }
    if (am_current_ctc_fixed === "") {
      errors.am_current_ctc_fixed = "Required";
    }
    if (am_current_ctc_reimbursements === "") {
      errors.am_current_ctc_reimbursements = "Required";
    }
    if (am_current_ctc_incentives === "") {
      errors.am_current_ctc_incentives = "Required";
    }
    if (am_current_ctc_others === "") {
      errors.am_current_ctc_others = "Required";
    }
    if (am_expected_emoluments === "") {
      errors.am_expected_emoluments = "Required";
    }
    if (am_notice_period === "") {
      errors.am_notice_period = "Required";
    }
    return errors;
  };

  const onInputChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const onInputChange2 = (e, index) => {
    const { name, value } = e.target;
    const lists = [...educational_history];
    lists[index][name] = value;
    setEducational_history(lists);
  };
  const onInputChange3 = (e, index) => {
    const { name, value } = e.target;
    const lists = [...publications];
    lists[index][name] = value;
    setPublications(lists);
  };
  const onInputChange4 = (e, index) => {
    const { name, value } = e.target;
    const lists = [...training_development];
    lists[index][name] = value;
    setTraining_development(lists);
  };
  const onInputChange5 = (e, index) => {
    const { name, value } = e.target;
    const lists = [...employment];
    lists[index][name] = value;
    setEmployment(lists);
  };
  const onInputChange6 = (e, index) => {
    const { name, value } = e.target;
    const lists = [...family];
    lists[index][name] = value;
    setFamily(lists);
  };
  const onInputChange7 = (e, index) => {
    const { name, value } = e.target;
    const lists = [...references];
    lists[index][name] = value;
    setReferences(lists);
  };

  const handleaddClick = () => {
    setEducational_history([...educational_history, { ame_qualification: "", ame_name_address: "", ame_university_board: "", ame_year_start: "", ame_year_end: "", ame_subject: "", ame_mark_percentage: "" }]);
  };
  const handleaddClick1 = () => {
    setPublications([...publications, { amp_written: "", amp_published_by: "", amp_date_published: "" }]);
  };
  const handleaddClick2 = () => {
    setTraining_development([...training_development, { amtad_topic: "", amtad_topic: "", amtad_organised_by: "", amtad_remarks: "" }]);
  };
  const handleaddClick3 = () => {
    setEmployment([...employment, { ame_name: "", ame_from: "", ame_to: "", ame_designation: "", ame_scope_subject: "", ame_ctc_drawn: "", ame_empCode: "", ame_reportingManager: "", ame_reasonForLeaving: "", ame_finalSettlement: "", ame_org_email: "", ame_org_contact: "" }]);
  };
  const handleaddClick4 = () => {
    setFamily([...family, { amfp_relation: "", amfp_name: "", amfp_age: "", amfp_staying: "", amfp_staying: "", amfp_dependant_on_you: "" }]);
  };
  const handleaddClick5 = () => {
    setReferences([...references, { ampr_name: "", ampr_address: "", ampr_occupation: "", ampr_may_be_contacted: "", ampr_email: "", ampr_phone: "" }]);
  };

  const handleRemove = (index) => {
    const list = [...educational_history];
    list.splice(index, 1);
    setEducational_history(list);
  };
  const handleRemove1 = (index) => {
    const list = [...publications];
    list.splice(index, 1);
    setPublications(list);
  };
  const handleRemove2 = (index) => {
    const list = [...training_development];
    list.splice(index, 1);
    setTraining_development(list);
  };
  const handleRemove3 = (index) => {
    const list = [...employment];
    list.splice(index, 1);
    setEmployment(list);
  };
  const handleRemove4 = (index) => {
    const list = [...family];
    list.splice(index, 1);
    setFamily(list);
  };
  const handleRemove5 = (index) => {
    const list = [...references];
    list.splice(index, 1);
    setReferences(list);
  };

  const getJobList = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`hiring_application/vacancies_getall`).then((res) => {
      setFullScreenLoader(false);
      setAlljoblists(res.data.data);
    });
  };

  const [wf_id, setWf_id] = useState("");

  const handleSubmit = () => {
    inputFields["am_org"] = companyName;
    inputFields["am_location"] = companyAddress;
    inputFields["am_position"] = position;
    inputFields["am_position_id"] = position_id;
    inputFields["am_company_id"] = company_id;
    inputFields["am_wf_id"] = wf_id;

    const data = new FormData();
    data.append("resume", selectedfile);
    data.append("basic_details", JSON.stringify(inputFields));
    data.append("educational_history", JSON.stringify(educational_history));
    data.append("published", JSON.stringify(publications));
    data.append("training_development", JSON.stringify(training_development));
    data.append("employment_history", JSON.stringify(employment));
    data.append("family_particulars", JSON.stringify(family));
    data.append("professional_references", JSON.stringify(references));

    // let ins_ob = {basic_details: inputFields, educational_history: educational_history, published: publications, training_development: training_development, employment_history: employment, family_particulars: family, professional_references: references, resume:data}
    // setFullScreenLoader(true)
    if (
      company_id !== "" &&
      position_id !== "" &&
      position !== "" &&
      companyAddress !== "" &&
      companyName !== "" &&
      am_first_name !== "" &&
      am_last_name !== "" &&
      am_address_correspondence !== "" &&
      am_address_permanent !== "" &&
      am_email !== "" &&
      am_dob !== "" &&
      am_marital_status !== "" &&
      am_lk_read !== "" &&
      am_lk_write !== "" &&
      am_lk_speak !== "" &&
      am_caste !== "" &&
      am_court_proceedings !== "" &&
      am_contagious_disease !== "" &&
      am_org_member_list !== "" &&
      am_political_org_connection !== "" &&
      am_employed_here_before !== "" &&
      am_relatives_employed_here !== "" &&
      am_current_ctc_fixed !== "" &&
      am_current_ctc_reimbursements !== "" &&
      am_current_ctc_incentives !== "" &&
      am_current_ctc_others !== "" &&
      am_current_ctc_others !== "" &&
      am_expected_emoluments !== "" &&
      am_notice_period !== "" &&
      am_other_details !== "" &&
      selectedfile !== "" &&
      employment[0].ame_name !== "" &&
      employment[0].ame_org_contact !== "" &&
      employment[0].ame_org_email !== "" &&
      employment[0].ame_from !== "" &&
      employment[0].ame_to !== "" &&
      employment[0].ame_ctc_drawn !== "" &&
      employment[0].ame_designation !== "" &&
      employment[0].ame_empCode !== "" &&
      employment[0].ame_finalSettlement !== "" &&
      employment[0].ame_reasonForLeaving !== "" &&
      employment[0].ame_reportingManager !== "" &&
      employment[0].ame_scope_subject !== ""
    ) {
      Apiconnect.postData(`hiring_application/hiring_application_create`, data).then((res) => {
        console.log("Hello World");
        setFullScreenLoader(false);
        setModalIsOpen(false);
        setInputFields({ am_first_name: "", am_middle_name: "", am_last_name: "", am_position: "", am_address_correspondence: "", am_address_permanent: "", am_phone_res: "", am_phone_cell: "", am_email: "", am_email_alternative: "", am_dob: "", am_marital_status: "", am_lk_read: "", am_lk_write: "", am_lk_speak: "", am_caste: "", am_court_proceedings: "", am_contagious_disease: "", am_org_member_list: "", am_political_org_connection: "", am_employed_here_before: "", am_relatives_employed_here: "", am_current_ctc_fixed: "", am_current_ctc_reimbursements: "", am_current_ctc_incentives: "", am_current_ctc_others: "", am_expected_emoluments: "", am_notice_period: "", am_other_details: "", am_remark_if_appointed: "" });
        setEducational_history([{ ame_qualification: "", ame_name_address: "", ame_university_board: "", ame_year_start: "", ame_year_end: "", ame_subject: "", ame_mark_percentage: "" }]);
        setPublications([{ amp_written: "", amp_published_by: "", amp_date_published: "" }]);
        setTraining_development([{ amtad_topic: "", amtad_topic: "", amtad_organised_by: "", amtad_remarks: "" }]);
        setEmployment([{ ame_name: "", ame_from: "", ame_to: "", ame_designation: "", ame_scope_subject: "", ame_ctc_drawn: "", ame_empCode: "", ame_reportingManager: "", ame_reasonForLeaving: "", ame_finalSettlement: "", ame_org_email: "", ame_org_contact: "" }]);
        setFamily([{ amfp_relation: "", amfp_name: "", amfp_age: "", amfp_staying: "", amfp_staying: "", amfp_dependant_on_you: "" }]);
        setReferences([{ ampr_name: "", ampr_address: "", ampr_occupation: "", ampr_may_be_contacted: "", ampr_email: "", ampr_phone: "" }]);
        alert.success("Successfully Applied");
      });
    } else {
      setFullScreenLoader(false);
      alert.error("Some Inputfields are empty");
    }
  };

  const handleClose = () => {
    setModalIsOpen(false);
    setInputFields({ am_first_name: "", am_middle_name: "", am_last_name: "", am_position: "", am_address_correspondence: "", am_address_permanent: "", am_phone_res: "", am_phone_cell: "", am_email: "", am_email_alternative: "", am_dob: "", am_marital_status: "", am_lk_read: "", am_lk_write: "", am_lk_speak: "", am_caste: "", am_court_proceedings: "", am_contagious_disease: "", am_org_member_list: "", am_political_org_connection: "", am_employed_here_before: "", am_relatives_employed_here: "", am_current_ctc_fixed: "", am_current_ctc_reimbursements: "", am_current_ctc_incentives: "", am_current_ctc_others: "", am_expected_emoluments: "", am_notice_period: "", am_other_details: "", am_remark_if_appointed: "" });
    setEducational_history([{ ame_qualification: "", ame_name_address: "", ame_university_board: "", ame_year_start: "", ame_year_end: "", ame_subject: "", ame_mark_percentage: "" }]);
    setPublications([{ amp_written: "", amp_published_by: "", amp_date_published: "" }]);
    setTraining_development([{ amtad_topic: "", amtad_topic: "", amtad_organised_by: "", amtad_remarks: "" }]);
    setEmployment([{ ame_name: "", ame_from: "", ame_to: "", ame_designation: "", ame_scope_subject: "", ame_ctc_drawn: "", ame_ampCode: "", ame_reportingManager: "", ame_reasonForLeaving: "", ame_finalSettlement: "", ame_org_email: "", ame_org_contact: "" }]);
    setFamily([{ amfp_relation: "", amfp_name: "", amfp_age: "", amfp_staying: "", amfp_staying: "", amfp_dependant_on_you: "" }]);
    setReferences([{ ampr_name: "", ampr_address: "", ampr_occupation: "", ampr_may_be_contacted: "", ampr_email: "", ampr_phone: "" }]);
    setSelectedfile("");
    setRes_dropDown(false);
  };

  const handleNext_1 = () => {
    setErrors(validateValues(inputFields));
    if (Object.keys(errors).length === 0 && am_first_name !== "") {
      setBasic_dropDown(false);
      setEd_dropDown(true);
      setPub_dropDown(false);
      setTd_dropDown(false);
      setEmp_dropDown(false);
      setFam_dropDown(false);
      setRef_dropDown(false);
      setRes_dropDown(false);
    }
    console.log(errors);
  };

  const handleNext_2 = () => {
    if (educational_history[0].ame_mark_percentage !== "" && educational_history[0].ame_name_address !== "" && educational_history[0].ame_qualification !== "" && educational_history[0].ame_subject !== "" && educational_history[0].ame_university_board !== "" && educational_history[0].ame_year_end !== "" && educational_history[0].ame_year_start !== "") {
      setBasic_dropDown(false);
      setEd_dropDown(false);
      setPub_dropDown(true);
      setTd_dropDown(false);
      setEmp_dropDown(false);
      setFam_dropDown(false);
      setRef_dropDown(false);
      setRes_dropDown(false);
    } else {
      alert.error("Input fields are empty");
    }
  };
  const handleNext_3 = () => {
    setBasic_dropDown(false);
    setEd_dropDown(false);
    setPub_dropDown(false);
    setTd_dropDown(true);
    setEmp_dropDown(false);
    setFam_dropDown(false);
    setRef_dropDown(false);
    setRes_dropDown(false);
  };
  const handleNext_4 = () => {
    setBasic_dropDown(false);
    setEd_dropDown(false);
    setPub_dropDown(false);
    setTd_dropDown(false);
    setEmp_dropDown(true);
    setFam_dropDown(false);
    setRef_dropDown(false);
    setRes_dropDown(false);
  };
  const handleNext_5 = () => {
    setBasic_dropDown(false);
    setEd_dropDown(false);
    setPub_dropDown(false);
    setTd_dropDown(false);
    setEmp_dropDown(false);
    setFam_dropDown(true);
    setRef_dropDown(false);
    setRes_dropDown(false);
  };
  const handleNext_6 = () => {
    setBasic_dropDown(false);
    setEd_dropDown(false);
    setPub_dropDown(false);
    setTd_dropDown(false);
    setEmp_dropDown(false);
    setFam_dropDown(false);
    setRef_dropDown(true);
    setRes_dropDown(false);
  };
  const handleNext_7 = () => {
    setBasic_dropDown(false);
    setEd_dropDown(false);
    setPub_dropDown(false);
    setTd_dropDown(false);
    setEmp_dropDown(false);
    setFam_dropDown(false);
    setRef_dropDown(false);
    setRes_dropDown(true);
  };

  return (
    <>
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between" style={{ background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)", borderRadius: 10 }}>
            <div className="iq-header-title">
              <h4 className="card-title white">Applying For {position}</h4>
            </div>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 m-2 p-2">
                    <div className="bs-example">
                      <ul className="nav nav-tabs">
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(true);
                            setEd_dropDown(false);
                            setPub_dropDown(false);
                            setTd_dropDown(false);
                            setEmp_dropDown(false);
                            setFam_dropDown(false);
                            setRef_dropDown(false);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${basic_dropDown ? "active" : null}`} data-toggle="tab">
                            Basic Details
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(true);
                            setPub_dropDown(false);
                            setTd_dropDown(false);
                            setEmp_dropDown(false);
                            setFam_dropDown(false);
                            setRef_dropDown(false);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${ed_dropDown ? "active" : null}`} data-toggle="tab">
                            Education
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(false);
                            setPub_dropDown(true);
                            setTd_dropDown(false);
                            setEmp_dropDown(false);
                            setFam_dropDown(false);
                            setRef_dropDown(false);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${pub_dropDown ? "active" : null}`} data-toggle="tab">
                            Publication
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(false);
                            setPub_dropDown(false);
                            setTd_dropDown(true);
                            setEmp_dropDown(false);
                            setFam_dropDown(false);
                            setRef_dropDown(false);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${td_dropDown ? "active" : null}`} data-toggle="tab">
                            Training & Development
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(false);
                            setPub_dropDown(false);
                            setTd_dropDown(false);
                            setEmp_dropDown(true);
                            setFam_dropDown(false);
                            setRef_dropDown(false);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${emp_dropDown ? "active" : null}`} data-toggle="tab">
                            Employment History
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(false);
                            setPub_dropDown(false);
                            setTd_dropDown(false);
                            setEmp_dropDown(false);
                            setFam_dropDown(true);
                            setRef_dropDown(false);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${fam_dropDown ? "active" : null}`} data-toggle="tab">
                            Family Particulars
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(false);
                            setPub_dropDown(false);
                            setTd_dropDown(false);
                            setEmp_dropDown(false);
                            setFam_dropDown(false);
                            setRef_dropDown(true);
                            setRes_dropDown(false);
                          }}
                        >
                          <Link className={`nav-link ${ref_dropDown ? "active" : null}`} data-toggle="tab">
                            Professional References
                          </Link>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setBasic_dropDown(false);
                            setEd_dropDown(false);
                            setPub_dropDown(false);
                            setTd_dropDown(false);
                            setEmp_dropDown(false);
                            setFam_dropDown(false);
                            setRef_dropDown(false);
                            setRes_dropDown(true);
                          }}
                        >
                          <Link className={`nav-link ${res_dropDown ? "active" : null}`} data-toggle="tab">
                            Upload Resume
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {basic_dropDown && (
                  <div className="row m-2 p-2" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                    <div className="col-md-4 mb-3">
                      <label>
                        First Name <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_first_name" value={am_first_name} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_first_name}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Middle Name</label>
                      <input type="text" className="form-control" name="am_middle_name" value={am_middle_name} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Last Name <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_last_name" value={am_last_name} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_last_name}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Email id <span className="required">*</span>
                      </label>
                      <input type="email" className="form-control" name="am_email" value={am_email} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_email}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Alternate Email Id</label>
                      <input type="email" className="form-control" name="am_email_alternative" value={am_email_alternative} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Date of Birth <span className="required">*</span>
                      </label>
                      <input type="date" className="form-control" name="am_dob" value={am_dob} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_dob}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Position</label>
                      <input type="text" className="form-control" name="position" value={position} />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Location</label>
                      <input type="text" className="form-control" name="companyaddress" value={companyAddress} />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Organisation</label>
                      <input type="text" className="form-control" name="companyname" value={companyName} />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        Correspondence Address <span className="required">*</span>
                      </label>
                      <textarea type="text" className="form-control" name="am_address_correspondence" value={am_address_correspondence} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_address_correspondence}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        Permanent Address <span className="required">*</span>
                      </label>
                      <textarea type="text" className="form-control" name="am_address_permanent" value={am_address_permanent} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_address_permanent}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Phone (Res) <span className="required">*</span>
                      </label>
                      <input type="text" pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im" className="form-control" name="am_phone_res" value={am_phone_res} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Phone (Cell) <span className="required">*</span>
                      </label>
                      <input type="text" pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im" className="form-control" name="am_phone_cell" value={am_phone_cell} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Marital Status <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_marital_status" value={am_marital_status} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="Married">Married</option>
                        <option value="Unmarried">Unmarried</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Languages Known: Read <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_lk_read" value={am_lk_read} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_lk_read}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Languages Known: Write <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_lk_write" value={am_lk_write} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_lk_write}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Languages Known: Speak <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_lk_speak" value={am_lk_speak} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_lk_speak}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Caste <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_caste" value={am_caste} onChange={(e) => onInputChange(e)} required>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                        <option value="OBC">OBC</option>
                        <option value="GEN">GEN</option>
                      </select>
                      <p className="required">{errors.am_caste}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        If you been involved in any court proceedings <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_court_proceedings" value={am_court_proceedings} onChange={(e) => onInputChange(e)} required>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="required">{errors.am_court_proceedings}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        If you suffer from any contagious disease <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_contagious_disease" value={am_contagious_disease} onChange={(e) => onInputChange(e)} required>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="required">{errors.am_contagious_disease}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        List of Professional, Social, Religious or cultural organization of which you are a member <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_org_member_list" value={am_org_member_list} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        If you have ever (directly or indirectly) been connected with any political organization <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_political_org_connection" value={am_political_org_connection} onChange={(e) => onInputChange(e)} required>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="required">{errors.am_political_org_connection}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        If you ever been employed with any of the company/institute affiliated(directly/indirectly)with this organisation <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_employed_here_before" value={am_employed_here_before} onChange={(e) => onInputChange(e)} required>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="required">{errors.am_employed_here_before}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        If any of your friend/relative is employed with this organisation <span className="required">*</span>
                      </label>
                      <select className="form-control" name="am_relatives_employed_here" value={am_relatives_employed_here} onChange={(e) => onInputChange(e)} required>
                        <option value="" disabled>
                          ---select---
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="required">{errors.am_relatives_employed_here}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Current CTC Fixed <span className="required">*</span>
                      </label>
                      <input type="number" className="form-control" name="am_current_ctc_fixed" value={am_current_ctc_fixed} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_current_ctc_fixed}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Current CTC Reimbursements <span className="required">*</span>
                      </label>
                      <input type="number" className="form-control" name="am_current_ctc_reimbursements" value={am_current_ctc_reimbursements} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_current_ctc_reimbursements}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Current CTC Incentives <span className="required">*</span>
                      </label>
                      <input type="number" className="form-control" name="am_current_ctc_incentives" value={am_current_ctc_incentives} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_current_ctc_incentives}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Current CTC Others</label>
                      <input type="number" className="form-control" name="am_current_ctc_others" value={am_current_ctc_others} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_current_ctc_others}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Total emoluments expected <span className="required">*</span>
                      </label>
                      <input type="number" className="form-control" name="am_expected_emoluments" value={am_expected_emoluments} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_expected_emoluments}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        Notice Period with the current organization <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" name="am_notice_period" value={am_notice_period} onChange={(e) => onInputChange(e)} autoComplete="off" required />
                      <p className="required">{errors.am_notice_period}</p>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Any other details to share (Industry, Research exposure you may like to share additionally)</label>
                      <textarea type="text" className="form-control" name="am_other_details" value={am_other_details} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>
                        How do you think our institute is going to benefit by appointing you <span className="required">*</span>
                      </label>
                      <textarea type="text" className="form-control" name="am_remark_if_appointed" value={am_remark_if_appointed} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Games & Sports</label>
                      <input type="text" className="form-control" name="am_cocurriculum_games_sports" value={am_cocurriculum_games_sports} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Art & Craft</label>
                      <input type="text" className="form-control" name="am_cocurriculum_art_craft" value={am_cocurriculum_art_craft} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Music & Dance</label>
                      <input type="text" className="form-control" name="am_cocurriculum_music_dance" value={am_cocurriculum_music_dance} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Environment Science/Astronomy</label>
                      <input type="text" className="form-control" name="am_cocurriculum_env_sc_astro" value={am_cocurriculum_env_sc_astro} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Computer & Technology</label>
                      <input type="text" className="form-control" name="am_cocurriculum_computer_tech" value={am_cocurriculum_computer_tech} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Debates & Quiz</label>
                      <input type="text" className="form-control" name="am_cocurriculum_debates_quiz" value={am_cocurriculum_debates_quiz} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Co-curriculum Activities - Any other</label>
                      <input type="text" className="form-control" name="am_cocurriculum_others" value={am_cocurriculum_others} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          {/* <button className='btn btn-sm btn-dark' onClick={() => {setBasic_dropDown(false); setEd_dropDown(false); setPub_dropDown(false); setTd_dropDown(false); setEmp_dropDown(false); setFam_dropDown(false); setRef_dropDown(false); setRes_dropDown(false)}}>Prev</button> */}
                          <button className="btn btn-sm btn-dark" onClick={handleNext_1}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ----Education--- */}
                {ed_dropDown && (
                  <div className="row">
                    <div className="col-md-12">
                      {educational_history.map((item, index) => {
                        return (
                          <div key={index} className="row mb-3 m-3" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <label>Qualification</label>
                                  <input type="text" className="form-control" name="ame_qualification" value={educational_history[index].ame_qualification} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Name & Address Of the Institute</label>
                                  <input type="text" className="form-control" name="ame_name_address" value={educational_history[index].ame_name_address} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>University/Board</label>
                                  <input type="text" className="form-control" name="ame_university_board" value={educational_history[index].ame_university_board} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>From</label>
                                  <input type="date" className="form-control" name="ame_year_start" value={educational_history[index].ame_year_start} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>To</label>
                                  <input type="date" className="form-control" name="ame_year_end" value={educational_history[index].ame_year_end} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Subject</label>
                                  <input type="text" className="form-control" name="ame_subject" value={educational_history[index].ame_subject} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>% Marks</label>
                                  <input type="number" className="form-control" name="ame_mark_percentage" value={educational_history[index].ame_mark_percentage} onChange={(e) => onInputChange2(e, index)} autoComplete="off" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3" style={{ border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  {educational_history.length - 1 === index && (
                                    <button className="btn btn-sm btn-primary mt-2" onClick={handleaddClick}>
                                      New
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center">
                                  <h5 className="text-capitalize" style={{ color: "white" }}>
                                    Educational History {index === 0 ? null : index + 1}
                                  </h5>
                                  <br />
                                </div>
                                <div className="col-md-3">
                                  {educational_history.length !== 1 && (
                                    <button className="btn btn-sm btn-danger mt-2 float-right" onClick={() => handleRemove(index)}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              setBasic_dropDown(true);
                              setEd_dropDown(false);
                              setPub_dropDown(false);
                              setTd_dropDown(false);
                              setEmp_dropDown(false);
                              setFam_dropDown(false);
                              setRef_dropDown(false);
                              setRes_dropDown(false);
                            }}
                          >
                            Prev
                          </button>
                          <button className="btn btn-sm btn-dark" onClick={handleNext_2}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ---publications--- */}
                {pub_dropDown && (
                  <div className="row">
                    <div className="col-md-12">
                      {publications.map((item, index) => {
                        return (
                          <div key={index} className="row mb-3 m-3" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <label>Thesis, papers, articles, cooks written</label>
                                  <input type="text" className="form-control" name="amp_written" value={publications[index].amp_written} onChange={(e) => onInputChange3(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Published by</label>
                                  <input type="text" className="form-control" name="amp_published_by" value={publications[index].amp_published_by} onChange={(e) => onInputChange3(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Date Published</label>
                                  <input type="date" className="form-control" name="amp_date_published" value={publications[index].amp_date_published} onChange={(e) => onInputChange3(e, index)} autoComplete="off" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3" style={{ border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  {publications.length - 1 === index && (
                                    <button className="btn btn-sm btn-primary mt-2" onClick={handleaddClick1}>
                                      New
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center">
                                  <h5 className="text-capitalize" style={{ color: "white" }}>
                                    Details Of Thesis, Papers, Articles, Books Published {index === 0 ? null : index + 1}
                                  </h5>
                                  <br />
                                </div>
                                <div className="col-md-3">
                                  {publications.length !== 1 && (
                                    <button className="btn btn-sm btn-danger mt-2 float-right" onClick={() => handleRemove1(index)}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              setBasic_dropDown(false);
                              setEd_dropDown(true);
                              setPub_dropDown(false);
                              setTd_dropDown(false);
                              setEmp_dropDown(false);
                              setFam_dropDown(false);
                              setRef_dropDown(false);
                              setRes_dropDown(false);
                            }}
                          >
                            Prev
                          </button>
                          <button className="btn btn-sm btn-dark" onClick={handleNext_3}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ---Training & Development--- */}
                {td_dropDown && (
                  <div className="row">
                    <div className="col-md-12">
                      {training_development.map((item, index) => {
                        return (
                          <div key={index} className="row mb-3 m-3" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <label>Topic</label>
                                  <input type="text" className="form-control" name="amtad_topic" value={training_development[index].amtad_topic} onChange={(e) => onInputChange4(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Date</label>
                                  <input type="date" className="form-control" name="amtad_date" value={training_development[index].amtad_date} onChange={(e) => onInputChange4(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Organized by</label>
                                  <input type="text" className="form-control" name="amtad_organised_by" value={training_development[index].amtad_organised_by} onChange={(e) => onInputChange4(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Remarks</label>
                                  <input type="text" className="form-control" name="amtad_remarks" value={training_development[index].amtad_remarks} onChange={(e) => onInputChange4(e, index)} autoComplete="off" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3" style={{ border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  {training_development.length - 1 === index && (
                                    <button className="btn btn-sm btn-primary mt-2" onClick={handleaddClick2}>
                                      New
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center">
                                  <h5 className="text-capitalize" style={{ color: "white" }}>
                                    Training & Development {index === 0 ? null : index + 1}
                                  </h5>
                                  <br />
                                </div>
                                <div className="col-md-3">
                                  {training_development.length !== 1 && (
                                    <button className="btn btn-sm btn-danger mt-2 float-right" onClick={() => handleRemove2(index)}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              setBasic_dropDown(false);
                              setEd_dropDown(false);
                              setPub_dropDown(true);
                              setTd_dropDown(false);
                              setEmp_dropDown(false);
                              setFam_dropDown(false);
                              setRef_dropDown(false);
                              setRes_dropDown(false);
                            }}
                          >
                            Prev
                          </button>
                          <button className="btn btn-sm btn-dark" onClick={handleNext_4}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ---Employment History--- */}
                {emp_dropDown && (
                  <div className="row">
                    <div className="col-md-12">
                      {employment.map((item, index) => {
                        return (
                          <div key={index} className="row mb-3 m-3" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <label>
                                    Period of Service From <span className="required">*</span>
                                  </label>
                                  <input type="date" className="form-control" name="ame_from" value={employment[index].ame_from} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label>
                                    Period of Service To <span className="required">*</span>
                                  </label>
                                  <input type="date" className="form-control" name="ame_to" value={employment[index].ame_to} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label>
                                    Name of the Company/School/College <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_name" value={employment[index].ame_name} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Official Email <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_org_email" value={employment[index].ame_org_email} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Official Contact Number <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_org_contact" value={employment[index].ame_org_contact} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Reporting Manager <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_reportingManager" value={employment[index].ame_reportingManager} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Employee Code <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_empCode" value={employment[index].ame_ampCode} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Designation <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_designation" value={employment[index].ame_designation} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Scope of Assignment/ Subject /Stream <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_scope_subject" value={employment[index].ame_scope_subject} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    CTC Drawn <span className="required">*</span>
                                  </label>
                                  <input type="number" className="form-control" name="ame_ctc_drawn" value={employment[index].ame_ctc_drawn} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Reason For Leaving <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_reasonForLeaving" value={employment[index].ame_reasonForLeaving} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>
                                    Final Settlement <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="ame_finalSettlement" value={employment[index].ame_finalSettlement} onChange={(e) => onInputChange5(e, index)} autoComplete="off" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3" style={{ border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  {employment.length - 1 === index && (
                                    <button className="btn btn-sm btn-primary mt-2" onClick={handleaddClick3}>
                                      New
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center">
                                  <h5 className="text-capitalize" style={{ color: "white" }}>
                                    Employment History {index === 0 ? null : index + 1}
                                  </h5>
                                  <br />
                                </div>
                                <div className="col-md-3">
                                  {employment.length !== 1 && (
                                    <button className="btn btn-sm btn-danger mt-2 float-right" onClick={() => handleRemove3(index)}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              setBasic_dropDown(false);
                              setEd_dropDown(false);
                              setPub_dropDown(false);
                              setTd_dropDown(true);
                              setEmp_dropDown(false);
                              setFam_dropDown(false);
                              setRef_dropDown(false);
                              setRes_dropDown(false);
                            }}
                          >
                            Prev
                          </button>
                          <button className="btn btn-sm btn-dark" onClick={handleNext_5}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ---Family Particulars--- */}
                {fam_dropDown && (
                  <div className="row">
                    <div className="col-md-12">
                      {family.map((item, index) => {
                        return (
                          <div key={index} className="row mb-3 m-3" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <label>Relation</label>
                                  <input type="text" className="form-control" name="amfp_relation" value={family[index].amfp_relation} onChange={(e) => onInputChange6(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Name</label>
                                  <input type="text" className="form-control" name="amfp_name" value={family[index].amfp_name} onChange={(e) => onInputChange6(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Age</label>
                                  <input type="number" className="form-control" name="amfp_age" value={family[index].amfp_age} onChange={(e) => onInputChange6(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Staying with you</label>
                                  <select className="form-control" name="amfp_staying" value={family[index].amfp_staying} onChange={(e) => onInputChange6(e, index)}>
                                    <option value="" disabled selected>
                                      --Select--
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Current Status</label>
                                  <select className="form-control" name="amfp_employment_status" value={family[index].amfp_employment_status} onChange={(e) => onInputChange6(e, index)}>
                                    <option value="" disabled selected>
                                      --Select--
                                    </option>
                                    <option value="Student">Student</option>
                                    <option value="Employed">Employed</option>
                                    <option value="Retired">Retired</option>
                                  </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Dependent on you</label>
                                  <select className="form-control" name="amfp_dependant_on_you" value={family[index].amfp_dependant_on_you} onChange={(e) => onInputChange6(e, index)}>
                                    <option value="" disabled selected>
                                      --Select--
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3" style={{ border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  {family.length - 1 === index && (
                                    <button className="btn btn-sm btn-primary mt-2" onClick={handleaddClick4}>
                                      New
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center">
                                  <h5 className="text-capitalize" style={{ color: "white" }}>
                                    Family Particulars {index === 0 ? null : index + 1}
                                  </h5>
                                  <br />
                                </div>
                                <div className="col-md-3">
                                  {family.length !== 1 && (
                                    <button className="btn btn-sm btn-danger mt-2 float-right" onClick={() => handleRemove4(index)}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              setBasic_dropDown(false);
                              setEd_dropDown(false);
                              setPub_dropDown(false);
                              setTd_dropDown(false);
                              setEmp_dropDown(true);
                              setFam_dropDown(false);
                              setRef_dropDown(false);
                              setRes_dropDown(false);
                            }}
                          >
                            Prev
                          </button>
                          <button className="btn btn-sm btn-dark" onClick={handleNext_6}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ---Professional References--- */}
                {ref_dropDown && (
                  <div className="row">
                    <div className="col-md-12">
                      {references.map((item, index) => {
                        return (
                          <div key={index} className="row mb-3 m-3" style={{ border: "3px solid #3F2A8F", borderRadius: 10 }}>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <label>Name</label>
                                  <input type="text" className="form-control" name="ampr_name" value={references[index].ampr_name} onChange={(e) => onInputChange7(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Email</label>
                                  <input type="text" className="form-control" name="ampr_email" value={references[index].ampr_email} onChange={(e) => onInputChange7(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Phone</label>
                                  <input type="text" className="form-control" name="ampr_phone" value={references[index].ampr_phone} onChange={(e) => onInputChange7(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>address</label>
                                  <input type="text" className="form-control" name="ampr_address" value={references[index].ampr_address} onChange={(e) => onInputChange7(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Occupation</label>
                                  <input type="text" className="form-control" name="ampr_occupation" value={references[index].ampr_occupation} onChange={(e) => onInputChange7(e, index)} autoComplete="off" />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>May be contacted</label>
                                  <select className="form-control" name="ampr_may_be_contacted" value={references[index].ampr_may_be_contacted} onChange={(e) => onInputChange7(e, index)}>
                                    <option value="" disabled selected>
                                      --Select--
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3" style={{ border: "1px solid #372a66", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  {references.length - 1 === index && (
                                    <button className="btn btn-sm btn-primary mt-2" onClick={handleaddClick5}>
                                      New
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center">
                                  <h5 className="text-capitalize" style={{ color: "white" }}>
                                    Professional References {index === 0 ? null : index + 1}
                                  </h5>
                                  <br />
                                </div>
                                <div className="col-md-3">
                                  {references.length !== 1 && (
                                    <button className="btn btn-sm btn-danger mt-2 float-right" onClick={() => handleRemove5(index)}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 center d-flex justify-content-around">
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              setBasic_dropDown(false);
                              setEd_dropDown(false);
                              setPub_dropDown(false);
                              setTd_dropDown(false);
                              setEmp_dropDown(false);
                              setFam_dropDown(true);
                              setRef_dropDown(false);
                              setRes_dropDown(false);
                            }}
                          >
                            Prev
                          </button>
                          <button className="btn btn-sm btn-dark" onClick={handleNext_7}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {res_dropDown && (
                  <div className="row m-2 p-2 mt-2">
                    <div className="col-md-8">
                      <label>
                        Upload Your Resume (PDF)<span className="required">*</span>
                      </label>
                      <input type="file" id="file" placeholder="Upload Your resume" className="form-control" name="resume" onChange={(e) => setSelectedfile(e.target.files[0])} />
                    </div>
                    <div className="col-md-4">
                      <button className="btn btn-sm btn-primary float-right mt-4" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                )}
                {res_dropDown && (
                  <div className="row m-2 p-2 mt-4">
                    <div className="col-md-12" align="center">
                      <button
                        className="btn btn-sm btn-dark"
                        onClick={() => {
                          setBasic_dropDown(false);
                          setEd_dropDown(false);
                          setPub_dropDown(false);
                          setTd_dropDown(false);
                          setEmp_dropDown(false);
                          setFam_dropDown(false);
                          setRef_dropDown(true);
                          setRes_dropDown(false);
                        }}
                      >
                        Prev
                      </button>
                      &nbsp;
                      <button className="btn btn-sm btn-danger" onClick={handleClose}>
                        close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid" style={{ background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)", marginTop: 0, minHeight: "100vh" }}>
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="container" style={{ background: "transparent" }}>
              <div className="jumbotron" style={{ background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                <h1 className="display-4" style={{ color: "#F8F9FA" }}>
                  Join Our Team
                </h1>
                <p className="lead" style={{ color: "#ffffff" }}>
                  Explore exciting career opportunities ...
                </p>
              </div>

              <div className="card" style={{ backgroundColor: "#00000016", borderRadius: 5 }}>
                {
                  alljoblists.length > 0
                    ? alljoblists.map((item, index) => {
                        let posted_on = moment(item.wf_added_on).format("DD/MM/YYYY");
                        return (
                          <div className="row mb-3 m-3" style={{ borderRadius: 10, backgroundColor: "#ffffffc0" }} key={index}>
                            <div className="col-md-12 mt-3 mb-2">
                              <div className="row">
                                <div className="col-md-6">
                                  <h6 className="font-weight-bolder">{item.wf_name}</h6>
                                  <span className="font-weight-bolder text-muted">Position Name: {item.position}</span>
                                  <br />
                                  <span className="text-muted">{item.company_name}</span>
                                  <br />
                                  <span className="text-muted">
                                    Posted On: <span className="font-italic">{posted_on}</span>
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <button
                                    className="btn btn-sm btn-info float-right"
                                    onClick={() => {
                                      setModalIsOpen(true);
                                      setCompanyName(item.company_name);
                                      setCompanyAddress(item.company_address);
                                      setPosition(item.position);
                                      setPosition_id(item.position_id);
                                      setCompany_id(item.company_id);
                                      setBasic_dropDown(true);
                                      setWf_id(item.id);
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null /*  */
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
