import React, {useState, useEffect } from 'react';
import "./ems_pages.css";
 
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function Daily_attendance() {

    const alert = useAlert();
    let history = useHistory();

    const [from_date, setFromDate] = useState(new Date());
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id); 
 
    useEffect(() => {
        getDailyData(from_date);  
        
    }, []);

    const [info, setInfo] = useState({
        emp_id: emp, 
        company_id:Apiconnect.get_company_id(),       
    });
    const { emp_id, company_id  } = info;
    
 
    const markAttendance = async e => {
        e.preventDefault(); 
        // console.log(info); 
        let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
    
        await Apiconnect.postData("employee_punchtime/attendance/",inf_a).then((response) => {
            // console.log(response);             
            alert.success(response.data.message);   
         });
      };

    

      const [catList, setCatList] = useState([]); 
      const [dailyAttendanceList, setDailyAttendanceList] = useState([]); 
      const [list_daily_attendance2, setlist_daily_attendance2] = useState([]); 
      const [list_empleave, setlist_empleave] = useState([]); 
      const [holidayList, setHolidayList] = useState([]);

      const punchtime = () => {
         Apiconnect.getData(`employee_punchtime/getinfo/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCatList(_xtract.reverse());      
         });
      };

      const onChangeDate = (date)=>{
        setFromDate(date)
        getDailyData(date)
      }

      
      const getDailyData = (from_date) => {
        if (from_date != null) {
            var from = format(from_date, "yyyy-MM");
        }
        let list_daily_attendance3=[];
        Apiconnect.getData(`employee_dailydata/getinfo/${emp}/${from}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setDailyAttendanceList(_xtract);  
           
           if(format(new Date(), "yyyy-MM") === from){
            var start = moment().format("D");
           }else{
            var start = moment(from_date).daysInMonth();
           }
        
            const output= [];
            for (var i = start; i >= 1; i--) {
                let isDailyData = _xtract.find(val => parseInt(val.days )=== i)
                if(isDailyData){ 
                    var app_date1=moment(new Date(isDailyData.punch_date2)).format("YYYY-MM-DD"); 

                    output.push(isDailyData); 

                    list_daily_attendance3.push(isDailyData);
                }else{
                    var app_date1=moment(new Date(from_date)).format("YYYY-MM-DD"); 

                    var obj = {
                        break_hours1: "00:00",
                        days:i,
                        emp_id: emp,
                        end_time: "-",
                        id: '',
                        production_hour1: "00:00",
                        punch_date1: moment(i,'Do').format('Do') +' '+moment(from_date).format("MMMM")+' '+moment(from_date).format("Y"),
                        start_time: "-",
                        punch_date2:app_date1,
                    }   
                    // console.log(app_date1); 
                    // list_daily_attendance2[app_date1]=obj;
                    list_daily_attendance3.push(obj);
                }   
            }
            // console.log(list_daily_attendance3); 
            setlist_daily_attendance2(list_daily_attendance3); 
            setDailyAttendanceList(output);   
        });  

        getleavelist_byempid(); 
        getHolidayList();
    }; 
    // console.log(dailyAttendanceList); 

    
    const getleavelist_byempid = async () => {
        await Apiconnect.postData(`leave_manage/get_leave_list_by_emp_id`,{employee_id:emp_id}).then(response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data); 
            setlist_empleave(_xtract);
        }); 
    }

    const getHolidayList = async () => {
        await Apiconnect.getData(`company_holiday/getDataYearly/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setHolidayList(_xtract);
        });
    }

    var sel_from_date1=from_date;
     
    var date_start1=new Date(sel_from_date1.getFullYear(), (sel_from_date1.getMonth()), 1);
    var date_end1=new Date(sel_from_date1.getFullYear(), (sel_from_date1.getMonth()) + 1, 0);
 
    // var date_start2=moment(new Date(date_start1)).format("YYYY-MM-DD");
    // var date_end2=moment(new Date(date_end1)).format("YYYY-MM-DD");

    var cnt_from1 =moment(new Date(date_start1)).format("DD");
    var cnt_to1 = moment(new Date(date_end1)).format("DD");

    var cal_month1=moment(new Date(date_end1)).format("MM");
    var cal_year1=moment(new Date(date_end1)).format("YYYY"); 

    let list_daily_attendance1=[];
    let cnt_col1=0;
    for (let cnt_day1 = cnt_from1; cnt_day1 <= cnt_to1; cnt_day1++) { 
        var date_combine1=cal_year1+'-'+cal_month1+'-'+cnt_day1;
        date_combine1=moment(new Date(date_combine1)).format("YYYY-MM-DD");

        let data_match1 = list_daily_attendance2.find(val1 => val1.punch_date2=== date_combine1);
        let data_match2 = list_empleave.find(val2 => moment(new Date(val2.leave_start_date)).format("YYYY-MM-DD")=== date_combine1);
        let data_match3 = holidayList.find(val3 => moment(new Date(val3.end_date)).format("YYYY-MM-DD")=== date_combine1);

        // console.log(date_combine1,data_match3);
        
        let cal_date='';
        let cal_time_in='';
        let cal_time_out='';
        let cal_production_hour='';
        if(typeof data_match1!='undefined'){   
            cal_date=data_match1.punch_date1;
            cal_time_in=data_match1.start_time;
            cal_time_out=data_match1.end_time;
            cal_production_hour=data_match1.production_hour1;
        }

        if(typeof data_match2!='undefined'){ 
            cal_date=data_match2.leave_start_date;
            cal_time_in='NA';
            cal_time_out='NA';
            cal_production_hour='Leave';
        }
        
        if(typeof data_match3!='undefined'){ 
            cal_date=data_match3.leave_start_date;
            cal_time_in='NA';
            cal_time_out='NA';
            cal_production_hour=data_match3.name;
        }

        list_daily_attendance1[cnt_col1]=[
            date_combine1,
            cal_date,
            cal_time_in,
            cal_time_out,
            cal_production_hour
        ];
        cnt_col1++;
    }

    // console.log(holidayList);
    var cnt_srno1=1; 

    return (
        <>

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row"> 
                        <div className="col-md-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Your Attendance</h4>
                                    </div>
                                </div>
                                <div className="row" style={{margin:'10px'}}>
                                    <div className="col-md-3">
                                        Months :
                                        <DatePicker
                                            selected={from_date}
                                            onChange={(date) => onChangeDate(date)}
                                            maxDate={new Date()}
                                            dateFormat="MM/yyyy"
                                            className="form-control"
                                            showMonthYearPicker
                                            dropdownMode="select"
                                        />
                                    </div>
                                </div>

                                <div className="iq-card-body padLRTzero">
                                
                                    <div className="table-responsive">
                                    
                                    <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                        <thead>
                                            <tr className="clr">
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>In Time</th>
                                                <th>Out Time</th> 
                                                <th>Total Hours</th>    
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {  
                                                list_daily_attendance1.map((val_att1, key) => {          
                                                    // console.log(val_att1);
                                                    var punch_date1=val_att1['0'];
                                                    var start_time=val_att1['2'];
                                                    var end_time=val_att1['3'];
                                                    var production_hour=val_att1['4']; 
                                                    var production_hour1=val_att1['4']; 

                                                    if(start_time=='-'){ start_time=''; }
                                                    if(end_time=='-'){ end_time=''; }

                                                    var cal_datenew=moment(new Date(punch_date1)).format("DD-MM-YYYY");

                                                    // var production_hour1 = '';   
                                                    if(start_time!='' && end_time!=''){ 
                                                        var selstart_datetime=new Date(punch_date1+' '+start_time+':00');
                                                        var selen_datetime=new Date(punch_date1+' '+end_time+':00');

                                                        var cal_hour_diff = Math.abs(selstart_datetime - selen_datetime) / 36e5;
                                                        production_hour1=parseFloat(cal_hour_diff).toFixed(2);

                                                        // console.log(selstart_datetime+' == '+selen_datetime+' === '+production_hour1);
                                                    }

                                                    if (production_hour1 >= '08:00') {
                                                        var production_hour = <div className=" badge badge-pill badge-success">{production_hour1}</div>;
                                                    }else if(production_hour1 <= '08:00' && production_hour1 >= '04:00'){
                                                        var production_hour = <div className=" badge badge-pill badge-warning">{production_hour1}</div>;
                                                    }else{
                                                        var production_hour = <div className=" badge badge-pill badge-danger">{production_hour1}</div>;
                                                    }

                                                    if(start_time=='NA'){
                                                        production_hour = <div className=" badge badge-pill badge-danger">{production_hour1}</div>;
                                                    }
                                                   
                                                    return (
                                                    <tr key={key}>
                                                        <td>{cnt_srno1++}</td>
                                                        <td>{cal_datenew}</td>
                                                        <td>{start_time}</td>
                                                        <td>{end_time}</td> 
                                                        <td>{production_hour}</td>   
                                                    </tr>
                                                    );  
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    </div>  
                                </div>
                            </div> 
                        </div>
                    </div>  
                </div>
            </div>  
        </>
    )
}

export default Daily_attendance
