import React, { useState, useEffect } from "react";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect";
import Modal from "react-modal";
import moment from "moment";
import { Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { pageStyles } from "../../globalStyles";

/* ---Modal--- */
const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "100%",
  },
};

const Work_force_request = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(localuser.id);
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);
  let company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const today = new Date();
  const numberOfDaysToAdd = 3;
  const date = today.setDate(today.getDate());
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [workforce_list, setWorkfforce_list] = useState([]);
  const [editId, setEditId] = useState("");

  const [desList, setDesList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [wf_id, setWf_id] = useState("");
  const [attList, setAttList] = useState([]);
  const [remark, setRemark] = useState("");

  const [inputFields, setInputFields] = useState({
    name: "",
    dor: defaultValue,
    position: "",
    attrition: "",
    onboarding: "",
    department: "",
    location: "",
    unit_details: "",
    reporting_auth: "",
    working_days: "",
    candidate_pref: "",
    location_type: "",
    salary_range: "",
    additional_Perquisites: "",
    vacancies: "",
    hiring_process: "",
    q1: "",
    q2: "",
    q3: "",
    exp1: "",
    exp2: "",
    justification1: "",
    justification2: "",
    justification3: "",
    key_deliverables1: "",
    key_deliverables2: "",
    key_deliverables3: "",
    key_deliverables4: "",
    key_deliverables5: "",
    other_requisites1: "",
    other_requisites2: "",
    other_requisites3: "",
    raised_by: user_id,
    approving_auth: "",
    added_by: user_id,
    company_id: company_id,
  });

  useEffect(() => {
    work_force_requisition_getall();
    getDesList();
    getDeptList();
    getEmployeeList();
    get_hierarchy_level();
    get_work_force_mst_authority_request_by_empId();
    getAttritionList();
  }, []);

  const { dor, position, attrition, onboarding, department, location, unit_details, reporting_auth, working_days, candidate_pref, location_type, salary_range, additional_Perquisites, vacancies, hiring_process, q1, q2, q3, exp1, exp2, justification1, justification2, justification3, key_deliverables1, key_deliverables2, key_deliverables3, key_deliverables4, key_deliverables5, other_requisites1, other_requisites2, other_requisites3, raised_by, approving_auth, name } = inputFields;

  const onInputChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const getAttritionList = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/workForce_mst_attrition_getall`).then((res) => {
      setFullScreenLoader(false);
      setAttList(Apiconnect.decrypt_obj(res.data.data));
    });
  };

  const getDesList = () => {
    setFullScreenLoader(true);
    Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
      setFullScreenLoader(false);
      let _xtract = response.data.data;
      setDesList(_xtract.reverse());
    });
  };

  const getDeptList = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setDeptList(_xtract.reverse());
    });
  };

  const getEmployeeList = () => {
    setFullScreenLoader(true);
    Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpList(_xtract.reverse());
      setFullScreenLoader(false);
    });
  };

  const get_hierarchy_level = () => {
    Apiconnect.postData(`work_force/work_force_authority_get_hierarchy_level/${user_id}`).then((res) => {
      let _xtract = res.data.data;
      let id = _xtract[0]?.wfa_user_type_code;
      setWf_id(id);
    });
  };

  const handleSubmit = () => {
    setFullScreenLoader(true);
    if (editId !== "") {
      if (name !== "" && dor !== "" && position !== "" && attrition !== "" && onboarding !== "" && department !== "" && location !== "" && unit_details !== "" && working_days !== "" && candidate_pref !== "" && location_type !== "" && salary_range !== "" && vacancies !== "" && hiring_process !== "" && q1 !== "" && exp1 !== "" && justification1 !== "" && key_deliverables1 !== "" && other_requisites1 !== "" && raised_by !== "" && approving_auth !== "") {
        Apiconnect.postData(`work_force/work_force_requisition_update/${company_id}/${editId}`, inputFields).then((res) => {
          setInputFields({
            name: "",
            dor: "",
            position: "",
            attrition: "",
            onboarding: "",
            department: "",
            location: "",
            unit_details: "",
            reporting_auth: "",
            working_days: "",
            candidate_pref: "",
            location_type: "",
            salary_range: "",
            additional_Perquisites: "",
            vacancies: "",
            hiring_process: "",
            q1: "",
            q2: "",
            q3: "",
            exp1: "",
            exp2: "",
            justification1: "",
            justification2: "",
            justification3: "",
            key_deliverables1: "",
            key_deliverables2: "",
            key_deliverables3: "",
            key_deliverables4: "",
            key_deliverables5: "",
            other_requisites1: "",
            other_requisites2: "",
            other_requisites3: "",
            raised_by: "",
            approving_auth: "",
            added_by: "",
          });
          setModalIsOpen(false);
          work_force_requisition_getall();
          alert.success("Updated");
          setFullScreenLoader(false);
        });
      } else {
        alert.error("Some Input fields are empty");
        alert.success("Error");
        setFullScreenLoader(false);
      }
    } else {
      if (name !== "" && dor !== "" && position !== "" && attrition !== "" && onboarding !== "" && department !== "" && location !== "" && unit_details !== "" && working_days !== "" && candidate_pref !== "" && location_type !== "" && salary_range !== "" && vacancies !== "" && hiring_process !== "" && q1 !== "" && exp1 !== "" && justification1 !== "" && key_deliverables1 !== "" && other_requisites1 !== "" && raised_by !== "" && approving_auth !== "") {
        Apiconnect.postData(`work_force/work_force_requisition_create/${company_id}`, inputFields).then((res) => {
          setInputFields({
            name: "",
            dor: "",
            position: "",
            attrition: "",
            onboarding: "",
            department: "",
            location: "",
            unit_details: "",
            reporting_auth: "",
            working_days: "",
            candidate_pref: "",
            location_type: "",
            salary_range: "",
            additional_Perquisites: "",
            vacancies: "",
            hiring_process: "",
            q1: "",
            q2: "",
            q3: "",
            exp1: "",
            exp2: "",
            justification1: "",
            justification2: "",
            justification3: "",
            key_deliverables1: "",
            key_deliverables2: "",
            key_deliverables3: "",
            key_deliverables4: "",
            key_deliverables5: "",
            other_requisites1: "",
            other_requisites2: "",
            other_requisites3: "",
            raised_by: "",
            approving_auth: "",
            added_by: "",
          });
          setModalIsOpen(false);
          work_force_requisition_getall();
          alert.success("Created");
          setFullScreenLoader(false);
        });
      } else {
        alert.error("Input fields are empty");
        alert.success("Error");
        setFullScreenLoader(false);
      }
    }
  };

  const work_force_requisition_getall = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_requisition_getall/${company_id}`, { company_id: company_id })
      .then((res) => {
        setFullScreenLoader(false);
        setWorkfforce_list(Apiconnect.decrypt_obj(res.data.data));
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (id) => {
    const filtered = workforce_list.find((item) => item.wf_pk_id === id);
    setModalIsOpen(true);

    setInputFields({
      name: filtered.name,
      dor: filtered.wf_date_of_requisition,
      position: filtered.wf_position,
      attrition: filtered.wf_attrition,
      onboarding: filtered.wf_onboarding_date,
      department: filtered.wf_department,
      location: filtered.wf_location,
      unit_details: filtered.wf_unit_details,
      reporting_auth: filtered.wf_reporting_authority,
      working_days: filtered.wf_working_days,
      candidate_pref: filtered.wf_candidate_pref,
      location_type: filtered.wf_location_type,
      salary_range: filtered.wf_salary_range,
      additional_Perquisites: filtered.wf_add_perquisites,
      vacancies: filtered.wf_no_of_vacancies,
      hiring_process: filtered.wf_hiring_process,
      q1: filtered.wf_qualification_1,
      q2: filtered.wf_qualification_2,
      q3: filtered.wf_qualification_3,
      exp1: filtered.wf_experience_1,
      exp2: filtered.wf_experience_2,
      justification1: filtered.wf_justification_1,
      justification2: filtered.wf_justification_2,
      justification3: filtered.wf_justification_3,
      key_deliverables1: filtered.wf_key_deliverables_1,
      key_deliverables2: filtered.wf_key_deliverables_2,
      key_deliverables3: filtered.wf_key_deliverables_3,
      key_deliverables4: filtered.wf_key_deliverables_4,
      key_deliverables5: filtered.wf_key_deliverables_5,
      other_requisites1: filtered.wf_other_requisites_1,
      other_requisites2: filtered.wf_other_requisites_2,
      other_requisites3: filtered.wf_other_requisites_3,
      raised_by: filtered.wf_raised_by,
      approving_auth: filtered.wf_approving_authority,
      added_by: filtered.wf_added_by,
    });

    setEditId(id);
  };

  const handleDelete = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_requisition_delete`, { company_id: company_id, id: id }).then((res) => {
      work_force_requisition_getall();
      alert.success("Deleted");
      setFullScreenLoader(false);
    });
  };

  const handleApproval = (id) => {
    let data_ins = {
      wfar_workforce_id: id,
      wfar_user_id: user_id,
      wfar_status_id: 1,
      wfar_remark: remark,
      wfar_company_id: company_id,
    };
    if (remark !== "") {
      Apiconnect.postData(`work_force/work_force_authority_request`, data_ins).then((res) => {
        alert.success(res.data.message);
        setRemark("");
      });
    } else {
      alert.error("Please Enter your remark");
    }
  };

  const [work_force_req_list, setWork_force_req_list] = useState([]);
  const get_work_force_mst_authority_request_by_empId = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_mst_authority_request_by_empId/${user_id}`).then((res) => {
      setWork_force_req_list(res.data.data);
      setFullScreenLoader(false);
    });
  };

  const handleApproveWorkForce = (id, vac_id) => {
    // setFullScreenLoader(true)
    if (remark !== "") {
      Apiconnect.postData(`work_force/work_force_mst_authority_approval/${id}`, {
        wf_pk_id: vac_id,
        wfar_remark: remark,
        company_id: company_id,
      })
        .then((res) => {
          get_work_force_mst_authority_request_by_empId();
          setRemark("");
          alert.success(res.data.message);
        })
        .catch((err) => {
          alert.error("Can't Approve");
        });
    } else {
      alert.error("Please Enter Remark");
    }
  };

  const handleRejectWorkForce = (id, vac_id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_mst_authority_reject/${id}`, { wf_pk_id: vac_id })
      .then((res) => {
        get_work_force_mst_authority_request_by_empId();
        setFullScreenLoader(false);
        alert.success("Rejected");
      })
      .catch((err) => {
        alert.error("Can't Reject");
      });
  };

  return (
    <>
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}

      <div className="content-page" style={pageStyles.main_bg}>
        {/* ---Modal--- */}
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between model_head">
              <div className="iq-header-title">
                <h4 className="card-title ">Create Vacancies</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="row m-2 p-2">
                    <div className="col-md-4 mb-3">
                      <label>Vacancy Name</label>
                      <input type="text" className="form-control" name="name" placeholder="Enter Vacancy Name" value={name} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Position</label>
                      <select className="form-control" name="position" value={position} onChange={(e) => onInputChange(e)}>
                        <option value="">--Select--</option>
                        {desList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Date of Requisition</label>
                      <input type="date" className="form-control" name="dor" value={dor} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Additional/Replacement</label>
                      <select className="form-control" name="attrition" value={attrition} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {attList.map((item, index) => {
                          return (
                            <option key={index} value={item.wfma_pk_id}>
                              {item.wfma_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label>Expected Date of onboarding</label>
                      <input type="date" className="form-control" placeholder="Date of Requisition" name="onboarding" value={onboarding} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Department</label>
                      {/* <input type='text' className='form-control' placeholder='Department' name='department' value={department} onChange={e => onInputChange(e)} autoComplete='off'/>  */}
                      <select className="form-control" name="department" value={department} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {deptList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Location (Exact)</label>
                      <input type="text" className="form-control" placeholder="Enter Location" name="location" value={location} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Unit details (Additional in any)</label>
                      <input type="text" className="form-control" placeholder="Enter Unit details" name="unit_details" value={unit_details} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Reporting Authority</label>
                      {/* <input type='text' className='form-control' placeholder='Enter Reporting Authority' name='reporting_auth' value={reporting_auth} onChange={e => onInputChange(e)} autoComplete='off'/> */}
                      <select className="form-control" name="reporting_auth" value={reporting_auth} onChange={(e) => onInputChange(e)}>
                        <option value="" disabled selected>
                          --Select--
                        </option>
                        {empList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.first_name + " " + item.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Working days/Time</label>
                      <input type="text" className="form-control" placeholder="Enter Working days/Time" name="working_days" value={working_days} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Candidate Preference</label>
                      <select className="form-control" name="candidate_pref" value={candidate_pref} onChange={(e) => onInputChange(e)}>
                        <option value="">--Select--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Local/Outstation</label>
                      <select className="form-control" name="location_type" value={location_type} onChange={(e) => onInputChange(e)}>
                        <option value="">--Select--</option>
                        <option value="Local">Local</option>
                        <option value="Outstation">Outstation</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Salary Range</label>
                      <input type="number" className="form-control" placeholder="Enter Salary Range" name="salary_range" value={salary_range} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Any Additional Perquisites</label>
                      <input type="text" className="form-control" placeholder="Enter Additional Perquisites" name="additional_Perquisites" value={additional_Perquisites} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Number of Vacancies</label>
                      <input type="number" className="form-control" placeholder="Enter Number of Vacancies" name="vacancies" value={vacancies} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>Hiring process (incase different from the standard process shared in Recruitment policy)</label>
                      <input type="text" className="form-control" placeholder="Hiring process" name="hiring_process" value={hiring_process} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Qualification-1</label>
                          <input type="text" className="form-control" placeholder="Enter Qualification" name="q1" value={q1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Qualification-2</label>
                          <input type="text" className="form-control" placeholder="Enter Qualification" name="q2" value={q2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Qualification-3</label>
                          <input type="text" className="form-control" placeholder="Enter Qualification" name="q3" value={q3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-6 mb-3">
                          <label>Experience-1</label>
                          <input type="text" className="form-control" placeholder="Enter Experience" name="exp1" value={exp1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Experience-2</label>
                          <input type="text" className="form-control" placeholder="Enter Experience" name="exp2" value={exp2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Justification for adding the position-1</label>
                          <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification1" value={justification1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Justification for adding the position-2 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification2" value={justification2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Justification for adding the position-3 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Justification for adding the position" name="justification3" value={justification3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables1" value={key_deliverables1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-2 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables2" value={key_deliverables2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-3 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables3" value={key_deliverables3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-4 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables4" value={key_deliverables4} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Key Deliverables-5 (If Any)</label>
                          <input type="text" className="form-control" placeholder="Enter Key Deliverables" name="key_deliverables5" value={key_deliverables5} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="row bbox">
                        <div className="col-md-4 mb-3">
                          <label>Any other requisites to be considered while screening </label>
                          <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites1" value={other_requisites1} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Any other requisites to be considered while screening-2 </label>
                          <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites2" value={other_requisites2} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>Any other requisites to be considered while screening-3 </label>
                          <input type="text" className="form-control" placeholder="Any other requisites" name="other_requisites3" value={other_requisites3} onChange={(e) => onInputChange(e)} autoComplete="off" />
                        </div>
                      </div>
                    </div>

                    {/* <div className='col-md-6 mb-3'>
                                        <label>Raised by</label>
                                        <input type='text' className='form-control' placeholder='Raised by' name='raised_by' value={raised_by} onChange={e => onInputChange(e)} autoComplete='off'/> 
                                    </div> */}
                    <div className="col-md-6 mb-3">
                      <label>Approving Authority</label>
                      <input type="text" className="form-control" placeholder="Approving Authority" name="approving_auth" value={approving_auth} onChange={(e) => onInputChange(e)} autoComplete="off" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-2 p-2 mt-4">
                <div className="col-md-12" align="center">
                  <button className="btn btn-sm btn-success" onClick={handleSubmit}>
                    {editId ? "Update" : "Create"}
                  </button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger" onClick={() => setModalIsOpen(false)}>
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <div className="container-fluid">
          <div className="iq-card" style={pageStyles.main_container}>
            <div className="iq-card-header" style={pageStyles.card_head}>
              <div className="iq-header-title">
                <h4 className="card-title text-white " style={pageStyles.h4_bg}>
                  Work Force Vacancy Requests
                </h4>
              </div>
            </div>
            {/* ----------------------------------------For id = 3------------------------------- */}
            {wf_id === 3 && (
              <div className="iq-card-body">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <input type="text" className="form-control" placeholder="Enter Approval Remarks" name="remark" value={remark} onChange={(e) => setRemark(e.target.value)} />
                  </div>
                  <div className="col-md-8">
                    {/* <button className='btn btn-sm btn-success float-right mr-2' onClick={() => handleApproval()}>Send to approval</button> */}
                    <button className="btn btn-sm btn-primary float-right mr-2" onClick={() => setModalIsOpen(true)}>
                      Create New
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered mt-1 center" role="grid" style={pageStyles.main_container}>
                      <thead className="thead-light">
                        <tr className="clr">
                          <th scope="col">#</th>
                          <th scope="col">Position</th>
                          <th scope="col">Requisition Date</th>
                          <th scope="col">Onboarding Date</th>
                          <th scope="col">Vacancy Type</th>
                          <th scope="col">Vacancy Count</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {workforce_list.map((item, index) => {
                          let dor = moment(item.wf_date_of_requisition).format("DD/MM/YYYY");
                          let onbording = moment(item.wf_onboarding_date).format("DD/MM/YYYY");

                          let btn = null;
                          if (item.wf_status == 0) {
                            btn = (
                              <h5>
                                <span className="badge badge-warning">Initiated</span>
                              </h5>
                            );
                          } else if (item.wf_status == 1) {
                            btn = (
                              <h5>
                                <span className="badge badge-success">Approved</span>
                              </h5>
                            );
                          } else {
                            btn = (
                              <h5>
                                <span className="badge badge-danger">Rejected</span>
                              </h5>
                            );
                          }

                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.wf_position}</td>
                              <td>{dor}</td>
                              <td>{onbording}</td>
                              <td>{item.attrition}</td>
                              <td>{item.wf_no_of_vacancies}</td>
                              <td>{btn}</td>
                              <td>
                                {item.wf_status == 0 && (
                                  <button className="btn btn-info btn-sm mr-2 mb-2" onClick={() => handleEdit(item.wf_pk_id)}>
                                    Edit
                                  </button>
                                )}
                                {/* <button className='btn btn-danger btn-sm' onClick={() => handleDelete(item.wf_pk_id)}>Delete</button> */}
                                {item.wf_status == 0 && (
                                  <button className="btn btn-primary btn-sm mr-2 mb-2" onClick={() => handleApproval(item.wf_pk_id)}>
                                    Request For Approval
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {/* ----------------------------------------For id = 2------------------------------- */}
            {wf_id === 2 && (
              <div className="iq-card-body">
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <input type="text" className="form-control" placeholder="Enter Approval Remarks" name="remark" value={remark} onChange={(e) => setRemark(e.target.value)} />
                  </div>
                  <div className="col-md-12">
                    <table className="table  table-bordered mt-1 center" role="grid" style={pageStyles.main_container}>
                      <thead className="thead-light">
                        <tr className="clr">
                          <th scope="col">#</th>
                          <th scope="col">Position</th>
                          <th scope="col">Applied On</th>
                          <th scope="col">Date of onboarding</th>
                          <th scope="col">Attrition</th>
                          <th scope="col">Vacancies</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {work_force_req_list.length > 0 ? (
                          work_force_req_list.map((item, index) => {
                            let dor = moment(item.wf_date_of_requisition).format("DD/MM/YYYY");
                            let onbording = moment(item.wf_onboarding_date).format("DD/MM/YYYY");

                            let btn = null;
                            if (item.wfar_status_id == 0) {
                              btn = (
                                <h5>
                                  <span className="badge badge-warning">Pending</span>
                                </h5>
                              );
                            } else if (item.wfar_status_id == 1) {
                              btn = (
                                <h5>
                                  <span className="badge badge-success">Approved</span>
                                </h5>
                              );
                            } else {
                              btn = (
                                <h5>
                                  <span className="badge badge-danger">Rejected</span>
                                </h5>
                              );
                            }

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.designation}</td>
                                <td>{dor}</td>
                                <td>{onbording}</td>
                                <td>{item.attrition}</td>
                                <td>{item.wf_no_of_vacancies}</td>
                                <td>{btn}</td>
                                <td>
                                  <button className="btn btn-info btn-sm mr-2 mb-2" onClick={() => handleApproveWorkForce(item.wfar_pk_id, item.wfar_workforce_id)}>
                                    Approve
                                  </button>
                                  <button className="btn btn-danger btn-sm mr-2 mb-2" onClick={() => handleRejectWorkForce(item.wfar_pk_id, item.wfar_workforce_id)}>
                                    Reject
                                  </button>
                                  {/* <button className='btn btn-danger btn-sm' onClick={() => handleDelete(item.wf_pk_id)}>Delete</button> */}
                                  {/* <button className='btn btn-success btn-sm mr-2 mb-2' onClick={() => handleApproval(item.wf_pk_id)}>Approve</button> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <th colSpan={10} style={{ textAlign: "center" }}>
                              {" "}
                              No Data Found..{" "}
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {/* ----------------------------------------For id = 1------------------------------- */}
            {wf_id === 1 && (
              <div className="iq-card-body">
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <input type="text" className="form-control" placeholder="Enter Approval Remarks" name="remark" value={remark} onChange={(e) => setRemark(e.target.value)} />
                  </div>
                  <div className="col-md-12">
                    <table className="table table-bordered mt-1 center" role="grid" style={pageStyles.main_container}>
                      <thead className="thead-light">
                        <tr className="clr">
                          <th scope="col">#</th>
                          <th scope="col">Position</th>
                          <th scope="col">Applied On</th>
                          <th scope="col">Date of onboarding</th>
                          <th scope="col">Attrition</th>
                          <th scope="col">Vacancies</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {work_force_req_list.length > 0 ? (
                          work_force_req_list.map((item, index) => {
                            let dor = moment(item.wf_date_of_requisition).format("DD/MM/YYYY");
                            let onbording = moment(item.wf_onboarding_date).format("DD/MM/YYYY");

                            let btn = null;
                            if (item.wfar_status_id == 0) {
                              btn = (
                                <h5>
                                  <span className="badge badge-warning">Pending</span>
                                </h5>
                              );
                            } else if (item.wfar_status_id == 1) {
                              btn = (
                                <h5>
                                  <span className="badge badge-success">Approved</span>
                                </h5>
                              );
                            } else {
                              btn = (
                                <h5>
                                  <span className="badge badge-danger">Rejected</span>
                                </h5>
                              );
                            }

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.designation}</td>
                                <td>{dor}</td>
                                <td>{onbording}</td>
                                <td>{item.attrition}</td>
                                <td>{item.wf_no_of_vacancies}</td>
                                <td>{btn}</td>
                                <td>
                                  <button className="btn btn-info btn-sm mr-2 mb-2" onClick={() => handleApproveWorkForce(item.wfar_pk_id, item.wfar_workforce_id)}>
                                    Approve
                                  </button>
                                  <button className="btn btn-danger btn-sm mr-2 mb-2" onClick={() => handleRejectWorkForce(item.wfar_pk_id, item.wfar_workforce_id)}>
                                    Reject
                                  </button>
                                  {/* <button className='btn btn-danger btn-sm' onClick={() => handleDelete(item.wf_pk_id)}>Delete</button> */}
                                  {/* <button className='btn btn-success btn-sm mr-2 mb-2' onClick={() => handleApproval(item.wf_pk_id)}>Approve</button> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <th colSpan={10} style={{ textAlign: "center" }}>
                              {" "}
                              No Data Found..{" "}
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Work_force_request;
