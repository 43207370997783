import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Input,
  SelectFromOptions,
  CustomModal,
  Table,
} from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import useFetchApi from "../../../hooks/useFetchApi";
import {
  tableDocsHeader,
  tableHeader,
} from "../../../components/employee_onboarding/employee_boarding";
import { AiFillEye } from "react-icons/ai";
import { pageStyles } from "../../../globalStyles";
import TableRow from "../../../components/employee_onboarding/employee_boarding/TableRow";
import toast from "react-hot-toast";
import { MdConstruction } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import { RiUserAddFill, RiSendPlane2Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import moment from "moment";

const Employee_boarding = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let user_id = Apiconnect.decrypt(localuser.id);
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);

  let history = useHistory();
  const [dobDate, setDobDate] = useState(new Date());

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [id, setId] = useState("");
  const [verifyId, setVerifyId] = useState("");
  const [list, setList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [doc, setDoc] = useState("");
  const [onboardingDocList, setOnboardingDocList] = useState([]);
  const [applicant, setApplicant] = useState({});
  //onboard
  const [empCode, setEmp_code] = useState([]);
  const [catList, setCategory] = useState([]);
  const [shiftList, setShift] = useState([]);
  const [empList, setEmp] = useState([]);
  const [empTypeList, setEmpType] = useState([]);
  const [empGroupList, setEmpGroup] = useState([]);
  const [empDesignationList, setEmpDesignationList] = useState([]);
  const [templateList, setTemplate] = useState([]);
  const [list_employee, setlist_employee] = useState([]);
  const [info, setInfo] = useState({
    company_location: "",
    emp_code: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    gender: "",
    date_of_birth: "",
    emp_category: "",
    employment_type_id: "",
    emp_group: "",
    emp_designation: "",
    emp_shift: "",
    company_id: Apiconnect.get_company_id(),
    reporting_officer: "",
    pm_userId: "",
    emp_manage_multiple_schools: "",
  });
  const [companyList, setCompanyList] = useState([]);
  const {
    company_location,
    emp_code,
    first_name,
    middle_name,
    last_name,
    phone,
    email,
    password,
    gender,
    date_of_birth,
    emp_category,
    employment_type_id,
    emp_group,
    emp_designation,
    emp_shift,
    reporting_officer,
    pm_userId,
    emp_manage_multiple_schools,
  } = info;

  useEffect(() => {
    getacceptedApplicantInfo();
    getDocList();
    getShift();
    getEmp();
    getTemplate();
    getEmpType();
    getEmpGroup();
    getEmpDesignation();
    employee_code();
    getcompanyList();
  }, []);

  //onboarding functions ---- start
  const employee_code = () => {
    Apiconnect.getData(
      `employee/getEmployeeCode/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmp_code(_xtract[0].prefix + "/" + _xtract[0].count);
    });
  };

  const getCategory = (emp_group) => {
    Apiconnect.getData(
      `company_category/getAllByGroupId/${Apiconnect.get_company_id()}/${emp_group}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setCategory(_xtract.reverse());
    });
  };

  const getShift = () => {
    Apiconnect.getData(`shift/getall/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setShift(_xtract.reverse());
      }
    );
  };

  const getEmp = () => {
    Apiconnect.getData(
      `employee/getusers_all/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      // console.log(_xtract);
      setEmp(_xtract.reverse());
    });
  };

  const getEmpType = () => {
    Apiconnect.getData(`common/getallemploymenttype`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpType(_xtract.reverse());
    });
  };

  const getEmpGroup = () => {
    Apiconnect.getData(
      `common/getallemploymenttypeById/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmpGroup(_xtract.reverse());
    });
  };

  const getEmpDesignation = () => {
    Apiconnect.getData(
      `designation/getall/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = response.data.data;
      setEmpDesignationList(_xtract.reverse());
    });
  };

  const getTemplate = () => {
    Apiconnect.getData(
      `employee_salary_template/getAllTemplate/${Apiconnect.get_company_id()}`
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setTemplate(_xtract.reverse());
    });
  };

  const onInputChange = (e) => {
    if (e.target.name === "emp_group") {
      getCategory(e.target.value);
    }

    if (e.target.name === "emp_code") {
      setNumMsg({ msg: "" });
    }

    if (e.target.name === "phone") {
      if (e.target.value.length > 10) {
        setNumMsg2({ msg2: "" });
        return false;
      } else {
        setNumMsg2({ msg2: "Number Should 10 Digits" });
      }
    }

    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const [num_msg2, setNumMsg2] = useState({ msg2: "" });
  const { msg2 } = num_msg2;

  const [num_msg, setNumMsg] = useState({ msg: "" });
  const { msg } = num_msg;

  function refreshPage() {
    window.location.reload();
  }

  const getcompanyList = () => {
    Apiconnect.getData(`company/getall`).then((res) => {
      let data = Apiconnect.decrypt_obj(res.data.data);
      setCompanyList(data);
    });
  };
  //onboarding functions ---- end

  const getacceptedApplicantInfo = () => {
    Apiconnect.postData(
      `selected_applicants/getallAcceptedApplicantsList/${Apiconnect.get_company_id()}`
    ).then((res) => {
      let _xtract = Apiconnect.decrypt_obj(res.data.data);
      setList(_xtract);
    });
  };
  const singleApplicant =
    list.length > 0 && list.find((item) => item.am_pk_id === id);
  const rowData = [
    {
      id: 1,
      name: "Resume/CV",
      hrefData: singleApplicant?.ad_cv,
      status: singleApplicant?.ad_cv_verify,
      verify: "ad_cv_verify",
    },
    {
      id: 2,
      name: "Copy of appointment letter / offer letter",
      hrefData: singleApplicant?.ad_offer_letter,
      status: singleApplicant?.ad_offer_verify,
      verify: "ad_offer_verify",
    },
    {
      id: 3,
      name: "Joining Letter",
      hrefData: singleApplicant?.ad_joining_letter,
      status: singleApplicant?.ad_joining_letter_verify,
      verify: "ad_joining_letter_verify",
    },
    {
      id: 4,
      name: "Metric (Secondary Education)",
      hrefData: singleApplicant?.ad_matric,
      status: singleApplicant?.ad_matric_verify,
      verify: "ad_matric_verify",
    },
    {
      id: 5,
      name: "Intermediate (Sr. Secondary Education)",
      hrefData: singleApplicant?.ad_intermediate,
      status: singleApplicant?.ad_intermediate_verify,
      verify: "ad_intermediate_verify",
    },
    {
      id: 6,
      name: "Graduation",
      hrefData: singleApplicant?.ad_grad,
      status: singleApplicant?.ad_grad_verify,
      verify: "ad_grad_verify",
    },
    {
      id: 7,
      name: "Post-Graduation",
      hrefData: singleApplicant?.ad_postGrad,
      status: singleApplicant?.ad_postGrad_verify,
      verify: "ad_postGrad_verify",
    },
    {
      id: 8,
      name: "Professional / Technical",
      hrefData: singleApplicant?.ad_prof,
      status: singleApplicant?.ad_prof_verify,
      verify: "ad_prof_verify",
    },
    {
      id: 9,
      name: "Others",
      hrefData: singleApplicant?.ad_others,
      status: singleApplicant?.ad_others_verify,
      verify: "ad_others_verify",
    },
    {
      id: 10,
      name: "Passport size recent color photos",
      hrefData: singleApplicant?.ad_photo,
      status: singleApplicant?.ad_photo_verify,
      verify: "ad_photo_verify",
    },
    {
      id: 11,
      name: "Age Proof",
      hrefData: singleApplicant?.ad_ageProof,
      status: singleApplicant?.ad_ageProof_verify,
      verify: "ad_ageProof_verify",
    },
    {
      id: 12,
      name: "Address Proof",
      hrefData: singleApplicant?.ad_addressProof,
      status: singleApplicant?.ad_addressProof_verify,
      verify: "ad_addressProof_verify",
    },
    {
      id: 13,
      name: "Id Proof",
      hrefData: singleApplicant?.ad_idProof,
      status: singleApplicant?.ad_idProof_verify,
      verify: "ad_idProof_verify",
    },
    {
      id: 14,
      name: "Medical fitness Certificate",
      hrefData: singleApplicant?.ad_medicalFitness,
      status: singleApplicant?.ad_medicalFitness_verify,
      verify: "ad_medicalFitness_verify",
    },
    {
      id: 15,
      name: "References",
      hrefData: singleApplicant?.ad_references,
      status: singleApplicant?.ad_references_verify,
      verify: "ad_references_verify",
    },
    {
      id: 16,
      name: "Experience Cirtificate",
      hrefData: singleApplicant?.ad_experienceLetter,
      status: singleApplicant?.ad_experienceLetter_verify,
      verify: "ad_experienceLetter_verify",
    },
    {
      id: 17,
      name: "Relieving Certificate",
      hrefData: singleApplicant?.ad_relievingLetter,
      status: singleApplicant?.ad_relievingLetter_verify,
      verify: "ad_relievingLetter_verify",
    },
    {
      id: 18,
      name: "Salary Slip",
      hrefData: singleApplicant?.ad_salarySlip,
      status: singleApplicant?.ad_salarySlip_verify,
      verify: "ad_salarySlip_verify",
    },
    {
      id: 19,
      name: "Deduction details",
      hrefData: singleApplicant?.ad_epf,
      status: singleApplicant?.ad_epf_verify,
      verify: "ad_epf_verify",
    },
    {
      id: 20,
      name: "Medical fitness Certificate",
      hrefData: singleApplicant?.ad_medical,
      status: singleApplicant?.ad_medical_verify,
      verify: "ad_medical_verify",
    },
  ];

  const handleVerify = (verify) => {
    let dataIns = {};
    dataIns[verify] = 1;
    try {
      Apiconnect.postData(
        `selected_applicants/verifyApplicantDocs/${verifyId}`,
        dataIns
      );
      toast.success("Verified");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const getDocList = () => {
    Apiconnect.postData(
      `onboarding/getAllDocs/${Apiconnect.get_company_id()}`
    ).then((res) => {
      const data = Apiconnect.decrypt_obj(res.data.data);
      setDocList(data);
    });
  };

  const onboardingDocRequest = (idd) => {
    Apiconnect.postData(`onboarding/shareOnBoardingDocs/${idd}`)
      .then((res) => {
        toast.success("Success");
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });
  };

  const getOnboardingDocs = (id) => {
    Apiconnect.postData(`onboarding/getallonboardingDocs/${id}`).then((res) => {
      let data = Apiconnect.decrypt_obj(res.data.data);
      setOnboardingDocList(data);
    });
  };

  let coc_st = null;
  if (onboardingDocList.length > 0) {
    if (onboardingDocList[0].od_codeOfConductVerify === 0) {
      coc_st = (
        <span className="badge badge-pill badge-warning">Not Verified Yet</span>
      );
    } else {
      coc_st = <FcApproval style={{ fontSize: 30 }} />;
    }
  }
  let nda_st = null;
  let nda_status = null;
  if (onboardingDocList.length > 0) {
    if (onboardingDocList[0].od_NDAVerify === 0) {
      nda_st = (
        <span className="badge badge-pill badge-warning">Not Verified Yet</span>
      );
      nda_status = 0;
    } else {
      nda_st = <FcApproval style={{ fontSize: 30 }} />;
      nda_status = 1;
    }
  }

  const handleVerifyOnboardingDoc = (val) => {
    const data = {
      od_applicant_id: id,
      doctype: val,
    };
    Apiconnect.postData(`onboarding/verifyOnboardingDocs`, data).then((res) => {
      toast.success("Verified");
      setModalIsOpen1(false);
    });
    console.log(data);
  };

  //-----------------------------Submit

  const applicant_age =
    applicant && moment(applicant.am_dob).format("YYYY-MM-DD");

  //onSubmit
  const handleOnboard = () => {
    info.date_of_birth = applicant_age;
    info.emp_code = empCode;
    info.phone = applicant?.am_phone_cell;
    info.first_name = applicant?.am_first_name;
    info.middle_name = applicant?.am_middle_name;
    info.last_name = applicant?.am_last_name;
    info.phone = applicant?.am_phone_cell;
    info.email = applicant?.am_email;
    info.emp_designation = applicant?.am_position_id;
    info.applicant_id = applicant?.am_pk_id;
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc, employee_id: employee_id };
    try {
      Apiconnect.postData("employee/create", inf_a).then((response) => {
        var last_id = response.data.id;
        getacceptedApplicantInfo();
        Apiconnect.postData(`onboarding/applicantOnboard/${id}`).then((res) => {
          toast.success("Onboarded Successfully");
        });
        setModalIsOpen2(false);
        if (typeof response.data.error !== "undefined") {
          if (response.data.error.code === "ER_DUP_ENTRY") {
            toast.success("Employee Code Already Exist");
            setNumMsg({ msg: "Employee Code Already Exist" });
          } else {
            toast.success(response.data.message);
            setInfo({
              company_location: "",
              emp_code: "",
              first_name: "",
              middle_name: "",
              last_name: "",
              phone: "",
              email: "",
              password: "",
              gender: "",
              date_of_birth: "",
              emp_category: "",
              employment_type_id: "",
              emp_group: "",
              emp_designation: "",
              id: "",
              company_id: Apiconnect.get_company_id(),
              emp_shift: "",
              reporting_officer: "",
              emp_manage_multiple_schools: "",
            });
          }
        } else {
          toast.success(response.data.message);
          setInfo({
            company_location: "",
            emp_code: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            phone: "",
            email: "",
            password: "",
            gender: "",
            date_of_birth: "",
            emp_category: "",
            employment_type_id: "",
            emp_group: "",
            emp_designation: "",
            id: "",
            company_id: Apiconnect.get_company_id(),
            emp_shift: "",
            reporting_officer: "",
            emp_manage_multiple_schools: "",
          });
        }
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Container heading="Employee Onboarding">
        <CustomModal
          header={`Document List`}
          open={modalIsOpen}
          close={() => setModalIsOpen(false)}
        >
          <Table className="col-md-12 p-2" trHeader={tableDocsHeader}>
            {rowData.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  name={item.name}
                  href={item.hrefData}
                  verify={item.verify}
                  status={item.status}
                  handelVerify={() => handleVerify(item.verify)}
                />
              );
            })}
          </Table>
        </CustomModal>
        <CustomModal
          header={`Onboarding Documents`}
          open={modalIsOpen1}
          close={() => setModalIsOpen1(false)}
        >
          {onboardingDocList.length > 0 ? (
            <Table className="col-md-12 p-2" trHeader={tableDocsHeader}>
              <tr className="text-center">
                <th>Code Of Conduct</th>
                <th>
                  <a
                    href={
                      onboardingDocList.length === 0
                        ? ""
                        : onboardingDocList[0].od_codeOfconduct
                    }
                    target="_blank"
                  >
                    <i
                      className="text-danger fa fa-file-pdf-o"
                      aria-hidden="true"
                    ></i>
                  </a>
                </th>
                <th> {coc_st} </th>
                <th>
                  <button
                    className={`btn btn-sm btn-info`}
                    onClick={() => handleVerifyOnboardingDoc(1)}
                  >
                    Verify
                  </button>
                </th>
              </tr>
              <tr className="text-center">
                <th>Non Disclosure Agreement</th>
                <th>
                  <a
                    href={
                      onboardingDocList.length === 0
                        ? ""
                        : onboardingDocList[0].od_NDA
                    }
                    target="_blank"
                  >
                    <i
                      className="text-danger fa fa-file-pdf-o"
                      aria-hidden="true"
                    ></i>
                  </a>
                </th>
                <th> {nda_st} </th>
                <th>
                  <button
                    className={`btn btn-sm btn-info`}
                    onClick={() => handleVerifyOnboardingDoc(2)}
                  >
                    Verify
                  </button>
                </th>
              </tr>
            </Table>
          ) : (
            <div className="text-center" style={{ width: "100%" }}>
              <span className="text-muted">Documents are not available</span>
            </div>
          )}
        </CustomModal>
        <CustomModal
          header={`Onboard applicant`}
          open={modalIsOpen2}
          close={() => setModalIsOpen2(false)}
        >
          <div className="col-md-12">
            <div className="border" style={pageStyles.main_container}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Employee Code</label>
                    <input
                      readonly="readonly"
                      type="text"
                      className="form-control "
                      name="emp_code"
                      value={emp_code ? emp_code : empCode}
                      placeholder="Employee Code"
                      onChange={(e) => onInputChange(e)}
                    />
                    {/* <span className="required">{msg}</span> */}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Reporting Officer <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="reporting_officer"
                      value={reporting_officer}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Reporting Officer</option>
                      {empList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.first_name} {val.last_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Job Location <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="company_location"
                      value={company_location}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Location</option>
                      {companyList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.company_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {user_typ === "3" ? (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Show Multiple School </label>
                      <select
                        className="form-control"
                        required
                        name="emp_manage_multiple_schools"
                        value={emp_manage_multiple_schools}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-3"></div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      First Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      name="first_name"
                      value={applicant?.am_first_name}
                      placeholder="First Name"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Middle Name </label>
                    <input
                      type="text"
                      className="form-control "
                      name="middle_name"
                      value={applicant?.am_middle_name}
                      placeholder="Middle Name"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Last Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      name="last_name"
                      value={applicant?.am_last_name}
                      placeholder="Last Name"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Phone <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={applicant?.am_phone_cell}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control "
                      name="email"
                      value={applicant?.am_email}
                      placeholder="Email"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Designation <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="emp_designation"
                      value={applicant?.am_position_id}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Designation</option>
                      {empDesignationList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div className="form-group">
                                            <label>Password <span className="required">*</span></label>
                                            <input type="text" className="form-control " name="password" value={password} placeholder="Password" onChange={e => onInputChange(e)} required />
                                        </div> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Gender <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="gender"
                      required
                      value={gender}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {/* <input type="text" className="form-control " name="gender" value={gender} placeholder="Gender" onChange={e => onInputChange(e)} /> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Date Of Birth <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={applicant_age}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Employee Shift <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="emp_shift"
                      value={emp_shift}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Shift</option>
                      {shiftList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Employment Group <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="emp_group"
                      value={emp_group}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Group</option>
                      {empGroupList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Employee Department <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="emp_category"
                      value={emp_category}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Department</option>
                      {catList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Employment Type <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      name="employment_type_id"
                      value={employment_type_id}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="">Select Type</option>
                      {empTypeList.map((val, key) => {
                        return (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>RF ID</label>
                    <input
                      type="text"
                      className="form-control "
                      name="pm_userId"
                      value={pm_userId}
                      placeholder="RF Id"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4 text-center">
                <button
                  className="btn btn-info btn-sm mr-2"
                  onClick={handleOnboard}
                >
                  Onboard
                </button>
              </div>
            </div>
          </div>
        </CustomModal>
        <Card>
          {list.length !== 0 && (
            <Table className="col-md-12 p-2" trHeader={tableHeader}>
              {list.map((item, index) => {
                return (
                  <tr key={index} className={`text-center`}>
                    <td>{index + 1}</td>
                    <td>
                      {item.am_first_name +
                        " " +
                        item.am_middle_name +
                        " " +
                        item.am_last_name}
                    </td>
                    <td>{item.am_email}</td>
                    <td>{item.am_position}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-info"
                        style={pageStyles.main_container}
                        onClick={() => {
                          setModalIsOpen(true);
                          setId(item.am_pk_id);
                          setVerifyId(item.ad_pk_id);
                        }}
                      >
                        <AiFillEye className="text-light" />
                      </button>
                    </td>
                    <td>
                      <button
                        className={`btn btn-sm btn-info`}
                        style={pageStyles.main_container}
                        onClick={() => {
                          onboardingDocRequest(item.am_pk_id);
                        }}
                      >
                        <RiSendPlane2Fill />
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-info"
                        style={pageStyles.main_container}
                        onClick={() => {
                          setModalIsOpen1(true);
                          getOnboardingDocs(item.am_pk_id);
                          setId(item.am_pk_id);
                        }}
                      >
                        <AiFillEye className="text-light" />
                      </button>
                    </td>
                    <td>
                      {item.am_onboarded === 0 && (
                        <button
                          className="btn btn-sm btn-info"
                          style={pageStyles.main_container}
                          onClick={() => {
                            setId(item.am_pk_id);
                            setModalIsOpen2(true);
                            setApplicant(item);
                          }}
                        >
                          <RiUserAddFill className="text-light" />
                        </button>
                      )}
                      {item.am_onboarded === 1 && (
                        <span className="badge badge-success badge-sm">
                          Onboarded
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </Table>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default Employee_boarding;

{
  /* <Container>
        <Card>
          <div
            className="d-flex justify-content-center align-items-center bg-info"
            style={{ height: "70vh", width: "100%" }}
          >
            <h2>
              <MdConstruction
                className="m-2 text-light text-center"
                style={{ fontSize: 50 }}
              />
              <span className="text-white"> Module Under Testing</span>
            </h2>
          </div>
        </Card>
      </Container> */
}
