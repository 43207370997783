import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import {Link} from 'react-router-dom' 
import DatePicker from "react-datepicker";
import moment from 'moment';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { format } from 'date-fns';

function Employee_leave_record() {
    const alert = useAlert();
    let history = useHistory(); 

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken; 
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false); 
    const [from_date, setFromDate] = useState(new Date());
    let user_name=localuser.username;
    var company_id=Apiconnect.get_company_id();
    const [list_employee, setEmployeeList] = useState([]);
    const [list_categoys, setlist_categoys] = useState([]);
    const [lists_categoys, setlists_categoys] = useState([]);
    const [arrlists_categoys, setarrlists_categoys] = useState([]);
    const [listsempwisecategory, setlistsempwisecategory] = useState([]);
    const [empTypeList, setEmpType] = useState([]);
    const [selstatus_id, setselstatus_id] = useState('');
    const [selemp_type, setselemp_type] = useState('');

    useEffect(() => {
        getEmployeeList(); 
        getLeaveCategoryList();
        lists_categorys(new Date());
        // list_test();
        lists_empwise_category();
        getEmpType();
    }, []);
      

    const getEmpType = () => {
        Apiconnect.getData(`common/getallemploymenttype`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpType(_xtract.reverse());
        });
    }

    const getEmployeeList =  async (sel_status_id='',sel_emp_type='')  => {
        setLoader(true);
        //employee/getall
        var data_pst={
            company_id:company_id,
            sel_status_id:sel_status_id,
            sel_emp_type:sel_emp_type,
        }
        await Apiconnect.postData(`employee/get_all_employee_lists`,data_pst).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setEmployeeList(_xtract.reverse());
           setLoader(false);
        });
    };

    const getLeaveCategoryList =  async ()  => { 
        await Apiconnect.postData(`employee_reports/get_leave_category_list`,{"company_id":company_id}).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setlist_categoys(_xtract); 
        });
    };

      
    const lists_categorys =  async (selfrom_date)  => { 
        var data_post={
            company_id:company_id,
            employee_id:0,
            is_admin:'',
            sel_shortleave_type:'',
            sel_from_date:selfrom_date,
        };
        await Apiconnect.postData(`employee_reports/get_leave_categorys_lists`,data_post).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            // console.log(_xtract);
            setlists_categoys(_xtract);
        });
    }

    const lists_empwise_category =  async (selfrom_date)  => { 
        var data_post2={
            company_id:company_id,
            employee_id:0,
            is_admin:0,
            sel_shortleave_type:"No",
        }; 
        await Apiconnect.postData(`leave_manage/get_employee_policy_category_list_all`,data_post2).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data); 
            setlistsempwisecategory(_xtract); 
        });
    }

    var array_header1=['EARNED','TAKEN','BALANCE'];
    
    const btn_submit = event => {
        let sel_from_date1=moment(new Date(from_date)).format("YYYY-MM-DD");
        // console.log(sel_from_date1);
    }
    
    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
    }


    function nextChar(c) {
        var u = c.toUpperCase();
        if (same(u,'Z')){
            var txt = '';
            var i = u.length;
            while (i--) {
                txt += 'A';
            }
            return (txt+'A');
        } else {
            var p = "";
            var q = "";
            if(u.length > 1){
                p = u.substring(0, u.length - 1);
                q = String.fromCharCode(p.slice(-1).charCodeAt(0));
            }
            var l = u.slice(-1).charCodeAt(0);
            var z = nextLetter(l);
            if(z==='A'){
                return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
            } else {
                return p + z;
            }
        }
    }

    function nextLetter(l){
        if(l<90){
            return String.fromCharCode(l + 1);
        }
        else{
            return 'A';
        }
    }
    
    function same(str,char){
        var i = str.length;
        while (i--) {
            if (str[i]!==char){
                return false;
            }
        }
        return true;
    }

    const btn_excel = () => {
        var from_datenew = moment(new Date()).format("DD-MM-YYYY");
        let header = ["Sr. No", "Dept.", "Name Of Employee","Emp Id","Group","Designation", "D.O.J"];

        var cnt_head1=7;
        lists_categoys.map((val_catg, key) => {
            array_header1.map((val_hread1, key) => {
                header[cnt_head1]=val_catg.leave_cat_code+' '+val_hread1;
                cnt_head1++;
            });
        })
          
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = getSheetData(header, header);
          const totalColumns = sheetData[0].length;
         
          sheet1.cell("A1").value(localuser.company_name); 
          sheet1.cell("A2").value(`Employee Leave Report - ${from_datenew}`); 
          sheet1.cell("A5").value(sheetData);
          
          
          var cnt_row1=6;
          var cnt_srno1=1;
          list_employee.map ((val_emp, key_emp) => {  
            var selempid1=val_emp.id 
            
            sheet1.cell("A"+cnt_row1).value(cnt_srno1);
            sheet1.cell("B"+cnt_row1).value(val_emp.Department);
            sheet1.cell("C"+cnt_row1).value(val_emp.first_name+' '+val_emp.last_name); 
            sheet1.cell("D"+cnt_row1).value(val_emp.emp_code); 
            sheet1.cell("E"+cnt_row1).value(val_emp.employee_group); 
            sheet1.cell("F"+cnt_row1).value(val_emp.emp_designation); 
            sheet1.cell("G"+cnt_row1).value(moment(new Date(val_emp.joining_date)).format("DD-MM-YYYY"));

            var cnt_char1="H"; 
            lists_categoys.map((val_catg, key) => {  
                var lpcategory_pk_id1=val_catg.lpcategory_pk_id;
                 
                array_header1.map((val_hread1, key) => {
                    var calculation_total1=listsempwisecategory[selempid1+'#'+lpcategory_pk_id1+'#'+val_hread1];  
                    
                    if(typeof calculation_total1=='undefined'){
                        calculation_total1=0;
                    } 
                    
                    sheet1.cell(cnt_char1+cnt_row1).value(parseFloat(calculation_total1).toFixed(1)); //--date_wise_data 

                    cnt_char1=nextChar(cnt_char1); 
                })  
            })

            cnt_row1++;
            cnt_srno1++;
          });


          const range = sheet1.usedRange();
          range.style("border", true); 
        //   const endColumn = String.fromCharCode(64 + totalColumns);
        //   sheet1.row(1).style("bold", true); 
        //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
 
          
          return workbook.outputAsync().then((res) => {
            saveAs(res, "employee_leave_record.xlsx");
          });
        });
    }


    // console.log(listsempwisecategory);
    const onSatusChange = (e) => {
        setselstatus_id(e.target.value);
    };

    const onInputChange = e => { 
        setselemp_type(e.target.value);
    }

    const onSubmit_filter = async e => {
        //console.log(selstatus_id);
        //console.log(selemp_type);
        getEmployeeList(selstatus_id,selemp_type);
    }

    return (
        <>
            <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">

                                <h4 className="card-title">Employee Leave Report</h4> 

                                <button type='button' className='btn btn-success pull-right' onClick={e => btn_excel(e)}>Excel</button>
                                 
                            </div>
                             
                            <div className="iq-card-body">
                                <div className='row'>  
                                    <div className="col-md-3">
                                        <label> Status </label>
                                        <select
                                        className="form-control"
                                        required
                                        name="emp_status" 
                                        onChange={(e) => onSatusChange(e)}
                                        >
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                            <option value="">Both</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Employment Type </label>
                                        <select className="form-control" name="employment_type_id" onChange={e => onInputChange(e)}>
                                            <option value="">Select Type</option>
                                            {
                                                empTypeList.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.id}>{val.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <button type='button' className='btn btn-success' onClick={e => onSubmit_filter(e)} style={{marginTop:'26px'}}>Submit</button>
                                    </div>
                                </div>  

                                <div className='row'> 
                                    <div className="col-md-12">&nbsp;</div>
                                </div>
                                <div className='row' style={{marginTop:10}}>  
                                    <div className="form-group col-md-12 table-responsive" style={{marginTop:'10'}}>
                                        <table className="table table-striped table-bordered table-sm center table-hover" role="grid" >
                                            <thead>
                                                <tr>
                                                    <th>Sr. No </th>
                                                    <th>Dept.  </th>
                                                    <th>Name Of Employee </th>
                                                    <th>Emp Id</th> 
                                                    <th>Group</th>
                                                    <th>Designation</th>
                                                    <th>D.O.J </th>
                                                    {
                                                        lists_categoys.map((val_catg, key) => {
                                                            return(
                                                                array_header1.map((val_hread1, key) => {
                                                                    return (<th>{val_catg.leave_cat_code+' '+val_hread1}</th>);
                                                                }) 
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                list_employee.map ((val_emp, key_emp) => {  
                                                    // console.log(val_emp);
                                                    let selempid1=val_emp.id;  
                                                    return(
                                                        <tr key={key_emp}>
                                                            <td scope="row">{key_emp+1}</td>
                                                            <td>{val_emp.Department} </td>
                                                            <td>{val_emp.first_name+' '+val_emp.last_name} </td>
                                                            <td>{val_emp.emp_code}</td> 
                                                            <td>{val_emp.employee_group}</td>
                                                            <td>{val_emp.emp_designation}</td>
                                                            <td>{moment(new Date(val_emp.joining_date)).format("DD-MM-YYYY")} </td>
                                                            {
                                                                lists_categoys.map((val_catg, key) => {  
                                                                    var lpcategory_pk_id1=val_catg.lpcategory_pk_id;
                                                                    
                                                                    return(
                                                                        array_header1.map((val_hread1, key) => {
                                                                            var calculation_total1=listsempwisecategory[selempid1+'#'+lpcategory_pk_id1+'#'+val_hread1]; 
                                                                            //console.log(calculation_total1); 
                                                                            if(typeof calculation_total1=='undefined'){
                                                                                calculation_total1=0;
                                                                            }
                                                                            return (
                                                                                <td>{parseFloat(calculation_total1).toFixed(1)}</td>
                                                                            );
                                                                        }) 
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                              }      
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}
export default Employee_leave_record