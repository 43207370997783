import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const Manage_hiring_panel = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert();

    const {p_id} = useParams()

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [des_list, setDesList] = useState([])
    const [emp_list, setEmp_list] = useState([])
    const [d_id, setD_id] = useState("")
    const [e_id, setE_id] = useState("")
    const [emp_h, setEmp_h] = useState("")
    const [edit_id, setEdit_id] = useState("")
    const [panel_members, setPanel_members] = useState([])

    useEffect(() => {getDesignation(); get_panel_members()}, [])

    const getDesignation = () => {
        Apiconnect.getData(`designation/getall/${company_id}`).then((response) => {
           let _xtract = response.data.data;
           setDesList(_xtract.reverse());      
        });
    };

    const get_emp_list_by_designation = (id) => {
        setFullScreenLoader(false)
        Apiconnect.postData(`hiring_application/hiring_panel_get_emp_by_designation/${company_id}/${id}`).then(res => {
            setFullScreenLoader(false)
            setEmp_list(res.data.data)
        })
    }

    const get_panel_members = () => {
        Apiconnect.postData(`hiring_application/hiring_panel_emp_getall/${company_id}/${p_id}`).then(res => {
            setPanel_members(res.data.data)
        })
    }

    const handleSubmit = () => {
        if(d_id!=="" && e_id!=="" && emp_h!==""){
            if(edit_id!== ""){
                /* Update */
            }else{
                let ins_ob = {hppm_company_id:company_id, hppm_panel_id:p_id, hppm_pannel_member:e_id, hppm_panel_designation: d_id, hppm_hierarchy:emp_h, hppm_added_by:emp_id}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_panel_emp_create`, ins_ob).then(res => {
                    setFullScreenLoader(false)
                    get_panel_members()
                    setD_id("")
                    setE_id("")
                    setEmp_h("")
                    alert.success("Created")
                })
            }
        }
    }

    const handleEditPanel = (id) => {
        setEdit_id(id)
        let ob = panel_members.find(item => item.id === id)
        console.log(ob)
    }

    const handleDeletePanel = (id) => {
        Apiconnect.postData(`hiring_application/hiring_panel_emp_delete/${id}`).then(res => {
            get_panel_members()
            alert.success("Deleted")
        })
    }

  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}   
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                    <div className='iq-card'>
                        <div className='iq-card-header justify-content-between model_head'>
                            <div className='iq-header-title' style={{float: "left"}}>
                                <h4 className='card-title'>Manage Hiring Panel For </h4>
                            </div>
                        </div>
                        <div className='iq-card-body'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <select className='form-control center' name='d_id' value={d_id} onChange={(e) => {setD_id(e.target.value); get_emp_list_by_designation(e.target.value)}}>
                                        <option value="" disabled selected>---Select Designation----</option>
                                        {
                                            des_list.map((item,index) => {
                                                return(
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className='form-control center' name='e_id' value={e_id} onChange={(e) => {setE_id(e.target.value)}}>
                                        <option value="" disabled selected>---Select Employee----</option>
                                        {
                                            emp_list.map((item,index) => {
                                                return(
                                                    <option key={index} value={item.id}>{item.first_name+" "+item.middle_name+" "+item.last_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <input type="number" className='form-control' placeholder='Enter hierarchy level' name='emp_h' value={emp_h} onChange={(e) => setEmp_h(e.target.value)}/>
                                </div>
                                <div className='col-md-3'>
                                    <button className='btn btn-sm btn-primary' onClick={handleSubmit}>Create</button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-12'>
                                    <table className='table table-striped table-bordered center'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Designation</th>
                                                <th>Hierarchy</th>
                                                <th>Panel For</th>
                                                <th>Manage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                                {panel_members.map((item, index) => {
                                                    
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.panel_member_name}</td>
                                                            <td>{item.designation_name}</td>
                                                            <td>{item.hppm_hierarchy}</td>
                                                            <td>{item.panel_name}</td>
                                                            <td>
                                                                <button className='btn btn-sm btn-info mr-2' onClick={() => handleEditPanel(item.id)}>Edit</button>
                                                                <button className='btn btn-sm btn-danger mr-2' onClick={() => handleDeletePanel(item.id)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Manage_hiring_panel