import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";

import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import FullScreenLoading from 'react-fullscreen-loading';


const Employee_manage_qualification = (props) => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 

    // let emp_id = Apiconnect.decrypt(props.match.params.id);
    let emp_id = props.match.params.id;

    const alert = useAlert();
    let history = useHistory();
    const [btn_data, setBtnId] = useState([]);
    const { btn_id } = btn_data;
    const [stdArr, setStdArr] = useState([]);
    const [dynamicDiv, setDynamicDiv] = useState([])
    const [eduId, setEduId] = useState('');
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [instituteArr, setInstitute] = useState([]);
    const [univarsityBoardArr, setUniversityBoard] = useState([]);
    const [yearArr, setYear] = useState([]);

    const [info2, setInfo2] = useState({
        employee_mst_id: emp_id, institute: '',subject:'',
        subject_specialiazation: '', university_board: '', total_marks:'', percentage: '',
        marks_obtained: '',year_of_passing:'',
    });

    const { employee_mst_id, institute,subject, subject_specialiazation, university_board, education,
        percentage,total_marks,
        marks_obtained,year_of_passing } = info2;


    const onInputChange2 = e => {

        if (education === 1) {
            var req = 'required';
            var req_mand = '*';
        }
        setInfo2({ ...info2, [e.target.name]: e.target.value });
    };

    // console.log(info2);

    const onSubmit = async e => {
        e.preventDefault();

        if (btn_id > 0) {
            //console.log('in edit function.....');
            info2.employee_mst_id = emp_id;

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id, log_name: "Educational Qualification", company_id:Apiconnect.get_company_id()};
            Apiconnect.postData("employee_info/update", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: emp_id, institute: '',subject:'',
                    subject_specialiazation: '', university_board: '', total_marks:'', percentage: '',
                    marks_obtained: '',year_of_passing:'',
                });

                alert.success(response.data.message);
                history.push(`/employee_manage_qualification/${emp_id}`);
            });
        } else {

            //console.log('in edit function.....');
            info2.employee_mst_id = emp_id;

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id };
            Apiconnect.postData("employee_info/create", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: emp_id, institute: '',subject:'',
                    subject_specialiazation: '', university_board: '', total_marks:'', percentage: '',
                    marks_obtained: '',year_of_passing:'',
                });

                alert.success(response.data.message);
                history.push(`/employee_manage_qualification/${emp_id}`);
            });

        }

    };

    useEffect(() => { getItem();getQualification();getInstitute();getUniversity_board();getYear() }, []);
    const getItem = () => {
        if (emp_id > 0) {

            Apiconnect.getData(`employee_info/getQualificationEmp/${emp_id}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                console.log('_xtract',_xtract)
                if (_xtract.length > 0) {
                    setBtnId({ btn_id: 1 });
                    setDynamicDiv(_xtract);
                } else {
                    setBtnId({ btn_id: null });
                }

            })

        }
    };

    const getQualification = () => {
        Apiconnect.getData(`employee_info/getAllQualification/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setStdArr(_xtract)
        });
    };

    const getInstitute = () => {
        Apiconnect.getData(`edu_institute/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = response.data.data;
            setInstitute(_xtract)
        });
    };

    const getUniversity_board = () => {
        Apiconnect.getData(`edu_university_board/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = response.data.data;
            setUniversityBoard(_xtract)
        });
    };

    const getYear = () => {
      const minOffset = 0;
      const maxOffset = 77;
      const options = [];

      const thisYear = (new Date()).getFullYear();
      for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        options.push({id:year,name:year});
      }

      setYear(options)
    }

    function nextPage() {
        history.push(`/employee_manage_employement/${emp_id}`);
    }
    function previousPage() {
        history.push(`/employee_manage_reimbursement/${emp_id}`);
    }

    const onChangeStd = (e) => {
        setEduId(e.target.value)
        if(e.target.value != ''){
            const isEdu = dynamicDiv.some(val => val.education == e.target.value);

            if(!isEdu){
                setDynamicDiv([...dynamicDiv,{[e.target.name] : e.target.value}])
            }
        }

    }

    const handleChangeDiv = (e, idx) => { 
        if(e.target.name === 'educationfile'){
           
            const rows = [...dynamicDiv]
            rows[idx] = {
              ...rows[idx],
              [e.target.name]: e.target.files[0]
            }
             setDynamicDiv(rows)
           
        }else{
            const { name, value } = e.target
            const rows = [...dynamicDiv]
            rows[idx] = {
              ...rows[idx],
              [name]: value
            }
            setDynamicDiv(rows)
        }
       
      }

      const submit  = () => {
        setFullScreenLoader(true)
        dynamicDiv.forEach(function(val,kk) {
            const formData = new FormData();
            formData.append('employee_id',emp_id)   
            for(let key in val){
                formData.append(key,val[key] != '' && val[key]!= 'null' ? val[key] : '')   
            }
            Apiconnect.postData(`employee_info/addqualification`, formData).then((response) => {
                if(response.data.status === 1){ 
                    if(dynamicDiv.length == kk+1){
                        getItem()
                        setFullScreenLoader(false)
                        alert.success(response.data.message); 
                    }
                }
                window.location.reload();
            });   
        }) 
      }

      const deleteRow = (inx,id) => {

        if(id){
            ondlt(id)
        }
        setEduId('')
        const list = [...dynamicDiv];
        list.splice(inx, 1);
        setDynamicDiv(list);
      }

      const ondlt = (id) => {
        confirmAlert({
          title: 'Are you sure to Delete?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => deleteItem(id), 
            },
            {
              label: 'No',
            }
          ]
        });
      };

    const deleteItem = (id) => {
        Apiconnect.deleteData(`employee_info/educationdelete/${id}`).then((response) => {
            alert.success(response.data.message);     
            window.location.reload(); 
        });
    };


    //console.log('dynamicDiv',dynamicDiv)

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                {fullScreenLoader
            ? (
              <FullScreenLoading loading loaderColor="#3498db" />
            )
             
            : null
            }
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Bank Info <span style={{ fontSize: '15px', color: 'blue' }}>Stage 6 </span></h4>
                            </div>
                        </div>
                        <div className="iq-card-body">

                            <div className="bs-example">
                            <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${emp_id}`} >Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${emp_id}`} >Other Details</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_bank/${emp_id}`} >Financial</Link>
                                    </li> */}
                                    <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_employement/${emp_id}`} >Employement Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/PreviousEmployment/${emp_id}`} >Previous Employment</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_reimbursement/${emp_id}`} >Reimbursement Info</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`/employee_manage_sal/${emp_id}`} >Salary (Monthly Wages)</Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link active" data-toggle="tab" to={`/employee_manage_qualification/${emp_id}`} >Educational Qualification</Link>
                                    </li>
                                </ul>
                            </div> 

                                <div className="border">

                                        <div className="col-md-3">
                                        <div className="form-group">
                                                <label>Add Educational Qualification </label>
                                                <select className="form-control" name="education" value={eduId} onChange={e => onChangeStd(e)}>
                                                <option value=''>Select Std</option>
                                                    {
                                                        stdArr.map((val,key)=>{
                                                            return (
                                                                <option value={val.id}>{val.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                    
                                        {dynamicDiv.length>0 && dynamicDiv.map((item, idx) => (

                                        <div className="bbox"  id='addr0' key={item} style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
                                              <button type="button" style={{position:'absolute',right:'60px',marginTop: '-15px'}} onClick={() => deleteRow(idx,dynamicDiv[idx].id)} className="btn btn-danger mr-2"> Delete </button>
                                         <div className="row">   
                                        <div className="col-md-3">
                                        <div className="form-group">
                                                <label>Educational Qualification </label>
                                                <select className="form-control" name="education"
                                                 value={dynamicDiv[idx].key} onChange={(e) => handleChangeDiv(e, idx)}>
                                                  
                                                    {
                                                        stdArr.map((val,key)=>{
                                                          return(
                                                            dynamicDiv[idx].education == val.id 
                                                            ? (
                                                                <option value={val.id}>{val.name}</option>
                                                            )
                                                            : null
                                                          )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>    

                                        
                                        <div className="col-md-3">
                                        <div className="form-group">
                                                <label>Institute Name (Complete Name)</label>
                                                
                                                <input type="text" className="form-control" name="institute" placeholder="Institute Name" value={dynamicDiv[idx].institute}  onChange={(e) => handleChangeDiv(e, idx)} autoComplete='off'/>
                                            </div>
                                        </div> 

                                        <div className="col-md-3">
                                        <div className="form-group">
                                                <label>University Board (Complete Name) </label>
                                                
                                                <input type="text" className="form-control" name="university_board" placeholder="University Board" value={dynamicDiv[idx].university_board}  onChange={(e) => handleChangeDiv(e, idx)} autoComplete='off'/>
                                            </div>
                                        </div>
 
                                        
                                        <div className="col-md-3">
                                        <div className="form-group">
                                                <label>Year of Passing </label>
                                                <select className="form-control" name="year_of_passing" value={dynamicDiv[idx].year_of_passing}  onChange={(e) => handleChangeDiv(e, idx)}>
                                                <option value=''>Select Year of Passing</option>
                                                    {

                                                        yearArr.map((val,key)=>{
                                                            return (
                                                                <option value={val.id}>{val.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
 

                                        <div className="col-md-3" >
                                            <label>Subjects</label>
                                            <input type="text" className="form-control validate[required]" name="subject" placeholder="Subjects" value={dynamicDiv[idx].subject}  onChange={(e) => handleChangeDiv(e, idx)} />
                                        </div>  

                                        <div className="col-md-3" >            
                                        <label for="photo">Upload Document &nbsp; </label>     
                                            <input 
                                                type="file"
                                                id="photo" 
                                                name="educationfile"
                                                accept=".png, .jpg, .jpeg"
                                                required
                                                onChange={(e) => handleChangeDiv(e, idx)}
                                            />  

                                            <span style={{fontSize:'10px',color:'#FF0000',fontWeight:'bold'}}>Document/File should be less than 1 MB</span>
                                          </div>
                                          <div className="col-md-3" >                       
                                            { 
                                                dynamicDiv[idx].document != '' && dynamicDiv[idx].document != null  ? (
                                                    // <img style={{width:'100px'}} src={process.env.React_App_BASE_URL_API +"/uploads/educationfile/" +dynamicDiv[idx].document} className="card-img-top" alt="Image"/>
                                                    <a href={process.env.React_App_BASE_URL_API +"/uploads/educationfile/" +dynamicDiv[idx].document} target='_blank' download className='btn btn-success'>View File</a> 
                                                ) : 'No document/file found please upload '
                                            } 
                                         </div>
                                              

                                        </div>

                                        <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Subject Specialization (On P.G. Level)</label>
                                                <input type="text" className="
                                 form-control validate[required]" name="subject_specialiazation" placeholder="Subject Specialization" value={dynamicDiv[idx].subject_specialiazation} onChange={(e) => handleChangeDiv(e, idx)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Marks Obtained </label>
                                                <input type="text" className="form-control validate[required]" name="marks_obtained" placeholder="Marks Obtained" value={dynamicDiv[idx].marks_obtained} onChange={(e) => handleChangeDiv(e, idx)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Total Marks</label>
                                                <input type="text" className="form-control validate[required]" name="total_marks" placeholder="Total Marks" value={dynamicDiv[idx].total_marks} onChange={(e) => handleChangeDiv(e, idx)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Grade/Percentage</label>
                                                <input type="text" className="form-control validate[required]" name="percentage" placeholder="Grade/Percentage" value={dynamicDiv[idx].percentage} onChange={(e) => handleChangeDiv(e, idx)} />
                                            </div>
                                        </div>
                                       
                                    </div>    

                                         

                                        
                                        </div>

                                        ))}

                                   
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" value={btn_id} onClick={ () => submit() } className="btn btn-primary mr-2"> {btn_id ? 'Update' : 'Submit'} </button>
                                        {/* <button type="button" className="btn btn-danger mr-2" onClick={ refreshPage }> cancle </button>  */}
                                        <button type="button" className="btn btn-info mr-2" onClick={previousPage}> Previous </button>
                                        {/* <button type="button" className="btn btn-success mr-2" onClick={nextPage}> Next </button> */}
                                        <Link style={{ float: 'right' }} className="btn btn-secondary" to="/employee_manage_list" >Back to List</Link>
                                    </div>
                                </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )



}
export default Employee_manage_qualification