import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import FullScreenLoading from 'react-fullscreen-loading';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

function Daily_departure_report() {
    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);
    var date = new Date();

    const [from_date, setFromDate] = useState(null);
    const [emp_id, setempid] = useState(null);
    const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const onInputChange = e => {
        setempid(e.target.value);
    }

    useEffect(() => { empdata();  }, []);
    const [empList, setEmpList] = useState([]);
    const [dailyAttendanceList, setDailyAttendanceList] = useState([]);


    const empdata = () => {
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpList(_xtract.reverse());
        });
    }

    const submit = () => {
       
        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select from date');
            return
        }

        setFullScreenLoader('true');
        let info = { company_id: Apiconnect.get_company_id(), from_date: from, emp_id: emp_id};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee_dailydata/get_daily_departure_report`, inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setDailyAttendanceList(_xtract.reverse());
            setDwnEmpAttendanceList(_xtract.reverse());
            setFullScreenLoader(false);
            
        });
    }





    const shift_porduction = (num) => {

        var hours = num;
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if(rhours.toString().length <= 1){
            rhours = '0'+rhours;
        }
        if(rminutes === 0){
            rminutes = '00';
        }
        return  rhours + ":" + rminutes;

    }

    const local_user = JSON.parse(localStorage.getItem('user'));
   const [companyaddress, setCompanyData] = useState([])
   const getCompany = () => {         
   Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
         let ern = Apiconnect.decrypt_obj(response.data.data);
         setCompanyData(ern[0].address_one)
         
      });
   };
   useEffect(() => {getCompany()}, [])

    /* ---------------------excel------------------------ */
    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }

      const saveAsExcel = () => {
        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select from date');
            return
        }
        let info = { company_id: Apiconnect.get_company_id(), from_date: from, emp_id: emp_id};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee_dailydata/get_daily_departure_report`, inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            let header = ["department",	"emp_code",	"Employee_name","Designation","shift_name",	"shift_in_time","start_time","shift_out_time","end_time","shift_working_hour","Working_hours"]
            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(_xtract, header);
            const totalColumns = sheetData[0].length;
        
            sheet1.cell("A1").value(local_user.company_name);
            sheet1.cell("A2").value(companyaddress)
            sheet1.cell("A3").value(`Daily Early Departure Report - ${from_date}`);
            sheet1.cell("A5").value(sheetData);

            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            // sheet1.column("D").value("numberFormat")
            // sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");

            sheet1.range("A1:K1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
            sheet1.range("A2:K2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
            sheet1.range("A3:K3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
            sheet1.range("A5:K5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "DailyEarlyDepartureReport.xlsx");
            });
            });
            
        });
      }


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    : null
                    }

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Daily Early Departure Report</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">
                                        <div className="col-md-3">
                                            <label>Date</label>
                                            <DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Selete Date"
                                                name="from_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                               
                                        {/* <div className="col-md-3">
                                            User : <br />
                                            <select className="form-control" name="emp_id" value={emp_id} onChange={e => onInputChange(e)}>
                                                <option value="">Select User</option>
                                                {empList.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div> */}
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                        </div>
                                        <div className='row'>
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-warning" value="Download in excel" style={{ marginTop: "23px" }} onClick={()=> saveAsExcel()} />
                                        </div>  
                                        </div>        
                                              
                                    </div>

                                    <div className="row" style={{ marginTop: '25px' }} >

                                        <div className="col-lg-12">
                                            <div className="iq-card">
                                                <div className="row">
                                                    <div className="col-md-6" >
                                                    </div>
{/* 
                                                    {
                                                        from_date && dwnEmpAttendanceList.length > 0
                                                        ? (
                                                            <div className="col-md-6" >
                                                            <CSVLink filename={format(from_date, 'yyyy-mm-dd')+"_Daily_Departure_Report.csv"} style={{ marginTop: '10px', marginRight: '20px', float: 'right' }} className="btn btn-warning" data={dwnEmpAttendanceList}  >
                                                                <i class="fa fa-download"></i>Daily Departure Report CSV
                                                            </CSVLink>
                                                        </div>
                                                        )
                                                        :''
                                                    }
                                                   */}
                                                </div>
                                                <div class="iq-card-body">
                                                    <div className="table-responsive">

                                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                                            <thead>
                                                                <tr className="clr">
                                                                    <th>#</th>
                                                                    <th>Department</th>
                                                                    <th>Emp Code</th>
                                                                    <th>Emp Name</th>
                                                                    <th>Designation</th>
                                                                    <th>Shift</th>
                                                                    <th>Shift Start Time</th>
                                                                    <th>In time</th>
                                                                    <th>Shift Out Time</th>
                                                                    <th>Out time</th>
                                                                    <th>Early Hour</th>
                                                                    <th>Working Hour</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {dailyAttendanceList.length > 0
                                                                    ?
                                                                    dailyAttendanceList.map((val, key) => {
                                                                     var msg = '';
                                                                    var shift_tot = val.shift_working_hour;
                                                                    if(shift_tot == null){
                                                                      shift_tot = 8;
                                                                    }
                                                                    var shift_tot2 = shift_tot/2;
                                                                    var shift_tot3 = 3/4*shift_tot;

                                                                   var shift_h =  shift_porduction(shift_tot);
                                                                   var shift_half =  shift_porduction(shift_tot2);
                                                                   var shift_half3 =  shift_porduction(shift_tot3);

                                                                    if (val.Working_hours >= shift_h) {
                                                                        var production_hour = <div className=" badge badge-pill badge-success">{val.Working_hours}</div>;
                                                                    } else if (val.Working_hours <= shift_h && val.Working_hours >= shift_half) {
                                                                         production_hour = <div className=" badge badge-pill badge-warning">{val.Working_hours}</div>;
                                                                    } else {
                                                                         production_hour = <div className=" badge badge-pill badge-danger">{val.Working_hours}</div>;
                                                                    }
                                                                    

                                                                    if (val.Working_hours == null) {
                                                                         production_hour = '-';
                                                                    }


                                                                    var start_time = '-';
                                                                    if (val.start_time != null) {
                                                                         start_time = val.start_time;
                                                                    }
                                                                    var end_time = '-';
                                                                    if (val.end_time != null) {
                                                                         end_time = val.end_time;
                                                                    }

                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{key + 1}</td>
                                                                            <td>{val.department}</td>
                                                                            <td>{val.emp_code}</td>
                                                                            <td>{val.Employee_name}</td>
                                                                            <td>{val.Designation}</td>
                                                                            <td>{val.shift_name}</td>
                                                                            <td>{val.shift_in_time}</td>
                                                                            <td>{start_time}</td>
                                                                            <td>{val.shift_out_time}</td>
                                                                            <td>{end_time}</td>
                                                                            <td>{val.shift_working_hour}</td>
                                                                            <td>{production_hour}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            : <tr>
                                                                <th colSpan={12}>No Data Found</th>
                                                                </tr>}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )

}
export default Daily_departure_report