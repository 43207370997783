import React, { useState, useEffect, useRef } from "react";
import Apiconnect from "../../services/Apiconnect.js"; 
import { format } from "date-fns"; 
import FullScreenLoading from 'react-fullscreen-loading';
import "react-datepicker/dist/react-datepicker.css"; 
import Axios from "axios";
import { CSVLink } from "react-csv"; 
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import moment from 'moment'
import parseJSON from "date-fns/parseJSON/index.js";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";


 
function Manage_attendance_paidday() {
    const alert = useAlert();
    const company_id = Apiconnect.get_company_id(); 
    const localuser = JSON.parse(localStorage.getItem("user"));  
    const [fullScreenLoader, setFullScreenLoader] = useState(false);  
    const [from_date, setFromDate] = useState(new Date());
    const [lists_employees1, set_lists_employees1] = useState([]); 
    const [emp_id, setempid] = useState('');
    const [selgrp, setSelgrp] = useState("");
    const [empList, setEmpList] = useState([]); 
    const selempid = Apiconnect.decrypt(localuser.id);
    const [val_emppaiddays, setval_emppaiddays] = useState("");
    const [val_emppaidremak1, setval_emppaidremak1] = useState("");
    const [array_emppaiddays, setarray_emppaiddays] = useState([]);
    const [array_emppaidremak, setarray_emppaidremak] = useState([]);
    const [inputFields1, setInputFields1] = useState([]);
    const [inputFields2, setInputFields2] = useState([]);

    useEffect(() => { 
      GetEmployeesCalculatedPaiddaysLlistsApi();
      empdata();
    }, []);

    const GetEmployeesCalculatedPaiddaysLlistsApi = async () => {  
      if (from_date != null) {
        var from = format(from_date, "yyyy-MM");
      }
      let info = {
        company_id: Apiconnect.get_company_id(),
        selected_date: from,
        emp_id: emp_id,
        group_id: selgrp
      };
  
      setFullScreenLoader(true);
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc };
      await Apiconnect.postData(`reports_module/report_manages/get_employees_calculated_paiddays_lists_api`,inf_a).then(response => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        var thearray_up=[];  
        _xtract.map((val_emps, key_emps) => {
          thearray_up[val_emps['emp_id']]={emppad_paidday:val_emps['emppad_paidday'],emppad_remarks:val_emps['emppad_remarks']};
        })  
        setInputFields1(thearray_up);
        setInputFields2(thearray_up);
        set_lists_employees1(_xtract); 
        setFullScreenLoader(false);
      }); 
    }; 

    const empdata =async ()  => {
      await Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then( (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpList(_xtract.reverse());
      });
    };

    const onInputChange_fromdate =async (e) => {   
      await setFromDate(e);  
    };

    const submit_filter =async () => {
      GetEmployeesCalculatedPaiddaysLlistsApi();
    }

    const onInputChange = (e) => {
      setempid(e.target.value); 
    }; 

     
    const onSubmit_save = async () => {  
      // console.log(inputFields1);
      // console.log('==========');
      // console.log(inputFields2);
      var array_info=[];
      var cal_cnt1=0;
      Object.keys(inputFields1).forEach(function(key_empid) {
        var arraydata1=inputFields1[key_empid];
        if(typeof arraydata1!='undefined'){
          // console.log(key_empid+' = '+arraydata1.emppad_paidday+' = '+arraydata1.emppad_remarks);
          var info={}; 
          info["emppad_emp_id"] = key_empid; 
          info["emppad_company_id"] = company_id;  
          info["emppad_yearmonth"] = moment(new Date(from_date)).format("YYYY-MM"); 
          info["emppad_paidday"] = arraydata1.emppad_paidday; 
          info["emppad_remarks"] = arraydata1.emppad_remarks;
          info["emppad_status"] = 1; 
          info["emppad_createdby"]=selempid; 
          info["emppad_datetime"]=moment(new Date()).format("YYYY-MM-DD H:i:s"); 

          array_info.push(info);
          cal_cnt1++;
        }
      });

      if(cal_cnt1>0){     
        await Apiconnect.postData(`reports_module/report_manages/submit_employee_paiddays_data`, array_info).then((response) => {
          alert.success(response.data.message);  
          GetEmployeesCalculatedPaiddaysLlistsApi();
        }); 
      } 
    } 

    const setItemName_day = (value, index) => {   
      inputFields1[index].emppad_paidday = value;
      setInputFields1([...inputFields1])
    }

    const setItemName_remark = (value, index) => {   
      inputFields2[index].emppad_remarks = value;
      setInputFields2([...inputFields2])
    }
  
  return (
    <>
      <div id="content-page" className="content-page">
        {fullScreenLoader? (<FullScreenLoading loading loaderColor="#3498db" />): null}  
        <div className="container-fluid">
           <div className="row"> 
             <div className="col-sm-12">
               <div className="iq-card">
                 <div> <h4 className="card-title hd">Attendance Set Paid Days</h4>  </div>
                 <div className="iq-card-body">
                   <div className="row"> 
                      <div className="col-md-2">
                        <label>Date</label>
                        <DatePicker
                          selected={from_date} 
                          onChange={(e) => onInputChange_fromdate(e)}
                          maxDate={new Date()}
                          dateFormat="MM/yyyy"
                          className="form-control"
                          showMonthYearPicker
                          dropdownMode="select"
                          autoComplete="off"
                        />
                      </div>

                      <div className="col-md-3">
                      <label>User</label>
                      <select
                        className="form-control"
                        name="emp_id"
                        value={emp_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option value="">Select User</option>
                        {empList.map((val, key) => {
                          return (
                            <option key={key} value={val.id}> {val.first_name} {val.last_name} </option>
                          );
                        })}
                      </select>
                      </div>

                      <div className="col-md-2">
                        <button type="button" className="btn btn-primary" style={{marginTop: "25px"}} onClick={(e) => submit_filter(e)}>Filter</button>
                      </div>

                   </div> 
                 </div>
               </div>
             </div> 
             <div className="col-lg-12">  
               <div className="iq-card">  
                  <div className="col-lg-12" style={{paddingTop:'10px'}}>  
                    <button type="button" className="btn btn-success pull-right" onClick={() => onSubmit_save()}>Submit</button>
                  </div>

                  <div className="col-lg-12 table-responsive" > 
                    <table className="table table-bordered" style={{marginTop:10,fontSize:12,textAlign:'center'}}  id="example">
                     <thead>
                       <tr>
                         <th>SrNo</th>
                         <th>Year Month</th>
                         <th>Code</th>
                         <th>Name</th> 
                         <th>Total Present Days</th>
                         <th>Total Absent Days</th> 
                         <th>Total Leave Taken / Adjusted</th> 
                         <th>Paid Days</th> 
                         <th>Manage Days</th> 
                         <th>Remarks</th> 
                       </tr>
                     </thead>
                     <tbody>  
                        {
                          lists_employees1.length>0 ?( 
                            lists_employees1.map((val_emps, key_emps) => {
                              // console.log(val_emps); 
                              var emp_pk_id=val_emps['emp_id'];
                              var calculate_parent=val_emps['calculate_parent'];
                              var calculate_absent=val_emps['calculate_absent'];
                              var calculate_totalleave=val_emps['calculate_totalleave'];
                              var cal_total_paiddays1=0;

                              cal_total_paiddays1=calculate_parent+calculate_absent+calculate_totalleave;
                              
                              var app_name1='emppaidday#'+emp_pk_id;
                              var app_name2='emppaiddayremak#'+emp_pk_id;
 
                              var theemppad_paidday=(typeof inputFields1[emp_pk_id]!='undefined')?inputFields1[emp_pk_id].emppad_paidday:0;
                              var theemppad_remarks=(typeof inputFields2[emp_pk_id]!='undefined')?inputFields2[emp_pk_id].emppad_remarks:0;
                              
                              
                              return(
                                <tr key={key_emps}>
                                  <td key={11}>{key_emps+1}</td>
                                  <td key={22}>{format(from_date, "yyyy-mm")}</td>
                                  <td key={33}>{val_emps['emp_code']}</td>  
                                  <td key={44}>{val_emps['emp_name']}</td>
                                  <td key={55}>{calculate_parent}</td>
                                  <td key={66}>{calculate_absent}</td>
                                  <td key={77}>{calculate_totalleave}</td>
                                  <td key={88}>{calculate_parent}</td>
                                  <td key={99}>
                                    <input
                                      name="emppad_paidday" 
                                      value={
                                        theemppad_paidday=='' && theemppad_paidday==0 ? val_emps['emppad_paidday'] :theemppad_paidday
                                      }
                                      onChange={(e) => setItemName_day(e.target.value,emp_pk_id)}
                                      type="text"
                                      placeholder="Days"
                                      autoComplete="off"
                                    /> 
                                  </td>
                                  <td key={12}>
                                    <textarea
                                      name="theemppad_remarks" 
                                      value={
                                        theemppad_remarks=='' && theemppad_remarks==0 ? val_emps['theemppad_remarks'] :theemppad_remarks
                                      }
                                      onChange={(e) => setItemName_remark(e.target.value,emp_pk_id)} 
                                      placeholder="Remaks"
                                      autoComplete="off"
                                     />
                                  </td>
                                </tr>
                              )
                            })
                          ):
                            <tr>
                              <th style={{textAlign:'center'}} colSpan={10}>No Data Found.. </th>
                            </tr> 
                        }
                     </tbody>
                    </table> 
                 </div> 
               </div>     
             </div>  
           </div>
         </div>
      </div>
    </>
  );
};

export default Manage_attendance_paidday;
