import React, { useState } from 'react'
import Axios from 'axios'
import Apiconnect from "../../services/Apiconnect.js";
import { useEffect } from 'react';
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import { confirmAlert } from 'react-confirm-alert';
// import { trim } from 'jquery';

const LeaveCategory = () => {
    
    let company_id = Apiconnect.get_company_id()
    const [title, setTitle] = useState("")
    const [catCode, setCatCode] = useState("")
    const [empStatus, setEmpStatus] = useState(1)
    const [leave_cat_lop, set_leave_cat_lop] = useState('')
    const [lc_short_leave, set_lc_short_leave] = useState('No') 
    const [leave_cat_type, set_leave_cat_type] = useState("Regular")
    
    const [all, setAll] = useState([])
    const [editId, setEditId] = useState(0)
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    
    const alert = useAlert(); 

    
    const getAllData = async () => {
        await Apiconnect.postData(`leave/leave_category_getall/${company_id}`).then(res => {
            setAll(res.data.data.reverse());
        }); 
    }
    useEffect(() => {getAllData()}, [])


    const handleAdd = async (e) => {
        e.preventDefault()
        setFullScreenLoader(true);
        let leave_cat_lop2='No';
        let lc_short_leave2='No';
        let leave_cat_compoff2='No';

        if(leave_cat_type=='Loss Of Pay'){
            leave_cat_lop2='Yes';
        }
        if(leave_cat_type=='Short Leave'){
            lc_short_leave2='Yes';
        }
        if(leave_cat_type=='Compensatory Off'){
            leave_cat_compoff2='Yes';
        }

        if(editId){

            const data = {
                leave_cat_title: title,
                leave_cat_code: catCode,
                leave_cat_status: empStatus,
                leave_cat_lop:leave_cat_lop2,
                leave_cat_short_leave:lc_short_leave2,
                leave_cat_compoff:leave_cat_compoff2,
                leave_cat_type:leave_cat_type,
            }

            await Apiconnect.postData(`leave/leave_category_update/${company_id}/${editId}`,data).then(res => {
                //setAll(res.data.data); 
                getAllData() 
                setEditId(0)
                setTitle("")
                setCatCode("")
                setEmpStatus(1)
                setFullScreenLoader(false)
                alert.success("Data updated Successfully")
            });
        }
  
        let info = {leave_cat_title:title, leave_cat_code:catCode, leave_cat_status: empStatus,leave_cat_lop:leave_cat_lop2,lc_short_leave:lc_short_leave2,leave_cat_compoff:leave_cat_compoff2,leave_cat_type:leave_cat_type}
         
        console.log(info);

        if(all.find(item => item.leave_cat_title.trim() === (info.leave_cat_title.trim()))){
            alert.error("Leave category title already exists...Try different name !!")
            setTitle("")
            setCatCode("") 
            setTimeout(() => {
                setFullScreenLoader(false)
              }, "1000")
        }else if(all.find(item => item.leave_cat_code.trim() === info.leave_cat_code.trim())){
            alert.error("Leave category code already exists...Try different code !!")
            setTitle("")
            setCatCode("") 
            setTimeout(() => {
                setFullScreenLoader(false)
            }, "1000")
        }else{

            await Apiconnect.postData(`leave/leave_category_create/${company_id}`, info).then(res => {
                getAllData()
                setTitle("")
                setCatCode("") 
                set_leave_cat_type('Regular');
                setFullScreenLoader(false)
                alert.success("Data added Successfully")
            });
 
        }
    }
    
    const ondlt = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui' style={{borderRadius: "10px", padding: "50px", backgroundColor: "white", boxShadow:" rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                    <h2 style={{textAlign: "center"}}>Are you sure?</h2>
                    <p>Are You Sure You Want To Delete this permanently ? If you delete then you cannot retrive this leave category</p>
                    <div style={{display: "flex", justifyContent: "space-around"}}>
                    <button onClick={onClose} style={{width: 100, height: 40, backgroundColor: "#98fb98", borderRadius: 20, border: "none", boxShadow:" rgba(0, 0, 0, 0.24) 0px 3px 8px", color: "white", border: "#98fb98"}}>No</button>
                    <button
                      onClick={() => {
                        handleDelete(id);
                        onClose();
                      }}
                      style={{width: 150, height: 40, backgroundColor: "#ff726f", borderRadius: 20, border: "none", boxShadow:" rgba(0, 0, 0, 0.24) 0px 3px 8px", color: "white", border:"#ff726f"}}
                    >
                      Yes, Delete it!
                    </button>
                    </div>
                  </div>
                );
              }
        });
      };

    const handleDelete = async (id) => {
        setFullScreenLoader(true) ;
        await Apiconnect.postData(`leave/leave_category_delete/${company_id}/${id}`).then(res => {
            getAllData()
            setFullScreenLoader(false)
            alert.success("Data deleted Successfully")
        });
    }
    
    const handleEdit = (id) => {
        const newEdit = all.find((item) => item.leave_cat_id === id)
        if(newEdit.leave_cat_code !== 3){
            setEditId(id)
            setTitle(newEdit.leave_cat_title)
            setCatCode(newEdit.leave_cat_code)
            set_leave_cat_lop(newEdit.leave_cat_lop)
            set_lc_short_leave(newEdit.leave_cat_short_leave)
            set_leave_cat_type(newEdit.leave_cat_type)
        }
        
    }
    
    let m = {0: "Inactive", 1:"Active", 2:"Deleted"}
 
   
  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">

                    {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Leave Category</h4>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-2 form-group ml-3'>
                                    <label>Title</label>
                                    <input className='form-control' placeholder='Category Title' type='text' name='title' value={title} onChange={e => setTitle(e.target.value)} autoComplete="off"/>
                                </div>
                                <div className='col-md-2 form-group '>
                                    <label>Code</label>
                                    <input className='form-control' placeholder='Category Code' type='text' name='catCode' value={catCode} onChange={e => setCatCode(e.target.value)} autoComplete="off"/>
                                </div> 
                                {/* <div className='' style={{display:'none'}}>
                                    <label>Loss Of Pay</label>
                                    <select
                                        className="form-control"
                                        name="leave_cat_lop" 
                                        onChange={e => set_leave_cat_lop(e.target.value)}
                                        value={leave_cat_lop}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className='' style={{display:'none'}}>
                                    <label>Short Leave</label>
                                    <select className="form-control" name='lc_short_leave' value={lc_short_leave} onChange={e => set_lc_short_leave(e.target.value)}>
                                        <option value="">--Select--</option>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div> */}
                                <div className='col-md-2 form-group '>
                                    <label>Status</label>
                                    <select className="form-control" name='empStatus' value={empStatus} onChange={e => setEmpStatus(e.target.value)}>
                                        <option value="" disabled>status</option>
                                        <option value="1" >active</option>
                                        <option value="0" >inactive</option>
                                    </select>
                                </div>
                                
                                <div className='col-md-2 form-group'>
                                    <label>Category Type</label>
                                    <select className="form-control" name='leave_cat_type' value={leave_cat_type} onChange={e => set_leave_cat_type(e.target.value)}>
                                        <option value="Regular">Regular</option>
                                        <option value="Loss Of Pay">Loss Of Pay</option>
                                        <option value="Short Leave">Short Leave</option>
                                        <option value="Compensatory Off">Compensatory Off</option>
                                    </select>
                                </div>

                                <div className='col-md-1 form-group'>
                                    <button className="btn badge badge-pill badge-success" onClick={e => handleAdd(e)} style={{marginTop:'25px'}}>{editId ? "Update" : "Add"}  </button>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                    <tr className="clr">
                                        <th scope="col">#</th>
                                        <th scope="col">Category Title</th>
                                        <th scope="col">Category Code</th>
                                        <th scope="col">Loss Of Pay</th>
                                        <th scope="col">Short Leave</th>
                                        <th scope="col">Compensatory Off</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (typeof all !='undefined') ?  (
                                            all.map((val, key) => {
                                                
                                                return(
                                                    <tr key={key}>
                                                        <td scope="row">{key + 1}</td>
                                                        <td>{val.leave_cat_title}</td>
                                                        <td>{val.leave_cat_code}</td>
                                                        <td>{val.leave_cat_lop}</td>
                                                        <td>{val.leave_cat_short_leave}</td>
                                                        <td>{val.leave_cat_compoff}</td>
                                                        <td>
                                                            {val.leave_cat_status == 0 ? <div className='badge badge-warning p-2'>{m[val.leave_cat_status]}</div> : ""}
                                                            {val.leave_cat_status == 1 ? <div className='badge badge-primary p-2'>{m[val.leave_cat_status]}</div> : ""}
                                                            {val.leave_cat_status == 2 ? <div className='badge badge-danger p-2'>{m[val.leave_cat_status]}</div> : ""}
                                                        </td>
                                                        <td>
                                                            {val.leave_cat_status == 2 ? "NA" :<button style={{marginRight: 10}}  className="btn badge badge-pill badge-success" onClick={() => handleEdit(val.leave_cat_id)} >Edit</button>}
                                                            {val.leave_cat_status == 2 ? "" :<button style={{marginRight: 10}}  className="btn badge badge-pill badge-danger" onClick={() =>ondlt(val.leave_cat_id)} >Delete</button>}
                                                        </td>
                                                    </tr>
                                                )  
                                            })
                                          ) : (
                                            <tr>
                                                <th colSpan={5} style={{textAlign:'center'}}> No Data Found.. </th>
                                            </tr>
                                          )
                                        }
                                    </tbody>
                                </table>
                              </div>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
    </>
  )
}

export default LeaveCategory