import React, { useState, useEffect } from "react";
import Apiconnect from "../../../services/Apiconnect.js";
import { pageStyles } from "../../../globalStyles.js";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import Modal from "react-modal";

const Hiring_approval_authority = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [desList, setDesList] = useState([]);
  const [desId, setDesId] = useState("");
  const [empList, setEmpList] = useState([]);
  const [empId, setEmpId] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    getDesList();
    getList();
  }, []);

  const getList = async () => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/hiringApprovalAuthGetAll/${Apiconnect.get_company_id()}`);
      const resData = res.data.data;
      setList(resData);
    } catch (error) {
      console.log(error);
    }
  };

  const getDesList = () => {
    setFullScreenLoader(true);
    Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
      setFullScreenLoader(false);
      let _xtract = response.data.data;
      setDesList(_xtract.reverse());
    });
  };
  const getEmpList = (id) => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/work_force_auth_get_emplist_by_designation/${id}/${company_id}`).then((res) => {
      setFullScreenLoader(false);
      setEmpList(Apiconnect.decrypt_obj(res.data.data));
    });
  };
  const handleCreate = async () => {
    console.log(empId, desId);
    try {
      let dataIns = {
        haa_company_id: Apiconnect.get_company_id(),
        haa_emp_id: empId,
        haa_desg_id: desId,
      };
      if (!empId) {
        alert.error("Please Select Employee");
      }
      if (empId !== "") {
        let res = await Apiconnect.postData(`selected_applicants/hiringApprovalAuthCreate`, dataIns);
          alert.success("Success");
          getList()
      }
    } catch (error) {
      console.log(error.message);
      alert.error("Error");
    }
  };

  const removeAuth = async (id) => {
    try {
      const res = await Apiconnect.postData(`selected_applicants/removeAuthById/${id}`);
        alert.success("Removed");
        getList()
    } catch (error) {
      alert.error("error");
      console.log(error);
    }
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <div className="container-fluid">
        <div className="iq-card" style={pageStyles.main_container}>
          <div className="iq-card-header" style={pageStyles.card_head}>
            <h5 className="text-white">Hiring Approval Authority</h5>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-3 m-2 ">
                <select
                  className="form-control"
                  name="desId"
                  value={desId}
                  onChange={(e) => {
                    setDesId(e.target.value);
                    getEmpList(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    ---Select Designation---
                  </option>
                  {desList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 m-2">
                <select className="form-control" name="empId" value={empId} onChange={(e) => setEmpId(e.target.value)}>
                  <option value="" disabled selected>
                    ---Select Employee---
                  </option>
                  {empList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.first_name + "" + item.last_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 m-2">
                <button className="btn btn-sm btn-info float-left" onClick={() => handleCreate()}>
                  Create
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 table-responsive">
                <table className="table table-bordered" style={pageStyles.normal_shadow}>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Designation</th>
                      <th className="text-center">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.designation_name}</td>
                          <td className="text-center">
                            <button className="btn btn-sm btn-danger" onClick={() => removeAuth(item.haa_pk_id)}>
                              Remove
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hiring_approval_authority;
