import React from 'react'
import { pageStyles } from '../../globalStyles'


const Container = ({ children, heading }) => {
  return (
      <div className='content-page' style={pageStyles.main_bg}>
          <div className='container-fluid'>
              <div className='iq-card' style={pageStyles.main_container}>
                  <div className='iq-card-header' style={pageStyles.card_head}>
                      <h4 className='text-white' style={pageStyles.h4_bg}>{ heading}</h4>
                  </div>
                  <div className='iq-card-body'>
                      <div className='row'>
                          {children}
                      </div>
                  </div>
              </div>
          </div>
    </div>
  )
}

export default Container