import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';

const Manage_assessment_forms = () => {
    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert();

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [name, setName] = useState("")
    const [list, setList] = useState([])
    
    useEffect(() => {get_forms()},[])
    
    const get_forms = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/assessment_forms_getall`).then(res =>{
            setFullScreenLoader(false)
            let data = Apiconnect.decrypt_obj(res.data.data)
            setList(data)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(name!==""){
            let data_ins = {
                hpaf_name: name
            }
            Apiconnect.postData(`hiring_application/assessment_forms_create`, data_ins).then(res => {
                get_forms()
                setName("")
                alert.success("Created Successfully")
            })
        }else{
            alert.error("Input Field is Empty")
        }
    }

  return (
    <>
        <div className='content-page'
        style={{
            background: "#ffffff",
            borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"
        }}
        >
            <div className='container-fluid'>
            {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'
                        style={{
                            borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"
                        }}
                        >
                            <div className='iq-card-header d-flex justify-content-between model_head'
                             style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div className='iq-header-title'>
                                    <h4 className='card-title text-white'>Manage Assessment Forms</h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <input type="text" className='form-control' placeholder='Create Assessment Form' name='name' value={name} onChange={(e) =>setName(e.target.value)} />
                                    </div>
                                    <div className='col-md-2'>
                                        <button className='btn btn-sm btn-info' onClick={(e) =>handleSubmit(e)}>Create</button>
                                    </div>
                                    <div className='col-md-4'>
                                        
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-12'
                                    
                                    >
                                        <table className='table table-bordered center'
                                        style={{
                                            borderRadius: 5, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                            border: "50px solid linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
                                    
                                        }}
                                        >
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.length > 0 ? 
                                                    (
                                                        list.map((item, index) => {
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{index +1}</td>
                                                                    <td>{item.hpaf_name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={2}>No Data found</th>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Manage_assessment_forms