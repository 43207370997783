import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const NoDuesResignationInfo = () => {
  const [list, setList] = useState([]);
  const [data, setData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [modalIsOpen4, setModalIsOpen4] = useState(false);
  const [modalIsOpen5, setModalIsOpen5] = useState(false);
  const [modalIsOpen6, setModalIsOpen6] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getAllNoDuesInfoResignation/${Apiconnect.get_company_id()}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      setList(extract);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Resign Date</th>
      <th>Relieve Date</th>
      <th>Notice Period</th>
      <th colSpan={6}>View Report</th>
    </tr>
  );
  const trHeader1 = (
    <tr className="text-center">
      <th>Particulars</th>
      <th>Description</th>
      <th>Recovery / Payment</th>
    </tr>
  );

  console.log(list);

  return (
    <Container heading="No Dues Report- Resignation">
      <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues Payroll
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>Payroll Incharge</td>
              <td>
                <span className="text-muted">{data.payrollHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Notice Period</td>
              <td>{data.ndr_payroll_np_desc}</td>
              <td>{data.ndr_payroll_np_recovery}</td>
            </tr>
            <tr>
              <td>Access leave</td>
              <td>{data.ndr_payroll_al_desc}</td>
              <td>{data.ndr_payroll_al_recovery}</td>
            </tr>
            <tr>
              <td>Salary </td>
              <td>{data.ndr_payroll_sal_desc}</td>
              <td>{data.ndr_payroll_sal_recovery}</td>
            </tr>
            <tr>
              <td>Incentive (if any)</td>
              <td>{data.ndr_payroll_inc_desc}</td>
              <td>{data.ndr_payroll_inc_recovery}</td>
            </tr>
            <tr>
              <td>Gratuity (if payable)</td>
              <td>{data.ndr_payroll_grat_desc}</td>
              <td>{data.ndr_payroll_grat_recovery}</td>
            </tr>
            <tr>
              <td>Any Reimbursement (LTA etc)</td>
              <td>{data.ndr_payroll_reim_desc}</td>
              <td>{data.ndr_payroll_reim_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Modal isOpen={modalIsOpen1} style={customStyles} onRequestClose={() => setModalIsOpen1(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues Accounts
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>Accounts Incharge</td>
              <td>
                <span className="text-muted">{data.accountHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Tour or any other Adv./Salary Adv./Loans (Specify)</td>
              <td>{data.ndr_acc_adv_desc}</td>
              <td>{data.ndr_acc_adv_recovery}</td>
            </tr>
            <tr>
              <td>Imprest</td>
              <td>{data.ndr_acc_imprest_desc}</td>
              <td>{data.ndr_acc_imprest_recovery}</td>
            </tr>
            <tr>
              <td>Company leased House</td>
              <td>{data.ndr_acc_leased_desc}</td>
              <td>{data.ndr_acc_leased_recovery}</td>
            </tr>
            <tr>
              <td>Any 3rd party payments</td>
              <td>{data.ndr_acc_pay_desc}</td>
              <td>{data.ndr_acc_pay_recovery}</td>
            </tr>
            <tr>
              <td>Any other (Specify)</td>
              <td>{data.ndr_acc_other_desc}</td>
              <td>{data.ndr_acc_other_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Modal isOpen={modalIsOpen2} style={customStyles} onRequestClose={() => setModalIsOpen2(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues Department Head
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>Department Head</td>
              <td>
                <span className="text-muted">{data.hodHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Office Information, records</td>
              <td>{data.ndr_hod_office_desc}</td>
              <td>{data.ndr_hod_office_recovery}</td>
            </tr>
            <tr>
              <td>Databases and files</td>
              <td>{data.ndr_hod_files_desc}</td>
              <td>{data.ndr_hod_files_recovery}</td>
            </tr>
            <tr>
              <td>Any other (Specify)</td>
              <td>{data.ndr_hod_other_desc}</td>
              <td>{data.ndr_hod_other_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Modal isOpen={modalIsOpen3} style={customStyles} onRequestClose={() => setModalIsOpen3(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues IT
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>IT Incharge</td>
              <td>
                <span className="text-muted">{data.itHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Email ID</td>
              <td>{data.ndr_it_email_desc}</td>
              <td>{data.ndr_it_email_recovery}</td>
            </tr>
            <tr>
              <td>Laptop / Computer</td>
              <td>{data.ndr_it_lap_desc}</td>
              <td>{data.ndr_it_lap_recovery}</td>
            </tr>
            <tr>
              <td>Data card</td>
              <td>{data.ndr_it_dc_desc}</td>
              <td>{data.ndr_it_dc_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Modal isOpen={modalIsOpen4} style={customStyles} onRequestClose={() => setModalIsOpen4(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues Admin
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>Admin Incharge</td>
              <td>
                <span className="text-muted">{data.adminHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Mobile handset</td>
              <td>{data.ndr_admin_mob_desc}</td>
              <td>{data.ndr_admin_mob_recovery}</td>
            </tr>
            <tr>
              <td>Any other (Specify)</td>
              <td>{data.ndr_admin_other_desc}</td>
              <td>{data.ndr_admin_other_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Modal isOpen={modalIsOpen5} style={customStyles} onRequestClose={() => setModalIsOpen5(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues Library
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>Library Incharge</td>
              <td>
                <span className="text-muted">{data.libHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Books, Periodicals & Newspapers</td>
              <td>{data.ndr_lib_book_desc}</td>
              <td>{data.ndr_lib_book_recovery}</td>
            </tr>
            <tr>
              <td>Any other (Specify)</td>
              <td>{data.ndr_lib_other_desc}</td>
              <td>{data.ndr_lib_other_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Modal isOpen={modalIsOpen6} style={customStyles} onRequestClose={() => setModalIsOpen6(false)}>
        <Card>
          <div className="col-md-12 text-center">
            <div>
              <h4 className="text-muted">
                <label htmlFor="" className="text-muted border-bottom">
                  No Dues HR
                </label>
              </h4>
            </div>
          </div>
          <Table className="col-md-12">
            <tr>
              <td>Employee Name</td>
              <td>
                <span className="text-muted">{data.employeeName}</span>
              </td>
            </tr>
            <tr>
              <td>HR Incharge</td>
              <td>
                <span className="text-muted">{data.hrHead}</span>
              </td>
            </tr>
          </Table>
          <Table className="col-md-12 mt-2" trHeader={trHeader1}>
            <tr>
              <td>Id Card</td>
              <td>{data.ndr_hr_ic_desc}</td>
              <td>{data.ndr_hr_ic_recovery}</td>
            </tr>
            <tr>
              <td>Access Card</td>
              <td>{data.ndr_hr_ac_desc}</td>
              <td>{data.ndr_hr_ac_recovery}</td>
            </tr>
            <tr>
              <td>Missed attendance (if any)</td>
              <td>{data.ndr_hr_ma_desc}</td>
              <td>{data.ndr_hr_ma_recovery}</td>
            </tr>
            <tr>
              <td>Income Tax Proofs (submitted/not submitted)</td>
              <td>{data.ndr_hr_itp_desc}</td>
              <td>{data.ndr_hr_itp_recovery}</td>
            </tr>
            <tr>
              <td>PL Accumulated</td>
              <td>{data.ndr_hr_pl_desc}</td>
              <td>{data.ndr_hr_pl_recovery}</td>
            </tr>
          </Table>
        </Card>
      </Modal>
      <Card className="col-md-12">
        <Table className="col-md-12" trHeader={trHeader}>
          {list.map((item, index) => {
            return (
              <tr key={index} className="text-center">
                <td>{index + 1}</td>
                <td>{item.employeeName}</td>
                <td>{item.employee_code}</td>
                <td>{item.ndr_resign_dt}</td>
                <td>{item.ndr_relieve_dt}</td>
                <td>{item.ndr_notice_period} days</td>
                <td className="flex">
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen(true);
                    }}
                  >
                    Payroll
                  </button>
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen1(true);
                    }}
                  >
                    Account
                  </button>
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen2(true);
                    }}
                  >
                    Department Head
                  </button>
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen3(true);
                    }}
                  >
                    IT
                  </button>
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen4(true);
                    }}
                  >
                    Admin
                  </button>
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen5(true);
                    }}
                  >
                    Library
                  </button>
                  <button
                    className="btn btn-sm btn-info m-2"
                    onClick={() => {
                      setData(item);
                      setModalIsOpen6(true);
                    }}
                  >
                    HR
                  </button>
                </td>
              </tr>
            );
          })}
        </Table>
      </Card>
    </Container>
  );
};

export default NoDuesResignationInfo;
