import React, {useState, useEffect} from 'react'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import Pagination from "react-js-pagination";
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './recruitment_module/recruitment.css'

const Compose_panel = () => {

    let {id} = useParams()

    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id()

    
    /* ------all states----- */
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const [emp_list, setEmp_list] = useState([]);   //emp list
    const [catList, setCategory] = useState([]);
    const [dept_id, setDept] = useState(null);
    const [desg_id, setDesg_id] = useState(null)
    const [activePage, setActivePage] = useState(1);
    const [empStatus, setEmpStatus] = useState(1);
    const [empCount, setempCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('')
    const [des_list, setDesList] = useState([])
    const [selected, setSelected] = useState([]);
    const [allchecked, setallchecked] = useState(null);
    const [name, setName] = useState("")

    /* ------all sideEffects---- */

    useEffect(() => {
        get_emp_list("10", "0", "",'',empStatus,desg_id);
        getEmpCount("",'',empStatus,desg_id);
        getDesignation()
        getCategory()
        get_panel_name()
    }, [])


    /* ------all functions------ */

    const get_panel_name = () => {
        Apiconnect.postData(`hiring_application/get_hiring_panel_name/${id}`).then(res => {
            setName(res.data.data[0].name)
        })
    }

    const get_emp_list = (limit, offset, search, desg_id) => {
        const data = {
          limit: limit,
          offset: offset,
          search: search,
          company_id: Apiconnect.get_company_id(),
          desg_id:desg_id
        };
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_panel_getallemployeelist`, data).then((res) => {
            setEmp_list(res.data.data)
            setFullScreenLoader(false)
          }
        );
      };

    const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * 10;
    let limit = 10;
    get_emp_list(limit, offset, "", desg_id);
    };

    const getEmpCount = (search,desg_id) => {
    
        const data = {
          search: search,
          company_id: Apiconnect.get_company_id(),
          desg_id:desg_id
        };
    
        Apiconnect.postData(`hiring_application/hiring_panel_getEmpSearchcount`,data).then((res) => {
          let _xtract = res.data.data;
          setempCount(_xtract[0].cnt);
        });
      };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let offset = (activePage - 1) * 10;
            let limit = 10;
            
            get_emp_list(limit, offset, searchTerm, desg_id);

            const data = {
                search: searchTerm,
                company_id: Apiconnect.get_company_id(),
                desg_id:desg_id
            };
        
            Apiconnect.postData(`hiring_application/hiring_panel_getEmpSearchcount`,data).then((res) => {
            let _xtract = (res.data.data);
            setempCount(_xtract[0].cnt);
            });
        
        }, 2000)
      
        return () => clearTimeout(delayDebounceFn)

      }, [searchTerm])

       const getDesignation = () => {
            Apiconnect.getData(`designation/getall/${company_id}`).then((response) => {
            let _xtract = response.data.data;
            setDesList(_xtract.reverse());      
            });
        };

        const onDesgChange = (e) => {
            let desg_id = e.target.value;
            get_emp_list("10", "0", "",desg_id);
            getEmpCount('',desg_id)
            setDesg_id(desg_id);
        };


      const getCategory = () => {
        var company_id = Apiconnect.get_company_id();
        Apiconnect.getData(`company_category/getall/${company_id}`).then(
          (response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCategory(_xtract.reverse());
          }
        );
      };

      const selectAll = (e) => {
        if (e.target.checked === true) { 
          setallchecked("checked");
          let ob = {};
          emp_list.map((val, key) => {
            ob[val.id] = val.id;
          });
          setSelected(ob);
        }else{
          setallchecked(null);
          setSelected([]);
        }
    };
    const onInputChange = (e) => {
        setallchecked(null);
        if (e.target.checked === true) {
            setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
            });
        } else {
            setSelected({ ...selected, [e.currentTarget.name]: 0 }); 
        }
    }

    const handleAssign = () => {
        if(Object.keys(selected).length !== 0){
            let ins_ob = {
                hppm_company_id: company_id,
                hppm_panel_id: id,
                panel_members : selected,
                hppm_added_by:emp_id
            }
            setFullScreenLoader(true)
            Apiconnect.postData(`hiring_application/hiring_panel_emp_create`, ins_ob).then((res) => {
                setSelected([])
                setFullScreenLoader(false)
                alert.success("Assigned Successfully")
            })
        }else{
            alert.error("Please Select Panel Members")
        }
        
    }

    console.log(emp_list)


      
  return (
    <>
        <div className='content-page main_bg'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}   
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card main_container'>
                            <div className='iq-card-header card_head'>
                                <div className='iq-header-title' style={{float: "left"}}>
                                    <h4 className='card-title text-white'>Compose Panel for <span className='font-weight-bold'>{name}</span></h4>
                                </div>
                                <Link to={`/hrms_hiring_panel`}><button className='btn btn-sm btn-info'>Back</button></Link>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <select className="form-control" required name="desg_id" value={desg_id} onChange={(e) => onDesgChange(e)}>
                                                <option value="">Select Designation</option>
                                                        {des_list.map((val, key) => {
                                                        return (
                                                                <option key={key} value={val.id}>{val.name}</option>
                                                            );
                                                        })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <button className='btn btn-sm btn-info' onClick={handleAssign}>Assign</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <input style={{border: "1px solid rgb(225, 238, 252)", borderRadius:10, backgroundColor:"rgb(255, 255, 255)", color: "grey"}} type="text" className='fomr-control' placeholder="Search Names..." name='search' onChange={(e) => setSearchTerm(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-12'>
                                        <table className='table table-bordered normal_shadow'>
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th className='text-center'>
                                                        {/* <input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => selectAll(e)} /> */}
                                                    </th>
                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Name</th>
                                                    <th className='text-center'>Designation</th>
                                                    <th className='text-center'>Panels Associated</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    emp_list.length > 0 ?
                                                    (
                                                        emp_list.map((item, index) => {

                                                            let ck = Object.keys(selected).find(function (element) {
                                                                return selected[element] == item.id;
                                                            });  
                                                            
                                                            var ck1 = "";
                                                            if (ck !== undefined) {
                                                                ck1 = true;
                                                            }

                                                            let names = ""
                                                            if(item.panels_names===null){
                                                                names = <span className='badge badge-warning'>No Panels Assigned</span>
                                                            }else{
                                                                names = item.panels_names.map((val, key) => {
                                                                    return(
                                                                        <span className='badge badge-success' key={key}>{val.hpp_name}</span>
                                                                    )
                                                                })
                                                            }
                                                            return(
                                                                <tr key={index}>
                                                                    <th scope="row" className='text-center'><input type="checkbox" name={item.id} value={item.id} checked={ck1} onChange={(e) => onInputChange(e)}/></th>
                                                                    <td className='text-center'>{index + 1}</td>
                                                                    <td>{item.first_name} {item.last_name}</td>
                                                                    <td>{item.emp_designation_name}</td>
                                                                    <td className='text-center'>{names}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={4}>No Data Found...</th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div>
                                            <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={50}
                                            totalItemsCount={empCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Compose_panel