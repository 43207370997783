import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Apiconnect from "../../services/Apiconnect.js";
import { format } from "date-fns";
import { pageStyles } from "../../globalStyles.js";

const PreviousEmploymentEms = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));

  const [startDate, setStartDate] = useState(new Date());
  const secondDate = new Date(startDate);
  const [nextDate, setNextDate] = useState(secondDate.getDate() + 1);
  const [endDate, setEndDate] = useState(new Date());
  const [editId, setEditId] = useState(0);
  let empId = Apiconnect.decrypt(localuser.id);

  /* ----------- */
  const [info, setInfo] = useState({
    company_id: Apiconnect.get_company_id(),
    employee_id: empId,
    organisaction_name: "",
    emp_designation: "",
    salary_drawn: "",
    from_date: format(new Date(), "yyyy-MM-dd"),
    to_date: format(new Date(), "yyyy-MM-dd"),
  });
  const { organisaction_name, emp_designation, salary_drawn, from_date, to_date } = info;

  const handleChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  /* ----- get data------ */
  const [data, setData] = useState([]);

  const getAllData = async () => {
    const inf_b = { company_id: Apiconnect.get_company_id(), employee_id: empId };
    await Apiconnect.postData(`leave_manage/employee_pre_emp_list`, inf_b).then((res) => {
      let _xtract = Apiconnect.decrypt_obj(res.data.data);
      setData(_xtract);
    });
  };
  useEffect(() => {
    getAllData();
  }, []);

  /* -----Add----- */

  const handleClick = (e) => {
    e.preventDefault();
    info.from_date = format(startDate, "yyyy-MM-dd");
    info.to_date = format(endDate, "yyyy-MM-dd");
    const inf_a = {
      company_id: Apiconnect.get_company_id(),
      employee_id: empId,
      organisaction_name: info.organisaction_name,
      emp_designation: info.emp_designation,
      salary_drawn: info.salary_drawn,
      from_date: info.from_date,
      to_date: info.to_date,
    };
    console.log(inf_a);
    if (data.find((item) => item.empprev_organisaction_name.includes(inf_a.organisaction_name))) {
      alert.error("Organisation name already Exists");
      setInfo({
        company_id: Apiconnect.get_company_id(),
        employee_id: empId,
        organisaction_name: "",
        emp_designation: "",
        salary_drawn: "",
        from_date: format(new Date(), "yyyy-MM-dd"),
        to_date: format(new Date(), "yyyy-MM-dd"),
      });
    } else if (inf_a.organisaction_name === "" || inf_a.emp_designation === "" || inf_a.salary_drawn === "") {
      alert.error("Please enter details");
    } else {
      Apiconnect.postData("leave_manage/employee_pre_emp_create", inf_a).then((res) => {
        alert.success("Previous Employment Added");
        getAllData();
        setInfo({
          company_id: Apiconnect.get_company_id(),
          employee_id: empId,
          organisaction_name: "",
          emp_designation: "",
          salary_drawn: "",
          from_date: format(new Date(), "yyyy-MM-dd"),
          to_date: format(new Date(), "yyyy-MM-dd"),
        });
      });
    }
  };

  /* delete */
  const handleDelete = async (id) => {
    await Apiconnect.postData(`leave_manage/employee_pre_emp_delete`, { empprev_pk_id: id }).then((res) => getAllData());
  };
  return (
    <>
      <div id="content-page" className="content-page" style={pageStyles.main_bg}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="iq-card" style={pageStyles.main_container}>
                <div className="iq-card-header d-flex justify-content-between" style={pageStyles.card_head}>
                  <div style={{ float: "left" }} className="iq-header-title">
                    <h4 className="card-title text-white" style={pageStyles.h4_bg}>
                      Previous Employment
                    </h4>
                  </div>
                </div>
                <div className="border">
                  <div className="bbox" style={{ padding: "10px" }}>
                    <div className="row" style={{ justifyContent: "space-between" }}>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-muted">Name of the Company/ School/ College</label>
                          <input type="text" className="form-control" name="organisaction_name" value={organisaction_name} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="text-muted">Period of Service From</label>
                          <DatePicker selected={startDate} onChange={(e) => setStartDate(e)} placeholderText="from date" name="from_date" className="form-control" dateFormat="yyyy-MM-dd" required="" showMonthDropdown showYearDropdown scrollableYearDropdown scrollableMonthDropdown yearDropdownItemNumber={100} dropdownMode="select" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="text-muted">Period of Service To</label>
                          <DatePicker selected={endDate} onChange={(e) => setEndDate(e)} placeholderText="To Date" minDate={startDate} name="to_date" className="form-control" dateFormat="yyyy-MM-dd" required="" showMonthDropdown showYearDropdown scrollableYearDropdown scrollableMonthDropdown yearDropdownItemNumber={100} dropdownMode="select" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="text-muted">Designation</label>
                          <input type="text" className="form-control" name="emp_designation" value={emp_designation} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="text-muted">Salary Drawn</label>
                          <input type="text" className="form-control" name="salary_drawn" value={salary_drawn} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button type="button" name="id" className="btn btn-sm btn-info mr-2" onClick={handleClick}>
                          {editId ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="iq-card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-bordered" style={pageStyles.main_container}>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Organisation</th>
                              <th scope="col">From</th>
                              <th scope="col">To</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Salary Drawn</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((value, key) => {
                              let s = value.empprev_date_from.slice(0, 10);
                              let e = value.empprev_date_to.slice(0, 10);

                              return (
                                <tr>
                                  <th>{key + 1}</th>
                                  <th>{value.empprev_organisaction_name}</th>
                                  <th>{s}</th>
                                  <th>{e}</th>
                                  <th>{value.empprev_designation}</th>
                                  <th>{value.empprev_status}</th>
                                  <th>
                                    <button
                                      type="button"
                                      className="btn btn-danger mr-2"
                                      onClick={() => {
                                        handleDelete(value.empprev_pk_id);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousEmploymentEms;
