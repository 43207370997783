import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "react-modal";

const NoDuesResig = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/getAllResignationList/${Apiconnect.get_company_id()}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      setList(extract);
    });
  };

  const trHeader = (
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Employee Code</th>
      <th>Email</th>
      <th>Manage</th>
    </tr>
  );

  const handleCreate = (empId, join, resign, relieve, notice, desig) => {
    const dataIns = {
      ndr_company_id: Apiconnect.get_company_id(),
      ndr_emp_id: empId,
      ndr_join_dt: join,
      ndr_resign_dt: resign,
      ndr_relieve_dt: relieve,
      ndr_notice_period: notice,
      ndr_desig: desig,
    };

    Apiconnect.postData(`separation/createResignationNoDues`, dataIns)
      .then((res) => {
        toast.success("Success");
        getList();
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });
  };

  console.log(list);

  return (
    <Container heading="No Dues - Resignation">
      <Card>
        <Table className="col-md-12" trHeader={trHeader}>
          {list.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.emp_name}</td>
                <td>{item.emp_code}</td>
                <td>{item.emp_email}</td>
                <td>
                  <button
                    className={`btn btn-sm btn-info m-1 ${item.er_noDues === 1 && "disabled"}`}
                    onClick={() => {
                      handleCreate(item.er_emp_id, item.joiningDate, item.er_resign_dt, item.er_last_working_day, item.noticePeriod, item.designationId);
                    }}
                  >
                    Create
                  </button>
                  {/* <button className="btn btn-sm text-center btn-info m-1">
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </button> */}
                </td>
              </tr>
            );
          })}
        </Table>
      </Card>
    </Container>
  );
};

export default NoDuesResig;
