import React, { useState, useEffect } from "react";
import { pageStyles } from "../../globalStyles";
import Apiconnect from "../../services/Apiconnect";
import { Link } from "react-router-dom";
import moment from "moment";
import FullScreenLoading from "react-fullscreen-loading";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Employee_logs = () => {
  const empId = useParams().id;
  const alert = useAlert();
  const company_id = Apiconnect.get_company_id();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [empDetails, setEmpDetails] = useState([]);

  const [list, setList] = useState([]);

  useEffect(() => {
    getLogList();
    getEmpInfo();
  }, []);

  const getEmpInfo = async () => {
    try {
      const res = await Apiconnect.getData(`employee/get/${empId}`);
      const resData = Apiconnect.decrypt_obj(res?.data?.data);
      setEmpDetails(resData[0]);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getLogList = async () => {
    try {
      const res = await Apiconnect.postData(`employee/get_all_logs_by_empId/${empId}`);
      const resData = res?.data?.data;
      setList(resData);
    } catch (error) {
      //console.log(error.message);
    }
  };

  // console.log(list);

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <div className="container-fluid">
        {fullScreenLoader ? <FullScreenLoading loading loaderColor="#b3bfc7" /> : null}
        <div className="row">
          <div className="col-md-12"> 
            <Link to="/employee_manage_list" className="btn btn-primary pull-right">Back</Link>
          </div>
          <div className="col-md-12" style={{marginTop:10}}>
            <div className="iq-card" style={pageStyles.main_container}>
              <div className="iq-card-header" style={pageStyles.card_head}>
                <h4 className="text-white">
                  Change Logs of{" "}
                  <span className="font-weight-bold">
                    {empDetails.first_name} {empDetails.last_name}
                  </span>
                </h4>
              </div>
              <div className="iq-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" style={pageStyles.normal_shadow}>
                        <thead className="thead-light">
                          <tr>
                            <th className="text-center">Time Stamp</th>
                            <th className="text-center">Log Type</th>
                            <th className="text-center">Log Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length > 0 ? (
                            list?.map((item, index) => {
                              let ts = moment(item.emplog_datetime).format("MMMM Do YYYY, h:mm:ss a");
                              return (
                                <tr key={index}>
                                  <td>{ts}</td>
                                  <td>
                                    <span className="badge badge-small badge-primary">{item.emplog_type}</span>
                                  </td>
                                  <td>
                                    {item.emplog_message}{" "}
                                    {item.changed_by ? (
                                      <>
                                        By <span className="font-weight-bold text-info">{item.changed_by}</span>
                                      </>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="font-weight-bold text-muted text-center" colSpan={3}>
                                No Record Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee_logs;
