import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";
import Chart from "react-google-charts";
import Calendar from "./Calendar.js";
import Modal from "react-modal";
import moment from "moment";
import { pageStyles } from "../../globalStyles.js";

function Dashboard() {
  const getinfo = () => {
    Apiconnect.getData(`test/getall`).then((response) => {
      let mm = response.data.message;
      let info = Apiconnect.decrypt_obj(response.data.data);

      let rrr = Apiconnect.encrypt("Welcome Jarvis ");
      let rrrx = Apiconnect.decrypt(rrr);

      // console.log(response.data);
      // console.log(rrr);
      // console.log(rrrx);
      // console.log('---hhhhhh--');
    });
  };

  useEffect(() => {
    getEmployeeCount();
    getCompanyCount();
    getLeaveList();
    getDeparmentCount();
    getdailyAttendance();
    getBirthdayList();
  }, []);
  const [emp_count, setEmpCount] = useState([]);
  const [count_company, setCompanyCount] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [dailyAttendance, setdailyAttendance] = useState([]);
  const [list_birthday, setlist_birthday] = useState([]);

  const getEmployeeCount = () => {
    Apiconnect.getData(`employee/getemployeecount/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      // console.log("emp",_xtract);
      setEmpCount(_xtract.total);
    });
  };

  const getdailyAttendance = () => {
    Apiconnect.getData(`employee/getdailyAttendance/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setdailyAttendance(_xtract.reverse());
    });
  };

  // console.log(dailyAttendance);
  var year = new Date().getFullYear();
  var month = new Date().getMonth() + 1;
  var days = new Date(year, month, 0).getDate();
  const dataattr = [["Date", "Attendance"]];

  //   for (var i = 1; i <= days; i++) {
  //    var attr_data = dailyAttendance.find(item => Number(i) === Number(item.day));
  //    if(attr_data != '' && attr_data != undefined){
  //       let per = attr_data.cnt/emp_count*100;
  //       console.log(per);
  //       dataattr.push([i,per]);
  //    }else{
  //       dataattr.push([i,0]);
  //    }
  // }

  dailyAttendance.map((val, key) => {
    let per = (val.cnt / emp_count) * 100;
    dataattr.push([val.date, per]);
  });

  const getCompanyCount = () => {
    Apiconnect.getData(`company/getcompanycount`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      // console.log(_xtract);
      setCompanyCount(_xtract.total);
    });
  };

  const [count_cat, setCatCount] = useState([]);
  const getDeparmentCount = () => {
    Apiconnect.getData(`company_category/getcount/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);

      setCatCount(_xtract.total);
    });
  };

  const [leaveList, setleaveList] = useState([]);

  const getLeaveList = () => {
    Apiconnect.getData(`employee_leave/getallbyapprove/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      // console.log(_xtract);
      setleaveList(_xtract.reverse());
    });
  };

  const getBirthdayList = () => {
    Apiconnect.getData(`company_holiday/getdatabithdayupcoming/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setlist_birthday(_xtract);
    });
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div id="content-page" className="content-page" style={pageStyles.main_bg}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="iq-card" style={pageStyles.main_container}>
                <div className="iq-card-body">
                  <div className="row row-eq-height">
                    <div className="col-lg-2 col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="iq-card" style={pageStyles.normal_shadow}>
                            <div className="iq-card-body">
                              <div className="row">
                                <div className="col-lg-12 mb-2 d-flex justify-content-between">
                                  <div className="icon iq-icon-box rounded-circle iq-bg-primary rounded-circle" data-wow-delay="0.2s">
                                    <i className="ri-account-box-line"></i>
                                  </div>
                                </div>
                                <div className="col-lg-12 mt-3">
                                  <h6 className="card-title text-uppercase text-secondary mb-0">Employee</h6>
                                  <span className="h2 text-dark mb-0 d-inline-block w-100">{emp_count}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="iq-card" style={pageStyles.normal_shadow}>
                            <div className="iq-card-body">
                              <div className="row">
                                <div className="col-lg-12 mb-2 d-flex justify-content-between">
                                  <div className="icon iq-icon-box rounded-circle iq-bg-success rounded-circle" data-wow-delay="0.2s">
                                    <i className="ri-clockwise-2-line"></i>
                                  </div>
                                </div>
                                <div className="col-lg-12 mt-3">
                                  <h6 className="card-title text-uppercase text-secondary mb-0">Department</h6>
                                  <span className="h2 text-dark mb-0 d-inline-block w-100">{count_cat}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-10 col-md-12">
                      <div className="iq-card" data-wow-delay="0.8s" style={pageStyles.normal_shadow}>
                        <div className="iq-card-header d-flex justify-content-between" style={pageStyles.card_head}>
                          <div className="iq-header-title">
                            <h4 className="card-title text-light">Daily User Attendance</h4>
                          </div>
                        </div>
                        <div className="iq-card-body p-0">
                          {/* <div id="chart-19"></div> */}

                          <div style={{ display: "flex", maxWidth: 900, marginLeft: "30px", marginTop: "10px" }}>
                            <Chart
                              width={"100%"}
                              height={"320px"}
                              chartType="Bar"
                              loader={<div>Loading Chart</div>}
                              data={dataattr}
                              options={{
                                // Material design options
                                hAxis: {
                                  title: "Total Population",
                                  maxValue: 100,
                                },
                              }}
                              // For tests
                              rootProps={{ "data-testid": "1" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12" style={{ "margin-bottom": "20px" }}>
                      <div className="iq-card" style={pageStyles.normal_shadow}>
                        <div className="iq-card-body">
                          <div className="row">
                            <div className="col-lg-12 text-center">
                              <div>{<Calendar />}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="iq-card" style={pageStyles.normal_shadow}>
                        <div className="iq-card-header d-flex justify-content-between" style={pageStyles.card_head}>
                          <div className="iq-header-title">
                            <h4 className="card-title text-light">Leaves</h4>
                          </div>
                        </div>
                        <div className="iq-card-body">
                          <div className="table-responsive">
                            <table className="table mb-0 table-borderless" style={pageStyles.normal_shadow}>
                              <thead className="thead-light">
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Department</th>
                                  <th>Applied On</th>
                                  <th>Leave Type</th>
                                  <th>Category</th>
                                  <th>Reason</th>
                                  <th>Days</th>
                                  <th>Date</th>
                                  <th>Approval Authority </th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {leaveList.length > 0 ? (
                                  leaveList.map((val, key) => {
                                    if (val.status == 0) {
                                      var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                    }
                                    if (val.status == 1) {
                                      var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                    }
                                    if (val.status == 2) {
                                      var status = <div className=" badge badge-pill badge-danger">Rejected</div>;
                                    }
                                    // console.log(val);
                                    return (
                                      <tr key={key}>
                                        <th scope="row">{key + 1}</th>
                                        <td>{val.emp_name + " " + val.middle_name + " " + val.last_name}</td>
                                        <td>{val.department}</td>
                                        <td>{val.applied_on}</td>
                                        <td>{Capitalize(val.typ)}</td>
                                        <td>{val.leave_cat_code}</td>
                                        <td>{val.reason}</td>
                                        <td>{val.leave_count}</td>
                                        <td>
                                          {val.leave_start_date1} to {val.leave_end_date1}
                                        </td>
                                        <td>{val.approve_emp_name}</td>
                                        <td> {status} </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <th colSpan={11} style={{ textAlign: "center" }}>
                                      {" "}
                                      No Data Found..{" "}
                                    </th>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="iq-card" style={pageStyles.normal_shadow}>
                        <div className="iq-card-header d-flex justify-content-between" style={pageStyles.card_head}>
                          <div className="iq-header-title">
                            <h4 className="card-title text-light">Upcoming Birthday</h4>
                          </div>
                        </div>
                        <div className="iq-card-body">
                          <div className="table-responsive">
                            <table className="table table-bordered" style={pageStyles.normal_shadow}>
                              <thead className="thead-light">
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Id</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list_birthday.length > 0 ? (
                                  list_birthday.map((val, key) => {
                                    return (
                                      <tr key={key}>
                                        <th scope="row">{key + 1}</th>
                                        <td>
                                          {val.first_name} {val.last_name}
                                        </td>
                                        <td>{val.emp_code}</td>
                                        <td>{moment(new Date(val.date_of_birth2)).format("YYYY-MM-DD")}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <th colSpan={4} style={{ textAlign: "center" }}>
                                      {" "}
                                      No Data Found..{" "}
                                    </th>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
