import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";
import Apiconnect from "../services/Apiconnect.js";
import { page_styles, pageStyles } from "../globalStyles";

function Header() {
  let history = useHistory();
  const localuser = JSON.parse(localStorage.getItem("user"));
  let downline = localuser.downline;
  let username = localuser.username;
  const [approvalList, setApprovalList] = useState([]);

  function down() {
    if (downline > 0) {
      return (
        <li>
          <a href="#emp_manage" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i className="ri-chat-check-line"></i>
            <span>Team Management</span>
            <i className="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="emp_manage" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            {
              //<li><Link to="/ems/team_member_attendance">Team Member Attendance</Link></li>
            }
            <li>
              <Link to="/ems/my_team">My Team</Link>
            </li>
            <li>
              <Link to="/ems/attendance_master_report">Attendance Master Report</Link>
            </li>
            <li>
              <Link to="/ems/manual_attendance_master">Manual Attendance</Link>
            </li>
            <li>
              <Link to="/ems/team_member_leaves">Team Member Leaves</Link>
            </li>
            <li>
              <Link to="/ems/team_member_short_leaves">Team Member Short Leave</Link>
            </li>
          </ul>
        </li>
      );
    } else {
      return null;
    }
  }

  function logOut() {
    AuthService.logout();
    history.push("/");
  }

  function resetPassword() {
    history.push("/ems/resetPassword/");
  }

  const locusr = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(locusr.id);
  let company_name = locusr.company_name;
  let fullname = locusr.fullname;

  const [profile_image, setprofile_image] = useState(null);
  const [logo, setlogo] = useState(null);
  const [empExit, setEmpExit] = useState(false);
  const [interviewedList, setInterviewedList] = useState(false);

  useEffect(() => {
    getprofile();
    getCompany();
    getApplicantFinalApprovalList();
    manageGrievanceStatus();
    retireEmpListbyEmpId();
    getEmpInfo();
    getUserInfo();
    empExitInterview();
    empExitInterviewedList();
  }, []);

  const empExitInterview = () => {
    Apiconnect.postData(`separation/emp_exit_interview_empList/${Apiconnect.get_company_id()}`).then((res) => {
      const data = Apiconnect.decrypt_obj(res.data.data);
      const result = data.length > 0 && data.filter((item, index) => item.id == user_id);
      if (result.length !== 0) {
        setEmpExit(true);
      }
    });
  };
  const empExitInterviewedList = () => {
    Apiconnect.postData(`separation/emp_exit_interviewed_list/${Apiconnect.get_company_id()}`).then((res) => {
      const data = Apiconnect.decrypt_obj(res.data.data);
      const result = data.length > 0 && data.filter((item) => item.eei_emp_id == user_id);
      if (result.length !== 0) {
        setInterviewedList(true);
      }
    });
  };

  const getprofile = () => {
    Apiconnect.getData(`employee/get/${user_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);

      if (_xtract[0].display_picture == null) {
        setprofile_image("/images/user/1.jpg");
      } else {
        var img = process.env.React_App_BASE_URL_API + "/uploads/thumbdp/" + _xtract[0].display_picture;
        setprofile_image(img);
      }
      // setInfo({ name: _xtract[0].name, state_id: _xtract[0].state_id, id: _xtract[0].id });
    });
  };

  const getCompany = () => {
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].logo == null) {
        setlogo("images/logo.png");
      } else {
        var img = `${process.env.React_App_BASE_URL_API}/uploads/logo/` + _xtract[0].logo;
        setlogo(img);
      }
    });
  };

  const getApplicantFinalApprovalList = async () => {
    try {
      const data = {
        empId: user_id,
        companyId: Apiconnect.get_company_id(),
      };
      const res = await Apiconnect.postData(`selected_applicants/applicantApprovalListGetByEmpId`, data);
      const resData = res.data.data;
      setApprovalList(resData);
    } catch (error) {
      console.log(error);
    }
  };

  const [grievanceList, setGrievanceList] = useState([]);
  const manageGrievanceStatus = async () => {
    try {
      const response = await Apiconnect.postData(`grievance/manageGrievanceStatusByEmpId/${user_id}`);
      const resultData = response.data.data;
      setGrievanceList(resultData);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [retireList, setRetireList] = useState([]);
  const retireEmpListbyEmpId = () => {
    try {
      Apiconnect.postData(`separation/allRetireEmployees/${user_id}/${Apiconnect.get_company_id()}`).then((res) => {
        const result = Apiconnect.decrypt_obj(res.data.data);
        setRetireList(result);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const [retirement, setRetirement] = useState(false);
  const getEmpInfo = () => {
    Apiconnect.postData(`separation/empRetirementInfoByEmpId/${user_id}`).then((res) => {
      const data = Apiconnect.decrypt_obj(res.data.data);
      const retireYear = data[0].retirement_on.slice(-4);
      const now = new Date();
      const currentYear = now.getFullYear();

      if (Number(currentYear) === Number(retireYear)) {
        setRetirement(true);
      }
    });
  };

  const [emp, setEmp] = useState({});
  const getUserInfo = () => {
    Apiconnect.postData(`separation/extensionOfferById/${user_id}`).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data);
      setEmp(extract[0]);
    });
  };

  return (
    <>
      <div className="wrapper" style={pageStyles.main_bg}>
        <div className="iq-sidebar" style={page_styles.main_container_bg}>
          <div className="iq-sidebar-logo d-flex justify-content-between" style={{ background: "transparent" }}>
            <Link to="">
              <img src={logo} className="img-fluid" alt="" />
              <span>HRMS</span>
            </Link>
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu">
                <div className="line-menu half start"></div>
                <div className="line-menu"></div>
                <div className="line-menu half end"></div>
              </div>
            </div>
          </div>
          <div id="sidebar-scrollbar">
            <nav className="iq-sidebar-menu">
              <ul id="iq-sidebar-toggle" className="iq-menu">
                <li className="iq-menu-title">
                  <i className="ri-separator"></i>
                  <span>Main</span>
                </li>
                <li>
                  <Link to="/ems/dashboard">
                    <i className="ri-home-4-line text-primary"></i> Dashboard
                  </Link>
                </li>

                <li>
                  <Link to="/ems/leave_manage">
                    <i className="fa fa-sign-out" aria-hidden="true"></i> Leave
                  </Link>
                </li>
                <li>
                  <Link to="/ems/short_leave">
                    <i className="fa fa-ticket"></i> Short Leave
                  </Link>
                </li>

                <li>
                  <Link to="/ems/yearly_holidays">
                    <i className="fa fa-calendar-check-o"></i> Holidays
                  </Link>
                </li>
                <li>
                  <Link to="/ems/daily_attendance">
                    <i className="fa fa-calendar" aria-hidden="true"></i> Attendance
                  </Link>
                </li>
                <li>
                  <Link to="/ems/manual_attendance">
                    <i className="fa fa-calendar" aria-hidden="true"></i> Manual Attendance
                  </Link>
                </li>
                {/* 
                     <li><Link to="/ems/reimbursement"><i className="fa fa-gift"></i> Reimbursement</Link></li>
                     <li><Link to="/ems/conveyance"><i className="fa fa-car"></i> Conveyance</Link></li>
                     <li><Link to="/ems/salary_breakup"><i className="fa fa-ticket"></i> Salary Breakup</Link></li> */}
                <li>
                  <Link to="/ems/salary_slip">
                    <i className="fa fa-money" aria-hidden="true"></i> Salary Slip
                  </Link>
                </li>
                <li>
                  <Link to="/ems/employee_manage_qualification">
                    <i className="fa fa-university" aria-hidden="true"></i> Educational Qualification
                  </Link>
                </li>
                <li>
                  <Link to="/ems/PreviousEmploymentEms">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    Previous Employment
                  </Link>
                </li>

                <li>
                  <Link to="/ems/Employee_compensatory_off">
                    <i className="fa fa-ticket"></i> Compensatory off
                  </Link>
                </li>
                <li>
                  <Link to="/ems/Work_force_request">
                    <i className="fa fa-hourglass" aria-hidden="true"></i>Work Force Vacancies Request
                  </Link>
                </li>
                <li>
                  <Link to="/ems/Manage_applicant_assessment">
                    <i className="fa fa-hourglass" aria-hidden="true"></i>Manage applicant assessment
                  </Link>
                </li>
                {approvalList.length > 0 && (
                  <li>
                    <Link to="/ems/Applicant_approval">
                      <i className="fa fa-hourglass" aria-hidden="true"></i>
                      Manage Applicant Approval
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/ems/Post_grievance">
                    <i className="fa fa-hourglass" aria-hidden="true"></i>Post Grievance
                  </Link>
                </li>
                {grievanceList.length > 0 && (
                  <li>
                    <Link to="/ems/Manage_grievance_status">
                      <i className="fa fa-hourglass" aria-hidden="true"></i>
                      Resolve Grievance
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/ems/Separation">
                    <i className="fa fa-hourglass" aria-hidden="true"></i>
                    Separation
                  </Link>
                </li>
                {retirement && (
                  <li>
                    <Link to="/ems/Retirement">
                      <i className="fa fa-hourglass" aria-hidden="true"></i>
                      Retirement
                    </Link>
                  </li>
                )}
                {retireList.length > 0 && (
                  <li>
                    <Link to="/ems/RetireEmps">
                      <i className="fa fa-hourglass" aria-hidden="true"></i>
                      Retired Teachers
                    </Link>
                  </li>
                )}
                {emp.offer_extension == 1 && (
                  <li>
                    <Link to="/ems/ExtensionOffer">
                      <i className="fa fa-hourglass" aria-hidden="true"></i>
                      Extension Offer
                    </Link>
                  </li>
                )}
                {empExit && (
                  <li>
                    <Link to="/ems/Emp_exit_interview">
                      <i className="fa fa-hourglass" aria-hidden="true"></i>Exit Interview
                    </Link>
                  </li>
                )}
                {down()}
                <li>
                  <Link to="/ems/NoDues">
                    <i className="fa fa-hourglass text-primary" aria-hidden="true"></i>
                    Employee No Dues
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="p-3"></div>
          </div>
        </div>

        <div className="iq-top-navbar" style={page_styles.main_container_bg}>
          <div className="iq-navbar-custom">
            <div className="iq-sidebar-logo" style={{ background: "transparent" }}>
              <div className="top-logo">
                <Link to="" className="logo">
                  <img src={logo} className="img-fluid" alt="" />
                  {window.innerWidth > "450px" && <span style={{ paddingLeft: "5px" }}>{company_name}</span>}
                </Link>
              </div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <span className="mb-0 pl-3 text-muted">{company_name}</span>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="ri-menu-3-line"></i>
              </button>
              <div className="iq-menu-bt align-self-center">
                <div className="wrapper-menu">
                  <div className="line-menu half start"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu half end"></div>
                </div>
              </div>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-list">
                  <li className="nav-item">
                    <a href="#" className="search-toggle iq-waves-effect">
                      <i className="ri-notification-2-line"></i>
                      <span className="bg-danger dots"></span>
                    </a>
                    <div className="iq-sub-dropdown">
                      <div className="iq-card shadow-none m-0">
                        <div className="iq-card-body p-0 ">
                          <div className="bg-danger p-3">
                            <h5 className="mb-0 text-white">
                              All Notifications
                              <small className="badge  badge-light float-right pt-1">4</small>
                            </h5>
                          </div>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">New Order Recieved</h6>
                                <small className="float-right font-size-12">23 hrs ago</small>
                                <p className="mb-0">Lorem is simply</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="images/user/01.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Emma Watson Nik</h6>
                                <small className="float-right font-size-12">Just Now</small>
                                <p className="mb-0">95 MB</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="images/user/02.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">New customer is join</h6>
                                <small className="float-right font-size-12">5 days ago</small>
                                <p className="mb-0">Jond Nik</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40" src="images/small/jpg.svg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Updates Available</h6>
                                <small className="float-right font-size-12">Just Now</small>
                                <p className="mb-0">120 MB</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item iq-full-screen">
                    <a href="#" className="iq-waves-effect" id="btnFullscreen">
                      <i className="ri-fullscreen-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="navbar-list">
                <li>
                  <a href="#" className="search-toggle iq-waves-effect bg-primary text-white">
                    <img src={profile_image} className="img-fluid rounded" alt="user" width={20} height={20} style={page_styles.profile} />
                  </a>
                  <div className="iq-sub-dropdown iq-user-dropdown" style={page_styles.drop_down}>
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white line-height">{username}</h5>
                          <span className="text-white font-size-12">Available</span>
                        </div>

                        <Link to="/ems/editProfile" className="iq-sub-card iq-bg-primary-hover">
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-file-user-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">My Profile</h6>
                              <p className="mb-0 font-size-12">View your profile details.</p>
                            </div>
                          </div>
                        </Link>

                        <Link to={`/ems/employee_upload_image`} className="iq-sub-card iq-bg-primary-hover">
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-file-user-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Change Profile Pic</h6>
                            </div>
                          </div>
                        </Link>

                        <div className="d-flex justify-content-around mb-3">
                          <button className="btn btn-danger btn-sm" onClick={logOut}>
                            Sign out
                          </button>
                          <button className="btn btn-sm btn-info" onClick={resetPassword}>
                            Change Password
                          </button>
                        </div>
                        {/* <div className="d-inline-block w-100 text-center p-3">
                                    <a className="iq-bg-danger iq-sign-btn" onClick={ logOut } role="button">Sign out<i className="ri-login-box-line ml-2"></i></a>
                                 </div> */}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
