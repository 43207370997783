import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Combine_view_emp_team_member_leave from "../../components/combine_view_emp_team_member_leave";

function Team_member_leaves() {
   const alert = useAlert();
   const localuser = JSON.parse(localStorage.getItem('user')); 
   let emp = Apiconnect.decrypt(localuser.id);
   var date = new Date();
   

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">

               <div className="row">
                  <div className="col-lg-12">
                     <Combine_view_emp_team_member_leave
                        options={{ 
                           title: 'Welcome'
                        }}
                        sel_user_id={emp} 
                        company_id={Apiconnect.get_company_id()}  
                        alert={alert} 
                     />
                  </div>
               </div>        
            </div>
         </div>


      </>
   )
}

export default Team_member_leaves
