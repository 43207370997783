import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

const Employee_leave_report = () => {
    let company_id = Apiconnect.get_company_id()
    
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    /* -----------Category list------------ */
    const [catList, setCategory] = useState([]);
    const getCategory = () => {
        var company_id = Apiconnect.get_company_id();
        setFullScreenLoader(true)
        Apiconnect.getData(`company_category/getall/${company_id}`).then(
          (response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCategory(_xtract.reverse());
            setFullScreenLoader(false)
          }
        );
     };
     useEffect(() => {getCategory()}, []);

     /* -----------Group list------------ */
     const [grp, setGrp] = useState([]);
     const get_group_list= async () => {
        const inf_c = {company_id : Apiconnect.get_company_id()};
        await Apiconnect.postData(`leave_manage/leave_group_list`, inf_c).then(res => setGrp(res.data.data)) 
       } 
     useEffect(() => {get_group_list()}, []);

     /* -----------Employee list------------ */
     const [empList, setEmpList] = useState([]);
    //  console.log(empList)

     const getEmployeeList_new = (limit, offset,company_id, search, dept_id, empStatus, grp_id,emp_grp_id='') => {
        const data = {
          limit: 1000,
          offset: offset,
          search: search,
          company_id:company_id,
          dept_id:dept_id,
          empStatus:empStatus,
          group_id: grp_id,
          emp_grp_id:emp_grp_id,
        };
        setFullScreenLoader(true);
        Apiconnect.postData(`employee_management/get_employee_lists`, data).then( (response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setEmpList(_xtract.reverse());
           setFullScreenLoader(false);
        });
      };
      const getempList_new = async (search) => {
        setFullScreenLoader(true)
        const inf_e = {search: search, company_id: company_id}
        // await Apiconnect.postData(`leave_manage/getemployeelist_new`, inf_e).then(res => {
        //    let _xtract = Apiconnect.decrypt_obj(res.data.data);
        //    setEmpList(_xtract.reverse())
        //    setFullScreenLoader(false)
        // });
        getEmployeeList_new(50,0,company_id,search,'','','');
      }

      useEffect(() => {getEmployeeList_new(50,0,company_id,'','','','');}, [])

     /* -----------Input Search------------ */ 
     const inputsearch = (e) => {
        let search = e.target.value;
        getempList_new(search);
      };
    /* -----------Onchange Group------------ */ 
    const [empGroupList, setEmpGroup] = useState([]);
    const getEmpGroup = () => {
        Apiconnect.getData(`common/getallemploymenttypeById/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpGroup(_xtract.reverse());
        });
     }
    useEffect(() => {getEmpGroup()},[]);

    const onInputChange = e => { 
        var company_id = Apiconnect.get_company_id();
        let emp_grp_id = e.target.value; 
        getEmployeeList_new("50", "0",company_id, "", '', '', '',emp_grp_id); 
     };

    /* -----------Onchange Department------------ */ 
    const [dept_id, setDept_id] = useState(null);
    const onDeptChange = (e,env_val) => {
        // console.log(e.target.value);
        var company_id = Apiconnect.get_company_id();
        let dept_id = e.target.value;
        setDept_id(dept_id);
        getEmployeeList_new("50", "0",company_id, "", dept_id, '', '');
        // getEmpCount('',dept_id,empStatus)
     };

    /* -----------Onchange Status------------ */ 
    const [empstatus, setEmpStatus] = useState("")
    const onStatusChange = e => {
        var company_id = Apiconnect.get_company_id();
        const status_id = e.target.value
        getEmployeeList_new("50", "0",company_id, "", '', status_id, '',""); 
    }
 
  return (
    <>
       <div id="content-page" className="content-page">
            <div className="container-fluid">
                {fullScreenLoader
               ? (
               <FullScreenLoading loading loaderColor="#3498db" />
               ) 
               : null
               }
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Employee Leave Report</h4>
                                </div>
                            </div>
                            <div className='row m-2 p-2'>
                                <div className='col-md-4 form-group'>
                                    <label>Department</label>
                                    <select className="form-control" required name="dept_id" onChange={(e) => onDeptChange(e)}>
                                        <option value="" disabled selected>Select Department</option>
                                        {catList.map((val, key) => {
                                            return (
                                            <option key={key} value={val.id}>
                                                {val.name}
                                            </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className='col-md-4 form-group'>
                                    <label>Group</label>
                                    <select className="form-control" name="emp_group"  onChange={e => onInputChange(e)}>
                                        <option value="" disabled selected>Select Group</option>
                                        {
                                            empGroupList.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.id}>{val.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-md-4 form-group '>
                                    <label>Status</label>
                                    <select className="form-control" name='empstatus' onChange={e => onStatusChange(e)}>
                                        <option value="" disabled selected>Select Status</option>
                                        <option value="1" >active</option>
                                        <option value="0" >inactive</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className='row m-2 p-2'>
                                <div className='col-md-3'><input className="form-control" type="text" autocomplete="off" placeholder="Search here..." name="search"  onChange={(e) => inputsearch(e)}/></div>
                            </div>
                            <div className='iq-card-body'>
                            <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col" style={{ textAlign: "center" }}>Profile</th>
                                            <th scope="col">Emp Code</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {empList.map((val, key) => {
                                            if (val.display_picture == null) {
                                                var profile_img = "/images/user/1.jpg";
                                            } else {
                                                const min = 1;
                                                const max = 1000;
                                                var rand = min + Math.random() * (max - min);
                                                var profile_img =
                                                process.env.React_App_BASE_URL_API +
                                                "/uploads/thumbdp/" +
                                                val.display_picture +
                                                "?" +
                                                rand;
                                            }
                                            
                                            
                                            return(
                                                <tr key={val.id}>
                                                    <td>{key+1}</td>
                                                    <td><img src={profile_img} alt="profile-img" className="prof"/></td>
                                                    <td>{val.emp_code}</td>
                                                    <td>{val.first_name} {val.last_name}</td>
                                                    <td><Link to={`/Employee_leave_report_format/${val.id}`}><button className="btn btn-info mr-2 m-1"><i className="fa fa-eye" aria-hidden="true"></i></button></Link></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </>
  )
}

export default Employee_leave_report