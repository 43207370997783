import React, { useEffect, useState } from "react"
import {
  Card,
  Container,
  Input,
  SelectFromOptions,
  CustomModal,
  Table,
} from "../../../components/imports"
import Apiconnect from "../../../services/Apiconnect"
import { pageStyles } from "../../../globalStyles"
import toast from "react-hot-toast"
import moment from "moment"
import Modal from "react-modal"

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "40%",
  },
}

const NoDuesHeads = () => {
  const [deg_list, setDeg_list] = useState([])
  const [emps, setEmps] = useState([])
  const [emp, setEmp] = useState("")
  const [desig, setDesig] = useState("")
  const [list, setList] = useState([])

  useEffect(() => {
    getDesignation()
    getList()
  }, [])

  const getList = () => {
    Apiconnect.postData(
      `separation/no_dues_assigned_list/${Apiconnect.get_company_id()}`
    ).then((res) => {
      const extract = Apiconnect.decrypt_obj(res.data.data)
      console.log(extract)

      extract.length > 0
        ? setList(extract[0])
        : setList({
            payrollHead: null,
            accountsHead: null,
            hodHead: null,
            itHead: null,
            adminHead: null,
            libraryHead: null,
            hrHead: "",
          })
    })
  }

  const getDesignation = () => {
    try {
      Apiconnect.postData(
        `separation/getalldesignationsbycompany/${Apiconnect.get_company_id()}`
      ).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data)
        setDeg_list(extract)
      })
    } catch (error) {
      toast.error("Can't fetch designations")
    }
  }

  const getallemps = (id) => {
    try {
      Apiconnect.postData(
        `separation/getAllEmpByDesignation/${Apiconnect.get_company_id()}/${id}`
      ).then((res) => {
        const extract = Apiconnect.decrypt_obj(res.data.data)
        setEmps(extract)
      })
    } catch (error) {
      toast.error("Error While Fetching Employees")
    }
  }

  const handleSubmit = (name) => {
    const dataIns = {
      ndh_company_id: Apiconnect.get_company_id(),
    }
    dataIns[`${name}`] = emp

    if (emp !== "") {
      Apiconnect.postData(`separation/no_dues_heads_create`, dataIns).then(
        (res) => {
          toast.success("Successfully Assigned")
          setEmp("")
          setDesig("")
          getList()
        }
      )
    }
    emp === "" && toast.error("Please Select Employee")
  }

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Department</th>
      <th>Assigned Employee</th>
      <th>Assign</th>
    </tr>
  )

  console.log(list)

  return (
    <Container heading="No Dues Heads">
      <Card>
        <SelectFromOptions
          className="col-md-4"
          label="Select Designation"
          name="desig"
          value={desig}
          optionOne="Designation List"
          onChange={(e) => {
            setDesig(e.target.value)
            getallemps(e.target.value)
          }}
        >
          {deg_list.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            )
          })}
        </SelectFromOptions>
        <SelectFromOptions
          className="col-md-4"
          label="Select Employee"
          optionOne="Employee List"
          name="emp"
          value={emp}
          onChange={(e) => {
            setEmp(e.target.value)
          }}
        >
          {emps.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.first_name +
                  " " +
                  item.middle_name +
                  " " +
                  item.last_name}
              </option>
            )
          })}
        </SelectFromOptions>
      </Card>
      <Card>
        <Table className="col-md-12" trHeader={trHeader}>
          <tr className="text-center">
            <td>1.</td>
            <td>Payroll</td>
            <td>
              {list.payrollHead ? list.payrollHead : <span>Not Assigned</span>}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_payroll")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td>2.</td>
            <td>Accounts</td>
            <td>
              {" "}
              {list.accountsHead ? (
                list.accountsHead
              ) : (
                <span className="text-muted">Not Assigned</span>
              )}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_accounts")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td>3.</td>
            <td>Hod</td>
            <td>
              {" "}
              {list.hodHead ? (
                list.hodHead
              ) : (
                <span className="text-muted">Not Assigned</span>
              )}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_hod")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td>4.</td>
            <td>IT</td>
            <td>
              {" "}
              {list.itHead ? (
                list.itHead
              ) : (
                <span className="text-muted">Not Assigned</span>
              )}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_it")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td>5.</td>
            <td>Admin</td>
            <td>
              {" "}
              {list.adminHead ? (
                list.adminHead
              ) : (
                <span className="text-muted">Not Assigned</span>
              )}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_admin")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td>6.</td>
            <td>Library</td>
            <td>
              {" "}
              {list.libraryHead ? (
                list.libraryHead
              ) : (
                <span className="text-muted">Not Assigned</span>
              )}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_library")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
          <tr className="text-center">
            <td>7.</td>
            <td>Hr</td>
            <td>
              {list.hrHead ? (
                list.hrHead
              ) : (
                <span className="text-muted">Not Assigned</span>
              )}{" "}
            </td>
            <td>
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  handleSubmit("ndh_hr")
                }}
              >
                Assign
              </button>
            </td>
          </tr>
        </Table>
      </Card>
    </Container>
  )
}

export default NoDuesHeads
