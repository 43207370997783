import React, { useEffect, useState } from "react";
import { pageStyles } from "../../../globalStyles";
import Apiconnect from "../../../services/Apiconnect";
import { useAlert } from "react-alert";
import toast, { Toaster } from "react-hot-toast";
import FullScreenLoading from "react-fullscreen-loading";
import moment from "moment";
import Modal from "react-modal";
import { FcAbout } from "react-icons/fc";

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Post_grievance = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const [loading, setLoading] = useState(false);
  const [grvDept, setGrvDept] = useState([]);
  const [grvCat, setGrvCat] = useState([]);
  const [grfile, setGrFile] = useState("");
  const [inputs, setInputs] = useState({
    gr_company_id: Apiconnect.get_company_id(),
    gr_emp_id: emp_id,
    gr_grievance_dept_id: "",
    gr_grievance_cat_id: "",
    gr_priority: "",
    gr_subject: "",
    gr_desc: "",
  });
  const [errors, setErrors] = useState({
    gr_grievance_dept_id_err: "",
    gr_grievance_cat_id_err: "",
    gr_priority_err: "",
    gr_subject_err: "",
    gr_desc_err: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [list, setList] = useState([]);
  const [logs, setLogs] = useState([]);
  const [cat, setCat] = useState("");
  const [sub, setSub] = useState("");
  const [desc, setDesc] = useState("");
  const {
    gr_grievance_cat_id_err,
    gr_grievance_dept_id_err,
    gr_priority_err,
    gr_subject_err,
    gr_desc_err,
  } = errors;
  const {
    gr_desc,
    gr_grievance_cat_id,
    gr_grievance_dept_id,
    gr_priority,
    gr_subject,
  } = inputs;

  useEffect(() => {
    getGrvDeptList();
    raiseList();
  }, []);

  const raiseList = async () => {
    try {
      const data = {
        empId: emp_id,
        companyId: Apiconnect.get_company_id(),
      };
      const response = await Apiconnect.postData(
        `grievance/getGrievanceRaiseListByEmpId`,
        data
      );
      const resultData = response.data.data;
      setList(resultData);
    } catch (error) {
      toast.error("Could not fetched raised list");
    }
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const getGrvDeptList = async () => {
    try {
      const response = await Apiconnect.postData(
        `grievance/getalldepartmentwiseHead/${Apiconnect.get_company_id()}`
      );
      const responseData = response.data.data;
      setGrvDept(responseData);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getCategoryList = async (id) => {
    try {
      const insData = {
        gc_company_id: Apiconnect.get_company_id(),
        gc_gd_dept_id: id,
      };
      const response = await Apiconnect.postData(
        `grievance/grievanceCategoryByDepartment`,
        insData
      );
      const resultData = response.data.data;
      setGrvCat(resultData);
    } catch (error) {
      console.log(error);
    }
  };
  const validate = () => {
    const error = {
      gr_grievance_dept_id_err: "",
      gr_grievance_cat_id_err: "",
      gr_priority_err: "",
      gr_subject_err: "",
      gr_desc_err: "",
    };
    if (gr_desc === "") {
      error.gr_desc_err = "Description is Empty";
    }
    if (gr_grievance_cat_id === "") {
      error.gr_grievance_cat_id_err = "Category Not Selected";
    }
    if (gr_grievance_dept_id === "") {
      error.gr_grievance_dept_id_err = "Department Not Selected";
    }
    if (gr_priority === "") {
      error.gr_priority_err = "Priority Not Selected";
    }
    if (gr_subject === "") {
      error.gr_subject_err = "Subject is Empty";
    }
    setErrors(error);
    return !Object.values(errors).some((error) => error);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        validate() &&
        gr_desc !== "" &&
        gr_grievance_cat_id !== "" &&
        gr_grievance_dept_id !== "" &&
        gr_priority !== "" &&
        gr_subject !== ""
      ) {
        const dataIns = new FormData();
        dataIns.append("applicant_docs", grfile);
        dataIns.append("gr_company_id", Apiconnect.get_company_id());
        dataIns.append("gr_emp_id", emp_id);
        dataIns.append("gr_grievance_dept_id", gr_grievance_dept_id);
        dataIns.append("gr_grievance_cat_id", gr_grievance_cat_id);
        dataIns.append("gr_priority", gr_priority);
        dataIns.append("gr_subject", gr_subject);
        dataIns.append("gr_desc", gr_desc);
        const response = await Apiconnect.postData(
          `grievance/grievanceRaise`,
          dataIns
        );
        toast.success("Grievance Raised");
        raiseList();
        setInputs({
          gr_company_id: Apiconnect.get_company_id(),
          gr_emp_id: emp_id,
          gr_grievance_dept_id: "",
          gr_grievance_cat_id: "",
          gr_priority: "",
          gr_subject: "",
          gr_desc: "",
        });
      } else {
        toast.error("Validation Error");
      }
    } catch (error) {
      toast.error("Error");
    }
  };

  const handleReopen = async (id) => {
    try {
      await Apiconnect.postData(`grievance/reopenGrievance/${id}`);
      raiseList();
    } catch (error) {
      console.log(error.message);
    }
  };

  const getLogs = (id) => {
    Apiconnect.postData(`grievance/getGrievanceLogs/${id}`).then((res) => {
      setLogs(res.data.data);
    });
  };

  return (
    <div className="content-page" style={pageStyles.main_bg}>
      <Toaster />
      {loading ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="iq-card">
          <div className="iq-card-header card_head">
            <h5 className="text-white">Grievance Resolve Logs</h5>
          </div>
          <div className="iq-card-body ">
            <div className="row mt-3">
              <div className="col-md-12 table-responsive">
                <table className="table table-bordered"></table>
                <table
                  className="table table-bordered"
                  style={pageStyles.main_container}
                >
                  <thead className="thead-light">
                    <tr className="text-center">
                      <th>Resolved On</th>
                      <th>Resolved By</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((item, index) => {
                      const dt = moment(item.grl_createdAt).format(
                        "DD/MM/YYYY"
                      );
                      return (
                        <tr className="text-center" key={index}>
                          <td>{dt}</td>
                          <td>{item.name}</td>
                          <td>{item.grl_message}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid">
        <div className="iq-card" style={pageStyles.main_container}>
          <div className="iq-card-header" style={pageStyles.card_head}>
            <h4 className="text-white" style={pageStyles.h4_bg}>
              Raise Grievance
            </h4>
          </div>
          <div className="iq-card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="iq-card" style={pageStyles.main_container}>
                  <div className="iq-card-body m-2">
                    <div className="row border bbox p-3">
                      <div className="col-md-3">
                        <label className="text-muted">
                          Grievance Department
                        </label>
                        <select
                          className="form-control"
                          name="gr_grievance_dept_id"
                          value={gr_grievance_dept_id}
                          onChange={(e) => {
                            getCategoryList(e.target.value);
                            handleInputChange(e);
                          }}
                        >
                          <option value="" disabled selected>
                            Select Grievance Department
                          </option>
                          {grvDept.map((item, index) => {
                            return (
                              <option key={index} value={item.gd_pk_id}>
                                {item.departmentName}
                              </option>
                            );
                          })}
                        </select>
                        <span className="required">
                          {gr_grievance_dept_id_err}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <label className="text-muted">Category</label>
                        <select
                          className="form-control"
                          name="gr_grievance_cat_id"
                          value={gr_grievance_cat_id}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <option value="" disabled selected>
                            Select Grievance Category
                          </option>
                          {grvCat.length > 0 &&
                            grvCat.map((item, index) => {
                              return (
                                <option key={index} value={item.gc_pk_id}>
                                  {item.gc_name}
                                </option>
                              );
                            })}
                        </select>
                        <span className="required">
                          {gr_grievance_cat_id_err}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <label className="text-muted">Priority</label>
                        <select
                          className="form-control"
                          name="gr_priority"
                          value={gr_priority}
                          onChange={(e) => handleInputChange(e)}
                        >
                          <option value="" disabled selected>
                            Select Grievance Priority
                          </option>
                          <option value={1}>High</option>
                          <option value={2}>Medium</option>
                          <option value={3}>Low</option>
                        </select>
                        <span className="required">{gr_priority_err}</span>
                      </div>
                      <div className="col-md-3">
                        <label className="text-muted">Subject</label>
                        <input
                          type="text"
                          name="gr_subject"
                          value={gr_subject}
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                        />
                        <span className="required">{gr_subject_err}</span>
                      </div>
                      <div className="col-md-12">
                        <label className="text-muted">Description</label>
                        <textarea
                          className="form-control"
                          name="gr_desc"
                          value={gr_desc}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <span className="required">{gr_desc_err}</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="text-muted">
                          Select Supporting Document
                        </label>
                        <input
                          type="file"
                          id="file"
                          className="form-control"
                          name="grfile"
                          onChange={(e) => setGrFile(e.target.files[0])}
                        />
                      </div>

                      <div className="col-md-12 mt-3">
                        <div className="row mt-3">
                          <div className="col-md-12 text-center">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="iq-card" style={pageStyles.main_container}>
                  <div className="iq-card-body">
                    <div className="row">
                      <div className="col-md-12 table-responsive">
                        <table
                          className="table table-bordered"
                          style={pageStyles.main_container}
                        >
                          <thead className="thead-light">
                            <tr className="text-center">
                              <th>#</th>
                              <th>Subject</th>
                              <th>Description</th>
                              <th>Category</th>
                              <th>Department</th>
                              <th>Priority</th>
                              <th>Raised On</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.map((item, index) => {
                              let priority = null;
                              if (item.gr_priority === 1) {
                                priority = (
                                  <span className="badge badge-danger">
                                    High
                                  </span>
                                );
                              } else if (item.gr_priority === 2) {
                                priority = (
                                  <span className="badge badge-warning">
                                    Medium
                                  </span>
                                );
                              } else if (item.gr_priority === 3) {
                                priority = (
                                  <span className="badge badge-success">
                                    Low
                                  </span>
                                );
                              }
                              let status = null;
                              if (item.gr_status === 1) {
                                status = (
                                  <span className="badge badge-warning">
                                    Pending
                                  </span>
                                );
                              } else if (item.gr_status === 2) {
                                status = (
                                  <span className="badge badge-success">
                                    Resolved
                                  </span>
                                );
                              }
                              const formatted = moment(
                                item.gr_createdOn
                              ).format("DD/MM/YYYY h:mm a");
                              return (
                                <tr className="text-center" key={index}>
                                  <td>{index + 1}. </td>
                                  <td>{item.gr_subject}</td>
                                  <td>{item.gr_desc}</td>
                                  <td>{item.grievanceCategoryName}</td>
                                  <td>{item.grievanceDepartment}</td>
                                  <td>{priority}</td>
                                  <td>{formatted}</td>
                                  <td className="text-center">
                                    <div>
                                      {status}
                                      {item.gr_status === 2 && (
                                        <button
                                          className="btn btn-sm btn-info m-3"
                                          onClick={() =>
                                            handleReopen(item.gr_pk_id)
                                          }
                                        >
                                          Reopen
                                        </button>
                                      )}
                                      {item.gr_status === 2 && (
                                        <button
                                          className="btn btn-sm btn-light"
                                          style={pageStyles.main_container}
                                          onClick={() => {
                                            setModalIsOpen(true);
                                            getLogs(item.gr_pk_id);
                                            setCat(item.grievanceCategoryName);
                                            setSub(item.gr_subject);
                                            setDesc(item.gr_desc);
                                          }}
                                        >
                                          <FcAbout />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post_grievance;
