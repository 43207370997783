import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import {useLocation} from 'react-router-dom'
import Apiconnect from "../../services/Apiconnect.js";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';

const LeavePolicyAssignEmp = () => {
    const location = useLocation()
    const policyName = location.pathname.split("/")[2]
    const policyId = location.pathname.split("/")[3]
    const alert = useAlert(); 

    const [list, setList] = useState([])
    const [empStatus, setEmpStatus] = useState("");
    const [dept_id, setDept_id] = useState(null);
    const [grp_id, setGrp_id] = useState("")
    const [searchTerm, setSearchTerm] = useState('')
    const [recordsPerpage, setrecordsPerpage] = useState(1000)
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const getEmpList = () => {
        setFullScreenLoader(true)
        const inf_a = {search: "", dept_id: "", empStatus: "", group_id: "", company_id: Apiconnect.get_company_id(), limit: recordsPerpage, offset: 0}
        Apiconnect.postData(`employee_management/get_employee_lists`, inf_a).then(res => {
          let _xtract = Apiconnect.decrypt_obj(res.data.data)
          setFullScreenLoader(false)
          setList(_xtract)
          let ob = {}
          _xtract.map((val,key) => {
            if(val.lpemp_emp_id === val.id){
              ob[val.lpemp_emp_id] = val.lpemp_emp_id
            }
          })
          // setSelectedEmp(ob)
        })
    }
    useEffect(() => {getEmpList()}, [])
    // console.log(list)

  
/* cat list */
    const [catList, setCategory] = useState([]);
    const getCategory = () => {
      var company_id = Apiconnect.get_company_id();
      setFullScreenLoader(true)
      Apiconnect.getData(`company_category/getall/${company_id}`).then(
        (response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setCategory(_xtract.reverse());
          setFullScreenLoader(false)
        }
      );
    };
    useEffect(() => {getCategory()}, [])

  /* grp list */

  const [grp, setGrp] = useState([])
  const getAll = async () => {
    const inf_c = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_c).then(res => setGrp(res.data.data)) 
}
  useEffect(() => {getAll()}, [])

  /* -----------------------Pagination------------------------ */
  const [activePage, setActivePage] = useState(1);
  
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * recordsPerpage;
    let limit = recordsPerpage;
    getEmployeeList_new(limit, offset, "", dept_id, empStatus, grp_id);
  };

  const getEmployeeList_new = (limit, offset, search, dept_id, empStatus, grp_id) => {
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id,
      empStatus:empStatus,
      group_id: grp_id
    };
    setFullScreenLoader(true)
    Apiconnect.postData(`employee_management/get_employee_lists`, data).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setList(_xtract);
        setFullScreenLoader(false)
        let ob = {}
          _xtract.map((val,key) => {
            if(val.lpemp_emp_id === val.id){
              ob[val.lpemp_emp_id] = val.lpemp_emp_id
            }
          })
          // setSelectedEmp(ob)
      }
    );
  };
  

  const [empCount, setempCount] = useState(0);
  const getEmpCount = (search,dept_id,empStatus) => {

    var dept_id1 = '';
    if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
       dept_id1 = dept_id;
    }

    const data = {
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id1,
      empStatus:empStatus
    };
    

    Apiconnect.postData(
      `employee/getEmpSearchcount`,data
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setempCount(_xtract[0].cnt);
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(searchTerm)
      
      let offset = (activePage - 1) * recordsPerpage;
      let limit = recordsPerpage;
      let group_id = ""
     
      getEmployeeList_new(limit, offset, searchTerm, dept_id,empStatus, grp_id);

      var dept_id1 = '';
      if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
         dept_id1 = dept_id;
      }
  
      var dept_id1 = '';
      const data = {
        search: searchTerm,
        company_id: Apiconnect.get_company_id(),
        dept_id:dept_id1,
        empStatus:empStatus
      };
  
      Apiconnect.postData(
        `employee/getEmpSearchcount`,data
      ).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setempCount(_xtract[0].cnt);
      });
  
    }, 2000)
  
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])
  /*-------------------------------pagination end------------------------- */
  
//select dept
  const onDeptChange = (e) => {
    let offset = (activePage - 1) * recordsPerpage;
    let dept_id = e.target.value;
    setDept_id(dept_id);
    getEmployeeList_new(recordsPerpage, offset, searchTerm, dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  };
//select grp
  const onGrpChange = (e) => {
    let offset = (activePage - 1) * recordsPerpage;
    let grp_id = e.target.value;
    setGrp_id(grp_id)
    getEmployeeList_new(recordsPerpage, offset, searchTerm, dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  }
//select status
  const onStatusChange = (e) => {
    let offset = (activePage - 1) * recordsPerpage;
    let empStatus = e.target.value;
    setEmpStatus(empStatus)
    getEmployeeList_new(recordsPerpage, offset, searchTerm, dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  }
  /* ---------------------------employee select------------------------------ */
  const [selectedEmp, setSelectedEmp] = useState([]);
  const onInputChange2 = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
      // console.log(selectedEmp);
    } else {
      // setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 }); 
      let selKeys = Object.keys(selectedEmp).filter(key => key !== e.currentTarget.name);
      console.log(selKeys);
      let ob1 = {};
      selKeys.map((val, key) => {
        ob1[val] = val;
      });
      setSelectedEmp(ob1);
      console.log(selectedEmp);
    }
   
  };

  const [allchecked, setallchecked] = useState(null);
  // const sellectAll = (e) => {
  //   console.log(e);
  //   if (e.target.checked === true) {
  //     setallchecked("checked");
  //     let ob = {};
  //     list.map((val, key) => {
  //       ob[val.id] = val.id;
  //     });
  //     setSelectedEmp(ob);
  //   } else {
  //     setallchecked(null);
  //     setSelectedEmp([]);
  //   }
  // };
  const sellectAll = (e) => {
    console.log(e.target.checked);
    if (e.target.checked === true) { 
      setallchecked("checked");
      let ob = {};
      list.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
      // setSelectedCountEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
      // setSelectedCountEmp([]);
    }
  };

  /* Assign */
  const handleCreate = async () => {
    if(Object.keys(selectedEmp).length ==0){
      alert.error("Plesae select employees." );
    }else{
      const inf_f = {company_id: Apiconnect.get_company_id(), policy_id: policyId, employee_id: selectedEmp, group_id:grp_id}
      // console.log(inf_f)
      
      setFullScreenLoader(true)
      await Apiconnect.postData(`leave_manage/get_groupwise_employee_create`, inf_f).then(res => {
        // console.log(res.data.message)
        let offset = (activePage - 1) * recordsPerpage;
        setSelectedEmp(['']); 
        setFullScreenLoader(false);
        // getEmployeeList_new(50, 0, '', '','', '');
        getEmployeeList_new(recordsPerpage, offset, searchTerm, dept_id, empStatus, grp_id);
        alert.success(res.data.message);
        
      })
    }
    
  }

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
                    {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
          <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                    <div style={{ float: "left" }} className="iq-header-title">
                        <h4 className="card-title">{policyName}</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Department</label>
                        <select className="form-control" 
                          required
                          name="dept_id"
                          value={dept_id}
                          onChange={(e) => onDeptChange(e)}
                        >
                          <option value="" selected>Choose Department</option>
                          {catList.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Group</label>
                        <select className="form-control" name="grp" value={grp_id} onChange={e => onGrpChange(e)}>
                          <option value="" selected>Choose Group</option>
                          {grp.map((val, key) => {
                              return(
                                <option key={key} value={val.leavegroup_pk_id}>{val.leavegroup_name}</option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Status</label>
                        <select  className="form-control" onChange={e => onStatusChange(e)}>
                          <option value="" disabled selected>status</option> 
                          <option value="1" >Active</option> 
                          <option value="0" >Inactive</option> 
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-3 d-flex mt-5">
                        <input autocomplete="off" className="form-control d-flex end" style={{textAlign: "center"}} placeholder='Search Employee..' onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className="col-md-5 d-flex mt-5" >
                      <button className="btn btn-success" onClick={() => handleCreate()}>Assign</button>
                    </div>
                  </div>
                  <div className="row">
                      <table  className="table table-striped  mt-4 center grid" role="grid" >
                          <thead className="">
                            <tr className="clr">
                              <th> 
                                <input
                                  type="checkbox"
                                  name="select_all"
                                  checked={allchecked}
                                  onChange={(e) => sellectAll(e)} 
                                />
                              </th>
                              <th scope="col">#</th>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Emp Id</th>
                              <th scope="col">Policy Name</th>
                            </tr>
                          </thead>
                          <tbody>
                              {list.map((val, key) => {

                                  if (val.display_picture == null) {
                                    var profile_img = "/images/user/1.jpg";
                                  } else {
                                    const min = 1;
                                    const max = 1000;
                                    var profile_img = process.env.React_App_BASE_URL_API + "/uploads/thumbdp/"+val.display_picture;
                                  }

                                  var ck = Object.keys(selectedEmp).find(function (element) {
                                    return selectedEmp[element] == val.id;
                                    });  
                                    var ck1 = "";
                                    if (ck !== undefined) {
                                        ck1 = true;
                                    }
                                return(
                                  <tr key={key}>
                                    <th scope="row"><input type="checkbox" name={val.id} value={val.id} checked={ck1} onChange={(e) => onInputChange2(e)}/></th>    
                                    <th scope="row">{key + 1}</th>
                                    <td style={{ width: "130px", textAlign: "center" }}>
                                      <div className="" style={{ display: "grid" }}>
                                        <img src={profile_img} alt="profile-img" className="prof"/>
                                      </div>
                                    </td>
                                    <td>{val.first_name} {val.last_name}</td>
                                    <td>{val.emp_code}  </td>
                                    <td>{val.leavepolicy_title ? val.leavepolicy_title : "NA"}</td> 
                                  </tr>
                                )
                              })}
                          </tbody>
                      </table>
                      <Pagination
                      activePage={activePage}
                      itemsCountPerPage={recordsPerpage}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                    </div>
                </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeavePolicyAssignEmp