import React, { useState, useEffect,useRef } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import Loadder from "./Loadder";
import Modal from 'react-modal';
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

function Employee_report() {

    const localuser = JSON.parse(localStorage.getItem('user'));
    const alert = useAlert();


    var date = new Date();
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);
    const [loader, setLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [column, setColumn] = useState(false);
    const csvLink = useRef();
    const [loader1, setLoader2] = useState(false);
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [customFile, setCustomFile] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    useEffect(() => {loadData('50','1',''); getEmpCount("");getCustomColomn()}, []);
    const [empList, setEmpList] = useState([]);

    const clear = () => {
        setToDate(null);
        setFromDate(null);
        loadData('50','1','');
        getEmpCount(searchTerm)
    }

    const handlePageChange = (pageNumber) => {
      
        setActivePage(pageNumber);
        let offset = (pageNumber - 1) * 50 + 1;
        console.log(`active page is ${pageNumber} -- ${offset}`);
        let limit = 50;
        loadData(limit,offset,'');
  
      }

    const submit = () => {
        loadData('50','1','');
        getEmpCount(searchTerm)
    }
    const loadData = (limit,offset,search,from=null,to=null) => {
        setLoader(true);

        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }
        if (to_date != null) {
            var to = format(to_date, 'yyyy-MM-dd');
        }

        let info = { company_id: Apiconnect.get_company_id(), from_date: from, to_date: to,limit:limit,offset:offset,search:search, group_id: selgrp};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee/getallemployeelistreport`, inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            let newData = [];
            _xtract.map((item, index) => {
                newData.push({sr: index + 1, ...item});
            });
            setEmpList(newData);
            setLoader(false);
        });

    }

    const [empCount, setEmpCount] = useState(0);

    const getEmpCount = (search) => {

        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }
        if (to_date != null) {
            var to = format(to_date, 'yyyy-MM-dd');
        }

        let info = { company_id: Apiconnect.get_company_id(), from_date: from, to_date: to,search:search};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        Apiconnect.postData(`employee/getallemployeelistreportcnt`,inf_a).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setEmpCount(_xtract[0].cnt); 
       });
    };
    
   

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          let offset = (activePage - 1) * 50;
          let limit = 50;
         
          loadData(limit, offset, searchTerm);
          getEmpCount(searchTerm)
        }, 2000)
      
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])
      
      const customStyles = {
        content: {
           top: '50%',
           left: '55%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           minWidth:'800px',
           maxHeight:'500px'
          
        },
     };
     
     const customReport = () => {
        setModalIsOpen(true)
     }

     const customReportUpload = () => {
      setUploadModalIsOpen(true)
     }

     const getCustomColomn = () => {
        Apiconnect.getData(`employee/getcustomcolumn/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setColumn(_xtract)
         });   
     }

     const [getRefnum, setRefnum] = useState(['emp_code'])
     const handleChange = (e) => {
      const value = e.target.value;
      if (e.target.checked) {
        setRefnum([...getRefnum, value])
      } else {
        const index = getRefnum.indexOf(value);
        if (index > -1) {
          getRefnum.splice(index, 1);
        }
      }
     }

     const handleChangeAll = (e) => {
      const value = e.target.value;
      if (e.target.checked) {
        const inarr = []
        const inputs = document.getElementsByClassName('child')
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].checked = true
          inarr.push(inputs[i].value)
        }
        setRefnum([...getRefnum, ...inarr])
      } else {
        const index = getRefnum.indexOf(value)
        if (index > -1) {
          getRefnum.splice(index, 1)
        }
        const inputs = document.getElementsByClassName('child')
        for (let ii = 0; ii < inputs.length; ii++) {
          const index1 = getRefnum.indexOf(inputs[ii].value)
          if (index1 > -1) {
            getRefnum.splice(index1, 1)
          }
          inputs[ii].checked = false
        }
      }
     }

     const [dwnCustomRpt,setDwnCustomRpt] = useState(null)

     const reportDownload = () => {
      setFullScreenLoader(true);
      let info = { company_id: Apiconnect.get_company_id(), column:getRefnum};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee/getcustomcolumndata`,inf_a).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setDwnCustomRpt(_xtract)
          setTimeout(function () {csvLink.current.link.click();setFullScreenLoader(false);}, 2000);
      });  
     }

     const handleInputChange = (event) => {
      setCustomFile(event.target.files[0])
  }

  const submitUploadFile = async e => {
    setFullScreenLoader(true)
    const data = new FormData() ;
    data.append('csvfile', customFile);   
     Apiconnect.postData(`upload/employee_custom_bulk_data/${Apiconnect.get_company_id()}`, data).then((response) => {   
      alert.success(response.data.message);  
      setFullScreenLoader(false)
    });
};

const emplistexcel = empList.map((item) => ({
  emp_code: item.emp_code,
  name: `${item.first_name} ${item.last_name}`,
  phone: item.phone,
  email: item.email
}))

const allemplistexcel = empList.map((item) => ({
  id: item.id,
  emp_code: item.emp_code,
  reporting_officer: item.reporting_officer,
  employee_group: item.employee_group,
  department:item.department,
  employement_type:item.employement_type,
  first_name:item.first_name,
  middle_name:item.middle_name,
  last_name:item.last_name,
  gender:item.gender,
  date_of_birth:item.date_of_birth,
  email:item.email,
  phone:item.phone,
  phone_secondary:item.phone_secondary,
  email_secondary:item.email_secondary,
  is_active:item.is_active,
  present_address_one:item.present_address_one,
  present_address_two:item.present_address_two,
  present_city_id:item.present_city_id,
  present_zip:item.present_zip,
  present_landmark:item.present_landmark,
  parmanent_address_one:item.parmanent_address_one,
  parmanent_state_id:item.parmanent_state_id,
  parmanent_city_id:item.parmanent_city_id,
  parmanent_area:item.parmanent_area,
  parmanent_zip:item.parmanent_zip,
  parmanent_landmark:item.parmanent_landmark,
  blood_group:item.blood_group,
  religion:item.religion,
  caste:item.caste,
  nationality:item.nationality,
  marital_status:item.marital_status,
  anniversary_date:item.anniversary_date,
  emergency_contact_person:item.emergency_contact_person,
  emergency_contact_person2:item.emergency_contact_person2,
  pan:item.pan,
  passport_no:item.passport_no,
  payment_mode:item.payment_mode,
  bank_name:item.bank_name,
  ifsc_code:item.ifsc_code,
  account_number:item.account_number,
  is_epf:item.is_epf,
  UAN_number:item.UAN_number,
  epf_number:item.epf_number,
  pf_start_date:item.pf_start_date,
  is_esi:item.is_esi,
  esi_number:item.esi_number,
  dispencery:item.dispencery,
  confirmation_date:item.confirmation_date,
  joining_date:item.joining_date,
  leaving_date:item.leaving_date,
  promotion_date:item.promotion_date,
  transfer_date:item.transfer_date,
  rf_id:item.rf_id,
  emp_shift:item.emp_shift,
  salary_template:item.salary_template,
  ctc_yearly:item.ctc_yearly,
  emp_designation:item.emp_designation
}))

console.log(allemplistexcel)


function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

const saveAsExcel = () => {
  let header = ["Employee Code", "Name", "Phone", "Email"]

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(emplistexcel, header);
    const totalColumns = sheetData[0].length;

    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    range.style("border", true);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "salary data.xlsx");
    });
  });
}

const local_user = JSON.parse(localStorage.getItem('user'));
  const [companyaddress, setCompanyData] = useState([])
  const getCompany = () => {         
  Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
        let ern = Apiconnect.decrypt_obj(response.data.data);
        setCompanyData(ern[0].address_one)
        
     });
  };
  useEffect(() => {getCompany()}, [])


const saveAsExcel2 = () => {
  let header = ["id", "emp_code", "reporting_officer", "employee_group", "department", "employement_type", "first_name", "middle_name", "last_name", "gender","date_of_birth", "email", "phone", "phone_secondary", "email_secondary", "is_active", "present_address_one", "present_address_two", "present_city_id", "present_zip", "present_landmark", "parmanent_address_one", "parmanent_state_id", "parmanent_city_id", "parmanent_area", "parmanent_zip", "parmanent_landmark", "blood_group", "religion", "caste", "nationality", "marital_status", "anniversary_date", "emergency_contact_person", "emergency_contact_person2", "pan", "passport_no", "payment_mode", "bank_name", "ifsc_code", "account_number", "is_epf", "UAN_number", "epf_number", "pf_start_date", "is_esi", "esi_number", "dispencery", "confirmation_date", "joining_date", "leaving_date", "promotion_date", "transfer_date", "rf_id", "emp_shift", "salary_template", "ctc_yearly", "emp_designation"]

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(allemplistexcel, header);
    const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(local_user.company_name);
            sheet1.cell("A2").value(companyaddress)
            sheet1.cell("A3").value("Employee Report");
            sheet1.cell("A5").value(sheetData);

    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    // sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    range.style("border", true);

            sheet1.range("A1:K1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
            sheet1.range("A2:K2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
            sheet1.range("A3:K3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
            sheet1.range("A5:BF5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

    return workbook.outputAsync().then((res) => {
      saveAs(res, "Employee Report.xlsx");
    });
  });
}

  //get group list
  const [grplist, setGrouplist] = useState([])
  const [selgrp, setSelgrp] = useState("")
  const getgrouplist = async () => {
    const inf_a = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_a).then(res => setGrouplist(res.data.data)) 
   }
    useEffect(() => {getgrouplist()}, [])

    console.log(selgrp)


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                {fullScreenLoader
            ? (
              <FullScreenLoading loading loaderColor="#3498db" />
            )
             
            : null
            }

              {/* Upload Custom Report */}
                <Modal isOpen={uploadModalIsOpen} style={customStyles} onRequestClose={() => setUploadModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Upload Custom Report</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{width:'900px'}}>
                        <div className="row" style={{width:'100%'}}>

                           <div class="btn-container">
                              <div class="dropzone">
                                  <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                  {/* <input type="file" class="upload-input" value="" /> */}
                                  <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                  <input type="file" id="file" className="form-control" name="csvfile" onChange={(e)=>handleInputChange(e)} />
                                  </div>
                              {/* <span style={{color:'red'}}><small>Employee Code required</small></span> */}

                              </div>
                           </div>

                         </div>
                        </div>
                        <div className="text_center">



                        <input type="button" className="btn btn-success" style={{marginRight:'10px'}} value="Submit" onClick={(e) => submitUploadFile(e)} />
                        {/* <button className="btn btn-success" onClick={() => reportDownload()}>Download</button> */}
                        <button className="btn btn-info" onClick={() => setUploadModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>

             {/* custom view */}
                  <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Custom Employee Data</h4>
                           </div>
                        </div>

                       

                        <div className="iq-card-body" style={{width:'900px'}}>
                        <div className="row" style={{width:'100%'}}>
                        <div className="col-4" style={{padding: '0px'}}>
                           <label class="checkbox-inline">
                               <input type="checkbox" value="" onChange={(e) => handleChangeAll(e)} />All
                            </label>
                         </div>
                         </div>

                          <div className="row" style={{width:'100%'}}>
                         {column.length
                          ? (
                            column[0].map((val)=>{
                              return (
                                val.Field != 'id' && val.Field != 'password' && val.Field != 'company_id' && val.Field != 'created_on' && val.Field != 'display_picture'
                                && val.Field != 'thumb' && val.Field != 'is_active' && val.Field != 'details_request_status' && val.Field != 'thumb_request_status' && val.Field != 'image'
                                ? (
                                  <div className="col-4" style={{padding: '0px'}}>
                                  <label class="checkbox-inline">
                                    <input type="checkbox" disabled={val.Field==='emp_code' ? true : false} defaultChecked={val.Field==='emp_code' ? true : false} className='child' value={val.Field} onChange={(e) => handleChange(e)}/>{` `+val.Field}
                                  </label>
                                  </div>
                                )
                                :''
                               
                              
                              )
                            })
                          )
                          :''
                         }
                         {column.length
                          ? (
                            column[1].map((val)=>{
                              return (
                                val.Field != 'id'
                                ? (
                                  <div className="col-4" style={{padding: '0px'}}>
                                  <label class="checkbox-inline">
                                    <input type="checkbox" className='child' value={val.Field} onChange={(e) => handleChange(e)}/>{` `+val.Field}
                                  </label>
                                  </div>
                                )
                                :''
                               
                              
                              )
                            })
                          )
                          :''
                         }
                         </div>
                        </div>
                        <div className="text_center">

                        {/* {loader1 ? (
                        <div><Loadder /></div>
                      ) : ''} */}


                        <input type="button" className="btn btn-success" style={{marginRight:'10px'}} value="Download" onClick={() => reportDownload()} />
                        {/* <button className="btn btn-success" onClick={() => reportDownload()}>Download</button> */}
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>   

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Employee Report</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">

                                        <div className="col-md-3">
                                        Joining Date :  <br /><DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="From Date"
                                                name="from_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}  
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3">
                                        Leaving :  <br /> <DatePicker
                                                selected={to_date}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="To Date"
                                                name="to_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            Group : <br/>
                                            <select className="form-control" name="selgrp" value={selgrp} onChange={(e) => setSelgrp(e.target.value)}>
                                                <option value="" disabled selected>select group</option>
                                                {
                                                    grplist.map((item, index) => (
                                                        <option key={index} value={item.leavegroup_pk_id}>{item.leavegroup_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                            <input type="button" className="btn btn-danger" value="Clear" style={{ marginTop: "23px",marginLeft:'10px' }} onClick={e => clear(e)} />  
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12'>
                                              <button onClick={() => customReportUpload()} className="btn btn-primary mr-2" style={{ marginTop: "23px",marginLeft:'10px',float: 'right' }}>
                                              Custom Employee Data Upload
                                              </button>
                                              <button onClick={() => customReport()} className="btn btn-info mr-2" style={{ marginTop: "23px",marginLeft:'10px',float: 'right' }}>
                                                Custom Employee Data
                                              </button>
                                              <button onClick={() => saveAsExcel()} className="btn btn-warning mr-2" style={{ marginTop: "23px",marginLeft:'10px',float: 'right' }}>
                                              <i class="fa fa-download"></i> Employee Basic Info Excel
                                              </button>
                                              <CSVLink filename={"employee-attendance.csv"} style={{ marginTop: '22px', float: 'right' }} className="btn btn-warning" data={empList}  >
                                                  <i class="fa fa-download"></i>Employee Data CSV
                                              </CSVLink>
                                              <button className="btn btn-warning mr-2" style={{ marginTop: '22px', float: 'right'}} onClick={() => saveAsExcel2()}><i class="fa fa-download"></i>Employee Data Excel</button>

                                            {
                                              dwnCustomRpt
                                              ? (
                                                <CSVLink
                                                data={dwnCustomRpt}
                                                filename={'Employee_report.csv'}
                                                className="hidden"
                                                ref={csvLink}
                                                target="_blank"
                                                /> 
                                              )
                                              :''
                                            } 
                                            </div>
                                        </div>
                                       

                                    </div>

                                    <div className="row" style={{ marginTop: '25px' }} >

                   <div style={{ padding: "10px", textAlign: "right" }}>
                    <div>
                      <input
                        type="text"
                        placeholder="Search here..."
                        name="search"
                        // onChange={(e) => inputsearch(e)}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>

                  <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Profile
                        </th>
                        <th scope="col">Emp Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {loader ? (
                        <Loadder />
                      ) : (
                        empList.map((val, key) => {
                          if (val.display_picture == null) {
                            var profile_img = "/images/user/1.jpg";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var profile_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumbdp/" +
                              val.display_picture +
                              "?" +
                              rand;
                          }

                          if (val.thumb == null) {
                            var atten_img = "";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var atten_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumb/" +
                              val.thumb +
                              "?" +
                              rand;
                          }

                        
                          return (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                <div className="" style={{ display: "grid" }}>
                                  <img
                                    src={profile_img}
                                    alt="profile-img"
                                    className="prof"
                                  />
                                </div>
                              </td>
                              <td>{val.emp_code}</td>
                              <td>
                                {val.first_name} {val.last_name}
                              </td>
                              <td>{val.phone}</td>
                              <td>{val.email}</td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={50}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )

}
export default Employee_report