import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

const Hiring_round_assessment = () => {

    const alert = useAlert(); 
    const company_id = Apiconnect.get_company_id()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [name, setName] = useState("")
    const [duration, setDuration] = useState("")
    const [remark, setRemark] = useState("")
    const [editId, setEditId] = useState("")
    const [list, setList] = useState([])

    useEffect(() => {get_assessment_list()}, [])

    const get_assessment_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_assessment_getall/${company_id}`).then((res) => {
            setList(Apiconnect.decrypt_obj(res.data.data))
            setFullScreenLoader(false)
        })
    }

    const handleSubmit = () => {
        setFullScreenLoader(true)
        if(editId !== ""){
            let data_ins = {hpa_name:name, hpa_duration:duration, hpa_remark:remark}
            Apiconnect.postData(`hiring_application/hiring_process_assessment_update/${editId}`, data_ins).then((res) => {
                setFullScreenLoader(false)
                get_assessment_list()
                setName("")
                setDuration("")
                setRemark("")
                setEditId("")
                alert.success("Updated")
            })
        }else{
            let data_ins = {hpa_company_id: company_id, hpa_name:name, hpa_duration:duration, hpa_remark:remark}
           if(duration < Number(24) && duration > 0){
            Apiconnect.postData(`hiring_application/hiring_process_assessment_create/${company_id}`, data_ins).then((res) => {
                setFullScreenLoader(false)
                get_assessment_list()
                setName("")
                setDuration("")
                setRemark("")
                alert.success("Created")
            })
           }else{
            alert.error("Please Enter Correct duration")
            setFullScreenLoader(false)
           }
        }
    }

    const handleUpdate = (id) => {
        setEditId(id)
        const filtered = list.find(item => item.hpa_pk_id === id)
        setName(filtered.hpa_name)
        setDuration(filtered.hpa_duration)
        setRemark(filtered.remark)
    }
    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_assessment_delete/${id}`).then((res) => {
            setFullScreenLoader(false)
            get_assessment_list()
            alert.success("Deleted Successfully")
        })
    }

  return (
    <>
        <div className='content-page'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card'>
                            <div className="iq-card-header d-flex justify-content-between model_head">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Hiring Round Assessments</h4>
                                </div>
                                <Link to={`/Hiring_process`}><button className='btn btn-sm btn-dark'>Back</button></Link>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <input type="text" required className='form-control' placeholder='Assessment Name' name='name' value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className='col-md-3'>
                                        <input type="number" step="any" min={1} max={24} required className='form-control' placeholder='Duration' name='duration' value={duration} onChange={(e) => setDuration(e.target.value)} />
                                    </div>
                                    <div className='col-md-3'>
                                        <input type="text" className='form-control' placeholder='Remarks If Any' name='remark' value={remark} onChange={(e) => setRemark(e.target.value)} />
                                    </div>
                                    <div className='col-md-3'>
                                        <button className='btn btn-sm btn-primary' onClick={handleSubmit}>{editId ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                            <thead className="">
                                                <tr className="clr">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list.length > 0 ?
                                                    (
                                                        list.map((val, key) => {
                                                            let status = null
                                                            if(val.hpa_status === 1){
                                                                status = <span className='badge badge-pill badge-success'>Active</span>
                                                            }else{
                                                                status = <span className='badge badge-pill badge-danger'>Inactive</span>
                                                            }
                                                            return(
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>{val.hpa_name}</td>
                                                                    <td>{val.hpa_duration} {Number(val.hpa_duration) < 1 ? "Minutes" : "Hours"}</td>
                                                                    <td>{val.hpa_remark}</td>
                                                                    <td>{status}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm btn-info mr-2 mb-2' onClick={() =>handleUpdate(val.hpa_pk_id)}>Edit</button>
                                                                        <button className='btn btn-sm btn-danger mr-2 mb-2' onClick = {() => handleDelete(val.hpa_pk_id)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_round_assessment