import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "react-modal";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Emp_exit_interviews = () => {
  const [list, seList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    Apiconnect.postData(`separation/emp_exit_interviewed_list/${Apiconnect.get_company_id()}`).then((res) => {
      let data = Apiconnect.decrypt_obj(res.data.data);
      seList(data);
    });
  };

  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Employee Name</th>
      <th>Employee Designation</th>
      <th>Manage</th>
    </tr>
  );

  function convertSentenceCase(str) {
    let updated_str = str.toLowerCase();
    const res = updated_str[0].toUpperCase() + updated_str.substr(1);
    return res;
  }

  console.log(list);

  return (
    <Container heading="Employee Exit Interviews">
      <Card>
        <Table className="col-md-12" trHeader={trHeader}>
          {list.map((item, index) => {
            return (
              <tr className="text-center" key={index}>
                <td>{index + 1}</td>
                <td>{item.employee_name}</td>
                <td>{convertSentenceCase(item.employee_designation)}</td>
                <td>
                  <Link to={`/Emp_exit_interview/${item.eei_emp_id}`}>
                    <button className="btn btn-sm btn-info">
                      <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
                    </button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </Table>
      </Card>
    </Container>
  );
};

export default Emp_exit_interviews;
