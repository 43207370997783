import React from "react";

const SelectFromOptions = ({ className, label, name, value, onChange, optionOne, children }) => {
  return (
    <>
      <div className={className}>
        <label className="text-muted">{label}</label>
        <select className="form-control" name={name} value={value} onChange={onChange}>
          <option value="" disabled selected>
            {optionOne}
          </option>
          {children}
        </select>
      </div>
    </>
  );
};

export default SelectFromOptions;
