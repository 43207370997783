import React, {useState, useEffect} from 'react'
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Apiconnect from '../../services/Apiconnect'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '55%',
        left: '55%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "80%" ,
        height: '100%'
    },
};
const page_styles = {
    main_container_bg: {background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)"},
    main_container_shadows: {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        borderRadius: 10
    },
    shadow: {boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"},
    card_head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "5",
        color: "white !important",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"
    }
 }

const Work_force_hierarchy = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    let company_id = Apiconnect.get_company_id();

    const alert = useAlert();

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [desList, setDesList] = useState([]);

    const [allchecked, setallchecked] = useState(null);
    const [selectedDes, setSelectedDes] = useState([]);
    const [list, setList] = useState([])

    const [table_list, setTable_list] = useState([])

    useEffect(() => {getDesList(); hierarchy_list(); get_work_force_hierarchy_list()}, []);

    const get_work_force_hierarchy_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_hierarchy_list/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setTable_list(_xtract)
            setFullScreenLoader(false)
        })
    }

    const getDesList = () => {
        setFullScreenLoader(true)
        Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
            setFullScreenLoader(false)
            let _xtract = response.data.data;
            setDesList(_xtract.reverse());      
        });
    };

    const hierarchy_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_hierarchy_level_list/${company_id}`).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setList(_xtract)
            setFullScreenLoader(false)
        })
    }

    const onInputChange2 = (e) => {
        setallchecked(null);
     
        if (e.target.checked === true) {
            setSelectedDes({
            ...selectedDes,
            [e.currentTarget.name]: e.currentTarget.value,
          });
        } else {
            setSelectedDes({ ...selectedDes, [e.currentTarget.name]: 0 });
        }
      };

    const [wfh_hierarchy_level_id, setWfh_hierarchy_level_id] = useState("")
      const handleCreate = () => {
        setFullScreenLoader(true)
        let ins_ob = {wfh_des_ids: selectedDes, wfh_hierarchy_level_id:wfh_hierarchy_level_id, created_by:user_id}
        if(wfh_hierarchy_level_id !== "" && Object.keys(selectedDes).length > 0){
            Apiconnect.postData(`work_force/work_force_hierarchy_create/${company_id}`, ins_ob).then(res => {
                alert.success("Assigned")
                setSelectedDes([])
                setFullScreenLoader(false)
                setWfh_hierarchy_level_id("")
                setModalIsOpen(false)
                get_work_force_hierarchy_list()
                console.log(res.data.data)
            })
        }
    }

    console.log(list)

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`work_force/work_force_hierarchy_delete/${id}`).then(res => {
            get_work_force_hierarchy_list()
            alert.success("Deleted Successfully")
            setFullScreenLoader(false)
        })
    }

    console.log(table_list)

  return (
    <>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />) : null }
        <div className='content-page' style={page_styles.main_container_bg}>
            <div className='container-fluid'>
            <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head"
                     style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                    >
                        <div className="iq-header-title">
                            <h4 className="card-title text-white">Work Force Hierarchy Assign</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <select className='form-control' name='des_level text-center' value={wfh_hierarchy_level_id} onChange={e => {
                                    setWfh_hierarchy_level_id(e.target.value)
                                    }}>
                                    <option value="" disabled selected>---Select Hierarchy Level---</option>
                                    {
                                        list.map((item, index) => {
                                            return(
                                                <option key={index} value={item.wfhl_pk_id}>{item.wfhl_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-12'>
                                <table className="table table-striped table-hover">
                                    <thead className="">
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {desList.map((val, key) => {  
                                            
                                            var ck = Object.keys(selectedDes).find(function (element) {
                                                return selectedDes[element] == val.id;
                                              });  
                                              var ck1 = "";
                                              if (ck !== undefined) {
                                                ck1 = true;
                                              }

                                        return (
                                            <tr key={key}>  
                                                 <th scope="row"><input type="checkbox" name={val.id} value={val.id} checked={ck1} onChange={(e) => onInputChange2(e)}/></th>
                                                <th scope="row">{key+1}</th>
                                                <td>{val.name}</td>
                                            </tr> 
                                        );
                                        })}                               
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-6 mt-4'>
                                <button className='btn btn-sm btn-primary mt-2 float-right' onClick = {handleCreate}>Assign</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between model_head"
                     style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                    >
                        <div className="iq-header-title">
                            <h4 className="card-title text-white">Work Force Hierarchy</h4>
                        </div>
                    </div>
                    <div className="iq-card-body">
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <button className='btn btn-sm btn-info float-right' onClick={() => setModalIsOpen(true)}>Assign Designation</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <table className="table table-striped table-bordered mt-1 center">
                                    <thead className="">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Hierarchy Level</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {table_list.map((val, key) => { 
                                        return (
                                            <tr key={key}>  
                                                <th scope="row">{key+1}</th>
                                                <td>{val.designation}</td>
                                                <td>{val.hierarchy_level}</td>
                                                <td>
                                                    <button className='btn btn-sm btn-danger' onClick={() => handleDelete(val.id)}>Delete</button>
                                                </td>
                                            </tr> 
                                        );
                                        })}                               
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Work_force_hierarchy