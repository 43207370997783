import React from 'react'
import { pageStyles } from '../../globalStyles'

const Table = ({ className, trHeader, children }) => {
  return (
      <div className={className} style={pageStyles.main_container}>
          <div className='row'>
            <div className='table-responsive'>
                <table className='col-md-12 table table-bordered'>
                    <thead className='thead-light'>
                        {trHeader}
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </table>
            </div>
          </div>
    </div>
  )
}

export default Table