import React, {useState, useEffect} from 'react'
import { pageStyles } from "../../../globalStyles.js";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const customStyles = {
    content: {
       top: '55%',
       left: '55%',
       right: 'auto',
       bottom: 'auto', 
       transform: 'translate(-50%, -50%)',
       width: "50%" ,
       height: '50%'
    },
};

const Rm_round_master = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [list, setList] = useState([])
    const [r_name, setR_name] = useState("")
    const [edit_id, setEdit_id] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [round_id, setRound_id] = useState([])
    const [round_name, setRound_name] = useState([])
    const [assessmnet_list, setAssessmnet_list] = useState([])
    const [allchecked, setallchecked] = useState(null);
    const [selected, setSelected] = useState([]);
    const [r_assessment_list, setR_assessment_list] = useState([])
    
    useEffect(() => {
        get_round_list()
        get_assessment_list()
    }, [])

    const handleCreate = (e) => {
        e.preventDefault()
        if (edit_id!=="") {
            if(r_name!==""){
                setFullScreenLoader(true)
                let data_ins = {
                    r_company_id:company_id,
                    r_name:r_name
                }
                Apiconnect.postData(`hrms_rounds/update/${edit_id}`, data_ins).then(res => {
                    alert.success("Updated Successfully")
                    setFullScreenLoader(true)
                    get_round_list()
                    setR_name("")
                    setEdit_id("")
                })
            }else{
                alert.error("Please Enter round")
            }
        } else {
            if(r_name!==""){
                setFullScreenLoader(true)
                let data_ins = {
                    r_company_id:company_id,
                    r_name:r_name,
                    r_created_by:emp_id
                }
                Apiconnect.postData(`hrms_rounds/create`, data_ins).then(res => {
                    alert.success("Created Successfully")
                    setFullScreenLoader(true)
                    get_round_list()
                    setR_name("")
                })
            }else{
                alert.error("Please Enter Round")
            }
        }
    }

    const get_round_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_rounds/getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setList(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    const handleEdit = (id) => {
        setEdit_id(id)
        let data = list.find((item) => item.r_pk_id == id)
        console.log(data)
        setR_name(data.r_name)

    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_rounds/delete/${id}`).then(res => {
            setFullScreenLoader(false)
            get_round_list()
        })
    }

    
    const onInputChange = (e) => {
        setallchecked(null);
        if (e.target.checked === true) {
          setSelected({
            ...selected,
            [e.currentTarget.name]: e.currentTarget.value,
          });
        } else {
          setSelected({ ...selected, [e.currentTarget.name]: 0 });
        }
      };
    const sellectAll = (e) => {
    if (e.target.checked === true) {
        setallchecked("checked");
        let ob = {};
        assessmnet_list.map((val, key) => {
        ob[val.ha_pk_id] = val.ha_pk_id;
        });
        setSelected(ob);
    } else {
        setallchecked(null);
        setSelected([]);
    }
    };

    const get_assessment_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_assessments/getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            setAssessmnet_list(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    const handleAssign = () => {
        let ins_ob = {
            hra_company_id:company_id,
            hra_round_id:round_id,
            hra_assessment_ids:selected,
            hra_added_by:emp_id
        }
        if(Object.keys(selected).length > 0){
            setFullScreenLoader(true)
            Apiconnect.postData(`hrms_hpall_assign_mst/round_assessments_create`, ins_ob).then(res => {
                setFullScreenLoader(false)
                alert.success("Assigned Successfully")
                get_round_list()
                setSelected([])
                setModalIsOpen(false)
            }).catch(err => {
                alert.error("Error Occured.. Please Try Again")
                setSelected([])
                setModalIsOpen(false)
                setFullScreenLoader(false)
            })
        }else{
            alert.error("Please Select Assessments")
        }
    }

    console.log(list)

    const get_assessments_list_by_round = (id) => {
        let ins_data = {hra_round_id: id}
        Apiconnect.postData(`hrms_hpall_assign_mst/assessments_list_per_round/${company_id}`, ins_data).then(res => {
            let data = Apiconnect.decrypt_obj(res.data.data)
            setR_assessment_list(data)
        })
    }

    const handleDelete_r_a = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hrms_hpall_assign_mst/delete_round_assessment/${id}`).then((res) => {
            setFullScreenLoader(false)
            alert.success("Successfully Removed Assessment")
            get_round_list()
            setSelected([])
            setModalIsOpen(false)
        })
    }

  return (
    <div className='content-page main_bg'>
    {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null} 
    <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
        <div className='iq-card' style={pageStyles.main_container}>
            <div className='iq-card-header card_head'>
                <h5 className='text-white'>Assign Assessments To {round_name}</h5>
            </div>
            <div className='iq-card-body '>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                       

                            <button className='btn btn-sm btn-primary float-right' onClick={handleAssign}>Assign</button>
                       
                        
                    </div>
                    <div className='col-md-12 mt-3'>
                        <div className='table-responsive' >
                            <table className='table table-bordered normal_shadow' style={pageStyles.normal_shadow}>
                                
                                <thead className='thead-light'>
                                    <tr>
                                        <th>
                                            {/* <input type="checkbox" name="select_all" checked={allchecked} onChange={(e) => sellectAll(e)}/> */}
                                        </th>
                                        <th style={{width:"10%"}} className='text-center'>#</th>
                                        <th className='text-center'>Name</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        assessmnet_list.length > 0 ? (
                                            assessmnet_list.map((item, index) => {

                                                let form_name = null
                                                if(item.ha_form_id === null){
                                                    form_name = <span className='badge badge-warning badge-small'>Form Not Assigned Yet</span>
                                                }else{
                                                    form_name = <span className='badge badge-success badge-small'>{item.form_name.hf_name}</span>
                                                }

                                                let ck = Object.keys(selected).find(function (element) {
                                                    return selected[element] == item.ha_pk_id;
                                                    });
                        
                                                    var ck1 = "";
                                                    if (ck !== undefined) {
                                                    ck1 = true;
                                                    }
                                                
                                                
                                                return(
                                                    <tr key={index}>
                                                        <td>{item.ha_form_id && <input
                                                                type="checkbox"
                                                                name={item.ha_pk_id}
                                                                value={item.ha_pk_id}
                                                                checked={ck1}
                                                                onChange={(e) => onInputChange(e)}
                                                                />}
                                                        </td>
                                                        <td className='text-center'>{index+1}</td>
                                                        <td>{item.ha_name}</td>
                                                        {/* <td className='text-center'>
                                                            {form_name} <br />
                                                            {!item.ha_form_id &&
                                                                <Link to={`/hrms_assessments`}><button className='btn btn-sm btn-info'>Assign Form</button></Link>
                                                            }
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <th colSpan={4} className='center text-muted'>----  No Records Found  ----</th>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='border-top'/>

                    <div className='col-md-12 mt-3'>
                        <div className='table-responsive'>
                            <table className='table table-bordered normal_shadow' style={pageStyles.normal_shadow}>
                                <thead className='thead-light'>
                                    <tr>
                                        <th>#</th>
                                        <th>Assessment Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        r_assessment_list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.assessment_name}</td>
                                                    <td>
                                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete_r_a(item.hra_pk_id)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
    <div className='container-fluid'>
        <div className='iq-card' style={pageStyles.main_container}>
            <div className='iq-card-header' style={pageStyles.card_head}>
                <h4 className='text-white'>Hiring Round Master</h4>
            </div>
            <div className='iq-card-body'>
                <div className='row'>
                    <div className='col-md-4'>
                        <input type="text" className='form-control' placeholder='Enter Round' name='r_name' value={r_name} onChange={e => setR_name(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                        <button className='btn btn-sm btn-primary mr-2' onClick={(e) => handleCreate(e)}>{edit_id ? "Update" : "Create"}</button>
                        {edit_id && <button className='btn btn-secondary btn-sm' onClick={() => {setEdit_id(""); setR_name("")}}>Clear</button>}
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-md-12 mt-3'>
                        <div table-responsive>
                        <table className='table table-bordered normal_shadow' style={pageStyles.normal_shadow} >
                            
                            <thead className='thead-light'>
                                <tr>
                                    <th style={{width:"10%"}} className='text-center'>#</th>
                                    <th className='text-center'>Round Name</th>
                                    <th className='text-center'>Assigned Assessments</th>
                                    <th style={{width: "30%"}} className='text-center'>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.length > 0 ? (
                                        list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td className='text-center'>{index+1}</td>
                                                    <td>{item.r_name}</td>
                                                    <td className='text-center'>
                                                        {
                                                        item.assessment_names.length > 0 ? (
                                                            item.assessment_names.map((val, key) => {
                                                                return(
                                                                    <span className='badge badge-success badge-small mr-2' key={key}>{val}</span>
                                                                )
                                                            })
                                                        ) : (
                                                            <span className='badge badge-warning badge small'>
                                                                Assessments Not Assigned Yet
                                                            </span>
                                                        )
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => {
                                                            setRound_id(item.r_pk_id)
                                                            setRound_name(item.r_name)
                                                            setModalIsOpen(true)
                                                            get_assessments_list_by_round(item.r_pk_id)
                                                            }}>Manage Assessments</button>
                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => {handleEdit(item.r_pk_id)}}>Edit</button>
                                                        {/* <button className='btn btn-sm btn-danger' onClick={() => handleDelete(item.r_pk_id)}>Delete</button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <th colSpan={3} className='center text-muted'>----  No Records Found  ----</th>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Rm_round_master