import React, { useEffect, useState } from "react";
import { Card, Container, Input, SelectFromOptions, CustomModal, Table } from "../../../components/imports";
import Apiconnect from "../../../services/Apiconnect";
import { pageStyles } from "../../../globalStyles";
import toast from "react-hot-toast";

const Appraiser_assigned_list = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);
  let company_id = Apiconnect.get_company_id();

  const [list, setList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    try {
      Apiconnect.postData(`appraisal/assignedAppraisersList/${company_id}`).then((res) => {
        const data = Apiconnect.decrypt_obj(res.data.data);
        setList(data);
      });
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };
  const trHeader = (
    <tr className="text-center">
      <th>#</th>
      <th>Appraisee Name</th>
      <th>Appraisee Employee Code</th>
      <th>Appraiser Name</th>
      <th>Appraiser Employee Code</th>
      <th>Reviewer Name</th>
      <th>Reviewer Employee Code</th>
    </tr>
  );

  return (
    <Container heading="Appraiser Assigned List">
      <Card>
        {list.length > 0 ? (
          <Table className="col-md-12" trHeader={trHeader}>
            {list.map((item, index) => {
              return (
                <tr className="text-center" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.appraisee_name}</td>
                  <td>{item.appraisee_emp_code}</td>
                  <td>{item.appraiser_name}</td>
                  <td>{item.appraiser_emp_code}</td>
                  <td>{item.reviewer_name}</td>
                  <td>{item.reviewer_emp_code}</td>
                </tr>
              );
            })}
          </Table>
        ) : (
          <div className="col-md-12 text-center">
            <span className="badge badge-pill badge-success">No Records Found</span>
          </div>
        )}
      </Card>
    </Container>
  );
};

export default Appraiser_assigned_list;
