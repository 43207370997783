import React, { useState, useEffect } from "react";
import "./recruitment.css";
import Apiconnect from "../../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from "react-fullscreen-loading";
import Modal from "react-modal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  content: {
    top: "55%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const Rm_replacement_vacancies = () => {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp_id = Apiconnect.decrypt(localuser.id);
  const company_id = Apiconnect.get_company_id();
  const alert = useAlert();

  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    get_replacement_vacancies_list();
  }, []);

  const get_replacement_vacancies_list = () => {
    setFullScreenLoader(true);
    Apiconnect.postData(`work_force/get_replacement_vacancies_list/${company_id}`).then((res) => {
      let _xtract = Apiconnect.decrypt_obj(res.data.data);
      setList(_xtract);
      setFullScreenLoader(false);
    });
  };

  return (
    <div className="content-page main_bg">
      {fullScreenLoader ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
      <div className="container-fluid">
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
          <div className="iq-card">
            <div className="iq-card-header card_head">
              <h5 className="text-white">Assign Rounds Name</h5>
            </div>
            <div className="iq-card-body ">
              <div className="row mt-3">
                {/* <div className='col-md-12 mt-3'>
                            <div className='table-responsive'>
                                <table className='table table-bordered normal_shadow'>
                                    
                                    <thead className='thead-light'>
                                    </thead>

                                    <tbody>
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>
        </Modal>
        <div className="iq-card main_container">
          <div className="iq-card-header card_head">
            <h4 className="text-white">Additional Vacancies</h4>
          </div>
          <div className="iq-card-body">
            <div className="row mt-3">
              <div className="col-md-12 mt-3">
                <div table-responsive>
                  <table className="table table-bordered normal_shadow">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">
                          #
                        </th>
                        <th scope="col" className="text-center">
                          Position
                        </th>
                        <th scope="col" className="text-center">
                          Vacancy Count
                        </th>
                        <th scope="col" className="text-center">
                          Manage Applicants
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.length > 0 ? (
                        list.map((item, index) => {
                          return (
                            <tr className="table-light" key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td>{item.position}</td>
                              <td className="text-center">
                                <span className="badge badge-pill badge-light">{item.wf_no_of_vacancies}</span>
                              </td>

                              <td className="text-center">
                                <Link to={`/Applicants/${item.wf_position}`}>
                                  <button className="btn btn-sm btn-success" style={{ borderRadius: 5, background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)" }}>
                                    View
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="table-light">
                          <th colSpan={10} style={{ textAlign: "center" }}>
                            {" "}
                            No Data Found..{" "}
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rm_replacement_vacancies;
