import React, {useState, useEffect} from 'react'
import './recruitment_module/recruitment.css'
import Apiconnect from '../../services/Apiconnect'
import { useAlert } from 'react-alert'
import FullScreenLoading from 'react-fullscreen-loading';
import Modal from 'react-modal';
import {Link} from 'react-router-dom'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "70%" ,
        height: '80%'
    },
};

const Hiring_panel = () => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert();
    
    const [name, setName] = useState("")
    const [editId, setEditId] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [panel_list, setPanel_list] = useState([])
    

    useEffect(() => {
        get_panel_list(); 
        getDesignation();
    }, [])

    const get_panel_list = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_panel_getall/${company_id}`).then(res => {
            setFullScreenLoader(false)
            console.log(res)
            setPanel_list(Apiconnect.decrypt_obj(res.data.data))
        })
    }

    const handleCreate = () => {
        if(name !== ""){
            if(editId !== ""){
               let ins_ob = {hpp_name:name}
               setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_panel_update/${editId}`, ins_ob).then(res => {
                    get_panel_list()
                    setFullScreenLoader(false)
                    alert.success("Updated")
                    setName("")
                    setEditId("")
                })
            }else{
                let ins_ob = {hpp_company_id: company_id, hpp_name:name, hpp_added_by:emp_id}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_panel_create`, ins_ob).then(res => {
                    get_panel_list()
                    setFullScreenLoader(false)
                    alert.success("Created")
                    setName("")
                })
            }
        }
    }

    const handleEdit = (id) => {
        setEditId(id)
        let data = panel_list.find((item) => item.id === id)
        setName(data.hpp_name)
    }

    const handleDelete = (id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_panel_delete/${id}`).then(res => {
            get_panel_list()
            setFullScreenLoader(false)
            alert.success("Deleted")
        })
    }

    
    const [panel_name, setPanel_name] = useState("")
    const [des_list, setDesList] = useState([])
    const [emp_list, setEmp_list] = useState([])
    const [panel_id, setPanel_id] = useState("")

    const getDesignation = () => {
        Apiconnect.getData(`designation/getall/${company_id}`).then((response) => {
           let _xtract = response.data.data;
           setDesList(_xtract.reverse());      
        });
    };

    const get_emp_list_by_designation = (id) => {
        setFullScreenLoader(false)
        Apiconnect.postData(`hiring_application/hiring_panel_get_emp_by_designation/${company_id}/${id}`).then(res => {
            setFullScreenLoader(false)
            setEmp_list(res.data.data)
        })
    }

    const [d_id, setD_id] = useState("")
    const [e_id, setE_id] = useState("")
    const [emp_h, setEmp_h] = useState("")
    const [edit_id, setEdit_id] = useState("")
    const [panel_members, setPanel_members] = useState([])

    const handleSubmit = () => {
        if(d_id!=="" && e_id!=="" && emp_h!==""){
            if(edit_id!== ""){
                /* Update */
            }else{
                let ins_ob = {hppm_company_id:company_id, hppm_panel_id:panel_id, hppm_pannel_member:e_id, hppm_panel_designation: d_id, hppm_hierarchy:emp_h, hppm_added_by:emp_id}
                setFullScreenLoader(true)
                Apiconnect.postData(`hiring_application/hiring_panel_emp_create`, ins_ob).then(res => {
                    setFullScreenLoader(false)
                    get_panel_members()
                    setD_id("")
                    setE_id("")
                    setEmp_h("")
                    alert.success("Created")
                })
            }
        }
    }

    const get_panel_members = (id) => {
        Apiconnect.postData(`hiring_application/hiring_panel_emp_getall/${company_id}/${id}`).then(res => {
            setPanel_members(res.data.data)
        })
    }

    const handleEditPanel = (id) => {
        setEdit_id(id)
        let ob = panel_members.find(item => item.id === id)
        console.log(ob)
    }

    const handleDeletePanel = (id) => {
        Apiconnect.postData(`hiring_application/hiring_panel_emp_delete/${id}`).then(res => {
            get_panel_members()
            alert.success("Deleted")
        })
    }


  return (
    <>
        <div className='content-page main_bg'>
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}   
            <div className='container-fluid'>
            {/* <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className='iq-card'>
                    <div className='iq-card-header card_head'>
                        <div className='iq-header-title' style={{float: "left"}}>
                            <h4 className='card-title'>Manage Hiring Panel For <span className='font-weight-bold'>{panel_name}</span></h4>
                        </div>
                    </div>
                    <div className='iq-card-body'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <select className='form-control center' name='d_id' value={d_id} onChange={(e) => {setD_id(e.target.value); get_emp_list_by_designation(e.target.value)}}>
                                    <option value="" disabled selected>---Select Designation----</option>
                                    {
                                        des_list.map((item,index) => {
                                            return(
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-3'>
                                <select className='form-control center' name='e_id' value={e_id} onChange={(e) => {setE_id(e.target.value)}}>
                                    <option value="" disabled selected>---Select Employee----</option>
                                    {
                                        emp_list.map((item,index) => {
                                            return(
                                                <option key={index} value={item.id}>{item.first_name+" "+item.middle_name+" "+item.last_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-3'>
                                <input type="number" className='form-control' placeholder='Enter hierarchy level' name='emp_h' value={emp_h} onChange={(e) => setEmp_h(e.target.value)}/>
                            </div>
                            <div className='col-md-3'>
                                <button className='btn btn-sm btn-primary' onClick={handleSubmit}>Create</button>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <table className='table table-striped table-bordered center'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Designation</th>
                                            <th>Hierarchy</th>
                                            <th>Panel For</th>
                                            <th>Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                            {panel_members.map((item, index) => {
                                                
                                                return(
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.panel_member_name}</td>
                                                        <td>{item.designation_name}</td>
                                                        <td>{item.hppm_hierarchy}</td>
                                                        <td>{item.panel_name}</td>
                                                        <td>
                                                            <button className='btn btn-sm btn-info mr-2' onClick={() => handleEditPanel(item.id)}>Edit</button>
                                                            <button className='btn btn-sm btn-danger mr-2' onClick={() => handleDeletePanel(item.id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='iq-card main_container'>
                            <div className='iq-card-header card_head'>
                                <div className='iq-header-title' style={{float: "left"}}>
                                    <h4 className='card-title text-white'>Hiring Panel</h4>
                                </div>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <input className='form-control' type="text" placeholder='Enter Panel Name' name='name' value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                    <div className='col-md-4'>
                                        <button className='btn btn-sm btn-primary mr-2' onClick={handleCreate}>{editId ? "Update" : "Create"}</button>
                                        {editId && <button className='btn btn-sm btn-dark mr-2' onClick={() => {setName(""); setEditId("")}}>Clear</button>}
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-12'>
                                        <table className='table table-bordered normal_shadow'>
                                            <thead className='thead-light'>
                                                <tr >
                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Name</th>
                                                    <th className='text-center'>Status</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    panel_list.length > 0 ?
                                                    (
                                                        panel_list.map((item, index) => {
                                                            let st = null
                                                            if(item.hpp_status === 1){
                                                                st = <span className='badge badge-pill badge-success'>Active</span>
                                                            }else{
                                                                st = <span className='badge badge-pill badge-danger'>Inactive</span>
                                                            }
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.hpp_name}</td>
                                                                    <td className='text-center'>{st}</td>
                                                                    <td className='text-center'>
                                                                        {/* <Link to={`/Manage_hiring_panel/${item.id}`}><button className='btn btn-sm btn-primary mr-2'>Assign Employee</button></Link> */}
                                                                        <Link to={`/Compose_panel/${item.id}`}><button className='btn btn-sm btn-info mr-2' >Compose Panel</button></Link>
                                                                        <Link to={`/View_panel/${item.id}`} ><button className='btn btn-sm btn-info mr-2' >View Panel</button></Link>
                                                                        <button className='btn btn-sm btn-info mr-2' onClick={() => handleEdit(item.id)}>Edit</button>
                                                                        {/* <button className='btn btn-sm btn-danger mr-2' onClick={() => handleDelete(item.id)}>Delete</button> */}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <tr>
                                                            <th colSpan={6}>No Data Found...</th>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hiring_panel