import React, {useState, useEffect} from 'react'
import { useLocation } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";
import DatePicker from "react-datepicker";
import Axios from 'axios';
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useAlert } from "react-alert";
import moment from 'moment'
import FullScreenLoading from 'react-fullscreen-loading';
import { tr } from 'date-fns/locale';
import { keys } from '@material-ui/core/styles/createBreakpoints.js';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

const Employee_leave_report_format = () => {
    const { emp_id } = useParams();
    const company_id = Apiconnect.get_company_id()

    var date_current1=new Date();
    var date_first1=new Date(date_current1.getFullYear(), (date_current1.getMonth()), 1);
    var date_last1=new Date(date_current1.getFullYear(), (date_current1.getMonth()) + 1, 0);

    const [from_date, setFromDate] = useState(date_first1);
    const [to_date, setToDate] = useState(date_last1);
    const [sel_category_id, setSel_Category] = useState('');
    

    const alert = useAlert();
    let history = useHistory();

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken;
    
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [data_user_details, set_user_details] = useState([]);
    const [data_user_leavelist, set_user_leavelist] = useState([]);
    const [data_user_policy, set_user_policy] = useState([]);
    const [list_categorys,set_list_categorys]=useState([]);
    const [tbl_cells2,set_tbl_cells2]=useState('');
    const [list_empcategorys,set_list_empcategorys]=useState([]);
    const [list_empcategorys_all,set_list_empcategorys_all]=useState([]);
    const [list_array1,set_list_array1]=useState([]);

    useEffect(() => {
        loadUser();  
        listempcategorys_all();
        listempcategorys_byid();
        list_datewise();
    }, []);
    
    const loadUser = async () => { 
        await Apiconnect.getData(`employee/get/${emp_id}`).then(response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            set_user_details(_xtract)
        }); 
    }; 

    var data_post={
        company_id:company_id,
        employee_id:emp_id,
        is_admin:0,
        sel_shortleave_type:'No', 
        sel_from_date:from_date,
        sel_to_date:to_date,
    };  
     
    let employee_name=(typeof data_user_details[0]!='undefined')?data_user_details[0].first_name+' '+data_user_details[0].last_name:'';
    let date_of_birth=(typeof data_user_details[0]!='undefined')?data_user_details[0].date_of_birth:'';
    
    let emp_designation=(typeof data_user_details[0]!='undefined')?data_user_details[0].emp_designation:'';
    let department=(typeof data_user_details[0]!='undefined')?data_user_details[0].department:'';

    //console.log(emp_designation+' == '+department);  

    var cal_length1=list_categorys.length; 
    var array_header2=['LEAVE EARNED','LEAVE TAKEN','LEAVE BALANCE'];

    var array_data_list1=[];
    var array_data_list2=[];
    var array_data_list3 ={}; 

    list_categorys.map((val_data1, key1) => {
        array_data_list1[val_data1.leave_cat_id]=val_data1.leave_cat_code;
    });


    var cnt_i1=0;
    var cnt_i2=1;
    list_categorys.map((val_data1, key2) => {
        let leave_cat_code=val_data1.leave_cat_code;
        let date_leave_app=moment(new Date(val_data1.date_leave_app)).format("YYYY-MM-DD");
        let date_leave_cr=moment(new Date(val_data1.date_leave_cr)).format("YYYY-MM-DD");
 
        if(!array_data_list2[date_leave_app])
            array_data_list2[date_leave_app] = [];

        let catobject = [];
        catobject['leave_cat_code'] = val_data1; 
        array_data_list2[date_leave_app].push(val_data1);

        cnt_i1++;
        cnt_i2++;
    });
    // console.log(list_categorys);

    const listempcategorys_all = async () => { 
        await Apiconnect.postData(`employee_reports/get_employee_wiese_category_lists_all`,{employee_id:emp_id}).then(response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            set_list_empcategorys_all(_xtract)
        }); 
    };  
    const listempcategorys_byid = async () => { 
        await Apiconnect.postData(`employee_reports/get_employee_wiese_category_lists`,{employee_id:emp_id,selcategory_id:sel_category_id}).then(response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            set_list_empcategorys(_xtract)
        }); 
    }; 

    // const list_datewise= async () => { }


    const [list_data, setList] = useState([])
    
    const list_datewise= async () => { 
        setFullScreenLoader(true); 
        var current_date1 = new Date();
        var current_time1 = current_date1.getHours() + ":" + current_date1.getMinutes() + ":" + current_date1.getSeconds();

        let data_pst1={
            employee_id:emp_id,
            from_date:from_date,
            to_date:to_date,
        }
        await Apiconnect.postData(`employee_reports/get_employee_wiese_date_lists`,data_pst1).then( response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            
            setFullScreenLoader(false);  
            setList(_xtract) 
        }); 
    }; 

    const new_list = Object.keys(list_data).map(val => {
        let ob = [];
        ob["date"]=val;
        let ins_ob_arr = [];

        for(let i=0; i<list_data[val].length; i++){
            let ob_2 = list_data[val][i] 
            //console.log(ob_2) 
            let ins_ob = {}
            Object.keys(ob_2).map((val2) => {
                ins_ob["leave_type"] = val2
                ins_ob["app_leave"] = ob_2[val2].app_leave
                ins_ob["cal_leave_credit"] = ob_2[val2].cal_leave_credit
            })
            ins_ob_arr.push(ins_ob)  
        }
        ob["details"] = ins_ob_arr
        return ob
    })

    // console.log(new_list);
    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    const download_excel = async () => {  
        
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            var sel_from_date=moment(from_date).format("DD-MM-YYYY");
            var sel_to_date=moment(to_date).format("DD-MM-YYYY");
            var sel_date_of_birth=moment(new Date(date_of_birth)).format("DD-MM-YYYY");
             
            const sheet1 = workbook.sheet(0);
            sheet1.cell("A1").value('From Date');
            sheet1.cell("B1").value(sel_from_date);
            sheet1.cell("C1").value('To Date');
            sheet1.cell("D1").value(sel_to_date);

            sheet1.cell("A3").value('Employee Name');
            sheet1.cell("B3").value('Date of Birth');
            sheet1.cell("C3").value('Department');
            sheet1.cell("D3").value('Desgination');

            sheet1.cell("A4").value(employee_name);
            sheet1.cell("B4").value(sel_date_of_birth);
            sheet1.cell("C4").value(department);
            sheet1.cell("D4").value(emp_designation);

            sheet1.cell("A7").value('Date');
            var cnt_char1="B"; 
            list_empcategorys.map((val_catg, key) => {
                sheet1.cell(cnt_char1+"7").value(val_catg.leave_cat_title);

                array_header2.map((val_hread2, key) => {
                    sheet1.cell(cnt_char1+"8").value(val_hread2);
                    cnt_char1=nextChar(cnt_char1); 
                });
            });

            if(new_list.length > 0){
                var cnt_col1=9;
                new_list.map((item, index) => {
                    let sel_date1=item.date;

                    sheet1.cell('A'+cnt_col1).value(sel_date1);

                    var cnt_char1="B"; 
                    list_empcategorys.map((val_catg, key) => {
                        var leave_cat_id=val_catg.leave_cat_id;
                        var lpcategory_pk_id=val_catg.lpcategory_pk_id;
                        var lpcategory_category_id=val_catg.lpcategory_category_id;
                         
                        array_header2.map((val_hread2, key) => {
                            //var cal_leave_credit=(typeof list_data[sel_date1][0][lpcategory_pk_id]!='undefined')?list_data[sel_date1][0][lpcategory_pk_id]['cal_leave_credit']:0;
                            //var app_leave=(typeof list_data[sel_date1][0][lpcategory_pk_id]!='undefined')?list_data[sel_date1][0][lpcategory_pk_id]['app_leave']:0;
                            
                            var cal_leave_credit=0;
                            var app_leave=0;
                            for(var cnt_i3=0;cnt_i3<list_data[sel_date1].length;cnt_i3++){
                                //console.log(list_data[sel_date1][cnt_i3][lpcategory_pk_id]);
                                if(typeof list_data[sel_date1][cnt_i3][lpcategory_pk_id]!='undefined'){
                                    cal_leave_credit=list_data[sel_date1][cnt_i3][lpcategory_pk_id]['cal_leave_credit'];
                                    app_leave=list_data[sel_date1][cnt_i3][lpcategory_pk_id]['app_leave'];
                                } 
                            }

                            let res_value1=0;
                            if(val_hread2=='LEAVE EARNED'){
                                res_value1=cal_leave_credit;
                            }
                            if(val_hread2=='LEAVE TAKEN'){
                                res_value1=app_leave;
                            }
                            if(val_hread2=='LEAVE BALANCE'){
                                res_value1=cal_leave_credit-app_leave;
                                if(res_value1<0){
                                    res_value1=0;
                                }
                            } 

                            sheet1.cell(cnt_char1+cnt_col1).value(res_value1);
                            cnt_char1=nextChar(cnt_char1); 
                        })  
                    })
                    cnt_col1++;
                })
            }

            return workbook.outputAsync().then((res) => {
                saveAs(res, "EmployeeLeaveLedger.xlsx");
            });
        });
    }

    function nextChar(c) {
        var u = c.toUpperCase();
        if (same(u,'Z')){
            var txt = '';
            var i = u.length;
            while (i--) {
                txt += 'A';
            }
            return (txt+'A');
        } else {
            var p = "";
            var q = "";
            if(u.length > 1){
                p = u.substring(0, u.length - 1);
                q = String.fromCharCode(p.slice(-1).charCodeAt(0));
            }
            var l = u.slice(-1).charCodeAt(0);
            var z = nextLetter(l);
            if(z==='A'){
                return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
            } else {
                return p + z;
            }
        }
    }

    function nextLetter(l){
        if(l<90){
            return String.fromCharCode(l + 1);
        }else{
            return 'A';
        }
    }
    
    function same(str,char){
        var i = str.length;
        while (i--) {
            if (str[i]!==char){
                return false;
            }
        }
        return true;
    }

    const onInputChange_category = e => {
        setSel_Category(e.target.value);
    }


    

    const btn_submit1 = async () => {  
        listempcategorys_byid();
        list_datewise();
    }
 
    return (
        <>
        {
            fullScreenLoader ? (
                <FullScreenLoading loading loaderColor="#3498db" />
            ) : 
                null 
        }
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                           {/* <div className="iq-card-header d-flex justify-content-between"> */}
                                {/* <div style={{ float: "left" }} className="iq-header-title"> */}
                                    <div className="col-sm-12 col-lg-12 col-md-12" style={{paddingTop:10}}>
                                        <div className="row"> 
                                            <div className="col-sm-6 col-lg-6 col-md-6">
                                                <h4 className="card-title">Employee Leave Ledger</h4>
                                            </div> 
                                            <div className="col-sm-6 col-lg-6 col-md-6">
                                                <a href='/Employee_leave_report' className='btn btn-info pull-right'>Back</a>
                                            </div> 
                                        </div>
                                    </div>
                                {/* </div> */}
                            {/* </div>  */}

                            <div className="iq-card-body">
                                <div className='row'> 
                                    <div className="form-group col-md-3">
                                        <label>From</label>
                                        <DatePicker
                                            selected={from_date}
                                            onChange={e => setFromDate(e)}
                                            className="form-control"
                                            placeholderText="From"
                                            name="from_date"
                                            dateFormat="dd-MM-yyyy" 
                                            autoComplete="off" 
                                            dropdownMode="select" 
                                            showMonthDropdown
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown   
                                        />
                                    </div> 
                                    <div className="form-group col-md-3">
                                        <label>To</label>
                                        <DatePicker
                                            selected={to_date}
                                            onChange={e => setToDate(e)}
                                            className="form-control"
                                            placeholderText="To"
                                            name="to_date"
                                            dateFormat="dd-MM-yyyy" 
                                            autoComplete="off" 
                                            dropdownMode="select" 
                                            showMonthDropdown
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown   
                                        />
                                    </div> 
                                    <div className="form-group col-md-3">
                                        <label>Category</label>
                                        <select className="form-control" name='sel_category_id' onChange={e => onInputChange_category(e)}>
                                            <option value="">--Select--</option>
                                            {
                                                list_empcategorys_all.map((val_catg, key) => { 
                                                    return ( 
                                                        <option value={val_catg.lpcategory_pk_id}>{val_catg.leave_cat_title}</option>
                                                    );  
                                                })
                                            }
                                        </select>
                                    </div> 
                                    <div className="form-group col-md-3">
                                        <button type='button' className='btn btn-success' style={{marginTop:25}}
                                                onClick={() => btn_submit1()}>
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button type='button' className='btn btn-primary' style={{marginTop:25}}
                                                onClick={() => download_excel()}>
                                            Excel
                                        </button>
                                    </div> 
                                </div>


                                <div className='row' style={{marginTop:10}}> 
                                    <div className="form-group col-md-12">
                                        <table className="table table-striped table-bordered table-sm center table-hover" role="grid" >
                                            <thead>
                                                <tr key={111}>
                                                    <td> <b>Employee Name</b> </td> 
                                                    <td> <b>Date of Birth</b> </td> 
                                                    <td> <b>Department</b> </td> 
                                                    <td> <b>Desgination</b> </td> 
                                                </tr> 
                                            </thead>
                                            <tbody> 
                                                <tr key={112}>
                                                    <td> {employee_name} </td> 
                                                    <td>{moment(new Date(date_of_birth)).format("DD-MM-YYYY") } </td> 
                                                    <td> {department}  </td> 
                                                    <td> {emp_designation}  </td> 
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="form-group col-md-12 table-responsive" style={{marginTop:'10'}}>
                                        <table className="table table-striped table-bordered table-sm center table-hover" role="grid" >
                                            <thead>
                                                <tr key="113">
                                                    <th rowSpan={2}>DATE</th> 
                                                    {
                                                        list_empcategorys.map((val_catg, key) => { 
                                                            return (<th colSpan={3}>{val_catg.leave_cat_title}</th>);  
                                                        })
                                                    }
                                                </tr> 
                                                <tr key="114"> 
                                                    {
                                                        list_empcategorys.map((val_catg, key) => {
                                                            return(
                                                                array_header2.map((val_hread2, key) => {
                                                                    return (<th>{val_hread2}</th>);
                                                                }) 
                                                            )
                                                        })
                                                    }
                                                </tr> 
                                            </thead>
                                            <tbody>  
                                                {
                                                    new_list.length > 0 ? 
                                                    (
                                                        new_list.map((item, index) => {
                                                            let sel_date1=item.date;
                                                            return(
                                                                <tr key={index}>
                                                                    <td style={{whiteSpace: 'nowrap'}}>{item.date}</td>  
                                                                    {
                                                                        list_empcategorys.map((val_catg, key) => {
                                                                            var leave_cat_id=val_catg.leave_cat_id;
                                                                            var lpcategory_pk_id=val_catg.lpcategory_pk_id;
                                                                            var lpcategory_category_id=val_catg.lpcategory_category_id;
                                                                             
                                                                            return(
                                                                                array_header2.map((val_hread2, key) => {
                                                                                    //var cal_leave_credit=(typeof list_data[sel_date1][0][lpcategory_pk_id]!='undefined')?list_data[sel_date1][0][lpcategory_pk_id]['cal_leave_credit']:0;
                                                                                    //var app_leave=(typeof list_data[sel_date1][0][lpcategory_pk_id]!='undefined')?list_data[sel_date1][0][lpcategory_pk_id]['app_leave']:0;
                                                                                    var cal_leave_credit=0;
                                                                                    var app_leave=0;
                                                                                    for(var cnt_i3=0;cnt_i3<list_data[sel_date1].length;cnt_i3++){
                                                                                        //console.log(list_data[sel_date1][cnt_i3][lpcategory_pk_id]);
                                                                                        if(typeof list_data[sel_date1][cnt_i3][lpcategory_pk_id]!='undefined'){
                                                                                            cal_leave_credit=list_data[sel_date1][cnt_i3][lpcategory_pk_id]['cal_leave_credit'];
                                                                                            app_leave=list_data[sel_date1][cnt_i3][lpcategory_pk_id]['app_leave'];
                                                                                        } 
                                                                                    }
                                                                                     
                                                                                    let res_value1=0;
                                                                                    if(val_hread2=='LEAVE EARNED'){
                                                                                        res_value1=cal_leave_credit;
                                                                                    }
                                                                                    if(val_hread2=='LEAVE TAKEN'){
                                                                                        res_value1=app_leave;
                                                                                    }
                                                                                    if(val_hread2=='LEAVE BALANCE'){
                                                                                        res_value1=cal_leave_credit-app_leave;
                                                                                        if(res_value1<0){
                                                                                            res_value1=0;
                                                                                        }
                                                                                    }

                                                                                    return (
                                                                                        <td>{res_value1}</td>
                                                                                    );
                                                                                }) 
                                                                            )
                                                                        })
                                                                    }
                                                                </tr> 
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <th colSpan={list_empcategorys.length*3+1} style={{textAlign:'center'}}> No Data Found.. </th>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Employee_leave_report_format

{/* Casual & Medical Leave 44 */}
{/* Leave Without Pay 42 */}
{/* Official Duty Leave 41 */}
{/* Privileged Leave 40 */}
{/* Short Leave 43 */}