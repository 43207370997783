import React from "react";
import { FcApproval } from "react-icons/fc";

const TableRow = ({ name, href, status, verify, handelVerify }) => {
  let badge = "";
  if (status === null) {
    badge = <span className="badge badge-pill badge-warning">Not Verified Yet</span>;
  } else if (status === 1) {
    badge = <FcApproval style={{ fontSize: 30 }} />;
  }

  return (
    <tr className="text-center">
      <td>{name}</td>
      <td>
        <a href={href} target="_blank">
          <i className="text-danger fa fa-file-pdf-o" aria-hidden="true"></i>
        </a>
      </td>
      <td>
        <span className="badge badge">{badge}</span>
      </td>
      <td>
        <button className={`btn btn-sm btn-info ${status === 1 ? "disabled" : null}`} onClick={handelVerify}>
          Verify
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
