import React, { Component } from "react";
import Apiconnect from "../../services/Apiconnect.js";
import Helper from "../../services/HelperCodebase.js";
import { withAlert } from "react-alert";
import { useAlert } from "react-alert";
import "../pages/admin.css";
import { green } from "@material-ui/core/colors";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import { CSVLink } from "react-csv";
import Modal from "react-modal";
import Loadder from "./Loadder";
import Loadder_progress from "./Loadder_progress";
import FullScreenLoading from "react-fullscreen-loading";

class Employee_month_sal extends Component {
  constructor(props) {
    super(props);
    this.state = { earning_head: [], deduct_head: [], all_emp: [], all_emp_m: [], holiday: 0, formula_list: [], monthlyPaidDays: [], allchecked: "", selectedEmp: [], selectedMonth: false, modalEmpIsOpen: false, lodder: false, fullScreen: false, modalProgressLodder: false, progressCount: 0, totalCount: 0 };
    //var aa = this.props.match.params.id;
    //var bb = this.props.match.params.id;
    if (this.props.match.params.id > 0) {
      this.yyyymm = this.props.match.params.id;
      this.yyyy = this.props.match.params.id.substring(0, 4);
      this.mm = this.props.match.params.id.substring(4, 6);
      this.status = this.props.match.params.status;
      this.monId = this.props.match.params.monId;
    } else {
      let newDate = new Date();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();

      month = ("0" + month).slice(-2);
      this.yyyymm = year + "" + month;
      this.yyyy = year;
      this.mm = month;
      this.status = 1;
    }

    this.get_earning_head();
    this.getdeductList();
    this.getAllEmp();
    this.getHolidayList();
    this.get_All_formula_byCompany();
    this.getPaidDaysMonthData();

    this.totdays = Helper.daysInMonth(this.yyyy, this.mm);
    this.totsundays = Helper.getSundays(parseInt(this.yyyy), parseInt(this.mm));
  }

  get_All_formula_byCompany = () => {
    Apiconnect.getData(`employee_salary/get_All_formula_byCompany/${Apiconnect.get_company_id()}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data);
      //   console.log('Formulas Data:',ern);
      this.setState({ formula_list: ern });
      //   console.log('Formulas Data:',this.state.formula_list);
    });
  };

  get_earning_head = () => {
    Apiconnect.getData(`earning_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let ern = Apiconnect.decrypt_obj(response.data.data);
      this.setState({ earning_head: ern });
      //   console.log(ern);
    });
  };
  getdeductList = () => {
    //var l_user = JSON.parse(localStorage.getItem('user'));
    //var empid = l_user.emp_id;
    Apiconnect.getData(`deduction_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      this.setState({ deduct_head: _xtract });
    });
  };

  getHolidayList = () => {
    var yum = this.yyyymm;
    //var l_user = JSON.parse(localStorage.getItem('user'));
    //var empid = l_user.emp_id;
    var company_id = Apiconnect.get_company_id();
    //  Axios.get(`http://localhost:3050/api/tush/getholidays/${company_id}/${yum}`).then((response) => {
    Apiconnect.getData(`employee_salary/getholidays/${company_id}/${yum}`).then((response) => {
      let _xtract = response.data.data;
      //    console.log(_xtract);
      this.setState({ holiday: _xtract });
    });
  };

  getAllEmp = () => {
    this.setState({ fullScreen: true });
    var yum = this.yyyymm;
    //   console.log(yum);
    // Axios.get(`http://localhost:3050/api/tush/allemployee_salaryinfo/${yum}`).then((response) => {
    Apiconnect.getData(`employee_salary/allemployee_salaryinfo/${yum}/${Apiconnect.get_company_id()}`).then(async (response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      // console.log(_xtract);
      this.setState({ fullScreen: false });
      this.setState({ all_emp_m: _xtract });

      /////////////////////////////////////////////////////////

      var mx_info = [];
      _xtract.map(async (vvx, kkx) => {
        let ind_info = vvx;
        var prsnt_days = ind_info.present_count;

        ////// ADD HOLIDAY AND SUNDAY TO IT...

        let cur_salx = ind_info.salary_info;
        // console.log('cur_salx=='cur_salx);

        var mx_sal = [];
        var is_esi = ind_info.is_esi;
        cur_salx.map(async (vv, kk) => {
          var mn_days = this.totdays;
          var pr_days = prsnt_days;
          // console.log('ind_info==',vv.saved_present_days)
          if (vv.saved_present_days !== null) {
            pr_days = vv.saved_present_days;
            prsnt_days = vv.saved_present_days;
          }
          if (pr_days > 0) {
          } else {
            var pr_days = 0;
          }
          // if(vv.typ=='earning'){
          //     var inddd = 'cut_if_lwp_earning'
          // }else{
          //     var inddd = 'cut_if_lwp_deduction'
          // }
          if (vv.saved_present_days !== null) {
            var tt = parseInt(vv.saved_amount);
          } else {
            if (vv.formula_id !== null && vv.formula_id != 0) {
              await this.sleep(10);
              tt = await this.formula_man(1, vv.formula_id, mx_sal);
              //  console.log('sachn value=',tt);
            } else {
              if (vv["cut_if_lwp_" + vv.typ] == 1) {
                tt = Math.round((parseInt(vv.amount) * pr_days) / mn_days);
              } else {
                tt = parseInt(vv.amount);
              }
            }

            if (is_esi == 0 && vv.abbreviation !== undefined && vv.abbreviation !== null && vv.abbreviation.toUpperCase() === "ESI") {
              tt = 0;
            }
            if (vv.abbreviation !== undefined && vv.abbreviation !== null && vv.abbreviation.toUpperCase() === "ESI") {
              tt = Math.ceil(tt);
            }
          }

          let mark_sal = vv;
          mark_sal = { ...mark_sal, amount: tt };
          mx_sal.push(mark_sal);
        });

        await this.sleep(10);
        ind_info = { ...ind_info, salary_info: mx_sal };
        ind_info = { ...ind_info, present_count: prsnt_days };

        mx_info.push(ind_info);
        this.setState({ all_emp: mx_info });
      });

      // this.setState({'fullScreen':false})

      ///////////////////////////////////////////////////////////////////////////////////
    });
  };

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  changedays = async (e, i) => {
    let ki = i.key_e;
    let va = e.currentTarget.value;
    // console.log("teestData",ki,va)
    // console.log('test',ki);
    // console.log('test1',va);

    let markers = this.state.all_emp;
    let markers_m = this.state.all_emp_m;
    //console.log('--->>>>-----');     let ff1 = isNaN(parseFloat(e.currentTarget.value))?0:parseFloat(e.currentTarget.value);
    // console.log(markers[ki]);
    // console.log('markers_m==',markers_m[ki]);
    //  let ff1 = isNaN(parseFloat(e.currentTarget.value))?0:parseFloat(e.currentTarget.value);
    let ff = isNaN(parseFloat(e.currentTarget.value)) ? 0 : !isNaN(parseFloat(e.currentTarget.value)) && isFinite(e.currentTarget.value) ? (e.currentTarget.value.charAt(0) === "0" ? parseFloat(e.currentTarget.value) * 1 : e.currentTarget.value) : parseFloat(e.currentTarget.value);

    let cur_uu = markers_m[ki];
    // console.log('cur_uu',cur_uu)
    let cur_sal = cur_uu.salary_info;

    // console.log('cur_sal',cur_sal)

    let isBasic = cur_sal.some((val) => val.abbreviation !== undefined && val.abbreviation !== null && val.abbreviation.toUpperCase() === "BASIC");
    var is_esi = cur_uu.is_esi;
    var mx_sal = [];

    let markdata = Promise.all(
      cur_sal.map(async (vv, kk) => {
        // return Promise.all( cur_sal.map( async (vv, kk) => {
        var mn_days = this.totdays;
        if (ff > mn_days) {
          ff = mn_days;
        }
        var pr_days = ff;
        // console.log('-->>>----');
        // console.log(vv.amount);
        // console.log(pr_days);
        // console.log(mn_days);
        // console.log('-->>>----');
        if (pr_days > 0) {
        } else {
          pr_days = 0;
        }
        var tt = 0;
        // console.log('max_salbefore:',mx_sal);
        let mark_sal = vv;
        // console.log('mx_sal',mx_sal)
        // if(vv.typ=='deduction'){
        // console.log('Calculate formula for ',vv.abbreviation)
        if (vv.formula_id !== null && vv.formula_id !== 0 && isBasic) {
          await this.sleep(10);
          tt = await this.formula_man(ki, vv.formula_id, mx_sal);
          //  console.log('sachn value=',tt);
        } else {
          if (vv["cut_if_lwp_" + vv.typ] == 1) {
            tt = Math.round((parseInt(vv.amount) * pr_days) / mn_days);
          } else {
            tt = parseInt(vv.amount);
          }
        }

        // console.log(tt);
        // console.log('s-->>>----');

        if (is_esi == 0 && vv.abbreviation !== undefined && vv.abbreviation !== null && vv.abbreviation.toUpperCase() === "ESI") {
          tt = 0;
        }
        if (vv.abbreviation !== undefined && vv.abbreviation !== null && vv.abbreviation.toUpperCase() === "ESI") {
          // console.log(' Math.ceil',tt ,Math.ceil(tt))
          tt = Math.ceil(tt);
        }

        mark_sal = { ...mark_sal, amount: tt };
        mx_sal.push(mark_sal);
        markers[ki] = { ...markers[ki], present_count: ff };
        markers[ki] = { ...markers[ki], salary_info: mx_sal };
        // this.setState({all_emp:  markers});
        return markers;

        //  return 1;
      })
    );

    markdata
      .then((value) => {
        this.setState({ all_emp: markers });
      })
      .catch((err) => {
        // console.log(err);
      });

    // console.log(markers[ki]);
    //console.log(cur_sal);
    //console.log('--->><<<<>>-----');

    //this.formula_man_er(ki,va);
  };

  //  addbits = async (s) => {
  //   var total = 0,
  //       s = s.match(/[+\-]*(\.\d+|\d+(\.\d+)?)/g) || [];

  //   while (s.length) {
  //     total += parseFloat(s.shift());
  //   }
  //   return total;
  // }

  formula_man = async (ki, va, mx_sal1) => {
    var itmnew = {};
    this.state.earning_head.map((val, key) => {
      let kkn = val.abbreviation;
      itmnew[kkn] = val.amount;
    });

    this.state.deduct_head.map((val, key) => {
      let kkn = val.abbreviation;
      itmnew[kkn] = val.amount;
    });

    // console.log('mx_sal1',mx_sal1)

    // for(let abb in itm){
    //   // console.log('abb',abb)
    //   const ishead = mx_sal1.some(val => val.abbreviation === abb)
    //   console.log('ishead',ishead,abb)
    // }
    // console.log('itm',itmnew)
    // console.log('function Called',va);
    // console.log('this.formula_list',this.formula_list);
    var out = 0;
    this.state.formula_list.map((fval, fkey) => {
      //  console.log('sachin Formula Logic',fval);
      if (fval.id == va) {
        var logic = fval.logic;
        // console.log('logic',logic)
        var itm = {};

        const kkknew = Object.keys(itmnew);

        kkknew.map((val, key) => {
          // console.log('val==',val)
          let kkn = val;
          itm[kkn] = 0;
        });
        mx_sal1.map((val, key) => {
          // console.log('val==',val)
          let kkn = val.abbreviation;
          itm[kkn] = val.amount;
        });

        const kkk = Object.keys(itm);
        const vvv = Object.values(itm);
        out = 0;
        // console.log('kkk',kkk);
        //  console.log('logic',logic);
        logic.map((v, k) => {
          var if_con = v.if_condition;
          var chk = v.check_value;
          var opr = v.f_operator;

          kkk.map((v1, k1) => {
            var finder = v1;
            var rplcer = vvv[k1];
            var rr = if_con.replace(finder, rplcer);
            if_con = rr.toString();
          });
          //  console.log('condition',v.if_condition,if_con);
          var ifcon_o = eval(if_con);

          if (opr == "BETWEEN") {
            var rng = chk.split("-");
            var ifcon_out = ifcon_o + ">=" + rng[0] + " && " + ifcon_o + "<=" + rng[1];
          } else {
            var ifcon_out = ifcon_o + opr + chk;
          }
          // console.log(ifcon_out);
          // console.log(eval(ifcon_out));

          if (eval(ifcon_out)) {
            out = v.f_then;
            //console.log('--====-->>');
            //console.log(out);
            kkk.map((v1, k1) => {
              var finder = v1;
              var rplcer = vvv[k1];
              //console.log(finder);
              //console.log(rplcer);
              var rr = out.replace(finder, rplcer);
              out = rr.toString();
            });

            if (fval.title == "ESI") {
              out = Math.ceil(eval(out));
            } else {
              out = Math.round(eval(out));
            }

            //     console.log(out);
            // console.log('--====-->>');
          } else {
            out = v.f_else;
            kkk.map((v1, k1) => {
              var finder = v1;
              var rplcer = vvv[k1];
              var rr = out.replace(finder, rplcer);
              out = rr.toString();
            });
            // console.log('out1',out);

            if (fval.title == "ESI") {
              out = Math.ceil(eval(out));
            } else {
              out = Math.round(eval(out));
            }

            //console.log(out);
            // out = Math.round(eval(out));
            //console.log(out);
            //console.log('--====-->>');
          }
        });
      }
    });
    // console.log('::: PTAX -->::' + out);
    return out;
  };

  saveMonthlyData = async () => {
    this.setState({ fullScreen: true });
    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let info_enc = Apiconnect.encrypt_obj(this.state.all_emp);
    const inf_a = { enc: info_enc, month: this.mm, year: this.yyyy, total_day: this.totdays, user_id: user_id };
    // console.log('inf_a',inf_a)
    await Apiconnect.postData("employee_monthly_salary/create", inf_a).then((response) => {
      this.props.alert.success(response.data.message);
      this.setState({ fullScreen: false });
      this.props.history.push("/monthly_salary_list");
    });
  };

  saveData = (e, i) => {
    e.preventDefault();
    confirmAlert({
      message: "We are saving modified salaries in database. Meanwhile you can work on other modules. Once we finish with this process you will be get redirected to monthly salary list page. Thank you",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.saveMonthlyData();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  lock = (val) => {
    if (val == 0) {
      var status = 1;
    }
    const inf_a = { id: this.monId, status: status };
    Apiconnect.postData("employee_monthly_salary/lock_month", inf_a).then((response) => {
      this.props.alert.success(response.data.message);
      this.props.history.push("/monthly_salary_list");
    });
  };

  resetData = () => {
    if (this.state.selectedEmp <= 0) {
      confirmAlert({
        title: "Select Employee?",
        buttons: [
          {
            label: "Yes",
            // onClick: () => this.deleteData(),
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "Are you sure you want to reset data?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteData(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  deleteData = () => {
    this.setState({ fullScreen: true });
    var yum = this.yyyymm;
    // console.log('yum',yum);
    // Apiconnect.deleteData(`employee_monthly_salary/delete/${this.yyyy}/${this.mm}/${Apiconnect.get_company_id()}`).then((response) => {
    //     this.props.history.push("/monthly_salary_list");
    //    this.props.alert.success('Data reset successfully')
    // });

    var emp_data = Object.values(this.state.selectedEmp);
    var filtered_ids = emp_data.filter(function (value, index, arr) {
      return value > 0;
    });

    var obj = { empids: filtered_ids, year: this.yyyy, month: this.mm, company_id: Apiconnect.get_company_id() };
    // let info_enc = Apiconnect.encrypt_obj(obj);
    Apiconnect.postData(`employee_monthly_salary/resetsalary`, obj).then((response) => {
      // if(response.data.status){
      this.setState({ fullScreen: true });
      this.props.history.push("/monthly_salary_list");
      this.props.alert.success("Data reset successfully");
      // }
    });
  };

  getPaidDaysMonthData = () => {
    Apiconnect.getData(`employee_salary/getPaidDaysDataMonthWise/${this.yyyymm}/${Apiconnect.get_company_id()}`).then((response) => {
      let paidDays = Apiconnect.decrypt_obj(response.data.data);
      console.log("salData", paidDays);
      this.setState({ monthlyPaidDays: paidDays });
      //   return salData;
    });
  };

  uploadPaidDays = (date) => {
    this.setState({ selectedMonth: date });
    this.setState({ modalEmpIsOpen: true });

    // this.state.all_emp.map((val,key) => {
    //         console.log('key',key)
    //     let data = this.state.monthlyPaidDays.find(val1 => val1.id === val.id)
    //     // val.present_count = 12;
    //     var obj2 = {'value':12}
    //     var val = {'currentTarget':obj2}
    //     var key1 = {'key_e':key}

    //     this.changedays(val,key1)
    // })
  };

  submitPaidDaysBulk = () => {
    const data = new FormData();
    data.append("csvpaiddaysfile", this.state.selectedFile);
    //  Axios.post(`http://localhost:3040/api/test/uploadsalarycsv/${Apiconnect.get_company_id()}/${selectedMonth}`, data).then((response) => {
    this.setState({ lodder: true });

    //  this.setState({'modalProgressLodder':true})
    Apiconnect.postData(`upload/uploadpaiddayscsv/${Apiconnect.get_company_id()}/${this.state.selectedMonth}`, data).then((response) => {
      if (response.data.status === true) {
        let monthlyPaidDays = response.data.data;
        monthlyPaidDays.map((val, key) => {
          const indexId = this.state.all_emp.findIndex((val1) => val1.id === parseInt(val.id));
          // const data = this.state.all_emp.find(val1=>val1.id===parseInt(val.id))

          // console.log('indexId',indexId)
          if (indexId !== -1) {
            var data = this.state.selectedMonth.split("-");
            var daysInMonth = new Date(data[0], data[1], 0).getDate();
            var dayCnt = val.present_days;
            if (dayCnt > daysInMonth) {
              dayCnt = 0;
            }

            var obj2 = { value: dayCnt };
            var val = { currentTarget: obj2 };
            var key1 = { key_e: indexId };

            this.changedays(val, key1);
          }

          if (monthlyPaidDays.length == key + 1) {
            this.setState({ lodder: false });
            this.setState({ modalEmpIsOpen: false });
            this.props.alert.success("file upload Successfully");
          }
        });
      }
    });
  };

  handelChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  closeModal = () => {
    this.setState({ modalEmpIsOpen: false });
  };

  sellectAll = (e) => {
    if (e.target.checked === true) {
      this.setState({ allchecked: "checked" });
      let ob = {};
      this.state.all_emp.map((val, key) => {
        ob[val.id] = val.id;
      });
      this.setState({ selectedEmp: ob });
    } else {
      this.setState({ allchecked: "" });
      this.setState({ selectedEmp: [] });
    }
  };

  onInputChange2 = (e) => {
    this.setState({ allchecked: "" });

    if (e.target.checked === true) {
      this.setState({
        selectedEmp: {
          ...this.state.selectedEmp,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      });
    } else {
      this.setState({
        selectedEmp: {
          ...this.state.selectedEmp,
          [e.currentTarget.name]: 0,
        },
      });
    }
  };

  render() {
    const customStyles = {
      content: {
        top: "55%",
        width: "60%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const alert = this.props.alert;

    const customStyles1 = {
      content: {
        top: "55%",
        width: "60%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "unset ",
        backgroundColor: "unset ",
      },
    };

    // console.log(this.state.all_emp);

    return (
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          {this.state.fullScreen ? <FullScreenLoading loading loaderColor="#3498db" /> : null}
          <div className="row">
            <div className="col-lg-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">
                      Employee Salary List ({this.yyyy}-{this.mm})
                    </h4>
                  </div>
                  <div>
                    <CSVLink filename={"employee-paidDayData.csv"} style={{ marginLeft: "20px", float: "right" }} className="btn btn-warning btn-sm" data={this.state.monthlyPaidDays}>
                      <i class="fa fa-download"></i>Monthly PaidDays CSV
                    </CSVLink>
                    <button className="btn btn-primary btn-sm" onClick={() => this.uploadPaidDays(this.yyyy + "-" + this.mm)}>
                      {` `} Upload PaidDays
                    </button>
                  </div>
                </div>

                <div>
                  <div>
                    {/* <Modal isOpen={this.state.modalProgressLodder} style={customStyles1}>
                      {
                        <Loadder_progress
                          sync_count={this.state.progressCount}
                          total={this.state.totalCount}
                          noSync='0'
                          hide
                        />
                      }
                    </Modal> */}
                  </div>
                  <Modal isOpen={this.state.modalEmpIsOpen} style={customStyles} onRequestClose={() => this.closeModal()}>
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between model_head">
                        <div className="iq-header-title">
                          <h4 className="card-title ">PaidDays Bulk File</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div className="row">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8">
                            {this.state.lodder ? <Loadder /> : ""}
                            <div class="container center">
                              <div class="row">
                                <div class="col-md-12" style={{ marginBottom: "15px" }}>
                                  <h2 class="white" style={{ paddingTop: "10px" }}>
                                    PaidDays Bulk Data Upload
                                  </h2>
                                  {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                  <hr style={{ backgroundColor: "#fff" }} />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3 col-md-offset-3 center"></div>
                                <div class="col-md-6 col-md-offset-3 center">
                                  <div class="btn-container">
                                    <div class="dropzone">
                                      <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                      {/* <input type="file" class="upload-input" value="" /> */}
                                      <div className="row" style={{ margin: " 0 10px 25px 10px" }}>
                                        <input type="file" id="file" className="form-control" name="csvpaiddaysfile" onChange={this.handelChange} />
                                      </div>
                                    </div>

                                    <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={() => this.submitPaidDaysBulk()}>
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-2"></div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <button className="btn btn-info" onClick={() => this.closeModal()}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>

                <div className="iq-card-body">
                  <form onSubmit={(e) => this.saveData(e)}>
                    <div className="table-responsive" style={{ height: "500px" }}>
                      <table className="table mb-0 table-borderless">
                        <thead className="sticky_header">
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th style={{ textAlign: "center", borderLeft: "1px solid #12171d", background: "#388238", borderRight: "1px solid #12171d" }} colSpan={this.state.earning_head.length}>
                              Credit
                            </th>
                            <th style={{ textAlign: "center", background: "#b95318" }} colSpan={this.state.deduct_head.length}>
                              Deduction
                            </th>
                            <th></th>
                          </tr>

                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="select_all" checked={this.state.allchecked} onChange={(e) => this.sellectAll(e)} />
                            </th>
                            <th>#</th>
                            <th>Employee Id</th>
                            <th>Name</th>
                            <th style={{ width: "60px" }}>Total Days</th>
                            <th style={{ width: "60px" }}>Paid Days</th>
                            {this.state.earning_head.map((val, key) => {
                              return (
                                <th key={key} style={{ textAlign: "center", borderLeft: "1px solid #12171d", background: "#388238" }}>
                                  {val.name}
                                </th>
                              );
                            })}
                            {this.state.deduct_head.map((val, key) => {
                              return (
                                <th key={key} style={{ textAlign: "center", borderLeft: "1px solid #12171d", background: "#b95318" }}>
                                  {val.name}
                                </th>
                              );
                            })}
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.all_emp.map((val_e, key_e) => {
                            var sngle = val_e;
                            var sngle_sal = sngle.salary_info;
                            var all_earning = [];
                            var tot = 0;
                            if (sngle_sal.length > 0) {
                              all_earning = Helper.array_search_multidim("typ", "earning", sngle_sal);
                            }

                            var all_deduct = [];
                            if (sngle_sal.length > 0) {
                              all_deduct = Helper.array_search_multidim("typ", "deduction", sngle_sal);
                            }

                            var ck = Object.values(this.state.selectedEmp).find(function (element) {
                              return element == val_e.id;
                            });

                            var ck1 = false;
                            if (ck !== undefined) {
                              ck1 = true;
                            }

                            // console.log(val_e.first_name+' = '+val_e.last_name);

                            return (
                              <tr key={key_e}>
                                <th scope="row">
                                  <input type="checkbox" name={val_e.id} value={val_e.id} checked={ck1} onChange={(e) => this.onInputChange2(e)} />
                                </th>
                                <th scope="row">{key_e + 1}</th>
                                <td>{val_e.emp_code}</td>
                                <td>
                                  {val_e.first_name} {val_e.last_name}
                                </td>
                                <td>
                                  <input style={{ width: "60px" }} type="text" placeholder="TotalDays" value={this.totdays} readOnly />
                                </td>
                                {/* <td   ><input style={{ width: '60px' }} type="text" placeholder="Presence"   value={Number(val_e.present_count)+Number(this.state.holiday)-Number(val_e.lop_count)}  onChange={e => this.changedays(e,{key_e})}   /></td> */}
                                <td>
                                  <input style={{ width: "60px" }} type="text" placeholder="Presence" value={val_e.present_count} onChange={(e) => this.changedays(e, { key_e })} />
                                </td>

                                {this.state.earning_head.map((val, key) => {
                                  //////////////////////////////////////////////
                                  var this_earning = [];
                                  if (all_earning.length > 0) {
                                    var th = Helper.array_search_multidim("head_id", val.id, all_earning);
                                    this_earning = th[0];
                                  }
                                  var earning_amount = "";
                                  if (typeof this_earning !== "undefined" && this_earning !== null) {
                                    earning_amount = this_earning.amount;
                                    tot = tot + earning_amount;
                                  }
                                  // console.log(earning_amount+'--'+tot);

                                  /////////////////////////////////////////////////////////////////////////

                                  return (
                                    <th style={{ background: "#a9c7a9" }}>
                                      <input style={{ width: "60px" }} type="text" placeholder="" value={earning_amount} />
                                    </th>
                                  );
                                })}
                                {this.state.deduct_head.map((val, key) => {
                                  //////////////////////////////////////////////
                                  var this_deduct = [];
                                  if (all_deduct.length > 0) {
                                    var th = Helper.array_search_multidim("head_id", val.id, all_deduct);
                                    this_deduct = th[0];
                                  }
                                  var deduct_amount = "";
                                  if (typeof this_deduct !== "undefined" && this_deduct !== null) {
                                    if (this_deduct.abbreviation !== undefined && this_deduct.abbreviation !== null && this_deduct.abbreviation.toUpperCase() === "ESI") {
                                      deduct_amount = Math.ceil(this_deduct.amount);
                                    } else {
                                      deduct_amount = this_deduct.amount;
                                    }
                                    tot = tot - deduct_amount;
                                  }
                                  /////////////////////////////////////////////////////////////////////////

                                  return (
                                    <th style={{ background: "#e6ab89" }}>
                                      <input style={{ width: "60px" }} type="text" placeholder="" value={deduct_amount} />
                                    </th>
                                  );
                                })}

                                <th>{isNaN(tot) ? 0 : tot}</th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <button disabled={this.status === "0" ? "" : "true"} type="submit" className="btn btn-success">
                      Save Record
                    </button>
                    {this.status === "0" ? (
                      <>
                        <button style={{ marginLeft: "5px" }} type="button" className="btn btn-info" onClick={(e) => this.lock(this.status)}>
                          {this.status === "0" ? "Lock" : "Unlock"}
                        </button>
                        <button style={{ marginLeft: "5px" }} type="button" className="btn btn-danger" onClick={(e) => this.resetData()}>
                          {this.status === "0" ? "Reset" : ""}
                        </button>
                      </>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAlert()(Employee_month_sal);
