import React, {useState, useEffect} from 'react'
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import Modal from 'react-modal';
import moment from 'moment';
import { getDate } from 'date-fns';
import { tr } from 'date-fns/locale';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-50%, -50%)',
        width: "50%" ,
        height: '80%'
    },
};

const Initiate_hiring_process = () => {

    const {id, vac_id} = useParams()

    const localuser = JSON.parse(localStorage.getItem("user"));
    let emp_id = Apiconnect.decrypt(localuser.id);
    const company_id = Apiconnect.get_company_id()
    const alert = useAlert(); 

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [applicant, setApplicant] = useState({})
    const [applicant_name, setApplicant_name] = useState("")
    const [rounds, setRounds] = useState([])
    const [round_name, setRound_name] = useState("")
    const [hp_id, setHp_id] = useState("")
    const [round_id, setRound_id] = useState("")
    const [assessment_list, setAssessment_list] = useState([])
    const [panel_list, setPanel_list] = useState([])

    useEffect(() => {
        getApplicant(); 
        get_initiated_round_of_applicant(); 
        get_panel_list(); 
        applicant_assessment_list();
        get_forms()
    }, [])

    const getApplicant = () => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_application_get_by_id/${id}`).then(res => {
            setFullScreenLoader(false)
            let data = res.data.data
            // console.log(data)
            setApplicant(data[0])
            setApplicant_name(data[0].applicant_name)
            setRounds(data[0].rounds)
            setHp_id(data[0].wf_hiring_process_id)
        })
    }

    // console.log(rounds)

    const getAssessment_list = (r_id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_round_assessments_getall/${company_id}/${hp_id}/${r_id}`).then(res => {
            setFullScreenLoader(false)
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setAssessment_list(_xtract)
            setRound_id(r_id)
        })
    }

    const getPanel_list = (r_id) => {
        setFullScreenLoader(true)
        Apiconnect.postData(`hiring_application/hiring_process_round_panels_getall/${company_id}/${hp_id}/${r_id}`).then(res => {
            setFullScreenLoader(false)
            let _xtract = Apiconnect.decrypt_obj(res.data.data)
            setPanel_list(_xtract)
        })
    }

    const handleModal = (name, id) => {
        setModalIsOpen(true)
        setRound_name(name)
    }


   /* ---------------sb_hiring_process_applicant_round_mst------------------*/

   

   const [hpram_inputs, setHpram_inputs] = useState({
    hparm_company_id:company_id,
    hparm_applicant_id:id,
    hparm_from_date: "",
    hparm_to_date:"",
    hparm_from_time:"",
    hparm_to_time:"",
    hparm_remarks:"",
    hparm_added_by: emp_id
   })
   const {hparm_from_date,hparm_from_time,hparm_remarks,hparm_to_date,hparm_to_time} = hpram_inputs

    const onInputChange = (e) => {
        setHpram_inputs({...hpram_inputs, [e.target.name]:e.target.value})  
    }

   const handleSubmitRound = (e) => {
    e.preventDefault()

    

    hpram_inputs["hparm_hiring_process_id"] = hp_id
    hpram_inputs["hparm_round_id"] = round_id

    if(hparm_from_date!=="" && hparm_from_time!=="" && hparm_remarks!=="" && hparm_to_date!=="" && hparm_to_time!=="" && round_id!=="" && hp_id!==""){
        setFullScreenLoader(true)

        Apiconnect.postData(`hiring_application/schedule_hiring_process_round`, hpram_inputs).then(res => {
            alert.success("Hiring Process Round Initiated...")
            get_initiated_round_of_applicant()
            setHpram_inputs({
                hparm_company_id:company_id,
                hparm_applicant_id:id,
                hparm_from_date: "",
                hparm_to_date:"",
                hparm_from_time:"",
                hparm_to_time:"",
                hparm_remarks:"",
                hparm_added_by: emp_id
               })
            setModalIsOpen(false)
            setFullScreenLoader(false)
        })
    }else{
        alert.error("Empty Input Fields")
    }
   }

    const [initiated_round, setInitiated_round] = useState([])
    const get_initiated_round_of_applicant = () => {
    setFullScreenLoader(true)
    Apiconnect.postData(`hiring_application/get_initiated_round/${company_id}/${id}`).then(res => {
        setFullScreenLoader(false)
        let data = Apiconnect.decrypt_obj(res.data.data)
        setInitiated_round(data)
    })
   }

   console.log(initiated_round)

   const assign_initiated_round_to_completed = (id) => {
    setFullScreenLoader(true)
    Apiconnect.postData(`hiring_application/assign_initiated_round_to_completed/${id}`).then(res => {
        setFullScreenLoader(false)
        alert.success("Round Completed")
        get_initiated_round_of_applicant()
    })
   }


   /* -----------------------------sb_hiring_process_applicant_assessment_mst--------------------------- */
   const [panelslist_for_assessments, setPanellist_for_assessments] = useState([])
   const [p_id, setP_id] = useState([])
   const get_panel_list = () => {
    setFullScreenLoader(true)
    Apiconnect.postData(`hiring_application/hiring_panel_getall/${company_id}`).then(res => {
        setFullScreenLoader(false)
        setPanellist_for_assessments(Apiconnect.decrypt_obj(res.data.data))
    })
    }

  const [forms, setForms] = useState([])
  const get_forms = () => {
    setFullScreenLoader(true)
    Apiconnect.postData(`hiring_application/assessment_forms_getall`).then(res =>{
        setFullScreenLoader(false)
        let data = Apiconnect.decrypt_obj(res.data.data)
        setForms(data)
    })
}
const [hpaam_inputs, setHpaam_inputs] = useState({
    hpaam_company_id: company_id, 
    hpaam_applicant_id:id, 
    hpaam_assessment_id: "",
    hpaam_panel_id:1,
    hpaam_date:"",
    hpaam_from_time:"",
    hpaam_to_time:"",
    hpaam_added_by:emp_id,
    hpaam_form_id:0
})
const {hpaam_form_id,hpaam_assessment_id, hpaam_panel_id,hpaam_date,hpaam_from_time,hpaam_to_time} = hpaam_inputs
const on_hpaam_input_change = (e) => {
    setHpaam_inputs({...hpaam_inputs, [e.target.name]:e.target.value})
}
const initiate_applicant_assessment = (e) => {
    e.preventDefault()
    if(hpaam_assessment_id!=="" && hpaam_date!=="" && hpaam_from_time!=="" && hpaam_to_time!==""){
        setFullScreenLoader(true)
        hpaam_inputs['hpaam_round_id'] = round_id
        hpaam_inputs['hpaam_hiring_process_id'] = hp_id
        Apiconnect.postData(`hiring_application/hiring_process_applicant_assessment_create`, hpaam_inputs).then(res => {
            setModalIsOpen(false)
            setFullScreenLoader(false)
            applicant_assessment_list()
            setHpaam_inputs({hpaam_assessment_id: "",hpaam_panel_id:"",hpaam_date:"", hpaam_from_time:"",hpaam_to_time:""})
            get_initiated_round_of_applicant()
            alert.success("Inserted Successfully")
        })
        console.log(hpaam_inputs)
    }else{
        alert.error("Input fields are empty")
    }
}
const [a_assessment_list, setA_assessment_list]=useState([])
const applicant_assessment_list = () => {
    let ins_ob = {
        company_id:company_id,
        id:id
    }
    setFullScreenLoader(true)
    Apiconnect.postData(`hiring_application/hiring_process_applicant_assessment_getall`, ins_ob).then(res => {
        setFullScreenLoader(false)
        let data = Apiconnect.decrypt_obj(res.data.data)
        setA_assessment_list(data)
    })
}

const [r_name, setR_name] = useState("")


console.log(a_assessment_list)



 

  return (
    <>
        <div className='content-page'
        style={{
            background: "#ffffff",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
        }}
        >
        {fullScreenLoader ? (<FullScreenLoading loading loaderColor="#3498db" />): null}
        <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
            <div className='iq-card'>
                <div className='iq-card-header d-flex justify-content-between model_head'
                style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                >
                    <div className='iq-header-title text-white' style={{float: "left"}}>
                        <h4 className='text-white'>Schedule <span className='font-weight-bold text-muted'>{round_name}</span> For <span className='font-weight-bold'>{applicant_name}</span>, <span>{r_name}</span></h4>
                    </div>
                </div>
                <div className='iq-card-body'>
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>From Date</label>
                                        <input type="date" className='form-control' name='hparm_from_date' value={hparm_from_date} onChange={e => onInputChange(e)}/>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>To Date</label>
                                        <input type="date" className='form-control' name='hparm_to_date' value={hparm_to_date} onChange={e => onInputChange(e)}/>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>Remarks If Any</label>
                                        <input type="text" className='form-control' placeholder='Please Enter Remarks If Any...' name='hparm_remarks' value={hparm_remarks} onChange={e => onInputChange(e)}/>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>From Time</label>
                                        <input type="time" className='form-control' name='hparm_from_time' value={hparm_from_time} onChange={e => onInputChange(e)}/>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>To Time</label>
                                        <input type="time" className='form-control' name='hparm_to_time' value={hparm_to_time} onChange={e => onInputChange(e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <button className='btn btn-sm btn-primary' style={{float: "right", marginTop: "50%"}} onClick={(e) => handleSubmitRound(e)}>Initiate</button>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        {
                           assessment_list.length > 0 &&
                        <div className='col-md-12' style={{border: "10px solid #F5FAFE", padding:10}}>
                           <h6 className='font-weight-bold text-muted'>Schedule Assessments</h6>
                           <div className='row'>
                            <div className='col-md-3 mb-2'>
                                <label>Assessment</label>
                                <select className='form-control center' name='hpaam_assessment_id' value={hpaam_assessment_id} onChange={(e) => on_hpaam_input_change(e)}>
                                    <option value="" disabled selected>---Please Select Assessment---</option>
                                            {
                                                assessment_list.map((item, index) => {
                                                    return(
                                                        <option key={index} value={item.id}>{item.assessment_name}</option>
                                                    )
                                                })
                                            }
                                </select>
                            </div> 
                            <div className='col-md-3 mb-2'>
                                <label>Assign Form</label>
                                {/* --------------------------------------------------------------------- */}
                                <select className='form-control center' name="hpaam_form_id" value={hpaam_form_id} onChange={(e) => on_hpaam_input_change(e)}>
                                    <option value={0}>--No tamplet--</option>
                                    {
                                    forms.map((item,index) => {
                                        return(
                                            <option key={index} value={item.hpaf_pk_id}>{item.hpaf_name}</option>
                                        )
                                    })
                                    }
                                </select>
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label>Assign Panel</label>
                                <select className='form-control center' name='hpaam_panel_id' value={hpaam_panel_id} onChange={(e) => on_hpaam_input_change(e)}>
                                    {
                                        panelslist_for_assessments.length > 0 && panelslist_for_assessments.map((item, index) => {
                                            return(
                                                <option key={index} value={item.id}>{item.hpp_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label>Select Date</label>
                                <input type="date" placeholder="dd-mm-yyyy" className='form-control' name='hpaam_date' value={hpaam_date} onChange={(e) => on_hpaam_input_change(e)}/>
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label>From Time</label>
                                <input type="time" className='form-control' name='hpaam_from_time' value={hpaam_from_time} onChange={(e) => on_hpaam_input_change(e)}/>
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label>To Time</label>
                                <input type="time" className='form-control' name='hpaam_to_time' value={hpaam_to_time} onChange={(e) => on_hpaam_input_change(e)}/>
                            </div>
                           </div>
                           <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <button className='btn btn-sm btn-primary' style={{float: "right"}} onClick={(e) => initiate_applicant_assessment(e)} >Initiate</button>
                                </div>
                            </div> 
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Modal>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='iq-card'
                        style={{borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}
                        >
                            <div className='iq-card-header d-flex justify-content-between model_head'
                             style={{borderRadius: 5,background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}
                            >
                                <div className='iq-header-title' style={{float: "left"}}>
                                    <h4 className='card-title text-white'>Initiate Hiring Process For <span className='font-weight-bold'>{applicant_name}</span></h4>
                                </div>
                                <Link to={`/Applicants/${vac_id}`} ><button className='btn btn-sm btn-info'>Back</button></Link>
                            </div>
                            <div className='iq-card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='iq-card'
                                        style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}
                                        >
                                            <table className='table table-bordered center'>
                                                <thead className='thead-light'>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Applied For</th>
                                                        <th>Hiring Process</th>
                                                        <th>Number of Rounds</th>
                                                        <th>Schedule Rounds</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{applicant.applicant_name}</td>
                                                        <td>{applicant.am_email}</td>
                                                        <td>{applicant.am_phone_cell}</td>
                                                        <td>{applicant.am_position}</td>
                                                        <td>{applicant.hp_name}</td>
                                                        <td>{rounds.length}</td>
                                                        <td>
                                                        {
                                                        rounds.map((item, index) => {
                                                            return(
                                                                    <button key={index} className='btn btn-primary mr-2' onClick={() => {
                                                                        handleModal(item.hpr_round_name); 
                                                                        getAssessment_list(item.hpr_pk_id); 
                                                                        getPanel_list(item.hpr_pk_id)
                                                                        setRound_id(item.hpr_pk_id)
                                                                        setR_name(applicant.am_position)
                                                                    }}>{item.hpr_round_name}</button> 
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div >
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <div className='iq-card'
                                        style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}
                                        >
                                            <table className='table table-bordered center'>
                                            <thead className='thead-light'>
                                                <tr>
                                                    {
                                                        rounds.map((item, index) => {
                                                            return(
                                                                
                                                                    <th key={index}>
                                                                        {item.hpr_round_name} 
                                                                        <br />
                                                                        {
                                                                            item.assessment_names.map((val, key) => {
                                                                                return(
                                                                                    <span key={key} style={{fontSize: "13px",color: "#000000", backgroundColor: "#B4FFA4", padding: 2, borderRadius: 20, marginRight: 3}}>{val.hpa_name}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </th>
                                                                
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                        </table>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'
                                    >
                                        <div className='iq-card'
                                        style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}
                                        >
                                            <table className='table table-bordered center'>
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Round Name</th>
                                                    <th>From Date</th>
                                                    <th>To Date</th>
                                                    <th>From Time</th>
                                                    <th>To Time</th>
                                                    <th>Remarks</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    initiated_round.map((item, index) => {
                                                        let frm_d = moment(item.hparm_from_date).format('DD/MM/YYYY')
                                                        let to_d = moment(item.hparm_to_date).format('DD/MM/YYYY')

                                                        let frm_t = moment(item.hparm_from_time, "HH:mm").format('hh:mm A')
                                                        let to_t = moment(item.hparm_to_time, "HH:mm").format('hh:mm A')

                                                        let st = ""
                                                        if(item.hparm_status == 0){
                                                            st = <span className='badge badge-pill badge-warning'>Not-Initiated</span>
                                                        }else if(item.hparm_status == 1){
                                                            st = <span className='badge badge-pill badge-info'>In-Progress</span>
                                                        }else{
                                                            st = <span className='badge badge-pill badge-success'>Completed</span>
                                                        }

                                                        return(
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.round_name}</td>
                                                                <td>{frm_d}</td>
                                                                <td>{to_d}</td>
                                                                <td>{frm_t}</td>
                                                                <td>{to_t}</td>
                                                                <td>{item.hparm_remarks}</td>
                                                                <td>{st}</td>
                                                                <td>
                                                                    <button className='btn btn-sm btn-info' onClick={() => assign_initiated_round_to_completed(item.id)}>Complete Manually</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <div className='iq-card'
                                        style={{borderRadius: 12, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}
                                        >
                                            <table className='table table-bordered center'>
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th colSpan={9} >
                                                        Scheduled Round Assessments
                                                        </th>
                                                </tr>
                                                <tr>
                                                    <th>Round Name</th>
                                                    <th>Assessment Name</th>
                                                    <th>Assigned Form</th>
                                                    <th>Assessment Panel</th>
                                                    <th>Scheduled Date</th>
                                                    <th>From Time</th>
                                                    <th>To Time</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    a_assessment_list.map((item,index) => {
                                                        let dt = moment(item.hpaam_date).format('DD/MM/YYYY')
                                                        let frm_t = moment(item.hpaam_from_time, "HH:mm").format('hh:mm A')
                                                        let to_t = moment(item.hpaam_to_time, "HH:mm").format('hh:mm A')
                                                        let st = ""
                                                        if(item.hpaam_status == 0){
                                                            st = <span className='badge badge-pill badge-warning'>Not-Initiated</span>
                                                        }else if(item.hpaam_status == 1){
                                                            st = <span className='badge badge-pill badge-info'>In-Progress</span>
                                                        }else{
                                                            st = <span className='badge badge-pill badge-success'>Completed</span>
                                                        }
                                                        return(
                                                            <tr key={index}>
                                                                <td>{item.round_name}</td>
                                                                <td>{item.assessment_name}</td>
                                                                <td>{item.form_name}</td>
                                                                <td>{item.assessment_panel_name}</td>
                                                                <td>{dt}</td>
                                                                <td>{frm_t}</td>
                                                                <td>{to_t}</td>
                                                                <td>{st}</td>
                                                                <td>
                                                                    <Link to={`/View_panelist_score/${item.id}/${id}`}><button className='btn btn-sm btn-primary'>View Score</button></Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Initiate_hiring_process